import styled from 'styled-components';

import TA from 'components/TextAreaNew';

export const Text = styled(TA)`
  width: 100%;
  min-height: 100px;
  border-color: ${({ error }) => (error ? '#ff4124' : '#dedede')};
  font-size: var(--font-size12);
  line-height: 1.33;
  letter-spacing: 0.33px;
`;

export const Message = styled.p`
  margin-bottom: 3px;
  margin-left: 10px;
  color: var(--color-orangeDC);
  font-size: var(--font-size13);
  word-break: break-word;
`;
