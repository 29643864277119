import styled from 'styled-components';

import TextInputWithSelect from 'components/TextInputWithSelect';

export const Container = styled.div`
  max-width: 1024px;
  margin: 30px auto;
`;

export const SearchPanel = styled(TextInputWithSelect)`
  margin: 40px 0 70px;
`;
