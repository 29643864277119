import styled from 'styled-components';
import { motion } from 'framer-motion';

import TextInput from 'components/TextInputNew';

import { ITEM_HEIGHT } from './consts';

export const Container = styled.div`
  margin-top: 15px;
`;

export const Input = styled(TextInput)`
  border-radius: ${({ isOpen }) => (isOpen ? '8px 8px 0 0' : '8px')};
  &:focus {
    box-shadow: none;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  background-color: var(--color-white);
`;

const getAnimate = ({ isOpen }) => ({
  animate: {
    height: isOpen ? 'auto' : 0,
    borderColor: isOpen ? 'var(--color-grey97)' : 'var(--color-white)',
  },
  transition: {
    height: { duration: 0.3 },
    borderColor: { duration: 0 },
  },
  initial: {
    height: 0,
    borderColor: 'var(--color-white)',
  },
});

export const CollapseBox = styled(motion.div).attrs(getAnimate)`
  position: relative;
  border: 1px solid;
  border-radius: 0 0 var(--border-radius8) var(--border-radius8);
  border-top: none;
  box-sizing: border-box;
  overflow: hidden;
`;

export const Content = styled.div`
  box-sizing: border-box;
  overflow: auto;
  min-height: ${ITEM_HEIGHT}px;
  max-height: ${ITEM_HEIGHT * 8 - 1}px;
  ${({ isLoading }) => isLoading && 'min-height: 64px;'}
`;

export const SpinnerWrap = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 1px;
  right: 1px;
  bottom: 1px;
  background-color: rgba(255, 255, 255, 0.75);
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: ${ITEM_HEIGHT}px;
  color: ${({ isError }) => (isError ? 'var(--color-orangeDC)' : 'var(--color-grey6A)')};
  font-size: var(--font-size18);
  padding: 0 10px;
`;

export const Text = styled.p`
  font-size: var(--font-size18);
  text-align: left;
  color: var(--color-grey6A);
`;

export const SelectedWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  margin-top: ${({ disabled }) => (disabled ? 0 : 10)}px;
`;
