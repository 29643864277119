import React, { useState, useEffect } from 'react';
import { CenteredContainer, Title, Text, Restart } from './styles';

const Error = () => {
  const [counter, setCounter] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter(counter - 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [counter, setCounter]);

  useEffect(() => {
    if (counter === 0) window.location.reload();
  }, [counter]);

  return (
    <CenteredContainer>
      <Title>Oops :(</Title>
      <Text>
        Auf Ihrem Computer ist ein Problem aufgetreten. Wir haben die Fehlerinformation gesammelt und anonymisiert an unsere
        Fehleranalyse-Software übertragen. Wir versuchen, das Problem so schnell wie möglich zu beheben.
      </Text>
      <Restart>{`Die Anwendung wird in ${counter}s neu gestartet.`}</Restart>
    </CenteredContainer>
  );
};

export default Error;
