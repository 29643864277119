import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Container, Button, Text, Icon, Content, Item } from './styles';

const DropDown = ({ items }) => {
  const [isOpen, setIsOpen] = useState();
  const handleClick = useCallback(() => setIsOpen(($) => !$), []);

  const buttonName = useMemo(() => items.reduce((acc, { label, disabled }) => (disabled ? label : acc), null), [items]);

  const handleClose = useCallback(() => setIsOpen(false), []);
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClose);

      return () => document.removeEventListener('click', handleClose);
    }
    return () => null;
  }, [handleClose, isOpen]);

  if (items.length === 0) return null;

  return (
    <Container>
      <Button type="button" onClick={handleClick} isOpen={isOpen} isActive={Boolean(buttonName)}>
        <Text>{buttonName || '...'}</Text>
        {buttonName && <Icon isOpen={isOpen} />}
      </Button>
      <Content isOpen={isOpen}>
        {items.map(({ path, label, disabled }) => (
          <Item key={path} to={path} disabled={disabled} onClick={handleClose}>
            {label}
          </Item>
        ))}
      </Content>
    </Container>
  );
};

DropDown.defaultProps = { items: [] };
DropDown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool.isRequired,
    }).isRequired
  ),
};

export default DropDown;
