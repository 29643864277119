import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_BUG_REPORTS = getName('UPDATE_BUG_REPORTS');
export const UPDATE_BUG_REPORT = getName('UPDATE_BUG_REPORT');
export const DELETE_BUG_REPORT = getName('DELETE_BUG_REPORT');
export const RESET_BUG_REPORTS = getName('RESET_BUG_REPORTS');

export const UPDATE_SORT = getName('UPDATE_SORT');
export const UPDATE_PAGINATION = getName('UPDATE_PAGINATION');
export const RESET_FILTERS = getName('RESET_FILTERS');

export const FETCH_BUG_REPORTS = getName('FETCH_BUG_REPORTS');
export const SEND_BUG_REPORT = getName('SEND_BUG_REPORT');
export const CHANGE_BUG_REPORT_STATUS = getName('CHANGE_BUG_REPORT_STATUS');
export const FETCH_MEDI_DATA = getName('FETCH_MEDI_DATA');
export const SAVE_MEDI_DATA = getName('SAVE_MEDI_DATA');
export const FETCH_USERS = getName('FETCH_USERS');
export const FETCH_USER = getName('FETCH_USER');
export const CREATE_USER = getName('CREATE_USER');
export const SAVE_USER = getName('SAVE_USER');
