import styled from 'styled-components';
import { motion } from 'framer-motion';

import ArrowIcon from 'components/ArrowIcon';
import { NavbarLink } from '../styles';

export const Container = styled.div`
  position: relative;
  z-index: 10;
`;

export const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  height: 40px;
  background-color: var(--color-greyF8);
  border: 1px solid;
  border-color: var(--color-greyF8);
  border-radius: ${({ isOpen }) => (isOpen ? '8px 8px 0 0' : '8px')};
  padding: 0 5px;
  outline: none;
  cursor: pointer;
  @media (hover) {
    ${({ isActive, isOpen }) =>
      !(isActive || isOpen) &&
      `
      &:hover {
        background-color: var(--color-greyEC);
      }
    `}
  }
  ${({ isActive, isOpen }) =>
    (isActive || isOpen) &&
    `
    border-color: var(--color-grey97);
    background-color: var(--color-white);
  `}
`;

export const Text = styled.p`
  flex: 1 1 auto;
  color: var(--color-grey6A);
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  letter-spacing: 0.92px;
  text-align: center;
  text-transform: uppercase;
  padding-left: 10px;
  ${({ children }) =>
    children === '...' &&
    `
    font-size: 25px;
    letter-spacing: 4px;
    line-height: 14px;
    padding: 0 0 14px 0;
  `}
`;

export const Icon = styled(ArrowIcon)`
  height: 18px;
  transform: scale(0.75);
  & > div {
    background-color: var(--color-grey6A);
  }
`;

const getAnimate = ({ isOpen }) => ({
  animate: {
    height: isOpen ? 'auto' : 0,
    borderWidth: isOpen ? '0 1px 1px 1px' : '0 0 0 0',
    opacity: isOpen ? 1 : 0,
  },
  transition: {
    height: { duration: 0.3 },
    borderWidth: { duration: 0 },
    opacity: { duration: 0.3 },
  },
  initial: {
    height: 0,
    borderWidth: '0 0 0 0',
    opacity: 0,
  },
});

export const Content = styled(motion.div).attrs(getAnimate)`
  position: absolute;
  top: 40px;
  right: 0;
  left: 0;
  overflow: hidden;
  border: solid var(--color-grey97);
  border-radius: 0 0 var(--border-radius8) var(--border-radius8);
`;

export const Item = styled(NavbarLink)`
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  &:last-child {
    border-bottom: none;
  }
`;
