import styled from 'styled-components';
import { path } from 'ramda';

import { REPORT_STATUS } from 'store/reports';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: var(--border-radius8);
  cursor: pointer;
  &:hover {
    background: var(--color-greyEC);
    opacity: 0.88;
  }
  margin: -6px 0;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${({ width }) => `calc(${width} - 10px)`};
  text-align: left;
  height: 45px;
`;

export const Field = styled.span`
  width: ${({ width }) => `calc(${width} - 10px)`};
  font-size: var(--font-size18);
  color: var(--color-grey6A);
  font-weight: ${({ status }) =>
    `${status}` !== path([3, 'id'], REPORT_STATUS) ? 'var(--font-weight600)' : 'var(--font-weight400)'};
  text-align: left;
  line-height: 45px;
`;
