import { takeLatest, call, put, select, throttle } from 'redux-saga/effects';
import { prop, head, omit } from 'ramda';

import { withAlert } from 'store/alerts';
import api from 'api';
import { getUserProp } from 'store/session/selectors';

import { SUB_ORGANIZATION } from '.';
import { updateReports, updateReport, updatePagination } from './actions';
import { FETCH_REPORTS, FETCH_REPORTS_STATUS, SAVE_REPORT, CREATE_REPORTS_ARCHIVE, UPLOAD_EXCEL_FILE } from './types';

function* fetchReports({ payload }) {
  const data = yield call(api.getReports, payload);
  yield put(updateReports(data.data));
  yield put(updatePagination(omit(['data'], data)));
}

function* fetchReportsStatus() {
  return { success: yield call(api.getReportsStatus) };
}

function* saveReport({ payload }) {
  const data = yield call(api.putReport, payload);
  yield put(updateReport(data));
}

function* createReportsArchive({ payload }) {
  const data = yield call(api.postReportsArchive, payload);

  return { success: prop('url', data), error: prop('error', data) };
}

function* uploadExcelFile({ payload }) {
  const orgId = yield select(getUserProp(SUB_ORGANIZATION));

  const { path } = yield call(api.uploadFile, head(payload));

  return yield call(api.postUploadResource, orgId, path);
}

export default function* watchReports() {
  yield throttle(500, FETCH_REPORTS, withAlert(fetchReports));
  yield takeLatest(FETCH_REPORTS_STATUS, withAlert(fetchReportsStatus));
  yield takeLatest(SAVE_REPORT, withAlert(saveReport));
  yield takeLatest(CREATE_REPORTS_ARCHIVE, withAlert(createReportsArchive));
  yield takeLatest(UPLOAD_EXCEL_FILE, withAlert(uploadExcelFile));
}
