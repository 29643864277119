import styled from 'styled-components';

export const DropButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 54px;
  border-radius: var(--border-radius8);
  border: ${({ isActive, isWarn }) => {
    if (!isActive && !isWarn) return 'solid 1px var(--color-greyDC)';
    if (isWarn) return 'solid 3px var(--color-orangeF5)';

    return 'solid 3px var(--color-green65)';
  }};
  background: var(--color-greyF8);
  padding-left: 20px;
  padding-right: 60px;
  margin-top: 15px;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: var(--shadow-focus);
  }
`;

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.p`
  font-size: var(--font-size15);
  font-weight: var(--font-weight500);
  letter-spacing: 0.46px;
  text-align: center;
  color: #a4a4a4;
  text-align: center;
`;

export const WarnText = styled(Text)`
  font-weight: var(--font-weight700);
  color: var(--color-orangeF5);
`;
