import { PRESSURE, VISUAL_ACUITY, COMMENT, DATE, MOBILITY } from 'store/examinations';

const validatePressure = (data) => {
  const errors = data.reduce((acc, item, index) => {
    const isValid = !Number.isNaN(Number(item));

    if (item === '' || isValid) return acc;
    if (!acc.message) {
      acc.message = 'Ungültiger Wert';
      acc.keys = [index];
    } else {
      acc.keys.push(index);
    }

    return acc;
  }, {});

  if (!errors.message) {
    return null;
  }

  return errors;
};

const withoutValidation = [COMMENT, DATE, MOBILITY, VISUAL_ACUITY];

export const validate = (data) => {
  let isError;
  const errors = Object.keys(data).reduce((acc, key) => {
    if (withoutValidation.includes(key)) return acc;

    const pressureErr = validatePressure(data[key][PRESSURE]);

    if (pressureErr) {
      isError = true;
      acc[key] = { [PRESSURE]: pressureErr };
    }

    return acc;
  }, {});

  return isError ? errors : null;
};
