import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withFormik } from 'formik';
import { pipe } from 'ramda';

import { SERVICES } from 'store/invoices';
import { saveInvoice } from 'store/invoices/actions';
import { getCurrentInvoiceProp } from 'store/invoices/selectors';

import InvoicesForm from './component';

const mapPropsToValues = ({ services }) => ({ [SERVICES]: services || [] });

const sendData = (values, { setInvoice }) => {
  setInvoice(values[SERVICES]);
};

const mapStateToProps = (state) => ({
  services: getCurrentInvoiceProp(SERVICES)(state),
});

const mapDispatchToProps = { setInvoice: saveInvoice };

export default pipe(
  withFormik({
    mapPropsToValues,
    validate: sendData,
    handleSubmit: (_, { setSubmitting }) => {
      setSubmitting(false);
    },
    validateOnBlur: false,
    enableReinitialize: true,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(InvoicesForm);
