import React, { useCallback, useMemo } from 'react';
import { isEmpty, pathOr } from 'ramda';
import PropTypes from 'prop-types';

import { CANTONS } from 'store/analytics';

import { Container, Circle, Rect, InfoBox, Label, Text } from './styles';

const Dot = ({
  data,
  groupLabel,
  nameLabel,
  xAxisLabel,
  yAxisLabel,
  zAxisLabel,
  zFactor,
  hoveredItem,
  hoveredGroup,
  onHover,
  selectedItems,
  selectedGroups,
  onSelect,
}) => {
  const { id, name, group, x, xRatio, y, yRatio, z, zRatio, borderColor, backgroundColor } = data;
  const onHoverStart = useCallback(() => onHover(id), [id, onHover]);
  const onHoverEnd = useCallback(() => onHover(null), [onHover]);
  const onClick = useCallback(() => onSelect(id), [id, onSelect]);
  const opacity = useMemo(
    () =>
      (!hoveredItem && !hoveredGroup && isEmpty(selectedItems) && isEmpty(selectedGroups) && 1) ||
      ((id === hoveredItem || selectedItems[id] || group === hoveredGroup || selectedGroups[group]) && 1) ||
      0.05,
    [group, hoveredGroup, hoveredItem, id, selectedGroups, selectedItems]
  );
  const getNumber = useCallback((val) => Math.round(val * 100) / 100, []);

  return (
    <Container
      type="button"
      onClick={onClick}
      $size={zRatio * zFactor}
      $left={xRatio}
      $top={yRatio}
      $zIndex={(hoveredItem === id && 2) || (opacity === 1 && 1) || 0}
    >
      {hoveredItem === id && <Rect />}
      <Circle
        onHoverStart={onHoverStart}
        onHoverEnd={onHoverEnd}
        $borderColor={borderColor}
        $backgroundColor={backgroundColor}
        $opacity={opacity}
      />
      {hoveredItem === id && (
        <InfoBox $left={xRatio} $top={yRatio}>
          <Label>{nameLabel}</Label>
          <Text>{name}</Text>
          <Label>{groupLabel}</Label>
          <Text>{pathOr('-', [group, 'name'], CANTONS)}</Text>
          <Label>{zAxisLabel}</Label>
          <Text>{getNumber(z)}</Text>
          <Label>{xAxisLabel}</Label>
          <Text>{getNumber(x)}</Text>
          <Label>{yAxisLabel}</Label>
          <Text>{getNumber(y)}</Text>
        </InfoBox>
      )}
    </Container>
  );
};

Dot.defaultProps = {
  xAxisLabel: null,
  yAxisLabel: null,
  zAxisLabel: null,
  hoveredItem: null,
  hoveredGroup: null,
};
Dot.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired,
    borderColor: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    x: PropTypes.number.isRequired,
    xRatio: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    yRatio: PropTypes.number.isRequired,
    z: PropTypes.number.isRequired,
    zRatio: PropTypes.number.isRequired,
  }).isRequired,
  groupLabel: PropTypes.string.isRequired,
  nameLabel: PropTypes.string.isRequired,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
  zAxisLabel: PropTypes.string,
  zFactor: PropTypes.number.isRequired,
  hoveredItem: PropTypes.string,
  hoveredGroup: PropTypes.string,
  onHover: PropTypes.func.isRequired,
  selectedItems: PropTypes.objectOf(PropTypes.bool).isRequired,
  selectedGroups: PropTypes.objectOf(PropTypes.bool).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Dot;
