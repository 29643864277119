import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pipe } from 'ramda';

import { saveExamination } from 'store/examinations/actions';
import { getCurrentExaminationProp } from 'store/examinations/selectors';
import { ID, STATUS, RIGHT, LEFT, DATE, COMMENT, MOBILITY } from 'store/examinations';

import ExaminationForm from './component';
import { mapPropsToValues, filterRequestData } from './utils';
import { validate } from './validate';

const validateAndSendData = (values, { examId, updateExamination }) => {
  const errors = validate(values);

  if (errors) return errors;

  updateExamination({
    [ID]: examId,
    ...filterRequestData(values),
  });

  return {};
};

const mapStateToProps = (state, { readMode }) => {
  const examId = getCurrentExaminationProp(ID)(state);
  const statusExam = getCurrentExaminationProp(STATUS)(state);

  return {
    key: examId,
    examId,
    statusExam,
    readMode: readMode || Boolean(statusExam),
    right: getCurrentExaminationProp(RIGHT)(state),
    left: getCurrentExaminationProp(LEFT)(state),
    date: getCurrentExaminationProp(DATE)(state),
    comment: getCurrentExaminationProp(COMMENT)(state),
    mobility: getCurrentExaminationProp(MOBILITY)(state),
  };
};

export default pipe(
  withFormik({
    mapPropsToValues,
    validate: validateAndSendData,
    handleSubmit: (_, { setSubmitting }) => {
      setSubmitting(false);
    },
    validateOnBlur: false,
  }),
  connect(mapStateToProps, { updateExamination: saveExamination }),
  withRouter
)(ExaminationForm);
