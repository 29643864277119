import styled from 'styled-components';

import { REMOVE, ITEM_HEIGHT } from '../consts';

export const Container = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: ${ITEM_HEIGHT}px;
  padding: 0 8px 0 10px;
  background-color: ${({ $isSelected }) => ($isSelected ? '#f0efef' : 'var(--color-white)')};
  border: none;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  outline: none;
  cursor: pointer;
  @media (hover) {
    &:hover {
      background-color: var(--color-greyEC);
    }
  }
`;

export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(${({ type }) => (type === REMOVE ? 45 : 0)}deg);
  transition: transform 0.3s;
`;
