import styled from 'styled-components';

import DefDateInput from './DateInput';
import DefTimeInput from './TimeInput';

export const Container = styled.div`
  width: 100%;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  padding: 6px 0;
  margin-bottom: 10px;
  ${({ $error }) =>
    $error &&
    `
      border: 1px solid #f5a623;
      border-radius: var(--border-radius8);
      background-color: #fef3e4;
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Item = styled.div`
  width: ${({ width }) => `calc(${width} - 10px)`};
  font-size: var(--font-size20);
  color: var(--color-grey6A);
  text-align: left;
`;

export const Auge = styled(Item)`
  width: ${({ width }) => `calc(${width} - 10px)`};
`;

export const Input = styled.input`
  width: ${({ width, $ml }) =>
    (width && $ml && `calc(${width} - 15px)`) || (width && `calc(${width} - 10px)`) || 'calc(100% - 48px)'};
  height: 40px;
  font-size: var(--font-size20);
  color: ${({ error }) => (error ? '#f5a623' : 'var(--color-grey6A)')};
  padding: 8px;
  border: ${({ error }) => (error ? `2px solid #f5a623` : '1px solid var(--color-grey97)')};
  border-radius: var(--border-radius8);
  background-color: inherit;
  outline: none;
  appearance: none;
  margin-left: ${({ $ml }) => ($ml ? 5 : 0)}px;
`;

export const ItemWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => `calc(${width} - 15px)`};
  margin-right: 5px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: var(--color-greyF8);
  border-radius: var(--border-radius8);
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    background: var(--color-greyEC);
  }
`;

export const DateInput = styled(DefDateInput)`
  width: ${({ width }) => `calc(${width} - 10px)`};
`;

export const TimeInput = styled(DefTimeInput)`
  width: ${({ width }) => `calc(${width} - 10px)`};
`;

export const Text = styled.p`
  font-size: var(--font-size20);
  color: var(--color-grey6A);
  text-align: left;
  padding: 0 5px;
`;
