import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Inside = styled.div`
  display: flex;
  min-width: ${({ columns }) => (columns === 1 ? 100 : 100 / (columns + 1))}%;
  flex-direction: column;
  justify-content: space-between;
`;
