import { pipe, times, identity, map } from 'ramda';

export const FEATURE_NAME = 'medicalHistory';

export const VALUE = 'value';
export const DESCRIPTION = 'description';
export const LIST = 'list';

export const ILLNESSES = 'illnesses';
export const ALLERGIES = 'allergies';
export const SURGERY = 'surgery';
export const HEART = 'heart';
export const UNDER_TREATMENT = 'underTreatment';
export const EYE_DROPS = 'eyeDrops';
export const DRUGS = 'drugs';
export const FILES = 'files';

export const BLOOD_PRESSURE = 'bloodPressure';
export const DIABETES = 'diabetes';
export const DEMENTIA = 'dementia';
export const ASTHMA = 'asthma';
export const SMOKING = 'smoking';
export const ALCOHOL = 'alcohol';
export const COMMENT = 'comment';

export const SEARCHABLE_MEDIA_UPDATE = 'SEARCHABLE_MEDIA_UPDATE';

export const CARE_LEVEL = 'careLevel';
export const CARE_LEVELS = pipe(
  times(identity),
  map((id) => ({
    id: String(id),
    label: `Pflegestufe ${id}`,
  }))
)(13);
