import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: ${({ width }) => width}%;
  margin-right: 7px;
`;

export const Task = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border: 1px solid ${({ done }) => (done ? 'var(--color-blue50)' : 'var(--color-greyEC)')};
  border-radius: var(--border-radius8);
  background-color: ${({ done }) => (done ? '#c4f6eb' : 'var(--color-greyEC)')};
  color: ${({ done }) => (done ? '#018869' : 'var(--color-grey6A)')};
  font-size: var(--font-size11);
  font-weight: var(--font-weight500);
  letter-spacing: 0.92px;
  text-transform: uppercase;
  padding: 8px;
  word-break: break-word;
  text-align: center;
`;
