import { useState, useEffect, useMemo, useCallback } from 'react';
import { prop, path, cond, equals, map, applySpec } from 'ramda';

import {
  CUSTOM,
  STATUS,
  ALL,
  READ,
  UNREAD,
  ID,
  EMPTY,
  SUB_PATIENT,
  BIRTH,
  META,
  MODIFIED,
  REPORT_URL,
  REPORT_STATUS,
} from 'store/reports';

import { joinName } from './utils';

export const useCheckedList = (reports) => {
  const [markers, setMarkers] = useState([]);
  const [checkboxStatus, setCheckboxStatus] = useState(CUSTOM);

  const dividedList = useMemo(
    () =>
      reports.reduce(
        (acc, item) => {
          if (prop(STATUS, item) === Number(path([3, 'id'], REPORT_STATUS))) {
            acc[READ].push(item);
          } else {
            acc[UNREAD].push(item);
          }

          return acc;
        },
        { [READ]: [], [UNREAD]: [] }
      ),
    [reports]
  );

  const list = useMemo(
    () =>
      map(
        applySpec({
          id: prop(ID),
          patientName: joinName(SUB_PATIENT),
          birth: path([SUB_PATIENT, BIRTH]),
          reportDate: path([META, MODIFIED]),
          status: prop(STATUS),
          url: prop(REPORT_URL),
        }),
        [...dividedList[UNREAD], ...dividedList[READ]]
      ),
    [dividedList]
  );
  const readIds = useMemo(() => map(prop(ID), dividedList[READ]), [dividedList]);
  const unreadIds = useMemo(() => map(prop(ID), dividedList[UNREAD]), [dividedList]);

  const updateStatus = useCallback(
    (status) =>
      cond([
        [equals(CUSTOM), () => {}],
        [equals(ALL), () => setMarkers(map(prop('id'), list))],
        [equals(EMPTY), () => setMarkers([])],
        [equals(READ), () => setMarkers(readIds)],
        [equals(UNREAD), () => setMarkers(unreadIds)],
      ])(status),
    [list, readIds, unreadIds]
  );

  useEffect(() => {
    updateStatus(checkboxStatus);
  }, [updateStatus, checkboxStatus]);

  return {
    list,
    checkboxStatus,
    setCheckboxStatus,
    markers,
    setMarkers,
  };
};
