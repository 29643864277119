import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { reducerTemplate } from 'store/utils';
import { NAME } from 'store/organizations';

import {
  ID,
  LIST,
  FILTERS,
  SEARCH,
  SUB_ORGANIZATION,
  REPORTS,
  SORT,
  SORT_BY,
  SORT_DIR,
  PAGINATION,
  CURSOR,
  LIMIT,
  COUNT,
  HAS_MORE,
  ALL,
} from '.';
import {
  UPDATE_PATIENTS,
  UPDATE_PATIENT,
  DELETE_PATIENT,
  RESET_PATIENTS,
  UPDATE_FILTER_ORGANIZATION,
  UPDATE_FILTER_SEARCH,
  RESET_FILTERS,
  UPDATE_SORT,
  UPDATE_PAGINATION,
  UPDATE_PATIENT_REPORTS,
} from './types';

const patentsReducer = reducerTemplate(
  {
    id: ID,
    list: UPDATE_PATIENTS,
    item: UPDATE_PATIENT,
    deleteItem: DELETE_PATIENT,
    reset: RESET_PATIENTS,
  },
  {}
);

const initFilter = {
  [SUB_ORGANIZATION]: { [ID]: ALL, [NAME]: 'Alle Pflegezentren' },
  [SEARCH]: '',
  [SORT]: {
    [SORT_BY]: '',
    [SORT_DIR]: '',
  },
  [PAGINATION]: {
    [CURSOR]: 0,
    [LIMIT]: 10,
    [COUNT]: 0,
    [HAS_MORE]: false,
  },
};

const filterReducer = handleActions(
  {
    [UPDATE_FILTER_ORGANIZATION]: (state, { payload }) => ({
      ...state,
      [SUB_ORGANIZATION]: payload,
      [PAGINATION]: {
        ...state[PAGINATION],
        [CURSOR]: 0,
      },
    }),
    [UPDATE_FILTER_SEARCH]: (state, { payload }) => ({
      ...state,
      [SEARCH]: payload,
      [PAGINATION]: {
        ...state[PAGINATION],
        [CURSOR]: 0,
      },
    }),
    [UPDATE_SORT]: (state, { payload }) => ({
      ...state,
      [SORT]: payload,
      [PAGINATION]: {
        ...state[PAGINATION],
        [CURSOR]: 0,
      },
    }),
    [UPDATE_PAGINATION]: (state, { payload }) => ({
      ...state,
      [PAGINATION]: {
        ...state[PAGINATION],
        ...payload,
      },
    }),
    [RESET_FILTERS]: () => initFilter,
  },
  initFilter
);

const reportsReducer = handleActions(
  {
    [UPDATE_PATIENT_REPORTS]: (_, { payload }) => payload || [],
  },
  []
);

export default combineReducers({
  [LIST]: patentsReducer,
  [FILTERS]: filterReducer,
  [REPORTS]: reportsReducer,
});
