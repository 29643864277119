import React, { useCallback, useMemo, useEffect } from 'react';
import { prop } from 'ramda';
import PropTypes from 'prop-types';

import { NAME, KIND, useOrganization, useOrganizationActions, useAlerts } from 'store/organizations';
import { usePermissions, ORGANIZATION } from 'permissions';
import PageHeader from 'components/PageHeader';
import Spinner from 'components/FullScreenSpinner';

import OrganizationForm from './OrganizationForm';
import DeleteSection from './DeleteSection';
import { LABELS } from './utils';
import { Container } from './styles';

const Organization = ({ history, match, location }) => {
  const { id } = match.params;
  const { loading, organization } = useOrganization(id);
  const can = usePermissions();
  const { removeOrganization } = useOrganizationActions();
  const { action, loading: deleteLoading, success } = useAlerts(removeOrganization);
  const onDelete = useCallback(() => action(id), [action, id]);

  const redirectToBack = useCallback(() => history.push('/organizations', location.state), [history, location.state]);

  const title = useMemo(
    () => organization && (prop(NAME, organization) || prop(prop(KIND, organization), LABELS) || 'Unbekannt'),
    [organization]
  );

  useEffect(() => {
    if (success) redirectToBack();
  }, [redirectToBack, success]);

  if (loading || deleteLoading) return <Spinner />;

  return (
    <Container>
      <PageHeader title={title} redirectToBack={redirectToBack} />
      {organization && <OrganizationForm organization={organization} />}
      {organization && can.delete(ORGANIZATION) && <DeleteSection kind={organization[KIND]} onDelete={onDelete} />}
    </Container>
  );
};

Organization.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }).isRequired,
};

export default Organization;
