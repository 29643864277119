import styled from 'styled-components';

import Msg from 'components/Message';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ mt = 20 }) => mt}px;
  margin-bottom: ${({ mb = 20 }) => mb}px;
  padding-top: 10px;
  border-top: 1px solid var(--color-grey97);
`;

export const SectionLabel = styled.label`
  align-self: flex-start;
  text-align: left;
  color: var(--color-grey6A);
  font-size: var(--font-size15);
  font-weight: var(--font-weight600);
  margin-bottom: 30px;
`;

export const PreviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ReportsContainer = styled.div`
  & > div {
    margin-bottom: 10px;
  }
`;

export const Message = styled(Msg)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
`;
