import styled from 'styled-components';

import Msg from 'components/Message';
import DefWarning from 'components/Warning';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ mt = 20 }) => mt}px;
  margin-bottom: ${({ mb = 20 }) => mb}px;
  padding-top: 10px;
  border-top: 1px solid var(--color-grey97);
`;

export const SectionLabel = styled.label`
  align-self: flex-start;
  text-align: left;
  color: var(--color-grey6A);
  font-size: var(--font-size15);
  font-weight: var(--font-weight600);
  margin-bottom: 10px;
`;

export const ScrollContainer = styled.div`
  overflow-y: auto;
  width: calc(100% + ${({ scrollWidth }) => scrollWidth}px + 10px);
  height: 300px;
  padding-right: 10px;
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--color-grey97);
`;

export const Message = styled(Msg)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
`;

export const Warning = styled(DefWarning)`
  margin: -30px 0 30px;
`;

export const Span = styled.span`
  display: block;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 0.3em;
  &:last-of-type {
    margin-bottom: 0;
  }
`;
