import React, { useState, useCallback, useEffect } from 'react';
import { useField } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { pathOr, propEq, pipe, head, prop } from 'ramda';
import PropTypes from 'prop-types';

import {
  ID,
  RIGHT,
  LEFT,
  VIEW,
  FUNDUS,
  FILES,
  STATUS,
  EXAMINATION_STATUS,
  useWebsocketActions,
  useExaminationsActions,
  useAlerts,
} from 'store/examinations';
import { EXAMINATION_REAL_TIME, usePermissions } from 'permissions';
import QuestionContainer from 'components/QuestionContainer';
import Button from 'components/Button';
import Confirm from 'components/Confirm';
import Message from 'components/Message';

const Submitting = ({ examinationId, examinationStatus, readMode }) => {
  const can = usePermissions();
  const [offlineWarn, setOfflineWarn] = useState(false);
  const { updateExaminationProgress } = useWebsocketActions(can.update(EXAMINATION_REAL_TIME));
  const { removeExamination, setCurrentExamination, updateExaminations } = useExaminationsActions();
  const { loading, success, error, extractId } = useAlerts();
  const [{ value: right }] = useField(RIGHT);
  const [{ value: left }] = useField(LEFT);
  const { push } = useHistory();
  const { id } = useParams();

  const checkOfflineFiles = useCallback(
    () =>
      [right, left]
        .reduce((acc, item) => [...acc, ...pathOr([], [VIEW, FILES], item), ...pathOr([], [FUNDUS, FILES], item)], [])
        .some(propEq(true, 'offline')),
    [right, left]
  );
  const setStatus = useCallback(
    (status) => {
      const hasOfflineFiles = checkOfflineFiles();

      if (hasOfflineFiles) {
        setOfflineWarn(true);
      } else {
        updateExaminationProgress({ [ID]: examinationId, [STATUS]: status });
      }
    },
    [examinationId, updateExaminationProgress, checkOfflineFiles]
  );
  const handleSubmit = useCallback(() => setStatus(EXAMINATION_STATUS.SUBMITTED), [setStatus]);
  const handleAbort = useCallback(
    () => extractId(removeExamination(examinationId)),
    [extractId, removeExamination, examinationId]
  );

  useEffect(() => {
    setOfflineWarn(($) => ($ ? !$ : $));
  }, [examinationId, readMode, right, left]);

  useEffect(() => {
    if (success) {
      setCurrentExamination(pipe(head, prop(ID))(success));
      updateExaminations(success);
      push(`/patients/${id}/personal`);
      window?.scrollTo(0, 0);
    }
  }, [id, push, setCurrentExamination, success, updateExaminations]);

  if (readMode) return null;

  return (
    <>
      <QuestionContainer label="Freigeben">
        <Button type="button" onClick={handleSubmit} color="success">
          Zum Befunden freigeben
        </Button>
        {offlineWarn && (
          <Message type="error">
            Der Untersuch kann nicht übermittelt werden, solange nicht alle Bilder vom Offline-Modus hochgeladen wurden.
          </Message>
        )}
      </QuestionContainer>
      {examinationStatus === EXAMINATION_STATUS.IN_PREPARATION && (
        <QuestionContainer label="Untersuch löschen">
          <Confirm title="Löschen" onClick={handleAbort} width="200px" disabled={Boolean(loading || success)} />
          {error && (
            <Message type="error" form={1} textAlign="right">
              {error}
            </Message>
          )}
        </QuestionContainer>
      )}
    </>
  );
};

Submitting.propTypes = {
  examinationId: PropTypes.string.isRequired,
  examinationStatus: PropTypes.number.isRequired,
  readMode: PropTypes.bool.isRequired,
};

export default Submitting;
