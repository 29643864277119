import { propOr, pathOr, pipe, take, all, is, cond, equals, T, any } from 'ramda';

import { ID, NAME, GROUP, CANTONS, YEAR, DATA, DIABETES_SHARE, AGE, DEMENTIA_SHARE, CATARACT_SHARE, AMD_SHARE } from '.';

export const isReversedAxis = (type) => type === AGE;

export const isLogarithmicValue = (type) => any(equals(type), [DIABETES_SHARE, DEMENTIA_SHARE, CATARACT_SHARE, AMD_SHARE]);

export const getValue = cond([
  [isLogarithmicValue, (_, value) => value * 100],
  [T, (_, value) => value],
]);

export const getAxisRatio = (type, value, min, max) =>
  cond([
    [equals(AGE), () => (100 - value) / (max - min)],
    [isLogarithmicValue, () => value && (Math.log1p(value) - min) / (max - min)],
    [T, () => (value - min) / (max - min)],
  ])(type);

export const getMinMaxByKey = cond([
  [equals(AGE), () => [50, 100]],
  [isLogarithmicValue, (_, list) => [Math.log1p(Math.min(...list)), Math.log1p(Math.max(...list))]],
  [T, (_, list) => [Math.min(...list), Math.max(...list)]],
]);

const getItem = ([x, y, z, data = {}], index, params) => ({
  id: data[ID] || String(index),
  name: propOr('', NAME, data),
  group: propOr('', GROUP, data),
  color: pathOr('', [data[GROUP], 'color'], CANTONS),
  x: getValue(params[0], x),
  y: getValue(params[1], y),
  z: getValue(params[2], z),
});
const isValid = pipe(take(3), all(is(Number)));

export const handleAnalytics = (params, list = []) =>
  list.map(({ [YEAR]: year, [DATA]: data }) => ({
    year,
    items: data.filter(isValid).map((v, i) => getItem(v, i, params)),
  }));
