import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Label, Container, Thumb } from './styles';

const Switcher = ({ label, checked, onChange, allowChange, className }) => {
  const handleClick = useCallback(() => {
    if (!allowChange) return;
    onChange(checked);
  }, [allowChange, onChange, checked]);

  return (
    <Wrapper className={className}>
      <Label allowChange={allowChange}>{label}</Label>
      <Container type="button" onClick={handleClick} checked={checked} allowChange={allowChange}>
        <Thumb checked={checked} />
      </Container>
    </Wrapper>
  );
};

Switcher.defaultProps = {
  checked: false,
  className: '',
};

Switcher.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  allowChange: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default Switcher;
