import React, { useMemo } from 'react';

import Spinner from 'components/FullScreenSpinner';
import QuestionContainer from 'components/QuestionContainer';
import { EXAMINATION, usePermissions } from 'permissions';
import { useExaminationTasks, useExaminations } from 'store/examinations';

import { extractData, filterInProgress } from './utils';
import { Message } from './styles';
import Item from './Item';

const ExaminationsTasks = () => {
  const can = usePermissions();
  const { loading, success, error } = useExaminationTasks(can.read(EXAMINATION));
  const examinations = useExaminations();
  const values = useMemo(() => extractData(filterInProgress(examinations)), [examinations]);

  if (loading || !(success || error)) return <Spinner />;

  if (error) return <Message type="error">{error}</Message>;

  return (
    <QuestionContainer label="Patienten im Untersuch">
      {!values.length && (
        <Message type="error">Sobald bei einem Untersuch eine Aufgabe erledigt wird, erscheint der Patient hier.</Message>
      )}
      {values.map((item) => (
        <Item key={item.id} {...item} />
      ))}
    </QuestionContainer>
  );
};

export default ExaminationsTasks;
