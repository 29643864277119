import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 30px;
  width: ${({ width }) => width};
`;

export const ItemList = styled.div`
  border-radius: var(--border-radius8);
  background-color: #f5f5f5;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const Title = styled.h1`
  font-size: var(--font-size18);
  color: var(--color-grey6A);
  font-weight: var(--font-weight400);
  line-height: 1.22;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  margin-left: 22px;
`;
