import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { pipe, prop, applySpec, propOr, pathOr, ifElse, propIs } from 'ramda';

import { saveDiagnosis } from 'store/diagnoses/actions';
import { getCurrentDiagnosis, getCurrentDiagnosisProp } from 'store/diagnoses/selectors';
import {
  ID,
  ASSESSMENT,
  PROCEDURE,
  SUB_DIAGNOSES_CODES_EXTERN,
  SUB_DIAGNOSES_CODES_INTERN,
  VALUE,
  COMMENT,
  PREVENT_ANALYSIS,
  TAKE_LAST_DIAGNOSIS,
  DIAGNOSIS,
  LEFT,
  RIGHT,
  EYE,
  DIABETES,
  DIABETES_TYPE,
  INSULIN,
  PREVENT_ANALYSIS_REPORT,
} from 'store/diagnoses';

import { extractFields } from './utils';
import RecommendationForm from './component';

const mapPropsToValues = ({ diagnosisData }) => ({
  [ASSESSMENT]: propOr('', ASSESSMENT, diagnosisData),
  [PROCEDURE]: {
    [VALUE]: pathOr(0, [PROCEDURE, VALUE], diagnosisData),
    [COMMENT]: pathOr('', [PROCEDURE, COMMENT], diagnosisData),
  },
  [SUB_DIAGNOSES_CODES_EXTERN]: propOr([], SUB_DIAGNOSES_CODES_EXTERN, diagnosisData),
  [SUB_DIAGNOSES_CODES_INTERN]: propOr([], SUB_DIAGNOSES_CODES_INTERN, diagnosisData),
  [PREVENT_ANALYSIS]: ifElse(propIs(Boolean, PREVENT_ANALYSIS), prop(PREVENT_ANALYSIS), () => null)(diagnosisData),
  [PREVENT_ANALYSIS_REPORT]: ifElse(
    propIs(Boolean, PREVENT_ANALYSIS_REPORT),
    prop(PREVENT_ANALYSIS_REPORT),
    () => null
  )(diagnosisData),
  [TAKE_LAST_DIAGNOSIS]: propOr(false, TAKE_LAST_DIAGNOSIS, diagnosisData),
  [DIAGNOSIS]: extractFields(diagnosisData),
});

const sendData = (values, { updateDiagnosis, diagnosisId, diagnosisData }) => {
  updateDiagnosis({
    diagnosisId,
    ...values,
    [DIAGNOSIS]: [
      {
        [EYE]: LEFT,
        ...values[DIAGNOSIS][LEFT],
      },
      {
        [EYE]: RIGHT,
        ...values[DIAGNOSIS][RIGHT],
      },
    ],
    [DIABETES]: values[DIAGNOSIS][LEFT][DIABETES],
    [DIABETES_TYPE]: values[DIAGNOSIS][LEFT][DIABETES_TYPE],
    [INSULIN]: values[DIAGNOSIS][LEFT][INSULIN],
  });

  return diagnosisData[TAKE_LAST_DIAGNOSIS] && values[TAKE_LAST_DIAGNOSIS] ? { [TAKE_LAST_DIAGNOSIS]: true } : {};
};

const mapStateToProps = applySpec({
  diagnosisId: getCurrentDiagnosisProp(ID),
  diagnosisData: getCurrentDiagnosis,
});

const mapDispatchToProps = { updateDiagnosis: saveDiagnosis };

export default pipe(
  withFormik({
    mapPropsToValues,
    validate: sendData,
    handleSubmit: (_, { setSubmitting }) => {
      setSubmitting(false);
    },
    validateOnBlur: false,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(RecommendationForm);
