import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  column-gap: 40px;
  margin: 15px 0;
  & > div {
    flex: 1 1 auto;
    margin: 0;
  }
`;

export const Label = styled.p`
  line-height: 40px;
  color: var(--color-grey6A);
`;

export const Text = styled(motion.p).attrs(({ $isOpen }) => ({
  animate: { height: $isOpen ? 'auto' : 0 },
  transition: { duration: 0.5 },
  initial: false,
}))`
  font-size: var(--font-size18);
  font-weight: var(--font-weight400);
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: var(--color-grey97);
  margin: 20px 0 30px;
  overflow: hidden;
  & > span {
    font-weight: var(--font-weight600);
  }
`;
