export const BRIGHTNESS = 'brightness';
export const CONTRAST = 'contrast';
export const SCALE = 'scale';
export const TRANSLATE = 'translate';

export const MIN = 'min';
export const MAX = 'max';
export const STEP = 'step';

export const ANIMATION_TIME = 75;

export const filtersData = {
  [BRIGHTNESS]: {
    [MIN]: 20,
    [STEP]: 0.1,
    [MAX]: 500,
  },
  [CONTRAST]: {
    [MIN]: 20,
    [STEP]: 0.1,
    [MAX]: 500,
  },
  [SCALE]: {
    [MIN]: 0.2,
    [STEP]: 0.1,
    [MAX]: 5,
  },
};
