import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  margin-top: ${({ $mt }) => $mt}px;
`;

export const DownloadBtn = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  height: 100px;
  border: 1px solid;
  border-color: var(--color-greyEC);
  border-radius: var(--border-radius8);
  background-color: var(--color-greyEC);
  padding: 0 20px;
  outline: none;
  cursor: pointer;
  transition: all 0.5s;
  & > span {
    flex: 1 1 auto;
    font-size: var(--font-size13);
    font-weight: var(--font-weight500);
    color: var(--color-grey6A);
    letter-spacing: 0.9px;
    line-height: 1.15;
    text-transform: uppercase;
  }
  @media (hover) {
    &:hover:enabled {
      border-color: var(--color-grey97);
      background-color: var(--color-white);
    }
  }
  &:disabled {
    border-color: var(--color-grey97);
    background-color: var(--color-white);
    cursor: not-allowed;
  }
  &:focus {
    box-shadow: var(--shadow-focus);
  }
`;

export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  min-width: 46px;
  height: 46px;
  border: 2px solid;
  border-radius: 50%;
  border-color: #21ea9f;
`;
