import styled from 'styled-components';

import arrow from './arrow.svg';

export const Container = styled.select`
  height: 26px;
  border: 1px solid var(--color-black);
  border-radius: var(--border-radius8);
  font-size: var(--font-size13);
  color: var(--color-black);
  padding: 0 20px 0 8px;
  background-image: url(${arrow});
  background-position: right;
  background-repeat: no-repeat;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
  &:disabled {
    opacity: 1;
    font-weight: var(--font-weight300);
    cursor: not-allowed;
  }
`;
