import React, { useCallback, useMemo, Fragment } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useUpdatedRegistration, usePlanningActions, ID } from 'store/planning';
import { FIRST_NAME, LAST_NAME, BIRTH } from 'store/patients';
import Checkbox from 'components/CheckboxNew';

import { COMPARING, CHECKBOXES } from './consts';
import OrganizationChecker from './OrganizationChecker';
import { Container, Wrapper, CheckboxWrap, Text, NavLink } from './styles';

const Popup = ({ id, status, confidence, patient, organization, firstName, lastName, birth, readMode }) => {
  const { id: scheduleId } = useParams();
  const { pathname } = useLocation();
  const updatedStatus = useUpdatedRegistration(scheduleId, id);
  const { setNewRegistration } = usePlanningActions();
  const onChange = useCallback(
    ({ currentTarget }) => setNewRegistration({ [ID]: scheduleId, [id]: Number(currentTarget.value) }),
    [setNewRegistration, scheduleId, id]
  );
  const linkProps = useMemo(
    () => (patient ? { to: { pathname: `/patients/${patient[ID]}`, state: { previousPath: pathname } } } : { to: pathname }),
    [pathname, patient]
  );
  const mistakes = useMemo(
    () =>
      patient && status === 1 && confidence < 1
        ? [firstName, lastName, birth].reduce((acc, val, i) => {
            const result = COMPARING[i](val, patient);

            if (result) acc.push(result);

            return acc;
          }, [])
        : [],
    [birth, confidence, firstName, lastName, patient, status]
  );

  return (
    <Container>
      {(!readMode && (status === 0 || !patient) && (
        <CheckboxWrap>
          <Checkbox
            value={3}
            selected={updatedStatus === 3}
            label="Neuer Patient"
            onChange={onChange}
            fontSize="var(--font-size10)"
            mr={0}
            mb={0}
          />
        </CheckboxWrap>
      )) ||
        ((status === 3 || status === 2 || (status === 1 && confidence === 1)) && (
          <NavLink {...linkProps}>Zum Patientenprofil</NavLink>
        )) || (
          <>
            <Wrapper>
              {mistakes.map(([key, name, value]) => (
                <Fragment key={key}>
                  <Text>{name}</Text>
                  <Text $isRed>{value}</Text>
                </Fragment>
              ))}
              <OrganizationChecker organization={organization} hasMistakes={mistakes.length > 0} />
            </Wrapper>
            {!readMode && (
              <CheckboxWrap>
                {CHECKBOXES.map(({ value, name }) => (
                  <Checkbox
                    key={value}
                    value={value}
                    selected={updatedStatus === value}
                    label={name}
                    onChange={onChange}
                    fontSize="var(--font-size10)"
                    mr={0}
                    mb={0}
                  />
                ))}
              </CheckboxWrap>
            )}
            <NavLink {...linkProps}>Zum Patientenprofil</NavLink>
          </>
        )}
    </Container>
  );
};

Popup.defaultProps = { patient: null, organization: null, firstName: null, lastName: null, birth: null };
Popup.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  confidence: PropTypes.number.isRequired,
  patient: PropTypes.shape({
    [ID]: PropTypes.string.isRequired,
    [FIRST_NAME]: PropTypes.string.isRequired,
    [LAST_NAME]: PropTypes.string.isRequired,
    [BIRTH]: PropTypes.string,
  }),
  organization: PropTypes.shape({}),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  birth: PropTypes.string,
  readMode: PropTypes.bool.isRequired,
};

export default Popup;
