import { string, array, number, boolean, object } from 'yup';
import { applySpec, propOr, prop, omit } from 'ramda';

import { TITLE, FIRST_NAME, LAST_NAME, GENDER, EMAIL } from 'store/patients';
import { ROLES, STATUS, HAS_2F } from 'store/session';

export const getValues = applySpec({
  [TITLE]: propOr('', TITLE),
  [FIRST_NAME]: propOr('', FIRST_NAME),
  [LAST_NAME]: propOr('', LAST_NAME),
  [GENDER]: propOr('', GENDER),
  [EMAIL]: propOr('', EMAIL),
  [ROLES]: prop(ROLES),
  [STATUS]: propOr(null, STATUS),
  [HAS_2F]: propOr(false, HAS_2F),
});

export const validationObject = {
  [FIRST_NAME]: string().trim().required('Vorname fehlt'),
  [LAST_NAME]: string().trim().required('Nachname fehlt'),
  [EMAIL]: string().trim().customEmail('E-Mail-Adresse ist ungültig.').required('E-Mail-Adresse fehlt.'),
  [ROLES]: array(string())
    .typeError('Wählen Sie mindestens eine Berechtigung aus')
    .min(1, 'Wählen Sie mindestens eine Berechtigung aus'),
  [STATUS]: number().required('Status festlegen').typeError('Status festlegen'),
  [HAS_2F]: boolean().required('Legen Sie zwei Faktoroptionen fest').typeError('Legen Sie zwei Faktoroptionen fest'),
};

export const validationSchema = object(omit([STATUS, HAS_2F], validationObject));

export const STATUS_OPTIONS = [
  { id: 1, jsonId: JSON.stringify(1), label: 'Aktiv' },
  { id: 0, jsonId: JSON.stringify(0), label: 'Inaktiv' },
];

export const TWO_FACTOR_OPTIONS = [
  { id: true, jsonId: JSON.stringify(true), label: 'Aktiv' },
  { id: false, jsonId: JSON.stringify(false), label: 'Inaktiv' },
];

export const emailError = 'Es existiert bereits ein Benutzerkonto mit dieser E-Mail-Adresse.';
