import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { isEmpty } from 'ramda';

import { usePlanningActions, useAlerts } from 'store/planning';
import { PLANNING, usePermissions } from 'permissions';
import { useAsyncState } from 'utils/useAsyncState';
import Spinner from 'components/FullScreenSpinner';
import Switcher from 'components/Switcher';
import Accordion from 'components/Accordion';
import Table from 'components/Table';
import Message from 'components/Message';
import AddButtonIcon from 'assets/svg-icons/AddButtonIcon';

import { DEFAULT_SORT, TABLE_HEAD } from './utils';
import { useData } from './hooks';
import Form from './Form';
import Row from './Row';
import Steps from './Steps';
import UploadAndSubmit from './UploadAndSubmit';
import UnsubscribeTable from './UnsubscribeTable';
import RegistrationForm from './RegistrationForm';
import { Container, Header, Text, NavLink, Status } from './styles';

const Edit = () => {
  const { push } = useHistory();
  const { id, patientId } = useParams();
  const can = usePermissions();
  const [sort, setSort] = useState(DEFAULT_SORT);
  const [errors, setErrors] = useAsyncState({});
  const [dateError, setDateError] = useState();
  const [regDateErr, setRegDateErr] = useState();
  const [unsubscribeTableKey, setUnsubscribeTableKey] = useAsyncState(0);
  const {
    isReady,
    isLoading,
    isAllBlocked,
    dataError,
    status,
    title,
    shifts,
    patients,
    patientsObject,
    statusData,
    editor,
    modified,
  } = useData(sort);
  const [readMode, setReadMode] = useState(true);
  const { saveSchedule } = usePlanningActions();
  const { action, loading, error } = useAlerts(saveSchedule);
  const redirectToBack = useCallback(() => push('/planning'), [push]);
  const renderRow = useCallback(($) => <Row {...$} readMode={Boolean(isLoading || loading)} />, [isLoading, loading]);

  useEffect(() => {
    if (isReady) setReadMode(!(can.update(PLANNING) && status < 3));
  }, [can, isReady, status]);

  if (dataError)
    return (
      <Message type="error" textAlign="center">
        {dataError}
      </Message>
    );

  if (!isReady) return <Spinner height="calc(100vh - 200px)" />;
  if (patientId && (patientsObject[patientId] || patientId === 'new')) {
    return <RegistrationForm key={patientId} data={patientsObject[patientId]} shifts={shifts} sort={sort} />;
  }
  if (!status) return <Steps />;

  return (
    <Container>
      <Header title={title} redirectToBack={redirectToBack}>
        <Status $color={statusData.color}>{statusData.label}</Status>
        <Switcher
          label="BEARBEITEN"
          checked={!readMode}
          onChange={setReadMode}
          allowChange={can.update(PLANNING) && status < 2}
        />
      </Header>
      <Text>
        Zul. bearbeitet von {editor} am {modified}
      </Text>
      <Form
        key={unsubscribeTableKey + 1}
        readMode={readMode}
        disabled={isLoading || loading}
        errors={errors}
        setErrors={setErrors}
        setDateError={setDateError}
        setRegistrationDateError={setRegDateErr}
        hasRegistrations={patients.length > 0}
      />
      <Accordion label="Geplante Messungen" defaultOpen>
        <NavLink to={`/planning/${id}/new`}>
          <span>Neue Registrierung</span>
          <AddButtonIcon />
        </NavLink>
        <Table
          head={TABLE_HEAD}
          list={patients}
          renderRow={renderRow}
          sort={sort}
          updateSort={setSort}
          loading={isLoading || loading}
        />
      </Accordion>
      <UnsubscribeTable key={unsubscribeTableKey} />
      {can.update(PLANNING) && (
        <UploadAndSubmit
          saveSchedule={action}
          saveError={error}
          setUnsubscribeTableKey={setUnsubscribeTableKey}
          disabled={isLoading || loading || Boolean(dateError || regDateErr) || !isEmpty(errors)}
          readMode={readMode}
          isAllRegistrationBlocked={isAllBlocked}
        />
      )}
    </Container>
  );
};

export default Edit;
