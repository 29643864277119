export const POSITION_TITLES = [
  { id: '', label: '-' },
  { id: 'dr', label: 'Dr.' },
  { id: 'drmed', label: 'Dr. med.' },
  { id: 'drmeduniv', label: 'Dr. med. univ.' },
  { id: 'pd', label: 'PD Dr.' },
  { id: 'pdmed', label: 'PD Dr. med.' },
  { id: 'professor', label: 'Prof.' },
  { id: 'professormed', label: 'Prof. Dr. med.' },
  { id: 'candmed', label: 'KD Dr. med.' },
];

export const POSITION_MAP = POSITION_TITLES.reduce((acc, { id, label }) => {
  if (id) acc[id] = label;
  return acc;
}, {});

export const GENDERS = {
  MALE: 'male',
  FEMALE: 'female',
  OTHER: 'other',
};

export const SALUTATION = {
  [GENDERS.MALE]: 'Herr',
  [GENDERS.FEMALE]: 'Frau',
};

export const imageFilter = {
  brightness: { min: 20, step: 1, max: 500 },
  contrast: { min: 20, step: 1, max: 500 },
};

export const GENDER_RADIOS = [
  { label: 'Männlich', value: GENDERS.MALE },
  { label: 'Weiblich', value: GENDERS.FEMALE },
  { label: 'Anderes', value: GENDERS.OTHER },
];
