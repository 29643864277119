import React from 'react';
import PropTypes from 'prop-types';

import { Label, Wrapper } from './styles';

const Section = ({ label, children }) => (
  <div>
    <Label>{label}</Label>
    <Wrapper>{children}</Wrapper>
  </div>
);

Section.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Section;
