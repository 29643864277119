import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { reducerTemplate } from 'store/utils';

import {
  ID,
  CURRENT,
  LIST,
  BLOCKED,
  FILTERS,
  SEARCH,
  SORT,
  SORT_BY,
  SORT_DIR,
  PAGINATION,
  CURSOR,
  LIMIT,
  COUNT,
  HAS_MORE,
  EXAMINATION_STATUS,
} from '.';
import {
  UPDATE_SEARCH,
  UPDATE_SORT,
  UPDATE_PAGINATION,
  RESET_FILTERS,
  SET_CURRENT_EXAMINATION,
  UPDATE_EXAMINATIONS,
  RESET_EXAMINATIONS,
  UPDATE_EXAMINATION,
  DELETE_EXAMINATION,
  UPDATE_BLOCKED_EXAMINATIONS,
} from './types';

const currentExaminationReducer = handleActions(
  {
    [SET_CURRENT_EXAMINATION]: (_, { payload }) => payload || null,
  },
  null
);

const examinationsReducer = reducerTemplate(
  {
    id: ID,
    list: UPDATE_EXAMINATIONS,
    item: UPDATE_EXAMINATION,
    deleteItem: DELETE_EXAMINATION,
    reset: RESET_EXAMINATIONS,
  },
  {}
);

const blockedExaminationsReducer = handleActions(
  {
    [UPDATE_BLOCKED_EXAMINATIONS]: (_, { payload }) => payload || null,
  },
  null
);

const initFilter = {
  [SEARCH]: '',
  [SORT]: {
    [SORT_BY]: '',
    [SORT_DIR]: '',
  },
  [PAGINATION]: {
    [CURSOR]: 0,
    [LIMIT]: 10,
    [COUNT]: 0,
    [HAS_MORE]: false,
  },
};

const { IN_PREPARATION, SUBMITTED, UNDER_REVIEW, IN_APPROVAL } = EXAMINATION_STATUS;

const initFilters = {
  [IN_PREPARATION]: initFilter,
  [SUBMITTED]: initFilter,
  [UNDER_REVIEW]: initFilter,
  [IN_APPROVAL]: initFilter,
};

const filterReducer = handleActions(
  {
    [UPDATE_SEARCH]: (state, { payload }) => {
      const { kind, value } = payload;
      return {
        ...state,
        [kind]: {
          ...state[kind],
          [SEARCH]: value,
          [PAGINATION]: {
            ...state[kind][PAGINATION],
            [CURSOR]: 0,
          },
        },
      };
    },
    [UPDATE_SORT]: (state, { payload }) => {
      const { kind, value } = payload;
      return {
        ...state,
        [kind]: {
          ...state[kind],
          [SORT]: value,
          [PAGINATION]: {
            ...state[kind][PAGINATION],
            [CURSOR]: 0,
          },
        },
      };
    },
    [UPDATE_PAGINATION]: (state, { payload }) => {
      const { kind, value } = payload;
      return {
        ...state,
        [kind]: {
          ...state[kind],
          [PAGINATION]: {
            ...state[kind][PAGINATION],
            ...value,
          },
        },
      };
    },
    [RESET_FILTERS]: (state, { payload }) =>
      payload
        ? {
            ...state,
            [payload]: initFilters[payload],
          }
        : initFilters,
  },
  initFilters
);

export default combineReducers({
  [CURRENT]: currentExaminationReducer,
  [LIST]: examinationsReducer,
  [BLOCKED]: blockedExaminationsReducer,
  [FILTERS]: filterReducer,
});
