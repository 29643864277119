import React from 'react';

import Input2FA from 'components/MultiInputCode';

import AuthStep1 from 'assets/svg-icons/AuthStep1';
import AuthStep2 from 'assets/svg-icons/AuthStep2';
import Spinner from 'components/FullScreenSpinner';

import QRCode from './QRCode';
import { Button, Confirm, LabelContainer, Label, ActiveLabel, MessageContainer, Message } from './styles';

export const generateWith2FA = (reset2FA, loading, error) => [
  {
    key: 1,
    component: (
      <LabelContainer>
        <Label>Status</Label>
        <ActiveLabel disabled={loading}>{loading ? <Spinner size={40} height="50px" /> : 'aktiv'}</ActiveLabel>
      </LabelContainer>
    ),
    title: 'Zwei-Faktor-Authentifizierung aktiviert',
    texts: [
      'Sie haben die Zwei-Faktor-Authentifizierung für Ihren Augenmobil-Account erfolgreich aktiviert.',
      'Sie können sich ab sofort mit Ihrer E-Mail-Adresse und Ihrem Passwort bei Augenmobil anmelden. Geben Sie anschliessend jeweils den 6-stelligen Code ein, der in der Google Authenticator App erscheint.',
    ],
    buttons: (
      <div>
        <Confirm
          title="gerät deaktivieren"
          question="Sind Sie sich wirklich sicher?"
          onClick={reset2FA}
          width="350px"
          disabled={loading}
        />
        {error && <Message type="error">{error}</Message>}
      </div>
    ),
  },
];

export const generateWithout2FA = (next, prev, apply2FA, validateAction, loading, error) => [
  {
    key: 1,
    component: <AuthStep1 />,
    title: 'Zwei-Faktor-Authentifizierung für Augenmobil',
    texts: [
      'Die Zwei-Faktor-Authentifizierung bietet eine zusätzliche Sicherheitsebene für Ihren Augemobil-Account. Mit ihr wird sichergestellt, dass nur Sie auf Ihren Account zugreifen können, auch wenn eine andere Person Ihr Passwort kennt.',
    ],
    buttons: (
      <>
        <div />
        <Button color="normal" onClick={next}>
          jetzt aktivieren
        </Button>
      </>
    ),
  },
  {
    key: 2,
    component: <AuthStep2 />,
    title: 'Google Authenticator App',
    texts: [
      'Mit der Google Authenticator App können Sie Ihr Smartphone oder Ihr Tablet als vertrauenswürdiges Gerät identifizieren. Laden Sie mit Ihrem mobilen Gerät die Google Authenticator App vom App Store oder vom Google Play Store.',
      'Starten Sie die App und überspringen Sie die Anleitung, bis Sie ein Konto hinzufügen können.',
    ],
    buttons: (
      <>
        <Button onClick={prev}>zurück</Button>
        <Button color="normal" onClick={next}>
          app installiert
        </Button>
      </>
    ),
  },
  {
    key: 3,
    component: <QRCode />,
    title: 'QR Code scannen',
    texts: [
      'Scannen Sie den hier angezeigten QR Code mit dem Barcode-Scanner der Google Authenticator App. Anschliessend erscheint ein neuer Eintrag «Augenmobil» in der Google Authenticator App.',
    ],
    buttons: (
      <>
        <Button onClick={prev}>zurück</Button>
        <Button color="normal" onClick={next}>
          barcode gescannt
        </Button>
      </>
    ),
  },
  {
    key: 4,
    component: <Input2FA onChange={apply2FA} validateAction={validateAction} />,
    title: 'Code eingeben',
    texts: ['Geben Sie den 6-stelligen Code ein, der im Eintrag «Augenmobil» in der Google Authenticator App erscheint.'],
    buttons: (
      <>
        <Button onClick={prev}>zurück</Button>
        <MessageContainer>
          {loading && <Spinner size={30} height="40px" />}
          {error && <Message type="error">{error}</Message>}
        </MessageContainer>
      </>
    ),
  },
];
