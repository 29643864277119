import styled from 'styled-components';

export const Text = styled.p`
  font-size: var(--font-size12);
  font-weight: var(--font-weight500);
  line-height: 1.83;
  letter-spacing: 0.33px;
  text-align: center;
  color: var(--color-grey6A);
`;
