import styled from 'styled-components';

import AnimateBox from 'components/AnimateBox';

export const CoupleWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const EyeIconContainer = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: ${({ selected }) => (selected ? '1.5px solid var(--color-blue50)' : '1px solid #858585')};
  border-radius: var(--border-radius8);
  width: calc(50% - 7px);
  background: ${({ selected }) => (selected ? 'rgba(173, 248, 231, 0.11)' : 'rgba(174, 174, 174, 0.11)')};
  padding: 30px;
  margin-bottom: 20px;
  outline: none;
  cursor: pointer;
  & * {
    transition: all 0.3s;
  }
`;

export const EyeIconLabel = styled.p`
  font-size: var(--font-size27);
  line-height: 0.81;
  color: ${({ selected }) => (selected ? 'var(--color-blue50)' : 'var(--color-grey97)')};
  margin-bottom: 20px;
`;

export const Content = styled(AnimateBox)`
  width: 100%;
  padding: 3px;
  overflow: hidden;
`;
