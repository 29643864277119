/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';

import { useInitCantonDashboard, useInvoicesActions } from 'store/invoices';
import Select from 'components/SelectNew';
import Message from 'components/Message';
import Spinner from 'components/FullScreenSpinner';
import KPI from '../KPIValue';

import { CANTON_OPTIONS, YEAR_OPTIONS } from './consts';
import { Container, Label, SelectWrap, Content } from './styles';

const CantonDashboard = () => {
  const { list, canton, year, loading, error } = useInitCantonDashboard();
  const { updateCanton, updateYear } = useInvoicesActions();
  const onSelectYear = useCallback((value) => updateYear(+value), [updateYear]);

  return (
    <Container>
      <Label>Jahreszahlen Kanton</Label>
      <SelectWrap>
        <Select value={canton} options={CANTON_OPTIONS} onSelect={updateCanton} />
        <Select value={year} options={YEAR_OPTIONS} onSelect={onSelectYear} />
      </SelectWrap>
      {(loading && <Spinner height="250px" />) ||
        ((error || !list?.length) && (
          <Message type="error" textAlign="center">
            {error || 'Keine Daten vorhanden'}
          </Message>
        )) || (
          <Content>
            {list.map((kpi, index) => (
              <KPI
                key={index}
                label={kpi.labelLocale}
                value={kpi.value}
                start={kpi.start}
                end={kpi.end}
                prevStart={kpi.prevStart}
                prevEnd={kpi.prevEnd}
                prevValue={kpi.prevValue}
                period={kpi.period}
                unit={kpi.unit}
                layout="small"
              />
            ))}
          </Content>
        )}
    </Container>
  );
};

export default CantonDashboard;
