import React from 'react';
import PropTypes from 'prop-types';

import { Text } from './styles';

const MobilitySection = ({ value }) => <Text>{value || '–'}</Text>;

MobilitySection.defaultProps = { value: null };
MobilitySection.propTypes = { value: PropTypes.string };

export default MobilitySection;
