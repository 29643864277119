import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_INFO_ITEMS = getName('UPDATE_INFO_ITEMS');
export const UPDATE_INFO_ITEM = getName('UPDATE_INFO_ITEM');
export const DELETE_INFO_ITEM = getName('DELETE_INFO_ITEM');
export const RESET_INFO_ITEMS = getName('RESET_INFO_ITEMS');
export const UPDATE_SORT = getName('UPDATE_SORT');
export const UPDATE_PAGINATION = getName('UPDATE_PAGINATION');
export const UPLOAD_MEDIA = getName('UPLOAD_MEDIA');

export const FETCH_INFO_ITEMS = getName('FETCH_INFO_ITEMS');
export const FETCH_INFO_ITEM = getName('FETCH_INFO_ITEM');
export const CREATE_INFO_ITEM = getName('CREATE_INFO_ITEM');
export const SAVE_INFO_ITEM = getName('SAVE_INFO_ITEM');
export const REMOVE_INFO_ITEM = getName('REMOVE_INFO_ITEM');
