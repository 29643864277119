import { path, prop } from 'ramda';

import { FEATURE_NAME as $, FILTERS, CURRENT, LIST, BLOCKED } from './consts';

export const getExaminationsFilters = (kind) => path([$, FILTERS, kind]);
export const getExaminations = path([$, LIST]);
export const getExamination = (id) => path([$, LIST, id]);
export const getCurrentExaminationId = path([$, CURRENT]);
export const getCurrentExamination = (state) => getExamination(path([$, CURRENT], state))(state);
export const getCurrentExaminationProp = (name) => (state) => prop(name, getCurrentExamination(state));

export const getOwnerBlockedExaminations = (id) => path([$, BLOCKED, id, 'user']);
