import React, { forwardRef } from 'react';

import { Container, Ring } from './styles';

const Spinner = forwardRef((props, ref) => (
  <Container ref={ref} {...props}>
    <Ring {...props}>
      <div>
        <div />
      </div>
    </Ring>
  </Container>
));

export default Spinner;
