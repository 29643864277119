import qs from 'query-string';

import { YEAR } from 'store/invoices';

import { wrapperRequest } from './utils';
import { API_URL, POST, PATCH, SUB_PATIENT, SUB_INSURANCE, EXPAND, OWN_ERR_HANDLER } from './consts';

const defParams = { [EXPAND]: [SUB_PATIENT, SUB_INSURANCE] };

export const postInvoice = (body = {}) =>
  wrapperRequest({
    url: `${API_URL}/invoices?${qs.stringify(defParams)}`,
    options: { method: POST, body },
  });

export const getInvoices = (query = {}) =>
  wrapperRequest({
    url: `${API_URL}/invoices?${qs.stringify({ ...query, ...defParams })}`,
  });

export const getInvoice = (id) =>
  wrapperRequest({
    url: `${API_URL}/invoices/${id}?${qs.stringify(defParams)}`,
  });

export const patchInvoice = (id, body = {}) =>
  wrapperRequest({
    url: `${API_URL}/invoices/${id}?${qs.stringify(defParams)}`,
    options: { method: PATCH, body },
  });

export const sendInvoice = (id) =>
  wrapperRequest({
    url: `${API_URL}/invoices/${id}/send`,
    options: { method: POST },
  });

export const cancelInvoice = (id) =>
  wrapperRequest({
    url: `${API_URL}/invoices/${id}/cancel`,
    options: { method: POST },
  });

export const resendInvoice = (id) =>
  wrapperRequest({
    url: `${API_URL}/invoices/${id}/resend?${qs.stringify(defParams)}`,
    options: { method: POST },
  });

export const getTarmedPositions = (query = {}) =>
  wrapperRequest({
    url: `${API_URL}/tarmedpositions?${qs.stringify(query)}`,
  });

export const getDashboard = () =>
  wrapperRequest({
    url: `${API_URL}/dashboard`,
  });

export const getNursingHomeKPIs = (id) =>
  wrapperRequest({
    url: `${API_URL}/organizations/${id}/kpis`,
  });

export const downloadPdf = (id) =>
  wrapperRequest({
    url: `${API_URL}/invoices/${id}/pdf`,
    options: { method: POST, [OWN_ERR_HANDLER]: true },
  });

export const getCantonDashboard = (year, id) =>
  wrapperRequest({
    url: `${API_URL}/dashboard/cantons${id ? `/${id}` : ''}?${qs.stringify({ [YEAR]: year })}`,
    options: { [OWN_ERR_HANDLER]: true },
  });
