import styled from 'styled-components';

export const ScrollContainer = styled.div`
  width: calc(100% + ${({ $scrollWidth }) => $scrollWidth}px + 10px);
  height: 300px;
  overflow-y: auto;
  padding-right: 10px;
  margin-top: 20px;
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--color-grey97);
`;
