import { PUBLIC_ID, FIRST_NAME, BIRTH, STATUS } from 'store/patients';
import { SORT_BY, SORT_DIR, ASC } from 'store/planning';

export const DEFAULT_SORT = { [SORT_BY]: PUBLIC_ID, [SORT_DIR]: ASC };

export const TABLE_HEAD = [
  { id: PUBLIC_ID, content: 'ID', width: '8%', withoutSort: true },
  { id: FIRST_NAME, content: 'Name', width: '47%', withoutSort: true },
  { id: BIRTH, content: 'Geburtstag', width: '28%', withoutSort: true },
  { id: STATUS, content: 'Status Untersuch', width: '17%', withoutSort: true },
];
