import { useRef, useMemo, useCallback, useEffect } from 'react';
import { useMotionValue } from 'framer-motion';

export const useDragAndDrop = (containerRef, onDragEnd) => {
  const config = useRef({});
  const display = useMotionValue('none');
  const top = useMotionValue(0);
  const left = useMotionValue(0);
  const width = useMotionValue(0);
  const opacity = useMotionValue(1);

  const onHover = useCallback((x, y) => {
    config.current.dragBoxRef.style.display = 'none';

    const elemBelow = document.elementFromPoint(x, y);
    const droppableElem = elemBelow?.closest('.droppable_element');
    const goal = config.current.containerRef !== droppableElem && droppableElem;

    if (goal) {
      config.current.droppableElement = goal;
      goal.classList.add('droppableHover');
    }
    if (!goal && config.current.droppableElement) {
      config.current.droppableElement.classList.remove('droppableHover');
      config.current.droppableElement = null;
    }

    config.current.dragBoxRef.style.display = 'block';
  }, []);

  const onMouseDown = useCallback(
    (e) => {
      e.preventDefault();

      if (!(config.current.parentRef && config.current.dragBoxRef)) return;

      const rect = config.current.parentRef.getBoundingClientRect();
      top.set(rect.y);
      left.set(rect.x);
      width.set(rect.width);
      config.current.pageX = e.pageX;
      config.current.pageY = e.pageY;
      config.current.containerRef = containerRef.current;

      const onMouseMove = ({ pageX, pageY, clientX, clientY }) => {
        if (
          !config.current.startDrag &&
          (Math.abs(config.current.pageX - pageX) > 5 || Math.abs(config.current.pageY - pageY) > 5)
        ) {
          config.current.startDrag = true;
          opacity.set(0.2);
          display.set('block');
        }

        if (!config.current.startDrag) return;

        if (config.current.animationFrame) window.cancelAnimationFrame(config.current.animationFrame);

        config.current.animationFrame = window.requestAnimationFrame(() => {
          top.set(rect.y + pageY - config.current.pageY);
          left.set(rect.x + pageX - config.current.pageX);

          onHover(clientX, clientY);
        });
      };
      const onMouseUp = (event) => {
        event.preventDefault();
        event.stopPropagation();
        document.body.removeEventListener('mousemove', onMouseMove);
        document.body.removeEventListener('mouseup', onMouseUp);
        document.body.removeEventListener('mouseleave', onMouseUp);

        if (config.current.animationFrame) window.cancelAnimationFrame(config.current.animationFrame);

        display.set('none');
        config.current.startDrag = false;

        if (config.current.droppableElement) {
          config.current.droppableElement.classList.remove('droppableHover');
          onDragEnd(config.current.droppableElement.dataset?.ward || '');
        }

        opacity.set(1);
      };

      document.body.addEventListener('mousemove', onMouseMove);
      document.body.addEventListener('mouseup', onMouseUp);
      document.body.addEventListener('mouseleave', onMouseUp);
    },
    [containerRef, display, left, onDragEnd, onHover, opacity, top, width]
  );

  useEffect(
    () => () => {
      if (config.current.animationFrame) window.cancelAnimationFrame(config.current.animationFrame);
    },
    []
  );

  return {
    onMouseDown,
    parentRef: useCallback((ref) => {
      config.current.parentRef = ref;
    }, []),
    dragBoxRef: useCallback((ref) => {
      config.current.dragBoxRef = ref;
    }, []),
    containerStyle: useMemo(() => ({ opacity }), [opacity]),
    dragBoxStyle: useMemo(
      () => ({
        display,
        width,
        height: width,
        top,
        left,
      }),
      [display, left, top, width]
    ),
  };
};
