import React, { useCallback, useRef, useEffect } from 'react';
import { cond, equals, min, max, T } from 'ramda';
import PropTypes from 'prop-types';

import Input from '../RangeInput';
import { filtersData, BRIGHTNESS, CONTRAST, SCALE, MIN, STEP, MAX, ANIMATION_TIME } from '../consts';

import Plus from './ScaleInc';
import Minus from './ScaleDec';
import Close from './Close';
import { Container, Wrapper, InputContainer, Label, Button } from './styles';

const Panel = ({ brightness, contrast, scale, updateFilter, onClose, disabled }) => {
  const timer = useRef();
  const handleBrightness = useCallback(
    ({ target }) => {
      const { value } = target;
      if (!timer.current) {
        timer.current = setTimeout(() => {
          updateFilter(BRIGHTNESS, value);
          timer.current = 0;
        }, ANIMATION_TIME);
      }
    },
    [updateFilter]
  );
  const handleContrast = useCallback(
    ({ target }) => {
      const { value } = target;
      if (!timer.current) {
        timer.current = setTimeout(() => {
          updateFilter(CONTRAST, value);
          timer.current = 0;
        }, ANIMATION_TIME);
      }
    },
    [updateFilter]
  );
  const handleScale = useCallback(
    ({ currentTarget }) => {
      const { id } = currentTarget.dataset;
      if (!id) return;
      const step = filtersData[SCALE][STEP];
      const nextStep = cond([
        [equals('inc'), () => min(scale + step, filtersData[SCALE][MAX])],
        [equals('dec'), () => max(scale - step, filtersData[SCALE][MIN])],
        [T, () => scale],
      ])(id);
      updateFilter(SCALE, nextStep);
    },
    [scale, updateFilter]
  );

  useEffect(
    () => () => {
      if (timer.current) clearTimeout(timer.current);
    },
    []
  );

  return (
    <Container>
      <Wrapper>
        <InputContainer>
          <Label>HELLIGKEIT</Label>
          <Input
            type="range"
            value={brightness}
            onChange={handleBrightness}
            min={filtersData[BRIGHTNESS][MIN]}
            step={filtersData[BRIGHTNESS][STEP]}
            max={filtersData[BRIGHTNESS][MAX]}
            disabled={disabled}
          />
        </InputContainer>
        <InputContainer>
          <Label>KONTRAST</Label>
          <Input
            type="range"
            value={contrast}
            onChange={handleContrast}
            min={filtersData[CONTRAST][MIN]}
            step={filtersData[CONTRAST][STEP]}
            max={filtersData[CONTRAST][MAX]}
            disabled={disabled}
          />
        </InputContainer>
      </Wrapper>
      <Wrapper>
        <Button type="button" data-id="inc" onClick={handleScale} disabled={disabled}>
          <Plus />
        </Button>
        <Button type="button" data-id="dec" ml={5} onClick={handleScale} disabled={disabled}>
          <Minus />
        </Button>
        <Button type="button" ml={12} onClick={onClose}>
          <Close />
        </Button>
      </Wrapper>
    </Container>
  );
};

Panel.defaultProps = {
  brightness: '100',
  contrast: '100',
  scale: 1,
  disabled: false,
};

Panel.propTypes = {
  brightness: PropTypes.string,
  contrast: PropTypes.string,
  scale: PropTypes.number,
  updateFilter: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default Panel;
