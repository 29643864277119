import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { pathOr, pipe, pathEq, not } from 'ramda';
import PropTypes from 'prop-types';

import { FIRST_NAME, LAST_NAME, usePatient, GENDER } from 'store/patients';
import { PROCEDURE, COMMENT, VALUE, PROCEDURE_STATUS } from 'store/diagnoses';
import { SALUTATION } from 'utils/constants';

import { Btn } from './styles';

const Button = ({ title, text, procedureValue, width }) => {
  const { id } = useParams();
  const [firstName, lastName, gender] = usePatient(id, [FIRST_NAME, LAST_NAME, GENDER]);
  const { setValues } = useFormikContext();
  const patientName = useMemo(() => {
    if (!firstName || !lastName) return 'die Patientin / den Patienten';
    return `${SALUTATION[gender] ? `${SALUTATION[gender]} ` : ''}${firstName} ${lastName}`;
  }, [firstName, lastName, gender]);
  const handleClick = useCallback(() => {
    setValues(($) => ({
      ...$,
      [PROCEDURE]: {
        ...$[PROCEDURE],
        [COMMENT]: `${pathOr('', [PROCEDURE, COMMENT], $).trim()} ${text.replace('%PATIENT%', patientName)}`,
        ...(pipe(pathEq(PROCEDURE_STATUS.NO_TREATMENT, [PROCEDURE, VALUE]), not)($) && { [VALUE]: procedureValue }),
      },
    }));
  }, [patientName, procedureValue, setValues, text]);

  return (
    <Btn type="button" onClick={handleClick} width={width}>
      {title}
    </Btn>
  );
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  procedureValue: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default Button;
