import React, { useMemo, useCallback, useEffect } from 'react';
import { tail, omit, pipe, when, filter, complement, includes, __, uniq } from 'ramda';
import { useHistory, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { TITLE, FIRST_NAME, LAST_NAME, GENDER, EMAIL } from 'store/patients';
import { ID, ROLES, STATUS, HAS_2F } from 'store/session';
import { useBugReportsActions, useAlerts, ROLE_OPTIONS, ROLE_CONFIG } from 'store/bugReports';
import { POSITION_TITLES, GENDER_RADIOS } from 'utils/constants';
import { useAsyncState } from 'utils/useAsyncState';
import PageHeader from 'components/PageHeader';
import Section from 'components/QuestionContainer';
import Select from 'components/SelectNew';
import TextInput from 'components/TextInputNew';
import Radios from 'components/RadiosNew';
import CheckBox from 'components/CheckboxNew';
import Button from 'components/Button';
import Message from 'components/Message';
import Spinner from 'components/FullScreenSpinner';

import { getValues, validationObject, validationSchema, STATUS_OPTIONS, TWO_FACTOR_OPTIONS, emailError } from './utils';
import QuestionInput from './QuestionInput';
import { Container, Checkboxes, SpinnerWrap } from './styles';

const Form = ({ id, data }) => {
  const { push } = useHistory();
  const { createUser, saveUser } = useBugReportsActions();
  const { action: create, loading, success, error: createErr } = useAlerts(createUser);
  const { action: save, error: saveErr } = useAlerts(saveUser);
  const [values, setValues] = useAsyncState(useMemo(() => getValues(data || {}), [data]));
  const { [TITLE]: title, [FIRST_NAME]: firstName, [LAST_NAME]: lastNAme, [ROLES]: roles, [STATUS]: status } = values;
  const [errors, setErrors] = useAsyncState({});
  const titles = useMemo(() => (title ? POSITION_TITLES : tail(POSITION_TITLES)), [title]);
  const pageTitle = useMemo(
    () => [firstName.trim(), lastNAme.trim()].filter(Boolean).join(' ') || (id ? 'Anonym' : 'Neuer Benutzer'),
    [firstName, id, lastNAme]
  );
  const goBack = useCallback(() => push('/bugreports'), [push]);
  const saveUserData = useCallback(
    (d) => {
      if (id) save({ [ID]: id, ...d });
    },
    [id, save]
  );
  const handleChange = useCallback(
    async ({ currentTarget }) => {
      const { name, value } = currentTarget;

      setValues(($) => ({ ...$, [name]: value }));

      if (validationObject[name]) {
        try {
          await validationObject[name].validate(value);

          saveUserData({ [name]: value });
          setErrors(($) => ($[name] ? omit([name], $) : $));
        } catch ({ message }) {
          setErrors(($) => ({ ...$, [name]: message }));
        }
      } else {
        saveUserData({ [name]: value });
      }
    },
    [saveUserData, setErrors, setValues]
  );
  const onSelect = useCallback(
    async ({ currentTarget }) => {
      const { value, checked } = currentTarget;

      setValues(($) => {
        const currentRoles = $[ROLES] || [];
        const { include = [], exclude = [] } = ROLE_CONFIG[value] || {};
        const updatedRoles = checked
          ? pipe(
              when(() => exclude.length, filter(complement(includes(__, exclude)))),
              uniq
            )([...currentRoles, value, ...include])
          : currentRoles.filter((role) => role !== value && !includes(role, include));

        saveUserData({ [ROLES]: updatedRoles });

        return { ...$, [ROLES]: updatedRoles };
      });
    },
    [saveUserData, setValues]
  );
  const handleStatus = useCallback(
    (value) => {
      setValues(($) => ({ ...$, [STATUS]: value }));
      saveUserData({ [STATUS]: value });
    },
    [saveUserData, setValues]
  );
  const handleTwoFactor = useCallback(
    (value) => {
      setValues(($) => ({ ...$, [HAS_2F]: value }));
      saveUserData({ [HAS_2F]: value });
    },
    [saveUserData, setValues]
  );
  const validateRoles = useCallback(async () => {
    if (status !== 1) {
      setErrors(($) => ($[ROLES] ? omit([ROLES], $) : $));

      return;
    }

    try {
      await validationObject[ROLES].validate(roles);

      setErrors(($) => ($[ROLES] ? omit([ROLES], $) : $));
    } catch ({ message }) {
      setErrors(($) => ({ ...$, [ROLES]: message }));
    }
  }, [roles, setErrors, status]);
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        const val = await validationSchema.validate(
          { ...omit([STATUS, HAS_2F], values), [ROLES]: values[ROLES] || [] },
          { abortEarly: false }
        );

        create(val);
      } catch (yupErrors) {
        setErrors(
          yupErrors.inner.reduce((acc, { path, message }) => {
            acc[path] = message;

            return acc;
          }, {})
        );
      }
    },
    [create, setErrors, values]
  );

  useEffect(() => validateRoles(), [validateRoles]);

  if (success) return <Redirect to={`/users/${success}`} />;

  return (
    <Container onSubmit={onSubmit}>
      <PageHeader title={pageTitle} redirectToBack={goBack} />
      <Section label="Persönliche Daten">
        <Select name={TITLE} label="Titel" value={title} options={titles} onChange={handleChange} />
        <TextInput name={FIRST_NAME} label="Vorname" value={firstName} onChange={handleChange} error={errors[FIRST_NAME]} />
        <TextInput name={LAST_NAME} label="Nachname" value={lastNAme} onChange={handleChange} error={errors[LAST_NAME]} />
        <Radios name={GENDER} label="Geschlecht" value={values[GENDER]} items={GENDER_RADIOS} onChange={handleChange} />
        <TextInput
          name={EMAIL}
          label="E-Mail"
          value={values[EMAIL]}
          onChange={handleChange}
          error={errors[EMAIL] || ((createErr === emailError || saveErr === emailError) && emailError)}
        />
        {Boolean(id) && (
          <>
            <QuestionInput
              title="Status"
              options={STATUS_OPTIONS}
              value={values[STATUS]}
              onChange={handleStatus}
              error={errors[STATUS]}
              hint={values[STATUS] === 0 && 'Hinweis: Ein inaktiver Nutzer kann nicht mehr auf das System zugreifen.'}
            />
            <QuestionInput
              title="Zwei-Faktor-Authentifizierung"
              options={TWO_FACTOR_OPTIONS}
              value={values[HAS_2F]}
              onChange={handleTwoFactor}
              error={errors[HAS_2F]}
            />
          </>
        )}
      </Section>
      <Section label="Nutzerrechte">
        <Checkboxes $error={errors[ROLES]}>
          {ROLE_OPTIONS.map(({ id: selected, label }) => (
            <CheckBox
              key={selected}
              value={selected}
              checked={Boolean(roles) && roles.includes(selected)}
              label={label}
              onChange={onSelect}
              error={errors[ROLES]}
            />
          ))}
        </Checkboxes>
      </Section>
      {Boolean(id && saveErr) && saveErr !== emailError && <Message type="error">{saveErr}</Message>}
      {!id && (
        <>
          <Section label="Nutzer hinzufügen">
            <Button type="submit" color="success" disabled={loading}>
              Einladung senden
            </Button>
            {createErr && createErr !== emailError && <Message type="error">{createErr}</Message>}
          </Section>
          {loading && (
            <SpinnerWrap>
              <Spinner height="100%" />
            </SpinnerWrap>
          )}
        </>
      )}
    </Container>
  );
};

Form.defaultProps = {
  id: null,
  data: null,
};
Form.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    [TITLE]: PropTypes.string,
    [FIRST_NAME]: PropTypes.string.isRequired,
    [LAST_NAME]: PropTypes.string.isRequired,
    [GENDER]: PropTypes.string,
    [EMAIL]: PropTypes.string.isRequired,
    [ROLES]: PropTypes.arrayOf(PropTypes.string).isRequired,
    [STATUS]: PropTypes.number.isRequired,
    [HAS_2F]: PropTypes.bool,
  }),
};

export default Form;
