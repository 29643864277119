import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import { isEmpty } from 'ramda';

import { EMAIL, useSessionActions, useAlerts } from 'store/session';
import Button from 'components/Button';
import Message from 'components/Message';
import Spinner from 'components/FullScreenSpinner';

import Input from './Input';
import { CenteredContainer, LoginTitle, FormContainer } from './styles';
import { validationSchema } from './validations';

const Reset = () => {
  const { fetchResetLink } = useSessionActions();
  const { extractId, success, error, loading, resetAlerts } = useAlerts();

  const handleReset = useCallback(
    (data) => {
      extractId(fetchResetLink({ ...data }));
    },
    [extractId, fetchResetLink]
  );

  const { errors, setErrors, values, setFieldValue, handleSubmit } = useFormik({
    initialValues: { [EMAIL]: '' },
    validationSchema,
    onSubmit: handleReset,
    validateOnChange: false,
  });

  return (
    <CenteredContainer>
      <LoginTitle>Passwort zurücksetzen</LoginTitle>
      <FormContainer onSubmit={handleSubmit}>
        <Input
          name={EMAIL}
          value={values[EMAIL]}
          isAlerts={Boolean(success || error)}
          resetAlerts={resetAlerts}
          errors={errors}
          setErrors={setErrors}
          setFieldValue={setFieldValue}
        />
        <Button type="submit" disabled={loading || success || !isEmpty(errors)} mt={14} height="50px">
          {loading ? <Spinner size={40} height="40px" /> : 'Senden'}
        </Button>
        {error && <Message type="error">{error}</Message>}
        {success && (
          <Message type="success">Wir haben Ihnen eine E-Mail gesendet, bitte überprüfen Sie Ihren Posteingang.</Message>
        )}
      </FormContainer>
    </CenteredContainer>
  );
};

export default Reset;
