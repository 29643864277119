import { all, spawn } from 'redux-saga/effects';

import watchSession from 'store/session/sagas';
import watchPatients from 'store/patients/sagas';
import watchOrganizations from 'store/organizations/sagas';
import watchMedicalHistory from 'store/medicalHistory/sagas';
import watchExaminations from 'store/examinations/sagas';
import watchDiagnoses from 'store/diagnoses/sagas';
import watchInvoices from 'store/invoices/sagas';
import watchReports from 'store/reports/sagas';
import watchBugReports from 'store/bugReports/sagas';
import watchInfoItems from 'store/infoItems/sagas';
import watchPlanning from 'store/planning/sagas';
import watchAnalytics from 'store/analytics/sagas';

export default function* watchers() {
  yield all([
    spawn(watchSession),
    spawn(watchPatients),
    spawn(watchOrganizations),
    spawn(watchMedicalHistory),
    spawn(watchExaminations),
    spawn(watchDiagnoses),
    spawn(watchInvoices),
    spawn(watchReports),
    spawn(watchBugReports),
    spawn(watchInfoItems),
    spawn(watchPlanning),
    spawn(watchAnalytics),
  ]);
}
