import React from 'react';
import PropTypes from 'prop-types';
import { Container, Label, Text, Button } from './styles';

const LabelText = ({ label, text, onClick, readonly }) => (
  <Container>
    <Label>{label}</Label>
    <Text>{text}</Text>
    {!readonly && <Button onClick={onClick}>ändern</Button>}
  </Container>
);

LabelText.defaultProps = {
  label: '',
  text: '',
  onClick: () => {},
  readonly: false,
};

LabelText.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.string,
  onClick: PropTypes.func,
  readonly: PropTypes.bool,
};

export default LabelText;
