import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_SEARCH = getName('UPDATE_SEARCH');
export const UPDATE_SORT = getName('UPDATE_SORT');
export const UPDATE_PAGINATION = getName('UPDATE_PAGINATION');
export const RESET_FILTERS = getName('RESET_FILTERS');
export const SET_CURRENT_EXAMINATION = getName('SET_CURRENT_EXAMINATION');
export const UPDATE_EXAMINATIONS = getName('UPDATE_EXAMINATIONS');
export const RESET_EXAMINATIONS = getName('RESET_EXAMINATIONS');
export const UPDATE_EXAMINATION = getName('UPDATE_EXAMINATION');
export const DELETE_EXAMINATION = getName('DELETE_EXAMINATION');
export const UPDATE_BLOCKED_EXAMINATIONS = getName('UPDATE_BLOCKED_EXAMINATIONS');
export const UPDATE_EXAMINATION_PROGRESS = getName('UPDATE_EXAMINATION_PROGRESS');

export const FETCH_EXAMINATIONS_BY_PATIENT_ID = getName('FETCH_EXAMINATIONS_BY_PATIENT_ID');
export const FETCH_EXAMINATIONS = getName('FETCH_EXAMINATIONS');
export const FETCH_INIT_EXAMINATIONS = getName('FETCH_INIT_EXAMINATIONS');
export const CREATE_EXAMINATION = getName('CREATE_EXAMINATION');
export const SAVE_EXAMINATION = getName('SAVE_EXAMINATION');
export const REMOVE_EXAMINATION = getName('REMOVE_EXAMINATION');
export const UPLOAD_MEDIA = getName('UPLOAD_MEDIA');
export const UPLOAD_XML = getName('UPLOAD_XML');
