import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.p`
  color: ${({ type }) => {
    if (type === 'error') return 'var(--color-orangeDC)';
    if (type === 'success') return '#47a77a';
    return 'var(--color-grey6A)';
  }};
  font-size: ${({ small }) => (small === 1 ? 'var(--font-size13)' : 'var(--font-size15)')};
  text-align: ${({ textAlign }) => textAlign};
  margin: ${({ form }) => (form === 1 ? '0 0 3px 0' : '15px 0')};
`;

const Message = ({ type, small, form, children, textAlign, className }) => (
  <Container type={type} small={small} form={form} textAlign={textAlign} className={className}>
    {children}
  </Container>
);

Message.defaultProps = {
  type: '',
  form: 0,
  small: 0,
  textAlign: 'left',
  className: '',
};

Message.propTypes = {
  type: PropTypes.string,
  form: PropTypes.number,
  small: PropTypes.number,
  className: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'right', 'center']),
  children: PropTypes.node.isRequired,
};

export default Message;
