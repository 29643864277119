import styled from 'styled-components';

import Msg from 'components/Message';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 85px 85px 100px;
  gap: 0 10px;
`;

export const Wrapper = styled.div`
  width: 120px;
`;

export const Message = styled(Msg).attrs(() => ({
  type: 'error',
  form: 1,
  small: 1,
}))`
  grid-column: 1/-1;
`;
