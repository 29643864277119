import React, { useEffect, useState, useCallback, memo } from 'react';
import { useParams } from 'react-router-dom';
import { head, prop } from 'ramda';
import PropTypes from 'prop-types';

import FormContainer from 'components/FormContainer';
import Select from 'components/SelectNew';
import Button from 'components/Button';
import YesNoQuestion from 'components/YesNoQuestion';
import AnimateBox from 'components/AnimateBox';
import {
  ASSESSMENT,
  SUB_DIAGNOSES_CODES_EXTERN,
  SUB_DIAGNOSES_CODES_INTERN,
  PREVENT_ANALYSIS,
  PREVENT_ANALYSIS_REPORT,
  useDiagnosisCodes,
  useDiagnosisReports,
  useDiagnosisPreviewReport,
  useDiagnosesActions,
} from 'store/diagnoses';
import { compareProps } from 'utils';

import TextArea from './TextArea';
import ListHeader from './ListHeader';
import Row from './Row';
import Checkboxes from './Checkboxes';
import TextAreaWithClues from './TextAreaWithClues';
import DiseasesList from './DiseasesList';
import AdoptLastDiagnosis from './AdoptLastDiagnosis';
import { Section, SectionLabel, PreviewContainer, ReportsContainer, Message } from './styles';
import { useContactsForPatient } from './hooks';

const RecommendationForm = ({ diagnosisId, setFieldValue, readMode, values, setValues }) => {
  const { id } = useParams();
  const { createDiagnosisPreviewReport, resetDiagnosisPreviewReport } = useDiagnosesActions();
  const preview = useDiagnosisPreviewReport();
  const reports = useDiagnosisReports();
  const { internCodes, externCodes } = useDiagnosisCodes();

  const contactList = useContactsForPatient(id);
  const [agent, setAgent] = useState(() => head(contactList)?.id || '');

  const createPreview = useCallback(() => {
    if (readMode) return;

    createDiagnosisPreviewReport({ diagnosisId, contact: agent });
  }, [readMode, createDiagnosisPreviewReport, diagnosisId, agent]);

  const handleChangeInternCodes = useCallback((val) => setFieldValue(SUB_DIAGNOSES_CODES_INTERN, val), [setFieldValue]);
  const handleChangeExternCodes = useCallback((val) => setFieldValue(SUB_DIAGNOSES_CODES_EXTERN, val), [setFieldValue]);
  const handleChangeNoAnalysis = useCallback(
    (val) => {
      if (val) {
        setFieldValue(PREVENT_ANALYSIS, true);
      } else {
        setValues({
          ...values,
          [PREVENT_ANALYSIS]: false,
          [PREVENT_ANALYSIS_REPORT]: null,
        });
      }
    },
    [setFieldValue, setValues, values]
  );
  const handleChangeNoAnalysisReport = useCallback((val) => setFieldValue(PREVENT_ANALYSIS_REPORT, val), [setFieldValue]);

  useEffect(() => {
    const url = prop('url', preview);

    if (url) {
      window.open(url);
      resetDiagnosisPreviewReport();
    }

    return resetDiagnosisPreviewReport;
  }, [resetDiagnosisPreviewReport, preview]);

  if (!diagnosisId) {
    return (
      <Section>
        <Message type="error">Kein Untersuch vorhanden</Message>
      </Section>
    );
  }

  return (
    <FormContainer>
      {!readMode && <AdoptLastDiagnosis />}
      <Section>
        <SectionLabel>Diagnose</SectionLabel>
        {externCodes.length && (
          <Checkboxes
            items={values[SUB_DIAGNOSES_CODES_EXTERN]}
            allItems={externCodes}
            onChange={handleChangeExternCodes}
            readMode={readMode}
          />
        )}
      </Section>
      <Section>
        <SectionLabel>Diagnose intern</SectionLabel>
        {internCodes.length && (
          <Checkboxes
            items={values[SUB_DIAGNOSES_CODES_INTERN]}
            allItems={internCodes}
            onChange={handleChangeInternCodes}
            readMode={readMode}
          />
        )}
      </Section>
      <Section>
        <SectionLabel>Zusammenfassende Beurteilung / Augenärztliche Diagnosestellung</SectionLabel>
        <DiseasesList readMode={readMode} />
        <TextArea name={ASSESSMENT} value={values[ASSESSMENT]} setFieldValue={setFieldValue} readMode={readMode} tall />
      </Section>
      <Section>
        <TextAreaWithClues readMode={readMode} />
      </Section>
      <Section>
        <SectionLabel>Durchführbarkeit</SectionLabel>
        <YesNoQuestion
          question="Musste der Untersuch abgebrochen werden?"
          value={values[PREVENT_ANALYSIS]}
          onChoose={handleChangeNoAnalysis}
          readMode={readMode}
          withoutBorder
        />
        <AnimateBox isOpen={values[PREVENT_ANALYSIS]}>
          <YesNoQuestion
            question="Kontaktpersonen über abgebrochenen Untersuch informieren?"
            value={values[PREVENT_ANALYSIS_REPORT]}
            onChoose={handleChangeNoAnalysisReport}
            readMode={readMode}
          />
        </AnimateBox>
      </Section>
      <AnimateBox isOpen={!values[PREVENT_ANALYSIS] || values[PREVENT_ANALYSIS_REPORT]}>
        <Section mb={60}>
          <SectionLabel>Bericht erstellen für</SectionLabel>
          <PreviewContainer>
            <Select value={agent} options={contactList} selectedOption={agent} onSelect={setAgent} labelKey="title" />
            <Button
              type="button"
              height="40px"
              width="350px"
              ml={20}
              color={values[PREVENT_ANALYSIS] ? 'warning' : 'normal'}
              onClick={createPreview}
              disabled={readMode || !agent}
            >
              Vorschau öffnen
            </Button>
          </PreviewContainer>
        </Section>
      </AnimateBox>
      {Boolean(reports.length) && (
        <ReportsContainer>
          <ListHeader />
          {reports.map((item) => (
            <Row key={item.publicId} {...item} />
          ))}
        </ReportsContainer>
      )}
    </FormContainer>
  );
};

RecommendationForm.defaultProps = {
  diagnosisId: null,
};

RecommendationForm.propTypes = {
  readMode: PropTypes.bool.isRequired,
  diagnosisId: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({}).isRequired,
  setValues: PropTypes.func.isRequired,
};

export default memo(RecommendationForm, compareProps(['diagnosisId', 'setFieldValue', 'readMode', 'values', 'setValues']));
