import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import Section from 'components/QuestionContainer';
import Spinner from 'components/FullScreenSpinner';

import { InputWrap, Text, Warn, Wrapper, Button, Success, Errors } from './styles';

const UploadXLS = ({ label, value, onChange, disabled, loading, error, errors }) => {
  const onDrop = useCallback((files) => onChange(files[0] || null), [onChange]);
  const reset = useCallback(() => onChange(null), [onChange]);

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
    accept: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  });

  return (
    <Section label={label}>
      <InputWrap
        {...(disabled || loading ? {} : getRootProps())}
        $isActive={isDragActive}
        $isWarn={isDragReject}
        disabled={disabled || loading}
      >
        <input {...getInputProps()} />
        {(loading && <Spinner size={35} />) ||
          (isDragReject && <Warn>Die Excel-Datei muss im XLSX-Format gespeichert werden.</Warn>) || <Text>Excel hochladen</Text>}
      </InputWrap>
      {value && (
        <Wrapper>
          <Button type="button" onClick={reset} disabled={disabled || loading}>
            Rückgängig
          </Button>
          <Success>{value.name ? `${value.name} bereit zum Import` : 'Dokument erfolgreich hochgeladen'}</Success>
        </Wrapper>
      )}
      <Errors error={error} errors={errors} />
    </Section>
  );
};

UploadXLS.defaultProps = {
  value: null,
  disabled: false,
  loading: false,
  error: null,
  errors: null,
  withTemplateLink: false,
};
UploadXLS.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(File),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.string,
  errors: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.string.isRequired)])).isRequired
  ),
  withTemplateLink: PropTypes.bool,
};

export default UploadXLS;
