import { is } from 'ramda';

export const getSelected = (value, key) => {
  if (is(Object, value) && value[key]) return { [value[key]]: value };
  if (is(Array, value))
    return value.reduce((acc, item) => {
      if (item[key]) acc[item[key]] = item;

      return acc;
    }, {});

  return {};
};
