import React from 'react';
import QR from 'qrcode.react';

import Spinner from 'components/FullScreenSpinner';
import Warning from 'assets/svg-icons/Warning';

import { useQRLink } from './hooks';

// TODO show errors

const QRCode = () => {
  const { url, loading, error } = useQRLink();

  if (!url) {
    return (
      <>
        {loading && <Spinner size={100} height="160px" />}
        {error && <Warning />}
      </>
    );
  }

  return url && <QR value={url} size={142} includeMargin />;
};

export default QRCode;
