import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { prop, omit } from 'ramda';

import { normalizeArray } from 'store/utils';

import { ID, SORT, SORT_BY, SORT_DIR, PAGINATION, CURSOR, LIMIT, COUNT, HAS_MORE, DESC, LIST, REQUEST_DATA } from '.';
import { UPDATE_INFO_ITEMS, UPDATE_INFO_ITEM, DELETE_INFO_ITEM, RESET_INFO_ITEMS, UPDATE_SORT, UPDATE_PAGINATION } from './types';

const infoItemsReducer = handleActions(
  {
    [UPDATE_INFO_ITEMS]: (state, { payload }) => ({
      ...state,
      ...normalizeArray(ID, payload),
    }),
    [UPDATE_INFO_ITEM]: (state, { payload }) => {
      const id = prop(ID, payload);

      return state[id] ? { ...state, [id]: payload } : { [id]: payload, ...state };
    },
    [DELETE_INFO_ITEM]: (state, { payload }) => ({
      ...omit([prop(ID, payload)], state),
    }),
    [RESET_INFO_ITEMS]: () => ({}),
  },
  {}
);

const initRequestData = {
  [SORT]: {
    [SORT_BY]: 'meta.created',
    [SORT_DIR]: DESC,
  },
  [PAGINATION]: {
    [CURSOR]: 0,
    [LIMIT]: 20,
    [COUNT]: 0,
    [HAS_MORE]: false,
  },
};

const requestDataReducer = handleActions(
  {
    [UPDATE_SORT]: (state, { payload }) => ({
      ...state,
      [SORT]: payload,
    }),
    [UPDATE_PAGINATION]: (state, { payload }) => ({
      ...state,
      [PAGINATION]: {
        ...state[PAGINATION],
        ...payload,
      },
    }),
  },
  initRequestData
);

export default combineReducers({
  [LIST]: infoItemsReducer,
  [REQUEST_DATA]: requestDataReducer,
});
