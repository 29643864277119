import qs from 'query-string';
import { prop, omit } from 'ramda';

import { APPROVALS } from 'store/diagnoses';

import { wrapperRequest } from './utils';
import { API_URL, POST, PATCH, SUB_USER, SUB_EXAMINATION, EXPAND, LIMIT, SUB_PHYSICIAN } from './consts';

const defParams = { [EXPAND]: [`${APPROVALS}.${SUB_USER}`, SUB_PHYSICIAN] };

export const getExaminationDiagnosis = (id) =>
  wrapperRequest({
    url: `${API_URL}/diagnoses?${qs.stringify({ [SUB_EXAMINATION]: id, ...defParams })}`,
  });

export const patchDiagnosis = (body = {}) =>
  wrapperRequest({
    url: `${API_URL}/diagnoses/${prop('diagnosisId', body)}?${qs.stringify(defParams)}`,
    options: { method: PATCH, body: omit(['diagnosisId'], body) },
  });

export const postDiagnosisReports = (id) =>
  wrapperRequest({
    url: `${API_URL}/diagnoses/${id}/reports?${qs.stringify({ [LIMIT]: 50 })}`,
    options: { method: POST, body: {} },
  });

export const getDiagnosisReports = (id) =>
  wrapperRequest({
    url: `${API_URL}/diagnoses/${id}/reports?${qs.stringify({ [LIMIT]: 50 })}`,
  });

export const getDiagnosisReportPreview = ({ diagnosisId, contact }) =>
  wrapperRequest({
    url: `${API_URL}/diagnoses/${diagnosisId}/reports/preview`,
    options: { method: POST, body: { receiverType: contact } },
  });

export const getDiagnosisCodes = () =>
  wrapperRequest({
    url: `${API_URL}/diagnosiscodes?${qs.stringify({ [LIMIT]: 50 })}`,
  });

export const postDiagnosisWithdraw = (id) =>
  wrapperRequest({
    url: `${API_URL}/diagnoses/${id}/withdraw`,
    options: { method: POST, body: {} },
  });
