/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { isEmpty, prop } from 'ramda';
import qs from 'query-string';

import { PASSWORD, useSessionActions, useAlerts } from 'store/session';
import TextInput from 'components/TextInputNew';
import Button from 'components/Button';
import Message from 'components/Message';
import Link from 'components/Link';
import Spinner from 'components/FullScreenSpinner';

import { guidelines } from './utils';
import { validationSchema } from './validation';
import { CenteredContainer, LoginTitle, FormContainer, LinkWrapper, Label, Item, Text } from './styles';

const SetPassword = () => {
  const { search } = useLocation();
  const token = prop('token', qs.parse(search));
  const { resetPassword } = useSessionActions();
  const { extractId, loading, success, error, resetAlerts } = useAlerts();

  const handleReset = useCallback(
    (data) => {
      extractId(resetPassword({ ...data }));
    },
    [extractId, resetPassword]
  );

  const { errors, setErrors, values, setFieldValue, handleSubmit } = useFormik({
    initialValues: { [PASSWORD]: '', token },
    validationSchema,
    onSubmit: handleReset,
    validateOnChange: false,
  });

  const handleChange = useCallback(
    (text) => {
      if (!isEmpty(errors)) {
        setErrors({});
      }

      if (error) {
        resetAlerts();
      }

      setFieldValue(PASSWORD, text);
    },
    [error, errors, resetAlerts, setErrors, setFieldValue]
  );

  return (
    <CenteredContainer>
      <LoginTitle>Neues Passwort setzen</LoginTitle>
      <FormContainer onSubmit={handleSubmit}>
        <TextInput
          value={values[PASSWORD]}
          placeholder="Passwort"
          type="password"
          error={errors[PASSWORD]}
          handleChangeText={handleChange}
        />
        <Button type="submit" disabled={loading || success || !isEmpty(errors)} mt={14} height="50px">
          {loading ? <Spinner size={40} height="40px" /> : 'Weiter'}
        </Button>
        {(errors.token || error) && <Message type="error">{errors.token || error}</Message>}
        {success && <Message type="success">Ihr Passwort wurde erfolgreich geändert. Bitte melden Sie sich an.</Message>}
        <LinkWrapper>
          <Link to="/login" name="Zur Anmeldung" />
        </LinkWrapper>
        <Label>Augenmobil-Richtlinien für sichere Passwörter</Label>
        {guidelines.map(({ icon: Icon, text }, i) => (
          <Item key={i}>
            <Icon />
            <Text>{text}</Text>
          </Item>
        ))}
      </FormContainer>
    </CenteredContainer>
  );
};

export default SetPassword;
