import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_REPORTS,
  UPDATE_REPORT,
  RESET_REPORTS,
  UPDATE_FILTER_STATUS,
  UPDATE_FILTER_SEARCH,
  UPDATE_SORT,
  UPDATE_PAGINATION,
  RESET_FILTER,
  FETCH_REPORTS,
  FETCH_REPORTS_STATUS,
  SAVE_REPORT,
  CREATE_REPORTS_ARCHIVE,
  UPLOAD_EXCEL_FILE,
} from './types';

export const updateReports = createAction(UPDATE_REPORTS);
export const updateReport = createAction(UPDATE_REPORT);
export const resetReports = createAction(RESET_REPORTS);

export const updateStatus = createAction(UPDATE_FILTER_STATUS);
export const updateSearch = createAction(UPDATE_FILTER_SEARCH);
export const updateSort = createAction(UPDATE_SORT);
export const updatePagination = createAction(UPDATE_PAGINATION);
export const resetFilter = createAction(RESET_FILTER);

export const fetchReports = createAlertAction(FETCH_REPORTS);
export const fetchReportsStatus = createAlertAction(FETCH_REPORTS_STATUS);
export const saveReport = createAlertAction(SAVE_REPORT);
export const createReportsArchive = createAlertAction(CREATE_REPORTS_ARCHIVE);
export const uploadExcelFile = createAlertAction(UPLOAD_EXCEL_FILE);
