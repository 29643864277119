import { NAME, CITY, CONTACTS, MAIN, LAST_NAME, ID, KIND, ORGANIZATION_KINDS } from 'store/organizations';
import { prop, applySpec, propOr, pipe, pathOr, props, filter, join } from 'ramda';
import { FIRST_NAME } from 'store/session';

export const items = [
  {
    key: ORGANIZATION_KINDS.NURSING_HOME,
    label: 'Pflegezentren',
  },
  {
    key: ORGANIZATION_KINDS.OPTICIAN,
    label: 'Optiker',
  },
  {
    key: ORGANIZATION_KINDS.CLINIC,
    label: 'Ärzte',
  },
];

export const LABELS = {
  [ORGANIZATION_KINDS.NURSING_HOME]: {
    NEW: 'Neues Pflegezentrum',
    SEARCH: 'Pflegezentrum suchen',
    LIST: 'Pflegezentren',
  },
  [ORGANIZATION_KINDS.OPTICIAN]: {
    NEW: 'Neuer Optiker',
    SEARCH: 'Optiker suchen',
    LIST: 'Optiker',
  },
  [ORGANIZATION_KINDS.CLINIC]: {
    NEW: 'Neuer Arzt',
    SEARCH: 'Ärzte suchen',
    LIST: 'Ärzte',
  },
};

export const tableHead = [
  {
    id: NAME,
    content: 'Name',
    width: '35%',
  },
  {
    id: CITY,
    content: 'Ort',
    width: '30%',
  },
  {
    id: [CONTACTS, MAIN, LAST_NAME].join('.'),
    content: 'Kontaktperson',
    width: '35%',
  },
];

export const handleOrganizations = (organizations) =>
  organizations.map(
    applySpec({
      id: prop(ID),
      kind: prop(KIND),
      name: (val = {}) => val[NAME] || LABELS[val[KIND]]?.NEW,
      city: propOr('–', CITY),
      contact: pipe(pathOr({}, [CONTACTS, MAIN]), props([FIRST_NAME, LAST_NAME]), filter(Boolean), join(', ')),
    })
  );
