import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const SET_CURRENT_INVOICE = getName('SET_CURRENT_INVOICE');
export const UPDATE_INVOICES = getName('UPDATE_INVOICES');
export const UPDATE_INVOICE = getName('UPDATE_INVOICE');
export const DELETE_INVOICE = getName('DELETE_INVOICE');
export const RESET_INVOICES = getName('RESET_INVOICES');
export const UPDATE_ALL_TARMEDS = getName('UPDATE_ALL_TARMEDS');
export const UPDATE_PATIENT_TARMEDS = getName('UPDATE_PATIENT_TARMEDS');
export const UPDATE_DASHBOARD = getName('UPDATE_DASHBOARD');

export const UPDATE_SEARCH = getName('UPDATE_SEARCH');
export const UPDATE_STATUS = getName('UPDATE_STATUS');
export const UPDATE_SORT = getName('UPDATE_SORT');
export const UPDATE_PAGINATION = getName('UPDATE_PAGINATION');
export const UPDATE_PATIENT_ID = getName('UPDATE_PATIENT_ID');
export const UPDATE_CANTON = getName('UPDATE_CANTON');
export const UPDATE_YEAR = getName('UPDATE_YEAR');
export const RESET_FILTERS = getName('RESET_FILTERS');

export const FETCH_KPI_BY_NURSING_HOME_ID = getName('FETCH_KPI_BY_NURSING_HOME_ID');
export const FETCH_DASHBOARD = getName('FETCH_DASHBOARD');
export const FETCH_INVOICES = getName('FETCH_INVOICES');
export const FETCH_INVOICE = getName('FETCH_INVOICE');
export const FETCH_INVOICES_BY_EXAM_ID = getName('FETCH_INVOICES_BY_EXAM_ID');
export const FETCH_INVOICES_BY_PATIENT = getName('FETCH_INVOICES_BY_PATIENT');
export const CREATE_INVOICE = getName('CREATE_INVOICE');
export const SAVE_INVOICE = getName('SAVE_INVOICE');
export const SAVE_PATIENT_INVOICE = getName('SAVE_PATIENT_INVOICE');
export const SEND_INVOICE_REPORT = getName('SEND_INVOICE_REPORT');
export const SEND_PATIENT_INVOICE = getName('SEND_PATIENT_INVOICE');
export const CANCEL_INVOICE = getName('CANCEL_INVOICE');
export const RESEND_INVOICE = getName('RESEND_INVOICE');
export const FETCH_BASE_TARMEDS = getName('FETCH_BASE_TARMEDS');
export const FETCH_PATIENT_TARMEDS = getName('FETCH_PATIENT_TARMEDS');
export const DOWNLOAD_PDF = getName('DOWNLOAD_PDF');
export const FETCH_CANTON_DASHBOARD = getName('FETCH_CANTON_DASHBOARD');
