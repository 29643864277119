import { useEffect } from 'react';

import { useAlerts } from 'store/session';

import { AMOUNT_FIELDS } from './consts';

export const useValidate = (values, action, validateAction) => {
  const code = values.join('');
  const { extractId, loading, resetAlerts, success, error } = useAlerts();
  const ready = success || error;

  useEffect(() => {
    if (code.length === AMOUNT_FIELDS && !ready && !loading) {
      extractId(validateAction(code));
    }
  }, [code, extractId, loading, ready, validateAction]);

  useEffect(() => {
    if (success) {
      action(code);
    }
  }, [action, code, success]);

  return {
    loading,
    isValid: success,
    ready,
    resetAlerts,
    error,
  };
};
