import styled from 'styled-components';

import Btn from 'components/Button';
import Msg from 'components/Message';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 700px;
  padding: 20px 0;
`;

export const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: ${({ maxWidth }) => (maxWidth ? '100%' : 'calc(50% - 15px)')};
`;

export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  height: 35px;
`;

export const Name = styled.button`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'calc(100% - 30px)')};
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  height: 25px;
  letter-spacing: 0.5px;
  color: var(--color-grey6A);
  background-color: var(--color-greyEC);
  border-radius: var(--border-radius8);
  border: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  &:disabled {
    background-color: #c3c3c3;
    cursor: auto;
  }
`;

export const Button = styled(Btn)`
  width: 25px;
  height: 25px;
  background-color: ${({ isSelected }) => (isSelected ? '#c3c3c3' : 'var(--color-greyEC)')};
  border-radius: var(--border-radius8);
`;

export const ImageBox = styled.div`
  display: flex;
  height: calc(100% - 35px);
  border: 1px solid var(--color-grey97);
  border-radius: var(--border-radius8);
  cursor: pointer;
  overflow: hidden;
`;

export const Message = styled(Msg)`
  text-align: center;
  margin: auto;
`;
