import { useMemo } from 'react';

import { useCurrentSchedule, useScheduleUpdates, ID, DATE, DAYS, SHIFTS, REGISTRATION_DATE } from 'store/planning';

import { getRegistrationDate } from './utils';

export const useFormData = () => {
  const [id, date, shifts, regDate] = useCurrentSchedule([ID, DATE, SHIFTS, REGISTRATION_DATE]);
  const [updatedDate, days, updatedShifts, updatedRegDate] = useScheduleUpdates(id, [DATE, DAYS, SHIFTS, REGISTRATION_DATE]);
  const values = useMemo(
    () => ({
      [DATE]: updatedDate || date,
      [DAYS]: days || shifts?.length || '',
      [SHIFTS]: updatedShifts || shifts || [],
      [REGISTRATION_DATE]: updatedRegDate || regDate || getRegistrationDate(shifts),
    }),
    [date, days, regDate, shifts, updatedDate, updatedRegDate, updatedShifts]
  );

  return { id, values };
};
