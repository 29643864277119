/* eslint-disable react/no-array-index-key */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { mergeAlerts } from 'store/alerts';
import Spinner from 'components/FullScreenSpinner';

import { useTexts, useCoincidences } from './hooks';
import Panel from './Panel';
import { Container, Wrapper, Text, Highlight, Message, Cover } from './styles';

const Content = ({ id, search }) => {
  const [page, setPage] = useState(0);
  const textData = useTexts(id, page);
  const matchData = useCoincidences(id, search, page);
  const { error, loading } = mergeAlerts([textData, matchData]);
  const { texts, amount } = textData;
  const { matches, matchPages } = matchData;

  return (
    <Container>
      {!loading && <Panel page={page} updatePage={setPage} pages={amount} matches={matchPages} />}
      <Wrapper>
        {error && <Message type="error">{error}</Message>}
        {texts.map((item, idx) => (
          <Text key={idx}>
            {item.map(({ id: wordId, text }) => (
              <Fragment key={wordId}>
                {matches[idx] && matches[idx].includes(wordId) ? <Highlight>{text}</Highlight> : text}{' '}
              </Fragment>
            ))}
          </Text>
        ))}
        {loading && (
          <Cover>
            <Spinner height="100%" />
          </Cover>
        )}
      </Wrapper>
    </Container>
  );
};

Content.propTypes = {
  id: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
};

export default Content;
