import styled from 'styled-components';
import { motion } from 'framer-motion';

const getAnimation = ({ isOpen }) => ({
  animate: { height: isOpen ? 'auto' : 0, overflow: isOpen ? 'visible' : 'hidden' },
  transition: {
    height: { duration: 0.5 },
    overflow: { delay: isOpen ? 0.5 : 0 },
  },
  initial: false,
});

const AnimateBox = styled(motion.div).attrs(getAnimation)``;

export default AnimateBox;
