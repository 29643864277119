import styled from 'styled-components';

import Btn from 'components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 55px;
  background-color: rgba(255, 255, 255, 0.85);
  padding: ${({ smallWidth }) => (smallWidth ? 0 : 20)}px;
`;

export const Message = styled.p`
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  color: var(--color-grey6A);
  margin-bottom: 5px;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 160px;
  justify-content: space-between;
  ${({ smallWidth }) =>
    smallWidth &&
    `
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  `};
`;

export const Button = styled(Btn)`
  height: 25px;
  width: 70px;
  padding: 0 8px;
  ${({ smallWidth }) =>
    smallWidth &&
    `
    width: 100%;
    &:first-child {
      margin-bottom: 10px;
    }
  `};
`;
