import styled from 'styled-components';

const NavbarLink = styled.div`
  display: flex;
  ${({ width }) => width && `width: ${width * 100}%;`}
  margin-bottom: ${({ mb = 0 }) => mb}px;

  & > button {
    margin-right: 10px;
  }
`;

export default NavbarLink;
