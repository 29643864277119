import React, { useMemo, useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { pick } from 'ramda';

import {
  DIAGNOSIS,
  TAKE_LAST_DIAGNOSIS,
  SUB_DIAGNOSES_CODES_EXTERN,
  SUB_DIAGNOSES_CODES_INTERN,
  ASSESSMENT,
  useDiagnosesActions,
  useAlerts,
} from 'store/diagnoses';
import { ID, STATUS, EXAMINATION_STATUS, useCurrentExamination, useExaminations } from 'store/examinations';
import Button from 'components/Button';
import Spinner from 'components/FullScreenSpinner';
import { extractFields } from '../utils';

import { getExamDate } from './utils';
import { Message } from './styles';

const AdoptLastDiagnosis = () => {
  const { values: diagnoses, setValues: setDiagnoses, errors, setErrors, setFieldValue } = useFormikContext();
  const isAlreadyCopied = diagnoses[TAKE_LAST_DIAGNOSIS];
  const currentExam = useCurrentExamination();
  const currentExamDate = useMemo(() => getExamDate(currentExam), [currentExam]);
  const examinations = useExaminations();
  const { fetchDiagnosesForExamination } = useDiagnosesActions();
  const { extractId, success, loading, error, resetAlerts } = useAlerts();

  const previousExamination = useMemo(
    () =>
      examinations.find(
        (exam) =>
          exam[ID] !== currentExam[ID] &&
          exam[STATUS] >= EXAMINATION_STATUS.RELEASED &&
          exam[STATUS] < EXAMINATION_STATUS.ABORTED &&
          currentExamDate > getExamDate(exam)
      ),
    [currentExam, currentExamDate, examinations]
  );

  const takeLastDiagnosis = useCallback(() => {
    if (previousExamination && previousExamination[ID]) {
      extractId(fetchDiagnosesForExamination(previousExamination[ID]));
    }
  }, [extractId, fetchDiagnosesForExamination, previousExamination]);

  useEffect(() => {
    if (!(success && success[0])) return;

    const prevDiagnosis = extractFields(success[0]);

    resetAlerts();
    setDiagnoses({
      ...diagnoses,
      ...pick([SUB_DIAGNOSES_CODES_EXTERN, SUB_DIAGNOSES_CODES_INTERN, ASSESSMENT], success[0]),
      [DIAGNOSIS]: prevDiagnosis,
      [TAKE_LAST_DIAGNOSIS]: true,
    });
  }, [success, resetAlerts, setDiagnoses, diagnoses]);

  useEffect(() => {
    if (errors[TAKE_LAST_DIAGNOSIS]) {
      setErrors({});
      setFieldValue(TAKE_LAST_DIAGNOSIS, false);
    }
  }, [errors, setErrors, setFieldValue]);

  return (
    <>
      <Button
        type="button"
        onClick={takeLastDiagnosis}
        color={isAlreadyCopied ? 'success' : 'default'}
        disabled={isAlreadyCopied || !previousExamination}
      >
        {loading ? <Spinner size={40} height="40px" /> : 'Diagnosen aus letztem Untersuch übernehmen'}
      </Button>
      {error && <Message type="error">Beim Übernehmen der früheren Diagnosen ist ein Fehler aufgetreten.</Message>}
    </>
  );
};

export default AdoptLastDiagnosis;
