import styled from 'styled-components';

import { ITEM_HEIGHT } from '../consts';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: ${ITEM_HEIGHT}px;
  background-color: var(--color-greyEC);
  border-radius: var(--border-radius8);
  padding-left: 10px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ITEM_HEIGHT}px;
  height: ${ITEM_HEIGHT}px;
  border-radius: var(--border-radius8);
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  @media (hover) {
    &:hover {
      background-color: var(--color-greyEC);
    }
  }
  & > svg {
    transform: rotate(45deg);
  }
`;
