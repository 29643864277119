import styled from 'styled-components';

import Btn from 'components/Button';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 80px;
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 40px;
  background: rgba(216, 216, 216, 0.4);
  height: 100%;
  padding: 10px 20px 20px;
  border-radius: var(--border-radius8);
  &:nth-child(2) {
    margin: 0 20px;
  }
`;

export const Label = styled.p`
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  line-height: 1;
  letter-spacing: 0.9px;
  color: var(--color-grey97);
  text-transform: uppercase;
`;

export const Button = styled(Btn)`
  width: 40px;
  height: 40px;
`;
