import { keys } from 'ramda';

import { INVOICE_LABELS } from 'store/invoices';
import palette from 'theme/palette';

export const STATUSES = keys(INVOICE_LABELS).reduce((acc, id) => {
  acc[id] = { id, name: INVOICE_LABELS[id], color: palette.invoice[id] };

  return acc;
}, {});
