import { path, prop } from 'ramda';

import { ALL } from 'api/consts';

import { FEATURE_NAME as $, CURRENT, LIST, FILTERS, DASHBOARD, TARMEDS, PATIENT_TARMEDS } from './consts';

export const getInvoices = path([$, LIST]);
export const getInvoice = (id) => path([$, LIST, id]);
export const getInvoiceProp = (id) => (name) => path([$, LIST, id, name]);
export const getCurrentInvoiceId = path([$, CURRENT]);
export const getCurrentInvoice = (state) => getInvoice(path([$, CURRENT], state))(state);
export const getCurrentInvoiceProp = (name) => (state) => prop(name, getCurrentInvoice(state));

export const getAllTarmeds = path([$, TARMEDS, ALL]);
export const getPatientTarmeds = path([$, TARMEDS, PATIENT_TARMEDS]);

export const getDashboard = path([$, DASHBOARD]);

export const getFilters = (kind) => path([$, FILTERS, kind]);
