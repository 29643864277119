import React from 'react';
import PropTypes from 'prop-types';

import ArrowRight from 'assets/svg-icons/ArrowRight';
import { INVOICE_LABELS, IS_USED, AMOUNT_DUE, CURRENCY, STATUS } from 'store/invoices';
import { getPrice, getDate } from '../utils';

import { Container, Button, ContentContainer, LeftSide, RightSide, Price, Status } from './styles';

const Panel = ({ redirectBack, invoice, disabled }) => {
  const { [IS_USED]: issued, [CURRENCY]: currency, [AMOUNT_DUE]: amountDue, [STATUS]: status } = invoice;

  return (
    <Container>
      <Button type="button" onClick={redirectBack} disabled={disabled}>
        <ArrowRight />
      </Button>
      <ContentContainer>
        <LeftSide>{`Rechnung vom ${getDate(issued) || '–'}`}</LeftSide>
        <RightSide>
          <Price>{getPrice(currency, amountDue)}</Price>
          <Status>{(INVOICE_LABELS[status] || '').toUpperCase()}</Status>
        </RightSide>
      </ContentContainer>
    </Container>
  );
};

Panel.defaultProps = { disabled: false };
Panel.propTypes = {
  redirectBack: PropTypes.func.isRequired,
  invoice: PropTypes.shape({
    [IS_USED]: PropTypes.string,
    [CURRENCY]: PropTypes.string,
    [AMOUNT_DUE]: PropTypes.number,
    [STATUS]: PropTypes.number.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
};

export default Panel;
