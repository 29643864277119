import React, { useCallback } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import Download from 'assets/svg-icons/Download';
import { REPORT_STATUS } from 'store/reports';

import { RowContainer, IdField, Field, DownloadWrapper } from './styles';

const Row = ({ publicId, status: reportStatus, meta, url, receiver }) => {
  const getReceiver = useCallback(() => {
    const { institution, firstname, lastname } = receiver;
    if (institution) return institution;
    return `${firstname || ''} ${lastname || ''}`;
  }, [receiver]);

  return (
    <RowContainer key={publicId}>
      <IdField width={20}>{publicId}</IdField>
      <Field width={30}>{getReceiver()}</Field>
      <Field width={20}>{moment(meta.modified).format('DD.MM.YYYY')}</Field>
      <Field width={20}>{REPORT_STATUS.find(({ id }) => id === `${reportStatus}`).title}</Field>
      <DownloadWrapper width={10}>
        <a href={url} aria-label="Download" download target="_blank" rel="noopener noreferrer">
          <Download />
        </a>
      </DownloadWrapper>
    </RowContainer>
  );
};

Row.defaultProps = {
  receiver: {},
};

Row.propTypes = {
  publicId: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  meta: PropTypes.shape({
    modified: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,
  receiver: PropTypes.shape({
    institution: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
  }),
};

export default Row;
