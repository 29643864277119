import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_MEDICAL_HISTORY,
  RESET_MEDICAL_HISTORY,
  FETCH_MEDICAL_HISTORY,
  FETCH_COLLECT_MEDICAL_HISTORY,
  SAVE_MEDICAL_HISTORY,
  UPLOAD_MEDIA_DATA,
  FETCH_SEARCHABLE_MEDIA,
  UPDATE_SEARCHABLE_MEDIA,
  DELETE_SEARCHABLE_MEDIA,
} from './types';

export const updateMedicalHistory = createAction(UPDATE_MEDICAL_HISTORY);
export const resetMedicalHistory = createAction(RESET_MEDICAL_HISTORY);

export const fetchMedicalHistory = createAlertAction(FETCH_MEDICAL_HISTORY);
export const fetchCollectMedicalHistory = createAlertAction(FETCH_COLLECT_MEDICAL_HISTORY);
export const saveMedicalHistory = createAlertAction(SAVE_MEDICAL_HISTORY);
export const uploadMediaData = createAlertAction(UPLOAD_MEDIA_DATA);
export const fetchSearchableMedia = createAlertAction(FETCH_SEARCHABLE_MEDIA);
export const updateSearchableMedia = createAlertAction(UPDATE_SEARCHABLE_MEDIA);
export const deleteSearchableMedia = createAlertAction(DELETE_SEARCHABLE_MEDIA);
