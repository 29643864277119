import styled from 'styled-components';

export const Container = styled.div`
  margin-top: ${({ isFirst }) => (isFirst ? 0 : 20)}px;
  padding-top: 10px;
  border-top: 1px solid var(--color-grey97);
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  color: var(--color-grey6A);
  font-size: var(--font-size15);
  font-weight: var(--font-weight600);
`;

export const Button = styled.button`
  display: flex;
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 9px;
  &:hover {
    background-color: var(--color-greyF3);
  }
  &,
  & > * {
    transition: all 0.3s;
  }
`;

const text = `
  font-size: var(--font-size18);
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: var(--color-grey6A);
`;

export const CurrentItem = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyF3);
  padding: 0 30px;
  margin-bottom: 24px;
  ${text}
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  border-bottom: 1px solid #cfcfcf;
  padding: 0 13px;
  ${({ isActive }) =>
    isActive &&
    `
    position: relative;
    background-color: var(--color-greenF6);
    border: 1px solid var(--color-blue50);
    border-radius: var(--border-radius8);
    &::before {
      content: '';
      position: absolute;
      top: -2px;
      left: -1px;
      right: -1px;
      height: 1px;
      background: var(--color-white);
      
    }
  `}
  &:last-child {
    margin-bottom: 24px;
  }
`;

export const DateText = styled.p`
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: var(--color-grey6A);
  margin-right: 90px;
`;

export const Text = styled.p`
  ${text}
`;
