import styled from 'styled-components';

import editIcon from 'assets/svg-icons/clock.svg';

export const Container = styled.label`
  display: grid;
  grid-template-columns: 240px ${({ $readMode }) => ($readMode ? '1fr' : '235px')};
  grid-template-rows: auto auto;
  align-items: center;
`;

export const Label = styled.p`
  width: 100%;
  color: var(--color-grey6A);
  font-weight: var(--font-weight400);
  font-size: var(--font-size15);
  padding-right: 10px;
`;

export const Input = styled.input`
  height: 40px;
  width: 100%;
  border: 1px solid var(--color-grey97);
  border-radius: 8px;
  background-color: var(--color-white);
  font-size: var(--font-size18);
  font-weight: var(--font-weight400);
  padding: 0 8px;
  outline: none;
  appearance: none;
  cursor: text;
  &:valid {
    color: ${({ $error }) => ($error ? 'var(--color-orangeDC)' : 'var(--color-grey6A)')};
    border-color: ${({ $error }) => ($error ? '#ff4124' : 'var(--color-grey97)')};
  }
  &:invalid {
    color: var(--color-orangeDC);
    border-color: #ff4124;
  }
  &:focus {
    box-shadow: var(--shadow-input-focus);
  }
  &:disabled {
    opacity: 1;
    background-color: var(--color-greyEC);
    border-color: var(--color-greyEC);
    font-weight: var(--font-weight300);
    cursor: not-allowed;
  }
  &::-webkit-calendar-picker-indicator {
    background-image: url(${editIcon});
    cursor: pointer;
  }
`;

export const Message = styled.p`
  margin-top: 3px;
  margin-left: 10px;
  grid-column: 2/3;
  color: var(--color-orangeDC);
  font-size: var(--font-size13);
  word-break: break-word;
`;
