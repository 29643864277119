import styled from 'styled-components';

import MessageDef from 'components/Message';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 5px;
  justify-content: center;
  align-items: center;
`;

export const InputContainer = styled.div`
  display: flex;
  column-gap: 5px;
`;

export const Input = styled.input`
  width: 30px;
  height: 40px;
  border: 1px solid ${({ error }) => (error ? 'var(--color-orangeDC)' : 'var(--color-grey97)')};
  color: ${({ error }) => (error ? 'var(--color-orangeDC)' : 'var(--color-black)')};
  border-radius: var(--border-radius8);
  background: var(--color-white);
  font-size: var(--font-size15);
  line-height: 1.33;
  letter-spacing: 0.38px;
  text-align: center;
  outline: none;
  cursor: pointer;
  &::placeholder {
    color: var(--color-grey97);
  }
  opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
  transition: all 0.5s;
  &:focus {
    box-shadow: var(--shadow-input-focus);
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
`;

export const Message = styled(MessageDef)`
  grid-column: 1/-1;
  max-width: 250px;
  margin: 5px 0;
`;
