import React, { useCallback, useMemo } from 'react';
import { useFormikContext, useField } from 'formik';
import { tail, assocPath, pathOr } from 'ramda';
import PropTypes from 'prop-types';

import TextInput from 'components/TextInputNew';
import Checkbox from 'components/CheckboxNew';
import Select from 'components/SelectNew';
import Radios from 'components/RadiosNew';
import AddressAutocomplete from 'components/AddressAutocomplete';
import { POSITION_TITLES, GENDER_RADIOS } from 'utils/constants';
import {
  CORRESPONDENCE,
  TITLE,
  FIRST_NAME,
  LAST_NAME,
  GENDER,
  ADDRESS,
  CANTON,
  COUNTRY,
  ZIP_CODE,
  CITY,
  PHONE,
  EMAIL,
  INSTITUTION,
  IS_EMERGENCY,
} from 'store/patients';

const Contact = ({ readMode, correspondenceLabel, feature, errors }) => {
  const getPath = useCallback((name) => [...feature, name].join('.'), [feature]);
  const { setValues } = useFormikContext();
  const [{ value: reporter }, , { setValue: setReporter }] = useField(getPath(CORRESPONDENCE));
  const [{ value: emergency }, , { setValue: setEmergency }] = useField(getPath(IS_EMERGENCY));
  const [{ value: title }, , { setValue: setTitle }] = useField(getPath(TITLE));
  const [firstNameField] = useField(getPath(FIRST_NAME));
  const [lastNameField] = useField(getPath(LAST_NAME));
  const [institutionField] = useField(getPath(INSTITUTION));
  const [{ value: gender }, , { setValue: setGender }] = useField(getPath(GENDER));
  const [{ value: address }] = useField(getPath(ADDRESS));
  const [{ value: zipCode }] = useField(getPath(ZIP_CODE));
  const [{ value: city }] = useField(getPath(CITY));
  const [{ value: canton }] = useField(getPath(CANTON));
  const [{ value: country }] = useField(getPath(COUNTRY));
  const [phoneField] = useField(getPath(PHONE));
  const [emailField] = useField(getPath(EMAIL));
  const updateAutocompleteValues = useCallback(
    (data) => setValues(($) => assocPath(feature, { ...pathOr({}, feature, $), ...data }, $)),
    [feature, setValues]
  );

  const options = useMemo(() => (title ? POSITION_TITLES : tail(POSITION_TITLES)), [title]);

  const handleReporter = useCallback(() => {
    if (!readMode) setReporter(!reporter);
  }, [readMode, reporter, setReporter]);
  const handleEmergency = useCallback(() => {
    if (!readMode) setEmergency(!emergency);
  }, [readMode, emergency, setEmergency]);

  return (
    <>
      <Checkbox label={correspondenceLabel} selected={reporter} onClick={handleReporter} readMode={readMode} noLabel />
      <Checkbox label="Ist Notfallkontakt" selected={emergency} onClick={handleEmergency} readMode={readMode} noLabel />
      <Select label="Titel" value={title || ''} options={options} onSelect={setTitle} readMode={readMode} />
      <TextInput label="Vorname" {...firstNameField} readMode={readMode} />
      <TextInput label="Nachname" {...lastNameField} readMode={readMode} />
      <TextInput
        label="Institution"
        {...institutionField}
        readMode={readMode}
        error={!readMode && errors && errors[INSTITUTION]}
      />
      <Radios value={gender} label="Geschlecht" items={GENDER_RADIOS} setValue={setGender} readMode={readMode} />
      <AddressAutocomplete
        address={address}
        zipCode={zipCode}
        city={city}
        canton={canton}
        country={country}
        setData={updateAutocompleteValues}
        zipCodeErr={errors && errors[ZIP_CODE]}
        readMode={readMode}
      />
      <TextInput label="Telefon" {...phoneField} readMode={readMode} />
      <TextInput label="E-Mail" {...emailField} readMode={readMode} error={!readMode && errors && errors[EMAIL]} />
    </>
  );
};

Contact.defaultProps = {
  correspondenceLabel: 'Erhält Korrespondenz',
  errors: null,
};
Contact.propTypes = {
  readMode: PropTypes.bool.isRequired,
  correspondenceLabel: PropTypes.string,
  feature: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired).isRequired,
  errors: PropTypes.shape({
    [EMAIL]: PropTypes.string,
    [INSTITUTION]: PropTypes.string,
    [ZIP_CODE]: PropTypes.string,
  }),
};

export default Contact;
