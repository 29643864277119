import { useEffect, useMemo } from 'react';
import { map, pipe, props, join, propOr, flatten, prop, uniq } from 'ramda';

import { useMedicalHistoryActions, useAlerts } from 'store/medicalHistory';
import { useAsyncState } from 'utils/useAsyncState';

const generateId = pipe(props(['page', 'line', 'word']), join('-'));

const generateIds = map(map(generateId));

const extractPages = pipe(flatten, map(prop('page')), uniq);

export const useTexts = (id, page) => {
  const [texts, setTexts] = useAsyncState();
  const { fetchSearchableMedia } = useMedicalHistoryActions();
  const { loading, success, error, extractId } = useAlerts();

  useEffect(() => {
    if (!texts && success) {
      setTexts(map(map(map((item) => ({ ...item, id: generateId(item) }))), success));
    }
  }, [texts, setTexts, success]);

  useEffect(() => {
    extractId(fetchSearchableMedia({ id, search: '' }));
  }, [extractId, fetchSearchableMedia, id]);

  return {
    texts: propOr([], page, texts),
    amount: propOr(0, 'length', texts),
    loading: !texts || loading,
    error,
  };
};

export const useCoincidences = (id, search, page) => {
  const { fetchSearchableMedia } = useMedicalHistoryActions();
  const { loading, success, error, extractId } = useAlerts();

  const matches = useMemo(() => (search && success?.length ? generateIds(success[page]) : []), [search, success, page]);

  const matchPages = useMemo(() => (search && success?.length ? extractPages(success) : []), [search, success]);

  useEffect(() => {
    if (search) {
      extractId(fetchSearchableMedia({ id, search }));
    }
  }, [extractId, fetchSearchableMedia, id, search]);

  return {
    matches,
    matchPages,
    loading,
    error,
  };
};
