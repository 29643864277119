import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_SEARCH,
  UPDATE_SORT,
  UPDATE_PAGINATION,
  RESET_FILTERS,
  SET_CURRENT_EXAMINATION,
  UPDATE_EXAMINATIONS,
  RESET_EXAMINATIONS,
  UPDATE_EXAMINATION,
  UPDATE_BLOCKED_EXAMINATIONS,
  UPDATE_EXAMINATION_PROGRESS,
  FETCH_EXAMINATIONS_BY_PATIENT_ID,
  FETCH_INIT_EXAMINATIONS,
  FETCH_EXAMINATIONS,
  CREATE_EXAMINATION,
  SAVE_EXAMINATION,
  REMOVE_EXAMINATION,
  UPLOAD_MEDIA,
  UPLOAD_XML,
} from './types';

export const setCurrentExamination = createAction(SET_CURRENT_EXAMINATION);
export const updateExaminations = createAction(UPDATE_EXAMINATIONS);
export const resetExaminations = createAction(RESET_EXAMINATIONS);
export const updateExamination = createAction(UPDATE_EXAMINATION);
export const updateBlockedExaminations = createAction(UPDATE_BLOCKED_EXAMINATIONS);
export const updateExaminationProgress = createAction(UPDATE_EXAMINATION_PROGRESS);
export const updateSearch = createAction(UPDATE_SEARCH);
export const updateSort = createAction(UPDATE_SORT);
export const updatePagination = createAction(UPDATE_PAGINATION);
export const resetFilters = createAction(RESET_FILTERS);

export const fetchExaminationsByPatientId = createAlertAction(FETCH_EXAMINATIONS_BY_PATIENT_ID);
export const fetchInitExaminations = createAlertAction(FETCH_INIT_EXAMINATIONS);
export const fetchExaminations = createAlertAction(FETCH_EXAMINATIONS);
export const createExamination = createAlertAction(CREATE_EXAMINATION);
export const saveExamination = createAlertAction(SAVE_EXAMINATION);
export const removeExamination = createAlertAction(REMOVE_EXAMINATION);
export const uploadMedia = createAlertAction(UPLOAD_MEDIA);
export const uploadXML = createAlertAction(UPLOAD_XML);
