import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import DateInput from 'components/DateInput';
import TimeInput from 'components/TimeInput';

const DateTime = ({ value, onChange, readMode }) => {
  const updateDate = useCallback(
    (d) => {
      d.setHours(value.getHours());
      d.setMinutes(value.getMinutes());
      onChange(d);
    },
    [value, onChange]
  );

  return (
    <>
      <DateInput
        value={value}
        label="Untersuchungsdatum"
        onChangeDate={updateDate}
        errorMessage="Ungültiges Datum."
        readMode={readMode}
      />
      <TimeInput
        label="Untersuchungszeit"
        date={value}
        onChange={onChange}
        errorMessage="Ungültige Uhrzeit."
        readMode={readMode}
      />
    </>
  );
};

DateTime.defaultProps = { value: new Date() };
DateTime.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  readMode: PropTypes.bool.isRequired,
};

export default DateTime;
