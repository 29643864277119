import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useCurrentSchedule } from 'store/planning';
import New from '../../../New';

const Step1 = ({ setStep }) => {
  const schedule = useCurrentSchedule();
  const onNext = useCallback(() => setStep(2), [setStep]);

  return <New data={schedule} onNext={onNext} />;
};

Step1.propTypes = { setStep: PropTypes.func.isRequired };

export default Step1;
