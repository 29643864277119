import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g fill="none" fillRule="evenodd">
      <g stroke="#979797" strokeWidth="1.5">
        <g>
          <g transform="translate(7 7)">
            <path d="M17.43 17.35L25.59 25.52" />
            <circle cx="10.58" cy="10.5" r="10" />
            <path d="M10.58 4.84L10.58 16.17M4.91 10.5L16.24 10.5" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
