import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

export const BackArrow = styled.div`
  height: 100%;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 100%;
  }
`;

export const ButtonContainer = styled.div`
  background-color: var(--color-greyF8);
  width: 40px;
  height: 40px;
  border-radius: var(--border-radius8);
  margin-right: 6px;

  &:hover {
    background-color: var(--color-greyEC);
    cursor: pointer;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyF8);
  color: var(--color-grey6A);
  font-size: var(--font-size18);
  font-weight: var(--font-weight400);
  padding: 0 13px;
`;

export const Text = styled.div`
  width: 100%;
`;
