export default {
  examination: {
    0: '#f5a623',
    1: '#0f7eff',
    2: '#00819e',
    3: 'var(--color-green65)',
    4: '#33e55d',
    5: '#009838',
    6: 'var(--color-grey6A)',
    7: 'var(--color-red)',
  },
  approval: {
    0: 'var(--color-green65)',
    1: '#009838',
    2: 'var(--color-red)',
    3: 'var(--color-grey6A)',
  },
  invoice: {
    0: '#f5a623',
    1: '#0f7eff',
    2: '#00819e',
    3: 'var(--color-green65)',
    4: '#33e55d',
    5: '#009838',
    6: 'var(--color-grey6A)',
    8: 'var(--color-grey6A)',
    9: 'var(--color-grey6A)',
    10: 'var(--color-red)',
  },
};
