import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { remove, adjust } from 'ramda';
import PropTypes from 'prop-types';

import FormContainer from 'components/FormContainer';
import FullScreenSpinner from 'components/FullScreenSpinner';
import TextInput from 'components/TextInputNew';
import { ID, PREVENT_ANALYSIS, useCurrentDiagnosis } from 'store/diagnoses';
import {
  SERVICES,
  TARMEDS_FILTERS,
  SUB_SERVICE,
  DATE,
  QUANTITY,
  SESSION,
  STATUS,
  INVOICE_STATUS,
  DURATION_IN_MINUTES,
  useInitInvoicesByExamination,
  useInvoicesActions,
  useInitPatientTarmeds,
  useCurrentInvoice,
} from 'store/invoices';
import { useCurrentExamination } from 'store/examinations';
import { TARMED, INVOICE, usePermissions } from 'permissions';

import BaseTarmeds from './BaseTarmeds';
import Table from './Table';
import SubmitSection from './SubmitSection';
import { useGetScrollWidth, useValidationTarmeds } from './hooks';
import { Section, SectionLabel, ScrollContainer, Line, Message, Warning, Span } from './styles';

const InvoicesForm = ({ values, setFieldValue, readMode }) => {
  const can = usePermissions();
  const { id: patientId } = useParams();
  const { updateSearch } = useInvoicesActions();
  const [examinationId, examDate] = useCurrentExamination([ID, DATE]);
  const { list: patientTarmeds, search, loading: tarmedsLoading } = useInitPatientTarmeds(can.read(TARMED), { patientId });
  const [dataError, setDataError] = useState(false);

  const updateSearchList = useCallback(
    (value) => {
      updateSearch({ kind: TARMEDS_FILTERS, value });
    },
    [updateSearch]
  );

  const { loading, success, error } = useInitInvoicesByExamination(can.read(INVOICE), examinationId);
  const invoiceStatus = useCurrentInvoice(STATUS);

  const isLoading = (loading && !(success || error)) || tarmedsLoading;
  const scrollWidth = useGetScrollWidth();
  const value = useMemo(() => values[SERVICES] || [], [values]);
  const [diagnosisId, noAnalysis] = useCurrentDiagnosis([ID, PREVENT_ANALYSIS]);

  const [comparedTarmeds, validationErrors, errorMessages] = useValidationTarmeds(value, examDate);

  const setBaseTarmeds = useCallback(
    (baseTarmeds = []) => {
      setFieldValue(
        SERVICES,
        baseTarmeds.map((item = {}) => ({
          [SUB_SERVICE]: item[ID],
          [QUANTITY]: 1,
          [SESSION]: 1,
          [DATE]: examDate,
        }))
      );
    },
    [setFieldValue, examDate]
  );

  const handleAddAction = useCallback(
    (id) => {
      setFieldValue(SERVICES, [...value, { [SUB_SERVICE]: id, [QUANTITY]: 1, [SESSION]: 1, [DATE]: examDate }]);
    },
    [examDate, setFieldValue, value]
  );

  const handleRemoveAction = useCallback(
    (index) => {
      setFieldValue(SERVICES, remove(index, 1, value));
    },
    [setFieldValue, value]
  );

  const handleChangeQuantity = useCallback(
    (index, data) => {
      setFieldValue(
        SERVICES,
        adjust(index, (item) => ({ ...item, [QUANTITY]: data }), value)
      );
    },
    [setFieldValue, value]
  );

  const handleChangeData = useCallback(
    (index, data) => {
      setFieldValue(
        SERVICES,
        adjust(index, (item) => ({ ...item, [DATE]: data }), value)
      );
    },
    [setFieldValue, value]
  );

  const handleChangeSession = useCallback(
    (index, data) => {
      setFieldValue(
        SERVICES,
        adjust(index, (item) => ({ ...item, [SESSION]: data }), value)
      );
    },
    [setFieldValue, value]
  );

  const handleChangeDuration = useCallback(
    (index, data) => {
      setFieldValue(
        SERVICES,
        adjust(index, (item) => ({ ...item, [DURATION_IN_MINUTES]: data }), value)
      );
    },
    [setFieldValue, value]
  );

  if (!examinationId && !diagnosisId) {
    return (
      <Section>
        <Message type="error">Kein Untersuch vorhanden</Message>
      </Section>
    );
  }

  if (isLoading) return <FullScreenSpinner />;

  if (noAnalysis) {
    return (
      <Section mt={50}>
        <SectionLabel>Bericht freigeben</SectionLabel>
        <SubmitSection readMode={readMode} disabled={dataError || errorMessages.length > 0} />
      </Section>
    );
  }

  if (readMode || invoiceStatus > INVOICE_STATUS.IN_PREPARATION) {
    return (
      <FormContainer>
        <Table title="Erfasste Tarmed-Positionen" list={comparedTarmeds} readMode isSelected />
        <Section mb={60}>
          <SectionLabel>Bericht und Rechnung</SectionLabel>
          <SubmitSection
            readMode={readMode || invoiceStatus > INVOICE_STATUS.SENT}
            disabled={dataError || errorMessages.length > 0}
          />
        </Section>
      </FormContainer>
    );
  }

  return (
    <FormContainer>
      <Section mb={30}>
        <SectionLabel>Rechnung</SectionLabel>
        <BaseTarmeds patientId={patientId} setBaseTarmeds={setBaseTarmeds} />
      </Section>
      <Section mb={60}>
        <SectionLabel>Suche</SectionLabel>
        <TextInput type="search" placeholder="Tarmeds suchen" value={search} handleChangeText={updateSearchList} mb={20} />
        <ScrollContainer scrollWidth={scrollWidth}>
          <Table list={patientTarmeds} action={handleAddAction} loading={tarmedsLoading} />
        </ScrollContainer>
        <Line />
      </Section>
      {errorMessages.length > 0 && (
        <Warning>
          {errorMessages.map((text) => (
            <Span key={text}>{text}</Span>
          ))}
        </Warning>
      )}
      <Table
        title="Erfasste Tarmed-Positionen"
        list={comparedTarmeds}
        action={handleRemoveAction}
        onChangeQuantity={handleChangeQuantity}
        onChangeDate={handleChangeData}
        onChangeSession={handleChangeSession}
        onChangeDuration={handleChangeDuration}
        setDataError={setDataError}
        validationErrors={validationErrors}
        isSelected
      />
      <Section mb={200}>
        <SectionLabel>Bericht und Rechnung</SectionLabel>
        <SubmitSection readMode={readMode} disabled={dataError || errorMessages.length > 0} />
      </Section>
    </FormContainer>
  );
};

InvoicesForm.propTypes = {
  values: PropTypes.shape({
    [SERVICES]: PropTypes.arrayOf(
      PropTypes.shape({
        [SUB_SERVICE]: PropTypes.string.isRequired,
        [QUANTITY]: PropTypes.number.isRequired,
      }).isRequired
    ),
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  readMode: PropTypes.bool.isRequired,
};

export default InvoicesForm;
