import React from 'react';

import { Roller, Dot } from './styles';

const Spinner = () => (
  <Roller>
    <Dot />
    <Dot />
    <Dot />
    <Dot />
    <Dot />
    <Dot />
  </Roller>
);

export default Spinner;
