import React, { useCallback, useState, useMemo } from 'react';
import { not, isEmpty } from 'ramda';
import PropTypes from 'prop-types';

import { ID, PRESSURE, RIGHT, LEFT, useExaminations, useCurrentExamination } from 'store/examinations';
import HistoryIcon from 'assets/svg-icons/HistoryIcon';
import AnimateBox from 'components/AnimateBox';

import { handleItems, getPressure } from './utils';
import { Container, Wrapper, Label, Button, CurrentItem, Item, DateText, Text } from './styles';

const PressureSection = ({ side }) => {
  const [isOpen, setIsOpen] = useState(false);
  const examinations = useExaminations();
  const [currentId, currentSide = {}] = useCurrentExamination([ID, side]);
  const currentEyePressure = useMemo(() => getPressure(currentSide), [currentSide]);

  const handleClick = useCallback(() => setIsOpen(not), []);

  const items = useMemo(() => handleItems(examinations, side), [examinations, side]);

  if (items.length <= 1 && isEmpty(currentSide[PRESSURE])) return null;

  return (
    <Container>
      <Wrapper>
        <Label>Augendruck (in mmHg)</Label>
        {items.length > 1 && (
          <Button type="button" onClick={handleClick}>
            <HistoryIcon isActive={isOpen} />
          </Button>
        )}
      </Wrapper>
      <CurrentItem>{currentEyePressure}</CurrentItem>
      {items.length > 1 && (
        <AnimateBox isOpen={isOpen}>
          {items.map(({ id, value, date }) => (
            <Item key={id} isActive={id === currentId}>
              <DateText>{date}</DateText>
              <Text>{value}</Text>
            </Item>
          ))}
        </AnimateBox>
      )}
    </Container>
  );
};

PressureSection.propTypes = {
  side: PropTypes.oneOf([RIGHT, LEFT]).isRequired,
};

export default PressureSection;
