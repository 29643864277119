import React from 'react';
import PropTypes from 'prop-types';

import { Container, Title } from './styles';

const Section = ({ title }) => (
  <Container>
    <Title>{title}</Title>
  </Container>
);

Section.propTypes = { title: PropTypes.string.isRequired };

export default Section;
