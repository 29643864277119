import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from './Button';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyF8);
  margin-bottom: 15px;
  padding: 5px 10px;
  opacity: ${({ disabled }) => (disabled ? 0.75 : 1)};

  &:hover {
    background-color: var(--color-greyEC);
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

const Label = styled.span`
  font-size: var(--font-size18);
  color: var(--color-grey6A);
  align-self: center;
`;

const AddItemBar = ({ label, onClick, disabled }) => (
  <Container onClick={onClick} disabled={disabled}>
    <Label>{label}</Label>
    <Button size={28} />
  </Container>
);

AddItemBar.defaultProps = {
  disabled: false,
};

AddItemBar.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default AddItemBar;
