import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 280px;
  margin-bottom: 50px;
`;

export const Wrapper = styled.div`
  & > * {
    margin-bottom: 10px;
  }
`;
