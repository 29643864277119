import React, { useCallback, useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';

import Augenmobil from 'assets/svg-icons/Augenmobil';
import { FIRST_NAME, useUser, useLoggedIn, useSessionActions } from 'store/session';

import { useNavigationItems, useAmountOfItems } from './hooks';
import DropDown from './DropDown';
import CacheReport from './CacheReport';
import ErrorReport from './ErrorReport';
import AppError from './AppError';
import Notification from './Notification';
import LlmLink from './LlmLink';
import { Container, NavbarItems, NavbarLink, Wrapper, NavbarExtra, NotificationsContainer } from './styles';

const Navbar = () => {
  const { pathname } = useLocation();
  const navigationItems = useNavigationItems();
  const { logout } = useSessionActions();
  const onLogout = useCallback(() => logout(), [logout]);
  const items = useMemo(
    () =>
      navigationItems.map(({ path, label, regexp }) => ({
        path,
        label,
        disabled: regexp ? regexp.test(pathname) : pathname.includes(path),
      })),
    [navigationItems, pathname]
  );

  const amountOfMainItems = useAmountOfItems();
  const [mainItems, dropDownItems, buttonsAmount] = useMemo(() => {
    const main = items.length - amountOfMainItems > 1 ? items.slice(0, amountOfMainItems) : items;
    const dropdown = items.length - amountOfMainItems > 1 ? items.slice(amountOfMainItems) : [];

    return [main, dropdown, main.length + (dropdown.length ? 3 : 2)];
  }, [amountOfMainItems, items]);

  const isLoggedIn = useLoggedIn();
  const fullName = useUser(FIRST_NAME);

  return (
    <>
      <Container>
        <Link to="/">
          <Augenmobil />
        </Link>
        <NavbarItems $length={buttonsAmount}>
          {isLoggedIn && (
            <>
              {mainItems.map(({ path, label, disabled }) => (
                <NavbarLink key={path} to={path} disabled={disabled}>
                  {label}
                </NavbarLink>
              ))}
              <DropDown items={dropDownItems} />
            </>
          )}
          {isLoggedIn && (
            <Wrapper>
              {fullName ? (
                <NavbarLink disabled={pathname.includes('/profile')} to="/profile">
                  {fullName}
                </NavbarLink>
              ) : (
                <div />
              )}
              <NavbarLink onClick={onLogout} to={pathname}>
                Abmelden
              </NavbarLink>
            </Wrapper>
          )}
        </NavbarItems>
        <NavbarExtra>
          {isLoggedIn && <CacheReport />}
          <ErrorReport />
          <LlmLink />
        </NavbarExtra>
      </Container>
      <NotificationsContainer>
        <AppError />
        <Notification />
      </NotificationsContainer>
    </>
  );
};

export default Navbar;
