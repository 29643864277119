import React from 'react';
import { useField } from 'formik';

import PaginationDropDown from 'components/PaginationDropDown';
import { ID, NAME, SUB_OPTICIAN, ORGANIZATION_KINDS, useCollectOrganizationsAction } from 'store/organizations';

const OpticianForm = () => {
  const getOrganizations = useCollectOrganizationsAction(ORGANIZATION_KINDS.OPTICIAN);
  const [{ value }, , { setValue }] = useField(SUB_OPTICIAN);

  return (
    <PaginationDropDown
      idKey={ID}
      nameKey={NAME}
      action={getOrganizations}
      placeholder="Optiker suchen"
      value={value}
      onChange={setValue}
      onlyOne
    />
  );
};

export default OpticianForm;
