import { handleActions } from 'redux-actions';

import { UPDATE_MEDICAL_HISTORY, RESET_MEDICAL_HISTORY } from './types';

export default handleActions(
  {
    [UPDATE_MEDICAL_HISTORY]: (_, { payload }) => payload,
    [RESET_MEDICAL_HISTORY]: () => ({}),
  },
  {}
);
