import React, { useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { path, pipe, propOr, filter, propEq } from 'ramda';

import LabelText from 'components/LabelText';
import FormContainer from 'components/FormContainer';

import { exteriorEyeQuestions, fundusEyeQuestions } from 'assets/analysisQuestions';
import { RIGHT, LEFT, EYE_EXTERIOR, FUNDUS, useCurrentDiagnosis } from 'store/diagnoses';
import {
  ID,
  COMMENT,
  MOBILITY,
  STATUS,
  SUB_PATIENT,
  SUB_EXAMINATION,
  EXAMINATION_STATUS,
  useCurrentExamination,
  useExaminationsActions,
  useAlerts,
} from 'store/examinations';
import { COMMENT as MED_COMMENT, DIABETES, useMedicalHistory } from 'store/medicalHistory';

import PressureSection from './PressureSection';
import VisualAcuitySection from './VisualAcuitySection';
import MobilitySection from './MobilitySection';
import EyeIcon from './EyeIcon';
import ConfirmButton from './ConfirmButton';
import CheckList from './CheckList';
import {
  EyeSelectorContainer,
  EyeSelectorSection,
  EyeIconContainer,
  EyeLabel,
  Button,
  AnalyzeImagesButton,
  OverviewContainer,
  Section,
  SectionLabel,
  Message,
  Warning,
  P,
} from './styles';

const EyeAnalysisForm = ({ readMode }) => {
  const [examId, examComment, mobility, examStatus] = useCurrentExamination([ID, COMMENT, MOBILITY, STATUS]);

  const { saveExamination } = useExaminationsActions();
  const { extractId, loading } = useAlerts();

  const [isDiabetes, medicalHistoryComment] = useMedicalHistory([DIABETES, MED_COMMENT]);

  const history = useHistory();
  const [selection, setSelection] = useState({
    eye: path(['location', 'state', 'eye'], history) || '',
    area: path(['location', 'state', 'area'], history) || '',
  });
  const [patient, exam, areaData = []] = useCurrentDiagnosis([SUB_PATIENT, SUB_EXAMINATION, selection.area]);
  const data = useMemo(() => pipe(filter(propEq(selection.eye, 'eye')), propOr({}, 0))(areaData), [areaData, selection.eye]);

  const rightEyeSelected = selection.eye === RIGHT;
  const leftEyeSelected = selection.eye === LEFT;
  const eyeExteriorSelected = selection.area === EYE_EXTERIOR;
  const fundusSelected = selection.area === FUNDUS;
  const eyeAndAreaSelected = !!selection.eye;

  const getEyeIcon = (eyeSide) => {
    if (eyeSide && eyeExteriorSelected) return <EyeIcon kind={EYE_EXTERIOR} />;
    if (eyeSide && fundusSelected) return <EyeIcon kind={FUNDUS} />;
    return <EyeIcon />;
  };

  const getQuestions = () => {
    if (!eyeAndAreaSelected) return [];
    return selection.area === EYE_EXTERIOR ? exteriorEyeQuestions : fundusEyeQuestions;
  };

  const getSelectedCheckboxes = (arr) => {
    try {
      return arr.reduce((acc, text, index) => `${acc}${index === 0 ? '' : ', '}${text}`, '');
    } catch (error) {
      return '';
    }
  };

  const addCommentText = useCallback((text) => (text ? `. ${text}` : ''), []);
  const resetStatus = useCallback(
    () => extractId(saveExamination({ [ID]: examId, [STATUS]: 0 })),
    [examId, extractId, saveExamination]
  );

  const redirectToDiagnosis = useCallback(
    (question) => () => {
      const { eye, area } = selection;

      if (!patient || !exam) return;

      const query = question
        ? qs.stringify({
            exam,
            eye,
            area,
            question,
          })
        : qs.stringify({ exam, eye, area });

      history.push(`/patients/${patient}/diagnosis?${query}`, history.location.state);
    },
    [exam, history, patient, selection]
  );

  if (!examId) {
    return (
      <Section>
        <Message type="error">Kein Untersuch vorhanden</Message>
      </Section>
    );
  }

  return (
    <FormContainer>
      <CheckList />
      {examComment && <Warning>{examComment}</Warning>}
      {(medicalHistoryComment || isDiabetes) && (
        <Warning>
          {isDiabetes && <P mt="3px">Patient mit Diabetes.</P>}
          {Boolean(medicalHistoryComment && isDiabetes) && (
            <>
              <br />
              <br />
            </>
          )}
          <P>{medicalHistoryComment}</P>
        </Warning>
      )}
      <EyeSelectorSection>
        <EyeSelectorContainer isActive={rightEyeSelected}>
          <EyeLabel>Rechtes Auge</EyeLabel>
          <EyeIconContainer>{getEyeIcon(rightEyeSelected)}</EyeIconContainer>
          <Button
            isActive={rightEyeSelected && eyeExteriorSelected}
            disabled={rightEyeSelected && eyeExteriorSelected}
            onClick={() => setSelection({ eye: RIGHT, area: EYE_EXTERIOR })}
          >
            Vorderes Auge
          </Button>
          <Button
            isActive={rightEyeSelected && fundusSelected}
            disabled={rightEyeSelected && fundusSelected}
            onClick={() => setSelection({ eye: RIGHT, area: FUNDUS })}
          >
            Augenhintergrund
          </Button>
        </EyeSelectorContainer>
        <EyeSelectorContainer isActive={leftEyeSelected}>
          <EyeLabel>Linkes Auge</EyeLabel>
          <EyeIconContainer>{getEyeIcon(leftEyeSelected)}</EyeIconContainer>
          <Button
            isActive={leftEyeSelected && eyeExteriorSelected}
            disabled={leftEyeSelected && eyeExteriorSelected}
            onClick={() => setSelection({ eye: LEFT, area: EYE_EXTERIOR })}
          >
            Vorderes Auge
          </Button>
          <Button
            isActive={leftEyeSelected && fundusSelected}
            disabled={leftEyeSelected && fundusSelected}
            onClick={() => setSelection({ eye: LEFT, area: FUNDUS })}
          >
            Augenhintergrund
          </Button>
        </EyeSelectorContainer>
      </EyeSelectorSection>
      <AnalyzeImagesButton disabled={!eyeAndAreaSelected} onClick={redirectToDiagnosis()}>
        {readMode ? 'BILDER ANZEIGEN' : 'ZUR BEFUNDUNG'}
      </AnalyzeImagesButton>
      {eyeAndAreaSelected && (
        <OverviewContainer>
          {selection.area === EYE_EXTERIOR && selection.eye && <PressureSection side={selection.eye} />}
          {selection.area === EYE_EXTERIOR && selection.eye && <VisualAcuitySection side={selection.eye} />}
          <Section pb={40}>
            <SectionLabel>Mobilität</SectionLabel>
            <MobilitySection value={mobility} />
          </Section>
          <Section>
            <SectionLabel>{`${selection.area === EYE_EXTERIOR ? 'Vorderes Auge' : 'Fundus'}`}</SectionLabel>
            {getQuestions().map(({ key: label, prop }) => (
              <LabelText
                key={`question_${prop}`}
                label={label}
                text={data[prop]}
                onClick={redirectToDiagnosis(prop)}
                readonly={readMode}
              />
            ))}
          </Section>
          {selection.area === FUNDUS && (
            <>
              <Section>
                <SectionLabel>Makula</SectionLabel>
                <LabelText
                  text={`${getSelectedCheckboxes(data.macula)}${addCommentText(data.maculaDescription)}`}
                  onClick={redirectToDiagnosis()}
                  readonly={readMode}
                />
              </Section>
              <Section>
                <SectionLabel>Netzhautperipherie</SectionLabel>
                <LabelText
                  text={`${getSelectedCheckboxes(data.peripheralVision)}${addCommentText(data.peripheralVisionDescription)}`}
                  onClick={redirectToDiagnosis()}
                  readonly={readMode}
                />
              </Section>
              <Section>
                <SectionLabel>Papille</SectionLabel>
                <LabelText
                  text={`${getSelectedCheckboxes(data.papilla)}${addCommentText(data.papillaDescription)}`}
                  onClick={redirectToDiagnosis()}
                  readonly={readMode}
                />
              </Section>
            </>
          )}
          <Section>
            <SectionLabel>Kommentar</SectionLabel>
            <LabelText text={data.description} onClick={redirectToDiagnosis()} readonly={readMode} />
          </Section>
        </OverviewContainer>
      )}
      {(examStatus === EXAMINATION_STATUS.SUBMITTED || examStatus === EXAMINATION_STATUS.UNDER_REVIEW) && (
        <Section pb={30}>
          <SectionLabel>Zurücksetzen</SectionLabel>
          <ConfirmButton onClick={resetStatus} disabled={loading}>
            An techn. Personal zurücksenden
          </ConfirmButton>
        </Section>
      )}
    </FormContainer>
  );
};

EyeAnalysisForm.propTypes = {
  readMode: PropTypes.bool.isRequired,
};

export default EyeAnalysisForm;
