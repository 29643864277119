import styled from 'styled-components';

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px auto;
  width: 90%;
  max-width: 470px;
`;

export const LoginTitle = styled.h1`
  font-size: var(--font-size30);
  color: var(--color-grey6A);
  font-weight: var(--font-weight400);
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 20px;
`;

export const FormContainer = styled.form`
  margin: 40px 0px;
  background: 'red';
`;

export const LinkContainer = styled.div`
  margin-top: 20px;
  text-align: center;
  width: 100%;
`;

export const ExternContainer = styled(LinkContainer)`
  margin-top: 60px;
  color: var(--color-grey6A);
`;
