import React from 'react';

import { useSearchStatuses } from 'permissions';

import Section from './Section';
import { Container } from './styles';

const OpenExaminations = () => {
  const searchStatuses = useSearchStatuses();

  return (
    <Container>
      {searchStatuses.map((status) => (
        <Section key={status} status={status} />
      ))}
    </Container>
  );
};

export default OpenExaminations;
