import React from 'react';
import PropTypes from 'prop-types';

import { EYE_EXTERIOR, FUNDUS } from 'store/diagnoses';

const mapping = {
  [EYE_EXTERIOR]: {
    color: 'var(--color-blue50)',
    outside: 'rgba(80, 227, 194, 0.27)',
    inside: 'var(--color-greenF6)',
  },
  [FUNDUS]: {
    color: 'var(--color-blue50)',
    outside: 'var(--color-greenF6)',
    inside: 'rgba(80, 227, 194, 0.27)',
  },
};

const defaultColors = {
  color: '#9a9a9a',
  outside: 'none',
  inside: 'none',
};

const EyeIcon = ({ kind }) => {
  const { color, outside, inside } = (kind && mapping[kind]) || defaultColors;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 358 195">
      <g fill="none" stroke="none" transform="translate(1 1)">
        <path fill={outside} stroke={color} d="M0 97C124.86-32.33 243.53-32.33 356 97m0-1C231.14 225.33 112.47 225.33 0 96" />
        <circle fill={inside} stroke={color} cx="179.5" cy="96.5" r="96.5" />
        <circle fill={inside} stroke={color} cx="180" cy="97" r="58" />
      </g>
    </svg>
  );
};

EyeIcon.defaultProps = {
  kind: null,
};

EyeIcon.propTypes = {
  kind: PropTypes.string,
};

export default EyeIcon;
