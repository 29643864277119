/* eslint-disable react/no-array-index-key */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { CREATE, DELETE } from './consts';
import InputRow from './InputRow';
import { NoDocuments } from '../MultiImage/styles';

const MultiAnswerInput = ({ values, label, handleListChange, readMode, errors, type }) => {
  const handleChange = useCallback(
    (index) => (text) => {
      const list = [...values];
      list[index] = text;

      handleListChange(list);
    },
    [values, handleListChange]
  );

  const handleCreate = useCallback(() => {
    if (errors) return;
    if (values.length === 0) {
      handleListChange(['', '', ...values]);
      return;
    }
    handleListChange(['', ...values]);
  }, [errors, values, handleListChange]);

  const handleDelete = useCallback(
    (index) => () => {
      handleListChange(values.filter((_, i) => i !== index));
    },
    [values, handleListChange]
  );

  const getError = useMemo(
    () => (index) => {
      if (!errors || !errors.keys.includes(index)) return null;

      return errors.message;
    },
    [errors]
  );

  if (!values) return null;

  if (values.length === 0 && !readMode) {
    return (
      <InputRow
        key={0}
        label={label}
        type={type}
        value={values[0]}
        onChange={handleChange(0)}
        btnType={CREATE}
        onClick={handleCreate}
        readMode={readMode}
        error={getError(0)}
      />
    );
  }

  if (values.length === 0 && readMode) {
    return <NoDocuments>Keine Messwerte vorhanden</NoDocuments>;
  }

  return values.map((_, id) => (
    <InputRow
      key={id}
      label={label}
      type={type}
      value={values[id]}
      onChange={handleChange(id)}
      btnType={id === 0 ? CREATE : DELETE}
      onClick={id === 0 ? handleCreate : handleDelete(id)}
      readMode={readMode}
      error={getError(id)}
    />
  ));
};

MultiAnswerInput.defaultProps = {
  readMode: false,
  values: [],
  label: '',
  handleListChange: () => null,
  errors: null,
  type: 'text',
};

MultiAnswerInput.propTypes = {
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  label: PropTypes.string,
  handleListChange: PropTypes.func,
  readMode: PropTypes.bool,
  errors: PropTypes.shape({
    keys: PropTypes.number,
    message: PropTypes.string,
  }),
  type: PropTypes.string,
};

export default MultiAnswerInput;
