import styled from 'styled-components';

export const Container = styled.button`
  width: 100%;
  min-height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: var(--border-radius8);
  background: none;
  font-weight: ${({ bold }) => (bold ? 'var(--font-weight600)' : 'var(--font-weight400)')};
  &:hover {
    background-color: var(--color-greyF8);
  }
`;

export const Item = styled.div`
  width: ${({ width }) => `calc(${width} - 10px)`};
  font-size: var(--font-size20);
  color: var(--color-grey6A);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
`;

export const Wrapper = styled.div`
  width: ${({ width }) => `calc(${width} - 10px)`};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Art = styled(Item)`
  width: calc(100% - 30px);
`;

export const Status = styled.div`
  width: 18px;
  height: 18px;
  background: ${({ status }) => (status === 0 ? 'var(--color-green65)' : 'none')};
  border-radius: 9px;
`;
