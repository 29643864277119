import React, { useState, useCallback, useEffect, memo, Fragment } from 'react';
import PropTypes from 'prop-types';

import AddItemBar from 'components/AddItemBar';
import Warning from 'components/Warning';
import QuestionContainer from 'components/QuestionContainer';
import FormContainer from 'components/FormContainer';
import { DIABETES, COMMENT as EXAMINATION_COMMENT, useMedicalHistory } from 'store/medicalHistory';
import { EXAMINATION_REAL_TIME, usePermissions } from 'permissions';
import { RIGHT, LEFT, VIEW, FUNDUS, EXAMINATION_STATUS, useBlockedExamination, useExaminationsActions } from 'store/examinations';

import MediaForm from './MediaForm';
import ItemContainer from './ItemContainer';
import PressureForm from './PressureForm';
import VisualAcuityForm from './VisualAcuityForm';
import DateForm from './DateForm';
import Comment from './Comment';
import CheckList from './CheckList';
import Mobility from './Mobility';
import Submitting from './Submitting';

import { ButtonContainer, MediaContainer, Button, Message, BlockMessage } from './styles';
import { mapPropsToValues } from './utils';

const sides = [RIGHT, LEFT];

const ExaminationForm = ({ history, match, resetForm, examId, statusExam, readMode, right, left, date, comment, mobility }) => {
  const can = usePermissions();
  const [side, setSide] = useState(RIGHT);
  const { isBlocked, userName } = useBlockedExamination(can.read(EXAMINATION_REAL_TIME), can.update(EXAMINATION_REAL_TIME));
  const [isHasDiabetes, examinationComment] = useMedicalHistory([DIABETES, EXAMINATION_COMMENT]);
  const { createExamination } = useExaminationsActions();

  useEffect(() => {
    resetForm({
      values: mapPropsToValues({
        right,
        left,
        date,
        comment,
        mobility,
      }),
      errors: {},
    });
  }, [comment, date, left, resetForm, right, mobility]);

  const handleClick = (name) => () => setSide(name);

  const handleCreateExamination = useCallback(() => {
    createExamination(match.params.id);
    history.push(match.url, history.location.state);
  }, [createExamination, history, match.params.id, match.url]);

  const getWarning = useCallback(
    () => `${isHasDiabetes ? 'Patient mit Diabetes. \n' : ''}${examinationComment || ''}`,
    [isHasDiabetes, examinationComment]
  );

  if (!examId) {
    return (
      <FormContainer>
        <AddItemBar label="Neuer Untersuch" onClick={handleCreateExamination} />
        <Message type="error">Es gibt noch keine Untersuchung für diesen Patienten.</Message>
      </FormContainer>
    );
  }

  return (
    <FormContainer>
      <CheckList disabled={isBlocked || statusExam !== EXAMINATION_STATUS.IN_PREPARATION} />
      {isBlocked && (
        <BlockMessage>{`Während ${userName} den Untersuch bearbeitet, können Sie keine Änderungen vornehmen.`}</BlockMessage>
      )}
      <AddItemBar label="Neuer Untersuch" onClick={handleCreateExamination} />
      <QuestionContainer label="Untersuchung">
        <DateForm readMode={readMode} />
      </QuestionContainer>
      {(isHasDiabetes || examinationComment) && <Warning>{getWarning()}</Warning>}
      <QuestionContainer label="Bilder">
        <ButtonContainer>
          <Button color="success" isActive={side === RIGHT} disabled={side === RIGHT} onClick={handleClick(RIGHT)}>
            Rechtes Auge
          </Button>
          <Button color="success" isActive={side === LEFT} disabled={side === LEFT} onClick={handleClick(LEFT)}>
            Linkes Auge
          </Button>
        </ButtonContainer>
        {sides.map((key) => {
          if (key !== side) return null;

          return (
            <Fragment key={key}>
              <MediaContainer>
                <MediaForm side={key} feature={VIEW} readMode={readMode} />
                <MediaForm side={key} feature={FUNDUS} readMode={readMode} />
              </MediaContainer>
              <ItemContainer label={`Augendruck ${key === RIGHT ? 'OD' : 'OS'} (in mmHg)`}>
                <PressureForm side={key} readMode={readMode} />
              </ItemContainer>
              <ItemContainer label={`Sehschärfe ${key === RIGHT ? 'OD' : 'OS'}`}>
                <VisualAcuityForm side={key} resetForm={resetForm} readMode={readMode} />
              </ItemContainer>
            </Fragment>
          );
        })}
        <QuestionContainer label="Mobilität">
          <Mobility readMode={readMode} />
        </QuestionContainer>
        <QuestionContainer label="Kommentar">
          <Comment readMode={readMode} />
        </QuestionContainer>
        <Submitting examinationId={examId} examinationStatus={statusExam} readMode={readMode} />
      </QuestionContainer>
    </FormContainer>
  );
};

ExaminationForm.defaultProps = {
  examId: null,
  date: null,
  comment: null,
  right: {},
  left: {},
  statusExam: null,
  mobility: null,
};

ExaminationForm.propTypes = {
  readMode: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      state: PropTypes.objectOf(PropTypes.string),
    }).isRequired,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  resetForm: PropTypes.func.isRequired,
  examId: PropTypes.string,
  statusExam: PropTypes.number,
  right: PropTypes.shape({}),
  left: PropTypes.shape({}),
  date: PropTypes.string,
  comment: PropTypes.string,
  mobility: PropTypes.string,
};

export default memo(
  ExaminationForm,
  (prev, next) =>
    prev.statusExam === next.statusExam &&
    prev.readMode === next.readMode &&
    prev.match.url === next.match.url &&
    prev.examId === next.examId
);
