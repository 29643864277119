import React, { useCallback, useEffect } from 'react';
import { useField } from 'formik';
import { path } from 'ramda';
import PropTypes from 'prop-types';

import { DIAGNOSIS, LEFT, RIGHT } from 'store/diagnoses';
import Checkbox from 'components/CheckboxNew';
import { CheckBoxWrap } from '../styles';

const CheckBox = ({ items, readMode, side, parentValue }) => {
  const itemId = path([0, 'id'], items);
  const [{ value }, , { setValue }] = useField(`${DIAGNOSIS}.${side}.${itemId}`);
  const handleChange = useCallback(
    (nextValue) => () => {
      setValue(nextValue === value ? '' : nextValue);
    },
    [setValue, value]
  );

  useEffect(() => {
    if (!parentValue && value) {
      setValue('');
    }
  }, [parentValue, value, setValue]);

  return items.map(({ value: val, label }) => (
    <CheckBoxWrap key={val} inActive={value && val !== value}>
      <Checkbox label={label} selected={val === value} onClick={handleChange(val)} readMode={readMode} side={side} />
    </CheckBoxWrap>
  ));
};

CheckBox.defaultProps = {
  readMode: false,
};

CheckBox.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  readMode: PropTypes.bool,
  parentValue: PropTypes.bool.isRequired,
  side: PropTypes.oneOf([LEFT, RIGHT]).isRequired,
};

export default CheckBox;
