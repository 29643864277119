import { map, applySpec, pipe, path, propOr, prop, either } from 'ramda';

import {
  ID,
  VISUAL_ACUITY,
  DATE,
  WITHOUT_CORRECTION,
  WITH_GLASSES,
  BEST_CORRECTION,
  PUPIL_SIZE,
  SPHERE,
  CYLINDER,
  AXIS,
  CONFIDENCE,
} from 'store/examinations';
import { parseDate } from 'utils';

const getDate = either(prop(DATE), path(['meta', 'modified']));

export const getVisualAcuity = pipe(propOr({}, VISUAL_ACUITY), ($) => {
  const highlightAutorefraction = !$[CONFIDENCE] && Boolean($[SPHERE] || $[CYLINDER] || $[AXIS]);

  return [
    {
      title: 'Ohne Korrektur',
      items: [{ id: WITHOUT_CORRECTION, value: $[WITHOUT_CORRECTION] }],
      width: 190,
    },
    {
      title: 'Mit bestehender Brille',
      items: [{ id: WITH_GLASSES, value: $[WITH_GLASSES] }],
      width: 190,
    },
    {
      title: 'Bestmöglich korrigiert',
      items: [{ id: BEST_CORRECTION, value: $[BEST_CORRECTION] }],
      width: 190,
    },
    {
      title: 'Pupillengr.',
      items: [{ id: PUPIL_SIZE, value: $[PUPIL_SIZE] }],
    },
    {
      title: 'Autorefraktion:',
      items: [{ id: SPHERE, name: 'Sphäre', value: $[SPHERE], isRed: highlightAutorefraction }],
    },
    { items: [{ id: CYLINDER, name: 'Cylinder', value: $[CYLINDER], isRed: highlightAutorefraction }] },
    { items: [{ id: AXIS, name: 'Achse', value: $[AXIS], isRed: highlightAutorefraction }] },
    { items: [{ id: CONFIDENCE, name: 'Messqualität', value: $[CONFIDENCE], isRed: highlightAutorefraction }] },
  ];
});

export const handleItems = (list, side) =>
  map(
    applySpec({
      id: prop(ID),
      values: pipe(propOr({}, side), getVisualAcuity),
      date: pipe(getDate, parseDate),
    })
  )(list || []);
