import styled from 'styled-components';

export const Container = styled.div`
  width: 90%;
  max-width: 1024px;
  margin: 30px auto 50px auto;
`;

export const PageUrl = styled.div`
  background: var(--color-greyF3);
  border-radius: var(--border-radius8);
  padding: 10px 40px;
  margin-bottom: 20px;
`;

export const Text = styled.div`
  background: var(--color-greyF3);
  border-radius: var(--border-radius8);
  padding: 40px;
`;
