import React, { useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { parseBirthday, parseDateWithTime } from 'utils';

import Icon from './Icon';
import Popup from './Popup';
import { Container, NavLink, Text } from './styles';

const Row = ({
  id,
  widths,
  patient,
  organization,
  publicId,
  date,
  firstName,
  lastName,
  birth,
  ward,
  status,
  confidence,
  statusData,
  isBlocked,
  readMode,
}) => {
  const { id: planningId } = useParams();
  const { label, color } = statusData;
  const [isOpenPopup, setOpenPopup] = useState(false);
  const onHoverStart = useCallback(() => setOpenPopup(true), []);
  const onHoverEnd = useCallback(() => setOpenPopup(false), []);
  const [dateString, birthString] = useMemo(() => [parseDateWithTime(date), parseBirthday(birth) || '–'], [birth, date]);
  const disabled = readMode || isBlocked;
  const editRoute = useMemo(
    () => (disabled ? `/planning/${planningId}` : `/planning/${planningId}/${id}`),
    [id, planningId, disabled]
  );

  return (
    <Container onHoverStart={onHoverStart} onHoverEnd={onHoverEnd}>
      <NavLink to={editRoute} $readMode={disabled}>
        <Text $width={widths[0]}>{publicId || '–'}</Text>
        <Text $width={widths[1]}>{dateString}</Text>
        <Text $width={widths[2]}>{firstName || '–'}</Text>
        <Text $width={widths[3]}>{lastName || '–'}</Text>
        <Text $width={widths[4]} $fontSize={13}>
          {birthString}
        </Text>
        <Text $width={widths[5]}>{ward || '–'}</Text>
        <Text $width={widths[6]} $fontSize={10} $color={color}>
          {label}
        </Text>
      </NavLink>
      {!disabled && <Icon patientId={id} />}
      {isOpenPopup && (
        <Popup
          id={id}
          status={status}
          confidence={confidence}
          patient={patient}
          organization={organization}
          firstName={firstName}
          lastName={lastName}
          birth={birth}
          readMode={disabled}
        />
      )}
    </Container>
  );
};

Row.defaultProps = {
  patient: null,
  organization: null,
  publicId: null,
  firstName: null,
  lastName: null,
  birth: null,
  ward: null,
};
Row.propTypes = {
  id: PropTypes.string.isRequired,
  widths: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  patient: PropTypes.shape({}),
  organization: PropTypes.shape({}),
  publicId: PropTypes.string,
  date: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  birth: PropTypes.string,
  ward: PropTypes.string,
  status: PropTypes.number.isRequired,
  confidence: PropTypes.number.isRequired,
  statusData: PropTypes.shape({
    label: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
  isBlocked: PropTypes.bool.isRequired,
  readMode: PropTypes.bool.isRequired,
};

export default Row;
