import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import TextInput from 'components/TextInputNew';

import Radio from './Radio';
import { Container, Label, Wrapper, Message } from './styles';

const Radios = ({ label, items, name, value, setValue, onChange, error, disabled, readMode, mt, mb, children }) => {
  const itemsMap = useMemo(() => {
    if (!readMode) return null;

    const map = new Map();

    items.forEach((item) => {
      map.set(item.value, item);
    });

    return map;
  }, [items, readMode]);

  return (
    <Container label={label} mt={readMode ? 10 : mt} mb={mb}>
      {label && <Label readMode={readMode}>{label}</Label>}
      {readMode ? (
        <TextInput value={itemsMap.get(value)?.label} mb={0} readMode />
      ) : (
        <>
          <Wrapper>
            {items.map((item) => (
              <Radio
                key={item.value}
                {...item}
                name={name}
                checked={item.value === value}
                onChange={onChange}
                setValue={setValue}
                disabled={disabled}
                error={error}
              />
            ))}
            {children}
          </Wrapper>
          {error && !readMode && typeof error === 'string' && <Message $label={label}>{error}</Message>}
        </>
      )}
    </Container>
  );
};

Radios.defaultProps = {
  label: null,
  items: [],
  name: undefined,
  value: null,
  setValue: () => null,
  onChange: () => null,
  error: null,
  disabled: false,
  readMode: false,
  mt: 15,
  mb: 10,
  children: null,
};
Radios.propTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    }).isRequired
  ),
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  setValue: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
  readMode: PropTypes.bool,
  mt: PropTypes.number,
  mb: PropTypes.number,
  children: PropTypes.node,
};

export { Radio };

export default Radios;
