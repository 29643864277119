import React from 'react';
import PropTypes from 'prop-types';

import Time from './Time';
import { Container, Wrapper, Task } from './styles';

const Tasks = ({ progress }) => (
  <Container>
    {progress.map(({ id, name, done, time }) => (
      <Wrapper key={id} width={100 / progress.length}>
        {done && time && <Time time={time} />}
        <Task done={done}>{name}</Task>
      </Wrapper>
    ))}
  </Container>
);

Tasks.propTypes = {
  progress: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      done: PropTypes.bool,
      time: PropTypes.string,
    }).isRequired
  ).isRequired,
};

export default Tasks;
