import React from 'react';

export default (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#979797">
      <g transform="translate(1, 1)">
        <circle cx="20.5" cy="20.5" r="20.5" fill="#FFF" fillRule="nonzero" />
        <path strokeWidth="1.5" d="M16 .84L16 31.16M.84 16L31.16 16" transform="translate(5, 5)" />
      </g>
    </g>
  </svg>
);
