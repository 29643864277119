import { useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { path } from 'ramda';

import { useAsyncState } from 'utils/useAsyncState';
import { wrapActions } from 'store/utils';

import { META, TASK_ID } from './consts';
import * as actions from './actions';
import { getSuccess, getError, getErrors, getLoading } from './selectors';

export const useAlertsActions = wrapActions({ ...actions });

export const useAlerts = (actionCreator) => {
  const index = useRef(0);
  const [id, setId] = useAsyncState();

  const success = useSelector(getSuccess(id));
  const error = useSelector(getError(id));
  const errors = useSelector(getErrors(id));
  const loading = useSelector(getLoading(id));
  const { setCancel } = useAlertsActions();

  const extractId = useCallback(
    (action) => {
      const idx = path([META, TASK_ID], action);
      if (!idx) return;

      setId(idx);
      index.current = idx;
    },
    [setId]
  );

  const handleAction = useCallback((...args) => extractId(actionCreator(...args)), [actionCreator, extractId]);

  const resetAlerts = useCallback(() => {
    if (index.current) {
      setId();
      setCancel({ id: index.current });
      index.current = 0;
    }
  }, [setCancel, setId]);

  useEffect(
    () => () => {
      if (index.current) setCancel({ id: index.current });
    },
    [setCancel]
  );

  return {
    id,
    extractId,
    action: handleAction,
    success: id && success,
    error: id && error,
    errors: id && errors,
    loading: id && loading,
    resetAlerts,
  };
};
