import styled, { keyframes } from 'styled-components';

const ring = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: ${({ size }) => (size ? size + 10 : 60)}px;
  height: ${({ height }) => height || 'calc(100vh - 72px)'};
  justify-content: center;
  align-items: center;
`;

export const Ring = styled.div`
  position: relative;
  & > div {
    animation: ${ring} 1s linear infinite;
  }
  & > div,
  & > div:after {
    position: absolute;
    width: ${({ size }) => size || 50}px;
    height: ${({ size }) => size || 50}px;
    border: 3px solid #51cacc;
    border-top-color: var(--color-white);
    border-radius: 50%;
  }
  & > div:after {
    transform: rotate(90deg);
  }
`;
