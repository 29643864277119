import React, { useCallback, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { pathOr, mergeDeepRight } from 'ramda';
import PropTypes from 'prop-types';

import { usePlanningActions, useAlerts, SUB_FORM, ID, SUB_EYE_DOCTOR, SUB_GP } from 'store/planning';
import Button from 'components/Button';
import { validationSchema, extractFormDataFromMedia, excludeErrorFields } from '../utils';
import { Message } from '../styles';

const Submit = ({ pdfForm }) => {
  const { id } = useParams();
  const { createRegistration } = usePlanningActions();
  const { action, loading, success, error } = useAlerts(createRegistration);
  const { values, setValues, setErrors } = useFormikContext();
  const formId = pdfForm && pdfForm[ID];

  const onSubmit = useCallback(async () => {
    const extraFields = {
      [SUB_EYE_DOCTOR]: pathOr(null, [SUB_EYE_DOCTOR, ID], values),
      [SUB_GP]: pathOr(null, [SUB_GP, ID], values),
      ...(formId && { [SUB_FORM]: formId }),
    };

    try {
      setErrors({});

      const val = await validationSchema.validate({ ...values, ...extraFields }, { abortEarly: false });

      action(val);
    } catch (yupErrors) {
      const errors = yupErrors.inner.reduce((acc, { path, message }) => {
        acc[path] = message;

        return acc;
      }, {});
      const filteredData = excludeErrorFields(values, errors);

      if (filteredData) action({ ...filteredData, ...extraFields });

      setErrors(errors);
    }
  }, [action, formId, setErrors, values]);

  useEffect(() => {
    if (pdfForm?.form) setValues((val) => mergeDeepRight(val, extractFormDataFromMedia(pdfForm.form)));
  }, [pdfForm?.form, setValues]);

  if (success) return <Redirect to={`/planning/${id}/${success}`} />;

  return (
    <>
      <Button type="button" onClick={onSubmit} color="normal" disabled={Boolean(loading || success)}>
        Erstellen
      </Button>
      {error && <Message>{error}</Message>}
    </>
  );
};

Submit.defaultProps = { pdfForm: null };
Submit.propTypes = {
  pdfForm: PropTypes.shape({
    form: PropTypes.shape({}).isRequired,
  }),
};

export default Submit;
