import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--color-greyF8);
  border-radius: var(--border-radius8);
  padding-right: 0 15px;
`;

export const Label = styled.p`
  margin: 0 10px;
  font-size: var(--font-size10);
  letter-spacing: 1px;
  font-weight: var(--font-weight600);
  color: var(--color-grey6A);
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
`;

export const Status = styled(Label)`
  width: 120px;
  text-align: center;
  margin: 0 0 0 10px;
  color: ${({ color }) => color};
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
`;
