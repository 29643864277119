import React from 'react';
import PropTypes from 'prop-types';

import ArrowRight from 'assets/svg-icons/ArrowRight';

import { Container, BackArrow, ButtonContainer, Title, Text } from './styles';

const PageHeader = ({ title, redirectToBack, className, children }) => (
  <Container className={className}>
    <ButtonContainer onClick={redirectToBack}>
      <BackArrow>
        <ArrowRight />
      </BackArrow>
    </ButtonContainer>
    <Title>
      <Text>{title}</Text>
      {children}
    </Title>
  </Container>
);

PageHeader.defaultProps = {
  title: null,
  redirectToBack: () => null,
  className: null,
  children: null,
};

PageHeader.propTypes = {
  title: PropTypes.node,
  redirectToBack: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default PageHeader;
