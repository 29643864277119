export const FEATURE_NAME = 'organizations';

export const ORGANIZATION_KINDS = {
  AUGENMOBIL: 1,
  NURSING_HOME: 2,
  OPTICIAN: 3,
  CLINIC: 4,
};

export const CURRENT = 'current';

export const KIND = 'kind';

export const NAME = 'name';
export const ADDRESS = 'address';
export const CITY = 'city';
export const ZIP_CODE = 'zipCode';
export const CANTON = 'canton';
export const COUNTRY = 'country';
export const PHONE = 'phone';
export const EMAIL = 'email';
export const ORG_URL = 'url';
export const TITLE = 'title';
export const GENDER = 'gender';
export const SPECIALTY = 'specialty';
export const DESCRIPTION = 'description';
export const CHANNEL = 'channel';
export const SHARE = 'share';
export const CONSENT = 'consent';
export const PATIENT_NAME = 'patientName';
export const NURSING_HOME_KIND = 'nursingHomeKind';

export const CONTACTS = 'contacts';
export const DOCTOR = 'gp';
export const MAIN = 'main';
export const OPHTHALMOLOGY = 'ophthalmology';

export const FIRST_NAME = 'firstname';
export const LAST_NAME = 'lastname';
export const NO_PATIENT_REPORTS = 'noPatientReports';

export const FILTERS = 'filters';

export const CONTACT_FORM_NAME = {
  [MAIN]: 'Kontaktperson',
  [DOCTOR]: 'Heimarzt/-ärztin',
};

export const CONTACT_TITLE = {
  [ORGANIZATION_KINDS.CLINIC]: 'Praxis/Klinik',
  [ORGANIZATION_KINDS.NURSING_HOME]: 'Pflegezentrum',
  [ORGANIZATION_KINDS.OPTICIAN]: 'Optiker',
};

export const CONTACT_KINDS = {
  [ORGANIZATION_KINDS.CLINIC]: [MAIN],
  [ORGANIZATION_KINDS.NURSING_HOME]: [MAIN, DOCTOR],
  [ORGANIZATION_KINDS.OPTICIAN]: [MAIN],
};

export const ORGANIZATION_STATUS = [
  { id: 1, label: 'Aktiv' },
  { id: 0, label: 'Inaktiv' },
];

export const SERVICES = 'services';
export const SERVICE_ITEMS = [
  {
    id: 'yagLaser',
    label: 'YAG-Laser',
  },
  {
    id: 'wheelchairAccessible',
    label: 'Praxis rollstuhlgängig',
  },
  {
    id: 'smallLiftOnly',
    label: 'nur kleiner Lift',
  },
];

export const CONSENTS = [
  { id: 'default', label: 'Standard' },
  { id: 'no_gp', label: 'Ohne Hausarzt' },
];

const GENDER_TYPES = { NEUTRAL: 'neutral', FEMALE: 'female', MALE: 'male' };
export const GENDERS = [
  { value: GENDER_TYPES.NEUTRAL, label: 'Das' },
  { value: GENDER_TYPES.FEMALE, label: 'Die' },
  { value: GENDER_TYPES.MALE, label: 'Der' },
];

const PATIENT_NAME_TYPES = { DEFAULT: 'default', GUEST: 'guest' };
export const PATIENT_NAMES = [
  { id: PATIENT_NAME_TYPES.DEFAULT, label: 'Bewohner' },
  { id: PATIENT_NAME_TYPES.GUEST, label: 'Gäste' },
];

export const NURSING_HOME_KINDS = [
  { id: 1, label: 'Alters- und Pflegeheim', gender: GENDER_TYPES.NEUTRAL, patientName: PATIENT_NAME_TYPES.DEFAULT },
  { id: 2, label: 'Alterswohngemeinschaft', gender: GENDER_TYPES.FEMALE, patientName: PATIENT_NAME_TYPES.DEFAULT },
  { id: 3, label: 'Alterszentrum', gender: GENDER_TYPES.NEUTRAL, patientName: PATIENT_NAME_TYPES.DEFAULT },
  { id: 4, label: 'Betagtenheim', gender: GENDER_TYPES.NEUTRAL, patientName: PATIENT_NAME_TYPES.DEFAULT },
  { id: 5, label: 'Gesundheitszentrum für das Alter', gender: GENDER_TYPES.NEUTRAL, patientName: PATIENT_NAME_TYPES.DEFAULT },
  { id: 6, label: 'Pflegezentrum', gender: GENDER_TYPES.NEUTRAL, patientName: PATIENT_NAME_TYPES.DEFAULT },
  { id: 7, label: 'Seniorenheim', gender: GENDER_TYPES.NEUTRAL, patientName: PATIENT_NAME_TYPES.DEFAULT },
  { id: 8, label: 'Stiftung', gender: GENDER_TYPES.FEMALE, patientName: PATIENT_NAME_TYPES.DEFAULT },
  { id: 9, label: 'Tertianum', gender: GENDER_TYPES.NEUTRAL, patientName: PATIENT_NAME_TYPES.GUEST },
];
