import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="96" height="100" viewBox="0 0 96 100">
    <g fill="none" fillRule="evenodd">
      <g stroke="#979797" strokeWidth="1.5">
        <g>
          <g>
            <path d="M15.4 0.1L0.4 15.1 15.4 30.1" transform="translate(40 35.75)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
