import React, { useCallback } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import YesNoQuestion from 'components/YesNoQuestion';

const Question = ({ field, label, children }) => {
  const [{ value }, , { setValue }] = useField(field);

  const handleChoose = useCallback(
    (val) => {
      if (val === value) {
        setValue(null);
      } else {
        setValue(val);
      }
    },
    [setValue, value]
  );

  return (
    <YesNoQuestion value={value} question={label} onChoose={handleChoose}>
      {children}
    </YesNoQuestion>
  );
};

Question.defaultProps = {
  children: null,
};

Question.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default Question;
