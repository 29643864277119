import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-greyF8);
  border-radius: var(--border-radius8);
  padding: 0 10px;
  margin-top: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const textStyle = `
  font-size: var(--font-size10);
  font-weight: var(--font-weight600);
  letter-spacing: 1px;
  color: var(--color-grey6A);
  text-transform: uppercase;
`;

export const Label = styled.p`
  ${textStyle}
`;

export const NavLink = styled(Link)`
  ${textStyle}
  color: var(--color-blue5D);
  transition: color 0.3s ease-in-out;
  &:active {
    color: var(--color-blue2E);
  }
  @media (hover) {
    &:hover {
      color: var(--color-blue8E);
    }
  }
`;

export const Status = styled(Label)`
  width: 120px;
  text-align: center;
  color: ${({ $color }) => $color};
  transition: color 0.3s ease-in-out;
`;
