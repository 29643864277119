import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container, MainContainer, Question, ButtonContainer, Button, CollapseContainer } from './styles';

const YesNoQuestion = ({ value, question, answers, onChoose, children, readMode, withoutBorder }) => {
  const handleClick = useCallback(
    (data) => () => {
      if (!readMode) {
        onChoose(data);
      }
    },
    [readMode, onChoose]
  );

  return (
    <MainContainer withoutBorder={withoutBorder}>
      <Container>
        <Question>{question}</Question>
        <ButtonContainer>
          <Button type="button" onClick={handleClick(answers[0])} isActive={value === answers[0]} disabled={readMode}>
            Ja
          </Button>
          <Button type="button" onClick={handleClick(answers[1])} isActive={value === answers[1]} disabled={readMode}>
            Nein
          </Button>
        </ButtonContainer>
      </Container>
      <CollapseContainer initialPose="close" isOpen={children && value === answers[0]}>
        {children}
      </CollapseContainer>
    </MainContainer>
  );
};

YesNoQuestion.defaultProps = {
  value: null,
  children: null,
  readMode: false,
  answers: [true, false],
  onChoose: () => null,
  withoutBorder: false,
};

YesNoQuestion.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  question: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string])),
  onChoose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  readMode: PropTypes.bool,
  withoutBorder: PropTypes.bool,
};

export default YesNoQuestion;
