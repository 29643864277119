import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { reducerTemplate } from 'store/utils';

import {
  ID,
  LIST,
  FILTER,
  STATUS,
  SEARCH,
  SORT,
  SORT_BY,
  SORT_DIR,
  PAGINATION,
  CURSOR,
  LIMIT,
  COUNT,
  HAS_MORE,
  META,
  CREATED,
  DESC,
  ALL,
} from '.';
import {
  UPDATE_REPORTS,
  UPDATE_REPORT,
  DELETE_REPORT,
  RESET_REPORTS,
  UPDATE_FILTER_STATUS,
  UPDATE_FILTER_SEARCH,
  UPDATE_SORT,
  UPDATE_PAGINATION,
  RESET_FILTER,
} from './types';

const reportReducer = reducerTemplate(
  {
    id: ID,
    list: UPDATE_REPORTS,
    item: UPDATE_REPORT,
    deleteItem: DELETE_REPORT,
    reset: RESET_REPORTS,
  },
  {}
);

const initFilter = {
  [STATUS]: ALL,
  [SEARCH]: '',
  [SORT]: {
    [SORT_BY]: `${META}.${CREATED}`,
    [SORT_DIR]: DESC,
  },
  [PAGINATION]: {
    [CURSOR]: 0,
    [LIMIT]: 10,
    [COUNT]: 0,
    [HAS_MORE]: false,
  },
};

const filterReducer = handleActions(
  {
    [UPDATE_FILTER_STATUS]: (state, { payload }) => ({
      ...state,
      [STATUS]: payload,
      [PAGINATION]: {
        ...state[PAGINATION],
        [CURSOR]: 0,
      },
    }),
    [UPDATE_FILTER_SEARCH]: (state, { payload }) => ({
      ...state,
      [SEARCH]: payload,
      [PAGINATION]: {
        ...state[PAGINATION],
        [CURSOR]: 0,
      },
    }),
    [UPDATE_SORT]: (state, { payload }) => ({
      ...state,
      [SORT]: payload,
      [PAGINATION]: {
        ...state[PAGINATION],
        [CURSOR]: 0,
      },
    }),
    [UPDATE_PAGINATION]: (state, { payload }) => ({
      ...state,
      [PAGINATION]: {
        ...state[PAGINATION],
        ...payload,
      },
    }),
    [RESET_FILTER]: () => initFilter,
  },
  initFilter
);

export default combineReducers({
  [LIST]: reportReducer,
  [FILTER]: filterReducer,
});
