import { keys } from 'ramda';

import {
  PHYSICIAN,
  NURSE,
  SYSADMIN,
  PHYSICIAN_JUNIOR,
  PHYSICIAN_MIDLEVEL,
  PHYSICIAN_SENIOR,
  PLANNER,
  PLANNER_SUPPORT,
  SECRETARY,
  FINANCE,
  FINANCE_MANAGER,
} from 'permissions/consts';

export const FEATURE_NAME = 'bugReports';

export const EMAIL = 'email';
export const MESSAGE = 'message';
export const PAYLOAD = 'payload';
export const SEVERITY = 'severity';
export const PAGE_URL = 'url';
export const ROLE = 'role';
export const CLIENT_ID = 'clientId';
export const MEDI_URL = 'url';

export const LIST = 'list';
export const FILTERS = 'filters';

export const STATUSES = [
  { id: 'null', label: 'Alle' },
  { id: '1', label: 'Aktiv' },
  { id: '0', label: 'Inaktiv' },
];

export const ROLE_FILTERS = [
  { id: 'null', label: 'Alle' },
  { id: PHYSICIAN, label: 'Ärztliches Personal' },
  { id: NURSE, label: 'Technisches Personal' },
  { id: SYSADMIN, label: 'Systemadministration' },
];

export const ROLE_CONFIG = {
  [PHYSICIAN_JUNIOR]: {
    label: 'Befunden (Assistenzart)',
    include: [PHYSICIAN],
    exclude: [NURSE, PHYSICIAN_MIDLEVEL, PHYSICIAN_SENIOR],
  },
  [PLANNER_SUPPORT]: {
    label: 'Terminplan ansehen',
    exclude: [PLANNER],
  },
  [PHYSICIAN_MIDLEVEL]: {
    label: 'Befunden (Oberarzt)',
    include: [PHYSICIAN],
    exclude: [NURSE, PHYSICIAN_JUNIOR, PHYSICIAN_SENIOR],
  },
  [SECRETARY]: { label: 'Berichte verwalten' },
  [PHYSICIAN_SENIOR]: {
    label: 'Befunden (Leitender Arzt)',
    include: [PHYSICIAN],
    exclude: [NURSE, PHYSICIAN_JUNIOR, PHYSICIAN_MIDLEVEL],
  },
  [FINANCE]: { label: 'Rechnungen verwalten' },
  [NURSE]: {
    label: 'Messungen durchführen',
    exclude: [PHYSICIAN, PHYSICIAN_JUNIOR, PHYSICIAN_MIDLEVEL, PHYSICIAN_SENIOR],
  },
  [FINANCE_MANAGER]: { label: 'Finanzkennzahlen (KPIs)' },
  [PLANNER]: {
    label: 'Terminplan erstellen',
    exclude: [PLANNER_SUPPORT],
  },
  [SYSADMIN]: { label: 'Systemadministration' },
};

export const ROLE_OPTIONS = keys(ROLE_CONFIG).map((id) => ({ id, label: ROLE_CONFIG[id].label }));
