import React, { useRef, useEffect } from 'react';

import { SpinnerDef } from './styles';

const Spinner = () => {
  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollIntoView?.({ block: 'start', behavior: 'smooth' });
  }, []);

  return <SpinnerDef ref={ref} height="60px" />;
};

export default Spinner;
