import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: flex-start;
  border: 1px solid var(--color-greyDC);
  border-radius: var(--border-radius8);
  padding: 20px;
`;

export const ReadModeWrapper = styled.div`
  width: 100%;
`;

export const Title = styled.p`
  color: var(--color-grey6A);
  font-size: var(--font-size18);
  font-weight: var(--font-weight400);
  line-height: 1.22;
  letter-spacing: 0.5px;
  text-align: left;
  margin: 30px 0 18px 0;
`;

export const Label = styled.p`
  width: 100%;
  color: var(--color-grey6A);
  margin-left: 20px;
  font-size: var(--font-size18);
  font-weight: var(--font-weight500);
  letter-spacing: 0.6px;
`;

export const NoDocuments = styled.p`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 5px 25px;
  background-color: var(--color-greyEC);
  border-radius: var(--border-radius8);
  color: var(--color-grey6A);
  font-weight: var(--font-weight400);
  font-size: var(--font-size18);
`;
