import React from 'react';
import PropTypes from 'prop-types';

import Close from 'assets/svg-icons/Close';
import Create from 'assets/svg-icons/Plus';
import { CREATE, DELETE } from '../consts';

import Btn from './styles';

const Button = ({ btnType, ...rest }) => {
  const map = {
    [CREATE]: <Create />,
    [DELETE]: <Close />,
  };

  return (
    <Btn type="button" {...rest}>
      {map[btnType]}
    </Btn>
  );
};

Button.propTypes = {
  btnType: PropTypes.oneOf([CREATE, DELETE]).isRequired,
};

export default Button;
