import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  border-radius: var(--border-radius8);
  border: ${({ isActive, isWarn }) => {
    if (!isActive && !isWarn) return 'solid 1px var(--color-greyDC)';
    if (isWarn) return 'solid 1px var(--color-orangeF5)';

    return 'solid 1px var(--color-green65)';
  }};
  cursor: pointer;
  background-color: var(--color-greyF8);
  &:focus {
    outline: none;
    box-shadow: var(--shadow-focus);
  }
  box-shadow: ${({ isActive, isWarn }) => {
    if (!isActive && !isWarn) return 'none';
    if (isWarn) return '0 0 1px 3px var(--color-orangeF5)';

    return '0 0 1px 3px var(--color-green65)';
  }};
`;

export const P = styled.p`
  font-size: var(--font-size15);
  font-weight: var(--font-weight500);
  letter-spacing: 0.5px;
  text-align: center;
  color: ${({ isActive }) => (isActive ? 'var(--color-green65)' : 'var(--color-greyDC)')};
`;

export const PW = styled(P)`
  font-size: var(--font-size15);
  font-weight: var(--font-weight500);
  letter-spacing: 0.5px;
  text-align: center;
  color: var(--color-orangeF5);
`;

export const Plus = styled.p`
  font-size: 90px;
  font-weight: var(--font-weight100);
  line-height: 100px;
  text-align: center;
  color: ${({ isActive }) => (isActive ? 'var(--color-green65)' : 'var(--color-greyDC)')};
`;
