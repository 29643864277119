import React, { useState, useCallback, useMemo, useEffect, memo } from 'react';
import { useFormik } from 'formik';
import { useDropzone } from 'react-dropzone';
import { not, path, pathEq, pipe, pick, pathOr, slice } from 'ramda';
import PropTypes from 'prop-types';

import {
  useInfoItemsActions,
  useAlerts,
  ID,
  MESSAGE,
  META,
  ATTACHMENTS,
  EDITOR,
  OWNER,
  MODIFIED,
  CREATED,
  PATH,
  FILE,
  TAGS,
  SUB_PATIENT,
} from 'store/infoItems';
import { FIRST_NAME, LAST_NAME } from 'store/session';
import Switcher from 'components/Switcher';
import ErrMessage from 'components/Message';
import Warn from 'components/WarnDeleting';
import Spinner from 'components/FullScreenSpinner';
import Close from 'assets/svg-icons/SmallClose';
import PlusIcon from 'assets/svg-icons/PlusDropZone';
import { parseDateWithTime } from 'utils';
import FileForm from '../FileForm';
import { DropZone } from '../styles';
import { useAllAttachments } from '../hooks';

import { Container, HeadWrap, MenuBtn, TextArea, Message, Text, EmptyMessage } from './styles';

const InfoItem = ({ data }) => {
  const [readMode, setReadMode] = useState(true);
  const [isShowWarn, setIsShowWarn] = useState(false);
  const handleChangeReadMode = useCallback(() => setReadMode(not), []);
  const { saveInfoItem, removeInfoItem, uploadMedia } = useInfoItemsActions();
  const { loading, error, extractId } = useAlerts();
  const uploadAlerts = useAlerts();

  const onSend = useCallback((values) => pipe(pick([ID, MESSAGE, ATTACHMENTS]), saveInfoItem)(values), [saveInfoItem]);

  const { values, setFieldValue, getFieldProps } = useFormik({
    initialValues: data,
    validate: onSend,
    validateOnBlur: false,
    validateOnMount: false,
  });

  const meta = values[META];
  const editor = useMemo(() => {
    const edit = path([META, EDITOR], data);
    if (!edit) return '';
    return ['Zul. bearbeitet von', edit[FIRST_NAME], edit[LAST_NAME], 'am', parseDateWithTime(data[META][MODIFIED])]
      .filter(Boolean)
      .join(' ');
  }, [data]);
  const owner = useMemo(
    () =>
      ['Erstellt von', meta[OWNER][FIRST_NAME], meta[OWNER][LAST_NAME], 'am', parseDateWithTime(meta[CREATED])]
        .filter(Boolean)
        .join(' '),
    [meta]
  );

  const updateFileData = useCallback(
    (index, fileData) => {
      setFieldValue(
        ATTACHMENTS,
        values[ATTACHMENTS].map((item, idx) => (index === idx ? { ...item, [FILE]: fileData } : item))
      );
    },
    [setFieldValue, values]
  );
  const updateTags = useCallback(
    (index, tags) => {
      setFieldValue(
        ATTACHMENTS,
        values[ATTACHMENTS].map((item, idx) => (index === idx ? { ...item, [TAGS]: tags } : item))
      );
    },
    [setFieldValue, values]
  );
  const deleteFile = useCallback(
    (index) => {
      setFieldValue(
        ATTACHMENTS,
        values[ATTACHMENTS].filter((_, i) => i !== index)
      );
    },
    [setFieldValue, values]
  );

  const handleShowWarn = useCallback(() => setIsShowWarn(not), []);
  const handleDelete = useCallback(() => {
    extractId(removeInfoItem(data[ID]));
  }, [extractId, removeInfoItem, data]);

  const onDrop = useCallback((files) => uploadAlerts.extractId(uploadMedia(files)), [uploadAlerts, uploadMedia]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const attachments = useAllAttachments(values[ATTACHMENTS]);

  useEffect(() => {
    if (error && isShowWarn) {
      setIsShowWarn(not);
    }
  }, [error, isShowWarn]);

  useEffect(() => {
    if (uploadAlerts.success) {
      setFieldValue(ATTACHMENTS, [
        ...values[ATTACHMENTS],
        ...uploadAlerts.success.map((file) => ({
          [FILE]: file,
          [TAGS]: [],
        })),
      ]);
      uploadAlerts.resetAlerts();
    }
  }, [uploadAlerts, setFieldValue, values]);

  return (
    <Container>
      <HeadWrap>
        <Switcher label="BEARBEITEN" checked={!readMode} onChange={handleChangeReadMode} allowChange />
      </HeadWrap>
      {readMode ? (
        <>
          {!values[ATTACHMENTS].length && !values[MESSAGE] && <EmptyMessage>Leerer Eintrag</EmptyMessage>}
          {values[MESSAGE] && <Message>{values[MESSAGE]}</Message>}
        </>
      ) : (
        <>
          <HeadWrap>
            <MenuBtn type="button" title="Löschen Sie" onClick={setIsShowWarn}>
              <Close />
            </MenuBtn>
          </HeadWrap>
          <TextArea placeholder="Keine Nachricht" {...getFieldProps(MESSAGE)} />
        </>
      )}
      {readMode || (
        <DropZone height="60px" mb="10px" isActive={isDragActive} {...getRootProps()}>
          {uploadAlerts.loading ? (
            <Spinner />
          ) : (
            <>
              <input {...getInputProps()} />
              <PlusIcon />
            </>
          )}
        </DropZone>
      )}
      {values[ATTACHMENTS].map((item, index) => (
        <FileForm
          key={item[FILE][PATH]}
          index={index}
          fileData={item[FILE]}
          tags={item[TAGS]}
          updateFileData={updateFileData}
          updateTags={updateTags}
          deleteFile={deleteFile}
          readMode={readMode}
          attachments={attachments}
        />
      ))}
      <Text>{editor}</Text>
      <Text>{owner}</Text>
      {isShowWarn && (
        <Warn
          loading={loading}
          message="Wollen Sie den Eintrag (inkl. Dateianhänge) löschen?"
          showActon={handleShowWarn}
          deleteAction={handleDelete}
        />
      )}
      {error && <ErrMessage type="error">{error}</ErrMessage>}
    </Container>
  );
};

InfoItem.propTypes = {
  data: PropTypes.shape({
    [ID]: PropTypes.string.isRequired,
    [SUB_PATIENT]: PropTypes.string.isRequired,
    [MESSAGE]: PropTypes.string,
    [ATTACHMENTS]: PropTypes.arrayOf(
      PropTypes.shape({
        [FILE]: PropTypes.shape({
          path: PropTypes.string.isRequired,
          description: PropTypes.string,
          mimetype: PropTypes.string.isRequired,
        }),
        [TAGS]: PropTypes.arrayOf(PropTypes.string),
      })
    ).isRequired,
    [META]: PropTypes.shape({
      [OWNER]: PropTypes.shape({
        [FIRST_NAME]: PropTypes.string,
        [LAST_NAME]: PropTypes.string,
      }).isRequired,
      [EDITOR]: PropTypes.shape({
        [FIRST_NAME]: PropTypes.string,
        [LAST_NAME]: PropTypes.string,
      }),
      [CREATED]: PropTypes.string.isRequired,
      [MODIFIED]: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default memo(
  InfoItem,
  (prev, next) =>
    pathEq(path(['data', META, EDITOR, ID], next), ['data', META, EDITOR, ID], prev) &&
    pipe(pathOr('', ['data', META, MODIFIED]), slice(0, 16))(prev) ===
      pipe(pathOr('', ['data', META, MODIFIED]), slice(0, 16))(next)
);
