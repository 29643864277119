import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  padding-top: 10px;
`;

export const Text = styled.p`
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 5px 30px;
  background-color: var(--color-greyF3);
  border-radius: var(--border-radius8);
  font-size: var(--font-size18);
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: var(--color-grey6A);
  white-space: pre-line;
  margin: 20px 0 10px;
`;
