import React, { useCallback, useEffect, memo } from 'react';
import { useField } from 'formik';
import { useParams } from 'react-router-dom';
import { eqProps } from 'ramda';
import PropTypes from 'prop-types';

import { MEDICAL_HISTORY, usePermissions } from 'permissions';
import Dropzone from 'components/Dropzone';
import MultiImage from 'components/MultiImage';
import Message from 'components/Message';
import QuestionContainer from 'components/QuestionContainer';
import { FILES, useMedicalHistoryActions, useAlerts, useWebsocket } from 'store/medicalHistory';

import { DropContainer } from './styles';

const DropzoneForm = ({ readMode }) => {
  const can = usePermissions();
  const [{ value = [] }, , { setValue }] = useField(FILES);
  const { id: patientId } = useParams();
  const { uploadMediaData } = useMedicalHistoryActions();
  const { action, success, error, loading, resetAlerts } = useAlerts(uploadMediaData);

  const onDrop = useCallback(
    (files) => {
      if (!loading) {
        action({ files, patientId });
      }
    },
    [action, loading, patientId]
  );
  const updateValues = useCallback((data) => setValue(data, false), [setValue]);

  useWebsocket(can.update(MEDICAL_HISTORY), patientId, setValue);

  useEffect(() => {
    if (success) {
      setValue(success);
      resetAlerts();
    }
  }, [resetAlerts, setValue, success]);

  if (readMode) {
    return (
      <QuestionContainer label="Dokumente">
        <DropContainer readMode={readMode}>
          <MultiImage values={value} readMode={readMode} isSearchableMedia />
        </DropContainer>
      </QuestionContainer>
    );
  }

  return (
    <QuestionContainer label="Dokumente">
      <DropContainer>
        <MultiImage values={value} updateValues={updateValues} isSearchableMedia />
        <Dropzone onDrop={onDrop} loading={loading} />
      </DropContainer>
      {error && (
        <Message type="error" form={1}>
          {error}
        </Message>
      )}
    </QuestionContainer>
  );
};

DropzoneForm.propTypes = {
  readMode: PropTypes.bool.isRequired,
};

export default memo(DropzoneForm, eqProps('readMode'));
