import React, { useState, useCallback } from 'react';
import { pipe, path, when } from 'ramda';
import PropTypes from 'prop-types';

import Close from 'assets/svg-icons/Close';

import { FIXED_WORDS } from './consts';
import Content from './Content';
import { Container, MenuWrap, Panel, InputWrap, Text, Input, Button, FixedWordWrap, FixedWord } from './styles';

const SearchableBox = ({ id, closeBox }) => {
  const [search, setSearch] = useState('');

  const onClose = useCallback(() => closeBox(), [closeBox]);

  const handleClickWord = useCallback((e) => pipe(path(['target', 'dataset', 'id']), when(Boolean, setSearch))(e), [setSearch]);

  return (
    <Container>
      <MenuWrap>
        <Panel>
          <InputWrap>
            <Text>Suche</Text>
            <Input value={search} onChange={(e) => setSearch(e.target.value)} />
          </InputWrap>
          <FixedWordWrap>
            {FIXED_WORDS.map((name) => (
              <FixedWord key={name} type="button" data-id={name} onClick={handleClickWord}>
                {name}
              </FixedWord>
            ))}
          </FixedWordWrap>
        </Panel>
        <Button type="button" onClick={onClose}>
          <Close />
        </Button>
      </MenuWrap>
      <Content id={id} search={search} />
    </Container>
  );
};

SearchableBox.propTypes = {
  id: PropTypes.string.isRequired,
  closeBox: PropTypes.func.isRequired,
};

export default SearchableBox;
