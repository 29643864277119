/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo } from 'react';
import { equals, pick } from 'ramda';
import PropTypes from 'prop-types';

import { ID } from 'api/consts';
import Checkbox from 'components/CheckboxNew';
import LabelText from 'components/LabelText';

import { Container, Inside, ItemContainer, BoldText, Text } from './styles';

const Checkboxes = ({ items, allItems, onChange, readMode }) => {
  const sections = useMemo(() => {
    if (readMode) return null;

    const handledItems = [...allItems];
    const half = handledItems.splice(0, Math.ceil(handledItems.length / 2));

    return [half, handledItems];
  }, [allItems, readMode]);

  const handleClick = (selected, item) => () => {
    if (selected) {
      onChange(items.filter((val) => val !== item));
    } else {
      onChange([...items, item]);
    }
  };

  if (readMode) {
    const string = allItems
      .filter((item = {}) => items.includes(item[ID]))
      .reduce((acc, { code, name }) => `${acc}${code} (${name}), `, '')
      .slice(0, -2);

    return <LabelText label="Codes" text={string} readonly />;
  }

  return (
    <Container>
      {sections.map((section, i) => (
        <Inside key={i}>
          {section.map((item) => {
            if (!item) return null;

            const { [ID]: id, code, name } = item;
            const selected = items.includes(id);
            return readMode ? (
              <ItemContainer key={id}>
                <div style={{ display: 'flex', flex: 1 }}>
                  <BoldText>{code}</BoldText>
                  <Text>{name}</Text>
                </div>
                <LabelText text={selected ? 'Yes' : 'No'} readonly />
              </ItemContainer>
            ) : (
              <ItemContainer key={id}>
                <Checkbox selected={selected} onClick={handleClick(selected, id)} mr={0} readMode={readMode} />
                <BoldText>{code}</BoldText>
                <Text>{name}</Text>
              </ItemContainer>
            );
          })}
        </Inside>
      ))}
    </Container>
  );
};

Checkboxes.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  allItems: PropTypes.arrayOf(
    PropTypes.shape({
      [ID]: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  readMode: PropTypes.bool.isRequired,
};

export default memo(Checkboxes, (prev, next) => {
  const props = ['items', 'allItems', 'readMode'];

  return equals(pick(props, prev), pick(props, next));
});
