import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
`;

export const Button = styled.button`
  border: none;
  background: none;
  outline: 0;
  cursor: pointer;
  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }
`;

export const Message = styled.p`
  font-size: var(--font-size13);
  color: var(--color-orangeDC);
  text-align: right;
  padding-top: 10px 0;
`;
