import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18">
    <g fill="none" fillRule="evenodd">
      <path fill="currentColor" d="M7.5 3l7.5 7.5h-3.948V18H3.948v-7.5H0L7.5 3z" />
      <path stroke="currentColor" strokeLinecap="square" d="M0 0.5L15 0.5" />
    </g>
  </svg>
);
