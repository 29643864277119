import React from 'react';
import PropTypes from 'prop-types';

import WarningIcon from 'assets/svg-icons/Warning';

import { Container, Message } from './styles';

const Warning = ({ children, className }) => (
  <Container className={className}>
    <Message>{children}</Message>
    <WarningIcon />
  </Container>
);

Warning.defaultProps = {
  className: '',
};

Warning.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Warning;
