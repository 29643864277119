import { wrapActions, wrapSelector, wrapMultiSelector } from 'store/utils';

import { getAuth, getAuth2FA, getHas2FA, getLoggedIn, get2FALink, getUser, getUserProp, getPermissionsObject } from './selectors';
import * as actions from './actions';

export const useSessionActions = wrapActions({ ...actions });

export const useAuth = wrapSelector(getAuth);
export const useAuth2FA = wrapSelector(getAuth2FA);
export const useHas2FA = wrapSelector(getHas2FA);
export const useLoggedIn = wrapSelector(getLoggedIn);
export const use2FALink = wrapSelector(get2FALink);
export const usePermissionsObject = wrapSelector(getPermissionsObject);

export const useUser = wrapMultiSelector(getUserProp, getUser);
