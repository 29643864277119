import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background: var(--color-greyF3);
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  margin: 10px 0;
`;

export const Id = styled.span`
  display: inline-block;
  width: ${({ width }) => `calc(${width} - 10px)`};
  height: 95px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  background-color: var(--color-grey6A);
  font-size: var(--font-size23);
  letter-spacing: 0.1px;
  color: var(--color-white);
  padding: 10px 0 0 15px;
`;

export const Field = styled.span`
  width: ${({ width }) => `calc(${width} - 10px)`};
  font-size: var(--font-size15);
  color: var(--color-grey6A);
  text-align: left;
  padding-top: 10px;
`;

export const DownloadWrapper = styled.div`
  width: ${({ width }) => `calc(${width} - 10px)`};
  display: flex;
  justify-content: center;
  align-items: center;

  & > a {
    border: 2px solid var(--color-green65);
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
