import React, { useCallback } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import { MOBILITY, MOBILITY_ITEMS } from 'store/examinations';
import CheckBox from 'components/CheckboxNew';

import { Container, Text } from './styles';

const Mobility = ({ readMode }) => {
  const [{ value }, , { setValue }] = useField(MOBILITY);
  const handleClick = useCallback((val, selected) => () => setValue(selected ? '' : val), [setValue]);

  if (readMode) {
    return <Text>{value || '–'}</Text>;
  }

  return (
    <Container pt={10}>
      {MOBILITY_ITEMS.map((item) => (
        <CheckBox
          key={item}
          selected={value === item}
          onClick={handleClick(item, value === item)}
          label={item}
          fontSize="var(--font-size18)"
          fontColor={!value || value === item ? 'var(--color-grey6A)' : 'rgba(106, 106, 106, 0.44)'}
        />
      ))}
    </Container>
  );
};

Mobility.propTypes = {
  readMode: PropTypes.bool.isRequired,
};

export default Mobility;
