import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import DatePeriod from '../DatePeriod';

import { ValueWrapper, Value, TimelineWrapper } from './styles';

const KPIValue = ({ start, end, hasChange, prev, unit, value, layout }) => {
  const hasCurrency = useMemo(() => unit && unit === 'CHF', [unit]);

  const convertCurrency = useCallback(() => {
    const result = value / 100;
    if (result >= 10000) return `${Math.round((result / 1000) * 100) / 100}k`;
    return Math.round(result);
  }, [value]);

  if (layout === 'small') {
    return (
      <ValueWrapper prev={prev} layout={layout}>
        <Value layout={layout}>{`${unit ? `${unit} ` : ''}${hasCurrency ? convertCurrency(value) : value}`}</Value>
        {hasChange && (
          <TimelineWrapper>
            <DatePeriod start={start} end={end} prev={prev} />
          </TimelineWrapper>
        )}
      </ValueWrapper>
    );
  }

  return (
    <ValueWrapper prev={prev}>
      <Value>{`${unit ? `${unit} ` : ''}${hasCurrency ? convertCurrency(value) : value}`}</Value>
      {hasChange && (
        <TimelineWrapper>
          <DatePeriod start={start} end={end} prev={prev} />
        </TimelineWrapper>
      )}
    </ValueWrapper>
  );
};

KPIValue.defaultProps = {
  start: null,
  end: null,
  unit: null,
  prev: false,
  hasChange: false,
};

KPIValue.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.number.isRequired,
  prev: PropTypes.bool,
  hasChange: PropTypes.bool,
  layout: PropTypes.string.isRequired,
};

export default KPIValue;
