import styled from 'styled-components';

import { ANIMATION_TIME } from '../../consts';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  user-select: none;
  -moz-user-select: none;
`;

export const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  user-select: none;
  -moz-user-select: none;
  margin: auto;
  ${({ isPressed }) => isPressed && 'cursor: move;'}
  object-fit: contain;
  transition-duration: ${ANIMATION_TIME * 2}ms;
  transition-property: transform, filter;
  transition-timing-function: ease-out;
`;
