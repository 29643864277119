import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
    <g fill="none" fillRule="evenodd">
      <circle cx="13" cy="13" r="13" fill="var(--color-redED)" />
      <g stroke="#FFF" strokeWidth="1.5">
        <path d="M8 17.898L17.967 8M8 8l9.967 9.898" />
      </g>
    </g>
  </svg>
);
