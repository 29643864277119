export const FEATURE_NAME = 'session';

export const ROLES = 'roles';
export const AUTH = 'auth';
export const AUTH_2F = 'auth2F';
export const HAS_2F = 'twoFactorEnabled';
export const LOGGED_IN = 'loggedIn';
export const LINK_2FA = 'link2FA';
export const USER = 'user';
export const PERMISSIONS = 'permissions';

export const TITLE = 'title';
export const FIRST_NAME = 'firstname';
export const LAST_NAME = 'lastname';

export const EMAIL = 'email';
export const PASSWORD = 'password';

export const MODE = 'mode';
export const OFFLINE_MODE = 'offline';
export const ONLINE_MODE = 'online';
