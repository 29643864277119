import { handleActions } from 'redux-actions';

import { UPDATE_PARAMS } from './types';
import { PARAMS, DIABETES_SHARE, AGE, PATIENT_AMOUNT, NURSING_HOME } from './consts';

export default handleActions(
  {
    [UPDATE_PARAMS]: (state, { payload }) => ({ ...state, [PARAMS]: payload }),
  },
  {
    [PARAMS]: [AGE, DIABETES_SHARE, PATIENT_AMOUNT, NURSING_HOME],
  }
);
