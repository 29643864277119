import React, { useMemo, useCallback, memo } from 'react';
import { useField, useFormikContext } from 'formik';
import { allPass, eqProps, tail } from 'ramda';
import PropTypes from 'prop-types';

import Select from 'components/SelectNew';
import TextInput from 'components/TextInputNew';
import Radios from 'components/RadiosNew';
import DateInput from 'components/DateInput';
import AddressAutocomplete from 'components/AddressAutocomplete';
import { POSITION_TITLES, GENDER_RADIOS, GENDERS } from 'utils/constants';
import {
  TITLE,
  FIRST_NAME,
  LAST_NAME,
  GENDER,
  BIRTH,
  ADDRESS,
  CANTON,
  COUNTRY,
  ZIP_CODE,
  CITY,
  PHONE,
  EMAIL,
  NATIONAL,
  CARE_OF,
} from 'store/patients';
import countries from 'assets/countries.json';

import Portrait from './Portrait';

const PatientForm = ({ readMode, errors }) => {
  const { setValues } = useFormikContext();
  const [{ value: title }, , { setValue: setTitle }] = useField(TITLE);
  const [firstNameField] = useField(FIRST_NAME);
  const [lastNameField] = useField(LAST_NAME);
  const [{ value: gender }, , { setValue: setGender }] = useField(GENDER);
  const [{ value: birth }, , { setValue: setBirth }] = useField(BIRTH);
  const [{ value: address }] = useField(ADDRESS);
  const [careOfField] = useField(CARE_OF);
  const [{ value: zipCode }] = useField(ZIP_CODE);
  const [{ value: city }] = useField(CITY);
  const [{ value: canton }] = useField(CANTON);
  const [{ value: country }] = useField(COUNTRY);
  const [phoneField] = useField(PHONE);
  const [emailField] = useField(EMAIL);
  const [{ value: nationality }, , { setValue: setNationality }] = useField(NATIONAL);
  const updateAutocompleteValues = useCallback((data) => setValues(($) => ({ ...$, ...data })), [setValues]);

  const options = useMemo(() => (title ? POSITION_TITLES : tail(POSITION_TITLES)), [title]);

  const genderItems = useMemo(() => GENDER_RADIOS.filter(({ value }) => value !== GENDERS.OTHER), []);

  return (
    <>
      <Portrait readMode={readMode} />
      <Select label="Titel" value={title || ''} options={options} onSelect={setTitle} readMode={readMode} />
      <TextInput label="Vorname" {...firstNameField} readMode={readMode} />
      <TextInput label="Nachname" {...lastNameField} readMode={readMode} />
      <Radios value={gender} label="Geschlecht" items={genderItems} setValue={setGender} readMode={readMode} />
      <DateInput
        value={birth}
        label="Geburtsdatum"
        onChangeDate={setBirth}
        errorMessage="Ungültiges Datum."
        readMode={readMode}
        withoutFuture
      />
      <TextInput label="c/o" {...careOfField} readMode={readMode} />
      <AddressAutocomplete
        address={address}
        zipCode={zipCode}
        city={city}
        canton={canton}
        country={country}
        setData={updateAutocompleteValues}
        zipCodeErr={errors && errors[ZIP_CODE]}
        readMode={readMode}
      />
      <TextInput label="Telefon" {...phoneField} readMode={readMode} />
      <TextInput label="E-Mail" {...emailField} readMode={readMode} error={!readMode && errors && errors[EMAIL]} />
      <Select
        label="Nationalität"
        value={nationality || ''}
        options={countries}
        idKey="value"
        onSelect={setNationality}
        readMode={readMode}
      />
    </>
  );
};

PatientForm.defaultProps = { errors: null };
PatientForm.propTypes = {
  readMode: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    [EMAIL]: PropTypes.string,
    [ZIP_CODE]: PropTypes.string,
  }),
};

export default memo(PatientForm, allPass([eqProps('readMode'), eqProps('errors')]));
