import React, { useMemo, useCallback } from 'react';
import { join, filter, pipe, paths, path, props, pathOr, propOr, __ } from 'ramda';
import PropTypes from 'prop-types';

import {
  NAME,
  CONTACTS,
  ZIP_CODE,
  CITY,
  MAIN,
  TITLE,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  CANTON,
  ADDRESS,
  ID,
} from 'store/organizations';
import { POSITION_MAP } from 'utils/constants';

import { ReactComponent as CrossIcon } from 'assets/svg-icons/cross.svg';
import { ReactComponent as ArrowIcon } from 'assets/svg-icons/arrowRight.svg';
import { Container, Wrapper, Text, Button, NavLink } from './styles';

const SelectedOrganization = ({ item, onDelete, disabled }) => {
  const [name, address, cityCanton, contact, email, url] = useMemo(
    () => [
      item[NAME],
      item[ADDRESS],
      pipe(props([ZIP_CODE, CITY, CANTON]), filter(Boolean), join(' '))(item),
      pipe(
        ($) => [
          pipe(pathOr('', [CONTACTS, MAIN, TITLE]), propOr('', __, POSITION_MAP))($),
          ...paths(
            [
              [CONTACTS, MAIN, FIRST_NAME],
              [CONTACTS, MAIN, LAST_NAME],
            ],
            $
          ),
        ],
        filter(Boolean),
        join(' ')
      )(item),
      path([CONTACTS, MAIN, EMAIL], item),
      item[ID] && `/organizations/${item[ID]}`,
    ],
    [item]
  );
  const handleDelete = useCallback(() => onDelete(item), [onDelete, item]);

  return (
    <Container>
      <Wrapper>
        {Boolean(name) && <Text>{name}</Text>}
        {Boolean(address) && <Text>{address}</Text>}
        {Boolean(cityCanton) && <Text>{cityCanton}</Text>}
        {Boolean(contact || email) && <br />}
        {Boolean(contact) && <Text>{contact}</Text>}
        {Boolean(email) && <Text>{email}</Text>}
      </Wrapper>
      {!disabled && (
        <Button type="button" onClick={handleDelete}>
          <CrossIcon />
        </Button>
      )}
      {!disabled && url && (
        <NavLink to={url}>
          <ArrowIcon />
        </NavLink>
      )}
    </Container>
  );
};

SelectedOrganization.defaultProps = { disabled: false };
SelectedOrganization.propTypes = {
  item: PropTypes.shape({
    [ID]: PropTypes.string.isRequired,
    [NAME]: PropTypes.string,
    [ADDRESS]: PropTypes.string,
    [ZIP_CODE]: PropTypes.string,
    [CITY]: PropTypes.string,
    [CANTON]: PropTypes.string,
    [CONTACTS]: PropTypes.shape({
      [MAIN]: PropTypes.shape({
        [TITLE]: PropTypes.string,
        [FIRST_NAME]: PropTypes.string,
        [LAST_NAME]: PropTypes.string,
        [EMAIL]: PropTypes.string,
      }).isRequired,
    }),
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SelectedOrganization;
