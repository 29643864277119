import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { omit } from 'ramda';

import Button from 'components/Button';
import Message from 'components/Message';
import Link from 'components/Link';
import Spinner from 'components/FullScreenSpinner';
import TextInput from 'components/TextInputNew';
import { useHomePage } from 'permissions';
import { EMAIL, PASSWORD, useAuth, useHas2FA, useLoggedIn, useSessionActions, useAlerts } from 'store/session';

import TwoFactorAuth from './TwoFactorAuth';
import { validationSchema } from './validation';
import { CenteredContainer, LoginTitle, FormContainer, LinkContainer, ExternContainer } from './styles';

const Login = () => {
  const history = useHistory();
  const homePage = useHomePage();
  const isLoggedIn = useLoggedIn();
  const isAuth = useAuth();
  const has2FA = useHas2FA();
  const { login } = useSessionActions();
  const { extractId, loading, error } = useAlerts();

  useEffect(() => {
    if (isLoggedIn) {
      history.push(homePage);
    }
  }, [history, homePage, isLoggedIn]);

  const handleAuth = useCallback(
    (results, { setSubmitting }) => {
      extractId(login({ ...results }));
      setSubmitting(false);
    },
    [extractId, login]
  );

  const { values, handleChange, errors, isSubmitting, submitForm, setErrors } = useFormik({
    initialValues: {
      [EMAIL]: '',
      [PASSWORD]: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    onSubmit: handleAuth,
  });

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      submitForm(e);
    },
    [submitForm]
  );

  const onReset = useCallback(
    (e) => {
      const { name } = e.target;

      if (errors[name]) setErrors(omit([name], errors));
    },
    [errors, setErrors]
  );

  if (isAuth && has2FA) {
    return <TwoFactorAuth />;
  }

  return (
    <CenteredContainer>
      <LoginTitle>Willkommen bei Augenmobil</LoginTitle>
      <FormContainer onSubmit={handleSubmit} onChange={onReset}>
        <TextInput
          name={EMAIL}
          value={values[EMAIL]}
          onChange={handleChange}
          type="email"
          placeholder="E-Mail"
          error={errors[EMAIL]}
        />
        <TextInput
          name={PASSWORD}
          value={values[PASSWORD]}
          onChange={handleChange}
          type="password"
          placeholder="Passwort"
          error={errors[PASSWORD]}
        />
        <Button type="submit" disabled={loading || isSubmitting} mt={24} height="50px">
          {loading ? <Spinner size={40} height="40px" /> : 'Anmelden'}
        </Button>
        {error && <Message type="error">{error}</Message>}
        <LinkContainer>
          <Link to="/reset" name="Passwort vergessen?" />
        </LinkContainer>
        <ExternContainer>
          Möchten Sie auf Ihre Augenmobil-Berichte zugreifen? Verwenden Sie unseren{' '}
          <Link to="/extern" name="Zugang für Partnerinstitutionen" />.
        </ExternContainer>
      </FormContainer>
    </CenteredContainer>
  );
};

export default Login;
