import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

export const Wrapper = styled.div`
  display: flex;
  column-gap: 10px;
  min-height: 265px;
`;
