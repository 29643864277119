import React, { useCallback, useEffect } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import { DIAGNOSIS, LEFT, RIGHT } from 'store/diagnoses';
import Checkbox from 'components/CheckboxNew';
import { CheckBoxWrap } from '../styles';

const CheckBox = ({ id, label, side, parentValue, readMode }) => {
  const [{ value }, , { setValue }] = useField(`${DIAGNOSIS}.${side}.${id}`);
  const handleChange = useCallback(() => setValue(!value), [setValue, value]);

  useEffect(() => {
    if (!parentValue && value) {
      setValue(false);
    }
  }, [parentValue, value, setValue]);

  return (
    <CheckBoxWrap>
      <Checkbox label={label} selected={value} onClick={handleChange} readMode={readMode} />
    </CheckBoxWrap>
  );
};

CheckBox.defaultProps = {
  readMode: false,
};

CheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  side: PropTypes.oneOf([LEFT, RIGHT]).isRequired,
  parentValue: PropTypes.bool.isRequired,
  readMode: PropTypes.bool,
};

export default CheckBox;
