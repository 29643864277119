import styled from 'styled-components';

import Message from 'components/Message';
import MultiInputCode from 'components/MultiInputCode';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px auto;
  width: 90%;
  max-width: 470px;
`;

export const Title = styled.h1`
  font-size: var(--font-size30);
  color: var(--color-grey6A);
  font-weight: var(--font-weight400);
  line-height: 1.1;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 20px;
`;

export const FormContainer = styled.form`
  margin: 40px 0px;
`;

export const MultiInput = styled(MultiInputCode)`
  margin-left: 50px;
`;

export const Msg = styled(Message)`
  text-align: center;
  margin-top: 30px;
`;
