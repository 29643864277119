import React, { useState, useCallback, useMemo, useEffect, forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import { when, complement, is, not } from 'ramda';
import PropTypes from 'prop-types';

import Close from 'assets/svg-icons/SmallClose';
import Warn from 'components/WarnDeleting';
import { useAsyncState } from 'utils/useAsyncState';
import { parseDate } from 'utils';
import api from 'api';
import { ID } from 'api/consts';
import { getOriginName } from '../utils';

import { useDragAndDrop } from './hooks';
import { Container, Button, ButtonWrap, SmallBtn, Label, DragBox } from './styles';

const Item = forwardRef(
  (
    {
      [ID]: id,
      destination,
      path,
      previewname: previewName,
      originalname: originalName,
      description,
      mimetype,
      form,
      openFile,
      deleteFile,
      saveMedia,
    },
    containerRef
  ) => {
    const { id: scheduleId } = useParams();
    const [isShowWarn, setIsShowWarn] = useState();
    const [imageUrl, setImageUrl] = useAsyncState('');
    const label = useMemo(() => {
      const { lastname, birthday } = form?.patient || {};

      if (!(lastname && birthday)) return when(complement(is(String)), () => getOriginName(originalName))(description);

      return [lastname, parseDate(birthday)].filter(Boolean).join(' ');
    }, [description, form?.patient, originalName]);
    const handleShowWarn = useCallback(() => setIsShowWarn(not), []);
    const handleOpen = useCallback(() => openFile(mimetype, path), [mimetype, openFile, path]);
    const handleDelete = useCallback(() => deleteFile(id, path), [deleteFile, id, path]);
    const getImageUrl = useCallback(async () => {
      const blobUrl = await api.loadFile({
        url: `${destination}/${previewName}`,
        mimetype,
      });

      setImageUrl((prev) => {
        if (prev) (window.URL || window.webkitURL).revokeObjectURL(prev);

        return blobUrl;
      });
    }, [destination, mimetype, previewName, setImageUrl]);

    const onDragEnd = useCallback(
      (val) => saveMedia({ scheduleId, id, form: { ...form, ward: val } }),
      [saveMedia, form, id, scheduleId]
    );
    const { onMouseDown, parentRef, dragBoxRef, containerStyle, dragBoxStyle } = useDragAndDrop(containerRef, onDragEnd);

    useEffect(() => {
      getImageUrl();
    }, [getImageUrl]);

    useEffect(
      () => () => {
        if (imageUrl) (window.URL || window.webkitURL).revokeObjectURL(imageUrl);
      },
      [imageUrl]
    );

    return (
      <>
        <Container key={path} onMouseDown={onMouseDown} style={containerStyle}>
          <Button ref={parentRef} type="button" src={imageUrl} onClick={handleOpen} disabled={!imageUrl} />
          {label && <Label>{label}</Label>}
          <ButtonWrap>
            <SmallBtn type="button" onClick={handleShowWarn}>
              <Close />
            </SmallBtn>
          </ButtonWrap>
          {isShowWarn && (
            <Warn
              message="Wollen Sie die Datei wirklich löschen?"
              showActon={handleShowWarn}
              deleteAction={handleDelete}
              smallWidth
            />
          )}
        </Container>
        <DragBox ref={dragBoxRef} style={dragBoxStyle} $src={imageUrl} />
      </>
    );
  }
);

Item.defaultProps = {
  [ID]: null,
  description: null,
  form: {},
};

Item.propTypes = {
  [ID]: PropTypes.string,
  destination: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  previewname: PropTypes.string.isRequired,
  originalname: PropTypes.string.isRequired,
  mimetype: PropTypes.string.isRequired,
  description: PropTypes.string,
  form: PropTypes.shape({
    patient: PropTypes.shape({
      lastname: PropTypes.string,
      birthday: PropTypes.string,
    }).isRequired,
    ward: PropTypes.string,
  }),
  openFile: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  saveMedia: PropTypes.func.isRequired,
};

export default Item;
