import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
`;

export const Switcher = styled.div`
  display: grid;
  grid-template-columns: 180px 180px;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const Link = styled(NavLink)`
  width: 100%;
  height: 40px;
  border: 1px solid;
  border-color: ${({ disabled }) => (disabled ? 'var(--color-grey6A)' : 'var(--color-greyF8)')};
  border-radius: var(--border-radius8);
  background-color: ${({ disabled }) => (disabled ? 'var(--color-white)' : 'var(--color-greyF8)')};
  color: var(--color-grey6A);
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  line-height: 38px;
  letter-spacing: 0.92px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.5s;
  @media (hover) {
    &:hover {
      background-color: var(--color-greyEC);
      border-color: var(--color-greyEC);
    }
  }
  &.active {
    border-color: var(--color-grey6A);
    background-color: var(--color-white);
    cursor: not-allowed;
  }
`;
