import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 20px auto;
  column-gap: 5px;
  align-items: center;
  width: auto;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin: 0 25px 5px 0;
`;

export const Input = styled.input`
  height: 20px;
  width: 20px;
  border: 1px solid;
  border-color: ${({ $error }) => ($error ? '#ff4124' : 'var(--color-grey97)')};
  border-radius: 50%;
  background: var(--color-white);
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: inherit;
  &:checked {
    background: radial-gradient(
      circle at center,
      ${({ $error }) => ($error ? 'var(--color-orangeDC)' : 'var(--color-grey97)')} 40%,
      var(--color-white) 45%
    );
  }
  &:disabled {
    opacity: 1;
  }
`;

export const Label = styled.label`
  width: fit-content;
  font-size: var(--font-size15);
  font-weight: var(--font-weight400);
  color: ${({ $error }) => ($error ? 'var(--color-orangeDC)' : 'var(--color-grey97)')};
  cursor: inherit;
`;
