import { takeLatest, takeEvery, takeLeading, debounce, call, put } from 'redux-saga/effects';
import { pick } from 'ramda';

import { withAlert } from 'store/alerts';
import api from 'api';

import {
  FETCH_BUG_REPORTS,
  SEND_BUG_REPORT,
  CHANGE_BUG_REPORT_STATUS,
  FETCH_MEDI_DATA,
  SAVE_MEDI_DATA,
  FETCH_USERS,
  FETCH_USER,
  CREATE_USER,
  SAVE_USER,
} from './types';
import { updateBugReports, updateBugReport, updatePagination } from './actions';
import { COUNT, HAS_MORE, ID } from '.';

function* fetchBugReports({ payload }) {
  const data = yield call(api.getBugReports, payload);

  yield put(updateBugReports(data?.data));
  yield put(updatePagination(pick([COUNT, HAS_MORE], data)));
}

function* sendBugReport({ payload }) {
  yield call(api.postBugReports, payload);
}

function* changeBugReportStatus({ payload }) {
  const data = yield call(api.patchBugReports, payload);

  yield put(updateBugReport(data));
}

function* fetchMediData() {
  const { data } = yield call(api.getMediData);

  return { success: data[0] };
}

function* saveMediData({ payload }) {
  yield call(api.patchMediData, payload);
}

function* fetchUsers({ payload }) {
  const { data } = yield call(api.getUsers, payload);

  return { success: data };
}

function* fetchUser({ payload }) {
  return { success: yield call(api.getUser, payload) };
}

function* createUser({ payload }) {
  const { [ID]: id } = yield call(api.postUser, payload);

  return { success: id };
}

function* saveUser({ payload }) {
  yield call(api.patchUser, payload);
}

export default function* watchBugReports() {
  yield takeLatest(FETCH_BUG_REPORTS, withAlert(fetchBugReports));
  yield takeLatest(SEND_BUG_REPORT, withAlert(sendBugReport));
  yield takeEvery(CHANGE_BUG_REPORT_STATUS, withAlert(changeBugReportStatus));
  yield takeLeading(FETCH_MEDI_DATA, withAlert(fetchMediData));
  yield takeLatest(SAVE_MEDI_DATA, withAlert(saveMediData));
  yield takeLatest(FETCH_USERS, withAlert(fetchUsers));
  yield takeLatest(FETCH_USER, withAlert(fetchUser));
  yield takeLeading(CREATE_USER, withAlert(createUser));
  yield debounce(500, SAVE_USER, withAlert(saveUser));
}
