import { applySpec, pipe, prop, sortBy, map } from 'ramda';
import { ID, DATE } from 'store/examinations';

import { parseDate } from 'utils';

export const getDates = (list = []) =>
  pipe(
    sortBy(pipe(prop(DATE), ($) => 0 - new Date($))),
    map(
      applySpec({
        id: prop(ID),
        date: pipe(prop(DATE), parseDate),
      })
    )
  )(list);
