import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/FullScreenSpinner';

import Item from './Item';
import { Container, Wrapper, SpinnerWrap } from './styles';

const List = forwardRef(({ items, hasZipCode, onSelect, loading }, ref) => (
  <Container>
    <Wrapper ref={ref} $isLoading={loading}>
      {items.map((data) => (
        <Item key={data.id} data={data} hasZipCode={hasZipCode} onSelect={onSelect} />
      ))}
    </Wrapper>
    {loading && (
      <SpinnerWrap>
        <Spinner size={30} />
      </SpinnerWrap>
    )}
  </Container>
));

List.defaultProps = { loading: false };
List.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  hasZipCode: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default List;
