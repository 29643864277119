/* eslint react/no-array-index-key: 0 */

import React from 'react';
import moment from 'moment';
import releaseNotes from 'assets/releases.json';
import { Container, Wrapper, Version, Title, Note, Role, Text } from './styles';

const Releases = () => (
  <Container>
    <Title>Release Notes</Title>
    {releaseNotes.map((release) => (
      <Wrapper key={release.version}>
        <Version>{`Version ${release.version} vom ${moment(release.date).format('DD.MM.YYYY')}`}</Version>
        {release.updates.map((update, index) => (
          <Note key={index}>
            <Role>{update.role}</Role>
            <Text>{update.text}</Text>
          </Note>
        ))}
      </Wrapper>
    ))}
  </Container>
);

export default Releases;
