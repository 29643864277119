import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.header`
  display: grid;
  grid-template-columns: minmax(auto, 230px) 1fr minmax(auto, 230px);
  column-gap: 20px;
  align-items: center;
  position: sticky;
  top: 0;
  height: 70px;
  max-height: 70px;
  padding: 15px 20px;
  border-bottom: solid 1px var(--color-grey97);
  background-color: var(--color-white);
  z-index: 100;
`;

export const NavbarItems = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ $length }) => $length}, 150px);
  column-gap: 10px;
  justify-self: center;
`;

export const NavbarLink = styled(Link)`
  display: block;
  width: 100%;
  background-color: var(--color-greyF8);
  border: 1px solid;
  border-color: var(--color-greyF8);
  border-radius: var(--border-radius8);
  color: var(--color-grey6A);
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  line-height: 38px;
  letter-spacing: 0.92px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  @media (hover) {
    ${({ disabled }) =>
      !disabled &&
      `
      &:hover {
        background-color: var(--color-greyEC);
      }
    `}
  }
  ${({ disabled }) =>
    disabled &&
    `
    background-color: var(--color-white);
    border-color: var(--color-grey6A);
    cursor: not-allowed;
  `}
`;

export const Wrapper = styled.div`
  grid-column: -3/-1;
  display: grid;
  grid-template-columns: 150px 140px;
  justify-content: flex-end;
  column-gap: 10px;
  width: 100%;
`;

export const NavbarExtra = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const NotificationsContainer = styled.div`
  position: sticky;
  top: 70px;
  background-color: var(--color-white);
  z-index: 99;
`;
