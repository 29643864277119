import React, { useCallback, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { props } from 'ramda';

import CloseIcon from 'assets/svg-icons/Close';
import Spinner from 'components/FullScreenSpinner';
import { useInfoItems, useInitInfoItems, useInfoItemsActions, ID, HAS_MORE, CURSOR, LIMIT } from 'store/infoItems';

import Creator from './Creator';
import InfoItem from './InfoItem';
import { useTogglePanel } from './hooks';
import { Container, TitleWrap, CreatorWrap, Button, Title, ContentWrap, LoadContainer } from './styles';

const InfoBox = () => {
  const ref = useRef();
  const { id } = useParams();
  const [isOpen, handleClose] = useTogglePanel();
  const infoItems = useInfoItems();
  const infoItemsAlerts = useInitInfoItems(true, id);
  const { updatePagination } = useInfoItemsActions();
  const { loading } = infoItemsAlerts;
  const [hasMore, cursor, limit] = props([HAS_MORE, CURSOR, LIMIT], infoItemsAlerts);

  const handleScroll = useCallback(
    (e) => {
      const { scrollHeight, scrollTop, clientHeight } = e.target;

      if (hasMore && !loading && scrollHeight === scrollTop + clientHeight) {
        updatePagination({ [CURSOR]: cursor + limit });
      }
    },
    [cursor, hasMore, limit, loading, updatePagination]
  );

  useEffect(() => {
    const elem = ref.current;
    elem.addEventListener('scroll', handleScroll);

    return () => {
      elem.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <Container ref={ref} isOpen={isOpen}>
      <TitleWrap>
        <Button type="button" onClick={handleClose}>
          <CloseIcon />
        </Button>
        <Title>Infobox</Title>
      </TitleWrap>
      <CreatorWrap>
        <Creator isOpenMenu={isOpen} />
      </CreatorWrap>
      <ContentWrap>
        {infoItems.map((item) => (
          <InfoItem key={item[ID]} data={item} />
        ))}
        {loading && (
          <LoadContainer>
            <Spinner height="50px" />
          </LoadContainer>
        )}
      </ContentWrap>
    </Container>
  );
};

export default InfoBox;
