import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const Text = styled.p`
  font-size: var(--font-size18);
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: var(--color-grey97);
`;
