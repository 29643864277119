import React, { useMemo, useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { pipe, pathOr, find, propEq } from 'ramda';

import { useDiagnosesActions, useAlerts, EYE, ANALYSIS_FROM_PREVIOUS } from 'store/diagnoses';
import { ID, STATUS, EXAMINATION_STATUS, useCurrentExamination, useExaminations } from 'store/examinations';
import Button from 'components/Button';
import Spinner from 'components/FullScreenSpinner';
import { generatorDefaultStringValues, questionsObject, additionalFields } from '../utils';

import { getExamDate } from './utils';
import { Message } from './styles';

const AdoptLastAnalysis = () => {
  const location = useLocation();
  const { eye, area } = qs.parse(location.search.slice(1));
  const { values: analysis, setValues: setAnalysis, errors, setErrors, setFieldValue } = useFormikContext();
  const isAlreadyCopied = analysis[ANALYSIS_FROM_PREVIOUS];
  const currentExam = useCurrentExamination();
  const currentExamDate = useMemo(() => getExamDate(currentExam), [currentExam]);
  const examinations = useExaminations();
  const { fetchDiagnosesForExamination } = useDiagnosesActions();
  const { extractId, success, loading, error, resetAlerts } = useAlerts();

  const previousExamination = useMemo(
    () =>
      examinations.find(
        (examination) =>
          examination[ID] !== currentExam[ID] &&
          examination[STATUS] >= EXAMINATION_STATUS.RELEASED &&
          examination[STATUS] < EXAMINATION_STATUS.ABORTED &&
          currentExamDate > getExamDate(examination)
      ),
    [currentExam, currentExamDate, examinations]
  );

  const takeLastAnalysis = useCallback(() => {
    if (previousExamination && previousExamination[ID]) {
      extractId(fetchDiagnosesForExamination(previousExamination[ID]));
    }
  }, [extractId, fetchDiagnosesForExamination, previousExamination]);

  useEffect(() => {
    if (!success) return;

    const prevAnalysis = pipe(pathOr([], [0, area]), find(propEq(eye, EYE)))(success);

    resetAlerts();
    setAnalysis({
      ...(prevAnalysis || { ...generatorDefaultStringValues(questionsObject[area]), ...additionalFields(eye)[area] }),
      [ANALYSIS_FROM_PREVIOUS]: true,
    });
  }, [area, eye, resetAlerts, setAnalysis, success]);

  useEffect(() => {
    if (errors[ANALYSIS_FROM_PREVIOUS]) {
      setErrors({});
      setFieldValue(ANALYSIS_FROM_PREVIOUS, false);
    }
  }, [errors, setErrors, setFieldValue]);

  return (
    <>
      <Button
        type="button"
        mb={20}
        onClick={takeLastAnalysis}
        color={isAlreadyCopied ? 'success' : 'default'}
        disabled={isAlreadyCopied || !previousExamination}
      >
        {loading ? <Spinner size={40} height="40px" /> : 'Befunde aus letztem Untersuch übernehmen'}
      </Button>
      {error && <Message type="error">Beim Übernehmen der früheren Befunde ist ein Fehler aufgetreten.</Message>}
    </>
  );
};

export default AdoptLastAnalysis;
