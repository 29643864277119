import React, { useState, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { omit } from 'ramda';

import Portal, { BUG_REPORT } from 'components/Portal';
import Button from 'components/Button';
import Message from 'components/Message';
import Spinner from 'components/FullScreenSpinner';
import { useLoggedIn } from 'store/session';
import { EMAIL, SUB_SENDER, MESSAGE, PAGE_URL, useBugReportsActions, useAlerts } from 'store/bugReports';

import TextArea from './TextArea';
import TextInput from './TextInput';
import CloseIcon from './CloseIcon';
import { Container, Icon, Text, Wrapper, FormContainer, BtnContainer, MessageWrapper, CloseBtn } from './styles';
import { validationSchema } from './validation';

const ErrorReport = () => {
  const isLoggedIn = useLoggedIn();
  const { pathname, search, hash } = useLocation();
  const [open, setOpen] = useState();
  const { sendBugReport } = useBugReportsActions();
  const { extractId, loading, success, error, resetAlerts } = useAlerts();

  const onChangeOpen = useCallback(() => {
    setOpen(!open);
    if (open) {
      resetAlerts();
    }
  }, [open, resetAlerts]);

  const handleSend = useCallback(
    (values) => {
      extractId(
        sendBugReport({
          ...(!isLoggedIn && { [EMAIL]: values[SUB_SENDER] }),
          [MESSAGE]: values[MESSAGE],
          [PAGE_URL]: `${window.location.origin}${pathname}${search}${hash}`,
        })
      );
    },
    [extractId, sendBugReport, isLoggedIn, pathname, search, hash]
  );

  const { values, errors, handleSubmit, handleChange, resetForm, setErrors } = useFormik({
    initialValues: { [SUB_SENDER]: '', [MESSAGE]: '' },
    onSubmit: handleSend,
    validationSchema: validationSchema(isLoggedIn),
    validateOnChange: false,
  });

  const handleOnChange = useCallback(
    (e) => {
      const { name } = e.target;

      if (errors[name]) {
        setErrors(omit([name], errors));
      }

      if (success || error) {
        resetAlerts();
      }

      handleChange(e);
    },
    [error, errors, handleChange, resetAlerts, setErrors, success]
  );

  const handleClick = useCallback(() => {
    if (success || error) {
      resetAlerts();
    }
  }, [error, resetAlerts, success]);

  const onClose = useCallback(() => {
    setOpen(false);
    resetAlerts();
  }, [resetAlerts]);

  useEffect(() => {
    if (success) {
      resetForm({
        values: { [SUB_SENDER]: '', [MESSAGE]: '' },
        errors: {},
      });
    }
  }, [resetForm, success]);

  return (
    <>
      <Container onClick={onChangeOpen} isActive={open}>
        <Icon>!</Icon>
        <Text>Problem melden</Text>
      </Container>
      <Portal type={BUG_REPORT}>
        <Wrapper isOpen={open} onSubmit={handleSubmit}>
          <FormContainer>
            <CloseBtn type="button" onClick={onClose}>
              <CloseIcon />
            </CloseBtn>
            {!isLoggedIn && (
              <TextInput
                name={SUB_SENDER}
                value={values[SUB_SENDER]}
                onChange={handleOnChange}
                open={open}
                error={errors[SUB_SENDER]}
              />
            )}
            <TextArea
              name={MESSAGE}
              value={values[MESSAGE]}
              onChange={handleOnChange}
              rows={isLoggedIn ? 7 : 5}
              placeholder="Geben Sie uns Feedback zu Problemen, die bei Ihnen aufgetreten sind."
              open={isLoggedIn && open}
              error={errors[MESSAGE]}
            />
            <BtnContainer>
              {loading ? (
                <Spinner size={20} />
              ) : (
                <>
                  <Button type="submit" color="success" height="22px" mt={5} onClick={handleClick}>
                    Senden
                  </Button>
                  <MessageWrapper>
                    {error && (
                      <Message type="error" small={1} form={1}>
                        {error}
                      </Message>
                    )}
                    {success && (
                      <Message type="success" small={1} form={1}>
                        Nachricht gesendet
                      </Message>
                    )}
                  </MessageWrapper>
                </>
              )}
            </BtnContainer>
          </FormContainer>
        </Wrapper>
      </Portal>
    </>
  );
};

export default ErrorReport;
