import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_PATIENTS,
  UPDATE_PATIENT,
  DELETE_PATIENT,
  RESET_PATIENTS,
  UPDATE_FILTER_ORGANIZATION,
  UPDATE_FILTER_SEARCH,
  RESET_FILTERS,
  UPDATE_SORT,
  UPDATE_PAGINATION,
  FETCH_PATIENTS,
  CREATE_PATIENT,
  FETCH_PATIENT,
  SAVE_PATIENT,
  REMOVE_PATIENT,
  UPDATE_PATIENT_REPORTS,
  FETCH_PATIENT_REPORTS,
  FETCH_INSURANCES,
  UPLOAD_MEDIA_FILE,
  SEARCH_PATIENTS,
  MERGE_PATIENT_RECORDS,
  AUTOCOMPLETE_ADDRESS,
} from './types';

export const updatePatients = createAction(UPDATE_PATIENTS);
export const updatePatient = createAction(UPDATE_PATIENT);
export const deletePatient = createAction(DELETE_PATIENT);
export const resetPatients = createAction(RESET_PATIENTS);
export const updateFilterOrganization = createAction(UPDATE_FILTER_ORGANIZATION);
export const updateFilterSearch = createAction(UPDATE_FILTER_SEARCH);
export const resetFilters = createAction(RESET_FILTERS);
export const updateSort = createAction(UPDATE_SORT);
export const updatePagination = createAction(UPDATE_PAGINATION);
export const updatePatientReports = createAction(UPDATE_PATIENT_REPORTS);

export const fetchPatients = createAlertAction(FETCH_PATIENTS);
export const fetchPatient = createAlertAction(FETCH_PATIENT);
export const createPatient = createAlertAction(CREATE_PATIENT);
export const savePatient = createAlertAction(SAVE_PATIENT);
export const removePatient = createAlertAction(REMOVE_PATIENT);
export const fetchPatientReports = createAlertAction(FETCH_PATIENT_REPORTS);
export const fetchInsurances = createAlertAction(FETCH_INSURANCES);
export const uploadMediaFile = createAlertAction(UPLOAD_MEDIA_FILE);
export const searchPatients = createAlertAction(SEARCH_PATIENTS);
export const mergePatientRecords = createAlertAction(MERGE_PATIENT_RECORDS);
export const autocompleteAddress = createAlertAction(AUTOCOMPLETE_ADDRESS);
