import styled from 'styled-components';

import Button from 'components/Button';
import AnimateBox from 'components/AnimateBox';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  row-gap: 10px;
`;

export const ConfirmContainer = styled(AnimateBox)`
  display: grid;
  grid-template-columns: auto repeat(2, 20%);
  column-gap: 30px;
  justify-content: end;
  align-items: center;
`;

export const Label = styled.p`
  font-size: var(--font-size14);
  font-weight: var(--font-weight700);
  letter-spacing: 0.5px;
  color: var(--color-orangeDC);
`;

export const NoButton = styled(Button)`
  height: 40px;
  border: solid 1px var(--color-orangeF5);
  background-color: var(--color-orangeFE);
`;
