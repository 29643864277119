import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { reducerTemplate } from 'store/utils';
import { ID, SORT, SORT_BY, SORT_DIR, PAGINATION, CURSOR, LIMIT, COUNT, HAS_MORE, DESC, META, CREATED } from '.';

import { LIST, FILTERS } from './consts';
import {
  UPDATE_BUG_REPORTS,
  UPDATE_BUG_REPORT,
  DELETE_BUG_REPORT,
  RESET_BUG_REPORTS,
  UPDATE_SORT,
  UPDATE_PAGINATION,
  RESET_FILTERS,
} from './types';

const bugReportsReducer = reducerTemplate(
  {
    id: ID,
    list: UPDATE_BUG_REPORTS,
    item: UPDATE_BUG_REPORT,
    deleteItem: DELETE_BUG_REPORT,
    reset: RESET_BUG_REPORTS,
  },
  {}
);

const initFilters = {
  [SORT]: {
    [SORT_BY]: `${META}.${CREATED}`,
    [SORT_DIR]: DESC,
  },
  [PAGINATION]: {
    [CURSOR]: 0,
    [LIMIT]: 10,
    [COUNT]: 0,
    [HAS_MORE]: false,
  },
};

const filtersReducer = handleActions(
  {
    [UPDATE_SORT]: (state, { payload }) => ({
      ...state,
      [SORT]: payload,
      [PAGINATION]: {
        ...state[PAGINATION],
        [CURSOR]: 0,
      },
    }),
    [UPDATE_PAGINATION]: (state, { payload }) => ({
      ...state,
      [PAGINATION]: {
        ...state[PAGINATION],
        ...payload,
      },
    }),
    [RESET_FILTERS]: () => initFilters,
  },
  initFilters
);

export default combineReducers({
  [LIST]: bugReportsReducer,
  [FILTERS]: filtersReducer,
});
