import styled from 'styled-components';

export const Wrapper = styled.div`
  border-radius: var(--border-radius8);
  margin-bottom: 10px;
  width: 100%;
  padding: 12px;
  background: #f5f5f5;
  max-width: ${({ layout }) => (layout === 'small' ? '235px' : 'auto')};
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  min-height: 29px;
`;

export const Values = styled.div`
  display: ${({ layout }) => (layout === 'small' ? 'block' : 'flex')};
  min-height: ${({ layout }) => (layout === 'small' ? '142px' : 'auto')};
  justify-content: space-between;
  margin-top: 12px;
  border: 1px solid #858585;
  padding: 10px;
  border-radius: var(--border-radius8);
`;

export const Label = styled.div`
  font-size: var(--font-size14);
  font-weight: var(--font-weight600);
  letter-spacing: 0.55px;
  color: var(--color-grey6A);
`;

export const Change = styled.p`
  background: ${({ isGrowing }) => (isGrowing ? '#c4f6eb' : '#f6cec4')};
  border: ${({ isGrowing }) => `1px solid ${isGrowing ? 'var(--color-blue50)' : 'var(--color-red)'}`};
  color: ${({ isGrowing }) => (isGrowing ? '#018869' : 'var(--color-red)')};
  padding: 5px 10px;
  border-radius: var(--border-radius8);
  margin-left: 10px;
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  letter-spacing: 0.92px;
`;
