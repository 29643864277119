import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/FullScreenSpinner';
import ArrowRight from 'assets/svg-icons/ArrowRight';
import { SORT_BY, SORT_DIR, CURSOR } from 'api/consts';

import HeadItem from './HeadItem';
import { Title, Container, THead, TBody, TFoot, PageButton, PaginationInfo, Message, Cover } from './styles';

const Table = ({ title, head, list, renderRow, sort, cursor, count, limit, hasMore, updateSort, updatePagination, loading }) => {
  const pageInfo = useMemo(() => {
    const all = Math.ceil(count / limit);
    const current = all ? Math.floor(cursor / limit) + 1 : 0;

    return `Seite ${current} von ${all}`;
  }, [count, limit, cursor]);
  const prevPage = useCallback(() => {
    if (cursor >= limit) {
      updatePagination({ [CURSOR]: cursor - limit });
    }
  }, [cursor, limit, updatePagination]);
  const nextPage = useCallback(() => {
    if (hasMore) {
      updatePagination({ [CURSOR]: cursor + limit });
    }
  }, [hasMore, cursor, limit, updatePagination]);
  const widths = useMemo(() => head.map(({ width }) => width), [head]);

  return (
    <>
      {title && <Title>{title}</Title>}
      <Container>
        <THead>
          {head.map(({ id, content, width, withoutSort }) => (
            <HeadItem
              key={id}
              id={id}
              width={width}
              sortId={sort[SORT_BY]}
              sortDir={sort[SORT_DIR]}
              onClick={updateSort}
              withoutSort={withoutSort}
            >
              {content}
            </HeadItem>
          ))}
        </THead>
        <TBody>
          {!list.length && <Message type="error">Keine Daten vorhanden</Message>}
          {list.map((item) => renderRow({ key: item.id, widths, ...item }))}
          {loading && (
            <Cover>
              <Spinner height="100%" />
            </Cover>
          )}
        </TBody>
        {Boolean(count && limit) && (
          <TFoot>
            <PaginationInfo>{pageInfo}</PaginationInfo>
            <PageButton disabled={cursor === 0 || loading} onClick={prevPage}>
              <ArrowRight />
            </PageButton>
            <PageButton disabled={!hasMore || loading} onClick={nextPage}>
              <ArrowRight />
            </PageButton>
          </TFoot>
        )}
      </Container>
    </>
  );
};

Table.defaultProps = {
  title: null,
  head: [],
  list: [],
  sort: {},
  cursor: 0,
  count: 0,
  limit: 0,
  hasMore: false,
  updateSort: () => null,
  updatePagination: () => null,
  loading: false,
};

Table.propTypes = {
  title: PropTypes.string,
  head: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
      width: PropTypes.string.isRequired,
      withoutSort: PropTypes.bool,
    })
  ),
  list: PropTypes.arrayOf(PropTypes.shape({})),
  renderRow: PropTypes.func.isRequired,
  sort: PropTypes.shape({
    [SORT_BY]: PropTypes.string.isRequired,
    [SORT_DIR]: PropTypes.string.isRequired,
  }),
  cursor: PropTypes.number,
  count: PropTypes.number,
  limit: PropTypes.number,
  hasMore: PropTypes.bool,
  updateSort: PropTypes.func,
  updatePagination: PropTypes.func,
  loading: PropTypes.bool,
};

export default Table;
