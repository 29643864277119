import React, { useMemo, useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { useInvoicesActions, useAlerts, ID, SUB_PATIENT, SUB_EXAMINATION, STATUS, INVOICE_STATUS } from 'store/invoices';
import { normalizeArray } from 'store/utils';
import { useAsyncState } from 'utils/useAsyncState';
import Spinner from 'components/FullScreenSpinner';
import AddItem from 'components/AddItemBar';

import Panel from './Panel';
import Form from './Form';
import { Container, SpinnerWrap } from './styles';

const PatientInvoices = () => {
  const { id } = useParams();
  const { replace, location, push } = useHistory();
  const { pathname, search } = location;
  const [{ list, objects }, setData] = useAsyncState({ list: [] });
  const { fetchInvoicesByPatient, createInvoice } = useInvoicesActions();
  const { action: getInvoices, id: actionId, loading: getLoading, success: getSuccess } = useAlerts(fetchInvoicesByPatient);
  const { action: create, loading: createLoading, success: createSuccess, resetAlerts } = useAlerts(createInvoice);
  const current = useMemo(() => {
    const invoiceId = search && new URLSearchParams(search).get('invoice');

    if (!list?.length) return null;

    return invoiceId ? objects[new URLSearchParams(search).get('invoice')] : list[0];
  }, [list, search, objects]);
  const onCreate = useCallback((data) => create({ [SUB_PATIENT]: id }), [create, id]);
  const updateInvoice = useCallback(
    (invoice) =>
      setData(($) => {
        const updated = $.list.map((item) => (item[ID] === invoice[ID] ? invoice : item));

        return {
          list: updated,
          objects: normalizeArray(ID, updated),
        };
      }),
    [setData]
  );
  const readMode = useMemo(
    () => !current || Boolean(current[SUB_EXAMINATION]) || current[STATUS] !== INVOICE_STATUS.IN_PREPARATION,
    [current]
  );

  useEffect(() => {
    getInvoices(id);
  }, [getInvoices, id]);

  useEffect(() => {
    if (getSuccess) setData({ list: getSuccess, objects: normalizeArray(ID, getSuccess) });
  }, [setData, getSuccess]);

  useEffect(() => {
    if (list.length && !current) replace(pathname);
  }, [current, list.length, pathname, replace]);

  useEffect(() => {
    if (createSuccess) {
      setData(($) => {
        const updated = [createSuccess, ...$.list];

        return {
          list: updated,
          objects: normalizeArray(ID, updated),
        };
      });
      resetAlerts();
      push(`${pathname}?${new URLSearchParams({ invoice: createSuccess[ID] }).toString()}`);
    }
  }, [createSuccess, setData, resetAlerts, push, pathname]);

  if (!actionId || getLoading) return <Spinner />;

  return (
    <Container>
      <AddItem label="Neue Rechnung" onClick={onCreate} />
      <Panel list={list} current={current} />
      {Boolean(current) && <Form key={current[ID]} data={current} updateData={updateInvoice} readMode={readMode} />}
      {createLoading && (
        <SpinnerWrap>
          <Spinner height="100%" />
        </SpinnerWrap>
      )}
    </Container>
  );
};

export default PatientInvoices;
