import styled from 'styled-components';

import DefSelect from 'components/SelectNew';
import DefInput from 'components/TextInputNew';

export const Container = styled.div`
  width: ${({ width }) => width};
`;

export const P = styled.p`
  color: var(--color-grey6A);
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  line-height: 1.15;
  letter-spacing: 0.5px;
  padding-top: 5px;
  min-height: 28px;
`;

export const Select = styled(DefSelect)`
  font-size: var(--font-size13);
`;

export const TextInput = styled(DefInput)`
  font-size: var(--font-size13);
  background-color: ${({ $isRed, readMode }) =>
    ($isRed && '#fdd7d7') || (readMode && 'var(--color-greyEC)') || 'var(--color-white)'};
`;

export const ErrorMessage = styled.p`
  margin: 3px 0 0 4px;
  color: var(--color-orangeDC);
  font-size: var(--font-size10);
  word-break: break-word;
`;
