import React from 'react';

import { Container, P, StyledLink } from './styles';

const Warn2FA = () => (
  <Container>
    <P>Aktivieren Sie die Zwei-Faktor-Authentifizierung für mehr Sicherheit.</P>
    <StyledLink to="/profile/authentication">Jetzt aktivieren.</StyledLink>
  </Container>
);

export default Warn2FA;
