import styled from 'styled-components';

export const Inputs = styled.div`
  display: grid;
  grid-template-columns: ${({ $withoutButton }) => ($withoutButton ? '1fr' : '1fr 36px')};
  grid-template-rows: auto auto;
  column-gap: 10px;
`;

export const InputWrap = styled.div`
  display: grid;
  grid-template-columns: 230px 1fr 1fr;
  column-gap: 10px;
  align-items: flex-start;
  &:before {
    content: '${({ $label }) => $label}';
    display: flex;
    align-items: center;
    min-height: 40px;
    font-size: var(--font-size15);
    font-weight: var(--font-weight400);
    color: var(--color-grey6A);
  }
`;
