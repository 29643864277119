import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Container, Left, Right } from './styles';

const ArrowIcon = ({ isOpen, className }) => {
  const [defaultOpen] = useState(isOpen);

  return (
    <Container className={className}>
      <Left isOpen={isOpen} defaultOpen={defaultOpen} />
      <Right isOpen={isOpen} defaultOpen={defaultOpen} />
    </Container>
  );
};

ArrowIcon.defaultProps = {
  isOpen: false,
  className: '',
};
ArrowIcon.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
};

export default ArrowIcon;
