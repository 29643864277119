import styled from 'styled-components';

import PaginationDropDown from 'components/PaginationDropDown';

export const Container = styled.div`
  max-width: 1024px;
  margin: 30px auto;
`;

export const DropDown = styled(PaginationDropDown)`
  margin-bottom: 70px;
`;
