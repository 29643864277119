import styled from 'styled-components';
import { motion } from 'framer-motion';

import Btn from 'components/Button';

export const Container = styled.div`
  height: 40px;
  width: 40px;
  background-color: ${({ isActive }) => (isActive ? 'var(--color-white)' : 'var(--color-greyF8)')};
  border: ${({ isActive }) => `1px solid ${isActive ? 'var(--color-grey6A)' : 'var(--color-white)'}`};
  border-radius: var(--border-radius8);
  padding: 4px;
  box-sizing: border-box;

  &:hover {
    background-color: var(--color-greyEC);
    cursor: pointer;
  }
`;

export const Icon = styled.p`
  color: var(--color-grey6A);
  font-weight: var(--font-weight500);
  font-size: 16px;
  line-height: 16px;
`;

export const Text = styled.p`
  color: var(--color-grey6A);
  font-weight: var(--font-weight500);
  font-size: 8px;
  line-height: 8px;
`;

const getAnimate = ({ isOpen }) => ({
  animate: {
    y: isOpen ? 0 : '-100vh',
    opacity: isOpen ? 1 : 0,
  },
  initial: { y: '-100vh', opacity: 0 },
});

export const Wrapper = styled(motion.form).attrs(getAnimate)`
  position: fixed;
  top: 85px;
  right: 20px;
  width: 220px;
  z-index: 100;
`;

export const FormContainer = styled.div`
  width: 100%;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyF8);
  padding: 12px;
`;

export const CloseBtn = styled(Btn)`
  width: 40px;
  height: 40px;
  background: inherit;
  margin: -8px 0 0 -8px;
`;

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MessageWrapper = styled.div`
  margin-top: 5px;
`;
