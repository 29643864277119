import { propOr, prop, pipe, applySpec, props, filter, path, join } from 'ramda';
import moment from 'moment';

import { ID, INVOICE_LABELS, SUB_INSURANCE } from 'store/invoices';
import { SUB_PATIENT, LAST_NAME, FIRST_NAME, META, CREATED, NAME } from 'store/patients';

export const tableHead = [
  {
    id: `${SUB_INSURANCE}.${NAME}`,
    content: 'Krankenkasse',
    width: '25%',
    withoutSort: true,
  },
  {
    id: `${SUB_PATIENT}.${LAST_NAME}`,
    content: 'Patient',
    width: '30%',
    withoutSort: true,
  },
  {
    id: `${META}.${CREATED}`,
    content: 'Datum',
    width: '15%',
  },
  {
    id: 'amountDue',
    content: 'Betrag',
    width: '15%',
  },
  {
    id: 'status',
    content: 'Status',
    width: '15%',
  },
];

export const prepareInvoices = (invoices = []) =>
  invoices.map(
    applySpec({
      id: prop(ID),
      insurance: path([SUB_INSURANCE, NAME]),
      name: pipe(propOr({}, SUB_PATIENT), props([LAST_NAME, FIRST_NAME]), filter(Boolean), join(', ')),
      status: ({ status }) => INVOICE_LABELS[status] && INVOICE_LABELS[status].toUpperCase(),
      date: pipe(path([META, CREATED]), (date) => date && moment(date).format('DD.MM.YYYY')),
      price: ({ currency, amountDue }) =>
        `${(currency && currency.toUpperCase()) || ''} ${amountDue ? Number(amountDue / 100).toFixed(2) : '0.00'}`,
    })
  );
