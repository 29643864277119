import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container, Item, Auge, Wrapper, Button } from './styles';

const Row = ({ id: serviceId, tariffType, code, name, bodyLocation, widths, icon, action }) => {
  const handleAction = useCallback(() => action(serviceId), [action, serviceId]);

  return (
    <Container>
      <Item width={widths[0]}>{tariffType}</Item>
      <Item width={widths[1]}>{code}</Item>
      <Item width={widths[2]}>{name}</Item>
      <Auge width={widths[3]}>{bodyLocation}</Auge>
      <Wrapper>
        {icon && (
          <Button type="button" onClick={handleAction}>
            {icon}
          </Button>
        )}
      </Wrapper>
    </Container>
  );
};

Row.defaultProps = {
  icon: null,
};

Row.propTypes = {
  id: PropTypes.string.isRequired,
  tariffType: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  bodyLocation: PropTypes.string.isRequired,
  widths: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  icon: PropTypes.node,
  action: PropTypes.func.isRequired,
};

export default Row;
