import { useCallback, useEffect } from 'react';
import { is } from 'ramda';

import { usePatientsActions, useAlerts } from 'store/patients';

export const useCreatePatient = (history) => {
  const { createPatient } = usePatientsActions();

  const { extractId, loading, success, resetAlerts } = useAlerts();

  const postPatient = useCallback(() => {
    extractId(createPatient());
  }, [createPatient, extractId]);

  useEffect(() => {
    if (is(String, success)) {
      history.push(`/patients/${success}`, { previousPath: '/patients/search' });
      resetAlerts();
    }
  }, [history, resetAlerts, success]);

  return { postPatient, loading };
};
