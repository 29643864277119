import React, { forwardRef, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import CrossIcon from 'assets/svg-icons/Close';

import VisibilityIcon from './VisibilityIcon';
import VisibilityOffIcon from './VisibilityOffIcon';
import { Container, Label, Wrapper, Input, Button, Message } from './styles';

const TextInput = forwardRef(
  (
    { type, label, value, handleChangeText, onChange, error, disabled, readMode, mt, mb, labelWidth, labelSize, ...rest },
    ref
  ) => {
    const inputRef = useRef();
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = useCallback(
      (e) => {
        onChange(e);
        handleChangeText(e.target.value);
      },
      [handleChangeText, onChange]
    );

    const handleClear = useCallback(() => {
      if (disabled) return;
      const elem = ref?.current || inputRef.current;
      const lastValue = elem.value;
      elem.value = '';
      const tracker = elem._valueTracker; // eslint-disable-line no-underscore-dangle
      if (tracker) tracker.setValue(lastValue);

      elem.dispatchEvent(new Event('change', { bubbles: true }));
    }, [disabled, ref]);

    const showHidePassword = useCallback(() => setShowPassword(($) => !$), []);

    return (
      <Container label={label} labelWidth={labelWidth} mt={mt} mb={mb}>
        {label && <Label labelSize={labelSize}>{label}</Label>}
        <Wrapper>
          <Input
            ref={ref || inputRef}
            type={(type === 'password' && showPassword) || type === 'search' ? 'text' : type}
            currentType={type}
            value={value}
            onChange={handleChange}
            error={error}
            disabled={disabled || readMode}
            readMode={readMode}
            {...rest}
          />
          <Button
            type="button"
            aria-label="Clear search"
            onClick={handleClear}
            isVisible={type === 'search' && Boolean(value) && !readMode}
          >
            <CrossIcon />
          </Button>
          <Button
            type="button"
            aria-label="Show/Hide password"
            onClick={showHidePassword}
            isVisible={type === 'password' && !readMode}
          >
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </Button>
        </Wrapper>
        {error && !readMode && typeof error === 'string' && <Message label={label}>{error}</Message>}
      </Container>
    );
  }
);

TextInput.defaultProps = {
  id: null,
  type: 'text',
  label: null,
  value: '',
  handleChangeText: () => null,
  onChange: () => null,
  error: null,
  disabled: false,
  readMode: false,
  mt: 0,
  mb: 10,
  labelWidth: '200px',
  labelSize: 'var(--font-size15)',
};
TextInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleChangeText: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
  readMode: PropTypes.bool,
  mt: PropTypes.number,
  mb: PropTypes.number,
  labelWidth: PropTypes.string,
  labelSize: PropTypes.string,
};

export default TextInput;
