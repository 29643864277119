import styled from 'styled-components';

import { LeftArrow } from '../styles';

export const SurveySection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Left = styled(LeftArrow)`
  width: 36px;
  height: 210px;
`;

export const Right = styled(Left)`
  transform: rotate(180deg);
`;

export const AnswerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;
  padding-left: 10px;

  &::after {
    content: '';
    flex: auto;
  }
`;

export const AnswerItem = styled(LeftArrow)`
  width: calc(25% - 10px);
  color: var(--color-grey6A);
  &:disabled {
    border: 1px solid var(--color-grey97);
    background: var(--color-white);
  }
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  padding: 8px;
  text-transform: uppercase;
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  line-height: 1.15;
  letter-spacing: 0.9px;
  word-break: break-word;
  margin-bottom: ${({ lastRow }) => (lastRow ? 0 : '10px')};
  margin-right: 10px;

  ${({ readMode, disabled }) =>
    readMode &&
    !disabled &&
    `
    &:hover, &:active {
      background: var(--color-greyF8);
    };
    &:focus {
      box-shadow: none;
    };
  `}
`;
