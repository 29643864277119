import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { map, prop } from 'ramda';
import PropTypes from 'prop-types';

import { usePlanningActions, useAlerts } from 'store/planning';
import TextInput from 'components/TextInputNew';
import Dropzone from 'components/Dropzone';
import Message from 'components/Message';

import MultiImage from './MultiImage';
import { Container, Wrapper } from './styles';

const Section = ({ index, ward, files, waitFiles, onChange, saveMedia }) => {
  const { id } = useParams();
  const { uploadScheduleFormMedia, saveScheduleFormMedias } = usePlanningActions();
  const { action: upload, loading: uploadLoading, error: uploadErr } = useAlerts(uploadScheduleFormMedia);
  const { action: saveMedias, loading: saveMediasLoading, error: saveMediasErr } = useAlerts(saveScheduleFormMedias);
  const onChangeWard = useCallback(
    ({ currentTarget }) => {
      if (files.length) saveMedias({ files, id, ward: currentTarget.value });

      onChange(($) => {
        const updated = [...$];
        updated[index] = { ...updated[index], ward: currentTarget.value };
        return updated;
      });
    },
    [files, id, index, onChange, saveMedias]
  );
  const onDelete = useCallback(
    (items) => {
      onChange(($) => {
        const updated = [...$];
        updated[index] = { ...updated[index], items };
        return updated;
      });
    },
    [index, onChange]
  );
  const onDrop = useCallback(
    (values) => {
      upload({ files: values, id, ward });
      onChange(($) => {
        const updated = [...$];
        updated[index] = { ...updated[index], wait: [...updated[index].wait, ...map(prop('path'), values)] };
        return updated;
      });
    },
    [upload, id, ward, onChange, index]
  );

  useEffect(() => {
    if (uploadErr) {
      onChange(($) => {
        const updated = [...$];
        updated[index] = { ...updated[index], wait: [] };
        return updated;
      });
    }
  }, [uploadErr, index, onChange]);

  return (
    <Container>
      <TextInput
        label="Station"
        value={ward}
        onChange={onChangeWard}
        placeholder="Keine Station"
        error={saveMediasErr}
        disabled={uploadLoading}
      />
      <Wrapper>
        <MultiImage values={files} waiting={waitFiles} updateValues={onDelete} ward={ward} saveMedia={saveMedia} />
        <Dropzone onDrop={onDrop} loading={uploadLoading} disabled={saveMediasLoading || uploadLoading} />
      </Wrapper>
      {uploadErr && (
        <Message type="error" form={1}>
          {uploadErr}
        </Message>
      )}
    </Container>
  );
};

Section.propTypes = {
  index: PropTypes.number.isRequired,
  ward: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  waitFiles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
  saveMedia: PropTypes.func.isRequired,
};

export default Section;
