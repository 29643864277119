import styled from 'styled-components';

import Select from './Select';

export const SelectX = styled(Select)`
  position: absolute;
  inset: auto 3px 3px auto;
`;

export const SelectY = styled(Select)`
  position: absolute;
  inset: 3px auto auto 3px;
`;

export const SelectZ = styled(Select)``;
