import React, { useState, useMemo, useCallback } from 'react';
import { pipe, find, propEq, prop, max, dec, min, inc, not } from 'ramda';
import PropTypes from 'prop-types';

import ArrowRight from 'assets/svg-icons/ArrowRight';
import ImagesReview from 'components/ImagesReview';
import { URL } from 'api/consts';
import { extractImage } from 'utils';
import { LeftArrow, RightArrow } from '../styles';

import { ImagePaginationContainer, ImagesSection, EyeImage } from './styles';

const Images = ({ items, currentId }) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [isOpenReview, setIsOpenReview] = useState();
  const currentExamImages = useMemo(
    () => pipe(find(propEq(currentId, 'id')), prop('files'), extractImage)(items),
    [currentId, items]
  );

  const handleClick = useCallback(
    ({ currentTarget }) => {
      const { id } = currentTarget.dataset;
      if (id === 'dec') setActiveImageIndex((val) => max(0, dec(val)));
      if (id === 'inc') setActiveImageIndex((val) => min(currentExamImages.length, inc(val)));
    },
    [currentExamImages]
  );
  const handleReviewClick = useCallback(() => setIsOpenReview(not), []);

  const counter = useMemo(
    () => (currentExamImages.length ? `${activeImageIndex + 1}/${currentExamImages.length}` : '0/0'),
    [currentExamImages, activeImageIndex]
  );
  const currentImagePath = useMemo(() => currentExamImages[activeImageIndex]?.path, [currentExamImages, activeImageIndex]);

  const allImages = useMemo(
    () =>
      items.reduce((acc, { id, date, files }) => {
        files.forEach(({ path, description, mimetype, originalname }) => {
          acc.push({
            path,
            description,
            mimetype,
            originalname,
            date,
            isCurrent: id === currentId,
          });
        });

        return acc;
      }, []),
    [items, currentId]
  );

  return (
    <>
      <ImagesSection>
        <LeftArrow type="button" data-id="dec" disabled={activeImageIndex === 0} onClick={handleClick}>
          <ArrowRight />
        </LeftArrow>
        <EyeImage src={currentImagePath && `${URL}/${currentImagePath}`} onClick={handleReviewClick} />
        <RightArrow
          type="button"
          data-id="inc"
          disabled={!currentExamImages.length || activeImageIndex === currentExamImages.length - 1}
          onClick={handleClick}
        >
          <ArrowRight />
        </RightArrow>
      </ImagesSection>
      <ImagePaginationContainer>{counter}</ImagePaginationContainer>
      {isOpenReview && (
        <ImagesReview images={allImages} currentImage={currentImagePath} onClose={handleReviewClick} withCompare />
      )}
    </>
  );
};

Images.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      files: PropTypes.arrayOf(
        PropTypes.shape({
          path: PropTypes.string.isRequired,
          description: PropTypes.string,
          mimetype: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  currentId: PropTypes.string.isRequired,
};

export default Images;
