import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useBugReportsActions, useAlerts } from 'store/bugReports';
import Spinner from 'components/FullScreenSpinner';

import Form from './Form';

const User = () => {
  const { id } = useParams();
  const { fetchUser } = useBugReportsActions();
  const { id: actionId, action, loading, success } = useAlerts(fetchUser);

  useEffect(() => {
    if (id !== 'new') action(id);
  }, [action, id]);

  if (id === 'new') return <Form key={id} />;
  if (!actionId || loading) return <Spinner />;

  return <Form key={id} id={id} data={success} />;
};

export default User;
