import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import {
  PREVENT_ANALYSIS,
  PREVENT_ANALYSIS_REPORT,
  APPROVALS,
  APPROVAL_COMMENT,
  SUB_USER,
  useCurrentDiagnosis,
  useDiagnosesActions,
  useAlerts,
  useDiagnosisReports,
} from 'store/diagnoses';
import { useInvoicesActions, APPROVAL_STATUS } from 'store/invoices';
import { ID, STATUS, EXAMINATION_STATUS, useExaminationsActions, useCurrentExamination } from 'store/examinations';
import { useCheckDiagnosisByRole } from 'permissions';
import Button from 'components/Button';
import Message from 'components/Message';

import ApprovalsPanel from './ApprovalsPanel';

const SubmitSection = ({ readMode, disabled }) => {
  const [examinationId, status] = useCurrentExamination([ID, STATUS]);
  const [diagnosisId, noAnalysis, noAnalysisReport, approvals, approvalComment] = useCurrentDiagnosis([
    ID,
    PREVENT_ANALYSIS,
    PREVENT_ANALYSIS_REPORT,
    APPROVALS,
    APPROVAL_COMMENT,
  ]);
  const diagnosisReports = useDiagnosisReports();
  const [message, setMessage] = useState(approvalComment || '');
  const { userId, needApproval, shouldApprove, canSend } = useCheckDiagnosisByRole(status, approvals);
  const { sendInvoiceReport, cancelInvoice } = useInvoicesActions();
  const { saveExamination } = useExaminationsActions();
  const { createDiagnosisReports, saveDiagnosis, withdrawDiagnosis } = useDiagnosesActions();
  const { extractId, loading } = useAlerts();
  const { action: sendInvoice, loading: sendInvoiceLoading, error: sendInvoiceError } = useAlerts(sendInvoiceReport);
  const canWithdraw = useMemo(
    () =>
      status === EXAMINATION_STATUS.RELEASED &&
      diagnosisReports?.length > 0 &&
      !diagnosisReports.some(({ [STATUS]: reportStatus }) => reportStatus === 2),
    [diagnosisReports, status]
  );

  const updateApprovalComment = useCallback(
    (text) => {
      setMessage(text);
      saveDiagnosis({
        diagnosisId,
        [APPROVAL_COMMENT]: text,
      });
    },
    [saveDiagnosis, diagnosisId]
  );

  const updateDiagnosis = useCallback(
    (approvalStatus) => {
      extractId(
        saveDiagnosis({
          diagnosisId,
          [APPROVALS]: [
            ...approvals,
            {
              status: approvalStatus,
              message,
              timestamp: new Date().toISOString(),
              [SUB_USER]: userId,
            },
          ],
          [APPROVAL_COMMENT]: '',
        })
      );
    },
    [saveDiagnosis, diagnosisId, userId, message, approvals, extractId]
  );

  const sendForApproval = useCallback(() => {
    saveExamination({
      [ID]: examinationId,
      [STATUS]: EXAMINATION_STATUS.IN_APPROVAL,
    });
    updateDiagnosis(APPROVAL_STATUS.IN_APPROVAL);
  }, [updateDiagnosis, saveExamination, examinationId]);
  const approveDiagnosis = useCallback(() => {
    saveExamination({
      [ID]: examinationId,
      [STATUS]: EXAMINATION_STATUS.APPROVED,
    });
    updateDiagnosis(APPROVAL_STATUS.APPROVED);
  }, [updateDiagnosis, saveExamination, examinationId]);
  const rejectDiagnosis = useCallback(() => {
    saveExamination({
      [ID]: examinationId,
      [STATUS]: EXAMINATION_STATUS.UNDER_REVIEW,
    });
    updateDiagnosis(APPROVAL_STATUS.REJECTED);
  }, [updateDiagnosis, saveExamination, examinationId]);
  const sendReport = useCallback(() => sendInvoice(message), [message, sendInvoice]);
  const abortExamination = useCallback(() => {
    cancelInvoice();
    saveExamination({ [ID]: examinationId, [STATUS]: EXAMINATION_STATUS.ABORTED });
    updateDiagnosis(APPROVAL_STATUS.SENT);
  }, [cancelInvoice, saveExamination, examinationId, updateDiagnosis]);
  const abortExaminationWithReport = useCallback(() => {
    abortExamination();
    createDiagnosisReports(diagnosisId);
  }, [createDiagnosisReports, diagnosisId, abortExamination]);
  const withdraw = useCallback(() => {
    extractId(withdrawDiagnosis(diagnosisId));
  }, [diagnosisId, extractId, withdrawDiagnosis]);

  const defaultProps = useMemo(
    () => ({
      type: 'button',
      color: 'success',
      disabled: (readMode || loading) && !canSend,
    }),
    [readMode, loading, canSend]
  );

  if (noAnalysis) {
    return (
      <Button {...defaultProps} color="warning" onClick={noAnalysisReport ? abortExaminationWithReport : abortExamination}>
        {noAnalysisReport ? 'Bericht zu abgebrochenem Untersuch freigeben' : 'Untersuch ohne Bericht abbrechen'}
      </Button>
    );
  }

  return (
    <>
      <ApprovalsPanel
        withMessage={!readMode && (needApproval || shouldApprove)}
        approvals={approvals}
        message={message}
        setMessage={updateApprovalComment}
      />
      {needApproval && (
        <Button {...defaultProps} onClick={sendForApproval} disabled={defaultProps.disabled || disabled}>
          Zur Überprüfung senden
        </Button>
      )}
      {shouldApprove && (
        <>
          <Button {...defaultProps} mb={10} onClick={approveDiagnosis} disabled={defaultProps.disabled || disabled}>
            Zum Versand freigeben
          </Button>
          <Button {...defaultProps} color="warning" onClick={rejectDiagnosis}>
            Zur Korrektur Senden
          </Button>
        </>
      )}
      {canSend && (
        <Button {...defaultProps} onClick={sendReport} disabled={readMode || sendInvoiceLoading || disabled}>
          Bericht und Rechnung senden
        </Button>
      )}
      {!(needApproval || shouldApprove || canSend) && (
        <Button {...defaultProps} disabled>
          Bericht und Rechnung senden
        </Button>
      )}
      {canWithdraw && (
        <Button type="button" color="warning" onClick={withdraw} mt={60} disabled={loading}>
          Berichte zurückrufen
        </Button>
      )}
      {sendInvoiceError && <Message type="error">{sendInvoiceError}</Message>}
    </>
  );
};

SubmitSection.propTypes = {
  readMode: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default SubmitSection;
