import styled from 'styled-components';

import Btn from 'components/Button';

export const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 100%;
`;

export const Button = styled(Btn)`
  width: 96px;
  height: 100px;
  background-color: ${({ isSelected }) => (isSelected ? '#c3c3c3' : 'var(--color-greyEC)')};
  border-radius: var(--border-radius8);
`;
