import styled from 'styled-components';

export const BtnWrap = styled.div`
  display: grid;
  grid-template-columns: 250px 250px;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  & > div:first-child {
    justify-self: end;
    grid-column: 1/-1;
    min-width: 250px;
  }
`;
