import styled from 'styled-components';
import { motion } from 'framer-motion';

const getAnimate = ({ isOpen }) => ({
  animate: { height: isOpen ? 120 : 40 },
  transition: { duration: 0.3 },
  get initial() {
    return this.animate;
  },
});

export const MessageArea = styled(motion.textarea).attrs(getAnimate)`
  display: block;
  width: calc(100% - 20px);
  border: 1px solid var(--color-grey97);
  border-radius: var(--border-radius8);
  color: var(--color-grey6A);
  font-size: var(--font-size18);
  line-height: 1.22;
  letter-spacing: 0.5px;
  padding: 8px 12px;
  margin: 0 10px 2px 10px;
  resize: none;
  &::placeholder {
    color: var(--color-grey97);
  }
  &:focus {
    outline: 0;
    box-shadow: var(--shadow-focus);
  }
`;

export const Wrapper = styled.div`
  padding: 10px;
`;

export const P = styled.p`
  font-size: var(--font-size15);
  font-weight: var(--font-weight500);
  letter-spacing: 0.5px;
  text-align: center;
  color: ${({ isActive }) => (isActive ? 'var(--color-green65)' : 'var(--color-greyDC)')};
`;
