import React, { useCallback } from 'react';
import { useFormikContext, useField } from 'formik';
import { pipe, pathEq, not } from 'ramda';
import PropTypes from 'prop-types';

import { PROCEDURE, COMMENT, VALUE, PROCEDURE_STATUS } from 'store/diagnoses';
import dictionary from 'utils/dictionary.json';
import FormQuestion from 'components/FormQuestion';
import TextArea from 'components/TextAreaNew';

import Button from './Button';
import { Container } from './styles';

const TextAreaWithClues = ({ readMode }) => {
  const { setValues } = useFormikContext();
  const [{ value: comment }] = useField(`${PROCEDURE}.${COMMENT}`);
  const onChange = useCallback(
    (val) => {
      setValues(($) => ({
        ...$,
        [PROCEDURE]: {
          ...$[PROCEDURE],
          [COMMENT]: val,
          ...(pipe(pathEq(PROCEDURE_STATUS.UNDECIDED, [PROCEDURE, VALUE]), not)($) && { [VALUE]: PROCEDURE_STATUS.UNDECIDED }),
        },
      }));
    },
    [setValues]
  );

  return (
    <FormQuestion question="Was ist Ihre Empfehlung?">
      {readMode || (
        <Container>
          {dictionary.recommendationClues.map(({ id, title, text, procedureValue }) => (
            <Button
              key={id}
              title={title}
              text={text}
              procedureValue={procedureValue}
              width={100 / dictionary.recommendationClues.length}
            />
          ))}
        </Container>
      )}
      <TextArea value={comment} onChangeText={onChange} readMode={readMode} />
    </FormQuestion>
  );
};

TextAreaWithClues.propTypes = {
  readMode: PropTypes.bool.isRequired,
};

export default TextAreaWithClues;
