import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <defs>
      <rect id="a" width="40" height="40" rx="8" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g stroke="#979797" strokeWidth="1.5">
        <path d="M12 12l16 16M12 28l16-16" />
      </g>
    </g>
  </svg>
);
