export const FEATURE_NAME = 'analytics';

export const PARAMS = 'params';

// export const VA_LEFT = 'va_left';
// export const VA_RIGHT = 'va_right';
export const DIABETES_SHARE = 'diabetes_share';
export const AGE = 'age';
export const DEMENTIA_SHARE = 'dementia_share';
export const CATARACT_SHARE = 'cataract_share';
export const AMD_SHARE = 'amd_share';
export const PATIENT_AMOUNT = 'patient_size';
export const CANTON = 'canton';
export const NURSING_HOME = 'nursing_home';

export const YEAR = 'year';
export const DATA = 'data';
export const NAME = 'name';
export const GROUP = 'group';
export const PROMPT = 'prompt';
export const RESPONSE = 'response';

export const CANTONS = {
  AG: { name: 'Aargau', color: '#fff900' },
  AI: { name: 'Appenzell Innerrhoden', color: '#9828ff' },
  AR: { name: 'Appenzell Ausserrhoden', color: '#ff7500' },
  BE: { name: 'Bern', color: '#93ff75' },
  BL: { name: 'Basel-Landschaft', color: '#ff93e7' },
  BS: { name: 'Basel-Stadt', color: '#3574e3' },
  FR: { name: 'Freiburg', color: '#ff5228' },
  GE: { name: 'Genf', color: '#cdfeb1' },
  GL: { name: 'Glarus', color: '#ebebeb' },
  GR: { name: 'Graubünden', color: '#00e8e5' },
  JU: { name: 'Jura', color: '#d68dff' },
  LU: { name: 'Luzern', color: '#00ff93' },
  NE: { name: 'Neuenburg', color: '#9fe7ff' },
  NW: { name: 'Nidwalden', color: '#ff003f' },
  OW: { name: 'Obwalden', color: '#0011ff' },
  SG: { name: 'St. Gallen', color: '#cd2bea' },
  SH: { name: 'Schaffhausen', color: '#31ca4d' },
  SO: { name: 'Solothurn', color: '#ff9090' },
  SZ: { name: 'Schwyz', color: '#b6f2ca' },
  TG: { name: 'Thurgau', color: '#c00000' },
  TI: { name: 'Tessin', color: '#b8b3ff' },
  UR: { name: 'Uri', color: '#157633' },
  VD: { name: 'Waadt', color: '#ffd2ef' },
  VS: { name: 'Wallis', color: '#fbb834' },
  ZG: { name: 'Zug', color: '#baf200' },
  ZH: { name: 'Zürich', color: '#8bc2ff' },
};

export const AXIS_OPTIONS = [
  { id: DIABETES_SHARE, label: 'Diabetes' },
  // { id: VA_LEFT, label: 'Sehschärfe links' },
  // { id: VA_RIGHT, label: 'Sehschärfe rechts' },
  { id: AGE, label: 'Alter' },
  { id: DEMENTIA_SHARE, label: 'Demenz' },
  { id: CATARACT_SHARE, label: 'Katarakt' },
  { id: AMD_SHARE, label: 'AMD' },
];
export const Z_AXIS_OPTIONS = [{ id: PATIENT_AMOUNT, label: 'Anzahl Patienten' }];

export const LABELS = [...AXIS_OPTIONS, ...Z_AXIS_OPTIONS].reduce((acc, { id, label }) => ({ ...acc, [id]: label }), {
  [CANTON]: 'Kanton',
  [NURSING_HOME]: 'Pflegeheim',
});
