/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/FullScreenSpinner';
import Valid2FA from 'assets/svg-icons/Valid2FA';
import InValid2FA from 'assets/svg-icons/InValid2FA';

import { useValidate } from './hooks';
import { AMOUNT_FIELDS, INPUT_ID_PREFIX } from './consts';
import { Container, InputContainer, Input, IconContainer, Message } from './styles';

const MultiInputCode = ({ onChange, className, validateAction }) => {
  const containerRef = useRef();
  const [values, setValues] = useState(new Array(AMOUNT_FIELDS).fill(''));
  const { loading, isValid, ready, resetAlerts, error } = useValidate(values, onChange, validateAction);

  const setFocus = useCallback((index) => {
    const selector = `input#${INPUT_ID_PREFIX}${index >= AMOUNT_FIELDS - 1 ? AMOUNT_FIELDS - 1 : index}`;
    const input = containerRef.current.querySelector(selector);

    if (input) input.focus?.();
  }, []);

  const keydownHandler = useCallback(
    ({ currentTarget, keyCode }) => {
      if (keyCode !== 8) return;

      const { id, value } = currentTarget;
      const index = Number((id || '').slice(-1));

      if (index > 0 && !value) setFocus(index - 1);
    },
    [setFocus]
  );

  const handleOnFocus = useCallback(
    ({ currentTarget }) => {
      currentTarget.select();
      currentTarget.addEventListener('keydown', keydownHandler);
    },
    [keydownHandler]
  );
  const handleOnBlur = useCallback(
    ({ currentTarget }) => currentTarget.removeEventListener('keydown', keydownHandler),
    [keydownHandler]
  );

  const handleChange = useCallback(
    (e) => {
      if (loading) return;
      const { id, value } = e.target;
      const index = +id.replace(INPUT_ID_PREFIX, '');
      const str = value.replace(/\s/g, '');

      resetAlerts();

      if (str.length === 0) {
        setValues([...values.slice(0, index), '', ...values.slice(index + 1)]);
      }
      if (str.length === 1) {
        setValues([...values.slice(0, index), str, ...values.slice(index + 1)]);
        setFocus(index + 1);
      }
      if (str.length === 2 && values[index].length === 1) {
        setValues([...values.slice(0, index), str[1], ...values.slice(index + 1)]);
        setFocus(index + 1);
        return;
      }

      if (str.length > 1) {
        setValues([...values.slice(0, index), ...str.split(''), ...values.slice(index + str.length)].slice(0, AMOUNT_FIELDS));
        setFocus(index + str.length);
      }
    },
    [loading, resetAlerts, setFocus, values]
  );

  useEffect(() => setFocus(0), [setFocus]);

  return (
    <Container ref={containerRef} className={className}>
      <InputContainer>
        {values.map((item, index) => (
          <Input
            key={index}
            id={`${INPUT_ID_PREFIX}${index}`}
            value={item}
            onChange={handleChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            error={ready && !isValid}
            isLoading={loading}
          />
        ))}
      </InputContainer>
      <IconContainer>
        {loading && <Spinner size={26} />}
        {ready && isValid && <Valid2FA />}
        {ready && !isValid && <InValid2FA />}
      </IconContainer>
      {error && <Message type="error">{error}</Message>}
    </Container>
  );
};

MultiInputCode.defaultProps = {
  onChange: () => null,
  className: '',
};

MultiInputCode.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  validateAction: PropTypes.func.isRequired,
};

export default MultiInputCode;
