import React, { useMemo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { map, applySpec, prop, pipe, props, filter, join } from 'ramda';

import { usePlanningActions, useAlerts } from 'store/planning';
import { ID, PUBLIC_ID, LAST_NAME, FIRST_NAME, BIRTH, STATUS } from 'store/patients';
import { parseBirthday } from 'utils';
import Accordion from 'components/Accordion';
import Table from 'components/Table';
import Spinner from 'components/FullScreenSpinner';

import { DEFAULT_SORT, TABLE_HEAD } from './utils';
import Row from './Row';

const UnsubscribeTable = () => {
  const { id } = useParams();
  const { fetchScheduleUnsubscribers } = usePlanningActions();
  const { id: actionId, action, loading, success } = useAlerts(fetchScheduleUnsubscribers);
  const list = useMemo(
    () =>
      map(
        applySpec({
          id: prop(ID),
          publicId: prop(PUBLIC_ID),
          name: pipe(props([FIRST_NAME, LAST_NAME]), filter(Boolean), join(' ')),
          birth: pipe(prop(BIRTH), parseBirthday),
          status: prop(STATUS),
        })
      )(success || []),
    [success]
  );

  const renderRow = useCallback(($) => <Row {...$} />, []);

  useEffect(() => {
    if (!actionId) action(id);
  }, [action, actionId, id]);

  if (loading) return <Spinner height="50px" />;
  if (!list.length) return null;

  return (
    <Accordion label="Abgesagte Messungen" defaultOpen>
      <Table head={TABLE_HEAD} list={list} renderRow={renderRow} sort={DEFAULT_SORT} />
    </Accordion>
  );
};

export default UnsubscribeTable;
