import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useInfoItemsActions, useAlerts, MESSAGE, ATTACHMENTS, FILE, TAGS, SUB_PATIENT, PATH } from 'store/infoItems';
import Spinner from 'components/FullScreenSpinner';
import Button from 'components/Button';
import Message from 'components/Message';
import AnimateBox from 'components/AnimateBox';
import PlusIcon from 'assets/svg-icons/PlusDropZone';
import FileForm from '../FileForm';
import { DropZone } from '../styles';
import { useAllAttachments } from '../hooks';

import { MessageArea, Wrapper, P } from './styles';

const initialValues = {
  [MESSAGE]: '',
  [ATTACHMENTS]: [],
};

const Creator = ({ isOpenMenu }) => {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const { uploadMedia, createInfoItem } = useInfoItemsActions();
  const uploadAlerts = useAlerts();
  const createAlerts = useAlerts();

  const onSubmit = useCallback(
    (data) => {
      createAlerts.extractId(
        createInfoItem({
          [SUB_PATIENT]: id,
          [MESSAGE]: data[MESSAGE],
          [ATTACHMENTS]: data[ATTACHMENTS].map((item) => ({
            [FILE]: item[FILE] || {},
            [TAGS]: item[TAGS] || [],
          })),
        })
      );
    },
    [createAlerts, createInfoItem, id]
  );

  const { values, setFieldValue, handleSubmit, getFieldProps, resetForm } = useFormik({
    initialValues,
    onSubmit,
  });

  const onFocus = useCallback(() => setIsOpen(true), []);
  const onDrop = useCallback((data) => uploadAlerts.extractId(uploadMedia(data)), [uploadAlerts, uploadMedia]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const updateFileData = useCallback(
    (index, fileData) => {
      setFieldValue(
        ATTACHMENTS,
        values[ATTACHMENTS].map((item, i) => (i === index ? { ...item, [FILE]: fileData } : item))
      );
    },
    [setFieldValue, values]
  );

  const updateTags = useCallback(
    (index, tags) => {
      setFieldValue(
        ATTACHMENTS,
        values[ATTACHMENTS].map((item, i) => (i === index ? { ...item, [TAGS]: tags } : item))
      );
    },
    [setFieldValue, values]
  );

  const deleteFile = useCallback(
    (index) => {
      setFieldValue(
        ATTACHMENTS,
        values[ATTACHMENTS].filter((_, i) => i !== index)
      );
    },
    [setFieldValue, values]
  );

  const attachments = useAllAttachments(values[ATTACHMENTS]);

  useEffect(() => {
    if (uploadAlerts.success) {
      setFieldValue(ATTACHMENTS, [...values[ATTACHMENTS], ...uploadAlerts.success.map((file) => ({ [FILE]: file }))]);
      uploadAlerts.resetAlerts();
    }
  }, [setFieldValue, uploadAlerts, values]);

  useEffect(() => {
    if (createAlerts.success) {
      resetForm({ values: initialValues });
      setIsOpen(false);
      createAlerts.resetAlerts();
    }
  }, [resetForm, createAlerts]);

  useEffect(() => {
    if (!isOpenMenu && isOpen) {
      setIsOpen(false);
    }
  }, [isOpenMenu, isOpen]);

  return (
    <form onSubmit={handleSubmit}>
      <MessageArea name={MESSAGE} isOpen={isOpen} {...getFieldProps(MESSAGE)} onFocus={onFocus} placeholder="Update erfassen" />
      <AnimateBox isOpen={isOpen}>
        <Wrapper>
          <DropZone isActive={isDragActive} {...getRootProps()}>
            {uploadAlerts.loading ? (
              <Spinner />
            ) : (
              <>
                <input {...getInputProps()} />
                <PlusIcon />
                <P isActive={isDragActive}>Dateien in dieses</P>
                <P isActive={isDragActive}>Feld ziehen</P>
              </>
            )}
          </DropZone>
          {values[ATTACHMENTS].map((item, index) => (
            <FileForm
              key={item[FILE][PATH]}
              index={index}
              fileData={item[FILE]}
              tags={item[TAGS]}
              updateFileData={updateFileData}
              updateTags={updateTags}
              deleteFile={deleteFile}
              attachments={attachments}
            />
          ))}
          <Button type="submit" color="success" height="48px" mt={12} disabled={createAlerts.loading || uploadAlerts.loading}>
            Eintrag erstellen
          </Button>
          {(createAlerts.error || uploadAlerts.error) && (
            <Message type="error">{createAlerts.error || uploadAlerts.error}</Message>
          )}
        </Wrapper>
      </AnimateBox>
    </form>
  );
};

Creator.propTypes = {
  isOpenMenu: PropTypes.bool.isRequired,
};

export default Creator;
