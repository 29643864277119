import styled from 'styled-components';
import { motion } from 'framer-motion';

const getAnimate = ({ isOpen }) => ({
  animate: { x: isOpen ? 0 : 450 },
  transition: { type: 'spring', damping: 40, stiffness: 300 },
  get initial() {
    return this.animate;
  },
});

export const Container = styled(motion.div).attrs(getAnimate)`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 450px;
  height: 1px;
  min-height: 100%;
  z-index: 100;
  background-color: var(--color-white);
  border-left: 1px solid var(--color-grey97);
  opacity: 0.95;
  overflow: auto;
`;

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`;

export const CreatorWrap = styled.div`
  padding: 0 20px 20px;
  border-bottom: 1px solid var(--color-grey97);
`;

export const Button = styled.button`
  width: 40px;
  height: 40px;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: var(--border-radius8);
  &:hover,
  &:focus {
    box-shadow: var(--shadow-focus);
  }
`;

export const Title = styled.p`
  font-size: var(--font-size18);
  color: var(--color-grey6A);
  font-weight: var(--font-weight500);
  margin-left: 12px;
  padding-bottom: 2px;
`;

export const ContentWrap = styled.div`
  width: 100%;
`;

export const LoadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
`;

export const DropZone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height || '120px'};
  margin-bottom: ${({ mb }) => mb || '0'};
  border-radius: var(--border-radius8);
  cursor: pointer;
  border: 1px solid ${({ isActive }) => (isActive ? 'var(--color-green65)' : 'var(--color-greyDC)')};
  background-color: var(--color-greyF8);
  & g {
    color: ${({ isActive }) => (isActive ? 'var(--color-green65)' : 'var(--color-grey97)')};
  }
  &:focus {
    outline: 0;
    box-shadow: var(--shadow-focus);
  }
`;
