import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  max-width: 500px;
  min-height: 500px;
  height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
  padding-bottom: 20px;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  padding: 10px 10px 10px 0;
  overflow: auto;
`;

export const SubmitWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  border: 1px solid var(--color-grey97);
  border-radius: var(--border-radius8);
  padding: 0 5px 0 10px;
`;

export const Input = styled.input`
  flex: 1 1 auto;
  height: 40px;
  border: none;
  background-color: var(--color-white);
  font-size: var(--font-size18);
  font-weight: var(--font-weight400);
  color: var(--color-grey6A);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  &::placeholder {
    color: var(--color-grey97);
  }
  &:disabled {
    opacity: 1;
    cursor: not-allowed;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  min-width: 30px;
  aspect-ratio: 1/1;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyEC);
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s;
  &:active {
    background-color: #bdbcbc;
  }
  &:disabled {
    opacity: 1;
    background-color: #eeeeee;
    cursor: not-allowed;
  }
  @media (hover) {
    &:hover:enabled {
      background-color: var(--color-greyF8);
    }
  }
`;
