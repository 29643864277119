import React, { useMemo, useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { pathOr, path, prop } from 'ramda';
import PropTypes from 'prop-types';

import Accordion from 'components/Accordion';
import FormContainer from 'components/FormContainer';
import Spinner from 'components/FullScreenSpinner';
import {
  ID,
  AGENT,
  OTHER_CONTACTS,
  FAMILY_DOCTOR,
  EYE_DOCTOR,
  NURSING_HOME,
  OPTICIAN,
  INSURANCE,
  SSN,
  usePatientsActions,
  useAlerts,
  usePatient,
  SUB_COMPANY,
  CORRESPONDENCE,
  GUARANTY,
} from 'store/patients';
import { PATIENT, usePermissions } from 'permissions';

import { getInitValues, extractOrgData } from './utils';
import PatientForm from './PatientForm';
import NursingHomeForm from './NursingHome';
import InsuranceForm from './Insurance';
import PatientReport from './PatientReport';
import ContactForm from './Contact';
import Contacts from './Contacts';
import Optician from './Optician';
import Clinic from './Clinic';
import { Title, Confirm } from './styles';

const agentFeature = [AGENT];

const PersonalInformationForm = ({ readMode }) => {
  const { id } = useParams();
  const { push, location } = useHistory();
  const can = usePermissions();
  const patient = usePatient(id);
  const initialValues = useMemo(() => getInitValues(patient), [patient]);
  const { removePatient, savePatient } = usePatientsActions();
  const { action: remove, loading: removeLoading, success: removeSuccess, resetAlerts: removeReset } = useAlerts(removePatient);
  const { action: save, errors: saveErrors } = useAlerts(savePatient);

  const sendData = useCallback(
    (values) => {
      save({
        [ID]: id,
        ...values,
        [NURSING_HOME]: extractOrgData([CORRESPONDENCE], values[NURSING_HOME]),
        [OPTICIAN]: extractOrgData([CORRESPONDENCE, GUARANTY], values[OPTICIAN]),
        [FAMILY_DOCTOR]: extractOrgData([CORRESPONDENCE], values[FAMILY_DOCTOR]),
        [EYE_DOCTOR]: extractOrgData([CORRESPONDENCE], values[EYE_DOCTOR]),
        [INSURANCE]: {
          ...values[INSURANCE],
          [SUB_COMPANY]: pathOr(pathOr(null, [INSURANCE, SUB_COMPANY], values), [INSURANCE, SUB_COMPANY, ID], values),
        },
      });
    },
    [id, save]
  );

  const handleDeletePatient = useCallback(() => {
    if (id) {
      remove(id);
    }
  }, [id, remove]);

  useEffect(() => {
    if (removeSuccess) {
      push(pathOr('/patients', ['state', 'previousPath'], location));
      removeReset();
    }
  }, [location, location.state, push, removeReset, removeSuccess]);

  if (removeLoading) return <Spinner />;

  return (
    <Formik initialValues={initialValues} validate={sendData} validateOnBlur={false}>
      <FormContainer>
        <Accordion label="Persönliche Daten" defaultOpen>
          <PatientForm readMode={readMode} errors={saveErrors} />
        </Accordion>
        <Accordion label="Versicherung">
          <InsuranceForm readMode={readMode} error={path([INSURANCE, SSN], saveErrors || null)} />
        </Accordion>
        <Accordion label="Bericht">
          <PatientReport readMode={readMode} />
        </Accordion>
        <Accordion label="Pflegezentrum">
          <NursingHomeForm readMode={readMode} />
        </Accordion>
        <Accordion label="Optiker">
          <Optician readMode={readMode} />
        </Accordion>
        <Accordion label="Gesetzlicher Vertreter">
          <ContactForm
            feature={agentFeature}
            errors={prop(AGENT, saveErrors || null)}
            correspondenceLabel="Patient hat einen gesetzlichen Vertreter"
            readMode={readMode}
          />
        </Accordion>
        <Accordion label="Kontaktpersonen">
          <Contacts errors={prop(OTHER_CONTACTS, saveErrors || null)} readMode={readMode} />
        </Accordion>
        <Accordion label="Hausarzt/-ärztin">
          <Clinic feature={FAMILY_DOCTOR} readMode={readMode} />
        </Accordion>
        <Accordion label="Augenarzt/-ärztin">
          <Clinic feature={EYE_DOCTOR} readMode={readMode} />
        </Accordion>
        {can.delete(PATIENT) && !readMode && (
          <>
            <Title>Patientenakte löschen</Title>
            <Confirm title="Patient löschen" onClick={handleDeletePatient} />
          </>
        )}
      </FormContainer>
    </Formik>
  );
};

PersonalInformationForm.propTypes = {
  readMode: PropTypes.bool.isRequired,
};

export default PersonalInformationForm;
