import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;
  column-gap: 40px;
  width: 100%;
`;

export const Label = styled.p`
  font-size: var(--font-size15);
  color: var(--color-grey6A);
  padding-bottom: 10px;
`;

export const Text = styled.p`
  width: fit-content;
  font-size: var(--font-size15);
  font-weight: var(--font-weight400);
  color: var(--color-grey97);
  margin: 0 25px 5px 0;
`;
