import { path } from 'ramda';

import { ID } from 'api/consts';
import { FEATURE_NAME as $, AUTH, AUTH_2F, HAS_2F, LOGGED_IN, LINK_2FA, USER, PERMISSIONS } from './consts';

export const getAuth = path([$, AUTH]);
export const getAuth2FA = path([$, AUTH_2F]);
export const getHas2FA = path([$, HAS_2F]);
export const getLoggedIn = path([$, LOGGED_IN]);
export const get2FALink = path([$, LINK_2FA]);
export const getUser = path([$, USER]);
export const getUserProp = (name) => path([$, USER, name]);
export const getUserId = path([$, USER, ID]);
export const getPermissionsObject = path([$, PERMISSIONS]);
