import { prop, omit } from 'ramda';
import qs from 'query-string';

import { wrapperRequest } from './utils';
import { API_URL, POST, PATCH, DELETE, OWN_ERR_HANDLER } from './consts';

export const postMedicalHistory = (body = {}) =>
  wrapperRequest({
    url: `${API_URL}/medicalhistories`,
    options: { method: POST, body },
  });

export const getMedicalHistory = (query = {}) =>
  wrapperRequest({
    url: `${API_URL}/medicalhistories?${qs.stringify(query)}`,
  });

export const patchMedicalHistory = (body = {}, query = {}) =>
  wrapperRequest({
    url: `${API_URL}/medicalhistories/${prop('medicalHistoryId', body)}?${qs.stringify(query)}`,
    options: { method: PATCH, body },
  });

export const getSearchableMedia = (id, body = {}) =>
  wrapperRequest({
    url: `${API_URL}/searchablemedia/${id}/search`,
    options: { method: POST, body, [OWN_ERR_HANDLER]: true },
  });

export const patchSearchableMedia = (body = {}) =>
  wrapperRequest({
    url: `${API_URL}/searchablemedia/${body.id}`,
    options: { method: PATCH, body: omit(['id'], body) },
  });

export const deleteSearchableMedia = (id) =>
  wrapperRequest({
    url: `${API_URL}/searchablemedia/${id}`,
    options: { method: DELETE },
  });
