import React from 'react';
import PropTypes from 'prop-types';

import AddButtonIcon from 'assets/svg-icons/AddButtonIcon';

import { ClickableCircle, IconRotation } from './styles';

const Button = ({ onClick, disabled, size, asClose, transparent }) => (
  <ClickableCircle transparent={transparent} size={size} onClick={disabled ? null : onClick} disabled={disabled}>
    <IconRotation rotated={asClose}>
      <AddButtonIcon />
    </IconRotation>
  </ClickableCircle>
);

Button.defaultProps = {
  disabled: false,
  size: 40,
  onClick: () => {},
  asClose: false,
  transparent: false,
};

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.number,
  asClose: PropTypes.bool,
  transparent: PropTypes.bool,
};

export default Button;
