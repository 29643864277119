import React, { useState, useMemo, useCallback, Fragment } from 'react';
import { times } from 'ramda';
import PropTypes from 'prop-types';

import { ID, NAME, SUB_ORGANIZATION, ORGANIZATION_KINDS, useCollectOrganizationsAction } from 'store/organizations';
import { DATE, DAYS, SHIFTS, REGISTRATION_DATE } from 'store/planning';
import SelectedOrganization from 'components/SelectedOrganization';
import { DAYS_OPTIONS, TIME_OPTIONS } from '../../consts';
import DateInput from '../../DateInput';
import { getRegistrationDate } from '../utils';

import { PARAMS, getHoursAndMinutes, resetShifts, getDateFromShifts } from './utils';
import { Container, Label, InputWrap, Dropdown, Select, Wrapper, Text, Message } from './styles';

const Form = ({ values, setValues, errors, setDateError, setRegistrationDateError, disabled }) => {
  const getOrganizations = useCollectOrganizationsAction(ORGANIZATION_KINDS.NURSING_HOME, PARAMS);
  const date = values[DATE];
  const days = values[DAYS];
  const shifts = values[SHIFTS];
  const registrationDate = values[REGISTRATION_DATE];
  const [index, setIndex] = useState(0);
  const updateIndex = useCallback((val) => setIndex(Number(val)), []);
  const indexes = useMemo(() => times((i) => ({ id: i, label: `${i + 1}. Tag` }), shifts.length), [shifts]);
  const [time1, time2] = useMemo(() => shifts[index].map(getHoursAndMinutes), [index, shifts]);

  const updateOrganization = useCallback((org) => setValues(($) => ({ ...$, [SUB_ORGANIZATION]: org })), [setValues]);
  const updateDays = useCallback(
    (val) => {
      const value = Number(val);
      const items = resetShifts(date, shifts, value);

      setValues(($) => ({
        ...$,
        [DATE]: getDateFromShifts(items),
        [DAYS]: value,
        [SHIFTS]: items,
        [REGISTRATION_DATE]: getRegistrationDate(items),
      }));
    },
    [date, setValues, shifts]
  );
  const updateDayDate = useCallback(
    (val) => {
      const items = index === 0 ? resetShifts(val, shifts, shifts.length) : [...shifts];

      if (index !== 0) {
        items[index] = shifts[index].map((v) => {
          const current = new Date(val);
          const d = new Date(v);

          current.setHours(d.getHours());
          current.setMinutes(d.getMinutes());

          return current.toISOString();
        });
      }

      setValues(($) => ({
        ...$,
        [DATE]: getDateFromShifts(items),
        [SHIFTS]: items,
        [REGISTRATION_DATE]: getRegistrationDate(items),
      }));
    },
    [index, setValues, shifts]
  );
  const updateRegistrationDate = useCallback((val) => setValues(($) => ({ ...$, [REGISTRATION_DATE]: val })), [setValues]);
  const updateTimeStart = useCallback(
    (time) => {
      const items = [...shifts];
      const [h, m] = time.split(':').map(Number);
      const d = new Date(items[index][0]);

      d.setHours(h);
      d.setMinutes(m);
      items[index][0] = d.toISOString();

      setValues(($) => ({
        ...$,
        [DATE]: getDateFromShifts(items),
        [SHIFTS]: items,
        [REGISTRATION_DATE]: getRegistrationDate(items),
      }));
    },
    [index, setValues, shifts]
  );
  const updateTimeEnd = useCallback(
    (time) => {
      const items = [...shifts];
      const [h, m] = time.split(':').map(Number);
      const d = new Date(items[index][1]);

      d.setHours(h);
      d.setMinutes(m);
      items[index][1] = d.toISOString();

      setValues(($) => ({
        ...$,
        [DATE]: getDateFromShifts(items),
        [SHIFTS]: items,
        [REGISTRATION_DATE]: getRegistrationDate(items),
      }));
    },
    [index, setValues, shifts]
  );

  return (
    <Container>
      <Label>Pflegeheim</Label>
      <InputWrap $error={errors[SUB_ORGANIZATION]} $fullWidth>
        <Dropdown
          idKey={ID}
          nameKey={NAME}
          action={getOrganizations}
          placeholder="Pflegeheim suchen"
          value={values[SUB_ORGANIZATION]}
          onChange={updateOrganization}
          selectedComponent={SelectedOrganization}
          disabled={disabled}
          onlyOne
        />
      </InputWrap>
      <Label $isSmall>Messtage</Label>
      <InputWrap $error={errors[DAYS]}>
        <Select value={days || ''} options={DAYS_OPTIONS} onSelect={updateDays} disabled={disabled} />
      </InputWrap>
      <div />
      <InputWrap>
        <Select value={index} options={indexes} onSelect={updateIndex} disabled={disabled} />
        {Boolean(errors[SHIFTS]) && (
          <Message>Die Messtage dürfen nicht in der Vergangenheit liegen. Bitte prüfen Sie die Daten.</Message>
        )}
      </InputWrap>
      {shifts[index] && (
        <Fragment key={`${index}-${date}-${days}`}>
          <Label>Datum</Label>
          <InputWrap>
            <DateInput value={shifts[index][0]} onChange={updateDayDate} setValidationError={setDateError} disabled={disabled} />
          </InputWrap>
          <Label>Uhrzeit</Label>
          <InputWrap>
            <Wrapper>
              <Select value={time1} options={TIME_OPTIONS} onSelect={updateTimeStart} disabled={disabled} />
              <Text>bis</Text>
              <Select value={time2} options={TIME_OPTIONS} onSelect={updateTimeEnd} disabled={disabled} />
            </Wrapper>
          </InputWrap>
          <Label>Anmeldeschluss</Label>
          <InputWrap>
            <DateInput
              value={registrationDate}
              onChange={updateRegistrationDate}
              setValidationError={setRegistrationDateError}
              disabled={disabled}
              allowAllDays
            />
            {errors[REGISTRATION_DATE] && <Message>{errors[REGISTRATION_DATE]}</Message>}
          </InputWrap>
        </Fragment>
      )}
    </Container>
  );
};

Form.defaultProps = { disabled: false };
Form.propTypes = {
  values: PropTypes.shape({
    [SUB_ORGANIZATION]: PropTypes.shape({
      [ID]: PropTypes.string.isRequired,
    }),
    [DATE]: PropTypes.string.isRequired,
    [DAYS]: PropTypes.number.isRequired,
    [SHIFTS]: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string.isRequired).isRequired).isRequired,
    [REGISTRATION_DATE]: PropTypes.string.isRequired,
  }).isRequired,
  setValues: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string.isRequired))])
  ).isRequired,
  setDateError: PropTypes.func.isRequired,
  setRegistrationDateError: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Form;
