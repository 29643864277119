import React, { useMemo, useCallback, useEffect } from 'react';
import { when, complement, is } from 'ramda';
import PropTypes from 'prop-types';

import { useAsyncState } from 'utils/useAsyncState';
import api from 'api';
import { parseDate } from 'utils';

import { getOriginName } from './utils';
import { Container, Wrapper, Button, Label } from './styles';

const Uploading = ({ fileData }) => {
  const { destination, path, previewname: previewName, originalname: originalName, description, mimetype, form } = fileData;
  const [imageUrl, setImageUrl] = useAsyncState('');
  const label = useMemo(() => {
    const { lastname, birthday } = form?.patient || {};

    if (!(lastname && birthday)) return when(complement(is(String)), () => getOriginName(originalName))(description);

    return [lastname, parseDate(birthday)].filter(Boolean).join(' ');
  }, [description, form?.patient, originalName]);
  const onClick = useCallback(async () => {
    const blobUrl = await api.loadFile({ url: path, mimetype });

    window.open(blobUrl, '_blank');
  }, [mimetype, path]);
  const getImageUrl = useCallback(async () => {
    const blobUrl = await api.loadFile({ url: `${destination}/${previewName}`, mimetype });

    setImageUrl((prev) => {
      if (prev) (window.URL || window.webkitURL).revokeObjectURL(prev);

      return blobUrl;
    });
  }, [destination, mimetype, previewName, setImageUrl]);

  useEffect(() => {
    getImageUrl();
  }, [getImageUrl]);

  useEffect(
    () => () => {
      if (imageUrl) (window.URL || window.webkitURL).revokeObjectURL(imageUrl);
    },
    [imageUrl]
  );

  return (
    <Container>
      <Wrapper key={fileData.path}>
        <Button type="button" src={imageUrl} onClick={onClick} disabled={!imageUrl} />
        {label && <Label>{label}</Label>}
      </Wrapper>
    </Container>
  );
};

Uploading.defaultProps = { fileData: null };
Uploading.propTypes = {
  fileData: PropTypes.shape({
    destination: PropTypes.string,
    path: PropTypes.string.isRequired,
    previewname: PropTypes.string.isRequired,
    originalname: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    mimetype: PropTypes.string.isRequired,
    form: PropTypes.shape({
      patient: PropTypes.shape({
        lastname: PropTypes.string,
        birthday: PropTypes.string,
      }).isRequired,
    }),
  }),
};

export default Uploading;
