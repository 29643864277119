import { map, join, filter, pipe, props } from 'ramda';

import { NAME, SPECIALTY, DOCTOR, OPHTHALMOLOGY, CONTACTS, ZIP_CODE, CITY, CANTON, ADDRESS } from 'store/organizations';
import { FAMILY_DOCTOR, EYE_DOCTOR, FIELDS } from 'store/patients';

const NAME_KEY = 'fullName';

const handleClinicName = (items = []) =>
  map((item = {}) => ({
    ...item,
    [NAME_KEY]: pipe(
      ($) => [$[NAME], pipe(props([ZIP_CODE, CITY, CANTON]), filter(Boolean), join(' '))($)],
      filter(Boolean),
      join(', ')
    )(item),
  }))(items);

const getParams = (specialty) => ({
  [FIELDS]: [NAME, SPECIALTY, CONTACTS, ZIP_CODE, CITY, CANTON, ADDRESS],
  [SPECIALTY]: specialty,
});

export const featureMap = {
  [FAMILY_DOCTOR]: [NAME_KEY, getParams(DOCTOR), handleClinicName, 'Hausarzt/ -ärztin suchen', DOCTOR],
  [EYE_DOCTOR]: [NAME_KEY, getParams(OPHTHALMOLOGY), handleClinicName, 'Augenarzt/-ärztin suchen', OPHTHALMOLOGY],
};
