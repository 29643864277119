import React from 'react';
import PropTypes from 'prop-types';

const EyeIcon = ({ selected }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="251" height="136" viewBox="0 0 358 195">
    <g fill="none" stroke="none" transform="translate(1 1)">
      <path
        fill={selected ? 'rgba(80, 227, 194, 0.27)' : 'transparent'}
        stroke={selected ? 'var(--color-blue50)' : '#858585'}
        d="M0 97C124.86-32.33 243.53-32.33 356 97m0-1C231.14 225.33 112.47 225.33 0 96"
      />
      <circle
        fill={selected ? '#fff' : 'transparent'}
        stroke={selected ? 'var(--color-blue50)' : '#858585'}
        cx="179.5"
        cy="96.5"
        r="96.5"
      />
      <circle
        fill={selected ? '#fff' : 'transparent'}
        stroke={selected ? 'var(--color-blue50)' : '#858585'}
        cx="180"
        cy="97"
        r="58"
      />
    </g>
  </svg>
);

EyeIcon.defaultProps = {
  selected: false,
};

EyeIcon.propTypes = {
  selected: PropTypes.node,
};

export default EyeIcon;
