import styled from 'styled-components';

export const Container = styled.form`
  width: 90%;
  max-width: 1024px;
  position: relative;
  margin: 30px auto 50px auto;
`;

export const Checkboxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  &:after {
    content: ${({ $error }) => (typeof $error === 'string' ? `'${$error}'` : 'none')};
    grid-column: 1 / -1;
    color: var(--color-orangeDC);
    font-size: var(--font-size13);
    word-break: break-word;
  }
`;

export const SpinnerWrap = styled.div`
  position: absolute;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
  cursor: not-allowed;
`;
