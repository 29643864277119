import React, { useState, useCallback, useEffect } from 'react';

import { useBugReportsActions, useAlerts, STATUS, ROLE, STATUSES, ROLE_FILTERS } from 'store/bugReports';
import { ID, FIRST_NAME, LAST_NAME, LIMIT, EMAIL } from 'store/session';
import { useAsyncState } from 'utils/useAsyncState';
import AddButtonIcon from 'assets/svg-icons/AddButtonIcon';
import Spinner from 'components/FullScreenSpinner';
import Message from 'components/Message';
import Select from 'components/SelectNew';

import { NavLink, LinkLabel, Wrapper, Item, Text, EmptyMessage } from './styles';

const Users = () => {
  const [users, setUsers] = useAsyncState(null);
  const [status, setStatus] = useState('1');
  const [role, setRole] = useState('null');
  const { fetchUsers } = useBugReportsActions();
  const { action, loading, success, error } = useAlerts(fetchUsers);

  const getName = useCallback(
    ({ [FIRST_NAME]: firstName, [LAST_NAME]: lastName }) => [firstName, lastName].filter(Boolean).join(' '),
    []
  );

  useEffect(() => {
    action({
      [LIMIT]: 50,
      ...(status && status !== 'null' && { [STATUS]: status }),
      ...(role && role !== 'null' && { [ROLE]: role }),
    });
  }, [action, role, status]);

  useEffect(() => {
    if (success) setUsers(success);
  }, [setUsers, success]);

  if (!users && loading) return <Spinner height="100px" />;
  if (!users && error)
    return (
      <Message type="error" form={1}>
        {error}
      </Message>
    );

  return (
    <>
      <NavLink to="/users/new">
        <LinkLabel>Neuer Nutzer</LinkLabel>
        <AddButtonIcon />
      </NavLink>
      <Select value={status} options={STATUSES} label="Status" disabled={loading} onSelect={setStatus} />
      <Select value={role} options={ROLE_FILTERS} label="Nutzerrolle" disabled={loading} onSelect={setRole} />
      {error && <Message type="error">{error}</Message>}
      <Wrapper>
        {users && users.length === 0 && <EmptyMessage>Keine Nutzer gefunden</EmptyMessage>}
        {users &&
          users.map((user) => (
            <Item key={user[ID]} to={`/users/${user[ID]}`}>
              <Text>{getName(user)}</Text>
              <Text>{user[EMAIL]}</Text>
            </Item>
          ))}
      </Wrapper>
    </>
  );
};

export default Users;
