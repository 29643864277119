import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_INFO_ITEMS,
  UPDATE_INFO_ITEM,
  DELETE_INFO_ITEM,
  RESET_INFO_ITEMS,
  UPDATE_SORT,
  UPDATE_PAGINATION,
  UPLOAD_MEDIA,
  FETCH_INFO_ITEMS,
  FETCH_INFO_ITEM,
  CREATE_INFO_ITEM,
  SAVE_INFO_ITEM,
  REMOVE_INFO_ITEM,
} from './types';

export const updateInfoItems = createAction(UPDATE_INFO_ITEMS);
export const updateInfoItem = createAction(UPDATE_INFO_ITEM);
export const deleteInfoItem = createAction(DELETE_INFO_ITEM);
export const resetInfoItems = createAction(RESET_INFO_ITEMS);
export const updateSort = createAction(UPDATE_SORT);
export const updatePagination = createAction(UPDATE_PAGINATION);

export const fetchInfoItems = createAlertAction(FETCH_INFO_ITEMS);
export const fetchInfoItem = createAlertAction(FETCH_INFO_ITEM);
export const createInfoItem = createAlertAction(CREATE_INFO_ITEM);
export const saveInfoItem = createAlertAction(SAVE_INFO_ITEM);
export const removeInfoItem = createAlertAction(REMOVE_INFO_ITEM);
export const uploadMedia = createAlertAction(UPLOAD_MEDIA);
