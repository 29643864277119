import React, { useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { all, propEq, keys, omit } from 'ramda';
import PropTypes from 'prop-types';

import {
  useCurrentSchedule,
  useScheduleUpdates,
  usePlanningActions,
  ID,
  REGISTRATIONS,
  CONFIDENCE,
  STATUS,
  RESCHEDULE,
} from 'store/planning';
import CheckboxComponent from 'components/CheckboxNew';

const Checkbox = ({ disabled, readMode, isAllRegistrationBlocked }) => {
  const { id } = useParams();
  const registrations = useCurrentSchedule(REGISTRATIONS);
  const updates = useScheduleUpdates(id, REGISTRATIONS);
  const isReschedule = useScheduleUpdates(id, RESCHEDULE);
  const { setScheduleUpdates } = usePlanningActions();

  const unrecognizedIds = useMemo(
    () =>
      (registrations || []).reduce((acc, { [ID]: patientId, [STATUS]: status, [CONFIDENCE]: confidence }) => {
        if (status === 0 || (status === 1 && confidence < 1)) acc.push(patientId);

        return acc;
      }, []),
    [registrations]
  );
  const isNewSelected = useMemo(() => all((key) => propEq(3, key, updates || {}), unrecognizedIds), [unrecognizedIds, updates]);
  const onChangeNewest = useCallback(
    (checked) => {
      setScheduleUpdates({
        [ID]: id,
        [REGISTRATIONS]: checked
          ? {
              ...updates,
              ...unrecognizedIds.reduce((acc, key) => {
                acc[key] = 3;

                return acc;
              }, {}),
            }
          : keys(updates).reduce(
              (acc, key) => (acc[key] === 3 && unrecognizedIds.includes(key) ? omit([key], acc) : acc),
              updates
            ),
      });
    },
    [id, setScheduleUpdates, unrecognizedIds, updates]
  );
  const onChangeReschedule = useCallback(
    (checked) => setScheduleUpdates({ [ID]: id, [RESCHEDULE]: checked }),
    [id, setScheduleUpdates]
  );

  if (!registrations?.length) return null;

  return (
    <div>
      {unrecognizedIds.length > 0 && (
        <CheckboxComponent
          selected={isNewSelected}
          label="Nicht gefundene Patienten neu erstellen"
          onClick={onChangeNewest}
          mr={0}
          disabled={disabled || (readMode && isAllRegistrationBlocked)}
        />
      )}
      <CheckboxComponent
        selected={isReschedule}
        label="Alle Patienten neu planen"
        onClick={onChangeReschedule}
        mr={0}
        mb={0}
        disabled={disabled || readMode}
      />
    </div>
  );
};

Checkbox.defaultProps = { disabled: false };
Checkbox.propTypes = {
  disabled: PropTypes.bool,
  readMode: PropTypes.bool.isRequired,
  isAllRegistrationBlocked: PropTypes.bool.isRequired,
};

export default Checkbox;
