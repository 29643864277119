import React, { useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';

import TextArea from 'components/TextAreaNew';
import { ID, SUB_USER, TITLE, FIRST_NAME, LAST_NAME } from 'store/session';

import { handleItems } from './utils';
import { Wrapper, Text, Status, Notify } from './styles';

const ApprovalsPanel = ({ withMessage, approvals, message, setMessage }) => {
  const items = useMemo(() => handleItems(approvals), [approvals]);

  return (
    <>
      {items.map(({ id, date, time, name, status, color, notify }) => (
        <Fragment key={id}>
          <Wrapper>
            <Text>{date}</Text>
            <Text>{time}</Text>
            <Text>{name}</Text>
            <Status color={color}>{status}</Status>
          </Wrapper>
          <Notify>{notify || 'ohne Kommentar'}</Notify>
        </Fragment>
      ))}
      {withMessage && <TextArea placeholder={withMessage ? 'Kommentar' : ''} value={message} onChangeText={setMessage} mb={20} />}
    </>
  );
};

ApprovalsPanel.defaultProps = { approvals: [] };
ApprovalsPanel.propTypes = {
  withMessage: PropTypes.bool.isRequired,
  approvals: PropTypes.arrayOf(
    PropTypes.shape({
      [ID]: PropTypes.string.isRequired,
      timestamp: PropTypes.string.isRequired,
      [SUB_USER]: PropTypes.shape({
        [TITLE]: PropTypes.string,
        [FIRST_NAME]: PropTypes.string.isRequired,
        [LAST_NAME]: PropTypes.string.isRequired,
      }).isRequired,
      status: PropTypes.number.isRequired,
      message: PropTypes.string,
    })
  ),
  message: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
};

export default ApprovalsPanel;
