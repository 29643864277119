import React, { useCallback, useMemo, useEffect } from 'react';
import { useField } from 'formik';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { pick, is } from 'ramda';

import {
  RIGHT,
  LEFT,
  DATE,
  COMMENT,
  WITHOUT_CORRECTION,
  WITH_GLASSES,
  BEST_CORRECTION,
  PUPIL_SIZE,
  SPHERE,
  CYLINDER,
  AXIS,
  CONFIDENCE,
  MOBILITY,
  VISUAL_ACUITY,
  useExaminationsActions,
  useAlerts,
} from 'store/examinations';
import FileDropZone from 'components/FileDropZone';
import { mapPropsToValues } from '../utils';

import Section from './Section';
import Input from './Input';
import { Container } from './styles';

const VisualAcuityForm = ({ side, readMode, resetForm }) => {
  const { id } = useParams();
  const { extractId, loading, success, resetAlerts } = useAlerts();
  const { uploadXML } = useExaminationsActions();

  const getPath = useCallback((item) => [side, VISUAL_ACUITY, item].join('.'), [side]);

  const [withoutCorrection, { error: withoutCorrectionErr }, { setValue: setWithoutCorrection }] = useField(
    getPath(WITHOUT_CORRECTION)
  );
  const [withGlasses, { error: withGlassesErr }, { setValue: setWithGlasses }] = useField(getPath(WITH_GLASSES));
  const [bestCorrection, { error: bestCorrectionErr }, { setValue: setBestCorrection }] = useField(getPath(BEST_CORRECTION));
  const [pupilSize, { error: pupilSizeErr }] = useField(getPath(PUPIL_SIZE));
  const [sphere, { error: sphereErr }] = useField(getPath(SPHERE));
  const [cylinder, { error: cylinderErr }] = useField(getPath(CYLINDER));
  const [axis, { error: axisErr }] = useField(getPath(AXIS));
  const [confidence, { error: confidenceErr }] = useField(getPath(CONFIDENCE));

  const highlightAutorefraction = useMemo(
    () =>
      !readMode &&
      (!confidence.value || confidence.value === 0 || confidence.value === '0') &&
      Boolean(sphere.value || cylinder.value || axis.value),
    [readMode, axis.value, confidence.value, cylinder.value, sphere.value]
  );

  const onDrop = useCallback(
    (files) => {
      if (!loading && files.length) {
        extractId(uploadXML({ patientId: id, files }));
      }
    },
    [extractId, id, loading, uploadXML]
  );

  useEffect(() => {
    if (is(Object, success)) {
      resetForm({
        values: mapPropsToValues(pick([RIGHT, LEFT, DATE, COMMENT, MOBILITY], success)),
        errors: {},
      });
    }
    if (success) {
      resetAlerts();
    }
  }, [resetAlerts, resetForm, success]);

  return (
    <div>
      <Container>
        <Section label="Ohne Korrektur">
          <Input
            field={withoutCorrection}
            readMode={readMode}
            error={withoutCorrectionErr}
            setValue={setWithoutCorrection}
            width="190px"
            withDropDown
          />
        </Section>
        <Section label="Mit bestehender Brille">
          <Input
            field={withGlasses}
            readMode={readMode}
            error={withGlassesErr}
            setValue={setWithGlasses}
            width="190px"
            withDropDown
          />
        </Section>
        <Section label="Bestmöglich korrigiert">
          <Input
            field={bestCorrection}
            readMode={readMode}
            error={bestCorrectionErr}
            setValue={setBestCorrection}
            width="205px"
            withDropDown
          />
        </Section>
        <Section label="Pupillengr.">
          <Input field={pupilSize} readMode={readMode} error={pupilSizeErr} />
        </Section>
        <Section label="Autorefraktion:">
          <Input field={sphere} label="Sphäre" readMode={readMode} isRed={highlightAutorefraction} error={sphereErr} />
          <Input field={cylinder} label="Cylinder" readMode={readMode} isRed={highlightAutorefraction} error={cylinderErr} />
          <Input field={axis} label="Achse" readMode={readMode} isRed={highlightAutorefraction} error={axisErr} />
          <Input
            field={confidence}
            label="Messqualität"
            readMode={readMode}
            isRed={highlightAutorefraction}
            error={confidenceErr}
          />
        </Section>
      </Container>
      {readMode || (
        <FileDropZone
          onDrop={onDrop}
          accept={['text/xml']}
          loading={loading}
          label="XML-Datei in dieses"
          acceptFormats={['.xml']}
        />
      )}
    </div>
  );
};

VisualAcuityForm.propTypes = {
  side: PropTypes.oneOf([RIGHT, LEFT]).isRequired,
  readMode: PropTypes.bool.isRequired,
  resetForm: PropTypes.func.isRequired,
};

export default VisualAcuityForm;
