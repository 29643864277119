import { prop, omit } from 'ramda';
import qs from 'query-string';

import { EMAIL, STATUS, SUB_SENDER, MESSAGE, PAYLOAD, PAGE_URL, OWN_ERR_HANDLER } from 'store/bugReports';

import { wrapperRequest } from './utils';
import { ID, API_URL, POST, PATCH, EXPAND, FIELDS, META } from './consts';

const defParams = {
  [EXPAND]: [SUB_SENDER],
  [FIELDS]: [EMAIL, STATUS, SUB_SENDER, MESSAGE, META, PAYLOAD, PAGE_URL],
};

export const postBugReports = (body = {}) =>
  wrapperRequest({
    url: `${API_URL}/bugreports?${qs.stringify(defParams)}`,
    options: { method: POST, body, [OWN_ERR_HANDLER]: true },
  });

export const getBugReports = (query = {}) =>
  wrapperRequest({
    url: `${API_URL}/bugreports?${qs.stringify({ ...defParams, ...query })}`,
  });

export const getBugReport = (id) =>
  wrapperRequest({
    url: `${API_URL}/bugreports/${id}?${qs.stringify(defParams)}`,
  });

export const patchBugReports = (body = {}) =>
  wrapperRequest({
    url: `${API_URL}/bugreports/${prop(ID, body)}?${qs.stringify(defParams)}`,
    options: { method: PATCH, body },
  });

export const getMediData = (query = {}) =>
  wrapperRequest({
    url: `${API_URL}/medidata?${qs.stringify(query)}`,
  });

export const patchMediData = (body = {}) =>
  wrapperRequest({
    url: `${API_URL}/medidata/${body[ID]}`,
    options: { method: PATCH, body: omit([ID], body) },
  });

export const getUsers = (query = {}) =>
  wrapperRequest({
    url: `${API_URL}/users?${qs.stringify(query)}`,
  });

export const getUser = (id) =>
  wrapperRequest({
    url: `${API_URL}/users/${id}`,
  });

export const postUser = (body = {}) =>
  wrapperRequest({
    url: `${API_URL}/users`,
    options: { method: POST, body, [OWN_ERR_HANDLER]: true },
  });

export const patchUser = (body = {}) =>
  wrapperRequest({
    url: `${API_URL}/users/${body[ID]}`,
    options: { method: PATCH, body: omit([ID], body), [OWN_ERR_HANDLER]: true },
  });
