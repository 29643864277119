import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 74px;
  height: 74px;
  min-width: 74px;
  min-height: 74px;
  border-radius: 50%;
  margin-left: 20px;
  background: var(--color-green65);
`;

export const Circle = styled.div`
  position: absolute;
  width: 74px;
  height: 74px;
  border-radius: 50%;
`;

export const First = styled(Circle)`
  clip: rect(0px, 74px, 74px, 37px);
  background: var(--color-grey97);
  transform: rotate(${({ ratio }) => 360 * ratio}deg);
`;

export const Second = styled(Circle)`
  clip: rect(0px, 37px, 74px, 0px);
  background: var(--color-grey97);
  transform: rotate(${({ ratio }) => (ratio < 0.5 ? 0 : 360 * (ratio - 0.5))}deg);
`;

export const Third = styled(Circle)`
  clip: rect(0px, 74px, 74px, 37px);
  background: var(--color-green65);
  opacity: ${({ ratio }) => (ratio < 0.5 ? 0 : 1)};
`;

export const Text = styled(Circle)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  margin: 2px;
  background-color: var(--color-greyF3);
  border-radius: 50%;
  font-size: var(--font-size18);
  font-weight: var(--font-weight500);
  letter-spacing: 0.92px;
  color: var(--color-green65);
`;
