import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 456px;
  width: 200px;
  left: calc((100% - 1024px) / 2 + 1024px + 20px);
  border-radius: var(--border-radius8);
  overflow: hidden;
  z-index: 1;
  @media (max-width: ${1024 + 2 * 220}px) {
    left: auto;
    right: 0;
  }
  @media (max-height: 670px) {
    top: 35%;
  }
`;

export const Title = styled.p`
  font-size: var(--font-size14);
  font-weight: var(--font-weight600);
  letter-spacing: 0.55px;
  line-height: 28px;
  color: var(--color-grey6A);
  background-color: rgba(237, 236, 236, 0.75);
  padding: 0 8px;
`;

export const ItemsContainer = styled.div`
  background-color: rgba(251, 251, 251, 0.9);
`;
