import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_REPORTS = getName('UPDATE_REPORTS');
export const UPDATE_REPORT = getName('UPDATE_REPORT');
export const DELETE_REPORT = getName('DELETE_REPORT');
export const RESET_REPORTS = getName('RESET_REPORTS');

export const UPDATE_FILTER_STATUS = getName('UPDATE_FILTER_STATUS');
export const UPDATE_FILTER_SEARCH = getName('UPDATE_FILTER_SEARCH');
export const UPDATE_SORT = getName('UPDATE_SORT');
export const UPDATE_PAGINATION = getName('UPDATE_PAGINATION');
export const RESET_FILTER = getName('RESET_FILTER');

export const FETCH_REPORTS = getName('FETCH_REPORTS');
export const FETCH_REPORTS_STATUS = getName('FETCH_REPORTS_STATUS');
export const FETCH_FILTERED_REPORTS = getName('FETCH_FILTERED_REPORTS');
export const SAVE_REPORT = getName('SAVE_REPORT');
export const CREATE_REPORTS_ARCHIVE = getName('CREATE_REPORTS_ARCHIVE');
export const UPLOAD_EXCEL_FILE = getName('UPLOAD_EXCEL_FILE');
