import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { usePermissions, PHYSICIAN_PATIENT, ANALYSIS } from 'permissions';

import { Container, Item } from './styles';

const Row = ({ id, widths, publicId, firstName, lastName, birth }) => {
  const { push } = useHistory();
  const can = usePermissions();
  const handleRowClick = useCallback(
    ({ currentTarget }) => {
      const { patient } = currentTarget?.dataset || {};

      const tab = can.redirect(PHYSICIAN_PATIENT) ? `/${ANALYSIS}` : '';

      if (patient) {
        push(`/patients/${patient}${tab}`, { previousPath: '/patients/search' });
      }
    },
    [can, push]
  );

  return (
    <Container type="button" data-patient={id} onClick={handleRowClick}>
      <Item width={widths[0]}>{publicId}</Item>
      <Item width={widths[1]}>{firstName}</Item>
      <Item width={widths[2]}>{lastName}</Item>
      <Item width={widths[3]}>{birth}</Item>
    </Container>
  );
};

Row.defaultProps = {
  publicId: '–',
  firstName: '–',
  lastName: '–',
  birth: '–',
};

Row.propTypes = {
  id: PropTypes.string.isRequired,
  widths: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  publicId: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  birth: PropTypes.string,
};

export default Row;
