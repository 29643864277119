import styled from 'styled-components';

export const DropContainer = styled.div`
  display: flex;
  margin: 5px 0;
  min-height: ${({ readMode }) => (readMode ? 0 : '300px')};
  & > div:first-child {
    margin-right: ${({ readMode }) => (readMode ? 0 : '10px')};
  }
`;

export default {};
