import React, { useMemo, useCallback } from 'react';
import { pipe, props, filter, join } from 'ramda';
import PropTypes from 'prop-types';

import { ZIP_CODE, CITY, CANTON, STREET, HOUSE } from 'store/patients';

import { Button, Text, Icon } from './styles';

const Item = ({ data, hasZipCode, onSelect }) => {
  const name = useMemo(() => {
    if (hasZipCode && data[STREET]) {
      return pipe(props([STREET, HOUSE]), filter(Boolean), join(' '))(data);
    }

    const rest = pipe(props([ZIP_CODE, CITY, CANTON]), filter(Boolean), join(' '))(data);

    return data[STREET] ? [data[STREET], rest].join(', ') : rest;
  }, [data, hasZipCode]);
  const onClick = useCallback(() => onSelect(data), [onSelect, data]);

  return (
    <Button type="button" onClick={onClick} title={name} aria-label={name}>
      <Text>{name}</Text>
      <Icon />
    </Button>
  );
};

Item.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    [ZIP_CODE]: PropTypes.string,
    [CANTON]: PropTypes.string,
    [CITY]: PropTypes.string,
    [STREET]: PropTypes.string,
    [HOUSE]: PropTypes.string,
  }).isRequired,
  hasZipCode: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Item;
