import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useField } from 'formik';
import { pipe, head, allPass, propEq, prop, not } from 'ramda';
import PropTypes from 'prop-types';

import {
  ID,
  PROGRESS,
  STATUS,
  COMMENT,
  DATE,
  EXAMINATION_STATUS,
  useWebsocketActions,
  useCurrentExamination,
} from 'store/examinations';
import { FIRST_NAME, LAST_NAME, BIRTH, PUBLIC_ID, SUB_PATIENT, usePatient } from 'store/patients';
import { EXAMINATION_REAL_TIME, usePermissions } from 'permissions';

import Item from './Item';
import { Container, Title, ItemsContainer } from './styles';

const CheckList = ({ disabled }) => {
  const { id: patientId } = useParams();
  const can = usePermissions();
  const patient = usePatient(patientId, {
    [ID]: ID,
    [PUBLIC_ID]: PUBLIC_ID,
    [FIRST_NAME]: FIRST_NAME,
    [LAST_NAME]: LAST_NAME,
    [BIRTH]: BIRTH,
  });
  const { updateExaminationProgress } = useWebsocketActions(can.update(EXAMINATION_REAL_TIME));
  const [examId, progress, comment] = useCurrentExamination([ID, PROGRESS, COMMENT]);

  const [, , { setValue }] = useField(DATE);

  const handleClick = useCallback(
    (id) => {
      const result = {
        [SUB_PATIENT]: patient,
        [ID]: examId,
        [STATUS]: EXAMINATION_STATUS.IN_PREPARATION,
        [PROGRESS]: progress.map((item) =>
          item[ID] === id
            ? {
                ...item,
                done: !item.done,
                timestamp: !item.done ? new Date().toISOString() : null,
              }
            : item
        ),
        [COMMENT]: comment,
      };

      if (pipe(head, allPass([propEq(id, ID), pipe(prop('done'), not)]))(progress)) {
        const date = new Date();
        result[DATE] = date.toISOString();
        setValue(date);
      }

      updateExaminationProgress(result);
    },
    [patient, examId, progress, comment, updateExaminationProgress, setValue]
  );

  if (!examId || !progress) return null;

  return (
    <Container>
      <Title>Checkliste Messung</Title>
      <ItemsContainer>
        {progress.map(({ [ID]: id, task, done, timestamp }) => (
          <Item key={id} id={id} task={task} done={done} timestamp={timestamp} onClick={handleClick} disabled={disabled} />
        ))}
      </ItemsContainer>
    </Container>
  );
};

CheckList.defaultProps = { disabled: false };

CheckList.propTypes = { disabled: PropTypes.bool };

export default CheckList;
