import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Select = ({ value, options, disabled, onSelect, ...rest }) => {
  const handleChange = useCallback(({ currentTarget }) => onSelect(currentTarget.value), [onSelect]);

  return (
    <Container value={value} disabled={disabled} onChange={handleChange} {...rest}>
      <option value="" label="Bitte auswählen" disabled>
        Bitte auswählen
      </option>
      {options.map(({ id, label, disabled: isDisabled }) => (
        <option key={id} value={id} label={label} disabled={isDisabled}>
          {label}
        </option>
      ))}
    </Container>
  );
};

Select.defaultProps = { disabled: false };

Select.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }).isRequired
  ).isRequired,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

export default Select;
