import React, { useMemo, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { props } from 'ramda';
import PropTypes from 'prop-types';

import { ID, STATUS, META, CREATED, SUB_EXAMINATION } from 'store/invoices';
import { useExamination, DATE } from 'store/examinations';
import { EXAMINATION, usePermissions } from 'permissions';
import { parseDate } from 'utils';
import Select from 'components/SelectNew';

import { STATUSES } from './consts';
import { Container, Wrapper, Label, NavLink, Status } from './styles';

const Panel = ({ list, current }) => {
  const { id: patientId } = useParams();
  const { push, location } = useHistory();
  const can = usePermissions();
  const [invoiceId, invoiceStatus, examId = null] = useMemo(() => props([ID, STATUS, SUB_EXAMINATION], current || {}), [current]);
  const options = useMemo(() => list.map(({ [ID]: id, [META]: meta }) => ({ id, label: parseDate(meta[CREATED]) })), [list]);
  const status = useMemo(() => typeof invoiceStatus === 'number' && STATUSES[invoiceStatus], [invoiceStatus]);
  const handleSelect = useCallback(
    (invoice) => push(`${location.pathname}?${new URLSearchParams({ invoice }).toString()}`),
    [location.pathname, push]
  );
  const examination = useExamination(examId);
  const examDate = examination && examination[DATE];
  const link = useMemo(
    () =>
      examId &&
      examDate && [
        `/patients/${patientId}/${can.redirect(EXAMINATION) ? 'examination' : 'analysis'}?exam=${examId}`,
        `Untersuch vom ${parseDate(examDate)}`,
      ],
    [can, examDate, examId, patientId]
  );

  if (!invoiceId) return null;

  return (
    <Container>
      <Wrapper>
        {link && (
          <Label>
            Rechnung für den <NavLink to={link[0]}>{link[1]}</NavLink>
          </Label>
        )}
      </Wrapper>
      <Wrapper>
        <Label>Rechnung vom</Label>
        <Select value={invoiceId || ''} options={options} onSelect={handleSelect} mb={0} mini />
        <Status $color={status?.color}>{status?.name}</Status>
      </Wrapper>
    </Container>
  );
};

const ItemPropType = PropTypes.shape({
  [ID]: PropTypes.string.isRequired,
  [STATUS]: PropTypes.number.isRequired,
  [META]: PropTypes.shape({
    [CREATED]: PropTypes.string.isRequired,
  }).isRequired,
});
Panel.defaultProps = { current: null };
Panel.propTypes = {
  list: PropTypes.arrayOf(ItemPropType.isRequired).isRequired,
  current: ItemPropType,
};

export default Panel;
