import React, { useCallback, useEffect, useRef } from 'react';
import { pathOr } from 'ramda';
import PropTypes from 'prop-types';

import { useAsyncState } from 'utils/useAsyncState';
import { BRIGHTNESS, CONTRAST, SCALE, TRANSLATE, ANIMATION_TIME } from '../../consts';

import { defineLimits } from './utils';
import { Wrapper, Img } from './styles';

const Image = ({ src, filter, updateFilter }) => {
  const wrapperRef = useRef();
  const imageRef = useRef();
  const data = useRef({});
  const [pressed, setPressed] = useAsyncState(false);
  const handlePress = useCallback(
    (e) => {
      e.preventDefault();
      const limits = defineLimits(wrapperRef.current.getBoundingClientRect(), imageRef.current.getBoundingClientRect());

      if (limits) {
        data.current.limits = limits;
        setPressed(true);
      }
    },
    [setPressed]
  );
  const handleUnPress = useCallback(() => {
    const { init, start, last } = data.current;

    if (start && last) {
      updateFilter(TRANSLATE, [init[0] + last[0] - start[0], init[1] + last[1] - start[1]]);
      data.current.start = null;
    }

    setPressed(false);
  }, [updateFilter, setPressed]);

  const applyStyles = useCallback((timeShot) => {
    if (!imageRef.current) return;
    if (!data.current.lastTimeShot || timeShot - data.current.lastTimeShot > ANIMATION_TIME) {
      const { brightness, contrast, scale, translateX, translateY, init } = data.current;

      const x = translateX / scale || init[0] / scale;
      const y = translateY / scale || init[1] / scale;

      imageRef.current.style.filter = `brightness(${brightness}%) contrast(${contrast}%)`;
      imageRef.current.style.transform = `scale3d(${scale}, ${scale}, 1) translate3d(${x}px, ${y}px, 0px)`;
      data.current.lastTimeShot = timeShot;
    }
    data.current.timer = window.requestAnimationFrame(applyStyles);
  }, []);

  const handleMouseMove = useCallback(({ screenX, screenY }) => {
    if (!data.current.start) {
      data.current.start = [screenX, screenY];
      data.current.last = [screenX, screenY];
      return;
    }

    const { init, start, last, limits } = data.current;

    const nextX = screenX - start[0] > limits.x[0] && screenX - start[0] < limits.x[1] ? screenX : last[0];
    const nextY = screenY - start[1] > limits.y[0] && screenY - start[1] < limits.y[1] ? screenY : last[1];

    if (nextX === last[0] && nextY === last[1]) return;

    data.current.last = [nextX, nextY];
    data.current.translateX = init[0] + nextX - start[0];
    data.current.translateY = init[1] + nextY - start[1];
  }, []);

  useEffect(() => {
    if (pressed) {
      document.body.addEventListener('mouseup', handleUnPress);
      document.body.addEventListener('mouseleave', handleUnPress);
      document.body.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      document.body.removeEventListener('mouseup', handleUnPress);
      document.body.addEventListener('mouseleave', handleUnPress);
      document.body.removeEventListener('mousemove', handleMouseMove);
    };
  }, [handleUnPress, handleMouseMove, pressed]);

  useEffect(() => {
    const left = pathOr(0, [TRANSLATE, 0], filter);
    const top = pathOr(0, [TRANSLATE, 1], filter);

    data.current.init = [left, top];
    data.current.scale = filter[SCALE] || 1;
    data.current.brightness = filter[BRIGHTNESS] || 100;
    data.current.contrast = filter[CONTRAST] || 100;
  }, [filter]);

  useEffect(() => {
    const { timer } = data.current;
    applyStyles();

    return () => {
      window.cancelAnimationFrame(timer);
    };
  }, [applyStyles]);

  return (
    <Wrapper ref={wrapperRef}>
      <Img ref={imageRef} src={src} draggable={false} onMouseDown={handlePress} isPressed={pressed} />
    </Wrapper>
  );
};

Image.defaultProps = {
  filter: {},
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  filter: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array])),
  updateFilter: PropTypes.func.isRequired,
};

export default Image;
