export const FEATURE_NAME = 'infoItems';

export const LIST = 'list';
export const REQUEST_DATA = 'requestData';

export const MESSAGE = 'message';
export const ATTACHMENTS = 'attachments';
export const TAGS = 'tags';
export const ORIGINAL_NAME = 'originalname';
export const DESCRIPTION = 'description';
export const PATH = 'path';
export const TYPE = 'mimetype';
export const DESTINATION = 'destination';
export const PREVIEW_NAME = 'previewname';

export const INFO_BOX = 'infobox';
