import React, { useRef, useCallback, useEffect } from 'react';

import { useAnalyticsActions, useAlerts } from 'store/analytics';
import { useAsyncState } from 'utils/useAsyncState';
import { ReactComponent as Icon } from 'assets/svg-icons/arrowUp.svg';

import Item from './Item';
import Spinner from './Spinner';
import { Container, Wrapper, SubmitWrap, Input, Button } from './styles';

const Llm = () => {
  const ref = useRef();
  const [conversation, setConversation] = useAsyncState([]);
  const { askQuestion } = useAnalyticsActions();
  const { action, loading, success, error, resetAlerts } = useAlerts(askQuestion);
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      const message = e.currentTarget.message.value;

      setConversation(($) => [...$, { key: $.length + 1, type: 'client', text: message }]);
      action(message);
    },
    [action, setConversation]
  );

  useEffect(() => {
    if (success || error) {
      setConversation(($) => [...$, { key: $.length + 1, type: success ? 'server' : 'error', text: success || error }]);
      resetAlerts();
    }
  }, [error, resetAlerts, setConversation, success]);

  useEffect(() => {
    ref.current?.focus?.();
  }, [conversation.length]);

  return (
    <Container onSubmit={onSubmit}>
      <Wrapper>
        {conversation.map(({ key, type, text }, index) => (
          <Item key={key} type={type} isLatest={index === conversation.length - 1}>
            {text}
          </Item>
        ))}
        {loading && <Spinner />}
      </Wrapper>
      <SubmitWrap>
        <Input ref={ref} key={conversation.length} name="message" placeholder="Message" disabled={loading} />
        <Button type="submit" disabled={loading} aria-label="Send" title="Send">
          <Icon />
        </Button>
      </SubmitWrap>
    </Container>
  );
};

export default Llm;
