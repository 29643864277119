import styled from 'styled-components';

import { URL } from 'api/consts';

export const ImagePaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  color: var(--color-grey97);
  margin: 6px 0;
  letter-spacing: 0.9px;
`;

export const ImagesSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  max-height: 480px;
  width: 100%;
`;

export const EyeImage = styled.div`
  width: 460px;
  height: 400px;
  background: no-repeat center center/cover;
  background-image: url(${({ src }) => src});
  border: 1px solid var(--color-grey97);
  border-radius: var(--border-radius8);
  cursor: ${({ src }) => (src !== `${URL}/` ? 'pointer' : 'default')};
`;
