import { useEffect, useCallback } from 'react';
import { values } from 'ramda';

import { useAlerts } from 'store/alerts/hooks';
import { wrapActions, wrapMultiSelector, normalizeArray } from 'store/utils';
import getWebSocket from 'api/webSocket';
import { ID, SUB_PATIENT } from 'api/consts';

import { SEARCHABLE_MEDIA_UPDATE, FILES } from './consts';
import * as actions from './actions';
import { getMedicalHistory, getMedicalHistoryProp } from './selectors';

export const useMedicalHistoryActions = wrapActions({ ...actions });

export const useMedicalHistory = wrapMultiSelector(getMedicalHistoryProp, getMedicalHistory);

export const useInitMedicalHistory = (hasAccess, patientId) => {
  const alerts = useAlerts();
  const { id, extractId, resetAlerts, success, loading } = alerts;
  const { resetMedicalHistory, fetchMedicalHistory } = useMedicalHistoryActions();
  const patientIdFromMedicalHistory = useMedicalHistory(SUB_PATIENT);

  useEffect(() => {
    if (hasAccess && patientId && !id) {
      extractId(fetchMedicalHistory(patientId));
    }
  }, [extractId, fetchMedicalHistory, hasAccess, id, patientId]);

  useEffect(() => {
    if (success && (!hasAccess || patientId !== patientIdFromMedicalHistory)) {
      resetMedicalHistory();
      resetAlerts();
    }
  }, [hasAccess, patientId, patientIdFromMedicalHistory, resetAlerts, resetMedicalHistory, success]);

  return hasAccess ? { ...alerts, loading: !id || loading } : { success: true };
};

export const useWebsocket = (hasAccess, patientId, action) => {
  const files = useMedicalHistory(FILES);
  const updateData = useCallback(
    (data) => {
      if (data[SUB_PATIENT] === patientId) {
        action(values({ ...normalizeArray(ID, files), [data[ID]]: data }));
      }
    },
    [patientId, action, files]
  );

  useEffect(() => {
    if (!hasAccess) return () => null;

    const socket = getWebSocket(hasAccess, 'medicalHistory');

    socket.on(SEARCHABLE_MEDIA_UPDATE, updateData);

    return () => {
      socket.off(SEARCHABLE_MEDIA_UPDATE, updateData);
    };
  }, [hasAccess, updateData]);

  useEffect(
    () => () => {
      const socket = getWebSocket(hasAccess, 'medicalHistory') || {};

      if (hasAccess && socket?.connected) socket.disconnect();
    },
    [hasAccess]
  );
};
