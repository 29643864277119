import { path } from 'ramda';

import { FEATURE_NAME as $, FILTERS, CURRENT, UPDATES, REGISTRATIONS } from './consts';

export const getFilters = path([$, FILTERS]);
export const getCurrentSchedule = path([$, CURRENT]);
export const getCurrentScheduleProp = (name) => path([$, CURRENT, name]);
export const getScheduleUpdates = (scheduleId) => path([$, UPDATES, scheduleId]);
export const getScheduleUpdatesProp = (scheduleId) => (key) => path([$, UPDATES, scheduleId, key]);
export const getUpdatedRegistration = (scheduleId) => (index) => path([$, UPDATES, scheduleId, REGISTRATIONS, index]);
