import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${({ label }) => (label ? '200px 1fr' : '1fr')};
  column-gap: 40px;
  align-items: center;
  width: 100%;
  margin-top: ${({ mt }) => mt}px;
  margin-bottom: ${({ mb }) => mb}px;
`;

export const Label = styled.p`
  font-size: var(--font-size15);
  font-weight: var(--font-weight400);
  color: var(--color-grey6A);
  margin-bottom: ${({ readMode }) => (readMode ? 0 : 5)}px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const Message = styled.p`
  grid-column: ${({ $label }) => ($label ? '2/3' : '1/2')};
  font-size: var(--font-size13);
  color: var(--color-orangeDC);
  word-break: break-word;
`;
