import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { values, props, prop } from 'ramda';

import { wrapActions, wrapMultiSelector } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { ID, SORT, PAGINATION, LIMIT, CURSOR, SEARCH, SUB_ORGANIZATION, FIELDS, ORGANIZATION_ID, ALL } from 'api/consts';

import { PUBLIC_ID, FIRST_NAME, LAST_NAME, BIRTH } from './consts';
import * as actions from './actions';
import { getPatients, getPatient, getPatientProp, getPatientsFilters, getPatientsReports } from './selectors';

export const usePatientsActions = wrapActions({ ...actions });

export const usePatient = (id, arg) => wrapMultiSelector(getPatientProp(id), getPatient(id))(arg);

export const useInitPatients = (hasAccess) => {
  const alerts = useAlerts();
  const patients = useSelector(getPatients);
  const list = useMemo(() => values(patients), [patients]);
  const filters = useSelector(getPatientsFilters);
  const [organization, search, sort, pagination] = props([SUB_ORGANIZATION, SEARCH, SORT, PAGINATION], filters);
  const organizationId = prop(ID, organization);
  const [limit, cursor] = props([LIMIT, CURSOR], pagination);

  const { extractId } = alerts;
  const { fetchPatients } = usePatientsActions();

  useEffect(() => {
    if (!hasAccess) return;
    extractId(
      fetchPatients({
        [ORGANIZATION_ID]: organizationId === ALL ? null : organizationId,
        [SEARCH]: search,
        [FIELDS]: [PUBLIC_ID, FIRST_NAME, LAST_NAME, BIRTH],
        [LIMIT]: limit,
        [CURSOR]: cursor,
        ...sort,
      })
    );
  }, [extractId, fetchPatients, hasAccess, organizationId, search, sort, limit, cursor]);

  return { list, ...alerts, organization, search, sort, pagination };
};

export const useInitPatient = (hasAccess, id) => {
  const alerts = useAlerts();
  const { extractId } = alerts;
  const { fetchPatient } = usePatientsActions();

  useEffect(() => {
    if (!hasAccess) return;
    extractId(fetchPatient(id));
  }, [extractId, fetchPatient, hasAccess, id]);

  return hasAccess ? alerts : { success: true };
};

export const useInitPatientReports = (patientId) => {
  const alerts = useAlerts();
  const reports = useSelector(getPatientsReports);

  const { extractId } = alerts;
  const { fetchPatientReports } = usePatientsActions();

  useEffect(() => {
    if (patientId) extractId(fetchPatientReports(patientId));
  }, [extractId, fetchPatientReports, patientId]);

  return { ...alerts, reports };
};
