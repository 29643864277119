import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container, InputWrap, Input, Label } from './styles';

const Checkbox = ({
  selected,
  label,
  error,
  onChange,
  onClick,
  readMode,
  disabled,
  size,
  mr,
  mb,
  borderColor,
  fontSize,
  fontColor,
  iconType,
  ...rest
}) => {
  const randomId = useMemo(() => `checkbox-${String(Math.random()).slice(2)}`, []);
  const handleChange = useCallback(
    (e) => {
      onChange(e);
      onClick(e.target.checked);
    },
    [onChange, onClick]
  );

  return (
    <Container mr={mr} mb={mb}>
      <InputWrap readMode={readMode}>
        <Input
          type="checkbox"
          id={randomId}
          value={selected}
          onChange={handleChange}
          checked={Boolean(selected)}
          error={error}
          disabled={disabled || readMode}
          size={size}
          borderColor={borderColor}
          iconType={iconType}
          {...rest}
        />
      </InputWrap>
      {label && (
        <Label htmlFor={randomId} fontSize={fontSize} fontColor={fontColor}>
          {label}
        </Label>
      )}
    </Container>
  );
};

Checkbox.defaultProps = {
  selected: false,
  label: null,
  error: false,
  onChange: () => null,
  onClick: () => null,
  readMode: false,
  disabled: false,
  size: 20,
  mr: 25,
  mb: 10,
  borderColor: null,
  iconType: 'cross',
  fontSize: 'var(--font-size15)',
  fontColor: null,
};

Checkbox.propTypes = {
  selected: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  readMode: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  borderColor: PropTypes.string,
  iconType: PropTypes.string,
  fontSize: PropTypes.string,
  fontColor: PropTypes.string,
};

export default Checkbox;
