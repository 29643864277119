import React from 'react';
import PropTypes from 'prop-types';

import { ORGANIZATION_KINDS } from 'store/organizations';

import { LABELS } from './utils';
import { Title, Confirm } from './styles';

const DeleteSection = ({ kind, onDelete }) => {
  const title = LABELS[kind] || 'Löschen';

  return (
    <>
      <Title>Eintrag löschen</Title>
      <Confirm title={title} onClick={onDelete} />
    </>
  );
};

DeleteSection.propTypes = {
  kind: PropTypes.oneOf([ORGANIZATION_KINDS.NURSING_HOME, ORGANIZATION_KINDS.OPTICIAN, ORGANIZATION_KINDS.CLINIC]).isRequired,
  onDelete: PropTypes.func.isRequired,
};
export default DeleteSection;
