import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  &:nth-child(2) {
    margin-top: 20px;
  }
`;

export const Text = styled.p`
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  line-height: 1.15;
  letter-spacing: 0.92px;
  color: var(--color-grey6A);
  margin-right: 20px;
  text-transform: uppercase;
`;

export const Status = styled.p`
  flex: 1 0 auto;
  font-size: var(--font-size10);
  font-weight: var(--font-weight600);
  line-height: 1.2;
  letter-spacing: 0.51px;
  color: ${({ color }) => color};
  text-align: right;
  text-transform: uppercase;
`;

export const Notify = styled.p`
  min-height: 40px;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyEC);
  padding: 8px 10px;
  font-size: var(--font-size18);
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: var(--color-grey6A);
  margin: 10px 0 20px 0;
`;
