import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="95" height="167" viewBox="0 0 95 167">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M46.797 14.296c-18.239 0-29.696 16.134-29.696 30.865v3.508a4.69 4.69 0 0 1-4.677 4.676H7.047a4.69 4.69 0 0 1-4.677-4.676V45.16C2.37 20.14 21.777.5 46.797.5s43.958 17.538 43.958 44.66c0 18.005-7.482 32.502-19.64 46.065l-51.208 56.818c-1.169 1.403-.468 3.74 1.87 3.74H89.82a4.691 4.691 0 0 1 4.676 4.678v4.91a4.69 4.69 0 0 1-4.676 4.677H5.176A4.69 4.69 0 0 1 .5 161.37v-8.65c0-3.51 1.17-4.911 2.103-5.847l56.353-63.366C69.01 72.284 76.492 63.165 76.492 45.16c0-15.666-9.352-30.865-29.695-30.865"
      />
      <path
        stroke="#9D9D9C"
        d="M46.797 14.296c-18.239 0-29.696 16.134-29.696 30.865v3.508a4.69 4.69 0 0 1-4.677 4.676H7.047a4.69 4.69 0 0 1-4.677-4.676V45.16C2.37 20.14 21.777.5 46.797.5s43.958 17.538 43.958 44.66c0 18.005-7.482 32.502-19.64 46.065l-51.208 56.818c-1.169 1.403-.468 3.74 1.87 3.74H89.82a4.691 4.691 0 0 1 4.676 4.678v4.91a4.69 4.69 0 0 1-4.676 4.677H5.176A4.69 4.69 0 0 1 .5 161.37v-8.65c0-3.51 1.17-4.911 2.103-5.847l56.353-63.366C69.01 72.284 76.492 63.165 76.492 45.16c0-15.666-9.352-30.865-29.695-30.865z"
      />
      <path
        fill="#FFF"
        d="M45.875 53.701c23.623 0 42.773 19.15 42.773 42.773 0 23.623-19.15 42.774-42.773 42.774-23.623 0-42.773-19.15-42.773-42.774 0-23.623 19.15-42.773 42.773-42.773"
      />
      <path
        stroke="#9D9D9C"
        d="M45.875 53.701c23.623 0 42.773 19.15 42.773 42.773 0 23.623-19.15 42.774-42.773 42.774-23.623 0-42.773-19.15-42.773-42.774 0-23.623 19.15-42.773 42.773-42.773z"
      />
      <path
        stroke="#9D9D9C"
        d="M55.805 85.495c0-5.567-4.513-10.081-10.08-10.081-5.568 0-10.082 4.514-10.082 10.081 0 3.56 1.85 6.682 4.636 8.476v21.87a5.445 5.445 0 0 0 10.891 0v-21.87c2.785-1.794 4.635-4.916 4.635-8.476z"
      />
    </g>
  </svg>
);
