import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Inside = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: space-between;
`;

export const ItemContainer = styled.div`
  display: flex;
  ${({ readMode }) =>
    readMode &&
    `
    width: 290px;
  `}
`;

export const BoldText = styled.p`
  font-size: var(--font-size18);
  font-weight: var(--font-weight500);
  line-height: 1.16;
  letter-spacing: 0.5px;
  color: var(--color-grey6A);
`;

export const Text = styled(BoldText)`
  font-weight: var(--font-weight400);
  margin-left: ${({ readMode }) => (readMode ? 10 : 50)}px;
`;
