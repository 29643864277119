import React from 'react';

export default () => (
  <svg
    version="1.1"
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="87.059px"
    height="36.82px"
    viewBox="-37 14.414 87.059 36.82"
    enableBackground="new -37 14.414 87.059 36.82"
    xmlSpace="preserve"
  >
    <path
      d="M-37,26.722l4.943-12.004h3.631l4.943,11.903h-2.623l-1.009-2.421h-6.355l-1.009,2.421H-37V26.722z M-28.021,22.182
	l-2.22-5.246h-0.201l-2.119,5.246H-28.021z"
    />
    <path
      d="M-9.158,24.502c0.707,0,1.21-0.504,1.21-1.211v-8.574h2.422v8.372c0,2.119-1.413,3.632-3.632,3.632h-4.741
	c-2.119,0-3.632-1.413-3.632-3.632v-8.372h2.421v8.574c0,0.707,0.504,1.211,1.211,1.211H-9.158z"
    />
    <path
      d="M7.185,21.879v-2.119h6.153c0,0.303,0.101,0.605,0.101,0.908c0,3.632-2.623,6.255-6.253,6.255
	c-3.632,0-6.255-2.624-6.255-6.255c0-3.631,2.623-6.254,6.255-6.254c1.815,0,3.329,0.605,4.438,1.715l-1.713,1.714
	c-0.707-0.706-1.615-1.21-2.724-1.21c-2.119,0-3.733,1.714-3.733,3.935c0,2.32,1.514,3.934,3.733,3.934
	c1.715,0,3.126-1.11,3.529-2.724H7.185V21.879z"
    />
    <path d="M21.913,21.778v2.724h9.582v2.118H19.491V14.717h11.904v2.118h-9.584v2.724h9.584v2.118L21.913,21.778L21.913,21.778z" />
    <path d="M40.473,18.449v8.272h-2.42V14.717h2.118l7.364,8.272v-8.272h2.422V26.62h-2.12L40.473,18.449z" />
    <path
      d="M-30.039,43.973h0.201l3.733-5.044h2.118v11.902h-2.421v-8.373l0,0c0,0-0.201,0.808-0.706,1.311l-1.715,2.222h-2.32
	l-1.715-2.222c-0.404-0.604-0.707-1.311-0.707-1.311l0,0v8.373h-2.32V39.028h2.118L-30.039,43.973z"
    />
    <path
      d="M-5.325,44.981c0,3.631-2.623,6.253-6.254,6.253c-3.632,0-6.254-2.622-6.254-6.253c0-3.633,2.622-6.257,6.254-6.257
	C-7.948,38.725-5.325,41.349-5.325,44.981z M-7.847,44.981c0-2.222-1.513-3.936-3.732-3.936c-2.119,0-3.732,1.714-3.732,3.936
	c0,2.32,1.513,3.934,3.732,3.934C-9.36,48.915-7.847,47.199-7.847,44.981z"
    />
    <path
      d="M1.031,39.028h8.172c2.118,0,3.631,1.411,3.631,3.632c0,0.908-0.404,1.715-1.11,2.22c1.009,0.504,1.515,1.413,1.515,2.521
	c0,2.118-1.413,3.631-3.631,3.631H1.031V39.028z M9.203,43.871c0.707,0,1.209-0.505,1.209-1.211v-0.403
	c0-0.706-0.502-1.211-1.209-1.211H3.452v2.825H9.203L9.203,43.871z M9.607,48.812c0.705,0,1.209-0.502,1.209-1.209v-0.404
	c0-0.706-0.504-1.209-1.209-1.209H3.351v2.822H9.607z"
    />
    <path d="M24.233,50.933V39.028h2.422v11.904H24.233z" />
    <path d="M38.053,39.028h2.42v9.784h9.586v2.12H38.053V39.028z" />
  </svg>
);
