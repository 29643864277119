import { object, string } from 'yup';

export const validationSchema = object().shape({
  currentPassword: string().required('Passwort fehlt.'),
  nextPassword: string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Ihr Passwort erfüllt die Sicherheitsanforderungen nicht. Bitte lesen Sie die Augenmobil-Richtlinien für sichere Passwörter.'
    )
    .required('Passwort fehlt.'),
});
