import styled from 'styled-components';

const greyText = `
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  letter-spacing: 0.92px;
  color: var(--color-grey97);
  text-transform: uppercase;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 15px;
  background-color: var(--color-white);
  z-index: 1000;
`;

export const MenuWrap = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  margin-bottom: 15px;
`;

export const Panel = styled.div`
  display: flex;
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 360px;
  height: 100%;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyEC);
  padding: 10px;
  margin-right: 20px;
`;

export const Text = styled.p`
  ${greyText}
`;

export const Input = styled.input`
  height: 33px;
  width: 100%;
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  letter-spacing: 0.53px;
  background-color: ${({ value }) => (value ? 'var(--color-orangeFE)' : 'var(--color-white)')};
  color: var(--color-grey97);
  outline: none;
  border: none;
  padding: 0 10px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: var(--color-greyEC);
  border-radius: var(--border-radius8);
  &:hover {
    ${({ disabled }) => !disabled && 'background-color: var(--color-greyEC);'}
  }
`;

export const FixedWordWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const FixedWord = styled(Button)`
  height: 36px;
  width: auto;
  min-width: 150px;
  margin-right: 20px;
  &:nth-child(odd) {
    margin-bottom: 8px;
  }
  ${greyText}
`;
