import React, { useCallback, useMemo } from 'react';
import { props } from 'ramda';
import PropTypes from 'prop-types';

import { useBugReports, useBugReportsActions, PAGINATION, SORT, CURSOR, LIMIT, COUNT, HAS_MORE } from 'store/bugReports';
import Table from 'components/Table';
import Accordion from 'components/Accordion';

import { tableHead, handleFields } from './utils';
import Users from './Users';
import Row from './Row';
import MedData from './MedData';
import { Container } from './styles';

const BugReports = () => {
  const { loading, list, filters } = useBugReports();
  const [sort, pagination] = useMemo(() => props([SORT, PAGINATION], filters), [filters]);
  const { updateSort, updatePagination } = useBugReportsActions();

  const bugReports = useMemo(() => handleFields(list), [list]);
  const renderRow = useCallback((data) => <Row {...data} />, []);

  return (
    <Container>
      <Accordion label="Nutzer" defaultOpen>
        <Users />
      </Accordion>
      <Accordion label="Nachrichten">
        <Table
          head={tableHead}
          list={bugReports}
          renderRow={renderRow}
          sort={sort}
          cursor={pagination[CURSOR]}
          count={pagination[COUNT]}
          limit={pagination[LIMIT]}
          hasMore={pagination[HAS_MORE]}
          updateSort={updateSort}
          updatePagination={updatePagination}
          loading={loading}
        />
      </Accordion>
      <Accordion label="MediData">
        <MedData />
      </Accordion>
    </Container>
  );
};

BugReports.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default BugReports;
