import { reverse, times, is, pipe, slice, map, join } from 'ramda';

import { getMinMaxByKey, getAxisRatio, isLogarithmicValue, isReversedAxis } from 'store/analytics/utils';

const AXIS_SCALE_TEMPLATE = [
  { range: 0.1, divider: 1, postfix: '' },
  { range: 1, divider: 1, postfix: '' },
  { range: 10, divider: 1, postfix: '' },
  { range: 100, divider: 1, postfix: '' },
  { range: 1000, divider: 1000, postfix: 'k' },
  { range: 10000, divider: 1000, postfix: 'k' },
  { range: 100000, divider: 1000, postfix: 'k' },
  { range: 1000000, divider: 1000000, postfix: 'M' },
  { range: 10000000, divider: 1000000, postfix: 'M' },
  { range: 100000000, divider: 1000000, postfix: 'M' },
];

const getMinMaxValues = (data, params) => {
  const [minMaxX, minMaxY, minMaxZ] = data
    .reduce(
      (acc, { items }) => {
        items.forEach(({ x, y, z }) => {
          acc[0].push(x);
          acc[1].push(y);
          acc[2].push(z);
        });

        return acc;
      },
      [[], [], []]
    )
    .map((val, i) => getMinMaxByKey(params[i], val));

  return { min: [minMaxX[0], minMaxY[0], minMaxZ[0]], max: [minMaxX[1], minMaxY[1], minMaxZ[1]] };
};

const fromHexToRgba = (hex, alpha = 1) => {
  if (!is(String, hex) || hex.length !== 7) return `rgba(255,255,255,${alpha})`;

  const rgb = pipe(
    (str) => [slice(1, 3, str), slice(3, 5, str), slice(5, 7, str)],
    map((n16) => Number(`0x${n16}`)),
    join(',')
  )(hex);

  return `rgba(${rgb},${alpha})`;
};

const getRoundNumber = (num) => (num % 1 ? num.toFixed(1) : num);

export const getChartData = (data, params) => {
  const minMaxValues = getMinMaxValues(data, params);
  const [maxValueX, maxValueY, maxValueZ] = minMaxValues.max;

  const [dataAxisX, dataAxisY] = [maxValueX, maxValueY].map((maxAmount, id) =>
    AXIS_SCALE_TEMPLATE.reduce((acc, { range, divider, postfix }) => {
      if (maxAmount / 10 > range || acc) return acc;

      const isLogarithmic = isLogarithmicValue(params[id]);
      const finish = Math.ceil(maxAmount / range);
      const result = {
        items: [],
        limit: 0,
        offset: 0,
        min: Math.floor(Math.max(0, minMaxValues.min[id]) / range) * range,
        isReversed: isReversedAxis(params[id]),
      };

      times((i) => {
        const index = i + 1;
        const unit = (range * index) / divider;

        if (unit > result.min) {
          result.items.push(isLogarithmic ? String(getRoundNumber(Math.exp(1) ** unit)) : `${getRoundNumber(unit)}${postfix}`);
        }

        result.limit = range * index;
      }, finish);

      return result;
    }, null)
  );

  return {
    dataAxisX,
    dataAxisY: { ...dataAxisY, items: reverse(dataAxisY.items) },
    playgroundData: data.map(({ year }) => year),
    data: data.map(({ items }) =>
      items.map(({ id, name, group, color, x, y, z }) => ({
        id,
        name,
        group,
        borderColor: color,
        backgroundColor: fromHexToRgba(color, 0.5),
        x,
        xRatio: getAxisRatio(params[0], x, dataAxisX.min, dataAxisX.limit),
        y,
        yRatio: getAxisRatio(params[1], y, dataAxisY.min, dataAxisY.limit),
        z,
        zRatio: getAxisRatio(params[2], z, minMaxValues.min[2], maxValueZ),
      }))
    ),
  };
};
