import { path, times } from 'ramda';

import { FIELDS, NAME, SPECIALTY, CONTACTS, ZIP_CODE, CITY, CANTON, ADDRESS } from 'store/organizations';

export const PARAMS = {
  [FIELDS]: [NAME, SPECIALTY, CONTACTS, ZIP_CODE, CITY, CANTON, ADDRESS],
};

export const getDateFromShifts = (shifts = []) => {
  const date = new Date(path([0, 0], shifts));

  return new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString();
};

export const getHoursAndMinutes = (dateStr) => {
  const date = new Date(dateStr);

  return [date.getHours(), date.getMinutes()].map((v) => (v < 10 ? `0${v}` : v)).join(':');
};

export const resetShifts = (date, shifts, days) => {
  const lastDate = new Date(date);

  return times((i) => {
    if (i !== 0) {
      const day = lastDate.getDay();

      lastDate.setDate(lastDate.getDate() + ((day === 5 && 3) || (day === 6 && 2) || 1));
    }

    if (shifts[i]) {
      lastDate.setHours(new Date(shifts[i][0]).getHours());
    } else {
      lastDate.setHours(i === 0 ? 10 : 9);
    }

    const result = [lastDate.toISOString()];

    lastDate.setHours(shifts[i] ? new Date(shifts[i][1]).getHours() : 17);
    result.push(lastDate.toISOString());

    return result;
  }, days || shifts.length);
};
