export const FEATURE_NAME = 'invoices';

export const TARMEDS = 'tarmeds';
export const PATIENT_TARMEDS = 'patient';
export const DASHBOARD = 'dashboard';
export const LIST = 'list';
export const CURRENT = 'current';
export const SERVICES = 'services';

export const FILTERS = 'filters';

export const INVOICES_FILTERS = 'invoicesFilters';
export const TARMEDS_FILTERS = 'tarmedsFilters';
export const CANTON_FILTERS = 'cantonFilters';
export const YEAR_FILTERS = 'yearFilters';
export const TARMEDS_PATIENT_ID = 'tarmedPatientId';

export const TARIFF_TYPE = 'tariff_type';
export const NAME = 'name';
export const NAME_UVG = 'name_uvg';
export const BODY_LOCATION = 'body_location';
export const LEFT = 'left';
export const RIGHT = 'right';
export const QUANTITY = 'quantity';
export const CODE = 'code';
export const REF_CODE = 'ref_code';
export const SESSION = 'session';
export const EXCLUDES = 'excludes';
export const CURRENCY = 'currency';
export const AMOUNT_TT = 'amountTT';
export const AMOUNT_MT = 'amountMT';
export const AMOUNT = 'amount';
export const MED_DATA_MESSAGES = 'mediDataMessages';
export const EXPLANATION = 'explanation';
export const MESSAGES = 'messages';
export const BEXIO_DOC = 'bexioDoc';
export const BEXIO_ESR_ID = 'bexioEsrId';
export const IS_USED = 'issued';
export const AMOUNT_DUE = 'amountDue';
export const KIND = 'kind';
export const TIERS_GARANT = 'tiers_garant';
export const TIERS_PAYANT = 'tiers_payant';
export const CANTON = 'canton';
export const YEAR = 'year';
export const DURATION_IN_MINUTES = 'durationInMinutes';

export const APPROVAL_STATUS = {
  IN_APPROVAL: 0,
  APPROVED: 1,
  REJECTED: 2,
  SENT: 3,
};

export const APPROVAL_LABELS = {
  [APPROVAL_STATUS.IN_APPROVAL]: 'Zur Überprüfung gesendet',
  [APPROVAL_STATUS.APPROVED]: 'Freigegeben',
  [APPROVAL_STATUS.REJECTED]: 'Abgelehnt',
  [APPROVAL_STATUS.SENT]: 'Gesendet',
};

export const INVOICE_STATUS = {
  IN_PREPARATION: 0,
  SCHEDULED: 1,
  SENT: 2,
  IN_REVIEW: 3,
  REJECTED: 4,
  ACCEPTED: 5,
  PAID: 6,
  ARCHIVED: 8,
  CANCELLED: 9,
  ERROR: 10,
};
export const INVOICE_LABELS = {
  [INVOICE_STATUS.IN_PREPARATION]: 'In Bearbeitung',
  [INVOICE_STATUS.SCHEDULED]: 'Zum Versand freigegeben',
  [INVOICE_STATUS.SENT]: 'Gesendet',
  [INVOICE_STATUS.IN_REVIEW]: 'In der Nachprüfung',
  [INVOICE_STATUS.REJECTED]: 'Abgelehnt',
  [INVOICE_STATUS.ACCEPTED]: 'Akzeptiert',
  [INVOICE_STATUS.PAID]: 'Bezahlt',
  [INVOICE_STATUS.ARCHIVED]: 'Archiviert',
  [INVOICE_STATUS.CANCELLED]: 'Abgebrochen',
  [INVOICE_STATUS.ERROR]: 'Fehler',
};

export const INVOICE_KINDS = {
  [TIERS_PAYANT]: 'Tiers payant',
  [TIERS_GARANT]: 'Tiers garant',
};
