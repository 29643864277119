import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Container, GridWrap, VLine, HLine, Content, YAxis, WrapY, TextY, XAxis, WrapX, TextX } from './styles';

const Grid = ({ dataAxisX, dataAxisY, children }) => {
  const [ratioX, ratioY, labelsX, labelsY, xReversed, yReversed] = useMemo(() => {
    const rx = dataAxisX.limit / (dataAxisX.limit + dataAxisX.offset);
    const ry = dataAxisY.limit / (dataAxisY.limit + dataAxisY.offset);

    return [
      rx,
      ry,
      dataAxisX.items.map((label, i) => ({ key: i, label, ratio: (rx / (dataAxisX.items.length || 1)) * (i + 1) })),
      dataAxisY.items.map((label, i) => ({ key: i, label, ratio: (ry / (dataAxisY.items.length || 1)) * (i + 1) })),
      dataAxisX.isReversed,
      dataAxisY.isReversed,
    ];
  }, [dataAxisX, dataAxisY]);

  return (
    <Container>
      <YAxis>
        <WrapY $ratio={ratioY} $reversed={yReversed}>
          {labelsY.map(({ key, label }) => (
            <TextY key={key} $content={label} />
          ))}
          <TextY />
        </WrapY>
      </YAxis>
      <GridWrap>
        {labelsX.map(({ key, ratio }) => (
          <VLine key={key} $ratio={ratio} />
        ))}
        {labelsY.map(({ key, ratio }) => (
          <HLine key={key} $ratio={ratio} />
        ))}
        <Content>{children}</Content>
      </GridWrap>
      <XAxis>
        <WrapX $ratio={ratioX} $reversed={xReversed}>
          <TextX />
          {labelsX.map(({ key, label }) => (
            <TextX key={key} $content={label} />
          ))}
        </WrapX>
      </XAxis>
    </Container>
  );
};

Grid.propTypes = {
  dataAxisX: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    offset: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    isReversed: PropTypes.bool.isRequired,
  }).isRequired,
  dataAxisY: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    offset: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    isReversed: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default Grid;
