import React, { useMemo, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { handleStatus } from './utils';
import { Container, Item, LinkWrap, NavLink } from './styles';

const Row = ({ id, widths, publicId, name, birth, status }) => {
  const { pathname } = useLocation();
  const [label, color] = useMemo(() => handleStatus(status), [status]);
  const [isShowLink, setShowLink] = useState(false);
  const onHoverStart = useCallback(() => setShowLink(true), []);
  const onHoverEnd = useCallback(() => setShowLink(false), []);
  const linkProps = useMemo(() => ({ to: { pathname: `/patients/${id}`, state: { previousPath: pathname } } }), [pathname, id]);

  return (
    <Container onHoverStart={onHoverStart} onHoverEnd={onHoverEnd}>
      <Item width={widths[0]}>{publicId}</Item>
      <Item width={widths[1]}>{name}</Item>
      <Item width={widths[2]}>{birth}</Item>
      <Item width={widths[3]} $color={color} $fontSize={10}>
        {label}
      </Item>
      {Boolean(id && isShowLink) && (
        <LinkWrap>
          <NavLink {...linkProps}>zum patientenprofil</NavLink>
        </LinkWrap>
      )}
    </Container>
  );
};

Row.defaultProps = {
  publicId: '–',
  name: '–',
  birth: '–',
};

Row.propTypes = {
  id: PropTypes.string.isRequired,
  widths: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  publicId: PropTypes.string,
  name: PropTypes.string,
  birth: PropTypes.string,
  status: PropTypes.number.isRequired,
};

export default Row;
