import { takeLatest, takeEvery, takeLeading, debounce, call, put, select, all } from 'redux-saga/effects';
import { pipe, prop, filter, allPass, is, propIs, path, head, mergeRight, omit, values } from 'ramda';

import api from 'api';
import { withAlert } from 'store/alerts';

import { ID, SUB_PATIENT } from '.';
import { setCurrentExamination, updateExaminations, updateExamination } from './actions';
import {
  FETCH_EXAMINATIONS_BY_PATIENT_ID,
  FETCH_INIT_EXAMINATIONS,
  FETCH_EXAMINATIONS,
  CREATE_EXAMINATION,
  SAVE_EXAMINATION,
  REMOVE_EXAMINATION,
  UPLOAD_MEDIA,
  UPLOAD_XML,
  UPDATE_EXAMINATION_PROGRESS,
} from './types';
import { getExamination, getCurrentExamination, getExaminations } from './selectors';
import { getExaminationFromXml } from './utils';

function* fetchExaminationsByPatientId({ payload }) {
  const data = yield call(api.getExaminations, payload);
  yield put(updateExaminations(data?.data || []));
  yield put(setCurrentExamination(pipe(head, prop(ID))(data?.data)));
}

function* fetchExaminations({ payload }) {
  return { success: yield call(api.getExaminations, payload) };
}

function* createExamination({ payload }) {
  const data = yield call(api.postExamination, { [SUB_PATIENT]: payload });
  yield put(updateExamination(data));
  yield put(setCurrentExamination(prop(ID, data)));

  return data;
}

function* saveExamination({ payload }) {
  const examId = prop(ID, payload);
  if (examId) {
    const data = yield call(api.putExamination, payload);
    yield put(updateExamination(data));
  } else {
    const { patientId } = payload;
    const newExamination = yield createExamination({ payload: patientId });
    const data = yield call(api.putExamination, { ...newExamination, ...payload });
    yield put(updateExamination(data));
  }
}

function* removeExamination({ payload }) {
  const data = yield call(api.deleteExamination, payload);
  const examinations = yield select(getExaminations);

  return { success: pipe(omit([data[ID]]), values)(examinations) };
}

function* uploadMedia({ payload }) {
  const { files, feature, offlineMode } = payload;

  const examination = yield select(getCurrentExamination);

  const apiAction = prop(offlineMode ? 'uploadFileToCache' : 'uploadFile', api);

  const data = yield all(files.map((file) => call(apiAction, file, examination[ID], feature)));
  const uploads = filter(allPass([is(Object), propIs(String, 'filename')]))(data);

  const oldMedia = path(feature, examination);
  const updatedMedia = oldMedia ? [...oldMedia, ...uploads] : uploads;

  return { success: updatedMedia };
}

function* uploadXML({ payload }) {
  const { patientId, files } = payload;

  const file = pipe(filter(Boolean), head)(files);
  const data = yield call(api.uploadXMLFile, file);

  const examination = yield select(getCurrentExamination);
  const updatedExamination = getExaminationFromXml(examination, data);

  if (data) {
    yield saveExamination({
      payload: {
        patientId,
        ...updatedExamination,
      },
    });

    return { success: updatedExamination };
  }

  return {};
}

function* updateExaminationProgress({ payload }) {
  const id = prop(ID, payload);
  const examination = yield select(getExamination(id));

  yield put(updateExamination(mergeRight(examination, payload)));
}

export default function* watchExaminations() {
  yield takeLatest(FETCH_EXAMINATIONS_BY_PATIENT_ID, withAlert(fetchExaminationsByPatientId));
  yield takeEvery(FETCH_INIT_EXAMINATIONS, withAlert(fetchExaminations));
  yield debounce(500, FETCH_EXAMINATIONS, withAlert(fetchExaminations));
  yield takeEvery(CREATE_EXAMINATION, withAlert(createExamination));
  yield debounce(500, SAVE_EXAMINATION, withAlert(saveExamination));
  yield takeLeading(REMOVE_EXAMINATION, withAlert(removeExamination));
  yield takeEvery(UPLOAD_MEDIA, withAlert(uploadMedia));
  yield takeEvery(UPLOAD_XML, withAlert(uploadXML));
  yield takeEvery(UPDATE_EXAMINATION_PROGRESS, updateExaminationProgress);
}
