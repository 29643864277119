import React, { useMemo } from 'react';

import Spinner from 'components/FullScreenSpinner';
import { useDashboard, useInitDashboard } from 'store/invoices';
import { DASHBOARD, usePermissions } from 'permissions';

import Item from './Item';
import { prepareDashboard } from './utils';
import { Container } from './styles';

const DashBoard = () => {
  const can = usePermissions();
  const { loading, success, error } = useInitDashboard(can.read(DASHBOARD));
  const isLoading = loading || !(success || error);
  const data = useDashboard();
  const dashboard = useMemo(() => prepareDashboard(data), [data]);

  if (!can.read(DASHBOARD)) return null;

  return (
    <Container>
      {isLoading ? (
        <Spinner height="340px" />
      ) : (
        <>
          <Item title="Monat" items={dashboard.month} width="235px" />
          <Item title="Jahr" items={dashboard.year} width="235px" />
          <Item title="Alle" items={dashboard.total} width="470px" />
        </>
      )}
    </Container>
  );
};

export default DashBoard;
