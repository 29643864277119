import {
  PHYSICIAN,
  NURSE,
  SECRETARY,
  FINANCE,
  FINANCE_MANAGER,
  SYSADMIN,
  EXTERN,
  PLANNER,
  PLANNER_SUPPORT,
  ANALYTICS,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  REDIRECT,
  USER,
  USERS,
  PATIENTS,
  PATIENT,
  NURSE_PATIENT,
  PHYSICIAN_PATIENT,
  MEDICAL_HISTORY,
  EXAMINATION,
  EXAMINATION_REAL_TIME,
  DIAGNOSIS,
  DIAGNOSIS_CODE,
  REPORTS,
  REPORT,
  INVOICES,
  INVOICE,
  TARMED,
  ORGANIZATIONS,
  ORGANIZATION,
  ORGANIZATION_REPORTS,
  INSURANCE,
  BUG_REPORTS,
  BUG_REPORT,
  UPLOAD,
  VISUAL_ACUITY,
  DASHBOARD,
  PLANNINGS,
  PLANNING,
  CANTON_DASHBOARD,
  INSIGHTS,
  LLM,
} from '../consts';

const user = {
  [READ]: true,
  [UPDATE]: true,
};
const bugreport = {
  [CREATE]: true,
};

export default {
  [PHYSICIAN]: {
    [USER]: user,
    [PATIENTS]: {
      [REDIRECT]: true,
    },
    [PATIENT]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
      [DELETE]: true,
    },
    [PHYSICIAN_PATIENT]: {
      [REDIRECT]: true,
    },
    [MEDICAL_HISTORY]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
    },
    [EXAMINATION]: {
      [READ]: true,
      [UPDATE]: true,
    },
    [EXAMINATION_REAL_TIME]: {
      [READ]: true,
    },
    [DIAGNOSIS]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
    },
    [DIAGNOSIS_CODE]: {
      [READ]: true,
    },
    [REPORT]: {
      [CREATE]: true,
      [READ]: true,
    },
    [INVOICE]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
      [DELETE]: true,
    },
    [TARMED]: {
      [READ]: true,
    },
    [ORGANIZATIONS]: {
      [REDIRECT]: true,
    },
    [ORGANIZATION]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
    },
    [INSURANCE]: {
      [READ]: true,
    },
    [BUG_REPORT]: bugreport,
    [UPLOAD]: {
      [CREATE]: true,
    },
  },
  [NURSE]: {
    [USER]: user,
    [PATIENTS]: {
      [REDIRECT]: true,
    },
    [PATIENT]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
      [DELETE]: true,
    },
    [NURSE_PATIENT]: {
      [REDIRECT]: true,
    },
    [MEDICAL_HISTORY]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
    },
    [EXAMINATION]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
      [REDIRECT]: true,
    },
    [EXAMINATION_REAL_TIME]: {
      [READ]: true,
      [UPDATE]: true,
      [REDIRECT]: true,
    },
    [VISUAL_ACUITY]: {
      [CREATE]: true,
    },
    [ORGANIZATIONS]: {
      [REDIRECT]: true,
    },
    [ORGANIZATION]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
      [DELETE]: true,
    },
    [INSURANCE]: {
      [READ]: true,
    },
    [BUG_REPORT]: bugreport,
    [UPLOAD]: {
      [CREATE]: true,
    },
  },
  [SECRETARY]: {
    [USER]: user,
    [REPORT]: {
      [READ]: true,
      [UPDATE]: true,
    },
    [BUG_REPORT]: bugreport,
    [REPORTS]: {
      [REDIRECT]: true,
    },
  },
  [FINANCE]: {
    [USER]: user,
    [BUG_REPORT]: bugreport,
    [INVOICE]: {
      [READ]: true,
      [UPDATE]: true,
    },
    [TARMED]: {
      [READ]: true,
    },
    [INVOICES]: {
      [REDIRECT]: true,
    },
    [ORGANIZATION]: {
      [READ]: true,
    },
  },
  [FINANCE_MANAGER]: {
    [USER]: user,
    [BUG_REPORT]: bugreport,
    [DASHBOARD]: {
      [READ]: true,
    },
    [INVOICES]: {
      [REDIRECT]: true,
    },
    [INVOICE]: {
      [READ]: true,
      [UPDATE]: true,
    },
    [ORGANIZATION]: {
      [READ]: true,
    },
    [CANTON_DASHBOARD]: {
      [READ]: true,
    },
  },
  [SYSADMIN]: {
    [USER]: user,
    [USERS]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
    },
    [BUG_REPORT]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
    },
    [BUG_REPORTS]: {
      [REDIRECT]: true,
    },
  },
  [EXTERN]: {
    [USER]: user,
    [REPORT]: {
      [READ]: true,
      [UPDATE]: true,
    },
    [BUG_REPORT]: bugreport,
    [ORGANIZATION]: {
      [CREATE]: true,
      [READ]: true,
    },
    [ORGANIZATION_REPORTS]: {
      [REDIRECT]: true,
    },
  },
  [PLANNER]: {
    [USER]: user,
    [BUG_REPORT]: bugreport,
    [PLANNINGS]: {
      [REDIRECT]: true,
    },
    [PLANNING]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
      [DELETE]: true,
    },
  },
  [PLANNER_SUPPORT]: {
    [USER]: user,
    [BUG_REPORT]: bugreport,
    [PLANNINGS]: {
      [REDIRECT]: true,
    },
    [PLANNING]: {
      [READ]: true,
    },
  },
  [ANALYTICS]: {
    [INSIGHTS]: {
      [REDIRECT]: true,
      [READ]: true,
    },
    [LLM]: {
      [REDIRECT]: true,
      [READ]: true,
    },
  },
};
