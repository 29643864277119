import React, { useCallback } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import { DATE } from 'store/examinations';
import DateInput from 'components/DateInput';

import TimeInput from './TimeInput';
import { Container } from './styles';

const DateForm = ({ readMode }) => {
  const [{ value: date }, , { setValue: setDate }] = useField(DATE);
  const updateDate = useCallback(
    (d) => {
      d.setHours(date.getHours());
      d.setMinutes(date.getMinutes());
      setDate(d);
    },
    [date, setDate]
  );

  return (
    <>
      <Container>
        <DateInput
          value={date}
          label="Untersuchungsdatum"
          onChangeDate={updateDate}
          errorMessage="Ungültiges Datum."
          readMode={readMode}
        />
      </Container>
      <Container>
        <TimeInput
          label="Untersuchungszeit"
          date={date}
          onChange={setDate}
          errorMessage="Ungültige Uhrzeit."
          readMode={readMode}
        />
      </Container>
    </>
  );
};

DateForm.propTypes = { readMode: PropTypes.bool.isRequired };

export default DateForm;
