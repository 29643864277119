import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import AddItemBar from 'components/AddItemBar';
import TextInput from 'components/TextInputNew';
import Spinner from 'components/FullScreenSpinner';
import Table from 'components/Table';
import { PATIENT, usePermissions } from 'permissions';
import { ID, NAME, CURSOR, LIMIT, HAS_MORE, COUNT, ORGANIZATION_KINDS, useCollectOrganizationsAction } from 'store/organizations';
import { useInitPatients, usePatientsActions, ALL } from 'store/patients';

import Row from './Row';
import { Container, DropDown } from './styles';
import { tableHead, handlePatients } from './utils';
import { useCreatePatient } from './hooks';

const Search = () => {
  const history = useHistory();
  const can = usePermissions();

  const { list, organization, pagination, sort, search, loading: patientsLoading } = useInitPatients(can.read(PATIENT));
  const patients = useMemo(() => handlePatients(list), [list]);
  const { updateFilterOrganization, updateFilterSearch, updatePagination, updateSort } = usePatientsActions();

  const getOrganizations = useCollectOrganizationsAction(ORGANIZATION_KINDS.NURSING_HOME);
  const handleFilterByOrganization = useCallback(
    (org) => {
      updateFilterOrganization(org || { [ID]: ALL, [NAME]: 'Alle Pflegezentren' });
    },
    [updateFilterOrganization]
  );

  const renderRow = useCallback((data) => <Row {...data} />, []);

  const { postPatient, loading } = useCreatePatient(history);

  if (loading) return <Spinner />;

  return (
    <Container>
      {can.create(PATIENT) && <AddItemBar label="Neuer Patient" onClick={postPatient} />}
      <TextInput type="search" placeholder="Patient suchen" value={search} handleChangeText={updateFilterSearch} mb={15} />
      <DropDown
        idKey={ID}
        nameKey={NAME}
        disabledId="all"
        action={getOrganizations}
        placeholder="Pflegezentrum suchen"
        value={organization}
        onChange={handleFilterByOrganization}
        onlyOne
      />
      <Table
        title="Patienten"
        head={tableHead}
        list={patients}
        renderRow={renderRow}
        sort={sort}
        cursor={pagination[CURSOR]}
        count={pagination[COUNT]}
        limit={pagination[LIMIT]}
        hasMore={pagination[HAS_MORE]}
        updateSort={updateSort}
        updatePagination={updatePagination}
        loading={patientsLoading}
      />
    </Container>
  );
};

export default Search;
