import React, { useMemo } from 'react';

import Box from 'components/AnimateBox';
import Icon from 'assets/svg-icons/Close';
import { useLoggedIn, useHas2FA } from 'store/session';
import { MAIN, APP, useErrors, useErrorsActions } from 'store/errors';
import { useShouldCheck2FA } from 'permissions';

import Warn2FA from './Warn2FA';
import WarnIE from './WarnIE';
import { Wrapper, Text, Button } from './styles';

const AppError = () => {
  const isLoggedIn = useLoggedIn();
  const has2FA = useHas2FA();
  const shouldCheck2FA = useShouldCheck2FA();
  const [mainErr, appErr] = useErrors([MAIN, APP]);
  const { resetErrors } = useErrorsActions();

  const warn2FA = useMemo(() => isLoggedIn && shouldCheck2FA && !has2FA && <Warn2FA />, [has2FA, isLoggedIn, shouldCheck2FA]);
  const warnIE = useMemo(() => Boolean(document.documentMode) && <WarnIE />, []);
  const warn = warnIE || warn2FA;
  const error = mainErr || appErr;

  return (
    <Box isOpen={Boolean(warn || error)}>
      {error ? (
        <Wrapper>
          <Text>{error}</Text>
          <Button type="button" onClick={resetErrors} title="Close" aria-label="Close">
            <Icon />
          </Button>
        </Wrapper>
      ) : (
        warn
      )}
    </Box>
  );
};

export default AppError;
