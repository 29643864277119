import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import Checkbox from 'components/CheckboxNew';

import { Container, Text, Time } from './styles';

const Item = ({ id, task, done, timestamp, onClick, disabled }) => {
  const handleClick = useCallback(() => onClick(id), [id, onClick]);

  const time = useMemo(() => {
    const date = moment(timestamp);
    const isValid = timestamp && date.isValid();

    return isValid && date.format('HH:mm');
  }, [timestamp]);

  return (
    <Container done={done}>
      <Checkbox
        borderColor={done ? 'var(--color-green65)' : 'var(--color-grey6A)'}
        selected={done}
        onClick={handleClick}
        disabled={disabled}
        iconType="check"
        size={14}
        mb={0}
        mr={0}
      />
      <Text done={done}>{task}</Text>
      <Time done={done}>{time}</Time>
    </Container>
  );
};

Item.defaultProps = {
  done: false,
  timestamp: '',
};

Item.propTypes = {
  id: PropTypes.string.isRequired,
  task: PropTypes.string.isRequired,
  done: PropTypes.bool,
  timestamp: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Item;
