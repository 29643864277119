import qs from 'query-string';
import { prop, omit, assocPath, pathOr, keys } from 'ramda';

import { ID } from 'store/examinations';

import { wrapperRequest } from './utils';
import { API_URL, URL, POST, PUT, DELETE, SUB_PATIENT, EXPAND, OWN_ERR_HANDLER } from './consts';
import { uploadFile } from './uploads';
import { getCache, UPLOAD_FILES_CACHE, FILES_MAP_CACHE } from './cache';

const defParams = { [EXPAND]: [SUB_PATIENT] };

export const postExamination = (body = {}) =>
  wrapperRequest({
    url: `${API_URL}/examinations?${qs.stringify(defParams)}`,
    options: { method: POST, body },
  });

export const getExaminations = (query = {}) =>
  wrapperRequest({
    url: `${API_URL}/examinations?${qs.stringify({ ...query, ...defParams })}`,
  });

export const getExamination = (id, query = {}) =>
  wrapperRequest({
    url: `${API_URL}/examinations/${id}?${qs.stringify(query)}`,
  });

export const putExamination = (body = {}) =>
  wrapperRequest({
    url: `${API_URL}/examinations/${prop(ID, body)}?${qs.stringify(defParams)}`,
    options: { method: PUT, body: omit([ID], body) },
  });

export const deleteExamination = (id) =>
  wrapperRequest({
    url: `${API_URL}/examinations/${id}`,
    options: { method: DELETE, [OWN_ERR_HANDLER]: true },
  });

export const uploadFileToCache = async (file, examinationId, examinationPath) => {
  const filesCache = await getCache(UPLOAD_FILES_CACHE);
  const mapCache = await getCache(FILES_MAP_CACHE);

  if (!(filesCache && mapCache)) return null;

  const { name, size, type, lastModified } = file;
  const fileName = `${size}_${lastModified}_${name}`;
  const cacheUrl = `${URL}/cache_upload/${fileName}`;

  try {
    const hasMatch = await mapCache.match(cacheUrl);

    if (hasMatch) return null;

    const fileMap = {
      fieldname: 'file',
      originalname: name,
      mimetype: type,
      destination: `${URL}/cache_upload/`,
      filename: cacheUrl,
      path: cacheUrl,
      size,
      previewname: cacheUrl,
      description: name,
      examinationId,
      examinationPath: examinationPath.join('.'),
      offline: true,
    };

    await filesCache.put(new Request(cacheUrl, { 'Content-Type': type }), new Response(file));
    await mapCache.put(cacheUrl, new Response(JSON.stringify(fileMap)));

    return fileMap;
  } catch (e) {
    throw Error('An error occurred when writing file to cache.');
  }
};

export const deleteFileFromCache = async (cacheUrl) => {
  const filesCache = await getCache(UPLOAD_FILES_CACHE);
  const mapCache = await getCache(FILES_MAP_CACHE);

  if (!(filesCache && mapCache)) return;

  try {
    await filesCache.delete(cacheUrl);
    await mapCache.delete(cacheUrl);
  } catch (e) {
    console.error('An error occurred when deleting file from cache.'); // eslint-disable-line no-console
  }
};

const getCacheFiles = async () => {
  try {
    const cacheMap = await getCache(FILES_MAP_CACHE);
    const cacheFiles = await getCache(UPLOAD_FILES_CACHE);
    if (!(cacheMap && cacheFiles)) return { error: 'An error occurred when opening caches.' };

    const allFilesMap = await cacheMap.matchAll();
    const mapFiles = await Promise.all(allFilesMap.map((response) => response.json()));
    const allFiles = await Promise.all(
      mapFiles.map((fileMap) =>
        cacheFiles
          .match(fileMap.filename)
          .then((r) => r && r.blob())
          .then((blob) => blob && { ...fileMap, file: new File([blob], fileMap.filename, { type: fileMap.mimetype }) })
      )
    );

    return { files: allFiles.filter(Boolean) };
  } catch (e) {
    const error = 'An error occurred when reading file from cache.';

    console.error(error); // eslint-disable-line no-console

    return { error };
  }
};

export const uploadFilesFromCache = async () => {
  const { files, error } = await getCacheFiles();

  if (error) return { error };
  if (!files.length) return {};

  const examIds = files.reduce((acc, { examinationId }) => {
    if (examinationId && !acc.includes(examinationId)) acc.push(examinationId);
    return acc;
  }, []);
  const pathsForDelete = [];

  try {
    const examinations = await Promise.all(examIds.map((examId) => getExamination(examId, defParams)));
    const examinationsMap = examinations.reduce((acc, exam) => {
      acc[exam[ID]] = exam;
      return acc;
    }, {});

    const promises = files.map(({ path, examinationId, examinationPath, file, description: fileDescription }) => async () => {
      const filesPath = examinationPath.split('.');
      const examinationFiles = pathOr([], [examinationId, ...filesPath], examinationsMap);
      const fileIndex = examinationFiles.findIndex((item) => item.offline && item.path === path);

      if (fileIndex > -1) {
        const data = await uploadFile(file);
        const description = pathOr(fileDescription, [examinationId, ...filesPath, fileIndex, 'description'], examinationsMap);
        examinationsMap[examinationId] = assocPath(
          [...filesPath, fileIndex],
          { ...data, description },
          examinationsMap[examinationId]
        );
        pathsForDelete.push(path);
      }
    });

    await Promise.all(promises.map((promise) => promise()));
    await Promise.all(keys(examinationsMap).map((examId) => putExamination(examinationsMap[examId])));
    await Promise.all(pathsForDelete.map((cacheUrl) => deleteFileFromCache(cacheUrl)));

    return { success: 'All files uploaded successfully.' };
  } catch (e) {
    const err = 'An error occurred when saving file to cache.';

    console.error(err); // eslint-disable-line no-console

    return { error: err };
  }
};
