import { useEffect } from 'react';
import { prop } from 'ramda';

import { ID, STATUS, useBugReport, useBugReportsActions, useAlerts } from 'store/bugReports';

export const useBugReportChecker = (burReportId, goBack) => {
  const { id, extractId } = useAlerts();
  const bugReport = useBugReport(burReportId);
  const status = prop(STATUS, bugReport);
  const { changeBugReportStatus } = useBugReportsActions();
  const existBugReport = Boolean(bugReport);

  useEffect(() => {
    if (!existBugReport) goBack();
    if (!id && existBugReport && status === 0) {
      extractId(changeBugReportStatus({ [ID]: burReportId, [STATUS]: 1 }));
    }
  }, [bugReport, changeBugReportStatus, existBugReport, extractId, id, goBack, status, burReportId]);

  return bugReport;
};
