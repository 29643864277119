import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Label, StyledSelect } from './styles';

const Select = ({
  value,
  options,
  label,
  placeholder,
  disabled,
  readMode,
  mb,
  onSelect,
  onChange,
  idKey,
  labelKey,
  labelSize,
  labelWidth,
  mini,
  ...rest
}) => {
  const randomId = useMemo(() => `select-${String(Math.random()).slice(2)}`, []);
  const handleChange = useCallback(
    (e) => {
      onChange(e);
      onSelect(e.target.value);
    },
    [onChange, onSelect]
  );

  return (
    <Wrapper label={label} labelWidth={labelWidth} mb={mb} mini={mini}>
      {label && (
        <Label htmlFor={randomId} labelSize={labelSize}>
          {label}
        </Label>
      )}
      <StyledSelect
        id={randomId}
        value={value}
        disabled={disabled || readMode}
        readMode={readMode}
        onChange={handleChange}
        mini={mini}
        {...rest}
      >
        <option value="" label={readMode ? '–' : placeholder} disabled>
          {placeholder}
        </option>
        {options.map(({ [idKey]: id, [labelKey]: itemLabel }) => (
          <option key={id} value={id} label={itemLabel}>
            {itemLabel}
          </option>
        ))}
      </StyledSelect>
    </Wrapper>
  );
};

Select.defaultProps = {
  value: '',
  options: [],
  label: null,
  placeholder: 'Bitte auswählen',
  disabled: false,
  readMode: false,
  mb: 10,
  onSelect: () => null,
  onChange: () => null,
  idKey: 'id',
  labelKey: 'label',
  labelSize: 'var(--font-size15)',
  labelWidth: '200px',
  mini: false,
};

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired).isRequired),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readMode: PropTypes.bool,
  mb: PropTypes.number,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  idKey: PropTypes.string,
  labelKey: PropTypes.string,
  labelSize: PropTypes.string,
  labelWidth: PropTypes.string,
  mini: PropTypes.bool,
};

export default Select;
