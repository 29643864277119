import { pipe, filter, complement, propEq, sort, map, applySpec, prop, propOr, props, join, __ } from 'ramda';

import { TITLE, LAST_NAME, FIRST_NAME } from 'store/session';
import { ID, APPROVAL_STATUS, APPROVAL_LABELS, SUB_USER } from 'store/invoices';
import { POSITION_MAP } from 'utils/constants';
import { parseDate, parseTime } from 'utils';
import palette from 'theme/palette';

export const handleItems = pipe(
  filter(complement(propEq(APPROVAL_STATUS.SENT, 'status'))),
  sort((a, b) => new Date(a?.timestamp || 0).valueOf() - new Date(b?.timestamp || 0).valueOf()),
  map(
    applySpec({
      id: prop(ID),
      date: pipe(prop('timestamp'), parseDate),
      time: pipe(prop('timestamp'), parseTime),
      name: pipe(
        propOr({}, SUB_USER),
        ($) => [POSITION_MAP[$[TITLE]], ...props([FIRST_NAME, LAST_NAME], $)],
        filter(Boolean),
        join(' ')
      ),
      status: pipe(prop('status'), prop(__, APPROVAL_LABELS)),
      color: pipe(prop('status'), prop(__, palette.approval)),
      notify: prop('message'),
    })
  )
);
