import styled from 'styled-components';

import search from 'assets/svg-icons/search.svg';

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${({ label, labelWidth }) => (label ? `${labelWidth} 1fr` : '1fr')};
  column-gap: 40px;
  align-items: center;
  width: 100%;
  margin-top: ${({ mt }) => mt}px;
  margin-bottom: ${({ mb }) => mb}px;
`;

export const Label = styled.label`
  font-size: ${({ labelSize }) => labelSize};
  font-weight: var(--font-weight400);
  color: var(--color-grey6A);
`;

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
`;

export const Input = styled.input`
  grid-column: 1/2;
  grid-row: 1/2;
  width: 100%;
  height: 40px;
  background-color: ${({ readMode }) => (readMode ? 'var(--color-greyEC)' : 'var(--color-white)')};
  border: 1px solid;
  border-radius: var(--border-radius8);
  border-color: ${({ readMode, error }) => (readMode && 'var(--color-greyEC)') || (error && '#ff4124') || 'var(--color-grey97)'};
  font-size: var(--font-size18);
  font-weight: var(--font-weight400);
  color: ${({ error }) => (error ? 'var(--color-orangeDC)' : 'var(--color-grey6A)')};
  padding: 0 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  ${({ currentType }) =>
    currentType === 'search' &&
    `
      background-image: url(${search});
      background-position: 5px;
      background-repeat: no-repeat;
  `}
  padding-left: ${({ currentType }) => (currentType === 'search' ? 40 : 10)}px;
  padding-right: ${({ currentType }) => (currentType === 'search' || currentType === 'password' ? 40 : 10)}px;
  &::placeholder {
    color: ${({ readMode }) => (readMode ? 'transparent' : 'var(--color-grey97)')};
  }
  &:disabled {
    opacity: 1;
    font-weight: var(--font-weight300);
    cursor: not-allowed;
  }
  &:focus {
    box-shadow: var(--shadow-input-focus);
  }
`;

export const Button = styled.button`
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: end;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const Message = styled.p`
  margin-top: 3px;
  margin-left: ${({ label }) => (label ? 250 : 10)}px;
  grid-column: 1/-1;
  color: var(--color-orangeDC);
  font-size: var(--font-size13);
  word-break: break-word;
`;
