import io from 'socket.io-client';

import { MESSENGER_URL } from 'api/consts';

import { getToken } from './utils';

const getSocket = () => {
  const sockets = {};

  return (hasAccess, key) => {
    if (hasAccess && !sockets[key]) {
      sockets[key] = io(MESSENGER_URL, {
        query: `token=${getToken()}`,
        reconnectionDelay: 10000,
        reconnectionDelayMax: 25000,
        reconnectionAttempts: process.env.NODE_ENV === 'development' ? 3 : 25,
        secure: true,
        withCredentials: true,
      });
    }
    if (hasAccess && sockets[key] && !sockets[key].connected) sockets[key].connect();

    return sockets[key] || {};
  };
};

export default getSocket();
