import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_PATIENTS = getName('UPDATE_PATIENTS');
export const UPDATE_PATIENT = getName('UPDATE_PATIENT');
export const DELETE_PATIENT = getName('DELETE_PATIENT');
export const RESET_PATIENTS = getName('RESET_PATIENTS');
export const UPDATE_FILTER_ORGANIZATION = getName('UPDATE_FILTER_ORGANIZATION');
export const UPDATE_FILTER_SEARCH = getName('UPDATE_FILTER_SEARCH');
export const RESET_FILTERS = getName('RESET_FILTERS');
export const UPDATE_SORT = getName('UPDATE_SORT');
export const UPDATE_PAGINATION = getName('UPDATE_PAGINATION');
export const UPDATE_PATIENT_REPORTS = getName('UPDATE_PATIENT_REPORTS');

export const FETCH_PATIENTS = getName('FETCH_PATIENTS');
export const FETCH_PATIENT = getName('FETCH_PATIENT');
export const CREATE_PATIENT = getName('CREATE_PATIENT');
export const SAVE_PATIENT = getName('SAVE_PATIENT');
export const REMOVE_PATIENT = getName('REMOVE_PATIENT');
export const FETCH_PATIENT_REPORTS = getName('FETCH_PATIENT_REPORTS');
export const FETCH_INSURANCES = getName('FETCH_INSURANCES');
export const UPLOAD_MEDIA_FILE = getName('UPLOAD_MEDIA_FILE');
export const SEARCH_PATIENTS = getName('SEARCH_PATIENTS');
export const MERGE_PATIENT_RECORDS = getName('MERGE_PATIENT_RECORDS');
export const AUTOCOMPLETE_ADDRESS = getName('AUTOCOMPLETE_ADDRESS');
