import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  AUTHENTICATE,
  AUTHENTICATE_2FA,
  UPDATE_USER,
  LOGOUT,
  UPDATE_2FA_LINK,
  UPDATE_USER_DATA,
  LOGIN,
  REQUEST_EXTERN_CODE,
  RESTORE,
  FETCH_RESET_LINK,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  FETCH_LINK_2FA,
  FETCH_2FA,
  VALIDATE_2FA,
  RESET_2FA,
  SAVE_USER_DATA,
  GET_EXTERNAL_EMAIL,
} from './types';

export const authenticate = createAction(AUTHENTICATE);
export const authenticate2FA = createAction(AUTHENTICATE_2FA);
export const updateUser = createAction(UPDATE_USER);
export const logout = createAction(LOGOUT);
export const update2FALink = createAction(UPDATE_2FA_LINK);
export const updateUserData = createAction(UPDATE_USER_DATA);

export const login = createAlertAction(LOGIN);
export const requestExternCode = createAlertAction(REQUEST_EXTERN_CODE);
export const restore = createAlertAction(RESTORE);
export const fetchResetLink = createAlertAction(FETCH_RESET_LINK);
export const resetPassword = createAlertAction(RESET_PASSWORD);
export const updatePassword = createAlertAction(UPDATE_PASSWORD);
export const fetch2FALink = createAlertAction(FETCH_LINK_2FA);
export const fetch2FA = createAlertAction(FETCH_2FA);
export const validate2FA = createAlertAction(VALIDATE_2FA);
export const reset2FA = createAlertAction(RESET_2FA);
export const saveUserData = createAlertAction(SAVE_USER_DATA);
export const getExternEmail = createAlertAction(GET_EXTERNAL_EMAIL);
