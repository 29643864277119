import styled from 'styled-components';

import Input from 'components/TextInputNew';
import Btn from 'components/Button';

export const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 10px 0;
`;

export const HeadWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MenuBtn = styled(Btn)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 8px;
`;

export const Preview = styled.div`
  width: 100%;
  height: 120px;
  border: 1px solid var(--color-grey97);
  border-radius: var(--border-radius8);
  margin: 10px 0 5px 0;
  background-color: var(--color-white);
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
`;

export const Text = styled.p`
  min-height: 25px;
  line-height: 23px;
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  border-radius: var(--border-radius8);
  color: var(--color-grey6A);
  background-color: var(--color-greyEC);
  text-align: center;
  text-transform: uppercase;
`;

export const EditName = styled(Input)`
  height: 25px;
  text-align: center;
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  text-transform: uppercase;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`;

export const Button = styled(Btn)`
  height: 25px;
  width: auto;
  padding: 0 8px;
`;
