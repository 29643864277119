import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { parseTime } from 'utils';

import { Container, Label, Input, Message } from './styles';

const TimeInput = ({ label, date, onChange, errorMessage, readMode }) => {
  const ref = useRef(null);
  const [isValid, setIsValid] = useState('');
  const defaultValue = useMemo(() => parseTime(date), [date]);
  const handleChange = useCallback(
    ({ currentTarget }) => {
      if (currentTarget.validity.valid) {
        const updatedDate = new Date(date);
        const [hours, minutes] = currentTarget.value.split(':');

        updatedDate.setHours(hours);
        updatedDate.setMinutes(minutes);
        onChange(updatedDate);
      }

      setIsValid(currentTarget.validity.valid);
    },
    [date, onChange]
  );

  useEffect(() => {
    setIsValid(Boolean(ref.current?.validity?.valid));
  }, [date]);

  return (
    <Container $readMode={readMode}>
      <Label>{label}</Label>
      <Input ref={ref} type="time" value={defaultValue} onChange={handleChange} $error={!isValid} disabled={readMode} required />
      {!readMode && !isValid && <Message>{errorMessage}</Message>}
    </Container>
  );
};

TimeInput.defaultProps = { readMode: false };
TimeInput.propTypes = {
  label: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  readMode: PropTypes.bool,
};

export default TimeInput;
