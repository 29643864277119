import React, { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { BUG_REPORT, IMAGES_REVIEW, types } from './consts';
import { Container } from './styles';

const Portal = ({ type, children }) => {
  const ref = useRef(document.createElement('div'));

  useEffect(() => {
    const html = document.querySelector('html');
    const wrapper = ref.current;
    const portal = document.querySelector(types[type]);

    if (type === IMAGES_REVIEW) html.style.overflow = 'hidden';

    portal.appendChild(wrapper);
    return () => {
      portal.removeChild(wrapper);

      if (type === IMAGES_REVIEW) html.style.overflow = 'auto';
    };
  }, [type]);

  return createPortal(<Container>{children}</Container>, ref.current);
};

Portal.propTypes = {
  type: PropTypes.oneOf([BUG_REPORT, IMAGES_REVIEW]).isRequired,
  children: PropTypes.node.isRequired,
};

export { BUG_REPORT, IMAGES_REVIEW };

export default Portal;
