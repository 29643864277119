import styled from 'styled-components';

import ConfirmComp from 'components/Confirm';
import Message from 'components/Message';

export const Title = styled.div`
  border-top: solid 1px var(--color-grey97);
  text-align: left;
  color: var(--color-grey6A);
  font-weight: var(--font-weight500);
  font-size: var(--font-size14);
  padding-top: 12px;
  margin-top: 200px;
`;

export const Confirm = styled(ConfirmComp)`
  margin-top: -18px;
`;

export const WarnMessage = styled(Message)`
  font-size: var(--font-size18);
`;

export const SectionWrap = styled.div`
  display: flex;
  margin-bottom: 10px;
  & > div {
    flex: 1 1 auto;
    margin: 0;
  }
`;

export const Label = styled.p`
  margin-right: 40px;
  width: 200px;
  max-width: 300px;
  line-height: 40px;
  color: var(--color-grey6A);
`;
