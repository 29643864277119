import { useEffect } from 'react';

import { use2FALink, useSessionActions, useAlerts } from 'store/session';

export const useQRLink = () => {
  const url = use2FALink();
  const { fetch2FALink, update2FALink } = useSessionActions();
  const { id, extractId, loading, success, error, resetAlerts } = useAlerts();

  useEffect(() => {
    if (!id) {
      extractId(fetch2FALink());
    }
  }, [extractId, fetch2FALink, id]);

  useEffect(
    () => () => {
      if (success || error) {
        update2FALink();
        resetAlerts();
      }
    },
    [error, resetAlerts, success, update2FALink]
  );

  return { url, loading, error };
};
