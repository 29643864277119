import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_DIAGNOSES = getName('UPDATE_DIAGNOSES');
export const UPDATE_DIAGNOSIS = getName('UPDATE_DIAGNOSIS');
export const DELETE_DIAGNOSIS = getName('DELETE_DIAGNOSIS');
export const RESET_DIAGNOSES = getName('RESET_DIAGNOSES');

export const UPDATE_DIAGNOSIS_REPORTS = getName('UPDATE_DIAGNOSIS_REPORTS');
export const UPDATE_DIAGNOSIS_REPORT = getName('UPDATE_DIAGNOSIS_REPORT');
export const DELETE_DIAGNOSIS_REPORT = getName('DELETE_DIAGNOSIS_REPORT');
export const RESET_DIAGNOSIS_REPORTS = getName('RESET_DIAGNOSIS_REPORTS');

export const UPDATE_DIAGNOSIS_PREVIEW_REPORT = getName('UPDATE_DIAGNOSIS_PREVIEW_REPORT');
export const RESET_DIAGNOSIS_PREVIEW_REPORT = getName('RESET_DIAGNOSIS_PREVIEW_REPORT');

export const UPDATE_DIAGNOSIS_CODES = getName('UPDATE_DIAGNOSIS_CODES');

export const FETCH_DIAGNOSIS_CODES = getName('FETCH_DIAGNOSIS_CODES');
export const FETCH_DIAGNOSES = getName('FETCH_DIAGNOSES');
export const FETCH_DIAGNOSES_FOR_EXAMINATION = getName('FETCH_DIAGNOSES_FOR_EXAMINATION');
export const SAVE_DIAGNOSIS = getName('SAVE_DIAGNOSIS');
export const CREATE_DIAGNOSIS_REPORTS = getName('CREATE_DIAGNOSIS_REPORTS');
export const CREATE_DIAGNOSIS_PREVIEW_REPORT = getName('CREATE_DIAGNOSIS_PREVIEW_REPORT');
export const WITHDRAW_DIAGNOSIS = getName('WITHDRAW_DIAGNOSIS');
export const TAKE_OVER_DIAGNOSIS = getName('TAKE_OVER_DIAGNOSIS');
