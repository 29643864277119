import styled from 'styled-components';

export const Container = styled.button`
  width: 100%;
  min-height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: var(--border-radius8);
  background: none;
  &:hover {
    background-color: var(--color-greyF8);
  }
`;

export const Item = styled.div`
  width: ${({ width }) => `calc(${width} - 10px)`};
  font-size: var(--font-size20);
  color: var(--color-grey6A);
  text-align: left;
`;
