/* eslint-disable no-param-reassign */
import { path, mergeRight, pick } from 'ramda';

import {
  RIGHT,
  LEFT,
  VISUAL_ACUITY,
  WITHOUT_CORRECTION,
  WITH_GLASSES,
  BEST_CORRECTION,
  PUPIL_SIZE,
  SPHERE,
  CYLINDER,
  AXIS,
  CONFIDENCE,
} from './consts';
import { FILE } from '.';

const filterField = pick([
  WITHOUT_CORRECTION,
  WITH_GLASSES,
  BEST_CORRECTION,
  PUPIL_SIZE,
  SPHERE,
  CYLINDER,
  AXIS,
  CONFIDENCE,
  FILE,
]);

export const getExaminationFromXml = (currentExam, xmlData) =>
  [RIGHT, LEFT].reduce(
    (init, key) => {
      init[key][VISUAL_ACUITY] = mergeRight(path([key, VISUAL_ACUITY], init), filterField(xmlData[key]));

      return init;
    },
    { ...currentExam }
  );
