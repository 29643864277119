import styled from 'styled-components';

import arrow from './arrow.svg';

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 14px;
  position: absolute;
  top: calc((100% - 14px) / 2);
  left: 0;
  pointer-events: none;
`;

export const Select = styled.select`
  width: 100%;
  height: var(--font-size18);
  border: none;
  background-color: transparent;
  background-image: url(${arrow});
  background-position: right;
  background-repeat: no-repeat;
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
  font-size: var(--font-size18);
`;
