import styled from 'styled-components';

export const CheckBoxContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const Label = styled.p`
  min-width: 200px;
  max-width: 300px;
  margin-right: 40px;
  font-size: var(--font-size15);
  color: var(--color-grey6A);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const CheckboxWrap = styled.div`
  width: 50%;
`;
