import { hasPath, pipe, path, filter } from 'ramda';

const UPLOAD_FILES_CACHE = 'upload-files';
const FILES_MAP_CACHE = 'files-map';
const BUGSNAG_CACHE = 'bugsnag';
const eventsKey = Symbol('events');

export const getCache = (() => {
  const caches = {};
  const cacheMiddleware = {};

  const onChange = (name) => {
    if (!hasPath([name, eventsKey], cacheMiddleware)) return;

    cacheMiddleware[name][eventsKey].forEach((cb) => cb(cacheMiddleware[name]));
  };
  const addEvent = (name, event) => {
    if (!hasPath([name, eventsKey], cacheMiddleware)) return;

    cacheMiddleware[name][eventsKey].push(event);
  };
  const removeEvent = (name, event) => {
    if (!hasPath([name, eventsKey], cacheMiddleware)) return;

    cacheMiddleware[name][eventsKey] = pipe(
      path([name, eventsKey]),
      filter((e) => e !== event)
    )(cacheMiddleware);
  };
  const viaEventsBus =
    (fn, name) =>
    async (...args) => {
      const data = await fn(...args);
      onChange(name);

      return data;
    };

  return async (name) => {
    if (cacheMiddleware[name]) return cacheMiddleware[name];

    caches[name] = await (window.caches && window.caches.open(name));
    const $ = caches[name];

    cacheMiddleware[name] = {
      add: viaEventsBus($.add.bind($), name),
      addAll: viaEventsBus($.addAll.bind($), name),
      delete: viaEventsBus($.delete.bind($), name),
      keys: $.keys.bind($),
      match: $.match.bind($),
      matchAll: $.matchAll.bind($),
      put: viaEventsBus($.put.bind($), name),
      addEvent,
      removeEvent,
      [eventsKey]: [],
    };

    return cacheMiddleware[name];
  };
})();

export { UPLOAD_FILES_CACHE, FILES_MAP_CACHE, BUGSNAG_CACHE };
