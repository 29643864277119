import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { highlightMap } from './utils';
import { TabsContainer, TabContainer, TabConnector, Button } from './styles';

const Navigation = ({ tabs, active, small }) => {
  const { push, location } = useHistory();
  const { hash } = location;
  const handleClick = useCallback(
    (e) => {
      const { path } = e.currentTarget?.dataset || {};
      if (path) {
        push(path, location.state);
      }
    },
    [push, location.state]
  );

  return (
    <TabsContainer small={small}>
      {tabs.map(({ id, path, label }, tabIndex) => (
        <TabContainer key={id}>
          <Button
            data-path={path}
            disabled={active === id}
            onClick={handleClick}
            highlight={highlightMap[id] === hash}
            small={small}
          >
            {label}
          </Button>
          {tabIndex !== tabs.length - 1 && <TabConnector />}
        </TabContainer>
      ))}
    </TabsContainer>
  );
};

Navigation.defaultProps = {
  active: '',
  small: false,
};

Navigation.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  active: PropTypes.string,
  small: PropTypes.bool,
};

export default Navigation;
