import { cond, test, equals, T } from 'ramda';

import { NURSING_HOME_KINDS, GENDER, PATIENT_NAME, NURSING_HOME_KIND } from './consts';

export const defineNursingHomeDataByName = cond([
  ...NURSING_HOME_KINDS.map(({ id, label, gender, patientName }) => [
    test(new RegExp(label, 'i')),
    () => ({ [GENDER]: gender, [PATIENT_NAME]: patientName, [NURSING_HOME_KIND]: id }),
  ]),
  [T, () => ({})],
]);

export const defineNursingHomeDataByKind = cond([
  ...NURSING_HOME_KINDS.map(({ id, gender, patientName }) => [
    equals(id),
    () => ({ [GENDER]: gender, [PATIENT_NAME]: patientName }),
  ]),
  [T, () => ({})],
]);
