export const defineLimits = (wrapper, image) => {
  if (!(wrapper && image)) return null;
  if (wrapper.height >= image.height && wrapper.width >= image.width) return null;

  const up = Math.round(wrapper.bottom - image.bottom);
  const down = Math.round(wrapper.top - image.top);
  const left = Math.round(wrapper.right - image.right);
  const right = Math.round(wrapper.left - image.left);

  return {
    x: [left < 0 ? left : 0, right > 0 ? right : 0],
    y: [up < 0 ? up : 0, down > 0 ? down : 0],
  };
};
