import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_MEDICAL_HISTORY = getName('UPDATE_MEDICAL_HISTORY');
export const RESET_MEDICAL_HISTORY = getName('RESET_MEDICAL_HISTORY');

export const FETCH_MEDICAL_HISTORY = getName('FETCH_MEDICAL_HISTORY');
export const FETCH_COLLECT_MEDICAL_HISTORY = getName('FETCH_COLLECT_MEDICAL_HISTORY');
export const SAVE_MEDICAL_HISTORY = getName('SAVE_MEDICAL_HISTORY');
export const UPLOAD_MEDIA_DATA = getName('UPLOAD_MEDIA_DATA');
export const FETCH_SEARCHABLE_MEDIA = getName('FETCH_SEARCHABLE_MEDIA');
export const UPDATE_SEARCHABLE_MEDIA = getName('UPDATE_SEARCHABLE_MEDIA');
export const DELETE_SEARCHABLE_MEDIA = getName('DELETE_SEARCHABLE_MEDIA');
