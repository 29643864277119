import { takeLatest, takeEvery, call, put, throttle, select, takeLeading } from 'redux-saga/effects';
import { when, is, prop } from 'ramda';

import { withAlert } from 'store/alerts';
import { STATUS, EXAMINATION_STATUS } from 'store/examinations';
import { saveExamination } from 'store/examinations/actions';
import { getUserProp } from 'store/session/selectors';
import api from 'api';

import { ID, SUB_EXAMINATION, APPROVALS, SUB_USER, SUB_PHYSICIAN } from '.';
import { getCurrentDiagnosisProp } from './selectors';
import {
  updateDiagnosisCodes,
  updateDiagnosisReports,
  updateDiagnoses,
  updateDiagnosis,
  updateDiagnosisPreviewReport,
} from './actions';
import {
  FETCH_DIAGNOSIS_CODES,
  FETCH_DIAGNOSES,
  FETCH_DIAGNOSES_FOR_EXAMINATION,
  SAVE_DIAGNOSIS,
  CREATE_DIAGNOSIS_REPORTS,
  CREATE_DIAGNOSIS_PREVIEW_REPORT,
  WITHDRAW_DIAGNOSIS,
  TAKE_OVER_DIAGNOSIS,
} from './types';

function* fetchDiagnosisCodes() {
  const { data } = yield call(api.getDiagnosisCodes);
  yield put(updateDiagnosisCodes(data));
}

function* fetchDiagnosisReports(diagnosisId) {
  const { data } = yield call(api.getDiagnosisReports, diagnosisId);
  yield put(updateDiagnosisReports(data));
}

function* fetchDiagnoses({ payload }) {
  const diagnoses = yield call(api.getExaminationDiagnosis, payload);
  yield put(updateDiagnoses(diagnoses));
  const diagnosisId = yield select(getCurrentDiagnosisProp(ID));
  yield call(fetchDiagnosisReports, diagnosisId);
}

function* fetchDiagnosesForExamination({ payload }) {
  return { success: yield call(api.getExaminationDiagnosis, payload) };
}

function* postDiagnosisReports({ payload }) {
  const { data } = yield call(api.postDiagnosisReports, payload);
  yield put(updateDiagnosisReports(data));
}

function* saveDiagnosis({ payload }) {
  const data = yield call(
    api.patchDiagnosis,
    payload[APPROVALS]
      ? {
          ...payload,
          [APPROVALS]: payload[APPROVALS].map((approval) => ({
            ...approval,
            [SUB_USER]: when(is(Object), prop(ID))(approval[SUB_USER]),
          })),
        }
      : payload
  );
  yield put(updateDiagnosis(data));
}

function* takeOverDiagnosis() {
  const userId = yield select(getUserProp(ID));
  const diagnosisId = yield select(getCurrentDiagnosisProp(ID));

  yield saveDiagnosis({ payload: { diagnosisId, [SUB_PHYSICIAN]: userId } });
}

function* createDiagnosisPreviewReport({ payload }) {
  const data = yield call(api.getDiagnosisReportPreview, payload);
  yield put(updateDiagnosisPreviewReport(data));
}

function* withdrawDiagnosis({ payload }) {
  const diagnosis = yield call(api.postDiagnosisWithdraw, payload);
  const { data } = yield call(api.getDiagnosisReports, payload);

  yield put(saveExamination({ [ID]: diagnosis[SUB_EXAMINATION], [STATUS]: EXAMINATION_STATUS.UNDER_REVIEW }));
  yield put(updateDiagnosis(diagnosis));
  yield put(updateDiagnosisReports(data));
}

export default function* watchDiagnoses() {
  yield takeLatest(FETCH_DIAGNOSIS_CODES, withAlert(fetchDiagnosisCodes));
  yield throttle(500, SAVE_DIAGNOSIS, withAlert(saveDiagnosis));
  yield takeLatest(FETCH_DIAGNOSES, withAlert(fetchDiagnoses));
  yield takeLatest(FETCH_DIAGNOSES_FOR_EXAMINATION, withAlert(fetchDiagnosesForExamination));
  yield takeEvery(CREATE_DIAGNOSIS_REPORTS, withAlert(postDiagnosisReports));
  yield takeLatest(CREATE_DIAGNOSIS_PREVIEW_REPORT, withAlert(createDiagnosisPreviewReport));
  yield takeEvery(WITHDRAW_DIAGNOSIS, withAlert(withdrawDiagnosis));
  yield takeLeading(TAKE_OVER_DIAGNOSIS, withAlert(takeOverDiagnosis));
}
