import React, { useCallback } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import { NO_REPORT } from 'store/patients';
import Checkbox from 'components/CheckboxNew';

const PatientReport = ({ readMode }) => {
  const [{ value }, , { setValue }] = useField(NO_REPORT);

  const handleClick = useCallback(() => {
    if (!readMode) setValue(!value);
  }, [readMode, setValue, value]);

  return <Checkbox label="Keinen Bericht an Patient senden" selected={value} onClick={handleClick} readMode={readMode} />;
};

PatientReport.propTypes = {
  readMode: PropTypes.bool.isRequired,
};

export default PatientReport;
