import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Input } from './styles';

const TextInput = ({ open, ...rest }) => {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current || !open) return;

    const input = ref.current.querySelector('input');

    if (input) {
      input.focus();
    }
  }, [open]);

  return (
    <div ref={ref}>
      <Input name="sender" placeholder="Ihre E-Mail-Adresse" {...rest} />
    </div>
  );
};

TextInput.defaultProps = {
  open: false,
};

TextInput.propTypes = {
  open: PropTypes.bool,
};

export default TextInput;
