import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { equals, pick } from 'ramda';

import Text from 'components/TextAreaNew';

const TextArea = ({ name, setFieldValue, readMode, ...rest }) => {
  const handleChange = useCallback(
    (text) => {
      if (readMode) return;

      setFieldValue(name, text);
    },
    [readMode, setFieldValue, name]
  );

  return <Text onChangeText={handleChange} readMode={readMode} {...rest} />;
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  readMode: PropTypes.bool.isRequired,
};

export default memo(TextArea, (prev, next) => {
  const props = ['value', 'readMode'];

  return equals(pick(props, prev), pick(props, next));
});
