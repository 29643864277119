import React, { useCallback, useEffect } from 'react';
import { omit } from 'ramda';

import { useSessionActions, useAlerts } from 'store/session';
import { useAsyncState } from 'utils/useAsyncState';
import { guidelines } from 'pages/SetPassword/utils';
import Input from 'components/TextInputNew';
import Button from 'components/Button';
import Spinner from 'components/FullScreenSpinner';
import Message from 'components/Message';

import { validationSchema } from './utils';
import { Title, Ul, Li, Span, Form } from './styles';

const Psd = () => {
  const [psd, setPsd] = useAsyncState('');
  const [nextPsd, setNextPsd] = useAsyncState('');
  const [errors, setErrors] = useAsyncState({});
  const { updatePassword } = useSessionActions();
  const { action, loading, success, error } = useAlerts(updatePassword);
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { currentPassword, nextPassword } = e.currentTarget;

      try {
        const values = await validationSchema.validate(
          { currentPassword: currentPassword.value, nextPassword: nextPassword.value },
          { abortEarly: false }
        );

        action(values);
      } catch (yupErrors) {
        setErrors(
          yupErrors.inner.reduce((acc, { path, message }) => {
            acc[path] = message;

            return acc;
          }, {})
        );
      }
    },
    [action, setErrors]
  );
  const onChange = useCallback(({ target }) => setErrors(($) => ($[target.name] ? omit([target.name], $) : $)), [setErrors]);

  useEffect(() => {
    if (success) {
      setPsd('');
      setNextPsd('');
    }
  }, [setNextPsd, setPsd, success]);

  return (
    <>
      <Form onSubmit={onSubmit} onChange={onChange}>
        <Input
          type="password"
          name="currentPassword"
          label="Aktuelles Passwort"
          value={psd}
          handleChangeText={setPsd}
          disabled={loading}
          error={errors.currentPassword}
        />
        <Input
          type="password"
          name="nextPassword"
          label="Neues Passwort"
          value={nextPsd}
          handleChangeText={setNextPsd}
          disabled={loading}
          error={errors.nextPassword}
        />
        <Button type="submit" color="normal" height="40px" mt="15" disabled={loading}>
          {loading ? <Spinner size={30} height="30px" /> : 'Ändern'}
        </Button>
        {(success || error) && <Message type={error ? 'error' : 'success'}>{error || 'Password erfolgreich geändert.'}</Message>}
      </Form>
      <Title>Augenmobil-Richtlinien für sichere Passwörter</Title>
      <Ul>
        {guidelines.map(({ icon: Icon, text }) => (
          <Li key={text}>
            <Icon />
            <Span>{text}</Span>
          </Li>
        ))}
      </Ul>
    </>
  );
};

export default Psd;
