import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
  position: relative;
`;

export const Label = styled.label`
  width: 200px;
  margin-right: 40px;
  text-align: left;
  color: var(--color-grey6A);
  font-weight: var(--font-weight400);
  font-size: var(--font-size15);
`;

export const Text = styled.span`
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 5px 80px 5px 25px;
  background-color: var(--color-greyEC);
  border-radius: var(--border-radius8);
  color: var(--color-grey6A);
  font-weight: var(--font-weight400);
  font-size: var(--font-size18);
  white-space: pre-line;
`;

export const Button = styled.button`
  background: transparent;
  border: none;
  font-size: var(--font-size10);
  font-weight: var(--font-weight600);
  color: var(--color-grey6A);
  position: absolute;
  right: 25px;
  cursor: pointer;
  text-transform: uppercase;

  &:focus {
    outline: 0;
  }
`;
