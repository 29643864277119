import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  font-size: var(--font-size15);
  color: var(--color-blue5D);
  letter-spacing: 0.33px;

  &:hover {
    color: var(--color-blue8E);
  }

  &:active {
    color: var(--color-blue2E);
  }
`;

const CustomLink = ({ name, to }) => <StyledLink to={to}>{name}</StyledLink>;

CustomLink.propTypes = {
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default CustomLink;
