import styled from 'styled-components';

import Btn from 'components/Button';
import Warning from 'components/Warning';

export const Container = styled.div`
  margin-bottom: 20px;
  border-radius: var(--border-radius8);
  overflow: hidden;
  &:nth-child(2) {
    margin-top: 40px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  background-color: var(--color-grey6A);
  padding: 0 20px;
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const Text = styled.p`
  font-size: var(--font-size18);
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: var(--color-white);
  margin: 0 ${({ mx }) => mx || 0};
`;

export const Button = styled(Btn)`
  height: 30px;
  width: 80px;
  font-size: var(--font-size11);
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: var(--color-greyF3);
  padding: 16px;
`;

export const TasksContainer = styled.div`
  width: 100%;
`;

export const Warn = styled(Warning)`
  height: auto;
  width: calc(100% - 7px);
  margin-top: 15px;
  & p {
    text-align: left;
  }
`;
