import React, { useMemo, useCallback, useEffect } from 'react';
import { update } from 'ramda';

import {
  useAnalyticsActions,
  useAlerts,
  useAnalyticsParams,
  AXIS_OPTIONS,
  LABELS,
  Z_AXIS_OPTIONS,
  CANTON,
  NURSING_HOME,
} from 'store/analytics';
import { useAsyncState } from 'utils/useAsyncState';
import Spinner from 'components/FullScreenSpinner';

import BubbleChart from './BubbleChart';
import { SelectX, SelectY, SelectZ } from './styles';

const Insights = () => {
  const [{ list, organizations, cantons }, setList] = useAsyncState({});
  const params = useAnalyticsParams();
  const { updateParams, getAnalytics } = useAnalyticsActions();
  const { action, loading, success } = useAlerts(getAnalytics);
  const [xOptions, yOptions] = useMemo(
    () =>
      AXIS_OPTIONS.reduce(
        (acc, item) => {
          acc[0].push({ ...item, disabled: item.id === params[1] });
          acc[1].push({ ...item, disabled: item.id === params[0] });

          return acc;
        },
        [[], []]
      ),
    [params]
  );
  const onSelectX = useCallback((val) => updateParams(update(0, val, params)), [params, updateParams]);
  const onSelectY = useCallback((val) => updateParams(update(1, val, params)), [params, updateParams]);
  const onSelectZ = useCallback((val) => updateParams(update(2, val, params)), [params, updateParams]);

  useEffect(() => {
    action();
  }, [action, params]);

  useEffect(() => {
    if (success) setList(success);
  }, [setList, success]);

  if (!list) return <Spinner />;

  return (
    <BubbleChart
      list={list}
      organizations={organizations}
      cantons={cantons}
      selectX={<SelectX value={params[0]} options={xOptions} disabled={loading} onSelect={onSelectX} />}
      selectY={<SelectY value={params[1]} options={yOptions} disabled={loading} onSelect={onSelectY} />}
      selectZ={<SelectZ value={params[2]} options={Z_AXIS_OPTIONS} disabled={loading} onSelect={onSelectZ} />}
      groupLabel={LABELS[CANTON]}
      nameLabel={LABELS[NURSING_HOME]}
      xAxisLabel={LABELS[params[0]]}
      yAxisLabel={LABELS[params[1]]}
      zAxisLabel={LABELS[params[2]]}
      loading={loading}
    />
  );
};

export default Insights;
