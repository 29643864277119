import { useMemo } from 'react';
import { useField } from 'formik';
import { values, pipe, prop, filter, complement, isEmpty } from 'ramda';

import { DIAGNOSIS, RIGHT, LEFT, DIABETES } from 'store/diagnoses';

const checkSide = (side, value) => pipe(prop(side), values, filter(Boolean), complement(isEmpty))(value);

export const useInitActiveSides = () => {
  const [{ value }] = useField(DIAGNOSIS);
  const isDiabetes = Boolean(value[LEFT][DIABETES]);
  const hasDiseaseForRightEye = useMemo(() => (checkSide(RIGHT, value) ? RIGHT : ''), [value]);
  const hasDiseaseForLeftEye = useMemo(() => (checkSide(LEFT, value) ? LEFT : ''), [value]);

  return useMemo(
    () => (isDiabetes ? [RIGHT, LEFT] : [hasDiseaseForRightEye, hasDiseaseForLeftEye]),
    [isDiabetes, hasDiseaseForRightEye, hasDiseaseForLeftEye]
  );
};
