import { pathOr, filter, applySpec, propOr, ifElse, pipe } from 'ramda';

import {
  VIEW,
  FUNDUS,
  PRESSURE,
  VISUAL_ACUITY,
  WITHOUT_CORRECTION,
  WITH_GLASSES,
  BEST_CORRECTION,
  PUPIL_SIZE,
  SPHERE,
  CYLINDER,
  AXIS,
  CONFIDENCE,
  FILES,
  RIGHT,
  LEFT,
  COMMENT,
  DATE,
  MOBILITY,
  FILE,
} from 'store/examinations';

const generateValues = (prop) => ({
  [VIEW]: prop[VIEW] || { [FILES]: [] },
  [FUNDUS]: prop[FUNDUS] || { [FILES]: [] },
  [PRESSURE]: filter((val) => val !== null, prop[PRESSURE] || []),
  [VISUAL_ACUITY]: {
    [WITHOUT_CORRECTION]: pathOr(null, [VISUAL_ACUITY, WITHOUT_CORRECTION], prop),
    [WITH_GLASSES]: pathOr(null, [VISUAL_ACUITY, WITH_GLASSES], prop),
    [BEST_CORRECTION]: pathOr(null, [VISUAL_ACUITY, BEST_CORRECTION], prop),
    [PUPIL_SIZE]: pathOr(null, [VISUAL_ACUITY, PUPIL_SIZE], prop),
    [SPHERE]: pathOr(null, [VISUAL_ACUITY, SPHERE], prop),
    [CYLINDER]: pathOr(null, [VISUAL_ACUITY, CYLINDER], prop),
    [AXIS]: pathOr(null, [VISUAL_ACUITY, AXIS], prop),
    [CONFIDENCE]: pathOr(null, [VISUAL_ACUITY, CONFIDENCE], prop),
    [FILE]: pathOr(null, [VISUAL_ACUITY, FILE], prop),
  },
});

export const mapPropsToValues = ({ right, left, date, comment, mobility }) => ({
  [RIGHT]: generateValues(right || {}),
  [LEFT]: generateValues(left || {}),
  [DATE]: new Date(date),
  [COMMENT]: comment || '',
  [MOBILITY]: mobility,
});

const handleInputField = (field) =>
  pipe(
    propOr(null, field),
    ifElse(
      (value) => (value || value === 0) && !isNaN(Number(value)),
      (value) => (field === CONFIDENCE ? Math.round(Number(value) * 10) / 10 : value),
      () => null
    )
  );
const handleVisualAcuity = (values) => ({
  ...values,
  [PRESSURE]: filter((val) => Boolean(val) || val === 0, values[PRESSURE] || []),
  [VISUAL_ACUITY]:
    values[VISUAL_ACUITY] &&
    applySpec({
      [WITHOUT_CORRECTION]: propOr(null, WITHOUT_CORRECTION),
      [WITH_GLASSES]: propOr(null, WITH_GLASSES),
      [BEST_CORRECTION]: propOr(null, BEST_CORRECTION),
      [PUPIL_SIZE]: handleInputField(PUPIL_SIZE),
      [SPHERE]: handleInputField(SPHERE),
      [CYLINDER]: handleInputField(CYLINDER),
      [AXIS]: handleInputField(AXIS),
      [CONFIDENCE]: handleInputField(CONFIDENCE),
      [FILE]: propOr(null, FILE),
    })(values[VISUAL_ACUITY]),
});

export const filterRequestData = (requestData) =>
  Object.entries(requestData).reduce((acc, [key, value]) => {
    if (key === LEFT || key === RIGHT) {
      acc[key] = handleVisualAcuity(value);

      return acc;
    }

    acc[key] = value;

    return acc;
  }, {});
