import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useInitPatientReports } from 'store/patients';
import CloseIcon from 'assets/svg-icons/Close';
import Spinner from 'components/FullScreenSpinner';
import Download from 'assets/svg-icons/Download';

import { useTogglePanel } from './hooks';
import { handleItems } from './utils';
import { Container, TitleWrap, Button, Title, Content, Message, Item, Text, DownloadWrap } from './styles';

const PatientReports = () => {
  const { id } = useParams();
  const [isOpen, handleClose] = useTogglePanel();
  const { loading, reports = [] } = useInitPatientReports(isOpen ? id : null);
  const items = useMemo(() => handleItems(reports), [reports]);

  return (
    <Container isOpen={isOpen}>
      <TitleWrap>
        <Button type="button" onClick={handleClose}>
          <CloseIcon />
        </Button>
        <Title>Berichte</Title>
      </TitleWrap>
      <Content>
        {loading && <Spinner height="30px" />}
        {Boolean(items.length) &&
          items.map(({ key, date, url }) => (
            <Item key={key}>
              <Text>{`Bericht vom ${date}`}</Text>
              <DownloadWrap href={url} download target="_blank" rel="noopener noreferrer">
                <Download />
              </DownloadWrap>
            </Item>
          ))}
        {!(items.length || loading) && (
          <Message type="error">
            Sobald Berichte für diesen Patienten erstellt wurden, erscheinen diese hier chronologisch sortiert.
          </Message>
        )}
      </Content>
    </Container>
  );
};

export default PatientReports;
