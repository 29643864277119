import styled from 'styled-components';

import WarningMessage from 'components/Warning';
import Msg from 'components/Message';

export const EyeSelectorSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
`;

export const EyeSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: ${({ isActive }) => (isActive ? '1.5px solid var(--color-blue50)' : `1px solid var(--color-grey97)`)};
  border-radius: var(--border-radius8);
  width: calc(50% - 6px);
  background: ${({ isActive }) => (isActive ? 'rgba(173, 248, 231, 0.11)' : 'rgba(174, 174, 174, 0.11)')};
  color: ${({ isActive }) => (isActive ? 'var(--color-blue50)' : 'var(--color-grey6A)')};
  padding: 60px;
  padding-top: 30px;
  transition: all 0.3s;
`;

export const EyeLabel = styled.p`
  font-size: var(--font-size27);
  line-height: 0.81;
  color: inherit;
`;

export const Button = styled.button`
  height: 60px;
  width: 100%;
  background: none;
  color: #4a8d7e;
  border: ${({ isActive }) => `1px solid ${isActive ? 'var(--color-blue50)' : 'var(--color-grey97)'}`};
  border-radius: var(--border-radius8);
  color: var(--color-grey6A);
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  cursor: pointer;
  margin-bottom: 20px;
  outline: none;
  letter-spacing: 0.92px;

  &:focus {
    box-shadow: var(--shadow-focus);
  }
  &:hover {
    background: var(--color-greyEC);
  }
  &:active {
    background: var(--color-green50);
  }
  &:disabled {
    background: #b8f4e6;
  }
  text-transform: uppercase;
  transition: all 0.3s;
`;

export const AnalyzeImagesButton = styled(Button)`
  height: 80px;
  color: var(--color-grey6A);
  border: 1px solid var(--color-grey97);
  &:focus {
    box-shadow: var(--shadow-focus);
  }
  &:hover {
    background: var(--color-greyEC);
  }
  &:active {
    background: #c5c5c5;
  }
  &:disabled {
    border: 1px solid var(--color-greyEC);
    background: var(--color-greyEC);
    color: rgba(106, 106, 106, 0.37);
  }
  margin: 0;
  transition: all 0.3s;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ isFirst }) => (isFirst ? 0 : 20)}px;
  padding-top: 10px;
  border-top: 1px solid var(--color-grey97);
  padding-bottom: ${({ pb }) => pb || 0}px;
`;

export const SectionLabel = styled.label`
  align-self: flex-start;
  text-align: left;
  color: var(--color-grey6A);
  font-size: var(--font-size15);
  font-weight: var(--font-weight600);
  margin-bottom: 30px;
`;

export const OverviewContainer = styled.div``;

export const MultipleQuestionLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-grey97);
  margin-bottom: 15px;
`;

export const MultipleQuestionLabel = styled.label`
  width: 16.67%;
  margin-bottom: 10px;
  color: var(--color-grey6A);
  font-size: var(--font-size15);
  font-weight: var(--font-weight600);
  text-align: ${({ leftAlign }) => (leftAlign ? 'left' : 'center')};
`;

export const MultipleQuestionContainer = styled.div`
  flex: 1;
  border-bottom: ${({ isLast }) => (isLast ? 'none' : '1px solid var(--color-grey97)')};
  padding-bottom: 15px;
  margin-bottom: 15px;

  .option-button {
    width: 16.67%;
    margin-left: 10px;
    & > button {
      height: 100px;
    }
  }
`;

export const EmptyOption = styled.div`
  width: 16.67%;
  margin-left: 10px;
`;

export const MultipleCheckQuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MultipleCheckQuestionColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const EyeIconContainer = styled.div`
  height: 160px;
  margin: 60px 0px;

  svg {
    height: 100%;
  }
`;

export const Message = styled(Msg)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
`;

export const Warning = styled(WarningMessage)`
  margin-bottom: 30px;
`;

export const P = styled.span`
  margin-top: ${({ mt }) => mt || 0};
`;
