import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: rgba(245, 35, 35, 0.25);
`;

const text = `
  font-size: var(--font-size13);
  font-weight: var(--font-weight600);
  line-height: 1.15;
  letter-spacing: 0.9px;
  color: var(--color-red);
  text-transform: uppercase;
`;

export const P = styled.p`
  ${text}
`;

export const StyledLink = styled(Link)`
  ${text}
  font-weight: var(--font-weight500);
  color: var(--color-redED);
  &:hover {
    color: #ff7878;
  }
  &:active {
    color: #bf1d1d;
  }
  margin-left: 5px;
`;
