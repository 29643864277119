import React from 'react';
import PropTypes from 'prop-types';

import { Container, Label } from './styles';

const ItemContainer = ({ label, children }) => (
  <Container>
    <Label>{label}</Label>
    {children}
  </Container>
);

ItemContainer.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ItemContainer;
