import styled from 'styled-components';

export const Container = styled.div`
  border-top: 1px solid var(--color-grey97);
  margin: 40px 0;
`;

export const Label = styled.div`
  color: var(--color-grey6A);
  font-size: var(--font-size18);
  font-weight: var(--font-weight400);
  line-height: 1.22;
  letter-spacing: 0.5px;
  text-align: left;
  margin: 18px 0;
`;
