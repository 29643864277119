import styled from 'styled-components';
import { Link } from 'react-router-dom';

import MessageDef from 'components/Message';
import profilePhoto from 'assets/img/profilePhoto.png';

export const NavLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyF8);
  text-decoration: none;
  padding: 5px 10px;
  margin-bottom: 15px;
  @media (hover) {
    &:hover {
      background-color: var(--color-greyEC);
    }
  }
`;

export const LinkLabel = styled.span`
  font-size: var(--font-size18);
  color: var(--color-grey6A);
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  max-height: 450px;
  gap: 45px 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  overflow: auto;
`;

export const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyF3);
  text-decoration: none;
  padding: 25px 5px;
  &:before {
    content: '';
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-image: url(${profilePhoto});
    background-color: var(--color-grey97);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
`;

export const Text = styled.p`
  font-size: var(--font-size13);
  font-weight: var(--font-weight400);
  letter-spacing: 0.92px;
  color: var(--color-black);
  word-break: break-all;
  text-align: center;
  &:first-of-type {
    margin-top: 20px;
    font-weight: var(--font-weight500);
  }
`;

export const EmptyMessage = styled(MessageDef)`
  grid-column: 1/-1;
  text-align: center;
`;
