import styled from 'styled-components';

export const Button = styled.button`
  width: ${({ width }) => `calc(${width} - 10px)`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: ${({ withoutSort }) => (withoutSort ? 'auto' : 'pointer')};
  outline: none;
  border: none;
  background: none;
  border-top: 1px solid var(--color-grey6A);
  padding-top: 2px;
`;

export const Content = styled.div`
  font-size: var(--font-size14);
  color: var(--color-grey6A);
  text-transform: uppercase;
`;

export const Icon = styled.div`
  display: flex;
`;
