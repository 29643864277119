import styled from 'styled-components';

const thumbStyle = `
  border: solid 1px rgba(167, 167, 167, 0.71);
  height: 28px;
  width: 28px;
  border-radius: 14px;
  background: var(--color-white);
  cursor: pointer;
  margin-top: -13px;
  &:active {
    box-shadow: var(--shadow-focus);
    cursor: ew-resize;
  }
`;

const getTrackStyle = ({ value, max }) => {
  const rate = (value / max) * 100;

  return `
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: linear-gradient(90deg, var(--color-green65) 0%, var(--color-green65) ${rate}%, var(--color-blueC7) ${rate}%, var(--color-blueC7) 100%);
    border-radius: 1px;
  `;
};

export default styled.input`
  width: 100%;
  -webkit-appearance: none;
  background: transparent;
  outline: none;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    ${thumbStyle}
  }
  &::-moz-range-thumb {
    ${thumbStyle}
  }
  &::-webkit-slider-runnable-track {
    ${getTrackStyle}
  }
  &::-moz-range-track {
    ${getTrackStyle}
  }
  &:disabled {
    &::-webkit-slider-thumb {
      background: var(--color-greyEC);
    }
    &::-moz-range-thumb {
      background: var(--color-greyEC);
    }
    &::-webkit-slider-runnable-track {
      background: var(--color-blueC7);
    }
    &::-moz-range-track {
      background: var(--color-blueC7);
    }
  }
`;
