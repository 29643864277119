import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  column-gap: 40px;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const Label = styled.p`
  font-size: var(--font-size15);
  font-weight: var(--font-weight400);
  color: var(--color-grey6A);
`;
