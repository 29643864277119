import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import { Text } from '../styles';

import { Container, Button } from './styles';

const SelectedItem = ({ item, nameKey, onDelete, disabled }) => {
  const name = useMemo(() => (typeof nameKey === 'function' ? nameKey(item) : item[nameKey]) || '-', [item, nameKey]);
  const handleDelete = useCallback(() => onDelete(item), [onDelete, item]);

  return (
    <Container>
      <Text>{name}</Text>
      {!disabled && (
        <Button type="button" onClick={handleDelete}>
          <Icon />
        </Button>
      )}
    </Container>
  );
};

SelectedItem.defaultProps = {
  disabled: false,
};
SelectedItem.propTypes = {
  item: PropTypes.shape({}).isRequired,
  nameKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SelectedItem;
