import React, { useMemo, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { map } from 'ramda';

import Table from 'components/Table';
import { useInitReports, useReportsActions, CURSOR, LIMIT, HAS_MORE, COUNT, ALL } from 'store/reports';
import { REPORT, usePermissions } from 'permissions';

import Row from './Row';
import { statuses, tableHead, handleFields } from './utils';
import { Container, SearchPanel } from './styles';

const Reports = () => {
  const can = usePermissions();
  const { list: reports, search, status, sort, pagination, loading } = useInitReports(can.read(REPORT));

  const list = useMemo(() => map(handleFields, reports), [reports]);
  const { updateStatus, updateSearch, updatePagination, updateSort } = useReportsActions();
  const setStatus = useCallback((val) => updateStatus(val === ALL ? ALL : Number(val)), [updateStatus]);

  const renderRow = useCallback((data) => <Row {...data} />, []);

  return (
    <Container>
      <SearchPanel
        selectValue={String(status)}
        options={statuses}
        onSelect={setStatus}
        value={search}
        onChange={updateSearch}
        placeholder="Bericht suchen"
      />
      <Table
        title="Berichte"
        head={tableHead}
        list={list}
        renderRow={renderRow}
        sort={sort}
        cursor={pagination[CURSOR]}
        count={pagination[COUNT]}
        limit={pagination[LIMIT]}
        hasMore={pagination[HAS_MORE]}
        updateSort={updateSort}
        updatePagination={updatePagination}
        loading={loading}
      />
    </Container>
  );
};

export default withRouter(Reports);
