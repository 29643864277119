import React, { useMemo, useEffect } from 'react';
import { useField } from 'formik';
import { propOr } from 'ramda';
import PropTypes from 'prop-types';

import { ID, ORGANIZATION_KINDS, useAlerts, useCollectOrganizationsAction, useOrganizationActions } from 'store/organizations';
import { SUB_EYE_DOCTOR, SUB_GP, SUB_ORGANIZATION } from 'store/planning';
import PaginationDropDown from 'components/PaginationDropDown';
import Spinner from 'components/FullScreenSpinner';
import SelectedOrganization from 'components/SelectedOrganization';

import { featureMap } from './utils';
import { Message } from './styles';

const Clinic = ({ field, disabled, emptyMessage }) => {
  const [nameKey, params, handleClinicName, placeholder] = useMemo(() => featureMap[field], [field]);
  const getOrganizations = useCollectOrganizationsAction(ORGANIZATION_KINDS.CLINIC, params);
  const { fetchCollectOrganization } = useOrganizationActions();
  const { action, loading, success, resetAlerts } = useAlerts(fetchCollectOrganization);
  const [{ value: clinic }, , { setValue: setClinic }] = useField(field);
  const orgId = useMemo(() => propOr(null, SUB_ORGANIZATION, clinic), [clinic]);

  const clinicThroughFiler = useMemo(() => {
    if (!clinic || typeof clinic !== 'object') return null;
    if (clinic[nameKey]) return clinic;

    return handleClinicName([clinic])[0];
  }, [handleClinicName, clinic, nameKey]);

  useEffect(() => {
    if (orgId) action(orgId);
  }, [action, orgId]);

  useEffect(() => {
    if (success) {
      setClinic(success, false);
      resetAlerts();
    }
  }, [resetAlerts, setClinic, success]);

  return loading ? (
    <Spinner height="100px" />
  ) : (
    <>
      <PaginationDropDown
        idKey={ID}
        nameKey={nameKey}
        action={getOrganizations}
        placeholder={placeholder}
        value={clinicThroughFiler}
        onChange={setClinic}
        applyFilter={handleClinicName}
        selectedComponent={SelectedOrganization}
        disabled={disabled}
        withoutRemoving
        onlyOne
      />
      {disabled && !clinicThroughFiler && <Message>{emptyMessage}</Message>}
    </>
  );
};

Clinic.defaultProps = { disabled: false };
Clinic.propTypes = {
  field: PropTypes.oneOf([SUB_EYE_DOCTOR, SUB_GP]).isRequired,
  disabled: PropTypes.bool,
  emptyMessage: PropTypes.string.isRequired,
};

export default Clinic;
