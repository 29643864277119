import styled from 'styled-components';

export const Button = styled.button.attrs(() => ({ type: 'button' }))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 55px;
  position: absolute;
  inset: auto 0 auto auto;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  &:disabled {
    display: none;
  }
`;
