import React from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import Spinner from 'components/FullScreenSpinner';
import Plus from 'assets/svg-icons/Plus';

import { DropButton, TextContainer, Text, WarnText } from './styles';

const FileDropZone = ({ onDrop, accept, loading, label, acceptFormats }) => {
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({ onDrop, accept });

  return (
    <DropButton {...getRootProps()} isActive={isDragActive} isWarn={isDragReject}>
      <input {...getInputProps()} />
      {loading ? (
        <Spinner size={30} height="50px" />
      ) : (
        <>
          <Plus />
          <TextContainer>
            {isDragReject ? (
              <>
                <WarnText>Gültiges Dateiformat</WarnText>
                <WarnText>{acceptFormats.join(', ')}</WarnText>
              </>
            ) : (
              <>
                <Text>{label}</Text>
                <Text>Feld ziehen</Text>
              </>
            )}
          </TextContainer>
        </>
      )}
    </DropButton>
  );
};

FileDropZone.defaultProps = {
  loading: false,
  label: '',
  acceptFormats: [],
};

FileDropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  accept: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  loading: PropTypes.bool,
  label: PropTypes.string,
  acceptFormats: PropTypes.arrayOf(PropTypes.string.isRequired),
};

export default FileDropZone;
