import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import AnimateBox from 'components/AnimateBox';
import Icon from 'components/ArrowIcon';

import { Container, Header, Label, ItemContent } from './styles';

const Accordion = ({ label, defaultOpen, children }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const handleClick = useCallback(() => setIsOpen(($) => !$), []);

  return (
    <Container>
      <Header onClick={handleClick}>
        <div>
          <Label>{label}</Label>
        </div>
        <Icon isOpen={isOpen} />
      </Header>
      <AnimateBox isOpen={isOpen}>
        <ItemContent>{children}</ItemContent>
      </AnimateBox>
    </Container>
  );
};

Accordion.defaultProps = {
  label: '',
  defaultOpen: false,
};

Accordion.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  defaultOpen: PropTypes.bool,
};

export default Accordion;
