import moment from 'moment';
import { prop, pick, path } from 'ramda';

import {
  ID,
  SUB_SERVICE,
  DATE,
  SESSION,
  CURRENCY,
  TARIFF_TYPE,
  CODE,
  NAME,
  QUANTITY,
  AMOUNT_TT,
  AMOUNT_MT,
  AMOUNT,
} from 'store/invoices';

const amountConverter = (amount) => Math.round((amount / 100) * 100) / 100;

export const getPrice = (currency, price) =>
  [(currency && currency.toUpperCase()) || '', price ? `${Number(amountConverter(price)).toFixed(2)}` : '0.00'].join(' ');

export const getDate = (date) => date && moment(date).format('DD.MM.YYYY');

const extractPrice = (value) => (data) => getPrice(prop(CURRENCY, data), prop(value, data));

export const tableTemplate = [
  {
    getData: (data) => [prop(TARIFF_TYPE, data), prop(CODE, data)].join('\n'),
    name: 'Typ / Code',
    width: '8%',
  },
  {
    getData: prop(NAME),
    name: 'Name',
    width: '30%',
  },
  {
    getData: prop(SESSION),
    name: 'Session',
    width: '6%',
  },
  {
    getData: prop(QUANTITY),
    name: 'Menge',
    width: '6%',
  },
  {
    getData: extractPrice(AMOUNT_TT),
    name: 'Technische Leistung',
    width: '17%',
  },
  {
    getData: extractPrice(AMOUNT_MT),
    name: 'Mediznische Leistung',
    width: '17%',
  },
  {
    getData: extractPrice(AMOUNT),
    name: 'Betrag',
    width: '10%',
  },
];

export const prepareTarmeds = (allTarmeds = [], patientTarmeds = []) => {
  if (!allTarmeds.length || !patientTarmeds.length) return [];

  const allTarmedsObj = allTarmeds.reduce((acc, item) => {
    acc[item[ID]] = item;

    return acc;
  }, {});

  return patientTarmeds.map((item = {}) => ({
    renderId: item[ID],
    ...pick([AMOUNT_TT, AMOUNT_MT, AMOUNT, QUANTITY, DATE, SESSION], item),
    ...allTarmedsObj[item[SUB_SERVICE]],
    [NAME]: item[NAME] || path([item[SUB_SERVICE], NAME], allTarmedsObj),
  }));
};
