import React from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/FullScreenSpinner';
import { useInitOrganization } from 'store/organizations';
import { useWebsocketConnect } from 'store/examinations';
import { USER, EXAMINATION_REAL_TIME, usePermissions } from 'permissions';

const InitData = ({ children }) => {
  const can = usePermissions();
  const { loading, success, error } = useInitOrganization(can.read(USER));

  useWebsocketConnect(can.read(EXAMINATION_REAL_TIME));

  if (loading || !(success || error)) return <Spinner />;

  return children;
};

InitData.propTypes = { children: PropTypes.node.isRequired };

export default InitData;
