/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useEffect } from 'react';
import { inc, dec } from 'ramda';

import { useHas2FA } from 'store/session';

import { useContent } from './hooks';
import Counter from './Counter';
import { Wrapper, Title, Text, BtnContainer } from './styles';

const Security = () => {
  const has2FA = useHas2FA();
  const [step, setStep] = useState(0);

  const handlePrevious = useCallback(() => {
    setStep(dec(step));
  }, [step]);

  const handleNext = useCallback(() => {
    setStep(inc(step));
  }, [step]);

  const content = useContent({
    has2FA,
    next: handleNext,
    prev: handlePrevious,
  });

  const { key, component, title, texts, buttons } = content[has2FA ? 0 : step];

  useEffect(
    () => () => {
      if (has2FA && step !== 0) {
        setStep(0);
      }
    },
    [has2FA, step]
  );

  return (
    <>
      {!has2FA && <Counter value={key} amount={content.length} />}
      <Wrapper>{component}</Wrapper>
      <div>
        <Title>{title}</Title>
        {texts.map((text, i) => (
          <Text key={i}>{text}</Text>
        ))}
      </div>
      <BtnContainer>{buttons}</BtnContainer>
    </>
  );
};

export default Security;
