import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
    <g fill="none" fillRule="evenodd">
      <g stroke="#979797" strokeWidth="1.5">
        <g>
          <g>
            <path d="M0 0L16 16M0 16L16 0" transform="translate(4.5 4.5)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
