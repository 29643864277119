import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const Item = styled.div`
  width: ${({ width }) => `calc(${width} - 10px)`};
  font-size: var(--font-size20);
  color: var(--color-grey6A);
  text-align: left;
`;

export const Auge = styled(Item)`
  width: ${({ width }) => `calc(${width} - 63px)`};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: var(--color-greyF8);
  border-radius: var(--border-radius8);
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    background: var(--color-greyEC);
  }
`;
