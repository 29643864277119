import React, { useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Container, Text, A } from './styles';

const Notification = () => {
  const containerRef = useRef();
  const { push } = useHistory();

  const handleClick = useCallback(() => {
    push('/releases');
    containerRef.current.classList.remove('show-sw-notification');
    window.location.reload();
  }, [push]);

  return (
    <Container id="sw-notification" ref={containerRef}>
      <Text>Es ist eine neue Version von Augenmobil verfügbar.</Text>
      <A type="button" id="sw-reload" onClick={handleClick}>
        Release Notes
      </A>
    </Container>
  );
};

export default Notification;
