import React from 'react';
import PropTypes from 'prop-types';

import { Container, Text } from './styles';

const Counter = ({ value, amount }) => (
  <Container>
    <Text>{`Schritt ${value}/${amount}`}</Text>
  </Container>
);

Counter.propTypes = {
  value: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
};

export default Counter;
