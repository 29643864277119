import { pipe, join, map, all, is, when, length, equals, prepend, toString } from 'ramda';

export const getTimeToNow = (date) => {
  const seconds = (Date.now() - new Date(date)) / 1000;
  const hours = Math.floor(seconds / 3600);
  const time = [hours, Math.floor(seconds / 60 - hours * 60)];

  if (!all(is(Number), time)) return '';
  if (hours >= 48) return `${Math.floor(hours / 24)} Tagen`;
  if (hours >= 24) return `1 Tag`;

  const value = pipe(map(pipe(toString, when(pipe(length, equals(1)), pipe(prepend('0'), join(''))))), join(':'))(time);

  return `${value} Std.`;
};
