import { map, applySpec, pipe, path, pathOr, prop, sort } from 'ramda';

import { ID, REPORT_URL } from 'store/reports';
import { parseDate } from 'utils';

export const handleItems = pipe(
  sort((a, b) => new Date(pathOr(0, ['meta', 'created'], b)).valueOf() - new Date(pathOr(0, ['meta', 'created'], a)).valueOf()),
  map(
    applySpec({
      key: prop(ID),
      date: pipe(path(['meta', 'created']), parseDate),
      url: prop(REPORT_URL),
    })
  )
);
