import styled from 'styled-components';

export const TimelineWrapper = styled.div``;

export const Value = styled.p`
  font-size: 26px;
  font-weight: var(--font-weight500);
  letter-spacing: 1.06px;
  margin-bottom: ${({ layout }) => (layout === 'small' ? '2px' : '30px')};
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ prev, layout }) => (layout === 'small' && !prev ? '20px' : 0)};

  & * {
    color: ${({ prev }) => (prev ? 'var(--color-grey97)' : 'var(--color-grey6A)')};
  }
`;
