import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { parseDate, parseTime } from 'utils';

import { Container, Input, Message } from './styles';

const TimeInput = ({ date, onChange, min, max }) => {
  const ref = useRef(null);
  const [isValid, setIsValid] = useState('');
  const error = useMemo(
    () =>
      isValid
        ? null
        : `Hinweis: Die gewählte Uhrzeit liegt ausserhalb des Terminplans vom ${parseDate(date)}, von ${min} Uhr bis ${max} Uhr.`,
    [date, isValid, max, min]
  );
  const defaultValue = useMemo(() => parseTime(date), [date]);
  const handleChange = useCallback(
    ({ currentTarget }) => {
      const updatedDate = new Date(date);
      const [hours, minutes] = currentTarget.value.split(':');

      updatedDate.setHours(hours);
      updatedDate.setMinutes(minutes);

      if (updatedDate instanceof Date && !isNaN(updatedDate)) {
        onChange(updatedDate.toISOString());
        setIsValid(currentTarget.validity.valid);
      } else {
        setIsValid(false);
      }
    },
    [date, onChange]
  );

  useEffect(() => {
    setIsValid(Boolean(ref.current?.validity?.valid));
  }, [date]);

  return (
    <Container>
      <Input ref={ref} type="time" value={defaultValue} onChange={handleChange} min={min} max={max} $error={!isValid} required />
      {error && <Message>{error}</Message>}
    </Container>
  );
};

TimeInput.propTypes = {
  date: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
};

export default TimeInput;
