import { exteriorEyeQuestions, fundusEyeQuestions } from 'assets/analysisQuestions';
import {
  EYE_EXTERIOR,
  FUNDUS,
  RIGHT,
  LEFT,
  EYE,
  DESCRIPTION,
  MACULA,
  MACULA_DESCRIPTION,
  PERIPHERAL_VISION,
  PERIPHERAL_VISION_DESCRIPTION,
  PAPILLA,
  PAPILLA_DESCRIPTION,
  ANALYSIS_FROM_PREVIOUS,
} from 'store/diagnoses';

export const generatorDefaultStringValues = (list) =>
  list.reduce((acc, { prop }) => {
    acc[prop] = '';

    return acc;
  }, {});

export const questionsObject = {
  [EYE_EXTERIOR]: exteriorEyeQuestions,
  [FUNDUS]: fundusEyeQuestions,
};

export const getTitleName = (area, eye) => {
  const mapArea = {
    [EYE_EXTERIOR]: 'Vorderes Auge',
    [FUNDUS]: 'Fundus',
  };
  const mapEye = {
    [RIGHT]: 'rechts',
    [LEFT]: 'links',
  };

  return `${mapArea[area]} ${mapEye[eye]}`;
};

export const additionalFields = (eye) => ({
  [EYE_EXTERIOR]: {
    [EYE]: eye,
    [DESCRIPTION]: '',
    [ANALYSIS_FROM_PREVIOUS]: false,
  },
  [FUNDUS]: {
    [EYE]: eye,
    [DESCRIPTION]: '',
    [MACULA]: [],
    [MACULA_DESCRIPTION]: '',
    [PERIPHERAL_VISION]: [],
    [PERIPHERAL_VISION_DESCRIPTION]: '',
    [PAPILLA]: [],
    [PAPILLA_DESCRIPTION]: '',
    [ANALYSIS_FROM_PREVIOUS]: false,
  },
});
