import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/FullScreenSpinner';
import { useLoggedIn, useAuth, useAuth2FA, useSessionActions, useAlerts } from 'store/session';

import { useBugsnagOffline } from './hooks';
import InitData from './InitData';

const InitApp = ({ children }) => {
  const { id, extractId, loading, error } = useAlerts();
  const { restore, logout } = useSessionActions();
  const isAuth = useAuth();
  const isAuth2FA = useAuth2FA();
  const isLoggedIn = useLoggedIn();
  const shouldRestore = (isAuth2FA || isAuth) && !isLoggedIn;

  useEffect(() => {
    if (shouldRestore) extractId(restore());
  }, [extractId, restore, shouldRestore]);

  useEffect(() => {
    if (error) logout();
  }, [error, logout]);

  useBugsnagOffline();

  if (shouldRestore && (!id || loading)) return <Spinner />;

  return <InitData>{children}</InitData>;
};

InitApp.propTypes = { children: PropTypes.node.isRequired };

export default InitApp;
