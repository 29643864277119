import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Item = styled.div`
  font-size: var(--font-size13);
  color: var(--color-grey6A);
  border-top: 1px solid var(--color-grey6A);
  margin-right: 8px;
  font-weight: var(--font-weight600);
  letter-spacing: 0.9px;
  text-transform: uppercase;
  padding-top: 7px;
  padding-left: 5px;
  width: ${({ width }) => width * 100}%;
  &:last-child {
    margin: 0;
  }
`;
