import React, { useCallback, useMemo, memo } from 'react';
import { useParams } from 'react-router-dom';
import qs from 'query-string';
import { path, pathOr, prop, propEq, head, T, map, applySpec, pipe, ifElse, not, filter } from 'ramda';
import PropTypes from 'prop-types';

import { maculaQuestions, retinaPerimeterQuestions, papilleQuestions } from 'assets/analysisQuestions';
import PageHeader from 'components/PageHeader';
import {
  DESCRIPTION,
  MACULA_DESCRIPTION,
  MACULA,
  PERIPHERAL_VISION,
  PERIPHERAL_VISION_DESCRIPTION,
  FUNDUS,
  PAPILLA,
  PAPILLA_DESCRIPTION,
} from 'store/diagnoses';
import {
  ID,
  STATUS,
  RIGHT,
  LEFT,
  COMMENT as EXAM_COMMENT,
  DATE,
  FILES,
  useCurrentExamination,
  useExaminations,
} from 'store/examinations';
import { FIRST_NAME, LAST_NAME, BIRTH, PUBLIC_ID, usePatient } from 'store/patients';
import { compareProps, getHeadTitleOfPatient, parseDate } from 'utils';
import { ANALYSIS } from 'permissions';

import Images from './Images';
import Survey from './Survey';
import Checkboxes from './Checkboxes';
import TextArea from './TextArea';
import AdoptLastDiagnosis from './AdoptLastAnalysis';
import { Container, FormContainer, Title, Section, SectionLabel } from './styles';
import { questionsObject, getTitleName } from './utils';

const EyeImagesAnalysis = ({ history, location, match, values, setFieldValue, readMode }) => {
  const { exam, eye, area, question } = qs.parse(location.search.slice(1));
  const { id } = useParams();
  const [firstName, lastName, birthday, publicId] = usePatient(id, [FIRST_NAME, LAST_NAME, BIRTH, PUBLIC_ID], T);
  const examination = useCurrentExamination({
    [ID]: ID,
    [STATUS]: STATUS,
    [RIGHT]: RIGHT,
    [LEFT]: LEFT,
    comment: EXAM_COMMENT,
  });

  const examinations = useExaminations();
  const imagesItems = useMemo(
    () =>
      map(
        applySpec({
          id: prop(ID),
          files: pipe(
            pathOr([], [eye, area, FILES]),
            filter(({ offline }) => not(offline))
          ),
          date: ifElse(prop(DATE), pipe(prop(DATE), parseDate), pipe(path(['meta', 'created']), parseDate)),
        }),
        examinations
      ),
    [examinations, eye, area]
  );

  const examId = examination[ID];

  const redirectToBack = useCallback(() => {
    history.push(`/patients/${id}/${ANALYSIS}`, { ...location.state, eye, area });
  }, [area, eye, history, id, location.state]);

  const questions = useMemo(() => questionsObject[area], [area]);
  const objectOfQuestion = useMemo(() => questions.find(propEq(question, 'prop')) || head(questions), [question, questions]);

  const handleClickQuestion = (value) => {
    history.push(
      `${match.url}?${qs.stringify({
        exam,
        eye,
        area,
        question: value,
      })}`,
      location.state
    );
  };

  const handleChangeAnswer = useCallback(
    (val) => {
      setFieldValue(objectOfQuestion.prop, val);
    },
    [objectOfQuestion.prop, setFieldValue]
  );
  const handleChangeMacula = useCallback(
    (val) => {
      setFieldValue(MACULA, val);
    },
    [setFieldValue]
  );
  const handleChangePeripheralVision = useCallback(
    (val) => {
      setFieldValue(PERIPHERAL_VISION, val);
    },
    [setFieldValue]
  );
  const handleChangePapilla = useCallback(
    (val) => {
      setFieldValue(PAPILLA, val);
    },
    [setFieldValue]
  );

  const HeadTitle = useMemo(
    () => (
      <Title>
        <div>{getHeadTitleOfPatient(firstName, lastName, birthday, publicId)}</div>
        <div>{`${getTitleName(area, eye)}`}</div>
      </Title>
    ),
    [area, birthday, eye, firstName, lastName, publicId]
  );

  if (readMode) {
    return (
      <>
        <FormContainer>
          <PageHeader title={HeadTitle} redirectToBack={redirectToBack} />
        </FormContainer>
        <Container>
          <Images items={imagesItems} currentId={examId} />
        </Container>
      </>
    );
  }

  return (
    <>
      <FormContainer>
        <PageHeader title={HeadTitle} redirectToBack={redirectToBack} />
      </FormContainer>
      <Container>
        <AdoptLastDiagnosis />
        <Images items={imagesItems} currentId={examId} />
        <Section mt={20}>
          <SectionLabel>{objectOfQuestion.key}</SectionLabel>
          <Survey
            options={questions}
            activeQuestion={objectOfQuestion.prop}
            answer={values[objectOfQuestion.prop]}
            onChange={handleChangeAnswer}
            setActiveQuestion={handleClickQuestion}
          />
        </Section>
        {area === FUNDUS && (
          <>
            <Section mb={20}>
              <SectionLabel>Makula</SectionLabel>
              <Checkboxes columns={3} items={values[MACULA]} allItems={maculaQuestions} onChange={handleChangeMacula} />
            </Section>
            <TextArea name={MACULA_DESCRIPTION} value={values[MACULA_DESCRIPTION]} setFieldValue={setFieldValue} />
            <Section mb={20}>
              <SectionLabel>Netzhautperipherie</SectionLabel>
              <Checkboxes
                columns={3}
                items={values[PERIPHERAL_VISION]}
                allItems={retinaPerimeterQuestions}
                onChange={handleChangePeripheralVision}
              />
            </Section>
            <TextArea
              name={PERIPHERAL_VISION_DESCRIPTION}
              value={values[PERIPHERAL_VISION_DESCRIPTION]}
              setFieldValue={setFieldValue}
            />
            <Section mb={20}>
              <SectionLabel>Papille</SectionLabel>
              <Checkboxes columns={3} items={values[PAPILLA]} allItems={papilleQuestions} onChange={handleChangePapilla} />
            </Section>
            <TextArea name={PAPILLA_DESCRIPTION} value={values[PAPILLA_DESCRIPTION]} setFieldValue={setFieldValue} />
          </>
        )}
        <Section>
          <SectionLabel>Kommentar</SectionLabel>
          <TextArea name={DESCRIPTION} value={values[DESCRIPTION]} setFieldValue={setFieldValue} />
        </Section>
      </Container>
    </>
  );
};

EyeImagesAnalysis.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    state: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  values: PropTypes.shape({}).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  readMode: PropTypes.bool.isRequired,
};

export default memo(EyeImagesAnalysis, compareProps(['history', 'location', 'match', 'values', 'setFieldValue', 'readMode']));
