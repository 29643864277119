import React from 'react';
import PropTypes from 'prop-types';

import { Container, Label } from './styles';

const QuestionContainer = ({ label, children, className }) => (
  <Container className={className}>
    <Label>{label}</Label>
    {children}
  </Container>
);

QuestionContainer.defaultProps = {
  children: null,
  className: null,
};

QuestionContainer.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.node]))]),
  className: PropTypes.string,
};

export default QuestionContainer;
