import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(245, 166, 35, 0.33);
  min-height: 60px;
  border-radius: var(--border-radius8);
  width: 100%;
  padding-right: 10px;
  & > svg {
    min-width: 32px;
  }
`;

export const Message = styled.p`
  flex: 1 1 auto;
  color: var(--color-orangeDC);
  white-space: pre-line;
  padding: 8px 18px;
  font-size: var(--font-size13);
  font-weight: var(--font-weight600);
  line-height: 1.25;
  letter-spacing: 0.92px;
`;
