import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  column-gap: 20px;
  width: 100%;
  height: calc(100vh - 70px);
  min-height: 500px;
  position: relative;
`;

export const MainWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
`;

export const Navbar = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  max-width: 350px;
  border-left: 1px solid var(--color-grey97);
`;

export const BackgroundLabel = styled.h1`
  font-size: 30vw;
  font-weight: var(--font-weight100);
  color: var(--color-greyDC);
`;

export const SpinnerWrap = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const SelectWrap = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  min-width: 300px;
  padding: 10px;
`;

export const SelectLabel = styled.p`
  font-size: var(--font-size18);
`;

export const Range = styled.input`
  flex: 1 1 auto;
  cursor: pointer;
`;
