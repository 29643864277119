import styled from 'styled-components';

import ButtonDef from 'components/Button';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 50px;
`;

export const Button = styled(ButtonDef)`
  height: 40px;
  width: 50%;
  margin: 50px 0 0 auto;
`;

export const Link = styled.a`
  align-self: flex-end;
  font-size: var(--font-size15);
  font-weight: var(--font-weight500);
  letter-spacing: 0.33px;
  color: var(--color-blue5D);
  text-transform: uppercase;
  margin-top: 10px;
  &:active {
    color: var(--color-blue8E);
  }
  @media (hover) {
    &:hover {
      color: var(--color-blue2E);
    }
  }
`;
