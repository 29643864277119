import React from 'react';
import PropTypes from 'prop-types';

import TextInput from 'components/TextInputNew';
import Button from '../Button';

import { Container, InputContainer } from './styles';

const InputRow = ({ value, label, onChange, readMode, error, type, ...buttonProps }) => (
  <Container>
    <InputContainer>
      <TextInput
        type={type}
        placeholder={label}
        value={value || value === 0 ? value : ''}
        handleChangeText={onChange}
        readMode={readMode}
        error={error}
        mb={0}
      />
    </InputContainer>
    {readMode || <Button {...buttonProps} />}
  </Container>
);

InputRow.defaultProps = {
  value: '',
  label: '',
  readMode: false,
  error: null,
};

InputRow.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  readMode: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default InputRow;
