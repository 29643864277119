import styled from 'styled-components';

import search from 'assets/svg-icons/search.svg';
import selectArrow from 'assets/svg-icons/selectArrow.svg';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  width: 100%;
  height: 40px;
`;

export const Select = styled.select`
  width: 100%;
  height: 100%;
  border: 1px solid;
  border-radius: var(--border-radius8) 0 0 var(--border-radius8);
  border-color: var(--color-grey97);
  font-size: var(--font-size18);
  color: var(--color-grey6A);
  padding: 0 30px 0 20px;
  background-color: var(--color-white);
  background-image: url(${selectArrow});
  background-position: calc(100% - 7px);
  background-repeat: no-repeat;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
  &:disabled {
    opacity: 1;
    font-weight: var(--font-weight300);
    cursor: not-allowed;
  }
  &:focus {
    box-shadow: var(--shadow-input-focus);
  }
`;

export const InputWrap = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
`;

export const Input = styled.input`
  grid-column: 1/2;
  grid-row: 1/2;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  background-image: url(${search});
  background-position: 5px;
  background-repeat: no-repeat;
  border-width: 1px 1px 1px 0;
  border-style: solid;
  border-radius: 0 var(--border-radius8) var(--border-radius8) 0;
  border-color: var(--color-grey97);
  font-size: var(--font-size18);
  font-weight: var(--font-weight400);
  color: var(--color-grey6A);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  padding: 0 40px;
  &::placeholder {
    color: var(--color-grey97);
  }
  &:disabled {
    opacity: 1;
    font-weight: var(--font-weight300);
    cursor: not-allowed;
  }
  &:focus {
    box-shadow: var(--shadow-input-focus);
  }
`;

export const Button = styled.button`
  grid-column: 1/2;
  grid-row: 1/2;
  justify-self: end;
  display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`;
