import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const AutoSave = ({ onSave }) => {
  const unmountFn = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      unmountFn.current = null;
      onSave();
    }, 500);

    unmountFn.current = onSave;

    return () => clearTimeout(timer);
  }, [onSave]);

  useEffect(
    () => () => {
      if (typeof unmountFn.current === 'function') unmountFn.current();
    },
    []
  );

  return null;
};

AutoSave.propTypes = { onSave: PropTypes.func.isRequired };

export default AutoSave;
