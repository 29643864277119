import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { includes, isEmpty, pathEq } from 'ramda';
import { shallowEqual } from 'react-redux';

import { useUser, usePermissionsObject, ID, ROLES } from 'store/session';
import { useBlockedExaminationData, EXAMINATION_STATUS } from 'store/examinations';
import { APPROVAL_STATUS, SUB_USER } from 'store/invoices';

import { getHomePage, getAllReadModeItems, getExaminationStatusesForSearch, checkAccess, userWithout2EA } from './utils';
import { EXAMINATION, EXAMINATION_REAL_TIME, READ, PHYSICIAN_JUNIOR, PHYSICIAN_MIDLEVEL, PHYSICIAN_SENIOR } from './consts';

export const usePermissions = () => {
  const permissions = usePermissionsObject();

  return useMemo(() => checkAccess(permissions), [permissions]);
};

export const useSearchStatuses = () => {
  const roles = useUser(ROLES, shallowEqual);

  return useMemo(() => getExaminationStatusesForSearch(roles || []), [roles]);
};

export const useHomePage = () => {
  const roles = useUser(ROLES, shallowEqual);

  return useMemo(() => getHomePage(roles || []), [roles]);
};

export const useShouldCheck2FA = () => {
  const roles = useUser(ROLES, shallowEqual);

  return useMemo(() => process.env.REACT_APP_PROCESS === 'production' && userWithout2EA(roles || []), [roles]);
};

export const useReadModeItems = (status, route) => {
  const { pathname } = useLocation();
  const can = usePermissions();
  const roles = useUser(ROLES, shallowEqual);

  const isExamPage = useMemo(() => includes(EXAMINATION, pathname), [pathname]);
  const canRead = isExamPage && can.read(EXAMINATION_REAL_TIME);
  const { isBlocked, isOwner } = useBlockedExaminationData(canRead);

  const items = useMemo(() => getAllReadModeItems(roles || [], status, route), [roles, route, status]);

  return isBlocked && !isOwner ? [READ] : items;
};

const { SUBMITTED, UNDER_REVIEW, IN_APPROVAL, APPROVED } = EXAMINATION_STATUS;

export const useCheckDiagnosisByRole = (status, approvals = []) => {
  const [userId, roles = []] = useUser([ID, ROLES], shallowEqual);
  const isApprovalOwner = useMemo(
    () => Boolean(approvals.find((item = {}) => pathEq(userId, [SUB_USER, ID], item))),
    [approvals, userId]
  );
  const isApproved = useMemo(
    () => Boolean(approvals.find(({ status: approvalStatus }) => approvalStatus === APPROVAL_STATUS.APPROVED)),
    [approvals]
  );
  const [isJunior, isMiddle, isSenior] = useMemo(
    () => [roles.includes(PHYSICIAN_JUNIOR), roles.includes(PHYSICIAN_MIDLEVEL), roles.includes(PHYSICIAN_SENIOR)],
    [roles]
  );

  const needApproval = isJunior && status <= IN_APPROVAL;
  const shouldApprove = isSenior && status === IN_APPROVAL;
  const canSend =
    (isJunior && status === APPROVED && isApproved && isApprovalOwner) ||
    ((isMiddle || isSenior) && status >= SUBMITTED && status <= UNDER_REVIEW && isEmpty(approvals));

  return {
    userId,
    needApproval,
    shouldApprove,
    canSend,
  };
};
