export const PHYSICIAN = 'PHYSICIAN';
export const PHYSICIAN_JUNIOR = 'PHYSICIAN_JUNIOR';
export const PHYSICIAN_MIDLEVEL = 'PHYSICIAN_MIDLEVEL';
export const PHYSICIAN_SENIOR = 'PHYSICIAN_SENIOR';
export const NURSE = 'NURSE';
export const SECRETARY = 'SECRETARY';
export const FINANCE = 'FINANCE';
export const FINANCE_MANAGER = 'FINANCE_MANAGER';
export const SYSADMIN = 'SYSADMIN';
export const EXTERN = 'EXTERN';
export const PLANNER = 'PLANNER';
export const PLANNER_SUPPORT = 'PLANNER_SUPPORT';
export const ANALYTICS = 'ANALYTICS';

export const USER = 'user';
export const USERS = 'users';
export const PATIENTS = 'patients';
export const PATIENT = 'patient';
export const NURSE_PATIENT = 'nursePatient';
export const PHYSICIAN_PATIENT = 'physicianPatient';
export const MEDICAL_HISTORY = 'medicalhistory';
export const EXAMINATION = 'examination';
export const EXAMINATION_REAL_TIME = 'examinationWebSocket';
export const DIAGNOSIS = 'diagnosis';
export const DIAGNOSIS_CODE = 'diagnosiscode';
export const REPORTS = 'reports';
export const REPORT = 'report';
export const INVOICES = 'invoices';
export const INVOICE = 'invoice';
export const TARMED = 'tarmed';
export const ORGANIZATIONS = 'organizations';
export const ORGANIZATION = 'organization';
export const ORGANIZATION_REPORTS = 'organizationsReports';
export const INSURANCE = 'insurance';
export const BUG_REPORTS = 'bugreports';
export const BUG_REPORT = 'bugreport';
export const UPLOAD = 'upload';
export const VISUAL_ACUITY = 'visualacuity';
export const DASHBOARD = 'dashboard';
export const PLANNINGS = 'plannings';
export const PLANNING = 'planning';
export const CANTON_DASHBOARD = 'cantonDashboard';
export const INSIGHTS = 'insights';
export const LLM = 'llm';

export const PERSONAL = 'personal';
export const ANALYSIS = 'analysis';
export const RECOMMEND = 'recommendation';
export const BILLS = 'invoices';

export const CREATE = 'canCreate';
export const READ = 'canRead';
export const UPDATE = 'canUpdate';
export const DELETE = 'canDelete';
export const REDIRECT = 'canRedirect';
