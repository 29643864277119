import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  background-color: rgba(245, 166, 35, 0.33);
  overflow: hidden;
  transition: height 0.5s;
`;

export const Text = styled.p`
  font-size: var(--font-size18);
  font-weight: var(--font-weight500);
  color: var(--color-orangeDC);
  line-height: 40px;
`;

export const A = styled.button`
  background: none;
  border: none;
  font-size: var(--font-size18);
  font-weight: var(--font-weight500);
  color: var(--color-orangeDC);
  line-height: 40px;
  text-decoration: underline;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    transform: scale(1.03);
    opacity: 0.9;
  }
`;
