import styled from 'styled-components';

import Msg from 'components/Message';
import Warning from 'components/Warning';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  & > button:first-child {
    margin-right: 7px;
  }
  & > button:last-child {
    margin-left: 7px;
  }
`;

export const MediaContainer = styled.div`
  margin: 40px 0;
`;

export const Button = styled.button`
  height: 80px;
  width: 100%;
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  letter-spacing: 0.92px;
  border-radius: var(--border-radius8);
  cursor: pointer;
  outline: none;
  text-transform: uppercase;
  transition: all 0.3s;

  background: ${({ isActive }) => (isActive ? '#b8f4e6' : 'var(--color-greyEC)')};
  color: var(--color-grey6A);
  border: ${({ isActive }) => `1px solid ${isActive ? 'var(--color-blue50)' : 'var(--color-grey97)'}`};

  &:focus {
    box-shadow: var(--shadow-focus);
  }
  &:hover {
    background: var(--color-greyEC);
  }
  &:active {
    background: var(--color-green50);
  }
  &:disabled {
    background: #b8f4e6;
  }
`;

export const Message = styled(Msg)`
  text-align: center;
`;

export const BlockMessage = styled(Warning)`
  margin-bottom: 20px;
`;
