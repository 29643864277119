import React from 'react';
import { useParams } from 'react-router-dom';

import { usePermissions, PLANNING } from 'permissions';

import List from './List';
import FormNew from './New';
import FormEdit from './Edit';
import { Container, Switcher, Link } from './styles';

const Planning = () => {
  const { id } = useParams();
  const can = usePermissions();

  return (
    <Container>
      {(!id || id === 'new') && (
        <Switcher>
          <Link to="/planning" exact>
            Übersicht
          </Link>
          {can.create(PLANNING) && <Link to="/planning/new">Neuer Terminplan</Link>}
        </Switcher>
      )}
      {(id === 'new' && <FormNew />) || (id ? <FormEdit /> : <List />)}
    </Container>
  );
};

export default Planning;
