import { map, join, filter, pipe, props } from 'ramda';

import { NAME, SPECIALTY, DOCTOR, OPHTHALMOLOGY, CONTACTS, ZIP_CODE, CITY, CANTON, ADDRESS } from 'store/organizations';
import { SUB_EYE_DOCTOR, SUB_GP, FIELDS } from 'store/planning';

const NAME_KEY = 'fullName';

const handleClinicName = (items = []) =>
  map((item = {}) => ({
    ...item,
    [NAME_KEY]: pipe(
      ($) => [$[NAME], pipe(props([ZIP_CODE, CITY, CANTON]), filter(Boolean), join(' '))($)],
      filter(Boolean),
      join(', ')
    )(item),
  }))(items);

const getParams = (specialty) => ({
  [FIELDS]: [NAME, SPECIALTY, CONTACTS, ZIP_CODE, CITY, CANTON, ADDRESS],
  [SPECIALTY]: specialty,
});

export const featureMap = {
  [SUB_GP]: [NAME_KEY, getParams(DOCTOR), handleClinicName, 'Hausarzt'],
  [SUB_EYE_DOCTOR]: [NAME_KEY, getParams(OPHTHALMOLOGY), handleClinicName, 'Augenarzt'],
};
