import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { values, props } from 'ramda';

import { wrapActions, wrapSelector, wrapMultiSelector, wrapSelectorWithArg } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { SORT, PAGINATION, SORT_BY, SORT_DIR, CURSOR, LIMIT, SEARCH, STATUS, ALL, SUB_PATIENT } from 'api/consts';

import {
  getInvoices,
  getInvoice,
  getInvoiceProp,
  getCurrentInvoice,
  getCurrentInvoiceProp,
  getAllTarmeds,
  getPatientTarmeds,
  getDashboard,
  getFilters,
} from './selectors';
import * as actions from './actions';
import { INVOICES_FILTERS, TARMEDS_FILTERS, CANTON_FILTERS, YEAR_FILTERS, CANTON, YEAR } from './consts';

export const useInvoicesActions = wrapActions({ ...actions });

export const useInvoices = () => {
  const invoices = useSelector(getInvoices);

  return useMemo(() => values(invoices), [invoices]);
};
export const useInvoice = (id, arg) => wrapMultiSelector(getInvoiceProp(id), getInvoice(id))(arg);
export const useCurrentInvoice = wrapMultiSelector(getCurrentInvoiceProp, getCurrentInvoice);

export const useAllTarmeds = wrapSelector(getAllTarmeds);
export const usePatientTarmeds = wrapSelector(getPatientTarmeds);
export const useDashboard = wrapSelector(getDashboard);
export const useFilters = wrapSelectorWithArg(getFilters);

export const useInitDashboard = (hasAccess) => {
  const alerts = useAlerts();
  const { id, extractId, success, resetAlerts } = alerts;
  const { fetchDashboard, updateDashboard } = useInvoicesActions();

  useEffect(() => {
    if (hasAccess && !id) {
      extractId(fetchDashboard());
    }
  }, [extractId, fetchDashboard, hasAccess, id]);

  useEffect(() => {
    if (!hasAccess && success) {
      updateDashboard({});
      resetAlerts();
    }
  }, [hasAccess, resetAlerts, success, updateDashboard]);

  return hasAccess ? alerts : { success: true };
};

export const useInitKPIByNurseHomeId = (hasAccess, nurseHomeId) => {
  const { fetchNursingHomeKPIs } = useInvoicesActions();
  const { id, extractId, loading, success, resetAlerts } = useAlerts();

  useEffect(() => {
    if (hasAccess && nurseHomeId) extractId(fetchNursingHomeKPIs(nurseHomeId));
  }, [extractId, fetchNursingHomeKPIs, hasAccess, nurseHomeId]);

  useEffect(() => {
    if (!hasAccess && id) resetAlerts();
  }, [hasAccess, id, resetAlerts]);

  return hasAccess ? { loading: nurseHomeId && (!id || loading), list: success || [] } : { list: [] };
};

export const useInitInvoicesByExamination = (hasAccess, examId) => {
  const alerts = useAlerts();
  const { id, extractId, success, resetAlerts } = alerts;
  const { fetchInvoicesByExamId, setCurrentInvoice, updateInvoices } = useInvoicesActions();

  useEffect(() => {
    if (hasAccess && examId && !id) {
      extractId(fetchInvoicesByExamId(examId));
    }
  }, [examId, extractId, fetchInvoicesByExamId, hasAccess, id]);

  useEffect(() => {
    if (!hasAccess && success) {
      updateInvoices([]);
      resetAlerts();
    }
  }, [hasAccess, resetAlerts, success, updateInvoices]);

  useEffect(() => () => setCurrentInvoice(), [setCurrentInvoice]);

  return hasAccess ? alerts : { success: true };
};

export const useInitInvoice = (hasAccess, invoiceId) => {
  const alerts = useAlerts();
  const { extractId } = alerts;
  const { fetchInvoice } = useInvoicesActions();

  useEffect(() => {
    if (hasAccess && invoiceId) extractId(fetchInvoice(invoiceId));
  }, [extractId, fetchInvoice, hasAccess, invoiceId]);

  return hasAccess ? alerts : { success: true };
};

export const useInitInvoices = (hasAccess) => {
  const alerts = useAlerts();
  const list = useInvoices();
  const filters = useFilters(INVOICES_FILTERS);
  const [status, search, sort, pagination] = props([STATUS, SEARCH, SORT, PAGINATION], filters);
  const [cursor, limit] = props([CURSOR, LIMIT], pagination);
  const { extractId, success, resetAlerts } = alerts;
  const { fetchInvoices, updateInvoices } = useInvoicesActions();

  useEffect(() => {
    if (hasAccess) {
      extractId(
        fetchInvoices({
          [SEARCH]: search,
          [STATUS]: status === ALL ? '' : status,
          [LIMIT]: limit,
          [CURSOR]: cursor,
          ...sort,
        })
      );
    }
  }, [extractId, fetchInvoices, hasAccess, search, status, limit, cursor, sort]);

  useEffect(() => {
    if (!hasAccess && success) {
      updateInvoices([]);
      resetAlerts();
    }
  }, [hasAccess, resetAlerts, success, updateInvoices]);

  return {
    list,
    status,
    search,
    sort,
    pagination,
    ...alerts,
  };
};

export const useInitPatientTarmeds = (hasAccess, { patientId }) => {
  const data = usePatientTarmeds();
  const filters = useFilters(TARMEDS_FILTERS);
  const [search, sort] = props([SEARCH, SORT], filters);
  const [sortBy, sortDir] = props([SORT_BY, SORT_DIR], sort);
  const { fetchPatientTarmeds } = useInvoicesActions();
  const { action, loading, success, error } = useAlerts(fetchPatientTarmeds);

  useEffect(() => {
    if (hasAccess && patientId) {
      action({ [SUB_PATIENT]: patientId, [LIMIT]: 50, [SEARCH]: search, [SORT_BY]: sortBy, [SORT_DIR]: sortDir });
    }
  }, [action, hasAccess, patientId, search, sortBy, sortDir]);

  return {
    list: data,
    search,
    loading,
    success,
    error,
  };
};

export const useInitCantonDashboard = () => {
  const { fetchCantonDashboard } = useInvoicesActions();
  const canton = useFilters(CANTON_FILTERS);
  const year = useFilters(YEAR_FILTERS);
  const { action, loading, success, error } = useAlerts(fetchCantonDashboard);

  useEffect(() => {
    action({ [CANTON]: canton, [YEAR]: year });
  }, [action, canton, year]);

  return { list: success, canton, year, loading, error };
};
