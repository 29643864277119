import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { PATIENT_REPORTS } from 'store/patients';

export const useTogglePanel = () => {
  const { push, location } = useHistory();
  const { hash, pathname, state } = location;

  return [hash === `#${PATIENT_REPORTS}`, useCallback(() => push(pathname, state), [pathname, push, state])];
};
