import { useEffect, useRef, useMemo } from 'react';
import { propEq, props, values, map, pipe, length } from 'ramda';

import { parseDate } from 'utils';
import {
  useAllTarmeds,
  SUB_SERVICE,
  ID,
  DATE,
  QUANTITY,
  CODE,
  REF_CODE,
  SESSION,
  EXCLUDES,
  BODY_LOCATION,
  DURATION_IN_MINUTES,
} from 'store/invoices';

export const useGetScrollWidth = () => {
  const width = useRef();

  useEffect(() => {
    const div = document.createElement('div');

    div.style.overflowY = 'scroll';
    div.style.width = '25px';
    div.style.height = '25px';
    div.style.opacity = '0';

    document.body.append(div);
    width.current = div.offsetWidth - div.clientWidth;
    div.remove();
  }, []);

  return width.current;
};

const getComparedList = (list, items, defaultDate) =>
  list.map((service = {}) => ({
    ...items.find((item = {}) => propEq(service[SUB_SERVICE], ID, item)),
    [SESSION]: service[SESSION],
    [DATE]: service[DATE] || defaultDate || '',
    [QUANTITY]: service[QUANTITY],
    ...(typeof service[DURATION_IN_MINUTES] === 'number' && { [DURATION_IN_MINUTES]: service[DURATION_IN_MINUTES] }),
  }));
const getErrorMessage = ({ code, date }) => `Die Tarmed-Position ${code} wurde für den ${parseDate(date)} mehrfach erfasst.`;
const getRefErrorMessage = ({ code, refCode }) =>
  `Um die Tarmed-Position ${code} zu verrechnen, muss auch die Tarmed-Position ${refCode} erfasst werden.`;
const getSessionErrorMessage = ({ code, sessionCodeError }) =>
  `Die Tarmed-Position ${code} darf nicht in der gleichen Session wie ${sessionCodeError} verrechnet werden.`;
const getIncorrect1100ErrorMessage = () =>
  'Verwenden Sie die Tarmed-Position Fundusaufnahmen, beidseitig (08.1110), um eine Fundusaufnahme für beide Augen zu erfassen.';
const getIncorrect1120ErrorMessage = () =>
  'Verwenden Sie die Tarmed-Position Funduspanorama, beidseitig (08.1130), um ein Funduspanorama für beide Augen zu erfassen.';
const comparator = (acc, items = []) => {
  items.forEach((item, index) => {
    if (!acc[item.index]) acc[item.index] = [];
    if (item.refCodeError) acc[item.index].push(getRefErrorMessage(item));

    const match = items.find(({ compareDate }, i) => i !== index && compareDate === item.compareDate);

    if (match && !acc[match.index]) acc[match.index] = [];
    if (match) {
      acc[item.index].push(getErrorMessage(item));
      acc[match.index].push(getErrorMessage(match));
    }

    if (item.sessionCodeError) acc[item.index].push(getSessionErrorMessage(item));
    if (item.code1100) acc[item.index].push(getIncorrect1100ErrorMessage());
    if (item.code1120) acc[item.index].push(getIncorrect1120ErrorMessage());
  });

  return acc;
};
const validateList = (list = []) => {
  const extracted = list.reduce((acc, service, index) => {
    const [id, date, code, refCode, session, excludes, bodyLocation] = props(
      [ID, DATE, CODE, REF_CODE, SESSION, EXCLUDES, BODY_LOCATION],
      service || {}
    );
    if (!acc[id]) acc[id] = [];

    const errors = { refCodeError: Boolean(refCode), sessionCodeError: null, code1100: null, code1120: null };
    list.forEach((item) => {
      if (refCode && item[CODE] === refCode) errors.refCodeError = false;
      if (!errors.sessionCodeError && excludes?.length && excludes.includes(item[CODE]) && session === item[SESSION])
        errors.sessionCodeError = item[CODE];
      if (code === '08.1100' && (item[CODE] === '08.1110' || (item[CODE] === code && bodyLocation !== item[BODY_LOCATION])))
        errors.code1100 = item[CODE];
      if (code === '08.1120' && (item[CODE] === '08.1130' || (item[CODE] === code && bodyLocation !== item[BODY_LOCATION])))
        errors.code1120 = item[CODE];
      if (code === '08.1110' && item[CODE] === '08.1100') errors.code1100 = item[CODE];
      if (code === '08.1130' && item[CODE] === '08.1120') errors.code1120 = item[CODE];
    });

    acc[id].push({
      index,
      date,
      compareDate: date.replace(/T.+/, ''),
      code,
      refCode,
      ...errors,
    });

    return acc;
  }, {});

  return values(extracted).reduce(comparator, {});
};

export const useValidationTarmeds = (compareList, defaultDate) => {
  const data = useAllTarmeds();
  const list = useMemo(() => getComparedList(compareList, data || [], defaultDate), [compareList, data, defaultDate]);
  const allErrors = useMemo(() => validateList(list), [list]);
  const errors = map(pipe(length, Boolean), allErrors);
  const errorMessages = useMemo(
    () =>
      values(allErrors).reduce((acc, messages) => {
        messages.forEach((msg) => {
          if (!acc.includes(msg)) acc.push(msg);
        });

        return acc;
      }, []),
    [allErrors]
  );

  return [list, errors, errorMessages];
};
