import React, { useMemo, useCallback, useEffect, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { any, pipe, prop, length } from 'ramda';

import { useCurrentSchedule, usePlanningActions, useAlerts, REGISTRATIONS, SHIFTS } from 'store/planning';
import { useAsyncState } from 'utils/useAsyncState';
import Spinner from 'components/FullScreenSpinner';

import { getWardStructure } from './utils';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const Steps = () => {
  const { id } = useParams();
  const [registrations, shifts] = useCurrentSchedule([REGISTRATIONS, SHIFTS]);
  const { fetchScheduleForms } = usePlanningActions();
  const { action, loading, success } = useAlerts(fetchScheduleForms);
  const [step, setStep] = useAsyncState(2);
  const [xlsFile, setXlsFile] = useAsyncState(null);
  const [formMedia, setFormMedia] = useAsyncState();
  const isShiftsInPast = useMemo(() => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    return any(
      any((d) => new Date(d).valueOf() < today.valueOf()),
      shifts
    );
  }, [shifts]);
  const hasRegistrations = useMemo(
    () => registrations.length > 0 || any(pipe(prop('items'), length))(formMedia || []),
    [formMedia, registrations.length]
  );

  const fetchMedia = useCallback(() => action(id), [action, id]);

  useEffect(() => {
    fetchMedia();
  }, [fetchMedia]);

  useEffect(() => {
    if (success) setFormMedia(getWardStructure(success));
  }, [success, setFormMedia]);

  useLayoutEffect(() => {
    if (isShiftsInPast) {
      setStep(1);

      return;
    }
    if (hasRegistrations) setStep(3);
  }, [hasRegistrations, isShiftsInPast, setStep]);

  if (!formMedia && loading) return <Spinner height="calc(100vh - 200px)" />;

  return (
    (step === 1 && <Step1 setStep={setStep} />) ||
    (step === 2 && <Step2 setStep={setStep} />) ||
    (step === 3 && (
      <Step3
        setStep={setStep}
        xlsFile={xlsFile}
        setXlsFile={setXlsFile}
        formMedia={formMedia}
        setFormMedia={setFormMedia}
        refetchFormMedia={fetchMedia}
        formMediaLoading={loading}
      />
    ))
  );
};

export default Steps;
