import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid var(--color-grey6A);
  padding-top: 3px;
  margin-top: 20px;
`;

export const Title = styled.p`
  font-size: var(--font-size15);
  font-weight: var(--font-weight600);
  line-height: 1.2;
  letter-spacing: 0.02px;
  color: var(--color-grey6A);
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100px;
`;

export const Text = styled.p`
  font-size: var(--font-size18);
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: var(--color-grey97);
`;
