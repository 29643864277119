import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  position: relative;
  width: 22px;
  height: 16px;
`;

const transition = {
  type: 'keyframes',
  times: [0, 0.5, 1],
  duration: 0.5,
};

const style = `
  position: absolute;
  bottom: 8px;
  width: 16px;
  height: 2px;
  background-color: var(--color-grey97);
  border-radius: 0;
`;

const getLeftAnimate = ({ isOpen }) => ({
  animate: {
    rotate: isOpen ? ['45deg', '90deg', '135deg'] : ['135deg', '90deg', '45deg'],
    left: isOpen ? [-2, 3, -2.0001] : [-2.0001, 3, -2],
    borderRadius: isOpen ? [0, 1, 0.0001] : [0.0001, 1, 0],
  },
  transition,
  initial: false,
});

export const Left = styled(motion.div).attrs(getLeftAnimate)`
  ${style}
  left: -2px;
  transform: rotate(${({ defaultOpen }) => (defaultOpen ? 135 : 45)}deg);
`;

const getRightAnimate = ({ isOpen }) => ({
  animate: {
    rotate: isOpen ? ['135deg', '90deg', '45deg'] : ['45deg', '90deg', '135deg'],
    left: isOpen ? [8, 3, 8.0001] : [8.0001, 3, 8],
    borderRadius: isOpen ? [0, 1, 0.0001] : [0.0001, 1, 0],
  },
  transition,
  initial: false,
});

export const Right = styled(motion.div).attrs(getRightAnimate)`
  ${style}
  left: 8px;
  transform: rotate(${({ defaultOpen }) => (defaultOpen ? 45 : 135)}deg);
`;
