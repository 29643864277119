export const FEATURE_NAME = 'examinations';

export const CURRENT = 'current';
export const LIST = 'list';
export const BLOCKED = 'blocked';
export const FILTERS = 'filters';

export const RIGHT = 'right';
export const LEFT = 'left';

export const PROGRESS = 'progress';

export const VIEW = 'eyeExterior';
export const FUNDUS = 'fundus';
export const PRESSURE = 'eyePressure';
export const VISUAL_ACUITY = 'visualAcuity';

export const PUPIL_SIZE = 'pupilSize';
export const SPHERE = 'sphere';
export const CYLINDER = 'cylinder';
export const AXIS = 'axis';
export const CONFIDENCE = 'confidence';
export const FILES = 'files';
export const COMMENT = 'comment';

export const GET_LOCKED_EXAMINATIONS = 'GET_LOCKED_EXAMINATIONS';
export const LOCKED_EXAMINATIONS = 'LOCKED_EXAMINATIONS';
export const LOCK_EXAMINATION = 'LOCK_EXAMINATION';
export const UNLOCK_EXAMINATION = 'UNLOCK_EXAMINATION';
export const EXAMINATION_UPDATED = 'EXAMINATION_UPDATED';

export const MOBILITY = 'mobility';
export const MOBILITY_ITEMS = [
  'im Rollstuhl',
  'Gehstock',
  'im Pflegerollstuhl',
  'am Rollator',
  'selbständig mobil',
  'bettlägrig',
];

export const WITHOUT_CORRECTION = 'withoutCorrection';
export const WITH_GLASSES = 'withGlasses';
export const BEST_CORRECTION = 'bestCorrection';
export const CORRECTION_ITEMS = [
  { id: '', label: '-' },
  { id: 'keine Lichtwahrnehmung', label: 'keine Lichtwahrnehmung' },
  { id: 'nur Lichtwahrnehmung', label: 'nur Lichtwahrnehmung' },
  { id: 'Fingerzählen', label: 'Fingerzählen' },
  { id: 'Handbewegungen', label: 'Handbewegungen' },
  { id: '< 0.1', label: '< 0.1' },
  { id: '0.1', label: '0.1' },
  { id: '0.25', label: '0.25' },
  { id: '0.32', label: '0.32' },
  { id: '0.4', label: '0.4' },
  { id: '0.5', label: '0.5' },
  { id: '0.63', label: '0.63' },
  { id: '0.8', label: '0.8' },
  { id: '1.0', label: '1.0' },
];
export const BEST_CORRECTION_ITEMS = [
  { id: '', label: '-' },
  { id: 'keine Lichtwahrnehmung', label: 'keine Lichtwahrnehmung' },
  { id: 'nur Lichtwahrnehmung', label: 'nur Lichtwahrnehmung' },
  { id: 'Fingerzählen', label: 'Fingerzählen' },
  { id: 'Handbewegungen', label: 'Handbewegungen' },
  { id: 'Gläser bessern nicht', label: 'Gläser bessern nicht' },
  { id: 'andere Gläser bessern nicht', label: 'andere Gläser bessern nicht' },
  { id: '< 0.1', label: '< 0.1' },
  { id: '0.1', label: '0.1' },
  { id: '0.125', label: '0.125' },
  { id: '0.16', label: '0.16' },
  { id: '0.2', label: '0.2' },
  { id: '0.25', label: '0.25' },
  { id: '0.32', label: '0.32' },
  { id: '0.4', label: '0.4' },
  { id: '0.5', label: '0.5' },
  { id: '0.63', label: '0.63' },
  { id: '0.8', label: '0.8' },
  { id: '1.0', label: '1.0' },
];

export const EXAMINATION_STATUS = {
  IN_PREPARATION: 0,
  SUBMITTED: 1,
  UNDER_REVIEW: 2,
  IN_APPROVAL: 3,
  APPROVED: 4,
  RELEASED: 5,
  SENT: 6,
  ABORTED: 7,
};

export const EXAMINATION_LABELS = {
  [EXAMINATION_STATUS.IN_PREPARATION]: 'In Bearbeitung',
  [EXAMINATION_STATUS.SUBMITTED]: 'Zur Befundung übermittelt',
  [EXAMINATION_STATUS.UNDER_REVIEW]: 'In Befundung',
  [EXAMINATION_STATUS.IN_APPROVAL]: 'Warten auf Zustimmung',
  [EXAMINATION_STATUS.APPROVED]: 'Zugestimmt',
  [EXAMINATION_STATUS.RELEASED]: 'Für Versand freigegeben',
  [EXAMINATION_STATUS.SENT]: 'Bericht gesendet',
  [EXAMINATION_STATUS.ABORTED]: 'Abgebrochen',
};
