import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import Spinner from 'components/FullScreenSpinner';

import { Container, P, PW, Plus } from './styles';

const accept = ['image/png', 'image/jpeg', 'application/pdf'];

const Dropzone = ({ onDrop, loading, disabled }) => {
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
    accept,
    disabled,
  });

  if (loading)
    return (
      <Container>
        <Spinner height="100%" />
      </Container>
    );

  return (
    <Container isActive={isDragActive} isWarn={isDragReject} {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragReject ? (
        <>
          <PW>Gültige Dateiformate</PW>
          <PW>.JPEG</PW>
          <PW>.PNG</PW>
          <PW>.PDF</PW>
        </>
      ) : (
        <>
          <Plus isActive={isDragActive}>+</Plus>
          <P isActive={isDragActive}>Dateien in dieses</P>
          <P isActive={isDragActive}>Feld ziehen</P>
        </>
      )}
    </Container>
  );
};

Dropzone.defaultProps = {
  onDrop: () => {},
  loading: false,
  disabled: false,
};

Dropzone.propTypes = {
  onDrop: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Dropzone;
