import { cond, equals, T } from 'ramda';

import { KIND, SPECIALTY, DOCTOR, OPHTHALMOLOGY, ORGANIZATION_KINDS } from 'store/organizations';

export const getData = (kind = '', specialty = '') =>
  cond([
    [equals(ORGANIZATION_KINDS.NURSING_HOME), (k) => ({ name: 'Neues Pflegezentrum', body: { [KIND]: k } })],
    [equals(ORGANIZATION_KINDS.OPTICIAN), (k) => ({ name: 'Neuer Optiker', body: { [KIND]: k } })],
    [
      (k, s) => k === ORGANIZATION_KINDS.CLINIC && s === DOCTOR,
      (k, s) => ({ name: 'Neuer Hausarzt', body: { [KIND]: k, [SPECIALTY]: s } }),
    ],
    [
      (k, s) => k === ORGANIZATION_KINDS.CLINIC && s === OPHTHALMOLOGY,
      (k, s) => ({ name: 'Neuer Augenarzt', body: { [KIND]: k, [SPECIALTY]: s } }),
    ],
    [T, () => ({})],
  ])(kind, specialty);
