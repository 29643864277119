import React, { useMemo, useCallback } from 'react';
import { join, filter, pipe, props } from 'ramda';
import PropTypes from 'prop-types';

import { NAME, ZIP_CODE, EMAIL } from 'store/organizations';
import { STREET, TOWN, COUNTRY, PHONE } from 'store/patients';

import { ReactComponent as CrossIcon } from 'assets/svg-icons/cross.svg';
import { Container, Wrapper, Text, Button } from './styles';

const Item = ({ item, onDelete, disabled }) => {
  const [name, address, cityCountry, phone, email] = useMemo(
    () => [
      item[NAME],
      item[STREET],
      pipe(props([ZIP_CODE, TOWN, COUNTRY]), filter(Boolean), join(' '))(item),
      item[PHONE],
      item[EMAIL],
    ],
    [item]
  );
  const handleDelete = useCallback(() => onDelete(item), [onDelete, item]);

  return (
    <Container>
      <Wrapper>
        {Boolean(name) && <Text>{name}</Text>}
        {Boolean(address) && <Text>{address}</Text>}
        {Boolean(cityCountry) && <Text>{cityCountry}</Text>}
        {Boolean(phone || email) && <br />}
        {Boolean(phone) && <Text>{phone}</Text>}
        {Boolean(email) && <Text>{email}</Text>}
      </Wrapper>
      {!disabled && (
        <Button type="button" onClick={handleDelete}>
          <CrossIcon />
        </Button>
      )}
    </Container>
  );
};

Item.defaultProps = { disabled: false };
Item.propTypes = {
  item: PropTypes.shape({
    [NAME]: PropTypes.string,
    [STREET]: PropTypes.string,
    [ZIP_CODE]: PropTypes.string,
    [TOWN]: PropTypes.string,
    [COUNTRY]: PropTypes.string,
    [PHONE]: PropTypes.string,
    [EMAIL]: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default Item;
