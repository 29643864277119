import React from 'react';

import { tableHeadData } from '../consts';

import { Container, Item } from './styles';

const ListHeader = () => (
  <Container>
    {tableHeadData.map(({ attribute, label, fieldWidth }) => (
      <Item key={attribute} width={fieldWidth}>
        {label}
      </Item>
    ))}
  </Container>
);

export default ListHeader;
