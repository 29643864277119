import styled from 'styled-components';

export const ClickableCircle = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  &:hover {
    cursor: pointer;
  }
`;

export const IconRotation = styled.div`
  transform: rotate(${({ rotated }) => (rotated ? 45 : 0)}deg);

  svg {
    g {
      fill: ${({ rotated }) => (rotated ? 'var(--color-white)' : 'none')};
    }
  }
`;
