import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { path, is } from 'ramda';
import PropTypes from 'prop-types';

import { KIND, useAlerts, useOrganizationActions } from 'store/organizations';
import AddItemBar from 'components/AddItemBar';
import NavbarItem from 'components/NavbarItem';
import NavbarLink from 'components/NavbarLink';

import { items, LABELS } from './utils';
import List from './List';
import { Container } from './styles';

const Organizations = ({ history, location }) => {
  const { createOrganization } = useOrganizationActions();
  const { extractId, resetAlerts, ...alerts } = useAlerts();

  const [kind, setKind] = useState(path(['state', KIND], location) || path([0, 'key'], items));

  const createNursingHome = useCallback(() => {
    if (alerts.loading) return;

    extractId(createOrganization({ [KIND]: kind }));
  }, [createOrganization, extractId, kind, alerts.loading]);

  useEffect(() => {
    if (is(String, alerts.success)) {
      history.push(`/organizations/${alerts.success}`, { [KIND]: kind });
      resetAlerts();
    }
  }, [history, kind, resetAlerts, alerts.success]);

  const labels = useMemo(() => LABELS[kind], [kind]);

  return (
    <Container>
      <NavbarLink width={8 / 12} mb={20}>
        {items.map(({ label, key }) => (
          <NavbarItem key={key} isActive={key === kind} onClick={() => setKind(key)}>
            {label}
          </NavbarItem>
        ))}
      </NavbarLink>
      <AddItemBar label={labels.NEW} onClick={createNursingHome} disabled={alerts.loading} />
      <List key={kind} kind={kind} />
    </Container>
  );
};

Organizations.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      kind: PropTypes.number,
    }),
  }).isRequired,
};

export default Organizations;
