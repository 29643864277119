import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { pipe, keys, reduce } from 'ramda';

import { saveMedicalHistory } from 'store/medicalHistory/actions';
import { getMedicalHistoryProp } from 'store/medicalHistory/selectors';
import {
  CARE_LEVEL,
  VALUE,
  DESCRIPTION,
  DATE,
  ILLNESSES,
  ALLERGIES,
  SURGERY,
  HEART,
  UNDER_TREATMENT,
  EYE_DROPS,
  DRUGS,
  LIST,
  BLOOD_PRESSURE,
  DIABETES,
  ASTHMA,
  SMOKING,
  ALCOHOL,
  FILES,
  COMMENT,
  DEMENTIA,
} from 'store/medicalHistory';

import MedicalHistoryForm from './component';

const defaultValues = {
  [CARE_LEVEL]: null,
  [DIABETES]: '',
  [DEMENTIA]: '',
  [UNDER_TREATMENT]: {
    [VALUE]: null,
    [DESCRIPTION]: '',
  },
  [SURGERY]: {
    [VALUE]: null,
    [DESCRIPTION]: '',
    [DATE]: '',
  },
  [EYE_DROPS]: {
    [VALUE]: null,
    [LIST]: [],
  },
  [DRUGS]: {
    [VALUE]: null,
    [LIST]: [],
  },
  [BLOOD_PRESSURE]: '',
  [ASTHMA]: '',
  [HEART]: {
    [VALUE]: null,
    [DESCRIPTION]: '',
    [DATE]: '',
  },
  [ILLNESSES]: {
    [VALUE]: null,
    [DESCRIPTION]: '',
    [DATE]: '',
  },
  [ALLERGIES]: {
    [VALUE]: null,
    [DESCRIPTION]: '',
  },
  [SMOKING]: '',
  [ALCOHOL]: '',
  [FILES]: [],
  [COMMENT]: '',
};

export const mapPropsToValues = (props) =>
  Object.entries(defaultValues).reduce((acc, [key, value]) => {
    if (key === FILES) {
      acc[key] = props[key] || value;
      return acc;
    }

    if (typeof value === 'object' && !props[key]) {
      acc[key] = value;
    } else {
      acc[key] = props[key];
    }

    if (key === DRUGS || key === EYE_DROPS) {
      acc[key][VALUE] = typeof acc[key][VALUE] === 'boolean' ? acc[key][VALUE] : null;
      acc[key][LIST] = acc[key][LIST].filter(Boolean);
    }

    return acc;
  }, {});

const sendData = (values, { updateHistory }) => {
  updateHistory(values);
};

const mapStateToProps = (state) =>
  pipe(
    keys,
    reduce((acc, key) => {
      acc[key] = getMedicalHistoryProp(key)(state);
      return acc;
    }, {})
  )(defaultValues);

export default pipe(
  withFormik({
    mapPropsToValues,
    validate: sendData,
    handleSubmit: (_, { setSubmitting }) => {
      setSubmitting(false);
    },
    validateOnBlur: false,
  }),
  connect(mapStateToProps, { updateHistory: saveMedicalHistory })
)(MedicalHistoryForm);
