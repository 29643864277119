import React, { useMemo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { values } from 'ramda';
import PropTypes from 'prop-types';

import { useOrganizationActions, useAlerts, KIND, ORGANIZATION_KINDS, DOCTOR, OPHTHALMOLOGY } from 'store/organizations';
import Icon from 'components/PaginationDropDown/Icon';

import { getData } from './utils';
import { Container, Text } from './styles';

const CreateOrganizationItem = ({ kind, specialty }) => {
  const { push } = useHistory();
  const { name, body } = useMemo(() => getData(kind, specialty), [kind, specialty]);
  const { createOrganization } = useOrganizationActions();
  const { extractId, loading, success } = useAlerts();
  const handleClick = useCallback(() => extractId(createOrganization(body)), [body, createOrganization, extractId]);

  useEffect(() => {
    if (success) push(`/organizations/${success}`, { [KIND]: kind });
  }, [kind, push, success]);

  if (!(name && body)) return null;

  return (
    <Container type="button" onClick={handleClick} disabled={loading}>
      <Text>{name}</Text>
      <Icon />
    </Container>
  );
};

CreateOrganizationItem.defaultProps = { specialty: null };
CreateOrganizationItem.propTypes = {
  kind: PropTypes.oneOf(values(ORGANIZATION_KINDS)).isRequired,
  specialty: PropTypes.oneOf([DOCTOR, OPHTHALMOLOGY]),
};

export default CreateOrganizationItem;
