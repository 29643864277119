import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g fill="none" fillRule="evenodd">
      <g stroke="#979797" strokeWidth="1.5">
        <g>
          <g>
            <path d="M0 0L16 16M0 16L16 0" transform="translate(12 12)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
