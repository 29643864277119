import styled from 'styled-components';

import QuestionContainer from 'components/QuestionContainer';
import SelectDef from 'components/SelectNew';
import Msg from 'components/Message';

export const Section = styled(QuestionContainer)`
  margin: 20px 0 50px;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 370px;
  gap: 25px;
  width: 100%;
  padding-top: 10px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
`;

export const Item = styled.div`
  display: grid;
  grid-template-columns: 130px repeat(3, auto);
  justify-content: start;
  column-gap: 20px;
  width: 100%;
  position: relative;
`;

export const Label = styled.p`
  align-self: center;
  font-size: var(--font-size15);
  font-weight: var(--font-weight400);
  color: var(--color-grey6A);
`;

export const Select = styled(SelectDef).attrs(() => ({ mb: 0, mini: true }))`
  align-self: flex-start;
  width: auto;
`;

export const SmallText = styled.p`
  align-self: flex-start;
  font-size: var(--font-size10);
  font-weight: var(--font-weight500);
  color: var(--color-grey6A);
  line-height: 26px;
`;

export const Message = styled(Msg).attrs(() => ({
  type: 'error',
  form: 1,
  small: 1,
}))`
  position: absolute;
  inset: 26px 0 auto 140px;
`;

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &:after {
    content: ${({ $error }) => ($error ? `'${$error}'` : 'none')};
    font-size: var(--font-size13);
    color: var(--color-orangeDC);
    margin-top: 3px;
  }
`;
