import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { prop, values, props, omit } from 'ramda';

import getWebSocket from 'api/webSocket';
import { wrapActions, wrapSelector, wrapSelectorWithArg, wrapMultiSelector } from 'store/utils';
import { useUser, useAlerts } from 'store/session';
import { ID, SEARCH, SORT, PAGINATION, CURSOR, LIMIT, SORT_BY, SORT_DIR, DESC, DATE, STATUS, SUB_PATIENT } from 'api/consts';

import {
  getExaminationsFilters,
  getExaminations,
  getExamination,
  getCurrentExaminationId,
  getCurrentExamination,
  getCurrentExaminationProp,
  getOwnerBlockedExaminations,
} from './selectors';
import * as actions from './actions';
import {
  GET_LOCKED_EXAMINATIONS,
  LOCKED_EXAMINATIONS,
  LOCK_EXAMINATION,
  UNLOCK_EXAMINATION,
  EXAMINATION_UPDATED,
  EXAMINATION_STATUS,
} from './consts';

export const useExaminationsActions = wrapActions({ ...actions });

export const useExaminations = () => {
  const examinations = useSelector(getExaminations);

  return useMemo(() => values(examinations), [examinations]);
};
export const useExamination = wrapSelectorWithArg(getExamination);
export const useCurrentExaminationId = wrapSelector(getCurrentExaminationId);
export const useCurrentExamination = wrapMultiSelector(getCurrentExaminationProp, getCurrentExamination);

export const useWebsocketConnect = (hasAccess) => {
  const { updateBlockedExaminations, updateExaminationProgress } = useExaminationsActions();

  useEffect(() => {
    if (!hasAccess) return () => null;

    const socket = getWebSocket(hasAccess, 'examination');

    socket.on(LOCKED_EXAMINATIONS, updateBlockedExaminations);
    socket.on(EXAMINATION_UPDATED, updateExaminationProgress);
    socket.emit(GET_LOCKED_EXAMINATIONS);

    return () => {
      socket.off(LOCKED_EXAMINATIONS, updateBlockedExaminations);
      socket.off(EXAMINATION_UPDATED, updateExaminationProgress);
    };
  }, [hasAccess, updateBlockedExaminations, updateExaminationProgress]);

  useEffect(
    () => () => {
      const socket = getWebSocket(hasAccess, 'examination') || {};

      if (hasAccess && socket?.connected) socket.disconnect();
    },
    [hasAccess]
  );
};

export const useWebsocketActions = (hasAccess) => {
  const webSocket = getWebSocket(hasAccess, 'examination');

  const { saveExamination } = useExaminationsActions();

  const lockExamination = useCallback(
    (examId) => {
      if (hasAccess) {
        webSocket.emit(LOCK_EXAMINATION, { [ID]: examId });
      }
    },
    [hasAccess, webSocket]
  );
  const unlockExamination = useCallback(
    (examId) => {
      if (hasAccess) {
        webSocket.emit(UNLOCK_EXAMINATION, { [ID]: examId });
      }
    },
    [hasAccess, webSocket]
  );
  const updateExamination = useCallback(
    (examination) => {
      if (hasAccess) {
        webSocket.emit(EXAMINATION_UPDATED, examination);

        saveExamination(examination);
      }
    },
    [hasAccess, saveExamination, webSocket]
  );

  return {
    lockExamination,
    unlockExamination,
    updateExaminationProgress: updateExamination,
  };
};

export const useBlockedExaminationData = () => {
  const userId = useUser(ID);
  const [examId, status] = useCurrentExamination([ID, STATUS]);
  const owner = useSelector(getOwnerBlockedExaminations(examId));
  const isOwner = useMemo(() => Boolean(owner && owner[ID] === userId), [owner, userId]);
  const userName = useMemo(
    () => [prop('title', owner || {}), prop('firstname', owner || {}), prop('lastname', owner || {})].filter(Boolean).join(' '),
    [owner]
  );

  return {
    isBlocked: Boolean(owner),
    isOwner,
    userName,
    examId,
    status,
  };
};

export const useBlockedExamination = (canRead, canUpdate) => {
  const { isBlocked, isOwner, userName, examId, status } = useBlockedExaminationData(canRead);

  const { lockExamination, unlockExamination } = useWebsocketActions(canUpdate);

  useEffect(() => {
    if (!isBlocked && status === EXAMINATION_STATUS.IN_PREPARATION) {
      lockExamination(examId);
    }
  }, [examId, isBlocked, lockExamination, status]);

  useEffect(
    () => () => {
      if (isOwner && status === EXAMINATION_STATUS.IN_PREPARATION) {
        unlockExamination(examId);
      }
    },
    [examId, isOwner, status, unlockExamination]
  );

  return canRead ? { isBlocked: isBlocked && !isOwner, userName } : {};
};

export const usePatientExaminations = (hasAccess, patientId) => {
  const alerts = useAlerts();
  const { id, extractId } = alerts;
  const { fetchExaminationsByPatientId, resetExaminations } = useExaminationsActions();

  useEffect(() => {
    if (hasAccess && !id) {
      extractId(
        fetchExaminationsByPatientId({
          [SUB_PATIENT]: patientId,
          [LIMIT]: 100,
          [CURSOR]: 0,
          [SORT_BY]: DATE,
          [SORT_DIR]: DESC,
        })
      );
    }
  }, [extractId, fetchExaminationsByPatientId, hasAccess, id, patientId]);

  useEffect(() => resetExaminations, [resetExaminations]);

  return hasAccess ? alerts : { success: true };
};

export const useFilteredExaminations = (hasAccess, status) => {
  const isInit = useRef(true);
  const filters = useSelector(getExaminationsFilters(status));
  const { fetchInitExaminations, fetchExaminations, updatePagination } = useExaminationsActions();
  const alerts = useAlerts();
  const { extractId, success } = alerts;
  const [search, sort, pagination] = props([SEARCH, SORT, PAGINATION], filters);
  const [cursor, limit] = props([CURSOR, LIMIT], pagination);

  useEffect(() => {
    if (!hasAccess) return;

    const requestAction = isInit.current ? fetchInitExaminations : fetchExaminations;

    extractId(
      requestAction({
        ...(search && { search }),
        ...(status === EXAMINATION_STATUS.UNDER_REVIEW ? { filter: 'IN_PROGRESS_OWN' } : { status }),
        [LIMIT]: limit,
        [CURSOR]: cursor,
        ...sort,
      })
    );
  }, [cursor, extractId, fetchInitExaminations, fetchExaminations, hasAccess, limit, search, sort, status]);

  useEffect(() => {
    if (success) {
      isInit.current = false;
      updatePagination({ kind: status, value: omit(['data'], success) });
    }
  }, [success, updatePagination, status]);

  return {
    list: success?.data || [],
    pagination,
    search,
    sort,
    ...alerts,
  };
};

export const useExaminationTasks = (hasAccess) => {
  const { fetchInitExaminations, updateExaminations, resetExaminations } = useExaminationsActions();
  const alerts = useAlerts();
  const { extractId, success } = alerts;

  useEffect(() => {
    if (hasAccess) {
      extractId(
        fetchInitExaminations({
          status: EXAMINATION_STATUS.IN_PREPARATION,
          [LIMIT]: 100,
          [CURSOR]: 0,
        })
      );
    }
  }, [extractId, fetchInitExaminations, hasAccess]);

  useEffect(() => {
    if (success) updateExaminations(success?.data || []);
  }, [success, updateExaminations]);

  useEffect(() => resetExaminations, [resetExaminations]);

  return hasAccess ? alerts : { success: true };
};
