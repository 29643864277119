import styled from 'styled-components';

export const Container = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: fit-content;
  margin-top: 10px;
`;

export const Span = styled.span`
  display: block;
  color: inherit;
  font-size: inherit;
`;
