import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Tasks from './Tasks';
import Grade from './Grade';
import { Container, Title, Wrapper, Text, Button, Content, TasksContainer, Warn } from './styles';

const Item = ({ id, patient, progress, grade, comment }) => {
  const { patientId, publicId, birth, name } = patient;
  const { push } = useHistory();

  const handleClick = useCallback(() => {
    push(`/patients/${patientId}/examination?exam=${id}`, { previousPath: '/patients/tasks' });
  }, [id, patientId, push]);

  return (
    <Container>
      <Title>
        <Wrapper>
          <Text>{publicId}</Text>
          <Text mx="40px">{birth}</Text>
          <Text>{name}</Text>
        </Wrapper>
        <Button type="button" onClick={handleClick}>
          öffnen
        </Button>
      </Title>
      <Content>
        <TasksContainer>
          <Tasks progress={progress} />
          {comment && <Warn>{comment}</Warn>}
        </TasksContainer>
        <Grade grade={grade} />
      </Content>
    </Container>
  );
};

Item.defaultProps = {
  comment: '',
};

Item.propTypes = {
  id: PropTypes.string.isRequired,
  patient: PropTypes.shape({
    patientId: PropTypes.string.isRequired,
    publicId: PropTypes.string,
    birth: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  progress: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  grade: PropTypes.number.isRequired,
  comment: PropTypes.string,
};

export default Item;
