import React, { useCallback, useEffect, useMemo } from 'react';

import api from 'api';
import { useAsyncState } from 'utils/useAsyncState';
import { EXAMINATION, NURSE, usePermissions } from 'permissions';
import { MODE, OFFLINE_MODE, ONLINE_MODE, ROLES, useUser, useSessionActions, useAlerts } from 'store/session';
import { useCurrentExaminationId } from 'store/examinations';
import Spinner from 'components/FullScreenSpinner';

import { useCacheLength } from './hooks';
import Icon from './Icon';
import { Switcher, Wrapper, Text } from './styles';

const CacheReport = () => {
  const can = usePermissions();
  const [userMode, roles] = useUser([MODE, ROLES]);
  const { saveUserData } = useSessionActions();
  const examId = useCurrentExaminationId();
  const { extractId, loading: updateModeLoading } = useAlerts();
  const hasAccess = useMemo(() => can.update(EXAMINATION, () => roles?.includes(NURSE)), [can, roles]);

  const length = useCacheLength();
  const [{ loading, success, error }, setAlerts] = useAsyncState({});
  const uploadFiles = useCallback(async () => {
    setAlerts({ loading: true });
    const data = await api.uploadFilesFromCache();
    setAlerts(data);
  }, [setAlerts]);

  const handleChange = useCallback(() => {
    extractId(saveUserData({ [MODE]: userMode === ONLINE_MODE ? OFFLINE_MODE : ONLINE_MODE }));
    if (userMode === OFFLINE_MODE && length && !(loading || success || error)) uploadFiles();
    if (userMode === ONLINE_MODE && length && error) setAlerts({});
  }, [error, extractId, length, loading, saveUserData, setAlerts, success, uploadFiles, userMode]);

  useEffect(() => {
    if (userMode === ONLINE_MODE && success) window.location.reload();
  }, [examId, setAlerts, success, userMode]);

  if (!hasAccess) return null;

  return (
    <>
      <Switcher
        label="ONLINE"
        checked={userMode === ONLINE_MODE}
        onChange={handleChange}
        allowChange={!(loading || updateModeLoading)}
      />
      {Boolean(length) && (
        <Wrapper error={Boolean(error)}>
          {loading ? (
            <Spinner size={25} height="40px" />
          ) : (
            <>
              <Text>{length}</Text>
              <Icon />
            </>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default CacheReport;
