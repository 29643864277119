import React, { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { pathOr } from 'ramda';
import PropTypes from 'prop-types';

import { usePlanningActions, useAlerts, ID, SUB_GP, SUB_EYE_DOCTOR } from 'store/planning';
import { useAsyncState } from 'utils/useAsyncState';
import PageHeader from 'components/PageHeader';
import Accordion from 'components/Accordion';
import Confirm from 'components/Confirm';
import UploadPDF from '../UploadPDF';

import { getPageTitle, getInitialValues, validationSchema, excludeErrorFields } from './utils';
import Patient from './Patient';
import Contact from './Contact';
import Clinic from './Clinic';
import Submit from './Submit';
import AutoSave from './AutoSave';
import Uploading from './Uploading';
import { Container, HeaderText, NavLink, Title, Message } from './styles';

const RegistrationForm = ({ data, shifts, sort }) => {
  const { push } = useHistory();
  const { id } = useParams();
  const { id: patientId, statusData, patient, isMatched, registrationForm } = data;
  const { label, color } = statusData || {};
  const [uploads, setUploads] = useAsyncState([]);
  const updateUploads = useCallback((arg) => setUploads(typeof arg === 'function' ? arg([]) : arg), [setUploads]);
  const redirectToBack = useCallback(() => push(`/planning/${id}`), [id, push]);
  const { saveRegistration, removePatientFromSchedule } = usePlanningActions();
  const { action, loading } = useAlerts(saveRegistration);
  const { action: remove, loading: removeLoading, error: removeErr } = useAlerts(removePatientFromSchedule);
  const title = useMemo(() => (patientId ? getPageTitle(data) : 'Neue Registrierung'), [data, patientId]);
  const initialValues = useMemo(() => getInitialValues(data, shifts), [data, shifts]);
  const [savingData, setSavingData] = useAsyncState();
  const onSubmit = useCallback(
    async (values) => {
      const fixedValues = {
        ...values,
        [SUB_EYE_DOCTOR]: pathOr(null, [SUB_EYE_DOCTOR, ID], values),
        [SUB_GP]: pathOr(null, [SUB_GP, ID], values),
      };

      try {
        const val = await validationSchema.validate(fixedValues, { abortEarly: false });

        setSavingData({ ...val, [ID]: patientId, sort });

        return {};
      } catch (yupErrors) {
        const errors = yupErrors.inner.reduce((acc, { path, message }) => {
          acc[path] = message;

          return acc;
        }, {});
        const filteredData = excludeErrorFields(fixedValues, errors);

        if (filteredData) setSavingData({ ...filteredData, [ID]: patientId, sort });

        return errors;
      }
    },
    [patientId, setSavingData, sort]
  );
  const onSave = useCallback(() => {
    if (savingData) action(savingData);
  }, [action, savingData]);
  const onDelete = useCallback(() => remove({ [ID]: patientId, sort }), [patientId, remove, sort]);

  return (
    <Container>
      <PageHeader title={title} redirectToBack={redirectToBack}>
        <HeaderText $color={color}>{label}</HeaderText>
      </PageHeader>
      {Boolean(patient && patient[ID]) && <NavLink to={`/patients/${patient[ID]}`}>Zum Patientendossier</NavLink>}
      <Formik initialValues={initialValues} validate={patientId && onSubmit} validateOnBlur={false}>
        <>
          {Boolean(savingData) && <AutoSave onSave={onSave} />}
          <Accordion label="Patient" defaultOpen>
            <Patient patientId={isMatched && patient ? patient[ID] : ''} shifts={shifts} isMatched={isMatched} />
            {Boolean(registrationForm) && <Uploading fileData={registrationForm} />}
          </Accordion>
          <Accordion label="Gesetzlicher Vertreter">
            <Contact disabled={isMatched} />
          </Accordion>
          <Accordion label="Hausarzt">
            <Clinic field={SUB_GP} disabled={isMatched} emptyMessage="Kein Hausarzt ausgewählt" />
          </Accordion>
          <Accordion label="Augenarzt">
            <Clinic field={SUB_EYE_DOCTOR} disabled={isMatched} emptyMessage="Kein Augenarzt ausgewählt" />
          </Accordion>
          {patientId ? (
            <>
              <Title>Registrierung löschen</Title>
              <Confirm title="Löschen" onClick={onDelete} disabled={loading || removeLoading} />
            </>
          ) : (
            <>
              <UploadPDF files={uploads} updateFiles={updateUploads} />
              <Submit pdfForm={uploads[0]} />
            </>
          )}
          {removeErr && <Message>{removeErr}</Message>}
        </>
      </Formik>
    </Container>
  );
};

RegistrationForm.defaultProps = { data: {} };
RegistrationForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    patient: PropTypes.shape({ [ID]: PropTypes.string.isRequired }),
    isMatched: PropTypes.bool,
    publicId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    birth: PropTypes.string,
    ward: PropTypes.string,
    date: PropTypes.string,
    statusData: PropTypes.shape({
      label: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
    registrationForm: PropTypes.shape({}),
  }),
  shifts: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string.isRequired).isRequired).isRequired,
  sort: PropTypes.shape({
    sortBy: PropTypes.string.isRequired,
    sortDir: PropTypes.string.isRequired,
  }).isRequired,
};

export default RegistrationForm;
