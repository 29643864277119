import React, { useMemo } from 'react';
import { propOr, prop } from 'ramda';
import PropTypes from 'prop-types';

import { useCurrentSchedule, ID, NAME, SUB_ORGANIZATION } from 'store/planning';
import { Text } from '../styles';

const OrganizationChecker = ({ organization, hasMistakes }) => {
  const scheduleOrganization = useCurrentSchedule(SUB_ORGANIZATION);
  const organizationName = useMemo(() => {
    const scheduleOrgId = prop(ID, scheduleOrganization || {});
    const patientOrgId = prop(ID, organization || {});

    return scheduleOrgId && patientOrgId !== scheduleOrgId && propOr('Keine Übereinstimmung', NAME, organization || {});
  }, [organization, scheduleOrganization]);

  if (organizationName)
    return (
      <>
        <Text>Pflegezentrum</Text>
        <Text $isRed>{organizationName}</Text>
      </>
    );

  return hasMistakes ? null : <Text>Keine Abweichung</Text>;
};

OrganizationChecker.defaultProps = { organization: null };
OrganizationChecker.propTypes = {
  organization: PropTypes.shape({
    [ID]: PropTypes.string.isRequired,
    [NAME]: PropTypes.string.isRequired,
  }),
  hasMistakes: PropTypes.bool.isRequired,
};

export default OrganizationChecker;
