import { useCallback, useEffect } from 'react';

import { getCache, FILES_MAP_CACHE } from 'api/cache';
import { useAsyncState } from 'utils/useAsyncState';

export const useCacheLength = () => {
  const [cache, setCache] = useAsyncState();
  const [length, setLength] = useAsyncState(0);

  const getCacheLength = useCallback(async () => {
    try {
      const mapCache = await getCache(FILES_MAP_CACHE);
      const amount = await mapCache.keys();

      setCache(($) => $ || mapCache);
      setLength(amount?.length ? amount.length : 0);
    } catch (e) {
      console.error(e.message); // eslint-disable-line no-console
    }
  }, [setCache, setLength]);

  useEffect(() => {
    getCacheLength();

    if (cache) {
      cache.addEvent(FILES_MAP_CACHE, getCacheLength);

      return () => cache.removeEvent(FILES_MAP_CACHE, getCacheLength);
    }

    return () => null;
  }, [cache, getCacheLength]);

  return length;
};
