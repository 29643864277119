/* eslint-disable react/no-array-index-key */
import React, { useMemo, useCallback } from 'react';
import { propOr, pathOr } from 'ramda';
import PropTypes from 'prop-types';

import {
  PUBLIC_ID,
  FIRST_NAME,
  LAST_NAME,
  BIRTH,
  INSURANCE,
  SSN,
  LAW_TYPE,
  LAW_TYPE_KINDS,
  LAW_TYPE_LABELS,
} from 'store/patients';
import { ID, NAME, ADDRESS, ZIP_CODE, CANTON, CITY } from 'store/organizations';
import {
  useAllTarmeds,
  SUB_INSURANCE,
  SUB_COPY,
  MED_DATA_MESSAGES,
  EXPLANATION,
  MESSAGES,
  BEXIO_DOC,
  BEXIO_ESR_ID,
  IS_USED,
  AMOUNT_DUE,
  CURRENCY,
  AMOUNT,
  KIND,
  INVOICE_KINDS,
  useInvoicesActions,
  useAlerts,
} from 'store/invoices';
import FullScreenSpinner from 'components/FullScreenSpinner';
import Accordion from 'components/Accordion';
import TextInput from 'components/TextInputNew';
import Button from 'components/Button';

import { parseBirthday } from 'utils';
import { getPrice, getDate, tableTemplate, prepareTarmeds } from './utils';
import { useInitData } from './hooks';
import Panel from './Panel';
import PdfSection from './PdfSection';
import {
  Container,
  NavLink,
  PinkBlock,
  Table,
  Text,
  BoldText,
  TR,
  TH,
  TD,
  CopyWrapper,
  ButtonLink,
  BoldTextWithParagraphs,
} from './styles';

const Invoice = ({ history, match }) => {
  const { id } = match.params;
  const allTarmeds = useAllTarmeds();
  const { resendInvoice } = useInvoicesActions();
  const { loading, extractId } = useAlerts();
  const { isLoading, invoice, patient, services, canResend, nursingHome } = useInitData(id);

  const tarmeds = useMemo(() => prepareTarmeds(allTarmeds, services), [allTarmeds, services]);
  const redirectBack = useCallback(() => history.push('/invoices'), [history]);
  const openInvoiceCopy = useCallback(() => history.push(`/invoices/${invoice[SUB_COPY]}`), [invoice, history]);
  const handleResend = useCallback(() => extractId(resendInvoice(id)), [id, extractId, resendInvoice]);

  if (isLoading) return <FullScreenSpinner />;

  const insurance = propOr({}, SUB_INSURANCE)(invoice);
  const mediaDataError = pathOr('', [MED_DATA_MESSAGES, EXPLANATION], invoice);
  const mediaDataMessages = pathOr([], [MED_DATA_MESSAGES, MESSAGES], invoice);

  return (
    <Container>
      <Panel invoice={invoice} redirectBack={redirectBack} disabled={loading} />
      <NavLink to={`/patients/${patient[ID]}`}>Zum Patientendossier</NavLink>
      <PdfSection />
      {(mediaDataError || mediaDataMessages.length > 0) && (
        <Accordion defaultOpen label="Fehlermeldung">
          {mediaDataError && (
            <PinkBlock>
              <BoldTextWithParagraphs>{mediaDataError}</BoldTextWithParagraphs>
            </PinkBlock>
          )}
          {mediaDataMessages.length > 0 && (
            <PinkBlock>
              {mediaDataMessages.map(({ code, text }, idx) => (
                <div key={idx}>
                  {code && <BoldText>{`Code: ${code}`}</BoldText>}
                  <Text>{text}</Text>
                </div>
              ))}
            </PinkBlock>
          )}
        </Accordion>
      )}
      {invoice[SUB_COPY] && (
        <CopyWrapper>
          Diese Rechnung wurde{' '}
          <ButtonLink type="button" onClick={openInvoiceCopy}>
            neu gesendet
          </ButtonLink>
          .
        </CopyWrapper>
      )}
      <Accordion defaultOpen label="Rechnung">
        <TextInput value={id || '–'} label="Rechnungs-ID" readMode />
        <TextInput value={INVOICE_KINDS[invoice[KIND]] || INVOICE_KINDS.TIERS_PAYANT} label="Vergütung" readMode />
        <TextInput value={LAW_TYPE_LABELS[invoice[LAW_TYPE]] || LAW_TYPE_LABELS[LAW_TYPE_KINDS.KVG]} label="Gesetz" readMode />
        <TextInput value={invoice[BEXIO_DOC] || '–'} label="Bexio-Nr." readMode />
        <TextInput value={invoice[BEXIO_ESR_ID] || '–'} label="ESR-ID" readMode />
        <TextInput value={getDate(invoice[IS_USED]) || '–'} label="Rechnungsdatum" readMode />
        <TextInput value={getPrice(invoice[CURRENCY], invoice[AMOUNT])} label="Betrag" readMode />
        <TextInput value={getPrice(invoice[CURRENCY], invoice[AMOUNT_DUE])} label="Total" readMode />
      </Accordion>
      <Accordion label="Patient">
        <TextInput value={patient[PUBLIC_ID]} label="ID" readMode />
        <TextInput value={patient[LAST_NAME] || '–'} label="Name" readMode />
        <TextInput value={patient[FIRST_NAME] || '–'} label="Vorname" readMode />
        <TextInput value={parseBirthday(patient[BIRTH])} label="Geburtsdatum" readMode />
        <TextInput value={pathOr('–', [INSURANCE, SSN], patient)} label="AHV-Nr." readMode />
      </Accordion>
      <Accordion label="Pflegezentrum">
        <TextInput value={nursingHome[NAME] || '–'} label="Name" readMode />
        <TextInput value={nursingHome[ADDRESS] || '–'} label="Adresse" readMode />
        <TextInput value={nursingHome[ZIP_CODE] || '–'} label="PLZ" readMode />
        <TextInput value={nursingHome[CITY] || '–'} label="Ort" readMode />
        <TextInput value={nursingHome[CANTON] || '–'} label="Kanton" readMode />
      </Accordion>
      <Accordion label="Krankenkasse">
        <TextInput value={insurance.name || '–'} label="Name" readMode />
        <TextInput value={insurance.street || '–'} label="Adresse" readMode />
        <TextInput value={insurance.zipCode || '–'} label="PLZ" readMode />
        <TextInput value={insurance.town || '–'} label="Ort" readMode />
      </Accordion>
      <Accordion label="Tarmed">
        <Table>
          <TR>
            {tableTemplate.map(({ name, width }) => (
              <TH key={name} w={width}>
                {name}
              </TH>
            ))}
          </TR>
          {tarmeds.map((item) => (
            <TR key={item.renderId}>
              {tableTemplate.map(({ getData, name, width }, idx) => (
                <TD key={name} w={width} first={idx === 0}>
                  {getData(item)}
                </TD>
              ))}
            </TR>
          ))}
        </Table>
      </Accordion>
      {canResend && (
        <Button type="button" color="success" mt={50} disabled={loading} onClick={handleResend}>
          Rechnung neu senden
        </Button>
      )}
    </Container>
  );
};

Invoice.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Invoice;
