import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 26px;
  border-top: 1px solid var(--color-grey97);
  padding: 0 6px;
  background-color: ${({ done }) => (done ? 'rgba(101, 216, 162, 0.75)' : 'none')};
`;

export const Time = styled.p`
  font-size: var(--font-size12);
  font-weight: var(--font-weight500);
  line-height: 2.18;
  letter-spacing: 0.47px;
  color: ${({ done }) => (done ? 'var(--color-white)' : 'var(--color-grey6A)')};
`;

export const Text = styled(Time)`
  flex: 1 0 auto;
`;
