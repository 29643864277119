import React, { useState, useCallback, useEffect } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import { RIGHT, LEFT, TAKE_LAST_DIAGNOSIS } from 'store/diagnoses';
import checkboxTemplates from '../checkboxTemplates.json';

import MultiButton from './MultiButton';
import EyeIcon from './EyeIcon';
import { useInitActiveSides } from './hooks';
import { CoupleWrap, EyeIconContainer, EyeIconLabel, Content } from './styles';

const DiseasesList = ({ readMode }) => {
  const initActiveSides = useInitActiveSides();
  const [activeSides, setActiveSides] = useState(initActiveSides);
  const [{ value }] = useField(TAKE_LAST_DIAGNOSIS);

  const clickRight = useCallback(() => setActiveSides((s) => [s[0] ? '' : RIGHT, s[1]]), []);
  const clickLeft = useCallback(() => setActiveSides((s) => [s[0], s[1] ? '' : LEFT]), []);

  useEffect(() => {
    if (value) setActiveSides(initActiveSides);
  }, [setActiveSides, initActiveSides, value]);

  return (
    <>
      <CoupleWrap>
        <EyeIconContainer selected={activeSides[0]} onClick={clickRight}>
          <EyeIconLabel selected={activeSides[0]}>Rechtes Auge</EyeIconLabel>
          <EyeIcon selected={activeSides[0]} />
        </EyeIconContainer>
        <EyeIconContainer selected={activeSides[1]} onClick={clickLeft}>
          <EyeIconLabel selected={activeSides[1]}>Linkes Auge</EyeIconLabel>
          <EyeIcon selected={activeSides[1]} />
        </EyeIconContainer>
      </CoupleWrap>
      <Content isOpen={Boolean(activeSides[0] || activeSides[1])}>
        {checkboxTemplates.map(({ id: checkBoxId, checkboxes = [], label, type, include, includedIn, exclude }) => (
          <MultiButton
            key={checkBoxId}
            id={checkBoxId}
            activeSides={activeSides}
            items={checkboxes}
            label={label}
            readMode={readMode}
            type={type}
            include={include}
            includedIn={includedIn}
            exclude={exclude}
          />
        ))}
      </Content>
    </>
  );
};

DiseasesList.defaultProps = {
  readMode: false,
};

DiseasesList.propTypes = {
  readMode: PropTypes.bool,
};

export default DiseasesList;
