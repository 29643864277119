/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { pipe, filter, complement, propEq } from 'ramda';

import api from 'api';
import { useAsyncState } from 'utils/useAsyncState';
import { usePlanningActions, ID } from 'store/planning';
import ImagesReview from 'components/ImagesReview';
import Spinner from 'components/FullScreenSpinner';

import Item from './Item';
import { Container, ReadModeWrapper, NoDocuments, WaitItem, SpinnerWrap, Label } from './styles';

const MultiImage = ({ values, waiting, updateValues }) => {
  const { removeScheduleFormMedia } = usePlanningActions();
  const [imagePath, setImagePath] = useAsyncState();
  const openFile = useCallback(
    async (mimetype, url) => {
      if (mimetype === 'application/pdf') {
        const blobUrl = await api.loadFile({ url, mimetype });

        window.open(blobUrl, '_blank');
      } else {
        setImagePath(url);
      }
    },
    [setImagePath]
  );
  const closeFile = useCallback(() => setImagePath(), [setImagePath]);

  const deleteFile = useCallback(
    (id, path) => {
      pipe(filter(complement(propEq(path, 'path'))), updateValues)(values);

      if (id) removeScheduleFormMedia(id);
    },
    [removeScheduleFormMedia, updateValues, values]
  );

  if (values.length === 0 && waiting.length === 0) {
    return (
      <ReadModeWrapper>
        <NoDocuments>Noch keine Dokumente vorhanden</NoDocuments>
      </ReadModeWrapper>
    );
  }

  return (
    <Container>
      {values.map((data) => (
        <Item key={data.path} {...data} openFile={openFile} deleteFile={deleteFile} />
      ))}
      {waiting.map((name, i) => (
        <WaitItem key={name + i}>
          <SpinnerWrap>
            <Spinner height="100%" />
          </SpinnerWrap>
          <Label>{name}</Label>
        </WaitItem>
      ))}
      {imagePath && <ImagesReview currentImage={imagePath} images={values} onClose={closeFile} />}
    </Container>
  );
};

MultiImage.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      [ID]: PropTypes.string,
      destination: PropTypes.string,
      path: PropTypes.string,
      previewname: PropTypes.string,
      originalname: PropTypes.string,
      mimetype: PropTypes.string,
    })
  ).isRequired,
  waiting: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  updateValues: PropTypes.func.isRequired,
};

export default MultiImage;
