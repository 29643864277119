import React from 'react';
import PropTypes from 'prop-types';

const HistoryIcon = ({ isActive }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="69" height="68" viewBox="0 0 69 68">
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <rect
        width="67"
        height="66"
        fill={isActive ? 'var(--color-greenF6)' : 'transparent'}
        stroke={isActive ? 'var(--color-blue50)' : '#979797'}
        rx="8"
      />
      <text
        fill={isActive ? 'var(--color-blue50)' : '#6a6a6a'}
        fontFamily="IBMPlexSans-Medium, IBM Plex Sans"
        fontSize="13"
        fontWeight="400"
        letterSpacing=".5"
        transform="translate(7 3)"
      >
        <tspan x="1.492" y="10.284">
          Historie
        </tspan>
      </text>
      <g stroke={isActive ? 'var(--color-blue50)' : '#979797'}>
        <path
          fill={isActive ? 'var(--color-greenF6)' : 'transparent'}
          d="M40 21.62h0L32.82 0H7.567L0 21.702v12.982C0 35.41.588 36 1.312 36h37.376c.724 0 1.312-.59 1.312-1.316V21.62z"
          transform="translate(13 21)"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M40 22.369H29.663c-.725 0-1.313.676-1.313 1.51v2.98c0 .834-.587 1.51-1.312 1.51h-13.75c-.725 0-1.312-.676-1.312-1.51v-2.886c0-.834-.588-1.51-1.313-1.51H0"
          transform="translate(13 21)"
        />
      </g>
    </g>
  </svg>
);

HistoryIcon.defaultProps = {
  isActive: false,
};

HistoryIcon.propTypes = {
  isActive: PropTypes.bool,
};

export default HistoryIcon;
