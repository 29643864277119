import { useMemo, useCallback } from 'react';
import { pipe } from 'ramda';

import { useSessionActions, useAlerts } from 'store/session';
import { generateWith2FA, generateWithout2FA } from './utils';

export const useContent = ({ has2FA, next, prev }) => {
  const { fetch2FA, reset2FA, validate2FA } = useSessionActions();
  const { extractId, loading, error } = useAlerts();

  const apply = useCallback((val) => pipe(fetch2FA, extractId)(val), [fetch2FA, extractId]);
  const reset = useCallback(() => extractId(reset2FA()), [extractId, reset2FA]);

  const with2FA = useMemo(() => generateWith2FA(reset, loading, error), [error, loading, reset]);
  const without2FA = useMemo(
    () => generateWithout2FA(next, prev, apply, validate2FA, loading, error),
    [apply, validate2FA, error, loading, next, prev]
  );

  return has2FA ? with2FA : without2FA;
};
