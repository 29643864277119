import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { find, propSatisfies, includes } from 'ramda';
import PropTypes from 'prop-types';

import ExaminationPanel from 'components/ExaminationPanel';
import PersonalInformationForm from 'containers/PersonalInformationForm';
import MedicalHistoryForm from 'containers/MedicalHistoryForm';
import ExaminationForm from 'containers/ExaminationForm';
import { PERSONAL, MEDICAL_HISTORY, EXAMINATION, REPORT, usePermissions } from 'permissions';
import { useInfoBoxPath, usePatientReportsPath } from '../hooks';
import Navigation from '../Navigation';

function NursePatient({ readMode }) {
  const can = usePermissions();
  const { id, view = PERSONAL } = useParams();
  const infoBoxPath = useInfoBoxPath();
  const patientReportsPath = usePatientReportsPath();

  const tabs = useMemo(
    () => [
      {
        id: 'personalInfoTab',
        path: `/patients/${id}/${PERSONAL}`,
        label: 'Persönliche Angaben',
        content: PersonalInformationForm,
      },
      {
        id: 'medicalHistoryTab',
        path: `/patients/${id}/${MEDICAL_HISTORY}`,
        label: 'Anamnese',
        content: MedicalHistoryForm,
      },
      {
        id: 'examinationTab',
        path: `/patients/${id}/${EXAMINATION}`,
        label: 'Untersuchung',
        content: ExaminationForm,
      },
      ...(can.read(REPORT)
        ? [
            {
              id: 'patientReportsTab',
              path: patientReportsPath,
              label: 'Berichte',
            },
          ]
        : []),
      {
        id: 'infoBoxTab',
        path: infoBoxPath,
        label: 'Infobox',
      },
    ],
    [id, infoBoxPath, patientReportsPath, can]
  );

  const activeTab = useMemo(() => find(propSatisfies(includes(view), 'path'), tabs) || tabs[0], [view, tabs]);

  const Component = activeTab.content;

  return (
    <>
      <Navigation tabs={tabs} active={activeTab.id} />
      {activeTab.id === 'examinationTab' && <ExaminationPanel />}
      {Component && <Component readMode={readMode} />}
    </>
  );
}

NursePatient.propTypes = {
  readMode: PropTypes.bool.isRequired,
};

export default NursePatient;
