import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 8px 0 10px;
  background-color: rgba(80, 227, 194, 0.34);
  border: none;
  border-top: 1px solid var(--color-blue50);
  border-radius: none;
  outline: none;
  cursor: pointer;
  @media (hover) {
    &:hover {
      background-color: #83dfb4;
    }
  }
  &:active {
    background-color: var(--color-green50);
    border-top: 1px solid var(--color-green50);
  }
  &:disabled {
    background-color: #c2efda;
    cursor: not-allowed;
  }
`;

export const Text = styled.p`
  font-size: var(--font-size18);
  color: #018869;
`;
