import styled from 'styled-components';

export const Label = styled.p`
  color: var(--color-grey6A);
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  line-height: 1.15;
  letter-spacing: 0.5px;
  min-height: 28px;
`;

export const Wrapper = styled.div`
  display: flex;
  column-gap: 7px;
`;
