/* eslint-disable no-console */
import Bugsnag from '@bugsnag/js';
import { keys } from 'ramda';

import { getCache, BUGSNAG_CACHE } from './cache';

const deleteCache = () => {
  window?.caches?.delete(BUGSNAG_CACHE);
};

export const notify = async (url) => {
  try {
    const cache = await getCache(BUGSNAG_CACHE);

    try {
      const request = new Request('');
      const date = new Date().toISOString();
      const response = await cache.match(request);

      if (response) {
        const data = await response.json();

        data[url] = data[url] ? [...data[url], date] : [date];
        cache.put(request, new Response(JSON.stringify(data)));
      } else {
        cache.put(request, new Response(JSON.stringify({ [url]: [date] })));
      }
    } catch (err) {
      deleteCache();
      console.error(err);
    }
  } catch (e) {
    console.error(e);
  }
};

export const extractBugsnagReport = async () => {
  try {
    const cache = await getCache(BUGSNAG_CACHE);

    try {
      const responses = await cache.matchAll();

      if (!responses?.length) return;

      const items = await Promise.all(responses.map((response) => response.json()));
      const message = {
        type: 'Internet connection is unavailable',
        requests: items.reduce((acc, obj) => {
          keys(obj).forEach((url) => acc.push({ url, attempts: obj[url] }));

          return acc;
        }, []),
      };

      if (process.env.NODE_ENV === 'production') {
        Bugsnag.notify(new Error(message.type, { cause: new Error(JSON.stringify(message.requests)) }), null, (err) => {
          if (!err) deleteCache();
        });
      } else {
        console.log(message);
        deleteCache();
      }
    } catch (err) {
      deleteCache();
      console.error(err);
    }
  } catch (e) {
    console.error(e);
  }
};
