import { call, select, takeLeading } from 'redux-saga/effects';
import { pipe, pathOr, map, last, applySpec, prop, propOr, pick, uniq, keys, sort } from 'ramda';

import { withAlert } from 'store/alerts';
import api from 'api';

import { getAnalyticsParams } from './selectors';
import { GET_ANALYTICS, ASK_QUESTION } from './types';
import { handleAnalytics } from './utils';
import { PROMPT, RESPONSE, ID, NAME, GROUP, CANTONS } from '.';

function* getAnalytics() {
  const params = yield select(getAnalyticsParams);
  const data = yield call(api.getAnalytics, params);
  const organizations = pipe(
    pathOr([], [0, 'data']),
    map(
      pipe(
        last,
        applySpec({
          id: prop(ID),
          name: propOr('', NAME),
          group: propOr('', GROUP),
          color: ($) => pathOr('', [$[GROUP], 'color'], CANTONS),
        })
      )
    )
  )(data);

  return {
    success: {
      list: handleAnalytics(params, data),
      organizations,
      cantons: pipe(
        pick(
          pipe(
            map(prop('group')),
            uniq,
            sort((a, b) => a.localeCompare(b))
          )(organizations)
        ),
        keys,
        map((id) => ({ id, ...CANTONS[id] }))
      )(CANTONS),
    },
  };
}

function* askQuestion({ payload }) {
  const { [RESPONSE]: response } = yield call(api.askQuestion, { [PROMPT]: payload });

  return { success: response[RESPONSE] };
}

export default function* watchAnalytics() {
  yield takeLeading(GET_ANALYTICS, withAlert(getAnalytics));
  yield takeLeading(ASK_QUESTION, withAlert(askQuestion));
}
