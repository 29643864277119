import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Icon } from 'assets/svg-icons/reverse.svg';

import { Container, Button } from './styles';

const ReverseButton = ({ onClick }) => (
  <Container>
    <Button type="button" onClick={onClick}>
      <Icon />
    </Button>
  </Container>
);

ReverseButton.propTypes = { onClick: PropTypes.func.isRequired };

export default ReverseButton;
