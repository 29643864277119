import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Table from 'components/Table';
import PaginationDropDown from 'components/PaginationDropDown';
import { INVOICE, CANTON_DASHBOARD, usePermissions } from 'permissions';
import { INVOICES_FILTERS, useInvoicesActions, useInitInvoices } from 'store/invoices';
import { ID, NAME, CURSOR, COUNT, LIMIT, HAS_MORE, ORGANIZATION_KINDS, useCollectOrganizationsAction } from 'store/organizations';

import { statusList } from './consts';
import { prepareInvoices, tableHead } from './utils';
import DashBoard from './DashBoard';
import Row from './Row';
import NurseHomeKPI from './NurseHomeKPI';
import CantonDashboard from './CantonDashboard';
import { Container, Wrapper, Label, SearchPanel } from './styles';

const Invoices = () => {
  const [nurseHome, setNurseHome] = useState();
  const can = usePermissions();

  const { list, status, search, sort, pagination, loading } = useInitInvoices(can.read(INVOICE));
  const invoices = useMemo(() => prepareInvoices(list), [list]);
  const { updateStatus, updateSearch, updateSort, updatePagination } = useInvoicesActions();

  const handleOnSelect = useCallback((id) => updateStatus({ kind: INVOICES_FILTERS, value: id }), [updateStatus]);
  const handleSearch = useCallback((text) => updateSearch({ kind: INVOICES_FILTERS, value: text }), [updateSearch]);
  const updateSortList = useCallback((value) => updateSort({ kind: INVOICES_FILTERS, value }), [updateSort]);
  const updatePaginationList = useCallback((value) => updatePagination({ kind: INVOICES_FILTERS, value }), [updatePagination]);

  const getOrganizations = useCollectOrganizationsAction(ORGANIZATION_KINDS.NURSING_HOME);

  const renderRow = useCallback((data) => <Row {...data} />, []);

  return (
    <Container>
      <DashBoard />
      {can.read(CANTON_DASHBOARD) && <CantonDashboard />}
      {can.read(INVOICE) && (
        <>
          <Wrapper>
            <Label>Jahreszahlen Pflegezentrum</Label>
            <PaginationDropDown
              idKey={ID}
              nameKey={NAME}
              action={getOrganizations}
              placeholder="Pflegezentrum suchen"
              value={nurseHome}
              onChange={setNurseHome}
              onlyOne
            />
            <NurseHomeKPI id={nurseHome && nurseHome[ID]} />
          </Wrapper>
          <Wrapper>
            <Label>Rechnungen</Label>
            <SearchPanel
              selectValue={status || ''}
              options={statusList}
              onSelect={handleOnSelect}
              value={search}
              onChange={handleSearch}
              placeholder="Suchen"
            />
            <Table
              head={tableHead}
              list={invoices}
              renderRow={renderRow}
              sort={sort}
              cursor={pagination[CURSOR]}
              count={pagination[COUNT]}
              limit={pagination[LIMIT]}
              hasMore={pagination[HAS_MORE]}
              updateSort={updateSortList}
              updatePagination={updatePaginationList}
              loading={loading}
            />
          </Wrapper>
        </>
      )}
    </Container>
  );
};

Invoices.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default Invoices;
