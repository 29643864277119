import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ArrowIconDate from 'assets/svg-icons/ArrowDate';

import { DateText, Arrow } from './styles';

const DatePeriod = ({ start, end, prev }) => (
  <DateText>
    <span>{moment(start).format('D MMM. YYYY')}</span>
    <Arrow>
      <ArrowIconDate color={prev ? 'var(--color-grey97)' : 'var(--color-grey6A)'} />
    </Arrow>
    <span>{moment(end).format('D MMM. YYYY')}</span>
  </DateText>
);

DatePeriod.defaultProps = {
  prev: false,
};

DatePeriod.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  prev: PropTypes.bool,
};

export default DatePeriod;
