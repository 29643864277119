import { map, applySpec, pipe, path, ifElse, prop, propOr, isEmpty, join, either, filter } from 'ramda';

import { ID, PRESSURE, DATE } from 'store/examinations';
import { parseDate } from 'utils';

export const getPressure = pipe(
  propOr([], PRESSURE),
  filter((val) => val !== null),
  ifElse(isEmpty, () => '–', join(', '))
);

const getDate = either(prop(DATE), path(['meta', 'modified']));

export const handleItems = (list, side) =>
  map(
    applySpec({
      id: prop(ID),
      value: pipe(propOr({}, side), getPressure),
      date: pipe(getDate, parseDate),
    })
  )(list || []);
