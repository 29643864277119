import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import SortTable from 'components/Table';
import Plus from 'assets/svg-icons/Plus';
import Close from 'assets/svg-icons/Close';
import { useInvoicesActions, useFilters, TARMEDS_FILTERS, SORT } from 'store/invoices';
import { usePatient, INSURANCE, LAW_TYPE } from 'store/patients';

import Row from './Row';
import SelectedRow from './SelectedRow';
import { headerFields, headerSelectedFields, prepareServices, prepareSelectedServices } from './utils';

const Table = ({
  title,
  list,
  isSelected,
  action,
  onChangeQuantity,
  onChangeDate,
  onChangeSession,
  onChangeDuration,
  setDataError,
  validationErrors,
  readMode,
  loading,
}) => {
  const { id: patientId } = useParams();
  const { updateSort } = useInvoicesActions();
  const filters = useFilters(TARMEDS_FILTERS);
  const insurance = usePatient(patientId, INSURANCE);
  const lawType = (insurance || {})[LAW_TYPE];
  const services = useMemo(
    () => (isSelected ? prepareSelectedServices(list || [], validationErrors, lawType) : prepareServices(list || [], lawType)),
    [validationErrors, isSelected, list, lawType]
  );
  const updateSortList = useCallback((value) => updateSort({ kind: TARMEDS_FILTERS, value }), [updateSort]);
  const renderRow = useCallback(
    (data) =>
      isSelected ? (
        <SelectedRow
          icon={<Close />}
          action={action}
          onChangeQuantity={onChangeQuantity}
          onChangeDate={onChangeDate}
          onChangeSession={onChangeSession}
          onChangeDuration={onChangeDuration}
          readMode={readMode}
          setDataError={setDataError}
          {...data}
        />
      ) : (
        <Row icon={!readMode && <Plus />} action={action} {...data} />
      ),
    [isSelected, action, onChangeQuantity, onChangeDate, onChangeSession, onChangeDuration, readMode, setDataError]
  );

  return (
    <SortTable
      title={title}
      head={isSelected ? headerSelectedFields : headerFields}
      list={services}
      renderRow={renderRow}
      sort={filters[SORT]}
      updateSort={updateSortList}
      loading={loading}
    />
  );
};

Table.defaultProps = {
  title: null,
  isSelected: false,
  action: () => null,
  readMode: false,
  onChangeQuantity: () => null,
  onChangeDate: () => null,
  onChangeSession: () => null,
  onChangeDuration: () => null,
  setDataError: () => null,
  validationErrors: {},
  loading: false,
};

Table.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSelected: PropTypes.bool,
  action: PropTypes.func,
  readMode: PropTypes.bool,
  onChangeQuantity: PropTypes.func,
  onChangeDate: PropTypes.func,
  onChangeSession: PropTypes.func,
  onChangeDuration: PropTypes.func,
  setDataError: PropTypes.func,
  validationErrors: PropTypes.objectOf(PropTypes.bool.isRequired),
  loading: PropTypes.bool,
};

export default Table;
