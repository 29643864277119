import { ALL, READ, UNREAD, EMPTY } from 'store/reports';

export const dropDownItems = [
  {
    id: UNREAD,
    name: 'Neue Berichte',
  },
  {
    id: ALL,
    name: 'Alle Berichte',
  },
  {
    id: READ,
    name: 'Alte Berichte',
  },
  {
    id: EMPTY,
    name: 'Keine',
  },
];
