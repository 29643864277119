import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_BUG_REPORTS,
  UPDATE_BUG_REPORT,
  DELETE_BUG_REPORT,
  RESET_BUG_REPORTS,
  FETCH_BUG_REPORTS,
  SEND_BUG_REPORT,
  CHANGE_BUG_REPORT_STATUS,
  UPDATE_SORT,
  UPDATE_PAGINATION,
  RESET_FILTERS,
  FETCH_MEDI_DATA,
  SAVE_MEDI_DATA,
  FETCH_USERS,
  FETCH_USER,
  CREATE_USER,
  SAVE_USER,
} from './types';

export const updateBugReports = createAction(UPDATE_BUG_REPORTS);
export const updateBugReport = createAction(UPDATE_BUG_REPORT);
export const deleteBugReport = createAction(DELETE_BUG_REPORT);
export const resetBugReports = createAction(RESET_BUG_REPORTS);

export const updateSort = createAction(UPDATE_SORT);
export const updatePagination = createAction(UPDATE_PAGINATION);
export const resetFilters = createAction(RESET_FILTERS);

export const fetchBugReports = createAlertAction(FETCH_BUG_REPORTS);
export const sendBugReport = createAlertAction(SEND_BUG_REPORT);
export const changeBugReportStatus = createAlertAction(CHANGE_BUG_REPORT_STATUS);
export const fetchMediData = createAlertAction(FETCH_MEDI_DATA);
export const saveMediData = createAlertAction(SAVE_MEDI_DATA);
export const fetchUsers = createAlertAction(FETCH_USERS);
export const fetchUser = createAlertAction(FETCH_USER);
export const createUser = createAlertAction(CREATE_USER);
export const saveUser = createAlertAction(SAVE_USER);
