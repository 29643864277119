import styled from 'styled-components';

import XlsErrors from '../../XlsErrors';

export const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: var(--border-radius8);
  border: ${({ $isActive, $isWarn }) => {
    if (!$isActive && !$isWarn) return 'solid 1px var(--color-blue4A)';
    if ($isWarn) return 'solid 3px var(--color-orangeF5)';

    return 'solid 3px var(--color-green65)';
  }};
  background-color: var(--color-blue4A);
  opacity: ${({ disabled }) => (disabled ? 0.75 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin-top: 20px;
  &:focus {
    outline: none;
    box-shadow: var(--shadow-focus);
  }
`;

export const Text = styled.p`
  width: 560px;
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  letter-spacing: 0.92px;
  color: var(--color-white);
  text-align: center;
  text-transform: uppercase;
`;

export const Warn = styled(Text)`
  font-weight: var(--font-weight700);
  color: var(--color-orangeF5);
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
`;

export const Button = styled.button`
  border: none;
  background: none;
  font-size: var(--font-size15);
  font-weight: var(--font-weight500);
  letter-spacing: 0.33px;
  color: var(--color-blue5D);
  text-transform: uppercase;
  text-decoration: underline;
  outline: none;
  cursor: pointer;
  &:active:enabled {
    color: var(--color-blue8E);
  }
  @media (hover) {
    &:hover:enabled {
      color: var(--color-blue2E);
    }
  }
  &:disabled {
    visibility: hidden;
  }
`;

export const Success = styled.p`
  font-size: var(--font-size10);
  font-weight: var(--font-weight600);
  line-height: 1.3;
  letter-spacing: 1.01px;
  color: #009838;
  text-transform: uppercase;
`;

export const Errors = styled(XlsErrors)`
  align-self: flex-start;
  align-items: flex-start;
`;
