import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  margin-top: 20px;
`;

export const SelectBtn = styled.button`
  width: 100%;
  height: 100px;
  border: 1px solid;
  border-color: var(--color-greyEC);
  border-radius: var(--border-radius8);
  background-color: var(--color-greyEC);
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  color: var(--color-grey6A);
  letter-spacing: 0.9px;
  line-height: 1.15;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  transition: all 0.5s;
  @media (hover) {
    &:hover:enabled {
      border-color: var(--color-grey97);
      background-color: var(--color-white);
    }
  }
  &:disabled {
    border-color: var(--color-grey97);
    background-color: var(--color-white);
    cursor: not-allowed;
  }
  &:focus {
    box-shadow: var(--shadow-focus);
  }
`;

export const Link = styled.a`
  align-self: flex-end;
  font-size: var(--font-size15);
  font-weight: var(--font-weight500);
  letter-spacing: 0.33px;
  color: var(--color-blue5D);
  text-transform: uppercase;
  margin-top: 10px;
  &:active {
    color: var(--color-blue8E);
  }
  @media (hover) {
    &:hover {
      color: var(--color-blue2E);
    }
  }
`;
