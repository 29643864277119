import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { values, pathOr } from 'ramda';

import { routes } from './utils';
import { Container, Wrapper, Sidebar, Link, ContentContainer } from './styles';

const Profile = () => {
  const { path } = useRouteMatch();
  const items = useMemo(() => values(routes), []);
  const Content = useMemo(() => pathOr(() => null, [path, 'content'], routes), [path]);

  return (
    <Container>
      <Wrapper>
        <Sidebar>
          {items.map(({ url, label }) => (
            <Link key={url} to={url} disabled={path === url}>
              {label}
            </Link>
          ))}
        </Sidebar>
        <ContentContainer>
          <Content />
        </ContentContainer>
      </Wrapper>
    </Container>
  );
};

export default Profile;
