import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useInvoicesActions, useAlerts } from 'store/invoices';
import { ReactComponent as Icon } from 'assets/svg-icons/downloadPdf.svg';

import { Container, Button, Message } from './styles';

const PdfSection = ({ id }) => {
  const { downloadPdf } = useInvoicesActions();
  const { action, loading, success, error, resetAlerts } = useAlerts(downloadPdf);
  const handleClick = useCallback(() => action(id), [action, id]);

  useEffect(() => {
    if (success?.url) {
      window.open(success.url, '_blank');
      resetAlerts();
    }
  }, [success, resetAlerts]);

  return (
    <Container>
      <Button type="button" onClick={handleClick} disabled={loading}>
        <Icon />
      </Button>
      {error && <Message type="error">{error}</Message>}
    </Container>
  );
};

PdfSection.propTypes = { id: PropTypes.string.isRequired };

export default PdfSection;
