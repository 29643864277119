import { YES, NO } from '../consts';

export const getReadModeValue = (data) => {
  if (typeof data === 'object' && Array.isArray(data)) {
    return data.length ? data.join(', ') : '';
  }

  if (data && typeof data === 'object') {
    const { value, list = [], description, date } = data;

    if (value === false) return NO;
    if (!value) return '–';
    let result = `${value === true ? YES : ''}. `;
    if (date) result += `${date}${description ? ': ' : ''}`;
    if (description) result += `${description}`;
    if (list.length > 0 && value) result += list.join(', ');
    return result;
  }

  if (typeof data === 'boolean') {
    if (data === true) return YES;
    if (data === false) return NO;
  }

  return '–';
};

export default {};
