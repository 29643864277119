import React, { useCallback, memo } from 'react';
import { useField } from 'formik';
import { eqProps } from 'ramda';
import PropTypes from 'prop-types';

import TextArea from 'components/TextAreaNew';
import QuestionContainer from 'components/QuestionContainer';
import { COMMENT } from 'store/medicalHistory';

const CommentForm = ({ readMode }) => {
  const [{ value: comment }, , { setValue: setComment }] = useField(COMMENT);

  const handleChangeComment = useCallback(
    (text) => {
      if (readMode) return;

      setComment(text);
    },
    [readMode, setComment]
  );

  return (
    <QuestionContainer label="Wichtiger Hinweis für Untersuche">
      <TextArea placeholder="Kommentar" value={comment} onChangeText={handleChangeComment} rows={8} readMode={readMode} />
    </QuestionContainer>
  );
};

CommentForm.propTypes = {
  readMode: PropTypes.bool.isRequired,
};

export default memo(CommentForm, eqProps('readMode'));
