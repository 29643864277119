import React, { useCallback, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { usePermissions } from 'permissions';
import { useSessionActions } from 'store/session';

const PrivatableRoute = ({ component, resource, ...rest }) => {
  const { logout } = useSessionActions();

  const can = usePermissions();
  const hasAccess = can.read(resource);

  const getComponent = useCallback(
    (props) => {
      const Component = component;
      if (hasAccess) return <Component {...props} />;

      return <Redirect to="/" />;
    },
    [component, hasAccess]
  );

  useEffect(
    () => () => {
      if (!hasAccess) logout();
    },
    [hasAccess, logout]
  );

  return <Route render={getComponent} {...rest} />;
};

PrivatableRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  resource: PropTypes.string.isRequired,
};

export default PrivatableRoute;
