import { Children } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid var(--color-grey97);
  margin-bottom: 80px;
`;

export const Label = styled.p`
  color: var(--color-grey6A);
  font-size: var(--font-size15);
  font-weight: var(--font-weight600);
`;

export const SelectWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
`;

export const Content = styled.div.attrs(({ children }) => ({
  $count: Children.count(children),
}))`
  display: flex;
  border-radius: var(--border-radius8);
  background-color: #f5f5f5;
  & > div {
    max-width: ${({ $count }) => 100 / Math.max(1, $count)}%;
  }
`;
