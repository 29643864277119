import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import { UPDATE_PARAMS, GET_ANALYTICS, ASK_QUESTION } from './types';

export const updateParams = createAction(UPDATE_PARAMS);

export const getAnalytics = createAlertAction(GET_ANALYTICS);
export const askQuestion = createAlertAction(ASK_QUESTION);
