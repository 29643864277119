import { wrapperRequest } from './utils';
import { API_URL, POST, OWN_ERR_HANDLER } from './consts';

export const getAnalytics = (header) =>
  wrapperRequest({
    url: `${API_URL}/gapminders`,
    options: { method: POST, body: { header } },
  });

export const askQuestion = (body) =>
  wrapperRequest({
    url: `${API_URL}/medignition-index`,
    options: { method: POST, body, [OWN_ERR_HANDLER]: true },
  });
