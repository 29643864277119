import React, { useCallback } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import { CARE_LEVEL, CARE_LEVELS } from 'store/medicalHistory';
import FormContainer from 'components/FormContainer';
import QuestionContainer from 'components/QuestionContainer';
import Select from 'components/SelectNew';

import YesNoForm from './YesNoForm';
import DropzoneForm from './DropzoneForm';
import CommentForm from './CommentForm';

const MedicalHistoryForm = ({ values, readMode }) => {
  const [{ value: careLevel }, , { setValue: setCareLevel }] = useField(CARE_LEVEL);
  const handleSelect = useCallback((val) => setCareLevel(Number(val)), [setCareLevel]);

  return (
    <FormContainer>
      <QuestionContainer label="Pflegestufe">
        <Select
          value={typeof careLevel === 'number' ? String(careLevel) : ''}
          options={CARE_LEVELS}
          onSelect={handleSelect}
          readMode={readMode}
        />
      </QuestionContainer>
      <YesNoForm values={values} readMode={readMode} />
      <DropzoneForm readMode={readMode} />
      <CommentForm readMode={readMode} />
    </FormContainer>
  );
};

MedicalHistoryForm.propTypes = {
  readMode: PropTypes.bool.isRequired,
  values: PropTypes.shape({}).isRequired,
};

export default MedicalHistoryForm;
