import { map, join, filter, pipe, props } from 'ramda';

import { NAME, SPECIALTY, CONTACTS, ZIP_CODE, CITY, CANTON, ADDRESS } from 'store/organizations';
import { FIELDS } from 'store/patients';

export const NAME_KEY = 'fullName';

export const PARAMS = {
  [FIELDS]: [NAME, SPECIALTY, CONTACTS, ZIP_CODE, CITY, CANTON, ADDRESS],
};

export const handleClinicName = (items = []) =>
  map((item = {}) => ({
    ...item,
    [NAME_KEY]: pipe(
      ($) => [$[NAME], pipe(props([ZIP_CODE, CITY, CANTON]), filter(Boolean), join(' '))($)],
      filter(Boolean),
      join(', ')
    )(item),
  }))(items);
