import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export const Container = styled(motion.div).attrs(() => ({
  animate: { opacity: 1 },
  transition: { duration: 0.5 },
  initial: { opacity: 0 },
}))`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 220px;
  position: absolute;
  inset: 0 0 auto auto;
  padding-left: 15px;
  transform: translateX(min(100%, calc((100vw - 950px) / 2)));
  z-index: 1;
  @media (max-width: 1101px) {
    transform: translateX(80px);
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-content: flex-start;
  gap: 0 10px;
  width: 100%;
  min-height: 55px;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyF3);
  padding: 5px 8px;
`;

export const CheckboxWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  min-height: 55px;
  & > div > label {
    font-weight: 600;
    letter-spacing: 1.01px;
    text-transform: uppercase;
  }
`;

export const Text = styled.p`
  font-size: var(--font-size10);
  font-weight: 600;
  letter-spacing: 1.01px;
  color: ${({ $isRed }) => ($isRed ? 'var(--color-redED)' : 'var(--color-grey6A)')};
  text-transform: uppercase;
  word-break: ${({ $isRed }) => ($isRed ? 'break-word' : 'normal')};
`;

export const NavLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 100%;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyEC);
  font-size: var(--font-size13);
  font-weight: 500;
  letter-spacing: 0.92px;
  color: var(--color-grey6A);
  text-transform: uppercase;
  text-decoration: none;
  @media (hover) {
    &:hover {
      background-color: var(--color-greyF8);
    }
  }
  &:active {
    background-color: #bdbcbc;
  }
`;
