import React, { useRef, useState, useMemo, useCallback, useLayoutEffect } from 'react';
import { omit, isEmpty } from 'ramda';
import PropTypes from 'prop-types';

import { useAnalyticsParams } from 'store/analytics';
import { useDebounceState } from 'utils/useDebounceState';
import Spinner from 'components/FullScreenSpinner';

import { getChartData } from './utils';
import { INIT_CONFIG } from './consts';
import Grid from './Grid';
import Dot from './Dot';
import List from './List';
import Playground from './Playground';
import { Container, MainWrap, Navbar, BackgroundLabel, SpinnerWrap, SelectWrap, SelectLabel, Range } from './styles';

const BubbleChart = ({
  list,
  organizations,
  cantons,
  selectX,
  selectY,
  selectZ,
  groupLabel,
  nameLabel,
  xAxisLabel,
  yAxisLabel,
  zAxisLabel,
  loading,
}) => {
  const params = useAnalyticsParams();
  const paramsRef = useRef(params);
  const [{ key, hoveredItem, selectedItems, hoveredGroup, selectedGroups }, setConfig] = useState(INIT_CONFIG);
  const [index, setIndex] = useState(0);
  const [zFactor, zFactorDebounced, setZFactor] = useDebounceState(0.25, 250);
  const { data, playgroundData, dataAxisX, dataAxisY } = useMemo(() => getChartData(list, paramsRef.current), [list]);
  const handleHoverItem = useCallback((id) => setConfig(($) => ({ ...$, hoveredItem: id || null })), []);
  const handleSelectItem = useCallback(
    (id) =>
      setConfig(($) => ({
        ...$,
        selectedItems: $.selectedItems[id] ? omit([id], $.selectedItems) : { ...$.selectedItems, [id]: true },
        ...(!isEmpty($.selectedGroups) && { selectedGroups: {} }),
      })),
    []
  );
  const handleHoverGroup = useCallback((id) => setConfig(($) => ({ ...$, hoveredGroup: id || null })), []);
  const handleSelectGroup = useCallback(
    (id) =>
      setConfig(($) => ({
        ...$,
        selectedGroups: $.selectedGroups[id] ? omit([id], $.selectedGroups) : { ...$.selectedGroups, [id]: true },
        ...(!isEmpty($.selectedItems) && { selectedItems: {} }),
      })),
    []
  );
  const updateZFactor = useCallback(({ currentTarget }) => setZFactor(Number(currentTarget.value)), [setZFactor]);

  useLayoutEffect(() => {
    setIndex(0);
    setConfig(($) => ({ ...INIT_CONFIG, key: $.key + 1 }));
  }, [list]);

  useLayoutEffect(() => {
    paramsRef.current = params;
  }, [params]);

  return (
    <Container key={key}>
      <MainWrap>
        <Grid dataAxisX={dataAxisX} dataAxisY={dataAxisY}>
          <BackgroundLabel>{playgroundData[index]}</BackgroundLabel>
          {selectX}
          {selectY}
          {Boolean(data[index]) &&
            data[index].map((item) => (
              <Dot
                key={item.id}
                data={item}
                groupLabel={groupLabel}
                nameLabel={nameLabel}
                xAxisLabel={xAxisLabel}
                yAxisLabel={yAxisLabel}
                zAxisLabel={zAxisLabel}
                zFactor={zFactorDebounced}
                hoveredItem={hoveredItem}
                hoveredGroup={hoveredGroup}
                onHover={handleHoverItem}
                selectedItems={selectedItems}
                selectedGroups={selectedGroups}
                onSelect={handleSelectItem}
              />
            ))}
        </Grid>
        <Playground index={index} setIndex={setIndex} items={playgroundData} />
      </MainWrap>
      <Navbar>
        <List
          title={groupLabel}
          data={cantons}
          hovered={hoveredGroup}
          selected={selectedGroups}
          onHover={handleHoverGroup}
          onSelect={handleSelectGroup}
          isFullHeight={!organizations.length}
        />
        {Boolean(organizations.length) && (
          <List
            title={nameLabel}
            data={organizations}
            hovered={hoveredItem}
            selected={selectedItems}
            onHover={handleHoverItem}
            onSelect={handleSelectItem}
            withFilter
          />
        )}
        <SelectWrap>
          <SelectLabel>Size</SelectLabel>
          {selectZ}
          <Range type="range" min={0.1} max={0.5} step={0.01} value={zFactor} onChange={updateZFactor} />
        </SelectWrap>
      </Navbar>
      {loading && (
        <SpinnerWrap>
          <Spinner height="100%" />
        </SpinnerWrap>
      )}
    </Container>
  );
};

BubbleChart.defaultProps = {
  selectX: null,
  selectY: null,
  selectZ: null,
  xAxisLabel: null,
  yAxisLabel: null,
  zAxisLabel: null,
  loading: false,
};
BubbleChart.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          group: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
          x: PropTypes.number.isRequired,
          y: PropTypes.number.isRequired,
          z: PropTypes.number.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired
  ).isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  cantons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  selectX: PropTypes.node,
  selectY: PropTypes.node,
  selectZ: PropTypes.node,
  groupLabel: PropTypes.string.isRequired,
  nameLabel: PropTypes.string.isRequired,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
  zAxisLabel: PropTypes.string,
  loading: PropTypes.bool,
};

export default BubbleChart;
