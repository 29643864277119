import { throttle, takeEvery, takeLatest, debounce, call, put } from 'redux-saga/effects';
import { prop, omit, keys, pathOr, test, any } from 'ramda';
import { string, number } from 'yup';
import { isEmail } from 'validator';

import api from 'api';
import { withAlert } from 'store/alerts';

import { ID, URL, EMAIL, INSTITUTION, INSURANCE, SSN, ZIP_CODE, AGENT, OTHER_CONTACTS } from '.';
import { updatePatients, updatePatient, deletePatient, updatePagination, updatePatientReports } from './actions';
import {
  FETCH_PATIENTS,
  FETCH_PATIENT,
  CREATE_PATIENT,
  SAVE_PATIENT,
  REMOVE_PATIENT,
  FETCH_PATIENT_REPORTS,
  FETCH_INSURANCES,
  UPLOAD_MEDIA_FILE,
  SEARCH_PATIENTS,
  MERGE_PATIENT_RECORDS,
  AUTOCOMPLETE_ADDRESS,
} from './types';

function* fetchPatients({ payload }) {
  const data = yield call(api.getPatients, payload);
  yield put(updatePatients(data.data));
  yield put(updatePagination(omit(['data'], data)));
}

function* fetchPatient({ payload }) {
  const data = yield call(api.getPatient, payload);
  yield put(updatePatient(data));
}

function* createPatient() {
  const data = yield call(api.postPatient);
  const id = prop(ID, data);

  yield put(updatePatient(data));

  return id && { success: prop(ID, data) };
}

const getContactErrors = (data = {}) => {
  const isValidEmail = !data[EMAIL] || isEmail(data[EMAIL].trim());
  const isValidInstitution = !data[INSTITUTION] || data[INSTITUTION].length <= 45;
  const isValidZipCode =
    !data[ZIP_CODE] || (string().min(4).isValidSync(data[ZIP_CODE]) && number().positive('').isValidSync(data[ZIP_CODE]));

  return !(isValidEmail && isValidInstitution && isValidZipCode)
    ? {
        ...(!isValidEmail && { [EMAIL]: 'E-Mail-Adresse ungültig' }),
        ...(!isValidInstitution && {
          [INSTITUTION]: 'Der Name der Institution darf max. 45 Zeichen betragen. Der Wert wird nicht gespeichert.',
        }),
        ...(!isValidZipCode && { [ZIP_CODE]: 'PLZ ungültig' }),
      }
    : null;
};
const validatePatient = (data = {}) => {
  const isValidPatientEmail = !data[EMAIL] || isEmail(data[EMAIL].trim());
  const ssn = pathOr('', [INSURANCE, SSN], data);
  const isValidSSN = !ssn || test(/^756\.\d{4}\.\d{4}\.\d{2}$/, ssn);
  const isValidPatientZipCode =
    !data[ZIP_CODE] || (string().min(4).isValidSync(data[ZIP_CODE]) && number().positive('').isValidSync(data[ZIP_CODE]));
  const agentErrors = getContactErrors(data[AGENT]);
  const otherContactsErrors = (data[OTHER_CONTACTS] || []).map(getContactErrors);
  const errors = {
    ...(!isValidPatientEmail && { [EMAIL]: 'E-Mail-Adresse ungültig' }),
    ...(agentErrors && { [AGENT]: agentErrors }),
    ...(any(Boolean, otherContactsErrors) && { [OTHER_CONTACTS]: otherContactsErrors }),
    ...(!isValidSSN && {
      [INSURANCE]: { [SSN]: 'Die AHV-Nummer muss folgendes Format haben 756.YYYY.YYYY.YY (z.B. 756.1234.1234.12).' },
    }),
    ...(!isValidPatientZipCode && { [ZIP_CODE]: 'PLZ ungültig' }),
  };

  return {
    errors,
    data: {
      ...omit(keys(errors), data),
      ...(data[INSURANCE]
        ? {
            [INSURANCE]: errors[INSURANCE] ? omit([SSN], data[INSURANCE]) : data[INSURANCE],
          }
        : {}),
    },
  };
};

function* savePatient({ payload }) {
  const { errors, data } = validatePatient(payload);
  const response = yield call(api.patchPatient, prop(ID, data), data);

  yield put(updatePatient(response));

  return { success: true, errors };
}

function* removePatient({ payload }) {
  const data = yield call(api.deletePatient, payload);
  yield put(deletePatient(data));
}

function* fetchPatientReports({ payload }) {
  const { data } = yield call(api.getPatientReports, payload);
  yield put(updatePatientReports(data));
}

function* fetchInsurances({ payload }) {
  return { success: yield call(api.getInsurances, payload) };
}

function* uploadMediaFile({ payload }) {
  const { path } = yield call(api.uploadFile, payload);

  return { success: `${URL}/${path}` };
}

function* searchPatients({ payload }) {
  return { success: yield call(api.getPatients, payload) };
}

function* mergePatientRecords({ payload }) {
  return yield call(api.mergePatientRecords, payload);
}

function* autocompleteAddress({ payload }) {
  return { success: yield call(api.addressAutocomplete, payload) };
}

export default function* watchPatients() {
  yield throttle(500, FETCH_PATIENTS, withAlert(fetchPatients));
  yield takeLatest(FETCH_PATIENT, withAlert(fetchPatient));
  yield takeEvery(CREATE_PATIENT, withAlert(createPatient));
  yield debounce(500, SAVE_PATIENT, withAlert(savePatient));
  yield takeEvery(REMOVE_PATIENT, withAlert(removePatient));
  yield takeLatest(FETCH_PATIENT_REPORTS, withAlert(fetchPatientReports));
  yield throttle(300, FETCH_INSURANCES, withAlert(fetchInsurances));
  yield takeLatest(UPLOAD_MEDIA_FILE, withAlert(uploadMediaFile));
  yield throttle(500, SEARCH_PATIENTS, withAlert(searchPatients));
  yield takeEvery(MERGE_PATIENT_RECORDS, withAlert(mergePatientRecords));
  yield throttle(500, AUTOCOMPLETE_ADDRESS, withAlert(autocompleteAddress));
}
