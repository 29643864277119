import styled from 'styled-components';

import Button from 'components/Button';
import Msg from 'components/Message';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-template-rows: 1fr auto;
  column-gap: 20px;
  align-items: center;
  margin: 0 0 10px 240px;
  ${({ error }) =>
    error &&
    `
      grid-template-rows: auto auto;
      align-items: end;
    `}
`;

export const Image = styled.button`
  grid-row: 1/3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  position: relative;
  border: 2px solid ${({ borderColor }) => borderColor || 'var(--color-white)'};
  border-radius: 50%;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
  &:disabled {
    opacity: 0.5;
  }
  & > svg {
    position: absolute;
    bottom: 0;
    right: -5px;
  }
  & > svg circle {
    fill: ${({ borderColor }) => borderColor || 'var(--color-white)'};
    transition: all 0.3s;
  }
`;

export const UploadButton = styled(Button)`
  height: 40px;
  position: relative;
  color: var(--color-white);
  transition: all 0.3s;
`;

export const Message = styled(Msg)`
  grid-column: 2/3;
  align-self: start;
  margin: 5px 0 0 0;
`;

export const CloseButton = styled(Button)`
  justify-self: end;
  width: 40px;
  height: 40px;
`;

export const ReviewContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
`;

export const ReviewWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  row-gap: 20px;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  overflow: auto;
  padding: 12px 15px;
`;

export const ReviewImage = styled.div`
  width: 100%;
  max-width: 800px;
  height: 100%;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: auto;
`;
