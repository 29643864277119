import React, { useCallback } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import MultiAnswerInput from 'components/MultiAnswerInput';
import { RIGHT, LEFT, PRESSURE } from 'store/examinations';

const PressureForm = ({ side, readMode }) => {
  const [{ value = [] }, { error }, { setValue }] = useField([side, PRESSURE].join('.'));

  const handleMultiInput = useCallback((data = '') => setValue(data), [setValue]);

  if (readMode) {
    return <MultiAnswerInput type="text" values={value} handleListChange={handleMultiInput} readMode={readMode} />;
  }

  return <MultiAnswerInput type="text" values={value} handleListChange={handleMultiInput} errors={error} />;
};

PressureForm.propTypes = {
  side: PropTypes.oneOf([RIGHT, LEFT]).isRequired,
  readMode: PropTypes.bool.isRequired,
};

export default PressureForm;
