import styled from 'styled-components';

import NavbarItem from 'components/NavbarItem';

export const Container = styled.div`
  max-width: 1024px;
  margin: 30px auto;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  column-gap: 10px;
`;

export const Button = styled(NavbarItem)`
  &:last-of-type {
    margin-left: auto;
  }
`;
