import React from 'react';

import { ID, PROGRESS, useCurrentExamination } from 'store/examinations';

import Item from './Item';
import { Container, Title, ItemsContainer } from './styles';

const CheckList = () => {
  const [examId, progress] = useCurrentExamination([ID, PROGRESS]);

  if (!(examId && progress)) return null;

  return (
    <Container>
      <Title>Checkliste Messung</Title>
      <ItemsContainer>
        {progress.map(({ [ID]: id, task, done, timestamp }) => (
          <Item key={id} task={task} done={done} timestamp={timestamp} />
        ))}
      </ItemsContainer>
    </Container>
  );
};

export default CheckList;
