import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import { ADD, REMOVE } from '../consts';
import { Text } from '../styles';

import { Container, IconWrap } from './styles';

const Item = ({ item, nameKey, type, onSelect, isSelected }) => {
  const name = useMemo(() => (typeof nameKey === 'function' ? nameKey(item) : item[nameKey]) || '-', [item, nameKey]);
  const handleClick = useCallback(() => onSelect(type, item), [item, onSelect, type]);

  return (
    <Container type="button" onClick={handleClick} $isSelected={isSelected}>
      <Text>{name}</Text>
      <IconWrap type={type}>
        <Icon />
      </IconWrap>
    </Container>
  );
};

Item.propTypes = {
  item: PropTypes.shape({}).isRequired,
  nameKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  type: PropTypes.oneOf([ADD, REMOVE]).isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default Item;
