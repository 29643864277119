import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { map, prop } from 'ramda';
import PropTypes from 'prop-types';

import getWebSocket from 'api/webSocket';
import { usePlanningActions, useAlerts, SUB_SCHEDULE } from 'store/planning';
import { SEARCHABLE_MEDIA_UPDATE } from 'store/medicalHistory';
import { useAsyncState } from 'utils/useAsyncState';
import Dropzone from 'components/Dropzone';
import Message from 'components/Message';
import QuestionContainer from 'components/QuestionContainer';

import MultiImage from './MultiImage';
import { DropContainer } from './styles';

const UploadPDF = ({ files, updateFiles }) => {
  const { id } = useParams();
  const [waitFiles, setWaitFiles] = useAsyncState([]);
  const { uploadScheduleFormMedia } = usePlanningActions();
  const { action, loading, error } = useAlerts(uploadScheduleFormMedia);

  const onDrop = useCallback(
    (values) => {
      if (!loading) {
        action({ files: values, id });
        setWaitFiles(($) => [...$, ...map(prop('path'), values)]);
      }
    },
    [loading, action, id, setWaitFiles]
  );

  useEffect(() => {
    const socket = getWebSocket(true, 'medicalHistory');
    const update = (data) => {
      if (data[SUB_SCHEDULE]) {
        updateFiles(($) => [...$, data]);
        setWaitFiles(($) => $.filter((name) => name.replace(/\W/g, '') !== data.originalname.replace(/\W/g, '')));
      }
    };

    socket.on(SEARCHABLE_MEDIA_UPDATE, update);

    return () => {
      socket.off(SEARCHABLE_MEDIA_UPDATE, update);
    };
  }, [setWaitFiles, updateFiles]);

  useEffect(() => {
    if (error) setWaitFiles([]);
  }, [error, setWaitFiles]);

  return (
    <QuestionContainer label="Einverständniserklärung">
      <DropContainer>
        <MultiImage values={files} waiting={waitFiles} updateValues={updateFiles} />
        <Dropzone onDrop={onDrop} loading={loading} />
      </DropContainer>
      {error && (
        <Message type="error" form={1}>
          {error}
        </Message>
      )}
    </QuestionContainer>
  );
};

UploadPDF.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  updateFiles: PropTypes.func.isRequired,
};

export default UploadPDF;
