import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { SORT_BY, SORT_DIR, ASC, DESC } from 'api/consts';
import ArrowAscDesc from 'assets/svg-icons/ArrowAscDesc';
import ArrowAsc from 'assets/svg-icons/ArrowAsc';
import ArrowDesc from 'assets/svg-icons/ArrowDesc';

import { Button, Content, Icon } from './styles';

const HeadItem = ({ id, sortId, sortDir, onClick, width, withoutSort, children }) => {
  const handleClick = useCallback(() => {
    if (withoutSort) return;
    onClick({ [SORT_BY]: id, [SORT_DIR]: sortDir === ASC ? DESC : ASC });
  }, [id, sortDir, onClick, withoutSort]);

  return (
    <Button type="button" width={width} onClick={handleClick} withoutSort={withoutSort}>
      <Content>{children}</Content>
      {!withoutSort && (
        <Icon>
          {sortId !== id && <ArrowAscDesc />}
          {sortId === id && sortDir === ASC && <ArrowAsc />}
          {sortId === id && sortDir === DESC && <ArrowDesc />}
        </Icon>
      )}
    </Button>
  );
};

HeadItem.defaultProps = {
  withoutSort: false,
};

HeadItem.propTypes = {
  id: PropTypes.string.isRequired,
  sortId: PropTypes.string.isRequired,
  sortDir: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  withoutSort: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default HeadItem;
