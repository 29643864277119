import { keys } from 'ramda';

import { INSURANCE, TIERS_GARANT, TIERS_PAYANT, LAW_TYPE_LABELS } from 'store/patients';

import { MODELS } from '../consts';

export const getPath = (name) => `${INSURANCE}.${name}`;

export const modelItems = [
  { label: 'Managed Care', value: MODELS.MANAGED_CARE },
  { label: 'Anderes', value: MODELS.OTHER },
];

export const yesNoItems = [
  { label: 'Ja', value: true },
  { label: 'Nein', value: false },
];

export const remunerationItems = [
  { label: 'Tiers payant', value: TIERS_PAYANT },
  { label: 'Tiers garant', value: TIERS_GARANT },
];

export const lawTypes = keys(LAW_TYPE_LABELS).map((key) => ({ label: LAW_TYPE_LABELS[key], value: +key }));
