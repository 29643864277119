import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useDebounceState } from 'utils/useDebounceState';
import Input from 'components/TextInputNew';

import { Container, Title, Wrapper, Item, InputWrap, Message } from './styles';

const List = ({ title, data, hovered, selected, onHover, onSelect, isFullHeight, withFilter }) => {
  const [value, debouncedValue, setValue] = useDebounceState('', 300);
  const onHoverStart = useCallback(({ currentTarget }) => onHover(currentTarget.value), [onHover]);
  const onHoverEnd = useCallback(() => onHover(), [onHover]);
  const onClick = useCallback(({ currentTarget }) => onSelect(currentTarget.value), [onSelect]);
  const list = useMemo(() => {
    if (!debouncedValue) return data;

    const regExp = new RegExp(debouncedValue, 'i');

    return data.filter(({ name }) => regExp.test(name));
  }, [data, debouncedValue]);

  return (
    <Container $isFullHeight={isFullHeight}>
      <Title>{title}</Title>
      {withFilter && (
        <InputWrap>
          <Input type="search" value={value} handleChangeText={setValue} mb={0} />
        </InputWrap>
      )}
      <Wrapper $isPending={value !== debouncedValue}>
        {list.map(({ id, name, color }) => (
          <Item
            key={id}
            type="button"
            value={id}
            onHoverStart={onHoverStart}
            onHoverEnd={onHoverEnd}
            onClick={onClick}
            $isHovered={hovered === id}
            $isSelected={selected[id]}
            $color={color}
          >
            {name}
          </Item>
        ))}
        {!list.length && <Message>{`Die Suche – ${value} – ergab keinen Treffer`}</Message>}
      </Wrapper>
    </Container>
  );
};

List.defaultProps = {
  data: [],
  hovered: null,
  isFullHeight: false,
  withFilter: false,
};
List.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }).isRequired
  ),
  hovered: PropTypes.string,
  selected: PropTypes.objectOf(PropTypes.bool.isRequired).isRequired,
  onHover: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  isFullHeight: PropTypes.bool,
  withFilter: PropTypes.bool,
};

export default List;
