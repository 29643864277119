import qs from 'query-string';

import {
  NURSING_HOME,
  FAMILY_DOCTOR,
  OPTICIAN,
  EYE_DOCTOR,
  NAME,
  INSURANCE,
  SUB_COMPANY,
  LAW_TYPES,
  STREET,
  ZIP_CODE,
  TOWN,
  COUNTRY,
  PHONE,
  EMAIL,
} from 'store/patients';
import { REPORT_URL } from 'store/reports';

import { wrapperRequest } from './utils';
import {
  API_URL,
  POST,
  PATCH,
  DELETE,
  EXPAND,
  FIELDS,
  SUB_ORGANIZATION,
  META,
  CURSOR,
  LIMIT,
  SORT_BY,
  SORT_DIR,
  ASC,
} from './consts';

export const getPatients = (query = {}) =>
  wrapperRequest({
    url: `${API_URL}/patients?${qs.stringify(query)}`,
  });

const patientQuery = {
  [EXPAND]: [
    `${NURSING_HOME}.${SUB_ORGANIZATION}`,
    `${FAMILY_DOCTOR}.${SUB_ORGANIZATION}`,
    `${EYE_DOCTOR}.${SUB_ORGANIZATION}`,
    `${OPTICIAN}.${SUB_ORGANIZATION}`,
    `${INSURANCE}.${SUB_COMPANY}`,
  ],
};
const patientReportsQuery = { [FIELDS]: [META, REPORT_URL], [LIMIT]: 50 };
const insurancesQuery = {
  [FIELDS]: [NAME, STREET, ZIP_CODE, TOWN, COUNTRY, PHONE, EMAIL, LAW_TYPES],
  [CURSOR]: 0,
  [LIMIT]: 10,
  [SORT_BY]: NAME,
  [SORT_DIR]: ASC,
};

export const getPatient = (id) =>
  wrapperRequest({
    url: `${API_URL}/patients/${id}?${qs.stringify(patientQuery)}`,
  });

export const postPatient = (body = {}) =>
  wrapperRequest({
    url: `${API_URL}/patients`,
    options: { method: POST, body },
  });

export const patchPatient = (id, body = {}) =>
  wrapperRequest({
    url: `${API_URL}/patients/${id}?${qs.stringify(patientQuery)}`,
    options: { method: PATCH, body },
  });

export const deletePatient = (id) =>
  wrapperRequest({
    url: `${API_URL}/patients/${id}`,
    options: { method: DELETE },
  });

export const getPatientReports = (id) =>
  wrapperRequest({
    url: `${API_URL}/patients/${id}/reports?${qs.stringify(patientReportsQuery)}`,
  });

export const getInsurances = (params = {}) =>
  wrapperRequest({
    url: `${API_URL}/insurances?${qs.stringify({ ...insurancesQuery, ...params })}`,
  });

export const mergePatientRecords = (body = {}) =>
  wrapperRequest({
    url: `${API_URL}/patients/merge`,
    options: { method: POST, body },
  });

export const addressAutocomplete = (body = {}) =>
  wrapperRequest({
    url: `${API_URL}/addresses/autocomplete`,
    options: { method: POST, body },
  });
