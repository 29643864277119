import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from 'pages/Login';
import ExternLogin from 'pages/ExternLogin';
import Reset from 'pages/Reset';
import SetPassword from 'pages/SetPassword';
import Releases from 'pages/Releases';
import Patients from 'pages/Patients';
import Patient from 'pages/Patient';
import Organizations from 'pages/Organizations';
import Organization from 'pages/Organization';
import ExternReports from 'pages/ExternReports';
import Reports from 'pages/Reports';
import Invoices from 'pages/Invoices';
import Invoice from 'pages/Invoice';
import BugReports from 'pages/BugReports';
import BugReport from 'pages/BugReport';
import Planning from 'pages/Planning';
import Profile from 'pages/Profile';
import User from 'pages/User';
import Insights from 'pages/Insights';
import Llm from 'pages/Llm';
import GlobalStyles from 'theme/GlobalStyles';
import { PATIENT, ORGANIZATION, REPORT, INVOICE, BUG_REPORT, USER, USERS, PLANNING, INSIGHTS, LLM } from 'permissions';

import InitApp from './InitApp';
import Navbar from './Navbar';
import PrivateRoute from './PrivateRoute';

const patientsRoutes = ['/patients', '/patients/search', '/patients/tasks', '/patients/duplicates'];
const profileRoutes = ['/profile', '/profile/authentication'];
const planningRoutes = ['/planning', '/planning/:id', '/planning/:id/:patientId'];

const Root = () => (
  <>
    <GlobalStyles />
    <Navbar />
    <InitApp>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/reset" component={Reset} />
        <Route exact path="/extern" component={ExternLogin} />
        <Route path="/setpassword" component={SetPassword} />
        <Route exact path="/releases" component={Releases} />
        <PrivateRoute exact path={patientsRoutes} resource={PATIENT} component={Patients} />
        <PrivateRoute exact path="/patients/:id/:view?" resource={PATIENT} component={Patient} />
        <PrivateRoute exact path="/organizations" resource={ORGANIZATION} component={Organizations} />
        <PrivateRoute exact path="/organizations/:id" resource={ORGANIZATION} component={Organization} />
        <PrivateRoute exact path="/reports" resource={REPORT} component={Reports} />
        <PrivateRoute exact path="/invoices" resource={INVOICE} component={Invoices} />
        <PrivateRoute exact path="/invoices/:id" resource={INVOICE} component={Invoice} />
        <PrivateRoute exact path="/bugreports" resource={BUG_REPORT} component={BugReports} />
        <PrivateRoute exact path="/bugreports/:id" resource={BUG_REPORT} component={BugReport} />
        <PrivateRoute exact path={profileRoutes} component={Profile} resource={USER} />
        <PrivateRoute exact path="/extern/reports" resource={REPORT} component={ExternReports} />
        <PrivateRoute exact path={planningRoutes} resource={PLANNING} component={Planning} />
        <PrivateRoute exact path="/users/:id" resource={USERS} component={User} />
        <PrivateRoute exact path="/insights" resource={INSIGHTS} component={Insights} />
        <PrivateRoute exact path="/llm" resource={LLM} component={Llm} />
        <Redirect to="/login" />
      </Switch>
    </InitApp>
  </>
);

export default Root;
