import React, { useCallback } from 'react';
import { max, min, pipe, path, when } from 'ramda';
import PropTypes from 'prop-types';

import ArrowRight from 'assets/svg-icons/ArrowRight';

import { Container, SwitcherWrap, SwitcherText, Button, MatchWrap, MatchText, PagesLinkWrap, MatchLink, Message } from './styles';

const Panel = ({ page, updatePage, pages, matches }) => {
  const handleSwitchPrev = useCallback(() => updatePage(max(0, page - 1)), [page, updatePage]);
  const handleSwitchNext = useCallback(() => updatePage(min(pages - 1, page + 1)), [page, pages, updatePage]);
  const handleLinkClick = useCallback(
    (e) => pipe(path(['target', 'dataset', 'id']), when(Boolean, pipe(Number, updatePage)))(e),
    [updatePage]
  );

  if (pages === 0) return <Message>Kein Text im Bild gefunden.</Message>;

  return (
    <Container>
      <SwitcherWrap>
        <SwitcherText>{`Seite ${page + 1} von ${pages}`}</SwitcherText>
        <Button disabled={page === 0} onClick={handleSwitchPrev}>
          <ArrowRight />
        </Button>
        <Button disabled={page === pages - 1} onClick={handleSwitchNext}>
          <ArrowRight />
        </Button>
      </SwitcherWrap>
      <MatchWrap isActive={matches.length}>
        <MatchText>Begriff wurde auf folgenden Seiten gefunden</MatchText>
        <PagesLinkWrap>
          {matches.map((index) => (
            <MatchLink key={index} type="button" data-id={index} onClick={handleLinkClick} disabled={page === index}>
              {index + 1}
            </MatchLink>
          ))}
        </PagesLinkWrap>
      </MatchWrap>
    </Container>
  );
};

Panel.propTypes = {
  page: PropTypes.number.isRequired,
  updatePage: PropTypes.func.isRequired,
  pages: PropTypes.number.isRequired,
  matches: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default Panel;
