import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export const Container = styled(motion.div)`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: var(--border-radius8);
  position: relative;
`;

export const Item = styled.div`
  width: ${({ width }) => `calc(${width} - 10px)`};
  font-size: ${({ $fontSize }) => ($fontSize === 10 ? 'var(--font-size10)' : 'var(--font-size18)')};
  font-weight: ${({ $fontSize }) => ($fontSize === 10 ? 600 : 400)};
  letter-spacing: ${({ $fontSize }) => ($fontSize === 10 ? 1.01 : 0.5)}px;
  color: ${({ $color }) => $color || 'var(--color-grey6A)'};
  text-align: left;
  text-transform: ${({ $fontSize }) => ($fontSize === 10 ? 'uppercase' : 'none')};
`;

export const LinkWrap = styled(motion.div).attrs(() => ({
  animate: { opacity: 1 },
  transition: { duration: 0.5 },
  initial: { opacity: 0 },
}))`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 220px;
  position: absolute;
  inset: 0 0 auto auto;
  padding-left: 15px;
  transform: translateX(min(100%, calc((100vw - 950px) / 2)));
  z-index: 1;
  @media (max-width: 1101px) {
    transform: translateX(80px);
  }
`;

export const NavLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 100%;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyEC);
  font-size: var(--font-size13);
  font-weight: 500;
  letter-spacing: 0.92px;
  color: var(--color-grey6A);
  text-transform: uppercase;
  text-decoration: none;
  @media (hover) {
    &:hover {
      background-color: var(--color-greyF8);
    }
  }
  &:active {
    background-color: #bdbcbc;
  }
`;
