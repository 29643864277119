import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <circle cx="15" cy="15" r="15" stroke="#F5A623" />
      <path
        fill="#F5A623"
        fillRule="nonzero"
        d="M15.426 18.891h-.667l-.368-6.509V7h1.426v5.382l-.391 6.509zm-.322 4.37c-.759 0-1.104-.414-1.104-1.035v-.253c0-.621.345-1.035 1.104-1.035.736 0 1.104.414 1.104 1.035v.253c0 .621-.368 1.035-1.104 1.035z"
      />
    </g>
  </svg>
);
