import styled from 'styled-components';

export const Container = styled.div`
  grid-row: 1/-1;
  grid-column: 2/3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: var(--color-greyF8);
  border: none;
  border-radius: var(--border-radius8);
  outline: none;
  cursor: pointer;
  transition: background-color 0.5s;
  &:hover {
    background-color: var(--color-greyEC);
  }
`;
