import styled from 'styled-components';
import { motion } from 'framer-motion';

import { ANIMATE_DURATION } from '../consts';

export const Container = styled(motion.button).attrs(({ $size, $left, $top }) => ({
  animate: {
    height: `${Math.max(0, $size) * 100}%`,
    top: `${(1 - $top) * 100}%`,
    left: `${$left * 100}%`,
  },
  transition: { duration: ANIMATE_DURATION },
  initial: false,
}))`
  display: flex;
  flex-direction: column;
  aspect-ratio: 1/1;
  position: absolute;
  border-radius: 50%;
  border: none;
  background: none;
  outline: none;
  transform: translate(-50%, -50%) translateZ(${({ $zIndex }) => $zIndex}px);
  cursor: pointer;
  z-index: ${({ $zIndex }) => $zIndex};
  pointer-events: ${({ $zIndex }) => ($zIndex ? 'auto' : 'none')};
`;

export const Circle = styled(motion.div)`
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 1px solid;
  border-color: ${({ $borderColor }) => $borderColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  opacity: ${({ $opacity }) => $opacity};
  transition: opacity 0.25s;
`;

export const Rect = styled.div`
  position: absolute;
  inset: 0;
  &:before {
    content: '';
    display: block;
    width: 100vw;
    height: 1px;
    position: absolute;
    inset: 50% 100% auto auto;
    background: repeating-linear-gradient(90deg, black 0vw, black 0.25vw, transparent 0.25vw, transparent 0.5vw);
    transform: translateY(-50%);
  }
  &:after {
    content: '';
    display: block;
    width: 1px;
    height: 100vh;
    position: absolute;
    inset: 100% auto auto 50%;
    background: repeating-linear-gradient(0deg, black 0vw, black 0.25vw, transparent 0.25vw, transparent 0.5vw);
    transform: translate(-50%);
  }
`;

export const InfoBox = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-items: start;
  align-items: center;
  column-gap: 5px;
  position: absolute;
  inset: ${({ $top, $left }) =>
    ($top < 0.5 && $left <= 0.5 && 'auto auto 100% calc(100% + 10px)') ||
    ($top < 0.5 && $left > 0.5 && 'auto calc(100% + 10px) 100% auto') ||
    ($top >= 0.5 && $left > 0.5 && '100% calc(100% + 10px) auto auto') ||
    '100% auto auto calc(100% + 10px)'};
  border: 1px solid var(--color-black);
  border-radius: var(--border-radius8);
  background-color: var(--color-white);
  padding: 5px;
`;

export const Label = styled.p`
  width: max-content;
  font-size: var(--font-size14);
  &:after {
    content: ':';
  }
`;

export const Text = styled.p`
  justify-self: center;
  width: max-content;
  font-size: var(--font-size14);
`;
