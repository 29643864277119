import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { props } from 'ramda';

import { wrapActions, wrapMultiSelector, wrapSelectorWithArg } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { useAsyncState } from 'utils/useAsyncState';
import {
  STATUS,
  ALL,
  SEARCH,
  SORT,
  PAGINATION,
  LIMIT,
  CURSOR,
  FIELDS,
  EXPAND,
  DATE,
  SUB_ORGANIZATION,
  META,
  EDITOR,
} from 'api/consts';

import * as actions from './actions';
import {
  getFilters,
  getCurrentSchedule,
  getCurrentScheduleProp,
  getScheduleUpdates,
  getScheduleUpdatesProp,
  getUpdatedRegistration,
} from './selectors';
import { REGISTRATIONS, DAYS } from './consts';
import { handleScheduleList } from './utils';

export const usePlanningActions = wrapActions({ ...actions });

export const useCurrentSchedule = wrapMultiSelector(getCurrentScheduleProp, getCurrentSchedule);
export const useScheduleUpdates = (scheduleId, key) =>
  wrapMultiSelector(getScheduleUpdatesProp(scheduleId), getScheduleUpdates(scheduleId))(key);
export const useUpdatedRegistration = (scheduleId, index) => wrapSelectorWithArg(getUpdatedRegistration(scheduleId))(index);

export const useInitSchedules = (hasAccess) => {
  const [list, setList] = useAsyncState(null);
  const filters = useSelector(getFilters);
  const [status, search, sort, pagination] = props([STATUS, SEARCH, SORT, PAGINATION], filters);
  const [limit, cursor] = props([LIMIT, CURSOR], pagination);

  const { fetchSchedules } = usePlanningActions();
  const { action, loading, success, error } = useAlerts(fetchSchedules);

  useEffect(() => {
    if (!hasAccess) return;

    action({
      [STATUS]: status === ALL ? '' : status,
      [SEARCH]: search,
      [LIMIT]: limit,
      [CURSOR]: cursor,
      [FIELDS]: [DATE, DAYS, REGISTRATIONS, SUB_ORGANIZATION, META, STATUS],
      [EXPAND]: [SUB_ORGANIZATION, `${META}.${EDITOR}`],
      ...sort,
    });
  }, [action, cursor, hasAccess, limit, search, sort, status]);

  useEffect(() => {
    if (success) setList(handleScheduleList(success));
  }, [setList, success]);

  return { list, loading, error, status, search, sort, pagination };
};
