import styled from 'styled-components';

import Btn from 'components/Button';
import ConfirmMessage from 'components/Confirm';
import Msg from 'components/Message';

export const Title = styled.p`
  font-size: var(--font-size18);
  font-weight: var(--font-weight600);
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: var(--color-grey97);
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;

export const Text = styled(Title)`
  font-weight: var(--font-weight400);
  margin-bottom: 22px;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Button = styled(Btn)`
  width: calc(50% - 10px);
  height: 40px;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.p`
  font-size: var(--font-size18);
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: var(--color-grey97);
  margin-right: 20px;
`;

export const ActiveLabel = styled.div`
  width: 180px;
  height: 50px;
  border-radius: var(--border-radius8);
  background-color: ${({ disabled }) => (disabled ? 'none' : 'var(--color-green65)')};
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  letter-spacing: 0.92px;
  line-height: 50px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-white);
`;

export const Confirm = styled(ConfirmMessage)`
  justify-content: flex-start;
  & > div > p {
    width: calc(350px / 2 - 40px);
  }
  & > div > button {
    width: calc(350px / 4 - 5px);
  }
`;

export const MessageContainer = styled.div`
  width: calc(50% - 10px);
`;

export const Message = styled(Msg)`
  max-width: 350px;
  font-size: var(--font-size13);
  margin: 3px 0;
`;
