import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  column-gap: 5px;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--color-greyEC);
  border-radius: var(--border-radius8);
  padding: 0 5px 0 10px;
`;

export const Wrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size18);
  padding: 5px 0;
`;

export const Text = styled.p`
  font-size: var(--font-size18);
  text-align: left;
  color: var(--color-grey6A);
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  min-width: 25px;
  height: 34px;
  border-radius: var(--border-radius8);
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  color: var(--color-grey97);
  @media (hover) {
    &:hover {
      background: none;
      color: var(--color-black);
    }
  }
`;
