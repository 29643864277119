import styled from 'styled-components';

export const DateText = styled.p`
  min-width: 160px;
  line-height: 1.15;
  font-size: var(--font-size13);
  display: flex;
  align-items: center;
`;

export const Arrow = styled.span`
  margin: 0 7px;
  display: flex;
`;
