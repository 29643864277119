import { useRef, useCallback, useState } from 'react';

import { useAsyncState } from './useAsyncState';

export const useDebounceState = (init, delay) => {
  const timer = useRef(null);
  const [value, setValue] = useState(init);
  const [debouncedValue, setDebouncedValue] = useAsyncState(init);

  const handleChange = useCallback(
    (val) => {
      setValue(val);

      if (timer.current) clearTimeout(timer.current);

      timer.current = setTimeout(setDebouncedValue, delay, val);
    },
    [delay, setDebouncedValue]
  );

  return [value, debouncedValue, handleChange];
};
