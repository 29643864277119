import styled from 'styled-components';

import TextInput from 'components/TextInputNew';

export const Input = styled(TextInput)`
  ${({ error }) => !error && 'border: 1px solid #dedede'};
  font-size: var(--font-size12);
  height: 27px;
  line-height: 1.33;
  letter-spacing: 0.33px;
`;
