import styled from 'styled-components';

import TextInputWithSelect from 'components/TextInputWithSelect';

export const Container = styled.div`
  width: 90%;
  max-width: 1024px;
  margin: 80px auto 50px auto;
`;

export const Wrapper = styled.div`
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid var(--color-grey97);
  margin-bottom: 80px;
`;

export const Label = styled.p`
  color: var(--color-grey6A);
  font-size: var(--font-size15);
  font-weight: var(--font-weight600);
  margin-bottom: 25px;
`;

export const SearchPanel = styled(TextInputWithSelect)`
  margin-bottom: 35px;
`;
