import { mergeRight, ifElse, is, F, find, propEq } from 'ramda';

import { DIAGNOSIS, RIGHT, LEFT, EYE } from 'store/diagnoses';
import checkboxTemplates from './checkboxTemplates.json';

const extractFieldsForEye = (template, commonData, data) =>
  template.reduce((acc, { id, checkboxes, value, type }) => {
    let subAcc;
    const item = type === 'both' ? commonData : data;

    acc[id] = item[id] || ifElse(is(String), () => '', F)(value);
    if (checkboxes) {
      subAcc = checkboxes.reduce(
        (counter, subItem) => mergeRight(counter, extractFieldsForEye(subItem || [], commonData, data)),
        {}
      );
    }

    return mergeRight(acc, subAcc);
  }, {});

export const extractFields = (data = {}) =>
  [RIGHT, LEFT].reduce((acc, eye) => {
    const eyeData = find(propEq(eye, EYE), data[DIAGNOSIS] || []) || {};

    acc[eye] = extractFieldsForEye(checkboxTemplates || [], data, eyeData || {});

    return acc;
  }, {});
