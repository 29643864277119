import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { useMotionValue, useTransform, animate } from 'framer-motion';
import PropTypes from 'prop-types';

import { useAsyncState } from 'utils/useAsyncState';
import { ANIMATE_DURATION } from '../consts';

import { ReactComponent as Play } from './play.svg';
import { ReactComponent as Pause } from './pause.svg';
import { Container, Button, Track, Thumb } from './styles';

const Playground = ({ index, setIndex, items }) => {
  const [init] = useState(() => index / (items.length - 1));
  const ref = useRef();
  const controls = useRef();
  const latest = useRef(init);
  const ratio = useMotionValue(init);
  const left = useTransform(ratio, [0, 1], ['0%', '100%']);
  const thumbStyle = useMemo(() => ({ left }), [left]);
  const [playing, setPlaying] = useAsyncState(false);

  const startAnimate = useCallback(() => {
    const currentRatio = ratio.get();

    controls.current = animate(currentRatio, currentRatio === 1 ? 0 : 1, {
      duration: currentRatio === 1 ? 3 * ANIMATE_DURATION : (1 - currentRatio) * ANIMATE_DURATION * (items.length - 1),
      ease: 'linear',
      onUpdate: (val) => ratio.set(val),
      onComplete: () => {
        controls.current = null;
        setPlaying(false);
      },
    });
    setPlaying(true);
  }, [items.length, ratio, setPlaying]);
  const stopAnimate = useCallback(() => {
    controls.current?.stop();
    controls.current = null;
    setPlaying(false);
  }, [setPlaying]);
  const onPlayPause = useCallback(() => {
    if (controls.current) {
      stopAnimate();
    } else {
      startAnimate();
    }
  }, [startAnimate, stopAnimate]);
  const setRatio = useCallback(
    (pageX) => {
      const { x, width } = ref.current.getBoundingClientRect();

      ratio.set(Math.max(0, Math.min(1, (pageX - x) / width)));
    },
    [ratio]
  );
  const onMouseDown = useCallback(
    ({ pageX }) => {
      stopAnimate();
      setRatio(pageX);

      const onMouseMove = (e) => setRatio(e.pageX);
      const onMouseUp = () => {
        document.body.removeEventListener('mousemove', onMouseMove);
        document.body.removeEventListener('mouseup', onMouseUp);
        document.body.removeEventListener('mouseleave', onMouseUp);
      };

      document.body.addEventListener('mousemove', onMouseMove);
      document.body.addEventListener('mouseup', onMouseUp);
      document.body.addEventListener('mouseleave', onMouseUp);
    },
    [setRatio, stopAnimate]
  );

  useEffect(() => {
    ratio.onChange((val) => {
      const i = Math.round(val * (items.length - 1));

      if (latest.current !== i) {
        setIndex(i);
        latest.current = i;
      }
    });
  }, [items.length, ratio, setIndex]);

  useEffect(() => {
    ratio.set(index / (items.length - 1));
  }, [index, items.length, ratio]);

  return (
    <Container>
      <Button type="button" onClick={onPlayPause}>
        {playing ? <Pause /> : <Play />}
      </Button>
      <Track ref={ref} type="button" onMouseDown={onMouseDown}>
        <Thumb style={thumbStyle} $content={items[index]} />
      </Track>
    </Container>
  );
};

Playground.propTypes = {
  index: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired).isRequired,
  setIndex: PropTypes.func.isRequired,
};

export default Playground;
