import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import TextArea from 'components/TextAreaNew';
import { COMMENT } from 'store/examinations';

const Comment = ({ readMode }) => {
  const [{ value }, , { setValue }] = useField(COMMENT);

  return <TextArea placeholder="Kommentar" value={value} onChangeText={setValue} rows={8} readMode={readMode} />;
};

Comment.propTypes = {
  readMode: PropTypes.bool.isRequired,
};

export default Comment;
