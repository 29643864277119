import { combineReducers } from 'redux';

import { FEATURE_NAME as session } from 'store/session/consts';
import sessionReducer from 'store/session/reducer';

import { FEATURE_NAME as alerts } from 'store/alerts/consts';
import alertsReducer from 'store/alerts/reducer';

import { FEATURE_NAME as patients } from 'store/patients/consts';
import patientsReducer from 'store/patients/reducer';

import { FEATURE_NAME as organizations } from 'store/organizations/consts';
import organizationsReducer from 'store/organizations/reducer';

import { FEATURE_NAME as medicalHistory } from 'store/medicalHistory/consts';
import medicalHistoryReducer from 'store/medicalHistory/reducer';

import { FEATURE_NAME as examinations } from 'store/examinations/consts';
import examinationsReducer from 'store/examinations/reducer';

import { FEATURE_NAME as diagnoses } from 'store/diagnoses/consts';
import diagnosesReducer from 'store/diagnoses/reducer';

import { FEATURE_NAME as reports } from 'store/reports/consts';
import reportsReducer from 'store/reports/reducer';

import { FEATURE_NAME as invoices } from 'store/invoices/consts';
import invoicesReducer from 'store/invoices/reducer';

import { FEATURE_NAME as bugReports } from 'store/bugReports/consts';
import bugReportsReducer from 'store/bugReports/reducer';

import { FEATURE_NAME as errors } from 'store/errors/consts';
import errorsReducer from 'store/errors/reducer';

import { FEATURE_NAME as infoItems } from 'store/infoItems/consts';
import infoItemsReducer from 'store/infoItems/reducer';

import { FEATURE_NAME as planning } from 'store/planning/consts';
import planningReducer from 'store/planning/reducer';

import { FEATURE_NAME as analytics } from 'store/analytics/consts';
import analyticsReducer from 'store/analytics/reducer';

export default combineReducers({
  [session]: sessionReducer,
  [alerts]: alertsReducer,
  [patients]: patientsReducer,
  [organizations]: organizationsReducer,
  [medicalHistory]: medicalHistoryReducer,
  [examinations]: examinationsReducer,
  [diagnoses]: diagnosesReducer,
  [reports]: reportsReducer,
  [invoices]: invoicesReducer,
  [bugReports]: bugReportsReducer,
  [errors]: errorsReducer,
  [infoItems]: infoItemsReducer,
  [planning]: planningReducer,
  [analytics]: analyticsReducer,
});
