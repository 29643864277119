import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: ${({ inlineStyle }) => (inlineStyle ? 'center' : 'stretch')};
  flex-direction: ${({ inlineStyle }) => (inlineStyle ? 'row' : 'column')};
  margin-bottom: 10px;
`;

export const Label = styled.label`
  width: ${({ smallLabel }) => (smallLabel ? '240px' : '60%')};
  min-width: 240px;
  text-align: left;
  color: var(--color-grey6A);
  font-weight: var(--font-weight400);
  font-size: ${({ smallLabel }) => (smallLabel ? 'var(--font-size15)' : 'var(--font-size18)')};
  margin-bottom: ${({ withBottomMargin }) => (withBottomMargin ? 10 : 0)}px;
  padding-right: 10px;
  ${({ alignedToTop }) => alignedToTop && 'align-self: flex-start'};
`;
