import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.p`
  margin: 0 10px;
  font-size: var(--font-size10);
  letter-spacing: 1px;
  font-weight: var(--font-weight600);
  color: ${({ allowChange }) => (allowChange ? 'var(--color-grey6A)' : '#b8b8b8')};
  transition: all 0.3s ease-in-out;
`;

export const Container = styled.button`
  width: 35px;
  height: 20px;
  border: none;
  border-radius: 10px;
  background: ${({ checked, allowChange }) => {
    if (!allowChange) return '#bebebe';

    return checked ? '#6bd383' : '#949494';
  }};
  outline: none;
  cursor: ${({ allowChange }) => (allowChange ? 'pointer' : 'not-allowed')};
  &:focus {
    box-shadow: ${({ allowChange }) => (allowChange ? 'var(--shadow-focus)' : 'none')};
  }
  transition: all 0.3s ease-in-out;
`;

export const Thumb = styled.div`
  position: relative;
  left: ${({ checked }) => (checked ? 17 : 2)}px;
  width: 16px;
  height: 16px;
  border-radius: var(--border-radius8);
  background: var(--color-greyEC);
  transition: all 0.3s ease-in-out;
`;
