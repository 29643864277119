import { useEffect } from 'react';

import { useLoggedIn } from 'store/session';
import { extractBugsnagReport } from 'api/bugsnagOfflineMode';

export const useBugsnagOffline = () => {
  const isLoggedIn = useLoggedIn();

  useEffect(() => {
    if (!(isLoggedIn && window)) return () => null;
    if (window.navigator?.onLine) extractBugsnagReport();

    window.addEventListener('online', extractBugsnagReport);

    return () => window.removeEventListener('online', extractBugsnagReport);
  }, [isLoggedIn]);
};
