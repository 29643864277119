import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_PARAMS = getName('UPDATE_PARAMS');

export const GET_ANALYTICS = getName('GET_ANALYTICS');
export const ASK_QUESTION = getName('ASK_QUESTION');
