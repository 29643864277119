import React, { useCallback, useEffect, useMemo } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import Dropzone from 'components/Dropzone';
import MultiImage from 'components/MultiImage';
import { useExaminationsActions, useAlerts, RIGHT, LEFT, VIEW, FUNDUS, FILES } from 'store/examinations';
import { MODE, OFFLINE_MODE, useUser } from 'store/session';
import { mediaLabels } from '../consts';

import { DropContainer } from './styles';

const MediaForm = ({ side, feature, readMode }) => {
  const userMode = useUser(MODE);
  const path = useMemo(() => [side, feature, FILES], [side, feature]);
  const { extractId, success, loading, resetAlerts } = useAlerts();
  const { uploadMedia } = useExaminationsActions();
  const [{ value = [] }, , { setValue }] = useField(path.join('.'));

  const onDrop = useCallback(
    (files) => {
      if (!files.length) return;

      extractId(uploadMedia({ files, feature: path, offlineMode: userMode === OFFLINE_MODE }));
    },
    [extractId, path, uploadMedia, userMode]
  );

  useEffect(() => {
    if (success) {
      setValue(success);
      resetAlerts();
    }
  }, [resetAlerts, setValue, success]);

  if (readMode) {
    return (
      <DropContainer readMode>
        <MultiImage label={mediaLabels[feature]} values={value} readMode={readMode} />
      </DropContainer>
    );
  }

  return (
    <DropContainer>
      <MultiImage label={mediaLabels[feature]} values={value} updateValues={setValue} />
      <Dropzone onDrop={onDrop} loading={loading} />
    </DropContainer>
  );
};

MediaForm.propTypes = {
  side: PropTypes.oneOf([RIGHT, LEFT]).isRequired,
  feature: PropTypes.oneOf([VIEW, FUNDUS]).isRequired,
  readMode: PropTypes.bool.isRequired,
};

export default MediaForm;
