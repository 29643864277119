import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0 0 10px 240px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  min-height: ${({ $isLoading }) => ($isLoading ? 50 : 25)}px;
  max-height: 110px;
  box-sizing: border-box;
  overflow: auto;
`;

export const SpinnerWrap = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  inset: 1px;
  background-color: rgba(255, 255, 255, 0.75);
`;
