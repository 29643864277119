import { EXAMINATION_STATUS } from 'store/examinations';
import {
  PHYSICIAN,
  PHYSICIAN_JUNIOR,
  PHYSICIAN_MIDLEVEL,
  PHYSICIAN_SENIOR,
  NURSE,
  PERSONAL,
  MEDICAL_HISTORY,
  EXAMINATION,
  ANALYSIS,
  RECOMMEND,
  DIAGNOSIS,
  INVOICE,
  READ,
  UPDATE,
  BILLS,
} from '../consts';

const { IN_PREPARATION, SUBMITTED, UNDER_REVIEW, IN_APPROVAL, APPROVED } = EXAMINATION_STATUS;

export default {
  [NURSE]: {
    [PERSONAL]: () => [UPDATE, READ],
    [MEDICAL_HISTORY]: () => [UPDATE, READ],
    [EXAMINATION]: (status) => {
      if (!status) return [UPDATE];
      if (status === IN_PREPARATION) return [UPDATE, READ];
      return [READ];
    },
  },
  [PHYSICIAN]: {
    [PERSONAL]: () => [READ, UPDATE],
    [MEDICAL_HISTORY]: () => [READ, UPDATE],
    [BILLS]: () => [READ, UPDATE],
  },
  [PHYSICIAN_JUNIOR]: {
    [ANALYSIS]: (status) => {
      if ([SUBMITTED, UNDER_REVIEW].includes(status)) return [UPDATE, READ];
      return [READ];
    },
    [DIAGNOSIS]: (status) => ([SUBMITTED, UNDER_REVIEW].includes(status) ? [UPDATE] : [READ]),
    [RECOMMEND]: (status) => {
      if ([SUBMITTED, UNDER_REVIEW].includes(status)) return [UPDATE, READ];
      return [READ];
    },
    [INVOICE]: (status) => {
      if ([SUBMITTED, UNDER_REVIEW, APPROVED].includes(status)) return [UPDATE, READ];
      return [READ];
    },
  },
  [PHYSICIAN_MIDLEVEL]: {
    [ANALYSIS]: (status) => {
      if ([SUBMITTED, UNDER_REVIEW].includes(status)) return [UPDATE, READ];
      return [READ];
    },
    [DIAGNOSIS]: (status) => ([SUBMITTED, UNDER_REVIEW].includes(status) ? [UPDATE] : [READ]),
    [RECOMMEND]: (status) => {
      if ([SUBMITTED, UNDER_REVIEW].includes(status)) return [UPDATE, READ];
      return [READ];
    },
    [INVOICE]: (status) => {
      if ([SUBMITTED, UNDER_REVIEW].includes(status)) return [UPDATE, READ];
      return [READ];
    },
  },
  [PHYSICIAN_SENIOR]: {
    [ANALYSIS]: (status) => {
      if ([SUBMITTED, UNDER_REVIEW, IN_APPROVAL, APPROVED].includes(status)) return [UPDATE, READ];
      return [READ];
    },
    [DIAGNOSIS]: (status) => ([SUBMITTED, UNDER_REVIEW, IN_APPROVAL, APPROVED].includes(status) ? [UPDATE] : [READ]),
    [RECOMMEND]: (status) => {
      if ([SUBMITTED, UNDER_REVIEW, IN_APPROVAL, APPROVED].includes(status)) return [UPDATE, READ];
      return [READ];
    },
    [INVOICE]: (status) => {
      if ([SUBMITTED, UNDER_REVIEW, IN_APPROVAL, APPROVED].includes(status)) return [UPDATE, READ];
      return [READ];
    },
  },
};
