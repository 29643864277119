import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavLink = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 38px;
  min-width: 38px;
  aspect-ratio: 1/1;
  background-color: var(--color-greyF8);
  border: 1px solid;
  border-color: var(--color-greyF8);
  border-radius: var(--border-radius8);
  color: var(--color-grey6A);
  font-weight: var(--font-weight500);
  font-size: 8px;
  line-height: 8px;
  text-align: left;
  text-decoration: none;
  padding: 3px;
  &:before {
    content: '?';
    font-size: 16px;
    font-weight: var(--font-weight500);
    color: var(--color-grey6A);
    line-height: 16px;
  }
  @media (hover) {
    &:hover {
      background-color: var(--color-greyEC);
    }
  }
`;
