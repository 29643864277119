import React, { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { CUSTOM, useReportsActions, useAlerts } from 'store/reports';
import Checkbox from 'components/CheckboxNew';
import { dropDownItems } from '../consts';

import { Container, Wrapper, Select } from './styles';

const DropDown = ({ marked, all, checkboxStatus, setCheckboxStatus, saveSuccess, archiveSuccess }) => {
  const handleOnSelect = useCallback(({ target }) => setCheckboxStatus(target.value), [setCheckboxStatus]);
  const { fetchReportsStatus } = useReportsActions();
  const { extractId, success } = useAlerts();
  const refetchReportsStatus = useCallback(() => extractId(fetchReportsStatus()), [extractId, fetchReportsStatus]);

  const items = useMemo(
    () =>
      success
        ? dropDownItems.map(({ id, name }) => ({
            id,
            name: typeof success[id] === 'number' ? `${name} (${success[id]})` : name,
          }))
        : dropDownItems,
    [success]
  );

  useEffect(() => {
    refetchReportsStatus();
  }, [refetchReportsStatus]);

  useEffect(() => {
    if (saveSuccess) refetchReportsStatus();
  }, [refetchReportsStatus, saveSuccess]);

  useEffect(() => {
    if (archiveSuccess) refetchReportsStatus();
  }, [archiveSuccess, refetchReportsStatus]);

  return (
    <Container>
      <Wrapper>
        <Checkbox
          selected={marked > 0}
          size={14}
          mr={0}
          mb={0}
          iconType={marked !== all ? 'minus' : 'darkCheck'}
          borderColor="var(--color-grey6A)"
        />
      </Wrapper>
      <Select value={checkboxStatus} onChange={handleOnSelect}>
        <option value={CUSTOM} label="Benutzerauswahl" disabled>
          Benutzerauswahl
        </option>
        {items.map(({ id, name }) => (
          <option key={id} value={id} label={name}>
            {name}
          </option>
        ))}
      </Select>
    </Container>
  );
};

DropDown.propTypes = {
  marked: PropTypes.number.isRequired,
  all: PropTypes.number.isRequired,
  checkboxStatus: PropTypes.string.isRequired,
  setCheckboxStatus: PropTypes.func.isRequired,
  saveSuccess: PropTypes.bool.isRequired,
  archiveSuccess: PropTypes.bool.isRequired,
};

export default DropDown;
