import React, { useState, useMemo, useCallback } from 'react';
import { last, path } from 'ramda';
import PropTypes from 'prop-types';

import Portal, { IMAGES_REVIEW } from 'components/Portal';

import { useLoadImage } from './hooks';
import { BRIGHTNESS, CONTRAST, SCALE } from './consts';
import Panel from './Panel';
import Content from './Content';
import List from './List';
import { Container, Wrapper } from './styles';

const ImagesReview = ({ images, currentImage, onClose, withCompare }) => {
  const [filters, setFilters] = useState({});
  const [focused, setFocused] = useState(currentImage);
  const { loading, list, error } = useLoadImage(images);
  const [selected, setSelected] = useState(currentImage ? [currentImage] : []);
  const compareList = useMemo(
    () => selected.map((imageId) => list.find(({ id }) => id === imageId)).filter(Boolean),
    [selected, list]
  );

  const selectImage = useCallback((values) => {
    setSelected(values);
    setFocused(last(values));
  }, []);
  const deleteSelectImage = useCallback(
    (id) => {
      const result = selected.filter((value) => value !== id);
      setSelected(result);
      setFocused(last(result));
    },
    [selected]
  );

  const updateFilter = useCallback(
    (field, value) => {
      setFilters((values) => ({
        ...values,
        [focused]: {
          ...values[focused],
          [field]: value,
        },
      }));
    },
    [focused]
  );

  return (
    <Portal type={IMAGES_REVIEW}>
      <Container>
        <Wrapper>
          <Panel
            brightness={path([focused, BRIGHTNESS], filters)}
            contrast={path([focused, CONTRAST], filters)}
            scale={path([focused, SCALE], filters)}
            updateFilter={updateFilter}
            onClose={onClose}
            disabled={loading || !focused}
          />
          <Content
            loading={loading}
            fullList={list}
            compareList={compareList}
            currentId={focused}
            deleteItem={deleteSelectImage}
            setFocused={setFocused}
            selectImage={selectImage}
            updateFilter={updateFilter}
            filters={filters}
            error={error}
          />
          <List loading={loading} list={list} selected={selected} selectImage={selectImage} withCompare={withCompare} />
        </Wrapper>
      </Container>
    </Portal>
  );
};

ImagesReview.defaultProps = {
  withCompare: false,
  currentImage: null,
};

ImagesReview.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      description: PropTypes.string,
      mimetype: PropTypes.string.isRequired,
      originalname: PropTypes.string.isRequired,
      date: PropTypes.string,
      isCurrent: PropTypes.bool,
    })
  ).isRequired,
  currentImage: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  withCompare: PropTypes.bool,
};

export default ImagesReview;
