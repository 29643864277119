import React, { useMemo } from 'react';
import { tail } from 'ramda';
import PropTypes from 'prop-types';

import { CORRECTION_ITEMS, BEST_CORRECTION, BEST_CORRECTION_ITEMS } from 'store/examinations';

import { Container, P, Select, TextInput, ErrorMessage } from './styles';

const Input = ({ field, label, error, withDropDown, width, setValue, readMode, isRed, ...rest }) => {
  const values = useMemo(() => (field.name.includes(BEST_CORRECTION) ? BEST_CORRECTION_ITEMS : CORRECTION_ITEMS), [field.name]);
  const isDropDown = useMemo(
    () => withDropDown && (!field.value || values.map((item) => item.id).includes(field.value)),
    [withDropDown, field.value, values]
  );
  const options = useMemo(() => (field.value ? values : tail(values)), [field.value, values]);
  const inputValue = useMemo(() => {
    const valString = field.value || field.value === 0 ? String(field.value) : '';

    if (readMode) return valString || '–';

    return valString;
  }, [field.value, readMode]);

  return (
    <Container width={width}>
      <P>{label}</P>
      {isDropDown ? (
        <Select value={field.value || ''} options={options} onSelect={setValue} error={error} readMode={readMode} {...rest} />
      ) : (
        <TextInput
          name={field.name}
          value={inputValue}
          onChange={field.onChange}
          error={Boolean(error)}
          mb={0}
          readMode={readMode}
          $isRed={isRed}
          {...rest}
        />
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

Input.defaultProps = {
  error: null,
  label: null,
  withDropDown: false,
  width: 'auto',
  setValue: () => null,
  readMode: false,
  isRed: false,
};

Input.propTypes = {
  label: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  error: PropTypes.string,
  withDropDown: PropTypes.bool,
  width: PropTypes.string,
  setValue: PropTypes.func,
  readMode: PropTypes.bool,
  isRed: PropTypes.bool,
};

export default Input;
