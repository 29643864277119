import React, { useMemo, useCallback } from 'react';
import { findIndex, propEq } from 'ramda';
import PropTypes from 'prop-types';

import Image from '../Image';

import Left from './LeftArrow';
import Right from './RightArrow';
import { BtnWrap, Button } from './styles';

const Slider = ({ list, currentId, filters, selectImage, updateFilter }) => {
  const currentIndex = useMemo(() => findIndex(propEq(currentId, 'id'), list), [currentId, list]);

  const handleSelect = useCallback(
    ({ currentTarget }) => {
      const { id } = currentTarget.dataset;

      if (id === 'prev') selectImage([list[currentIndex - 1].id]);
      if (id === 'next') selectImage([list[currentIndex + 1].id]);
    },
    [selectImage, list, currentIndex]
  );

  return (
    <>
      <BtnWrap>
        <Button type="button" data-id="prev" disabled={currentIndex === 0} onClick={handleSelect}>
          <Left />
        </Button>
      </BtnWrap>
      {list.map(
        ({ id, blobUrl }) => currentId === id && <Image key={id} src={blobUrl} filter={filters[id]} updateFilter={updateFilter} />
      )}
      <BtnWrap>
        <Button type="button" data-id="next" disabled={currentIndex === list.length - 1} onClick={handleSelect}>
          <Right />
        </Button>
      </BtnWrap>
    </>
  );
};

Slider.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      blobUrl: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  currentId: PropTypes.string.isRequired,
  filters: PropTypes.objectOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array])))
    .isRequired,
  selectImage: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default Slider;
