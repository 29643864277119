export const URL = process.env.REACT_APP_URL || 'http://localhost:8089';
export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8089/api';
export const MESSENGER_URL = process.env.REACT_APP_MESSENGER_URL || 'http://localhost:8096';
export const BUGSNAG_API_KEY = '4f431adf3322965920d36185191a2340';
export const DEFAULT_ERROR = 'Oops, ein Fehler ist aufgetreten.';

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const PATCH = 'PATCH';
export const DELETE = 'DELETE';

export const TOKEN = 'token';
export const TOKEN_2FA = 'token2FA';

export const ID = '_id';
export const DATE = 'date';
export const FILE = 'file';
export const STATUS = 'status';
export const ALL = 'all';

export const EXPAND = 'expand[]';
export const FIELDS = 'fields[]';

export const META = 'meta';
export const OWNER = 'owner';
export const EDITOR = 'editor';
export const CREATED = 'created';
export const MODIFIED = 'modified';

export const SEARCH = 'search';

export const SORT = 'sort';
export const SORT_BY = 'sortBy';
export const SORT_DIR = 'sortDir';
export const ASC = 'asc';
export const DESC = 'desc';

export const PAGINATION = 'pagination';
export const CURSOR = 'start_after';
export const NEXT = 'startAfter';
export const LIMIT = 'limit';
export const COUNT = 'count';
export const HAS_MORE = 'hasMore';

export const SUB_COMPANY = '_company';
export const SUB_ORGANIZATION = '_organization';
export const SUB_OPTICIAN = '_optician';
export const SUB_PATIENT = '_patient';
export const SUB_EXAMINATION = '_examination';
export const SUB_DIAGNOSES_CODES_EXTERN = '_diagnosisCodes';
export const SUB_DIAGNOSES_CODES_INTERN = '_privateDiagnosisCodes';
export const SUB_USER = '_user';
export const SUB_INSURANCE = '_insurance';
export const SUB_SERVICE = '_service';
export const SUB_SENDER = '_sender';
export const SUB_COPY = '_copy';
export const SUB_SCHEDULE = '_schedule';
export const SUB_PHYSICIAN = '_physician';
export const ORGANIZATION_ID = 'organizationId';

export const OWN_ERR_HANDLER = Symbol('ownErrHandler');
