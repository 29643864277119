import styled from 'styled-components';

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 90%;
  max-width: 990px;
  height: calc(100vh - 16px);
`;

export const Title = styled.h1`
  font-family: 'IBM Plex Sans', sans-serif;
  color: #6a6a6a;
  font-size: 60px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.5;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 40px;
`;

export const Text = styled.p`
  color: #6a6a6a;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 80px;
  font-size: 30px;
`;

export const Restart = styled.p`
  color: #6a6a6a;
  font-weight: 500;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 30px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 30px;
`;
