import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { pipe, prop } from 'ramda';

import { useHomePage } from 'permissions';
import { EMAIL, PASSWORD, useLoggedIn, useSessionActions, useAlerts } from 'store/session';
import { useAsyncState } from 'utils/useAsyncState';
import TextInput from 'components/TextInputNew';
import Button from 'components/Button';
import Message from 'components/Message';
import Spinner from 'components/FullScreenSpinner';

import { validationSchema } from './validation';
import { Container, Title, FormContainer, MultiInput, Msg } from './styles';

const ExternLogin = () => {
  const { push, location } = useHistory();
  const [email, setEmail] = useAsyncState('');
  const [err, setErr] = useState('');
  const homePage = useHomePage();
  const isLoggedIn = useLoggedIn();
  const { requestExternCode, login, getExternEmail } = useSessionActions();
  const { extractId, loading, success, error, resetAlerts } = useAlerts();
  const { extractId: emailExtractId, loading: emailLoading, success: emailSuccess, error: emailErr } = useAlerts();

  useEffect(() => {
    if (isLoggedIn) {
      push(homePage);
    }
  }, [push, homePage, isLoggedIn]);

  useEffect(() => {
    if (!location.search) return;

    emailExtractId(getExternEmail(location.search));
  }, [emailExtractId, location.search, getExternEmail]);

  useEffect(() => {
    if (emailSuccess && validationSchema.isValidSync(emailSuccess)) setEmail(emailSuccess);
  }, [emailSuccess, setEmail]);

  const handleChange = useCallback(
    (e) => {
      if (success || loading) return;
      if (error) {
        resetAlerts();
      }
      if (err) {
        setErr('');
      }
      setEmail(e.target.value);
    },
    [err, error, loading, resetAlerts, success, setEmail]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      validationSchema
        .validate(email)
        .then((data) => extractId(requestExternCode({ [EMAIL]: data })))
        .catch(pipe(prop('message'), setErr));
    },
    [email, extractId, requestExternCode]
  );

  const loginExtern = useCallback((code) => login({ [EMAIL]: email, [PASSWORD]: code }), [email, login]);

  return (
    <Container>
      <Title>Augenmobil für Partnerinstitutionen</Title>
      <FormContainer onSubmit={handleSubmit}>
        {success ? (
          <>
            <MultiInput validateAction={loginExtern} />
            <Msg>
              Wir haben Ihnen eben einen Zugangscode an Ihre E-Mail-Adresse gesendet. Bitte überprüfen Sie Ihren Posteingang.
            </Msg>
          </>
        ) : (
          <>
            <TextInput
              value={email}
              onChange={handleChange}
              type="email"
              placeholder="E-Mail"
              error={err}
              disabled={loading || success || emailLoading}
            />
            <Button type="submit" disabled={loading || success || emailLoading} mt={24} height="50px">
              {loading || emailLoading ? <Spinner size={40} height="40px" /> : 'Anmelden'}
            </Button>
            {(error || emailErr) && <Message type="error">{error || emailErr}</Message>}
          </>
        )}
      </FormContainer>
    </Container>
  );
};

export default ExternLogin;
