import React, { useRef, useMemo, useCallback } from 'react';
import { pipe, props, map, propSatisfies } from 'ramda';
import PropTypes from 'prop-types';

import { parseDate, parseTime, validateDate, validateTime } from 'utils';

import { Container, Placeholder, PlaceholderText, Input, Divider } from './styles';

const TimeInput = ({ value, onChange, error, onError, className, disabled, compareError }) => {
  const minuteRef = useRef();
  const [day = 0, month = 0, year = 0] = useMemo(() => (value ? parseDate(value).split('.').map(Number) : []), [value]);
  const [hour = '', minute = ''] = useMemo(() => parseTime(value).split(':'), [value]);

  const onFocus = useCallback((e) => e.currentTarget.select(), []);
  const onBlur = useCallback((e) => {
    const val = e.currentTarget.value;
    if (val.length === 0) e.currentTarget.value = '00';
    if (val.length === 1) e.currentTarget.value = `0${val}`;
  }, []);

  const onChangeHour = useCallback((e) => {
    const val = e.currentTarget.value.replace(/\D/, '').slice(-2);
    e.currentTarget.value = val;

    if (val.length === 2) minuteRef.current?.focus();
  }, []);
  const onChangeMinute = useCallback((e) => {
    const val = e.currentTarget.value.replace(/\D/, '').slice(-2);
    e.currentTarget.value = val;
  }, []);

  const onChangeTime = useCallback(
    ({ currentTarget }) => {
      if (error) onError('');

      const [h, m] = pipe(props(['hour', 'minute']), map(propSatisfies(Number, 'value')))(currentTarget);

      if (validateDate(day, month, year) && validateTime(h, m)) {
        onChange(new Date(year, month - 1, day, h, m).toISOString());
      } else {
        onError('Ungültige Zeit');
      }
    },
    [day, error, month, onChange, onError, year]
  );

  return (
    <Container onChange={onChangeTime} error={Boolean(error)} className={className}>
      <Placeholder>
        <PlaceholderText compareError={compareError}>HH</PlaceholderText>
        <PlaceholderText compareError={compareError}>mm</PlaceholderText>
      </Placeholder>
      <Input
        name="hour"
        placeholder="--"
        defaultValue={hour}
        onChange={onChangeHour}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
      />
      <Divider />
      <Input
        ref={minuteRef}
        name="minute"
        placeholder="--"
        defaultValue={minute}
        onChange={onChangeMinute}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
      />
    </Container>
  );
};

TimeInput.defaultProps = {
  value: '',
  onChange: () => null,
  error: '',
  onError: () => null,
  className: '',
  disabled: false,
};
TimeInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  onError: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  compareError: PropTypes.bool.isRequired,
};

export default TimeInput;
