import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { values, props } from 'ramda';

import { useAlerts } from 'store/alerts/hooks';
import { wrapActions, wrapSelectorWithArg } from 'store/utils';
import { SORT, PAGINATION, LIMIT, CURSOR, SEARCH, STATUS, ALL } from 'api/consts';

import { getReports, getReport, getReportsFilters } from './selectors';
import * as actions from './actions';

export const useReportsActions = wrapActions({ ...actions });

export const useReport = wrapSelectorWithArg(getReport);

export const useInitReports = (hasAccess) => {
  const alerts = useAlerts();
  const data = useSelector(getReports);
  const list = useMemo(() => values(data), [data]);
  const filters = useSelector(getReportsFilters);
  const [status, search, sort, pagination] = props([STATUS, SEARCH, SORT, PAGINATION], filters);
  const [limit, cursor] = props([LIMIT, CURSOR], pagination);

  const { extractId } = alerts;
  const { resetReports, fetchReports } = useReportsActions();

  useEffect(() => {
    if (!hasAccess) return;
    extractId(
      fetchReports({
        ...(status !== ALL && { [STATUS]: status }),
        [SEARCH]: search,
        [LIMIT]: limit,
        [CURSOR]: cursor,
        ...sort,
      })
    );
  }, [extractId, fetchReports, hasAccess, status, search, sort, limit, cursor]);

  useEffect(() => {
    resetReports();
  }, [resetReports]);

  return {
    list,
    ...alerts,
    status,
    search,
    sort,
    pagination,
  };
};
