import React, { useRef, useMemo, useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { props, remove, adjust } from 'ramda';
import PropTypes from 'prop-types';

import {
  useInvoicesActions,
  useAlerts,
  ID,
  STATUS,
  SERVICES,
  SUB_SERVICE,
  QUANTITY,
  SESSION,
  DATE,
  DURATION_IN_MINUTES,
  INVOICE_STATUS,
} from 'store/invoices';
import { useValidationTarmeds } from 'containers/InvoicesForm/hooks';
import Table from 'containers/InvoicesForm/Table';
import Button from 'components/Button';

import PdfSection from './PdfSection';
import DateTime from './DateTime';
import SearchList from './Search';
import { Section, BaseTarmeds, Warning, Span } from './styles';

const Form = ({ data, updateData, readMode }) => {
  const init = useRef(true);
  const { id } = useParams();
  const [invoiceId, invoiceStatus, invoiceServices] = useMemo(() => props([ID, STATUS, SERVICES], data), [data]);
  const [services, setServices] = useState(() => invoiceServices || []);
  const [date, setDate] = useState(() => new Date());
  const [comparedTarmeds, validationErrors, errorMessages] = useValidationTarmeds(services, date);
  const [dataError, setDataError] = useState(false);
  const { savePatientInvoice, sendPatientInvoice } = useInvoicesActions();
  const { action, loading, success, resetAlerts } = useAlerts(sendPatientInvoice);

  const setBaseTarmeds = useCallback(
    (baseTarmeds = []) => {
      setServices(
        baseTarmeds.map((item = {}) => ({
          [SUB_SERVICE]: item[ID],
          [QUANTITY]: 1,
          [SESSION]: 1,
          [DATE]: date.toISOString(),
        }))
      );
    },
    [date]
  );
  const onAddService = useCallback(
    (serviceId) =>
      setServices(($) => [...$, { [SUB_SERVICE]: serviceId, [QUANTITY]: 1, [SESSION]: 1, [DATE]: date.toISOString() }]),
    [date]
  );
  const onRemoveService = useCallback((index) => setServices(($) => remove(index, 1, $)), []);
  const onChangeQuantity = useCallback(
    (index, value) => setServices(($) => adjust(index, (item) => ({ ...item, [QUANTITY]: value }), $)),
    []
  );
  const onChangeDate = useCallback(
    (index, value) => setServices(($) => adjust(index, (item) => ({ ...item, [DATE]: value }), $)),
    []
  );
  const onChangeSession = useCallback(
    (index, value) => setServices(($) => adjust(index, (item) => ({ ...item, [SESSION]: value }), $)),
    []
  );
  const onChangeDuration = useCallback(
    (index, value) => setServices(($) => adjust(index, (item) => ({ ...item, [DURATION_IN_MINUTES]: value }), $)),
    []
  );
  const onSendInvoice = useCallback(() => action(invoiceId), [action, invoiceId]);

  useEffect(() => {
    if (init.current) {
      init.current = false;
    } else {
      savePatientInvoice({ [ID]: invoiceId, [SERVICES]: services });
    }
  }, [invoiceId, savePatientInvoice, services]);

  useEffect(() => {
    if (success) {
      updateData(success);
      resetAlerts();
    }
  }, [resetAlerts, success, updateData]);

  if (readMode || invoiceStatus > INVOICE_STATUS.IN_PREPARATION) {
    return (
      <>
        {invoiceStatus >= INVOICE_STATUS.SENT && invoiceStatus <= INVOICE_STATUS.PAID && (
          <Section $title="Rechnung als PDF herunterladen">
            <PdfSection id={invoiceId} />
          </Section>
        )}
        <Section>
          <Table title="Erfasste Tarmed-Positionen" list={comparedTarmeds} readMode isSelected />
        </Section>
        <Section $title="Rechnung">
          <Button type="button" onClick={onSendInvoice} color="success" disabled>
            Rechnung senden
          </Button>
        </Section>
      </>
    );
  }

  return (
    <>
      <Section $title="Rechnung">
        <DateTime value={date} onChange={setDate} readMode={readMode} />
        <BaseTarmeds patientId={id} setBaseTarmeds={setBaseTarmeds} />
      </Section>
      <Section $title="Suche">
        <SearchList onAddService={onAddService} />
      </Section>
      <Section>
        {errorMessages.length > 0 && (
          <Warning>
            {errorMessages.map((text) => (
              <Span key={text}>{text}</Span>
            ))}
          </Warning>
        )}
        <Table
          title="Erfasste Tarmed-Positionen"
          list={comparedTarmeds}
          action={onRemoveService}
          onChangeQuantity={onChangeQuantity}
          onChangeDate={onChangeDate}
          onChangeSession={onChangeSession}
          onChangeDuration={onChangeDuration}
          setDataError={setDataError}
          validationErrors={validationErrors}
          isSelected
        />
      </Section>
      <Section $title="Rechnung">
        <Button
          type="button"
          onClick={onSendInvoice}
          disabled={readMode || dataError || errorMessages.length > 0 || loading}
          color="success"
        >
          Rechnung senden
        </Button>
      </Section>
    </>
  );
};

Form.propTypes = {
  data: PropTypes.shape({}).isRequired,
  updateData: PropTypes.func.isRequired,
  readMode: PropTypes.bool.isRequired,
};

export default Form;
