/* eslint-disable no-console */
import { put, cancelled, select } from 'redux-saga/effects';
import { createAction } from 'redux-actions';
import { propOr, path, applySpec, pipe, map, prop, filter, head, not, isEmpty, propEq } from 'ramda';

import dictionary from 'utils/dictionary.json';
import { setMainError, setAppError, resetErrors } from 'store/errors/actions';
import { getIsExistError } from 'store/errors/selectors';

import { SUCCESS, ERROR, LOADING, META, TASK_ID } from './consts';
import { setLoading, setSuccess, setError, setErrors, setCancel } from './actions';

const { defaultError } = dictionary;

export const withAlert = (saga) =>
  function* wrapper(action) {
    const id = path([META, TASK_ID], action);

    yield put(setLoading(id));

    try {
      const data = yield saga(action);
      const success = prop('success', data);
      const error = prop('error', data);
      const errors = prop('errors', data);
      const isExistError = yield select(getIsExistError);

      if (error && process.env.NODE_ENV === 'development') {
        console.error('sagaHandlerError: ', action, error);
      }

      if (isExistError) {
        yield put(resetErrors());
      }

      if (errors) {
        yield put(setErrors({ id, errors }));
      }

      if (error) {
        yield put(
          setError({
            id,
            error,
          })
        );
      }
      if (!(error || errors)) {
        yield put(setSuccess({ id, success }));
      }
    } catch (error) {
      if (error && process.env.NODE_ENV === 'development') {
        console.error('sagaHandlerError: ', action, error);
      }

      if (propEq('main', 'type', error || {})) {
        yield pipe(prop('message'), setMainError, put)(error);
      }

      if (propEq('app', 'type', error || {})) {
        yield pipe(prop('message'), setAppError, put)(error);
      }

      yield put(
        setError({
          id,
          error: propOr(defaultError, 'message')(error),
          ...(error?.errors && { errors: error.errors }),
        })
      );
    } finally {
      if (yield cancelled()) {
        yield put(setCancel(id));
      }
    }
  };

let counter = 0;
export const createAlertAction = (type) =>
  createAction(
    type,
    ($) => $,
    () => {
      counter += 1;
      return { [TASK_ID]: counter };
    }
  );

export const mergeAlerts = (alerts) =>
  applySpec({
    [ERROR]: pipe(map(prop(ERROR)), filter(Boolean), head),
    [SUCCESS]: pipe(map(prop(SUCCESS)), filter(not), isEmpty),
    [LOADING]: pipe(map(prop(LOADING)), filter(Boolean), head),
  })(alerts);
