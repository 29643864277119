import React, { useMemo, useCallback, useEffect } from 'react';
import { equals, applySpec, path, pathOr, inc } from 'ramda';

import {
  usePatientsActions,
  useAlerts,
  ID,
  FIELDS,
  LIMIT,
  PUBLIC_ID,
  FIRST_NAME,
  LAST_NAME,
  BIRTH,
  FROM,
  TO,
} from 'store/patients';
import { useAsyncState } from 'utils/useAsyncState';
import { parseBirthday } from 'utils';
import QuestionContainer from 'components/QuestionContainer';
import PaginationDropDown from 'components/PaginationDropDown';
import Button from 'components/Button';
import Message from 'components/Message';

import { Wrapper, Label, Text } from './styles';

const Duplicates = () => {
  const [values, setValues] = useAsyncState({ [FROM]: null, [TO]: null });
  const [renderKey, setRenderKey] = useAsyncState(0);
  const isValid = useMemo(() => values[FROM] && values[TO] && !equals(values[FROM], values[TO]), [values]);
  const { mergePatientRecords, searchPatients } = usePatientsActions();
  const { extractId, loading, success, error, resetAlerts } = useAlerts();

  const setSearch = useCallback(
    (args) =>
      searchPatients({
        [FIELDS]: [PUBLIC_ID, FIRST_NAME, LAST_NAME, BIRTH],
        [LIMIT]: 10,
        ...args,
      }),
    [searchPatients]
  );

  const handleChangeFrom = useCallback(
    (data) => {
      resetAlerts();

      setValues(($) => ({ ...$, [FROM]: data }));
    },
    [setValues, resetAlerts]
  );
  const handleChangeTo = useCallback(
    (data) => {
      resetAlerts();

      setValues(($) => ({ ...$, [TO]: data }));
    },
    [setValues, resetAlerts]
  );

  const handleSubmit = useCallback(() => {
    extractId(
      mergePatientRecords(
        applySpec({
          [FROM]: path([FROM, ID]),
          [TO]: path([TO, ID]),
        })(values)
      )
    );
  }, [extractId, mergePatientRecords, values]);

  const disabled = Boolean(loading || success);
  const getName = useCallback(
    (item) => `${item[FIRST_NAME]} ${item[LAST_NAME]}, ${parseBirthday(item[BIRTH])} (${item[PUBLIC_ID]})`,
    []
  );

  useEffect(() => {
    if (!success) return () => null;

    const timer = setTimeout(() => {
      resetAlerts();
      setValues({ [FROM]: null, [TO]: null });
      setRenderKey(inc);
    }, 3000);

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [resetAlerts, setRenderKey, setValues, success]);

  return (
    <QuestionContainer label="Patientendossiers zusammenlegen">
      <Wrapper>
        <Label>Patienten-ID von</Label>
        <PaginationDropDown
          key={renderKey}
          idKey={ID}
          nameKey={getName}
          action={setSearch}
          placeholder="Suchen"
          value={values[FROM]}
          onChange={handleChangeFrom}
          disabled={disabled}
          onlyOne
        />
      </Wrapper>
      <Wrapper>
        <Label>Patienten-ID nach</Label>
        <PaginationDropDown
          key={renderKey}
          idKey={ID}
          nameKey={getName}
          action={setSearch}
          placeholder="Suchen"
          value={values[TO]}
          onChange={handleChangeTo}
          disabled={disabled}
          onlyOne
        />
      </Wrapper>
      <Text $isOpen={isValid}>
        Das Patientendossier <span>{pathOr('-', [FROM, PUBLIC_ID])(values)}</span> wird mit dem Patientendossier{' '}
        <span>{pathOr('-', [TO, PUBLIC_ID])(values)}</span> zusammengelegt. Sämtliche Untersuchungen, Berichte, Rechnungen etc.
        erscheinen neu unter der Patienten-ID <span>{pathOr('-', [TO, PUBLIC_ID])(values)}</span>. Der Patient mit der ID{' '}
        <span>{pathOr('-', [FROM, PUBLIC_ID])(values)}</span> wird gelöscht.
      </Text>
      <Button type="button" onClick={handleSubmit} disabled={disabled || !isValid} color="success">
        Dossiers zusammenlegen
      </Button>
      {error && <Message type="error">{error}</Message>}
      {success && <Message type="success">{success}</Message>}
    </QuestionContainer>
  );
};

export default Duplicates;
