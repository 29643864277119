import styled from 'styled-components';

export default styled.button`
  padding: 10px;
  min-width: 150px;
  background-color: ${({ isActive }) => (isActive ? 'var(--color-white)' : 'var(--color-greyF8)')};
  border: ${({ isActive }) => (isActive ? '1px solid var(--color-grey6A)' : 'none')};
  color: var(--color-grey6A);
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.92px;
  border-radius: var(--border-radius8);
  text-align: center;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: ${({ isActive }) => (isActive ? 'var(--color-white)' : 'var(--color-greyEC)')};
  }
`;
