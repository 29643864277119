import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import TextInput from 'components/TextInputNew';
import Table from 'components/Table';
import {
  useExaminationsActions,
  useFilteredExaminations,
  CURSOR,
  LIMIT,
  COUNT,
  HAS_MORE,
  EXAMINATION_STATUS,
} from 'store/examinations';
import { EXAMINATION, usePermissions } from 'permissions';

import { getTableHead, handleExaminations, tableTitles } from './utils';
import Row from './Row';
import { Label } from './styles';

const Section = ({ status }) => {
  const can = usePermissions();
  const { updateSearch, updateSort, updatePagination } = useExaminationsActions();
  const { list, pagination, search, sort, loading } = useFilteredExaminations(can.read(EXAMINATION), status);
  const examinations = useMemo(() => handleExaminations(list), [list]);
  const renderRow = useCallback((data) => <Row {...data} />, []);

  const updateSearchList = useCallback((value) => updateSearch({ kind: status, value }), [updateSearch, status]);
  const updateSortList = useCallback((value) => updateSort({ kind: status, value }), [updateSort, status]);
  const updatePaginationList = useCallback((value) => updatePagination({ kind: status, value }), [updatePagination, status]);

  const isInPreparation = status === EXAMINATION_STATUS.IN_PREPARATION;

  const searchProps = useMemo(
    () => ({
      type: 'search',
      placeholder: 'Patient suchen',
      value: search,
      handleChangeText: updateSearchList,
    }),
    [search, updateSearchList]
  );
  const tableProps = useMemo(
    () => ({
      head: getTableHead(!isInPreparation),
      list: examinations,
      renderRow,
      sort,
      cursor: pagination[CURSOR],
      count: pagination[COUNT],
      limit: pagination[LIMIT],
      hasMore: pagination[HAS_MORE],
      updateSort: updateSortList,
      updatePagination: updatePaginationList,
      loading,
    }),
    [examinations, loading, pagination, renderRow, sort, updatePaginationList, updateSortList, isInPreparation]
  );

  return isInPreparation ? (
    <>
      <TextInput {...searchProps} mb={70} />
      <Table title={tableTitles[status]} {...tableProps} />
    </>
  ) : (
    <>
      <Label>{tableTitles[status]}</Label>
      <TextInput {...searchProps} mb={30} />
      <Table {...tableProps} />
    </>
  );
};

Section.propTypes = {
  status: PropTypes.number.isRequired,
};

export default Section;
