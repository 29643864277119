import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { usePlanningActions, useAlerts } from 'store/planning';
import Download from 'assets/svg-icons/Download';
import Spinner from 'components/FullScreenSpinner';

import { Container, Item, Label, Button, Wrapper } from './styles';

const Downloads = ({ id, hasRegistrations }) => {
  const { exportSchedule, getScheduleLabels, getScheduleCoverLetters, getScheduleConsents } = usePlanningActions();
  const { action: getExport, loading: exportLoading, error: exportErr } = useAlerts(exportSchedule);
  const { action: getLabels, loading: labelsLoading, error: labelsErr } = useAlerts(getScheduleLabels);
  const { action: getCoverLetters, loading: coverLettersLoading, error: coverLettersErr } = useAlerts(getScheduleCoverLetters);
  const { action: getConsents, loading: consentsLoading, error: consentsErr } = useAlerts(getScheduleConsents);

  const onDownloadSchedule = useCallback(() => getExport(id), [getExport, id]);
  const onDownloadLabels = useCallback(() => getLabels(id), [getLabels, id]);
  const onDownloadCoverLetters = useCallback(() => getCoverLetters(id), [getCoverLetters, id]);
  const onDownloadConsents = useCallback(() => getConsents(id), [getConsents, id]);

  return (
    <Container>
      <Item>
        <Label>Begleitbrief (DOCX)</Label>
        <Button onClick={onDownloadCoverLetters} disabled={coverLettersLoading} title="Download DOCX">
          {coverLettersLoading ? (
            <Spinner size={46} height="100%" />
          ) : (
            <Wrapper $error={coverLettersErr}>
              <Download />
            </Wrapper>
          )}
        </Button>
      </Item>
      <Item>
        <Label>Einverständniserklärung (DOCX)</Label>
        <Button onClick={onDownloadConsents} disabled={consentsLoading} title="Download DOCX">
          {consentsLoading ? (
            <Spinner size={46} height="100%" />
          ) : (
            <Wrapper $error={consentsErr}>
              <Download />
            </Wrapper>
          )}
        </Button>
      </Item>
      <Item>
        <Label>Patienten-Etiketten (PDF)</Label>
        <Button onClick={onDownloadLabels} disabled={labelsLoading || !hasRegistrations} title="Download PDF">
          {labelsLoading ? (
            <Spinner size={46} height="100%" />
          ) : (
            <Wrapper $error={labelsErr} $gray={!hasRegistrations}>
              <Download />
            </Wrapper>
          )}
        </Button>
      </Item>
      <Item>
        <Label>Terminplan (XLSX)</Label>
        <Button onClick={onDownloadSchedule} disabled={exportLoading || !hasRegistrations} title="Download XLS">
          {exportLoading ? (
            <Spinner size={46} height="100%" />
          ) : (
            <Wrapper $error={exportErr} $gray={!hasRegistrations}>
              <Download />
            </Wrapper>
          )}
        </Button>
      </Item>
    </Container>
  );
};

Downloads.propTypes = {
  id: PropTypes.string.isRequired,
  hasRegistrations: PropTypes.bool.isRequired,
};

export default Downloads;
