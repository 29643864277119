import React, { useState, useCallback, useMemo } from 'react';
import { not, without, is } from 'ramda';
import PropTypes from 'prop-types';

import Close from 'assets/svg-icons/SmallClose';
import { ORIGINAL_NAME, DESCRIPTION, PATH, TYPE, DESTINATION, PREVIEW_NAME, URL } from 'store/infoItems';
import Warn from 'components/WarnDeleting';
import ImagesReview from 'components/ImagesReview';

import { Container, HeadWrap, MenuBtn, Preview, Text, EditName, ButtonWrap, Button } from './styles';

const FileForm = ({ index, fileData, tags, updateFileData, updateTags, deleteFile, readMode, attachments }) => {
  const [isShowWarn, setIsShowWarn] = useState(false);
  const [imagePath, setImagePath] = useState();

  const handleTags = useCallback(
    (e) => {
      if (readMode) return;
      const { id } = e.currentTarget?.dataset || {};
      updateTags(index, tags.includes(id) ? without([id], tags) : [...tags, id]);
    },
    [updateTags, index, tags, readMode]
  );

  const updateFileName = useCallback(
    (e) => {
      updateFileData(index, {
        ...fileData,
        [DESCRIPTION]: e.target.value,
      });
    },
    [updateFileData, index, fileData]
  );

  const handleShowWarn = useCallback(() => setIsShowWarn(not), []);
  const handleDelete = useCallback(() => deleteFile(index), [deleteFile, index]);
  const handleClickPreview = useCallback(() => {
    if (fileData[TYPE].includes('image')) {
      setImagePath(fileData[PATH]);
    } else {
      window.open(`${URL}/${fileData[PATH]}`, '_blank');
    }
  }, [fileData]);
  const handleClosePreview = useCallback(() => setImagePath(), []);

  const fileName = useMemo(
    () => (is(String, fileData[DESCRIPTION]) ? fileData[DESCRIPTION] : fileData[ORIGINAL_NAME]),
    [fileData]
  );

  return (
    <Container>
      {readMode || (
        <HeadWrap>
          <MenuBtn type="button" title="Löschen Sie" onClick={handleShowWarn}>
            <Close />
          </MenuBtn>
        </HeadWrap>
      )}
      <Preview src={`${URL}/${fileData[DESTINATION]}/${fileData[PREVIEW_NAME]}`} onClick={handleClickPreview} />
      {readMode ? <Text>{fileName}</Text> : <EditName value={fileName} onChange={updateFileName} />}
      <ButtonWrap>
        <Button
          type="button"
          data-id="Einverständiserklärung"
          color={tags.includes('Einverständiserklärung') ? 'success' : 'default'}
          onClick={handleTags}
          disabled={readMode}
        >
          Einverständiserklärung
        </Button>
        <Button
          type="button"
          data-id="Externer Bericht"
          color={tags.includes('Externer Bericht') ? 'success' : 'default'}
          onClick={handleTags}
          disabled={readMode}
        >
          Externer Bericht
        </Button>
      </ButtonWrap>
      {isShowWarn && (
        <Warn
          message="Wollen Sie die angehängte Datei wirklich löschen?"
          showActon={handleShowWarn}
          deleteAction={handleDelete}
        />
      )}
      {imagePath && <ImagesReview images={attachments} currentImage={imagePath} onClose={handleClosePreview} />}
    </Container>
  );
};

FileForm.defaultProps = {
  tags: [],
  readMode: false,
  deleteFile: () => null,
  attachments: [],
};

FileForm.propTypes = {
  index: PropTypes.number.isRequired,
  fileData: PropTypes.shape({
    [ORIGINAL_NAME]: PropTypes.string.isRequired,
    [DESCRIPTION]: PropTypes.string,
    [PATH]: PropTypes.string.isRequired,
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  updateFileData: PropTypes.func.isRequired,
  updateTags: PropTypes.func.isRequired,
  deleteFile: PropTypes.func,
  readMode: PropTypes.bool,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      description: PropTypes.string,
      mimetype: PropTypes.string.isRequired,
    })
  ),
};

export default FileForm;
