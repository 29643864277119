import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container, Input, Label } from './styles';

const Radio = ({ name, value, label, checked, onChange, setValue, disabled, error }) => {
  const randomId = useMemo(() => `radio-${String(Math.random()).slice(2)}`, []);

  const handleChange = useCallback(
    (e) => {
      onChange(e);
      setValue(value);
    },
    [onChange, setValue, value]
  );

  return (
    <Container disabled={disabled}>
      <Input
        id={randomId}
        name={name}
        type="radio"
        value={value}
        onChange={handleChange}
        checked={checked}
        disabled={disabled}
        $error={error}
      />
      <Label htmlFor={randomId} $error={error}>
        {label}
      </Label>
    </Container>
  );
};

Radio.defaultProps = { name: undefined, error: null };
Radio.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default Radio;
