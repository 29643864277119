const maxDayInMonths = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const validateDate = ({ day, month, year, allowAllDays }) => {
  if (!(day && month && year)) return { message: 'Ungültiges Datum.', day: true, month: true, year: true };
  if (month < 1 || month > 12) return { message: 'Der Monat ist ungültig.', month: true };
  if (day < 1) return { message: 'Ungültiges Datum.', day: true };
  if ((day > 28 && month === 2 && year % 4 !== 0) || day > maxDayInMonths[month - 1])
    return { message: 'Ungültiges Datum.', day: true, month: true };

  if (allowAllDays) return {};

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const date = new Date(year, month - 1, day);

  if (date.valueOf() < today.valueOf())
    return { message: 'Das Datum darf nicht in der Vergangenheit liegen.', day: true, month: true, year: true };

  const weekDay = date.getDay();

  if (weekDay === 6 || weekDay === 0)
    return { message: 'Das gewählte Datum ist kein Wochentag.', day: true, month: true, year: true };

  return {};
};
