import qs from 'query-string';
import { prop } from 'ramda';

import { wrapperRequest } from './utils';
import { API_URL, POST, PATCH, DELETE, EXPAND, SUB_OPTICIAN, ID } from './consts';

export const postOrganization = (body = {}) =>
  wrapperRequest({
    url: `${API_URL}/organizations`,
    options: { method: POST, body },
  });

export const getOrganizations = (query = {}) =>
  wrapperRequest({
    url: `${API_URL}/organizations?${qs.stringify(query)}`,
  });

const defParams = { [EXPAND]: [SUB_OPTICIAN] };

export const getOrganization = (id, query = {}) =>
  wrapperRequest({
    url: `${API_URL}/organizations/${id}?${qs.stringify({ ...defParams, ...query })}`,
  });

export const patchOrganization = (body = {}) =>
  wrapperRequest({
    url: `${API_URL}/organizations/${prop(ID, body)}?${qs.stringify(defParams)}`,
    options: { method: PATCH, body },
  });

export const postUploadResource = (organizationId, source) =>
  wrapperRequest({
    url: `${API_URL}/organizations/${organizationId}/submit`,
    options: { method: POST, body: { source } },
  });

export const deleteOrganization = (id) =>
  wrapperRequest({
    url: `${API_URL}/organizations/${id}`,
    options: { method: DELETE },
  });
