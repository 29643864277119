import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Text } from './styles';
import { getTimeToNow } from './utils';

const Time = ({ time }) => {
  const [state, setState] = useState(getTimeToNow(time));

  useEffect(() => {
    const timer = setInterval(() => {
      setState(getTimeToNow(time));
    }, 10000);

    return () => {
      clearInterval(timer);
    };
  }, [time]);

  return <Text>vor {state}</Text>;
};

Time.propTypes = {
  time: PropTypes.string.isRequired,
};

export default Time;
