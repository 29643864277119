import React, { useState, useCallback, useEffect } from 'react';
import { omit } from 'ramda';

import { useBugReportsActions, useAlerts, ID, CLIENT_ID, MEDI_URL, TOKEN } from 'store/bugReports';
import { useAsyncState } from 'utils/useAsyncState';
import Spinner from 'components/FullScreenSpinner';
import Button from 'components/Button';
import TextInput from 'components/TextInputNew';

import { validationObject } from './utils';
import { Wrapper, Label } from './styles';

const MedData = () => {
  const [isHidden, setHidden] = useState(true);
  const [values, setValues] = useAsyncState({});
  const [errors, setErrors] = useAsyncState({});
  const { fetchMediData, saveMediData } = useBugReportsActions();
  const { id: actionId, action: getData, loading: getLoading, success: getSuccess } = useAlerts(fetchMediData);
  const { action: save } = useAlerts(saveMediData);
  const id = values[ID];
  const saveData = useCallback(
    (d) => {
      if (id) save({ [ID]: id, ...d });
    },
    [id, save]
  );
  const onChange = useCallback(
    async ({ currentTarget }) => {
      const { name, value } = currentTarget;

      setValues(($) => ({ ...$, [name]: value }));

      try {
        await validationObject[name].validate(value);

        saveData({ [name]: value });
        setErrors(($) => ($[name] ? omit([name], $) : $));
      } catch ({ message }) {
        setErrors(($) => ({ ...$, [name]: message }));
      }
    },
    [saveData, setErrors, setValues]
  );
  const onClick = useCallback(() => setHidden(false), []);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (getSuccess) {
      setValues({
        [ID]: getSuccess[ID],
        [TOKEN]: getSuccess[TOKEN] || '',
        [CLIENT_ID]: getSuccess[CLIENT_ID] || '',
        [MEDI_URL]: getSuccess[MEDI_URL] || '',
      });
    }
  }, [getSuccess, setValues]);

  if (!actionId || getLoading) return <Spinner height="100px" />;

  return (
    <>
      {isHidden ? (
        <Wrapper>
          <Label>Autorisierungsheader</Label>
          <Button type="button" onClick={onClick} height="40px">
            Sicherheitsschlüssel ändern
          </Button>
        </Wrapper>
      ) : (
        <TextInput
          type="password"
          name={TOKEN}
          label="Autorisierungsheader"
          value={values[TOKEN]}
          onChange={onChange}
          error={errors[TOKEN]}
        />
      )}
      <TextInput name={CLIENT_ID} label="Client-ID" value={values[CLIENT_ID]} onChange={onChange} error={errors[CLIENT_ID]} />
      <TextInput name={MEDI_URL} label="Basis-URL" value={values[MEDI_URL]} onChange={onChange} error={errors[MEDI_URL]} />
    </>
  );
};

export default MedData;
