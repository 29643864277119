import styled from 'styled-components';

import { Button as Btn } from '../../styles';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 920px;
`;

export const SwitcherWrap = styled.div`
  display: flex;
  min-width: 240px;
`;

export const SwitcherText = styled.p`
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  letter-spacing: 0.92px;
  color: var(--color-grey97);
  margin-right: 6px;
  text-transform: uppercase;
  line-height: 40px;
`;

export const Button = styled(Btn)`
  margin-left: 10px;
  &:last-child {
    transform: rotate(180deg);
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export const MatchWrap = styled.div`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
`;

export const MatchText = styled.p`
  font-size: var(--font-size15);
  font-weight: var(--font-weight500);
  letter-spacing: 0.33px;
  color: var(--color-blue5D);
  margin: 10px 20px 0 0;
  min-width: 125px;
`;

export const PagesLinkWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const MatchLink = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  font-size: var(--font-size15);
  font-weight: var(--font-weight500);
  color: var(--color-blue5D);
  height: 40px;
  width: 40px;
  text-decoration: underline;
  transition: all 0.3s;
  &:hover {
    color: var(--color-blue8E);
  }
  &:active {
    color: var(--color-blue2E);
  }
  &:disabled {
    color: var(--color-grey97);
    text-decoration: none;
  }
`;

export const Message = styled.p`
  font-size: var(--font-size18);
  color: var(--color-grey6A);
  text-align: center;
`;
