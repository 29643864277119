/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Message from 'components/Message';

import { Container, Span } from './styles';

const XlsErrors = ({ error, errors, className }) => {
  if (!error) return null;

  return (
    <Container className={className}>
      <Message type="error" small={1} form={1}>
        {error}
      </Message>
      {errors?.length > 0 && (
        <Message type="error" small={1} form={1}>
          {errors.map(([row, messages]) => (
            <Fragment key={row}>
              <Span>{`Zeile ${row}: ${messages.join(' ')}`}</Span>
            </Fragment>
          ))}
        </Message>
      )}
    </Container>
  );
};

XlsErrors.defaultProps = {
  error: null,
  errors: null,
  className: null,
};
XlsErrors.propTypes = {
  error: PropTypes.string,
  errors: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.string.isRequired)])).isRequired
  ),
  className: PropTypes.string,
};

export default XlsErrors;
