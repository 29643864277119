/* eslint-disable react/prop-types */
import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { omit } from 'ramda';

import MultiAnswerInput from 'components/MultiAnswerInput';
import QuestionContainer from 'components/QuestionContainer';
import LabelText from 'components/LabelText';
import {
  VALUE,
  DESCRIPTION,
  LIST,
  DATE,
  ILLNESSES,
  ALLERGIES,
  SURGERY,
  HEART,
  UNDER_TREATMENT,
  EYE_DROPS,
  DRUGS,
  BLOOD_PRESSURE,
  DIABETES,
  ASTHMA,
  SMOKING,
  ALCOHOL,
  FILES,
  COMMENT,
  DEMENTIA,
  CARE_LEVEL,
} from 'store/medicalHistory';
import { readModeQuestions } from '../consts';

import Question from './Question';
import TextArea from './TextArea';
import Input from './Input';
import { getReadModeValue } from './utils';

const YesNoForm = ({ values, readMode }) => {
  const [{ value: eyeDropsList }, , { setValue: setEyeDropsList }] = useField(`${EYE_DROPS}.${LIST}`);
  const [{ value: drugsList }, , { setValue: setDrugsList }] = useField(`${DRUGS}.${LIST}`);

  if (readMode) {
    return (
      <QuestionContainer label="Anamnese">
        {Object.keys(omit([CARE_LEVEL, FILES, COMMENT], values)).map((key) => (
          <LabelText key={key} label={readModeQuestions[key]} text={getReadModeValue(values[key])} readonly />
        ))}
      </QuestionContainer>
    );
  }

  return (
    <>
      <Question field={DIABETES} label="Leiden Sie an der Zuckerkrankheit (Diabetes)?" />
      <Question field={DEMENTIA} label="Haben Sie eine Demenzerkrankung?" />
      <Question field={`${UNDER_TREATMENT}.${VALUE}`} label="Sind Sie zurzeit in augenärztlicher Behandlung?">
        <TextArea field={`${UNDER_TREATMENT}.${DESCRIPTION}`} label="Wenn ja weshalb?" />
      </Question>
      <Question field={`${SURGERY}.${VALUE}`} label="Sind Sie jemals an den Augen operiert worden?">
        <Input field={`${SURGERY}.${DATE}`} label="Wann ungefähr" />
        <TextArea field={`${SURGERY}.${DESCRIPTION}`} label="und weshalb?" />
      </Question>
      <Question field={`${EYE_DROPS}.${VALUE}`} label="Verwenden Sie Augentropfen?">
        <MultiAnswerInput label="Welche?" values={eyeDropsList} handleListChange={setEyeDropsList} />
      </Question>
      <Question field={`${DRUGS}.${VALUE}`} label="Nehmen Sie regelmässig Medikamente ein?">
        <MultiAnswerInput label="Welche?" values={drugsList} handleListChange={setDrugsList} />
      </Question>
      <Question field={BLOOD_PRESSURE} label="Besteht bei Ihnen ein hoher Blutdruck?" />
      <Question field={ASTHMA} label="Haben Sie Asthma?" />
      <Question
        field={`${HEART}.${VALUE}`}
        label="Hatten Sie einmal einen Schlaganfall, Herzinfarkt oder bestehen andere Herzprobleme?"
      >
        <Input field={`${HEART}.${DATE}`} label="Wann ungefähr?" />
        <TextArea field={`${HEART}.${DESCRIPTION}`} label="und was?" />
      </Question>
      <Question field={`${ILLNESSES}.${VALUE}`} label="Haben Sie andere Erkrankungen?">
        <Input field={`${ILLNESSES}.${DATE}`} label="Seit wann ungefähr?" />
        <TextArea field={`${ILLNESSES}.${DESCRIPTION}`} label="Welche?" />
      </Question>
      <Question field={`${ALLERGIES}.${VALUE}`} label="Haben Sie Allergien?">
        <TextArea field={`${ALLERGIES}.${DESCRIPTION}`} label="Welche?" />
      </Question>
      <Question field={SMOKING} label="Rauchen Sie?" />
      <Question field={ALCOHOL} label="Trinken Sie regelmässig Alkohol?" />
    </>
  );
};

YesNoForm.propTypes = {
  readMode: PropTypes.bool.isRequired,
  values: PropTypes.shape({}).isRequired,
};

export default YesNoForm;
