import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Container, Text } from './styles';

const Item = ({ type, isLatest, children }) => {
  const ref = useRef();

  useEffect(() => {
    if (isLatest) ref.current?.scrollIntoView?.({ block: 'start', behavior: 'smooth' });
  }, [isLatest]);

  return (
    <Container ref={ref} $type={type}>
      <Text $isError={type === 'error'}>{children}</Text>
    </Container>
  );
};

Item.propTypes = {
  type: PropTypes.oneOf(['client', 'server', 'error']).isRequired,
  isLatest: PropTypes.bool.isRequired,
  children: PropTypes.string.isRequired,
};

export default Item;
