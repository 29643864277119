import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  SET_CURRENT_INVOICE,
  UPDATE_INVOICES,
  UPDATE_INVOICE,
  UPDATE_ALL_TARMEDS,
  UPDATE_PATIENT_TARMEDS,
  UPDATE_DASHBOARD,
  FETCH_DASHBOARD,
  FETCH_INVOICES,
  FETCH_INVOICES_BY_PATIENT,
  FETCH_INVOICE,
  FETCH_INVOICES_BY_EXAM_ID,
  FETCH_KPI_BY_NURSING_HOME_ID,
  CREATE_INVOICE,
  SAVE_INVOICE,
  SAVE_PATIENT_INVOICE,
  SEND_INVOICE_REPORT,
  SEND_PATIENT_INVOICE,
  CANCEL_INVOICE,
  RESEND_INVOICE,
  FETCH_BASE_TARMEDS,
  FETCH_PATIENT_TARMEDS,
  UPDATE_SEARCH,
  UPDATE_STATUS,
  UPDATE_SORT,
  UPDATE_PAGINATION,
  UPDATE_PATIENT_ID,
  UPDATE_CANTON,
  UPDATE_YEAR,
  RESET_FILTERS,
  DOWNLOAD_PDF,
  FETCH_CANTON_DASHBOARD,
} from './types';

export const setCurrentInvoice = createAction(SET_CURRENT_INVOICE);
export const updateInvoices = createAction(UPDATE_INVOICES);
export const updateInvoice = createAction(UPDATE_INVOICE);
export const updateAllTarmeds = createAction(UPDATE_ALL_TARMEDS);
export const updatePatientTarmeds = createAction(UPDATE_PATIENT_TARMEDS);
export const updateDashboard = createAction(UPDATE_DASHBOARD);

export const updateSearch = createAction(UPDATE_SEARCH);
export const updateStatus = createAction(UPDATE_STATUS);
export const updateSort = createAction(UPDATE_SORT);
export const updatePagination = createAction(UPDATE_PAGINATION);
export const updatePatientId = createAction(UPDATE_PATIENT_ID);
export const updateCanton = createAction(UPDATE_CANTON);
export const updateYear = createAction(UPDATE_YEAR);
export const resetFilters = createAction(RESET_FILTERS);

export const fetchNursingHomeKPIs = createAlertAction(FETCH_KPI_BY_NURSING_HOME_ID);
export const fetchDashboard = createAlertAction(FETCH_DASHBOARD);
export const fetchInvoices = createAlertAction(FETCH_INVOICES);
export const fetchInvoice = createAlertAction(FETCH_INVOICE);
export const fetchInvoicesByExamId = createAlertAction(FETCH_INVOICES_BY_EXAM_ID);
export const fetchInvoicesByPatient = createAlertAction(FETCH_INVOICES_BY_PATIENT);

export const createInvoice = createAlertAction(CREATE_INVOICE);
export const saveInvoice = createAlertAction(SAVE_INVOICE);
export const savePatientInvoice = createAlertAction(SAVE_PATIENT_INVOICE);
export const sendInvoiceReport = createAlertAction(SEND_INVOICE_REPORT);
export const sendPatientInvoice = createAlertAction(SEND_PATIENT_INVOICE);
export const cancelInvoice = createAlertAction(CANCEL_INVOICE);
export const resendInvoice = createAlertAction(RESEND_INVOICE);
export const fetchBaseTarmeds = createAlertAction(FETCH_BASE_TARMEDS);
export const fetchPatientTarmeds = createAlertAction(FETCH_PATIENT_TARMEDS);
export const downloadPdf = createAlertAction(DOWNLOAD_PDF);
export const fetchCantonDashboard = createAlertAction(FETCH_CANTON_DASHBOARD);
