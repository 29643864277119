/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import { INVOICE, usePermissions } from 'permissions';
import { useInitKPIByNurseHomeId } from 'store/invoices';
import KPI from '../KPIValue';

import { Container } from './styles';

const NurseHomeKPI = ({ id }) => {
  const can = usePermissions();

  const { loading, list } = useInitKPIByNurseHomeId(can.read(INVOICE), id);

  if (!id || loading) return null;

  return (
    <Container>
      {list.map((kpi, index) => (
        <KPI
          key={index}
          label={kpi.labelLocale}
          value={kpi.value}
          start={kpi.start}
          end={kpi.end}
          prevStart={kpi.prevStart}
          prevEnd={kpi.prevEnd}
          prevValue={kpi.prevValue}
          period={kpi.period}
          unit={kpi.unit}
        />
      ))}
    </Container>
  );
};

NurseHomeKPI.defaultProps = { id: null };

NurseHomeKPI.propTypes = { id: PropTypes.string };

export default NurseHomeKPI;
