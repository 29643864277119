import { object, string } from 'yup';

import { SUB_SENDER, MESSAGE } from 'store/bugReports';

export const validationSchema = (withoutEmail) =>
  object().shape({
    [SUB_SENDER]: string().when((_, schema) =>
      withoutEmail ? schema : schema.trim().customEmail('E-Mail-Adresse ist ungültig.').required('E-Mail-Adresse fehlt.')
    ),
    [MESSAGE]: string().required('Geben Sie eine Nachricht ein.'),
  });
