import React, { useMemo, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { propEq } from 'ramda';

import { useCurrentExamination, useExaminations, ID, STATUS } from 'store/examinations';
import { PERSONAL } from 'permissions';
import Select from 'components/SelectNew';

import { statuses } from './consts';
import { getDates } from './utils';
import { Container, Label, Status } from './styles';

const ExaminationPanel = () => {
  const { id, view = PERSONAL } = useParams();
  const { push, location } = useHistory();
  const examinations = useExaminations();
  const [examId, examStatus] = useCurrentExamination([ID, STATUS]);

  const examDates = useMemo(() => getDates(examinations), [examinations]);
  const status = useMemo(() => statuses.find(propEq(examStatus, 'id')), [examStatus]);

  const handleSelect = useCallback(
    (exam) => {
      push(`/patients/${id}/${view}?${qs.stringify({ exam })}`, location.state);
    },
    [push, id, view, location.state]
  );

  if (!examId) return null;

  return (
    <Container>
      <Label>Untersuch vom</Label>
      <Select value={examId || ''} options={examDates} labelKey="date" onSelect={handleSelect} mb={0} mini />
      <Status color={status.color}>{status.name}</Status>
    </Container>
  );
};

export default ExaminationPanel;
