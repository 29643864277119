import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container, Label, Wrapper, Button, Message } from './styles';

const QuestionInput = ({ title, options, value, onChange, error, hint }) => {
  const onClick = useCallback(({ currentTarget }) => onChange(JSON.parse(currentTarget.value)), [onChange]);

  return (
    <Container>
      <Label>{title}</Label>
      <Wrapper>
        {options.map(({ id, jsonId, label }) => (
          <Button key={jsonId} type="button" value={jsonId} onClick={onClick} disabled={value === id} $error={error}>
            {label}
          </Button>
        ))}
      </Wrapper>
      {(error || hint) && <Message>{error || hint}</Message>}
    </Container>
  );
};

QuestionInput.defaultProps = {
  value: null,
  error: null,
  hint: false,
};
QuestionInput.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired,
      jsonId: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default QuestionInput;
