import {
  UNDER_TREATMENT,
  SURGERY,
  EYE_DROPS,
  DRUGS,
  BLOOD_PRESSURE,
  DIABETES,
  ASTHMA,
  HEART,
  ILLNESSES,
  ALLERGIES,
  SMOKING,
  ALCOHOL,
  DEMENTIA,
} from 'store/medicalHistory';

export const YES = 'Ja';
export const NO = 'Nein';

export const readModeQuestions = {
  [DEMENTIA]: 'Demenz',
  [UNDER_TREATMENT]: 'In augenärztlicher Beh.',
  [SURGERY]: 'Augenoperationen',
  [EYE_DROPS]: 'Augentropfen',
  [DRUGS]: 'Medikamente',
  [BLOOD_PRESSURE]: 'Hoher Blutdruck',
  [DIABETES]: 'Diabetes',
  [ASTHMA]: 'Asthma',
  [HEART]: 'Herzprobleme',
  [ILLNESSES]: 'Erkrankungen',
  [ALLERGIES]: 'Allergien',
  [SMOKING]: 'Rauchen',
  [ALCOHOL]: 'Regelmässig Alkohol',
};
