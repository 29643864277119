import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--color-grey97);
  padding-top: 10px;
  margin-bottom: 30px;
`;

export const Title = styled.p`
  font-size: var(--font-size14);
  font-weight: var(--font-weight500);
  color: var(--color-grey6A);
  line-height: 1.56;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Button = styled.button`
  border: none;
  background: none;
  outline: 0;
  cursor: pointer;
  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }
`;

export const Message = styled.p`
  font-size: var(--font-size13);
  color: var(--color-orangeDC);
  text-align: right;
  padding-top: 10px 0;
`;
