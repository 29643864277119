import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  column-gap: 40px;
  width: 100%;
  margin-top: 10px;
`;

export const Label = styled.label`
  font-size: var(--font-size15);
  font-weight: var(--font-weight400);
  line-height: 40px;
  color: var(--color-grey6A);
`;

export const TA = styled.textarea`
  width: 100%;
  min-height: ${({ readMode, tall }) => (readMode && 0) || (tall && 300) || 150}px;
  background-color: ${({ readMode }) => (readMode ? 'var(--color-greyEC)' : 'var(--color-white)')};
  border: 1px solid;
  border-radius: var(--border-radius8);
  border-color: ${({ readMode }) => (readMode ? 'var(--color-greyEC)' : 'var(--color-grey97)')};
  font-size: var(--font-size18);
  font-weight: var(--font-weight400);
  color: var(--color-grey6A);
  padding: 5px 10px;
  margin-bottom: ${({ mb }) => mb}px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  resize: none;
  &::placeholder {
    color: ${({ readMode }) => (readMode ? 'transparent' : 'var(--color-grey97)')};
  }
  &:disabled {
    opacity: 1;
    font-weight: var(--font-weight300);
    cursor: not-allowed;
  }
  &:focus {
    box-shadow: var(--shadow-input-focus);
  }
`;
