import MD5 from 'crypto-js/md5';
import { omit, prop } from 'ramda';

import { wrapperRequest } from './utils';
import { API_URL, URL, POST, PATCH, OWN_ERR_HANDLER } from './consts';

export const restore = () =>
  wrapperRequest({
    url: `${URL}/session`,
  });

export const patchUserData = (body = {}) =>
  wrapperRequest({
    url: `${API_URL}/users/${prop('id', body)}`,
    options: { method: PATCH, body: omit(['id'], body) },
  });

export const login = (body = {}) =>
  wrapperRequest({
    url: `${URL}/login`,
    options: {
      method: POST,
      body: {
        ...body,
        password: MD5(prop('password', body)).toString(),
      },
      [OWN_ERR_HANDLER]: true,
    },
  });

export const requestExternCode = (body = {}) =>
  wrapperRequest({
    url: `${URL}/authorize`,
    options: { method: POST, body, [OWN_ERR_HANDLER]: true },
  });

export const sendResetLink = (body = {}) =>
  wrapperRequest({
    url: `${URL}/reset`,
    options: { method: POST, body, [OWN_ERR_HANDLER]: true },
  });

export const resetPassword = (body = {}) =>
  wrapperRequest({
    url: `${URL}/setpassword`,
    options: {
      method: POST,
      body: {
        ...body,
        password: MD5(prop('password', body)).toString(),
      },
      [OWN_ERR_HANDLER]: true,
    },
  });

export const updatePassword = (id, body = {}) =>
  wrapperRequest({
    url: `${API_URL}/users/${id}/password`,
    options: {
      method: POST,
      body: {
        currentPassword: MD5(body.currentPassword).toString(),
        nextPassword: MD5(body.nextPassword).toString(),
      },
      [OWN_ERR_HANDLER]: true,
    },
  });

export const create2FA = () =>
  wrapperRequest({
    url: `${URL}/auth/2fa/create`,
    options: { method: POST, body: {} },
  });

export const validate2FA = (code) =>
  wrapperRequest({
    url: `${URL}/auth/2fa/validate`,
    options: { method: POST, body: { code }, [OWN_ERR_HANDLER]: true },
  });

export const post2FA = (code) =>
  wrapperRequest({
    url: `${URL}/auth/2fa/authenticate`,
    options: { method: POST, body: { code }, [OWN_ERR_HANDLER]: true },
  });

export const delete2FA = () =>
  wrapperRequest({
    url: `${URL}/auth/2fa/delete`,
    options: { method: POST, body: {} },
  });

export const getEmailByHash = (queryString) =>
  wrapperRequest({
    url: `${API_URL}/email${queryString}`,
    options: { [OWN_ERR_HANDLER]: true },
  });
