import styled from 'styled-components';

import AnimateBox from 'components/AnimateBox';

export const MainContainer = styled.div`
  padding-top: 10px;
  border-top: ${({ withoutBorder }) => (withoutBorder ? 'none' : '1px solid var(--color-greyEC)')};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Question = styled.div`
  margin-right: 20px;
  font-size: var(--font-size18);
  font-weight: var(--font-weight400);
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: var(--color-grey6A);
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Button = styled.button`
  height: 40px;
  width: 150px;
  background-color: ${({ isActive }) => (isActive ? 'var(--color-white)' : 'var(--color-greyF8)')};
  border: ${({ isActive }) => (isActive ? '1px solid var(--color-grey97)' : 'none')};
  color: var(--color-grey6A);
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  border-radius: var(--border-radius8);
  cursor: pointer;
  outline: 0;
  &:hover {
    background-color: ${({ isActive }) => (isActive ? 'var(--color-white)' : 'var(--color-greyEC)')};
  }
  &:first-child {
    margin-right: 16px;
  }
  &:focus {
    box-shadow: var(--shadow-focus);
  }
`;

export const CollapseContainer = styled(AnimateBox)`
  margin-top: 10px;
`;
