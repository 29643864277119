import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 40px;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const Label = styled.p`
  font-size: var(--font-size15);
  font-weight: var(--font-weight400);
  color: var(--color-grey6A);
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 150px 150px;
  grid-template-rows: 40px;
  column-gap: 15px;
  align-items: center;
`;

export const Button = styled.button`
  height: 100%;
  width: 100%;
  border: 1px solid;
  border-color: ${({ $error }) => ($error ? 'var(--color-orangeDC)' : 'var(--color-greyF8)')};
  border-radius: var(--border-radius8);
  background-color: var(--color-greyF8);
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  color: var(--color-grey6A);
  cursor: pointer;
  outline: 0;
  &:focus {
    box-shadow: var(--shadow-focus);
  }
  &:disabled {
    border-color: ${({ $error }) => ($error ? 'var(--color-orangeDC)' : 'var(--color-grey97)')};
    background-color: var(--color-white);
  }
  @media (hover) {
    &:hover:enabled {
      background-color: var(--color-greyEC);
    }
  }
`;

export const Message = styled.p`
  grid-column: 1 / -1;
  color: var(--color-orangeDC);
  font-size: var(--font-size13);
  text-align: right;
  margin-top: 3px;
`;
