import React, { useCallback, useEffect } from 'react';

import { useAsyncState } from 'utils/useAsyncState';
import Message from 'components/Message';
import Button from 'components/Button';
import Spinner from 'components/FullScreenSpinner';
import { CenteredContainer, LoginTitle } from 'pages/Login/styles';
import { useSessionActions, useAlerts } from 'store/session';

import { Input, Description } from './styles';

const description =
  'Öffnen Sie die Google Authenticator App auf Ihrem mobilen Gerät und geben Sie den 6-stelligen Code ein, der beim Eintrag «Augenmobil» erscheint.';

const TwoFactorAuth = () => {
  const { fetch2FA, validate2FA } = useSessionActions();
  const { extractId, loading, error } = useAlerts();
  const [code, setCode] = useAsyncState();

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (code) {
        extractId(fetch2FA(code));
      }
    },
    [fetch2FA, code, extractId]
  );

  useEffect(() => {
    if (code) extractId(fetch2FA(code));
  }, [code, extractId, fetch2FA]);

  return (
    <CenteredContainer>
      <LoginTitle>Zwei-Faktor-Authentifizierung </LoginTitle>
      <form onSubmit={handleSubmit}>
        <Input onChange={setCode} validateAction={validate2FA} />
        <Description>{description}</Description>
        <Button type="submit" color={code ? 'normal' : 'default'} disabled={!code || loading} height="50px">
          {loading ? <Spinner size={40} height="40px" /> : 'Weiter'}
        </Button>
        {error && <Message type="error">{error}</Message>}
      </form>
    </CenteredContainer>
  );
};

export default TwoFactorAuth;
