import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  align-items: flex-start;
  max-height: 265px;
  width: 100%;
  border: 1px solid var(--color-greyDC);
  border-radius: var(--border-radius8);
  padding: 20px 15px;
  overflow: auto;
`;

export const ReadModeWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyEC);
`;

export const NoDocuments = styled.p`
  font-size: var(--font-size18);
  font-weight: var(--font-weight400);
  color: var(--color-grey6A);
  text-align: center;
  padding: 5px 25px;
`;

export const WaitItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px;
  width: 140px;
`;

export const SpinnerWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1/1;
  background-color: var(--color-greyEC);
  border-radius: var(--border-radius8);
`;

export const Label = styled.p`
  font-size: var(--font-size13);
  color: var(--color-grey6A);
  text-align: center;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyEC);
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
