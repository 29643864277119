import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import TA from 'components/TextAreaNew';

const TextArea = ({ field, label }) => {
  const [{ value }, , { setValue }] = useField(field);

  return <TA value={value} onChangeText={setValue} placeholder={label} />;
};

TextArea.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default TextArea;
