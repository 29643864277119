import qs from 'query-string';

import { wrapperRequest } from './utils';
import { API_URL, POST, PATCH, DELETE, OWN_ERR_HANDLER } from './consts';

export const getInfoItems = (query = {}) =>
  wrapperRequest({
    url: `${API_URL}/infoitems?${qs.stringify(query)}`,
  });

export const getInfoItem = (id, query = {}) =>
  wrapperRequest({
    url: `${API_URL}/infoitems/${id}?${qs.stringify(query)}`,
  });

export const postInfoItem = (body = {}, query = {}) =>
  wrapperRequest({
    url: `${API_URL}/infoitems?${qs.stringify(query)}`,
    options: { method: POST, body, [OWN_ERR_HANDLER]: true },
  });

export const patchInfoItem = (id, body = {}, query = {}) =>
  wrapperRequest({
    url: `${API_URL}/infoitems/${id}?${qs.stringify(query)}`,
    options: { method: PATCH, body },
  });

export const deleteInfoItem = (id) =>
  wrapperRequest({
    url: `${API_URL}/infoitems/${id}`,
    options: { method: DELETE, [OWN_ERR_HANDLER]: true },
  });
