import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Text, Message } from './styles';

const TextArea = ({ error, open, ...rest }) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current && open) {
      ref.current.focus();
    }
  }, [open]);

  return (
    <div>
      <Text ref={ref} error={error} mb={0} {...rest} />
      {error && <Message>{error}</Message>}
    </div>
  );
};

TextArea.defaultProps = {
  error: null,
  open: false,
};

TextArea.propTypes = {
  error: PropTypes.string,
  open: PropTypes.bool,
};

export default TextArea;
