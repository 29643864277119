import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { isNil } from 'ramda';
import PropTypes from 'prop-types';

import { useUpdatedRegistration, usePlanningActions, REGISTRATIONS, ID } from 'store/planning';
import { ReactComponent as EditIcon } from 'assets/svg-icons/edit.svg';

import { Button } from './styles';

const Icon = ({ patientId }) => {
  const { id } = useParams();
  const registrationProp = useUpdatedRegistration(id, patientId);
  const { resetUpdatesProp } = usePlanningActions();
  const onClick = useCallback(
    () => resetUpdatesProp({ [ID]: id, prop: REGISTRATIONS, patientId }),
    [id, patientId, resetUpdatesProp]
  );

  return (
    <Button onClick={onClick} disabled={isNil(registrationProp)} title="Reset">
      <EditIcon />
    </Button>
  );
};

Icon.propTypes = { patientId: PropTypes.string.isRequired };

export default Icon;
