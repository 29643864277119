import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { omit, pathOr } from 'ramda';

import {
  CURRENT,
  UPDATES,
  FILTERS,
  SEARCH,
  ALL,
  STATUS,
  SORT,
  SORT_BY,
  SORT_DIR,
  ASC,
  PAGINATION,
  CURSOR,
  LIMIT,
  COUNT,
  HAS_MORE,
  REGISTRATIONS,
  ID,
} from '.';
import {
  SET_CURRENT,
  RESET_CURRENT,
  SET_SCHEDULE_UPDATES,
  SET_NEW_REGISTRATION,
  RESET_UPDATES_PROP,
  RESET_UPDATES,
  UPDATE_STATUS,
  UPDATE_SEARCH,
  UPDATE_SORT,
  UPDATE_PAGINATION,
  RESET_FILTERS,
} from './types';

const initFilters = {
  [STATUS]: ALL,
  [SEARCH]: '',
  [SORT]: {
    [SORT_BY]: STATUS,
    [SORT_DIR]: ASC,
  },
  [PAGINATION]: {
    [CURSOR]: 0,
    [LIMIT]: 10,
    [COUNT]: 0,
    [HAS_MORE]: false,
  },
};

const scheduleReducer = handleActions(
  {
    [SET_CURRENT]: (state, { payload }) => payload,
    [RESET_CURRENT]: () => null,
  },
  null
);

const updatesReducer = handleActions(
  {
    [SET_SCHEDULE_UPDATES]: (state, { payload }) => ({
      ...state,
      [payload[ID]]: {
        ...state[payload[ID]],
        ...omit([ID], payload),
      },
    }),
    [SET_NEW_REGISTRATION]: (state, { payload }) => ({
      ...state,
      [payload[ID]]: {
        ...state[payload[ID]],
        [REGISTRATIONS]: {
          ...pathOr({}, [payload[ID], REGISTRATIONS], state),
          ...omit([ID], payload),
        },
      },
    }),
    [RESET_UPDATES_PROP]: (state, { payload }) => {
      const { [ID]: id, prop, patientId } = payload;

      return {
        ...state,
        [id]:
          prop === REGISTRATIONS
            ? {
                ...state[id],
                [REGISTRATIONS]: omit([patientId], state[id][REGISTRATIONS]),
              }
            : omit([prop], state[id]),
      };
    },
    [RESET_UPDATES]: (state, { payload }) => (payload ? omit([payload], state) : {}),
  },
  {}
);

const filterReducer = handleActions(
  {
    [UPDATE_STATUS]: (state, { payload }) => ({
      ...state,
      [STATUS]: payload === ALL ? ALL : Number(payload),
      [PAGINATION]: { ...state[PAGINATION], [CURSOR]: 0 },
    }),
    [UPDATE_SEARCH]: (state, { payload }) => ({
      ...state,
      [SEARCH]: payload,
      [PAGINATION]: { ...state[PAGINATION], [CURSOR]: 0 },
    }),
    [UPDATE_SORT]: (state, { payload }) => ({
      ...state,
      [SORT]: payload,
      [PAGINATION]: { ...state[PAGINATION], [CURSOR]: 0 },
    }),
    [UPDATE_PAGINATION]: (state, { payload }) => ({
      ...state,
      [PAGINATION]: { ...state[PAGINATION], ...payload },
    }),
    [RESET_FILTERS]: () => initFilters,
  },
  initFilters
);

export default combineReducers({
  [CURRENT]: scheduleReducer,
  [UPDATES]: updatesReducer,
  [FILTERS]: filterReducer,
});
