import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Container, Item, Status } from './styles';

const Row = ({ id, widths, insurance, name, status, date, price }) => {
  const { push } = useHistory();
  const handleRowClick = useCallback(
    ({ currentTarget }) => {
      const { invoice } = currentTarget?.dataset || {};

      if (invoice) push(`/invoices/${invoice}`);
    },
    [push]
  );

  return (
    <Container type="button" data-invoice={id} onClick={handleRowClick}>
      <Item width={widths[0]}>{insurance}</Item>
      <Item width={widths[1]}>{name}</Item>
      <Item width={widths[2]}>{date}</Item>
      <Item width={widths[3]}>{price}</Item>
      <Status width={widths[4]}>{status}</Status>
    </Container>
  );
};

Row.defaultProps = {
  insurance: '–',
  name: '–',
  status: '–',
  date: '–',
};

Row.propTypes = {
  id: PropTypes.string.isRequired,
  widths: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  insurance: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  date: PropTypes.string,
  price: PropTypes.string.isRequired,
};

export default Row;
