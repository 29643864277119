import styled from 'styled-components';

import Btn from 'components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Button = styled(Btn)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 41px;
  margin-right: 6px;
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 auto;
  height: 41px;
  opacity: 0.88;
  background-color: var(--color-greyEC);
  border-radius: var(--border-radius8);
`;

export const LeftSide = styled.div`
  opacity: 0.88;
  font-size: var(--font-size18);
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: var(--color-grey6A);
  padding-left: 13px;
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
`;

export const Price = styled(LeftSide)`
  font-weight: var(--font-weight600);
  padding-left: 0;
`;

export const Status = styled.div`
  opacity: 0.88;
  font-size: var(--font-size10);
  font-weight: var(--font-weight600);
  letter-spacing: 1.01px;
  color: #676767;
  padding: 0 20px;
`;
