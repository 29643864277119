import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Circle, First, Second, Third, Text } from './styles';

const Grade = ({ grade }) => {
  const ratio = grade / 100;

  return (
    <Wrapper>
      <Circle>
        <First ratio={ratio} />
        <Second ratio={ratio} />
        <Third ratio={ratio} />
      </Circle>
      <Text>{grade}%</Text>
    </Wrapper>
  );
};

Grade.propTypes = {
  grade: PropTypes.number.isRequired,
};

export default Grade;
