import styled from 'styled-components';

import cross from './cross.svg';
import check from './check.svg';
import minus from './minus.svg';
import darkCheck from './darkCheck.svg';

const iconMap = { cross, check, minus, darkCheck };

export const Container = styled.div`
  display: flex;
  align-items: center;
  & > * {
    cursor: pointer;
  }
  margin-bottom: ${({ mb }) => mb}px;
  margin-right: ${({ mr }) => mr}px;
`;

export const InputWrap = styled.div`
  background-color: var(--color-white);
  margin-right: 10px;
  border-radius: 3px;
`;

export const Input = styled.input`
  display: block;
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  border: 1px solid ${({ error, borderColor }) => (error ? 'var(--color-orangeDC)' : borderColor || 'var(--color-grey97)')};
  border-radius: 3px;
  background-color: transparent;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  &:checked {
    background: url(${({ iconType }) => iconMap[iconType]}) no-repeat;
    background-position: center;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export const Label = styled.label`
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ fontColor }) => fontColor || 'var(--color-grey6A)'};
  min-width: fit-content;
  user-select: none;
`;
