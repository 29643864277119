import styled from 'styled-components';

export const Label = styled.div`
  border-top: 1px solid var(--color-grey97);
  margin: 70px 0 20px 0;
  padding-top: 10px;
  color: var(--color-grey6A);
  font-size: var(--font-size15);
  font-weight: var(--font-weight600);
  margin-bottom: 20px;
`;
