import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 265px;
  width: 100%;
  border: 1px solid var(--color-greyDC);
  border-radius: var(--border-radius8);
  padding: 20px 15px;
  margin-top: 20px;
  overflow: auto;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
  position: relative;
  margin: 10px;
  width: 140px;
`;

export const Button = styled.button`
  display: block;
  width: 100%;
  padding-top: 100%;
  background: var(--color-greyEC);
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: var(--border-radius8);
  border: 1px solid var(--color-white);
  outline: none;
  cursor: pointer;
  &:hover {
    border: 1px solid var(--color-grey97);
  }
  &:focus {
    box-shadow: var(--shadow-focus);
  }
  &:disabled {
    border: 1px solid var(--color-white);
    cursor: not-allowed;
  }
`;

export const Label = styled.p`
  font-size: var(--font-size13);
  border-radius: var(--border-radius8);
  color: var(--color-grey6A);
  background-color: var(--color-greyEC);
  padding: 0 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
