import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { map, T, tail } from 'ramda';

import { POSITION_TITLES } from 'utils/constants';
import { TITLE, FIRST_NAME, LAST_NAME, useUser, useSessionActions } from 'store/session';
import Section from 'pages/Profile/Section';
import Select from 'components/SelectNew';
import Input from 'components/TextInputNew';

import { Container, Wrapper } from './styles';

const Personal = () => {
  const { saveUserData } = useSessionActions();
  const initValues = useUser(
    {
      [TITLE]: 'title',
      [FIRST_NAME]: 'firstname',
      [LAST_NAME]: 'lastname',
    },
    T
  );

  const { values, handleChange, setFieldValue, errors } = useFormik({
    initialValues: map((item) => item || '', initValues),
    validate: saveUserData,
    validateOnBlur: false,
  });

  const handleChangeTitle = useCallback((id) => setFieldValue(TITLE, id || ''), [setFieldValue]);

  const options = useMemo(() => (values[TITLE] ? POSITION_TITLES : tail(POSITION_TITLES)), [values]);

  const inputProps = useMemo(
    () => ({
      labelWidth: '160px',
      labelSize: 'var(--font-size18)',
    }),
    []
  );

  return (
    <Container>
      <Section title="Mein Profil" />
      <Wrapper>
        <Select
          label="Titel"
          value={values[TITLE] || ''}
          options={options}
          onSelect={handleChangeTitle}
          labelSize="var(--font-size18)"
          labelWidth="160px"
        />
        <Input
          label="Name"
          name={FIRST_NAME}
          value={values[FIRST_NAME]}
          onChange={handleChange}
          error={errors[FIRST_NAME]}
          {...inputProps}
        />
        <Input
          label="Vorname"
          name={LAST_NAME}
          value={values[LAST_NAME]}
          onChange={handleChange}
          error={errors[LAST_NAME]}
          {...inputProps}
        />
      </Wrapper>
    </Container>
  );
};

export default Personal;
