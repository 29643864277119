import React, { useCallback } from 'react';
import { useFormikContext, useField } from 'formik';
import PropTypes from 'prop-types';

import {
  NAME,
  ADDRESS,
  CITY,
  CANTON,
  COUNTRY,
  ZIP_CODE,
  PHONE,
  ORG_URL,
  SPECIALTY,
  SERVICES,
  SERVICE_ITEMS,
  DESCRIPTION,
  ORGANIZATION_STATUS,
  STATUS,
  CHANNEL,
} from 'store/organizations';
import { defineNursingHomeDataByName } from 'store/organizations/utils';
import TextInput from 'components/TextInputNew';
import Radios from 'components/RadiosNew';
import Checkbox from 'components/CheckboxNew';
import Textarea from 'components/TextAreaNew';
import Select from 'components/SelectNew';
import AddressAutocomplete from 'components/AddressAutocomplete';

import { SPECIALTY_LABELS, CHANNEL_LABELS } from './utils';
import { CheckBoxContainer, Label, Wrapper, CheckboxWrap } from './styles';

const MainForm = ({ getFieldProps, isNurseHome, isClinic, errors }) => {
  const { setValues } = useFormikContext();
  const [{ value: name }, , { setValue: setName }] = useField(NAME);
  const [{ value: specialty }, , { setValue: setSpecialty }] = useField(SPECIALTY);
  const [{ value: address }] = useField(ADDRESS);
  const [{ value: zipCode }] = useField(ZIP_CODE);
  const [{ value: city }] = useField(CITY);
  const [{ value: canton }] = useField(CANTON);
  const [{ value: country }] = useField(COUNTRY);
  const [{ value: services }, , { setValue: setServices }] = useField(SERVICES);
  const [urlField] = useField(ORG_URL);
  const [{ value: channel }, , { setValue: setChannel }] = useField(CHANNEL);
  const [descriptionField] = useField(DESCRIPTION);
  const [{ value: status }, , { setValue: setStatus }] = useField(STATUS);
  const updateAutocompleteValues = useCallback((data) => setValues(($) => ({ ...$, ...data })), [setValues]);
  const onChangeName = useCallback(
    (val) => {
      if (isNurseHome) {
        setValues(($) => ({ ...$, [NAME]: val, ...defineNursingHomeDataByName(val) }));
      } else {
        setName(val);
      }
    },
    [isNurseHome, setName, setValues]
  );
  const handleClick = useCallback(
    (id) => () => {
      setServices({ ...services, [id]: !services[id] });
    },
    [services, setServices]
  );

  const handleSelect = useCallback((value) => setStatus(Number(value)), [setStatus]);

  return (
    <>
      {isClinic && <Radios label="Fachgebiet" items={SPECIALTY_LABELS} value={specialty} setValue={setSpecialty} />}
      <TextInput value={name} handleChangeText={onChangeName} label="Name" />
      <AddressAutocomplete
        address={address}
        zipCode={zipCode}
        city={city}
        canton={canton}
        country={country}
        setData={updateAutocompleteValues}
        zipCodeErr={errors && errors[ZIP_CODE]}
      />
      <TextInput {...getFieldProps(PHONE)} label="Telefon" />
      <TextInput {...urlField} label="URL" error={errors && errors[ORG_URL]} />
      {isClinic && (
        <>
          <CheckBoxContainer>
            <Label>Angebot</Label>
            <Wrapper>
              {SERVICE_ITEMS.map(({ id, label }) => (
                <CheckboxWrap key={id}>
                  <Checkbox label={label} selected={services[id]} onClick={handleClick(id)} />
                </CheckboxWrap>
              ))}
            </Wrapper>
          </CheckBoxContainer>
          <Radios label="Berichte senden" items={CHANNEL_LABELS} value={channel} setValue={setChannel} />
        </>
      )}
      <Textarea {...descriptionField} label="Bemerkungen" />
      <Select label="Status" value={status} options={ORGANIZATION_STATUS} onSelect={handleSelect} />
    </>
  );
};

MainForm.defaultProps = { errors: null };
MainForm.propTypes = {
  getFieldProps: PropTypes.func.isRequired,
  isNurseHome: PropTypes.bool.isRequired,
  isClinic: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    [ZIP_CODE]: PropTypes.string,
    [CANTON]: PropTypes.string,
    [ORG_URL]: PropTypes.string,
  }),
};

export default MainForm;
