import { withFormik } from 'formik';
import qs from 'query-string';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mergeRight, pipe, applySpec, cond, equals, either, find, propEq, T, path } from 'ramda';

import { saveDiagnosis } from 'store/diagnoses/actions';
import { getCurrentDiagnosisProp } from 'store/diagnoses/selectors';
import { ID, EYE, EYE_EXTERIOR, FUNDUS, ANALYSIS_FROM_PREVIOUS } from 'store/diagnoses';

import { generatorDefaultStringValues, questionsObject, additionalFields } from './utils';
import EyeImagesAnalysis from './component';

const mapPropsToValues = ({ eyeExterior = [], fundus = [], location }) => {
  const { eye, area } = qs.parse(location.search.slice(1));

  return pipe(
    cond([
      [equals(EYE_EXTERIOR), () => eyeExterior],
      [equals(FUNDUS), () => fundus],
      [T, () => []],
    ]),
    either(find(propEq(eye, EYE)), () => ({})),
    mergeRight({
      ...generatorDefaultStringValues(questionsObject[area]),
      ...additionalFields(eye)[area],
    })
  )(area);
};

const sendData = (values, { location, diagnosisId, eyeExterior, fundus, updateDiagnosis }) => {
  const { eye, area } = qs.parse(location.search.slice(1));

  const currentArea = cond([
    [equals(EYE_EXTERIOR), () => eyeExterior],
    [equals(FUNDUS), () => fundus],
    [T, () => []],
  ])(area);
  const fieldIndex = currentArea.findIndex(propEq(eye, EYE));

  const body = { diagnosisId };

  if (fieldIndex === -1) {
    body[area] = [...currentArea, { ...values }];
    updateDiagnosis(body);

    return {};
  }

  body[area] = [...currentArea];
  body[area][fieldIndex] = { ...values };
  updateDiagnosis(body);

  return path([fieldIndex, ANALYSIS_FROM_PREVIOUS], currentArea) && values[ANALYSIS_FROM_PREVIOUS]
    ? { [ANALYSIS_FROM_PREVIOUS]: true }
    : {};
};

const mapStateToProps = applySpec({
  diagnosisId: getCurrentDiagnosisProp(ID),
  eyeExterior: getCurrentDiagnosisProp(EYE_EXTERIOR),
  fundus: getCurrentDiagnosisProp(FUNDUS),
});

const mapDispatchToProps = { updateDiagnosis: saveDiagnosis };

export default pipe(
  withFormik({
    mapPropsToValues,
    validate: sendData,
    handleSubmit: (_, { setSubmitting }) => {
      setSubmitting(false);
    },
    validateOnBlur: false,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(EyeImagesAnalysis);
