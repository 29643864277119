import { values, pathOr, path } from 'ramda';

import { WARD, META, CREATED } from 'store/planning';

export const getWardStructure = (files) => {
  const items = values(
    files.reduce((acc, file) => {
      const key = pathOr(null, ['form', WARD], file);

      if (!acc[key]) {
        acc[key] = { ward: key || '', items: [], wait: [], created: new Date(path([META, CREATED], file)).valueOf() };
      }

      acc[key].items.push(file);

      return acc;
    }, {})
  );

  return items.length === 0
    ? [{ index: 0, ward: '', items: [], wait: [] }]
    : items.sort((a, b) => b.created - a.created).map((item, index) => ({ ...item, index }));
};
