import React, { useCallback, useMemo } from 'react';
import { pipe, find, propEq, prop } from 'ramda';
import PropTypes from 'prop-types';

import Spinner from 'components/FullScreenSpinner';

import Close from './Close';
import Slider from './Slider';
import Image from './Image';
import { Container, ContentItem, TitleWrap, Name, Button, ImageBox, Message } from './styles';

const Content = ({
  loading,
  fullList,
  compareList,
  currentId,
  deleteItem,
  selectImage,
  setFocused,
  updateFilter,
  filters,
  error,
}) => {
  const handleSelect = useCallback(
    ({ currentTarget }) => {
      const { id, action } = currentTarget.dataset;
      if (!(id && action)) return;
      if (action === 'focus') {
        setFocused(id);
      }
      if (action === 'delete') {
        deleteItem(id);
      }
    },
    [setFocused, deleteItem]
  );

  const currentDescription = useMemo(
    () => pipe(find(propEq(currentId, 'id')), prop('description'))(fullList),
    [fullList, currentId]
  );

  if (loading) {
    return (
      <Container>
        <Spinner height="100%" />
      </Container>
    );
  }
  if (error || !compareList.length) {
    return (
      <Container>
        <Message type={error ? 'error' : ''}>{error || 'Wählen Sie ein Bild aus der Liste aus.'}</Message>
      </Container>
    );
  }
  if (compareList.length === 1) {
    return (
      <Container>
        <ContentItem maxWidth>
          <TitleWrap>
            <Name disabled fullWidth>
              {currentDescription}
            </Name>
          </TitleWrap>
          <ImageBox>
            <Slider
              list={fullList}
              currentId={currentId}
              filters={filters}
              selectImage={selectImage}
              updateFilter={updateFilter}
            />
          </ImageBox>
        </ContentItem>
      </Container>
    );
  }

  return (
    <Container>
      {compareList.map(({ id, blobUrl, description }) => (
        <ContentItem key={id}>
          <TitleWrap>
            <Name type="button" disabled={id === currentId} data-id={id} data-action="focus" onClick={handleSelect}>
              {description}
            </Name>
            <Button type="button" data-id={id} data-action="delete" onClick={handleSelect} isSelected={id === currentId}>
              <Close />
            </Button>
          </TitleWrap>
          <ImageBox data-id={id} data-action="focus" onMouseDown={handleSelect}>
            <Image src={blobUrl} filter={filters[id]} updateFilter={updateFilter} />
          </ImageBox>
        </ContentItem>
      ))}
    </Container>
  );
};

Content.defaultProps = {
  loading: false,
  currentId: null,
  filters: {},
  error: null,
};

Content.propTypes = {
  loading: PropTypes.bool,
  fullList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  compareList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      blobUrl: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentId: PropTypes.string,
  deleteItem: PropTypes.func.isRequired,
  selectImage: PropTypes.func.isRequired,
  setFocused: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  filters: PropTypes.objectOf(PropTypes.shape({})),
  error: PropTypes.string,
};

export default Content;
