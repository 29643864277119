import React from 'react';

export default () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="11.591px"
    height="21.061px"
    viewBox="14.439 10.007 11.591 21.061"
    enableBackground="new 14.439 10.007 11.591 21.061"
    xmlSpace="preserve"
  >
    <path fill="none" stroke="#979797" strokeWidth="1.5" d="M25.5,10.537l-10,10l10,10" />
  </svg>
);
