import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container, Button, Warn } from './styles';

const Removing = ({ index, onRemove }) => {
  const [isShowWarn, setShowWarn] = useState(false);
  const onToggle = useCallback(() => setShowWarn(($) => !$), []);
  const onDelete = useCallback(() => onRemove(index), [index, onRemove]);

  return (
    <Container>
      {isShowWarn ? (
        <Warn message="Möchten Sie den Kontakt wirklich löschen?" showActon={onToggle} deleteAction={onDelete} />
      ) : (
        <Button type="button" onClick={onToggle} color="warning">
          Kontakt löschen
        </Button>
      )}
    </Container>
  );
};

Removing.propTypes = {
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Removing;
