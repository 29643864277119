import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const AUTHENTICATE = getName('AUTHENTICATE');
export const AUTHENTICATE_2FA = getName('AUTHENTICATE_2FA');
export const UPDATE_USER = getName('UPDATE_USER');
export const LOGOUT = getName('LOGOUT');
export const UPDATE_2FA_LINK = getName('UPDATE_2FA_LINK');
export const UPDATE_USER_DATA = getName('UPDATE_USER_DATA');

export const LOGIN = getName('LOGIN');
export const REQUEST_EXTERN_CODE = getName('REQUEST_EXTERN_CODE');
export const RESTORE = getName('RESTORE');
export const FETCH_RESET_LINK = getName('FETCH_RESET_LINK');
export const RESET_PASSWORD = getName('RESET_PASSWORD');
export const UPDATE_PASSWORD = getName('UPDATE_PASSWORD');
export const FETCH_LINK_2FA = getName('FETCH_LINK_2FA');
export const FETCH_2FA = getName('FETCH_2FA');
export const VALIDATE_2FA = getName('VALIDATE_2FA');
export const RESET_2FA = getName('RESET_2FA');
export const SAVE_USER_DATA = getName('SAVE_USER_DATA');
export const GET_EXTERNAL_EMAIL = getName('GET_EXTERNAL_EMAIL');
