import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

export const GridWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const lineStyle = `
  position: absolute;
  background-color: var(--color-grey97);
`;
export const VLine = styled.div`
  ${lineStyle}
  inset: ${({ $ratio }) => `0 auto 0 calc(${$ratio * 100}% - 1px)`};
  width: 1px;
`;
export const HLine = styled.div`
  ${lineStyle}
  inset: ${({ $ratio }) => `calc(100% - ${$ratio * 100}%) 0 auto 0 `};
  height: 1px;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
  overflow: hidden;
`;
export const YAxis = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  border-right: 1px solid var(--color-black);
`;
export const WrapY = styled.div`
  display: flex;
  flex-direction: ${({ $reversed }) => ($reversed ? 'column-reverse' : 'column')};
  justify-content: space-between;
  height: ${({ $ratio }) => $ratio * 100}%;
`;
export const TextY = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1px;
  padding: 0 5px;
  &:after {
    content: '${({ $content }) => $content || ''}';
    font-size: var(--font-size15);
    color: var(--color-black);
  }
`;
export const XAxis = styled.div`
  grid-column: 2/3;
  width: 100%;
  border-top: 1px solid var(--color-black);
`;
export const WrapX = styled.div`
  display: flex;
  flex-direction: ${({ $reversed }) => ($reversed ? 'row-reverse' : 'row')};
  justify-content: space-between;
  width: ${({ $ratio }) => $ratio * 100}%;
`;
export const TextX = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1px;
  padding: 2px 0;
  &:after {
    content: '${({ $content }) => $content || ''}';
    font-size: var(--font-size15);
    color: var(--color-black);
  }
`;
