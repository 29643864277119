import React, { useCallback, useMemo } from 'react';
import { props } from 'ramda';
import PropTypes from 'prop-types';

import {
  useOrganizations,
  useOrganizationActions,
  ORGANIZATION_KINDS,
  SEARCH,
  SORT,
  PAGINATION,
  CURSOR,
  LIMIT,
  COUNT,
  HAS_MORE,
} from 'store/organizations';
import TextInput from 'components/TextInputNew';
import Table from 'components/Table';

import Row from './Row';
import { LABELS, tableHead, handleOrganizations } from '../utils';

const List = ({ kind }) => {
  const { updateSearch, updateSort, updatePagination } = useOrganizationActions();
  const { list, loading, filters } = useOrganizations(kind);
  const [search, sort, pagination] = props([SEARCH, SORT, PAGINATION], filters);
  const organizations = useMemo(() => handleOrganizations(list), [list]);

  const updateSortList = useCallback((value) => updateSort({ kind, value }), [updateSort, kind]);
  const updatePaginationList = useCallback((value) => updatePagination({ kind, value }), [updatePagination, kind]);
  const handleChangeSearch = useCallback(
    (value) => {
      updateSearch({ kind, value });
    },
    [kind, updateSearch]
  );

  const renderRow = useCallback((data) => <Row {...data} />, []);

  return (
    <>
      <TextInput type="search" placeholder={LABELS[kind].SEARCH} value={search} handleChangeText={handleChangeSearch} mb={70} />
      <Table
        title={LABELS[kind].LIST}
        head={tableHead}
        list={organizations}
        renderRow={renderRow}
        sort={sort}
        cursor={pagination[CURSOR]}
        count={pagination[COUNT]}
        limit={pagination[LIMIT]}
        hasMore={pagination[HAS_MORE]}
        updateSort={updateSortList}
        updatePagination={updatePaginationList}
        loading={loading}
      />
    </>
  );
};

List.propTypes = {
  kind: PropTypes.oneOf([ORGANIZATION_KINDS.NURSING_HOME, ORGANIZATION_KINDS.OPTICIAN, ORGANIZATION_KINDS.CLINIC]).isRequired,
};

export default List;
