import React, { useState, useCallback, useEffect } from 'react';
import { useField } from 'formik';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import { PORTRAIT, usePatientsActions, useAlerts } from 'store/patients';
import Spinner from 'components/FullScreenSpinner';
import Portal, { IMAGES_REVIEW } from 'components/Portal';
import defaultProfilePhoto from 'assets/img/profilePhoto.png';

import Icon from './Icon';
import Close from './Close';
import { Container, Image, UploadButton, Message, CloseButton, ReviewContainer, ReviewWrapper, ReviewImage } from './styles';

const Portrait = ({ readMode }) => {
  const [isOpenReview, setIsOpenReview] = useState();
  const handleOpenReview = useCallback(() => setIsOpenReview(($) => !$), []);
  const [{ value: portrait }, , { setValue: setPortrait }] = useField(PORTRAIT);
  const { uploadMediaFile } = usePatientsActions();
  const { extractId, loading, success, error, resetAlerts } = useAlerts();
  const onDrop = useCallback(
    ([file]) => {
      if (file) extractId(uploadMediaFile(file));
    },
    [extractId, uploadMediaFile]
  );
  const {
    getRootProps,
    getInputProps,
    isDragActive: btnIsDragActive,
    isDragReject: btnIsDragReject,
  } = useDropzone({
    onDrop,
    accept: ['image/png', 'image/jpeg'],
  });
  const {
    getRootProps: imgGetRootProps,
    getInputProps: imgGetInputProps,
    isDragActive: imgIsDragActive,
    isDragReject: imgIsDragReject,
  } = useDropzone({
    onDrop,
    accept: ['image/png', 'image/jpeg'],
  });
  const isDragActive = btnIsDragActive || imgIsDragActive;
  const isDragReject = btnIsDragReject || imgIsDragReject;

  useEffect(() => {
    if (success) {
      setPortrait(success);
      resetAlerts();
    }
  }, [resetAlerts, setPortrait, success]);

  return (
    <>
      <Container error={error}>
        {!(readMode || portrait) && (
          <Image
            type="button"
            src={defaultProfilePhoto}
            {...imgGetRootProps()}
            borderColor={(isDragReject && '#f79253') || (isDragActive && '#83dfb4')}
            disabled={loading}
          >
            <input {...imgGetInputProps()} />
            {loading && <Spinner height={120} />}
            <Icon />
          </Image>
        )}
        {Boolean(portrait) && (
          <Image type="button" src={portrait} onClick={handleOpenReview} disabled={loading}>
            {loading && <Spinner height={120} />}
          </Image>
        )}
        {!readMode && (
          <UploadButton
            type="button"
            color={(isDragReject && 'warning') || (isDragActive && 'success') || 'normal'}
            {...getRootProps()}
            disabled={loading}
          >
            <input {...getInputProps()} />
            {isDragReject && 'Gültige Dateiformate .JPEG und .PNG'}
            {!isDragReject && portrait && 'Foto ändern'}
            {!isDragReject && !portrait && 'Foto hochladen'}
          </UploadButton>
        )}
        {!readMode && error && (
          <Message type="error" small={1}>
            {error}
          </Message>
        )}
      </Container>
      {Boolean(portrait && isOpenReview) && (
        <Portal type={IMAGES_REVIEW}>
          <ReviewContainer>
            <ReviewWrapper>
              <CloseButton type="button" onClick={handleOpenReview}>
                <Close />
              </CloseButton>
              <ReviewImage src={portrait} />
            </ReviewWrapper>
          </ReviewContainer>
        </Portal>
      )}
    </>
  );
};

Portrait.propTypes = { readMode: PropTypes.bool.isRequired };

export default Portrait;
