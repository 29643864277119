import { pipe, applySpec, prop, cond, equals, T, ifElse } from 'ramda';

import {
  ID,
  DATE,
  TARIFF_TYPE,
  CODE,
  NAME,
  NAME_UVG,
  BODY_LOCATION,
  LEFT,
  RIGHT,
  SESSION,
  QUANTITY,
  DURATION_IN_MINUTES,
} from 'store/invoices';
import { LAW_TYPE_KINDS } from 'store/patients';

export const headerFields = [
  { id: TARIFF_TYPE, content: 'Typ', width: '11%' },
  { id: CODE, content: 'Code', width: '12%' },
  { id: NAME, content: 'Name', width: '60%' },
  { id: BODY_LOCATION, content: 'Auge', width: '17%' },
];

export const headerSelectedFields = [
  { id: SESSION, content: 'Session', width: '10%' },
  { id: DATE, content: 'Datum', width: '15%' },
  { id: 'time', content: 'zeit', width: '10%' },
  { id: DURATION_IN_MINUTES, content: 'Dauer in Minuten', width: '15%' },
  { id: TARIFF_TYPE, content: 'Typ', width: '5%' },
  { id: CODE, content: 'Code', width: '15%' },
  { id: BODY_LOCATION, content: 'Auge', width: '10%' },
  { id: QUANTITY, content: 'Menge', width: '20%' },
].map((item) => ({ ...item, withoutSort: true }));

export const prepareServices = (services, lawType) =>
  services.map(
    applySpec({
      id: prop(ID),
      tariffType: prop(TARIFF_TYPE),
      code: prop(CODE),
      name: ifElse(() => lawType !== LAW_TYPE_KINDS.KVG, prop(NAME_UVG), prop(NAME)),
      bodyLocation: pipe(
        prop(BODY_LOCATION),
        cond([
          [equals(LEFT), () => 'OS'],
          [equals(RIGHT), () => 'OD'],
          [T, () => 'Beide'],
        ])
      ),
    })
  );

export const prepareSelectedServices = (services, errors, lawType) =>
  services.map((service, index) =>
    applySpec({
      id: ({ [ID]: id }) => `${id}-${index}`,
      index: () => index,
      date: prop(DATE),
      tariffType: prop(TARIFF_TYPE),
      code: prop(CODE),
      name: ifElse(() => lawType === LAW_TYPE_KINDS.UVG, prop(NAME_UVG), prop(NAME)),
      bodyLocation: pipe(
        prop(BODY_LOCATION),
        cond([
          [equals(LEFT), () => 'OS'],
          [equals(RIGHT), () => 'OD'],
          [T, () => 'Beide'],
        ])
      ),
      quantity: prop(QUANTITY),
      compareError: () => Boolean(errors[index]),
      session: prop(SESSION),
      duration: prop(DURATION_IN_MINUTES),
    })(service)
  );
