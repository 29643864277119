import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 40px;
  max-width: 470px;
  margin: 40px auto;
`;

export const Wrapper = styled.div`
  border-top: 1px solid var(--color-grey6A);
  margin: 50px 0;
`;

export const Version = styled.div`
  color: var(--color-grey6A);
  font-size: var(--font-size15);
  font-weight: var(--font-weight600);
  line-height: 1.2;
  letter-spacing: 0;
  text-align: left;
  margin: 5px 0 10px 0;

  @media (max-width: 1024px) {
    padding: 0 10px;
  }
`;

export const Title = styled.h1`
  font-size: var(--font-size30);
  color: var(--color-grey6A);
  font-weight: var(--font-weight400);
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 20px;
`;

export const Note = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    display: block;
    padding: 0 10px;
  }
`;

export const Text = styled.p`
  font-size: var(--font-size14);
  line-height: 1.36;
  letter-spacing: 0.08px;
  color: var(--color-grey6A);
  width: 100%;
`;

export const Role = styled.p`
  width: 120px;
  margin-right: 18px;
  font-size: var(--font-size10);
  line-height: 1.3;
  letter-spacing: 1.01px;
  font-weight: var(--font-weight500);
  text-transform: uppercase;
  color: var(--color-blue5D);

  @media (max-width: 1024px) {
    margin-bottom: 3px;
    width: auto;
  }
`;
