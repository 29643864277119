import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1024px;
  margin: 40px auto;
`;

export const NavLink = styled(Link)`
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyEC);
  font-size: var(--font-size13);
  font-weight: 500;
  letter-spacing: 0.92px;
  color: var(--color-grey6A);
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 15px;
  margin: 15px 0;
  @media (hover) {
    &:hover {
      background-color: var(--color-greyF8);
    }
  }
  &:active {
    background-color: #bdbcbc;
  }
`;

export const PinkBlock = styled.div`
  border-radius: var(--border-radius8);
  background-color: rgba(253, 215, 215, 0.46);
  padding: 20px 30px;
  margin-bottom: 5px;
  &:first-child {
    margin-top: -10px;
  }
`;

export const Text = styled.p`
  font-size: var(--font-size18);
  line-height: 1.56;
  letter-spacing: 0.5px;
`;

export const BoldText = styled(Text)`
  font-weight: var(--font-weight500);
`;

export const BoldTextWithParagraphs = styled(BoldText)`
  white-space: pre-wrap;
`;

export const Table = styled.div`
  margin-top: -20px;
  width: 100%;
`;

export const TR = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TD = styled.div`
  font-size: var(--font-size15);
  font-weight: var(--font-weight400);
  line-height: 1.2;
  color: var(--color-grey6A);
  width: ${({ w }) => w || 'auto'};
  margin: 5px 0;
  padding-right: ${({ first }) => (first ? '30px' : 0)};
`;

export const TH = styled(TD)`
  font-size: var(--font-size13);
  letter-spacing: 0.92px;
  border-top: solid 1px var(--color-grey97);
  text-transform: uppercase;
  padding-top: 5px;
  margin-bottom: 15px;
`;

export const CopyWrapper = styled.p`
  margin-top: 30px;
  color: var(--color-grey6A);
`;

export const ButtonLink = styled.button`
  font-size: var(--font-size15);
  color: var(--color-blue5D);
  letter-spacing: 0.33px;
  cursor: pointer;
  background: none;
  border: none;
  outline: 0;
  text-decoration: underline;

  &:hover {
    color: var(--color-blue8E);
  }

  &:active {
    color: var(--color-blue2E);
  }
`;
