import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Container, Wrapper, DateInput, TimeInput, Item, Auge, Input, ItemWrap, Button, Text } from './styles';

const SelectedRow = ({
  id,
  index,
  date,
  tariffType,
  code,
  name,
  bodyLocation,
  session,
  quantity,
  duration,
  widths,
  icon,
  action,
  onChangeQuantity,
  onChangeDate,
  onChangeSession,
  onChangeDuration,
  setDataError,
  readMode,
  compareError,
}) => {
  const [amountErr, setAmountErr] = useState('');
  const [dateErr, setDateErr] = useState('');
  const [timeErr, setTimeErr] = useState('');
  const [sessionErr, setSessionErr] = useState('');
  const [durationErr, setDurationErr] = useState('');

  const handleChangeDate = useCallback((isoDate) => onChangeDate(index, isoDate), [onChangeDate, index]);
  const handleChangeQuantity = useCallback(
    (event) => {
      const value = Number(event.target.value);

      setAmountErr(($) => ($ ? '' : $));
      onChangeQuantity(index, value);

      if (!(value > 0 && Number.isInteger(value))) setAmountErr('Ungültige Menge');
    },
    [onChangeQuantity, index]
  );
  const handleChangeSession = useCallback(
    (event) => {
      const value = Number(event.target.value);

      setSessionErr(($) => ($ ? '' : $));
      onChangeSession(index, value);

      if (!(value > 0 && value < 11 && Number.isInteger(value))) setSessionErr('Ungültige Session');
    },
    [index, onChangeSession]
  );
  const handleChangeDuration = useCallback(
    (event) => {
      const value = Number(event.target.value);

      setDurationErr(($) => ($ ? '' : $));
      onChangeDuration(index, value);

      if (!(value > 0 && Number.isInteger(value))) setDurationErr('Ungültige Dauer');
    },
    [index, onChangeDuration]
  );

  const handleAction = useCallback(() => action(index), [action, index]);

  useEffect(() => {
    setDataError(($) => {
      if ((amountErr || dateErr || timeErr || sessionErr || durationErr) && !$) return true;
      if (!(amountErr && dateErr && timeErr && sessionErr && durationErr) && $) return false;

      return $;
    });
  }, [amountErr, dateErr, sessionErr, setDataError, timeErr, durationErr]);

  return (
    <Container key={id} $error={compareError}>
      <Wrapper>
        <Input
          type="number"
          value={session}
          min={1}
          max={10}
          onChange={handleChangeSession}
          width={widths[0]}
          error={Boolean(sessionErr)}
          disabled={readMode}
          $ml
        />
        <DateInput
          width={widths[1]}
          value={date}
          onChange={handleChangeDate}
          error={dateErr}
          onError={setDateErr}
          disabled={readMode}
          compareError={compareError}
        />
        <TimeInput
          width={widths[2]}
          value={date}
          onChange={handleChangeDate}
          error={timeErr}
          onError={setTimeErr}
          disabled={readMode}
          compareError={compareError}
        />
        <Input
          type="number"
          value={duration}
          min={1}
          onChange={handleChangeDuration}
          width={widths[3]}
          error={Boolean(durationErr)}
          disabled={readMode}
        />
        <Item width={widths[4]}>{tariffType}</Item>
        <Item width={widths[5]}>{code}</Item>
        <Auge width={widths[6]}>{bodyLocation}</Auge>
        <ItemWrap width={widths[7]}>
          <Input type="number" value={quantity} onChange={handleChangeQuantity} error={Boolean(amountErr)} disabled={readMode} />
          {!readMode && (
            <Button type="button" onClick={handleAction}>
              {icon}
            </Button>
          )}
        </ItemWrap>
      </Wrapper>
      <Text>{name}</Text>
    </Container>
  );
};

SelectedRow.defaultProps = { icon: null, quantity: 0, duration: 0 };
SelectedRow.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  tariffType: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  bodyLocation: PropTypes.string.isRequired,
  session: PropTypes.number.isRequired,
  quantity: PropTypes.number,
  duration: PropTypes.number,
  widths: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  icon: PropTypes.node,
  action: PropTypes.func.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onChangeSession: PropTypes.func.isRequired,
  onChangeDuration: PropTypes.func.isRequired,
  setDataError: PropTypes.func.isRequired,
  readMode: PropTypes.bool.isRequired,
  compareError: PropTypes.bool.isRequired,
};

export default SelectedRow;
