import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="177" height="177" viewBox="0 0 177 177">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M88.363.5c48.525 0 87.863 39.338 87.863 87.863 0 48.526-39.338 87.864-87.863 87.864S.5 136.89.5 88.363C.5 39.838 39.838.5 88.363.5"
      />
      <path
        stroke="#9D9D9C"
        d="M88.363.5c48.525 0 87.863 39.338 87.863 87.863 0 48.526-39.338 87.864-87.863 87.864S.5 136.89.5 88.363C.5 39.838 39.838.5 88.363.5z"
      />
      <path
        fill="#FFF"
        d="M88.363.5c48.525 0 87.863 39.338 87.863 87.863 0 48.526-39.338 87.864-87.863 87.864S.5 136.89.5 88.363C.5 39.838 39.838.5 88.363.5"
      />
      <path
        stroke="#9D9D9C"
        d="M88.363.5c48.525 0 87.863 39.338 87.863 87.863 0 48.526-39.338 87.864-87.863 87.864S.5 136.89.5 88.363C.5 39.838 39.838.5 88.363.5z"
      />
      <path
        fill="#FFF"
        d="M88.363 144.275c-30.883 0-55.91-25.028-55.91-55.911 0-30.883 25.027-55.911 55.91-55.911 15.435 0 29.41 6.259 39.535 16.376l22.592-22.593C134.59 10.335 112.62.5 88.363.5 39.837.5.5 39.838.5 88.364c0 48.526 39.337 87.863 87.863 87.863 24.264 0 46.226-9.834 62.135-25.728l-22.593-22.593c-10.132 10.11-24.107 16.369-39.542 16.369"
      />
      <path
        stroke="#9D9D9C"
        d="M88.363 144.275c-30.883 0-55.91-25.028-55.91-55.911 0-30.883 25.027-55.911 55.91-55.911 15.435 0 29.41 6.259 39.535 16.376l22.592-22.593C134.59 10.335 112.62.5 88.363.5 39.837.5.5 39.838.5 88.364c0 48.526 39.337 87.863 87.863 87.863 24.264 0 46.226-9.834 62.135-25.728l-22.593-22.593c-10.132 10.11-24.107 16.369-39.542 16.369z"
      />
      <path
        fill="#FFF"
        d="M144.274 88.364h-27.959c0-15.435-12.517-27.96-27.959-27.96-15.44 0-27.951 12.519-27.951 27.96 0 7.52 2.974 14.337 7.808 19.37l-.02.023 34.614 34.614.007.007c23.874-6.372 41.46-28.136 41.46-54.014"
      />
      <path
        stroke="#9D9D9C"
        d="M144.274 88.364h-27.959c0-15.435-12.517-27.96-27.959-27.96-15.44 0-27.951 12.519-27.951 27.96 0 7.52 2.974 14.337 7.808 19.37l-.02.023 34.614 34.614.007.007c23.874-6.372 41.46-28.136 41.46-54.014z"
      />
      <path
        fill="#FFF"
        d="M176.22 88.364h-31.952c0 25.878-17.595 47.642-41.454 54.014l24.674 24.674c28.886-14.394 48.732-44.222 48.732-78.688"
      />
      <path
        stroke="#9D9D9C"
        d="M176.22 88.364h-31.952c0 25.878-17.595 47.642-41.454 54.014l24.674 24.674c28.886-14.394 48.732-44.222 48.732-78.688z"
      />
      <path
        fill="#FFF"
        d="M160.251 80.374h-71.89a7.985 7.985 0 0 0-7.987 7.986 7.986 7.986 0 0 0 7.986 7.987h71.884a7.987 7.987 0 0 0 7.986-7.987c.007-4.41-3.568-7.986-7.979-7.986"
      />
      <path
        stroke="#9D9D9C"
        d="M160.251 80.374h-71.89a7.985 7.985 0 0 0-7.987 7.986 7.986 7.986 0 0 0 7.986 7.987h71.884a7.987 7.987 0 0 0 7.986-7.987c.007-4.41-3.568-7.986-7.979-7.986z"
      />
      <path
        fill="#FFF"
        d="M160.251 80.374h-71.89a7.985 7.985 0 0 0-7.987 7.986 7.986 7.986 0 0 0 7.986 7.987h71.884a7.987 7.987 0 0 0 7.986-7.987c.007-4.41-3.568-7.986-7.979-7.986"
      />
      <path
        stroke="#9D9D9C"
        d="M160.251 80.374h-71.89a7.985 7.985 0 0 0-7.987 7.986 7.986 7.986 0 0 0 7.986 7.987h71.884a7.987 7.987 0 0 0 7.986-7.987c.007-4.41-3.568-7.986-7.979-7.986z"
      />
      <path fill="#FFF" d="M16.473 82.374a5.99 5.99 0 1 1 0 11.979 5.99 5.99 0 0 1 0-11.979" />
      <path stroke="#9D9D9C" d="M16.473 82.374a5.99 5.99 0 1 1 0 11.979 5.99 5.99 0 0 1 0-11.979z" />
      <path fill="#FFF" d="M88.363 10.483a5.99 5.99 0 1 1 0 11.98 5.99 5.99 0 0 1 0-11.98" />
      <path stroke="#9D9D9C" d="M88.363 10.483a5.99 5.99 0 1 1 0 11.98 5.99 5.99 0 0 1 0-11.98z" />
      <path fill="#FFF" d="M88.363 154.257a5.99 5.99 0 1 1-.002 11.98 5.99 5.99 0 0 1 .002-11.98" />
      <path stroke="#9D9D9C" d="M88.363 154.257a5.99 5.99 0 1 1-.002 11.98 5.99 5.99 0 0 1 .002-11.98z" />
      <path fill="#FFF" d="M37.444 31.54a5.99 5.99 0 1 1 0 11.979 5.99 5.99 0 0 1 0-11.98" />
      <path stroke="#9D9D9C" d="M37.444 31.54a5.99 5.99 0 1 1 0 11.979 5.99 5.99 0 0 1 0-11.98z" />
      <path fill="#FFF" d="M37.444 133.293a5.99 5.99 0 1 1 0 11.98 5.99 5.99 0 0 1 0-11.98" />
      <path stroke="#9D9D9C" d="M37.444 133.293a5.99 5.99 0 1 1 0 11.98 5.99 5.99 0 0 1 0-11.98z" />
      <path fill="#FFF" d="M139.283 133.293a5.99 5.99 0 1 1 0 11.98 5.99 5.99 0 0 1 0-11.98" />
      <path stroke="#9D9D9C" d="M139.283 133.293a5.99 5.99 0 1 1 0 11.98 5.99 5.99 0 0 1 0-11.98z" />
    </g>
  </svg>
);
