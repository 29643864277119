import { object, string } from 'yup';

export const validationSchema = object().shape({
  token: string().required('Kein Benutzerkonto mit diesen Informationen vorhanden.'),
  password: string()
    .required('Passwort fehlt.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Ihr Passwort erfüllt die Sicherheitsanforderungen nicht. Bitte lesen Sie die Augenmobil-Richtlinien für sichere Passwörter.'
    ),
});
