import styled from 'styled-components';

export const Title = styled.h2`
  font-size: var(--font-size14);
  font-weight: var(--font-weight600);
  line-height: 1.36;
  letter-spacing: 0.08px;
  color: var(--color-grey6A);
  margin-left: 240px;
`;

export const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 15px 0 0 175px;
`;

export const Li = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  column-gap: 15px;
  width: 455px;
  justify-content: space-between;
`;

export const Span = styled.span`
  width: 390px;
  font-size: var(--font-size14);
  line-height: 1.36;
  letter-spacing: 0.08px;
  color: var(--color-grey6A);
`;

export const Form = styled.form`
  margin-bottom: 30px;
`;
