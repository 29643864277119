import styled from 'styled-components';

import Warning from 'components/Warning';

export const Message = styled(Warning)`
  margin: 30px 0 -20px;
`;

export const Button = styled.button`
  display: inline;
  background: none;
  border: none;
  font-size: var(--font-size13);
  font-weight: var(--font-weight600);
  line-height: 1.25;
  letter-spacing: 0.92px;
  text-decoration: underline;
  color: var(--color-orangeDC);
  cursor: pointer;
  &:disabled {
    opacity: 0.75;
    text-decoration: none;
    cursor: not-allowed;
  }
  @media (hover) {
    &:hover:enabled {
      color: var(--color-orangeF7);
    }
  }
`;
