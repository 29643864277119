import React from 'react';

import { Container, P, StyledLink } from './styles';

const WarnIE = () => (
  <Container>
    <P>Ihr Browser ist veraltet. Verwenden Sie einen</P>
    <StyledLink href="https://www.mozilla.org/de/firefox/new/" target="_blank">
      modernen Browser
    </StyledLink>
    <P>für mehr Sicherheit und ein besseres Benutzererlebnis.</P>
  </Container>
);

export default WarnIE;
