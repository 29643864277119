import styled from 'styled-components';

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px auto;
  width: 90%;
  max-width: 470px;
`;

export const LoginTitle = styled.h1`
  font-size: var(--font-size30);
  color: var(--color-grey6A);
  font-weight: var(--font-weight400);
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 20px;
`;

export const FormContainer = styled.form`
  margin: 40px 0px;
`;

export const LinkWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

export const Label = styled.p`
  font-size: var(--font-size14);
  font-weight: var(--font-weight600);
  line-height: 1.36;
  letter-spacing: 0.08px;
  color: var(--color-grey6A);
  margin: 20px 0;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Text = styled.p`
  width: 390px;
  font-size: var(--font-size14);
  line-height: 1.36;
  letter-spacing: 0.08px;
  color: var(--color-grey6A);
  margin-bottom: 14px;
`;
