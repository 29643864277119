import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { reducerTemplate } from 'store/utils';

import {
  ID,
  CURRENT,
  LIST,
  TARMEDS,
  ALL,
  PATIENT_TARMEDS,
  DASHBOARD,
  FILTERS,
  SORT,
  SORT_BY,
  SORT_DIR,
  PAGINATION,
  TARMEDS_PATIENT_ID,
  CURSOR,
  LIMIT,
  STATUS,
  SEARCH,
  INVOICES_FILTERS,
  TARMEDS_FILTERS,
  CANTON_FILTERS,
  YEAR_FILTERS,
  CODE,
  ASC,
} from '.';
import {
  SET_CURRENT_INVOICE,
  UPDATE_INVOICES,
  UPDATE_INVOICE,
  DELETE_INVOICE,
  RESET_INVOICES,
  UPDATE_ALL_TARMEDS,
  UPDATE_PATIENT_TARMEDS,
  UPDATE_DASHBOARD,
  UPDATE_SEARCH,
  UPDATE_STATUS,
  UPDATE_SORT,
  UPDATE_PAGINATION,
  UPDATE_PATIENT_ID,
  UPDATE_CANTON,
  UPDATE_YEAR,
  RESET_FILTERS,
} from './types';

const currentInvoiceReducer = handleActions(
  {
    [SET_CURRENT_INVOICE]: (_, { payload }) => payload || null,
  },
  null
);

const invoicesReducer = reducerTemplate(
  {
    id: ID,
    list: UPDATE_INVOICES,
    item: UPDATE_INVOICE,
    deleteItem: DELETE_INVOICE,
    reset: RESET_INVOICES,
  },
  {}
);

const initTarmeds = { [ALL]: [], [PATIENT_TARMEDS]: [] };

const tarmedsReducer = handleActions(
  {
    [UPDATE_ALL_TARMEDS]: (state, { payload }) => ({
      ...state,
      [ALL]: payload,
    }),
    [UPDATE_PATIENT_TARMEDS]: (state, { payload }) => ({
      ...state,
      [PATIENT_TARMEDS]: payload,
    }),
  },
  initTarmeds
);

const dashboardReducer = handleActions(
  {
    [UPDATE_DASHBOARD]: (_, { payload }) => payload,
  },
  []
);

const initFilter = {
  [STATUS]: ALL,
  [SEARCH]: '',
  [SORT]: {
    [SORT_BY]: '',
    [SORT_DIR]: '',
  },
  [PAGINATION]: {
    [CURSOR]: 0,
    [LIMIT]: 10,
  },
};
const initFilters = {
  [INVOICES_FILTERS]: initFilter,
  [TARMEDS_FILTERS]: {
    ...initFilter,
    [SORT]: {
      [SORT_BY]: CODE,
      [SORT_DIR]: ASC,
    },
  },
  [CANTON_FILTERS]: ALL,
  [YEAR_FILTERS]: new Date().getFullYear(),
  [TARMEDS_PATIENT_ID]: null,
};

const filtersReducer = handleActions(
  {
    [UPDATE_SEARCH]: (state, { payload }) => {
      const { kind, value } = payload;
      return {
        ...state,
        [kind]: {
          ...state[kind],
          [SEARCH]: value,
          [PAGINATION]: {
            ...state[kind][PAGINATION],
            [CURSOR]: 0,
          },
        },
      };
    },
    [UPDATE_STATUS]: (state, { payload }) => {
      const { kind, value } = payload;
      return {
        ...state,
        [kind]: {
          ...state[kind],
          [STATUS]: value,
          [PAGINATION]: {
            ...state[kind][PAGINATION],
            [CURSOR]: 0,
          },
        },
      };
    },
    [UPDATE_SORT]: (state, { payload }) => {
      const { kind, value } = payload;
      return {
        ...state,
        [kind]: {
          ...state[kind],
          [SORT]: value,
          [PAGINATION]: {
            ...state[kind][PAGINATION],
            [CURSOR]: 0,
          },
        },
      };
    },
    [UPDATE_PAGINATION]: (state, { payload }) => {
      const { kind, value } = payload;
      return {
        ...state,
        [kind]: {
          ...state[kind],
          [PAGINATION]: {
            ...state[kind][PAGINATION],
            ...value,
          },
        },
      };
    },
    [UPDATE_PATIENT_ID]: (state, { payload }) => ({ ...state, [TARMEDS_PATIENT_ID]: payload }),
    [UPDATE_CANTON]: (state, { payload }) => ({
      ...state,
      [CANTON_FILTERS]: payload,
    }),
    [UPDATE_YEAR]: (state, { payload }) => ({
      ...state,
      [YEAR_FILTERS]: payload,
    }),
    [RESET_FILTERS]: () => initFilters,
  },
  initFilters
);

export default combineReducers({
  [CURRENT]: currentInvoiceReducer,
  [LIST]: invoicesReducer,
  [TARMEDS]: tarmedsReducer,
  [DASHBOARD]: dashboardReducer,
  [FILTERS]: filtersReducer,
});
