import styled from 'styled-components';

import Btn from 'components/Button';
import TA from 'components/TextAreaNew';

export const Container = styled.div`
  position: relative;
  padding: 10px 30px 20px;
  border-bottom: 1px solid var(--color-grey97);
  &:last-child {
    border-bottom: none;
  }
`;

export const HeadWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const MenuBtn = styled(Btn)`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 8px;
`;

export const TextArea = styled(TA)`
  width: 100%;
  min-height: 120px;
  margin-bottom: 8px;
  &:focus {
    box-shadow: var(--shadow-focus);
  }
`;

export const Message = styled.div`
  width: 100%;
  min-height: 120px;
  border-radius: var(--border-radius8);
  background-color: var(--color-greyEC);
  font-size: var(--font-size18);
  color: var(--color-grey6A);
  line-height: 1.22;
  letter-spacing: 0.5px;
  padding: 8px 12px;
  margin-bottom: 8px;
  white-space: pre-line;
`;

export const EmptyMessage = styled.p`
  width: 100%;
  font-size: var(--font-size18);
  color: var(--color-grey6A);
  line-height: 1.22;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
`;

export const Text = styled.p`
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  color: #bfbfbf;
`;
