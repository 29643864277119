import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { STATUSES } from '../../consts';

import { Container, Text } from './styles';

const Row = ({ id, widths, date, days, amountPatients, organization, editor, status }) => {
  const { label, color } = useMemo(() => STATUSES[status] || {}, [status]);

  return (
    <Container to={`/planning/${id}`}>
      <Text $width={widths[0]} $align="center">
        {date}
      </Text>
      <Text $width={widths[1]} $align="center">
        {days}
      </Text>
      <Text $width={widths[2]} $align="center">
        {amountPatients}
      </Text>
      <Text $width={widths[3]}>{organization}</Text>
      <Text $width={widths[4]} $fontSize={13}>
        {editor}
      </Text>
      <Text $width={widths[5]} $fontSize={10} $color={color}>
        {label || '-'}
      </Text>
    </Container>
  );
};

Row.defaultProps = { organization: '–', editor: '–' };

Row.propTypes = {
  id: PropTypes.string.isRequired,
  widths: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  date: PropTypes.string.isRequired,
  days: PropTypes.number.isRequired,
  amountPatients: PropTypes.number.isRequired,
  organization: PropTypes.string,
  editor: PropTypes.string,
  status: PropTypes.number.isRequired,
};

export default Row;
