import { takeEvery, takeLatest, debounce, call, put, all } from 'redux-saga/effects';
import { prop, omit, filter } from 'ramda';

import api from 'api';
import { withAlert } from 'store/alerts';

import { ID, MESSAGE, ATTACHMENTS, META, OWNER, EDITOR, SUB_PATIENT, EXPAND, FIELDS } from '.';
import { updateInfoItems, updateInfoItem, deleteInfoItem, updatePagination } from './actions';
import { FETCH_INFO_ITEMS, FETCH_INFO_ITEM, CREATE_INFO_ITEM, SAVE_INFO_ITEM, REMOVE_INFO_ITEM, UPLOAD_MEDIA } from './types';

const query = {
  [FIELDS]: [MESSAGE, ATTACHMENTS, META, SUB_PATIENT],
  [EXPAND]: [`${META}.${OWNER}`, `${META}.${EDITOR}`],
};

function* fetchInfoItems({ payload }) {
  const data = yield call(api.getInfoItems, payload);
  yield put(updateInfoItems(data.data));
  yield put(updatePagination(omit(['data'], data)));
}

function* fetchInfoItem({ payload }) {
  const data = yield call(api.getInfoItem, payload, query);
  yield put(updateInfoItem(data));
}

function* createInfoItem({ payload }) {
  const data = yield call(api.postInfoItem, payload, query);
  yield put(updateInfoItem(data));
}

function* saveInfoItem({ payload }) {
  const data = yield call(api.patchInfoItem, prop(ID, payload), payload, query);
  yield put(updateInfoItem(data));
}

function* removeInfoItem({ payload }) {
  const data = yield call(api.deleteInfoItem, payload);
  yield put(deleteInfoItem(data));
}

function* uploadMedia({ payload }) {
  const data = yield all(payload.map((file) => call(api.uploadFile, file)));

  return { success: filter(prop('filename'), data) };
}

export default function* watchInfoItems() {
  yield takeLatest(FETCH_INFO_ITEMS, withAlert(fetchInfoItems));
  yield takeLatest(FETCH_INFO_ITEM, withAlert(fetchInfoItem));
  yield takeEvery(CREATE_INFO_ITEM, withAlert(createInfoItem));
  yield debounce(500, SAVE_INFO_ITEM, withAlert(saveInfoItem));
  yield takeEvery(REMOVE_INFO_ITEM, withAlert(removeInfoItem));
  yield takeEvery(UPLOAD_MEDIA, withAlert(uploadMedia));
}
