import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import CrossIcon from 'assets/svg-icons/Close';

import { Container, Select, InputWrap, Input, Button } from './styles';

const TextInput = ({ selectValue, options, onSelect, selectPlaceholder, value, onChange, disabled, placeholder, className }) => {
  const ref = useRef(null);

  const handleSelect = useCallback(({ currentTarget }) => onSelect(currentTarget.value), [onSelect]);

  const handleChange = useCallback(({ currentTarget }) => onChange(currentTarget.value), [onChange]);
  const handleClear = useCallback(() => {
    if (disabled || !ref.current) return;

    const lastValue = ref.current.value;
    ref.current.value = '';
    const tracker = ref.current._valueTracker; // eslint-disable-line no-underscore-dangle

    if (tracker) tracker.setValue(lastValue);

    ref.current.dispatchEvent(new Event('change', { bubbles: true }));
  }, [disabled]);

  return (
    <Container className={className}>
      <Select value={selectValue} disabled={disabled} onChange={handleSelect}>
        <option value="" label={selectPlaceholder} disabled>
          {selectPlaceholder}
        </option>
        {options.map(({ id, title }) => (
          <option key={id} value={id} label={title}>
            {title}
          </option>
        ))}
      </Select>
      <InputWrap>
        <Input ref={ref} type="text" value={value} onChange={handleChange} disabled={disabled} placeholder={placeholder} />
        <Button type="button" aria-label="Clear search" onClick={handleClear} $isVisible={Boolean(value) && !disabled}>
          <CrossIcon />
        </Button>
      </InputWrap>
    </Container>
  );
};

TextInput.defaultProps = {
  selectValue: '',
  options: [],
  onSelect: () => null,
  selectPlaceholder: 'Bitte auswählen',
  value: '',
  onChange: () => null,
  disabled: false,
  placeholder: '',
  className: null,
};
TextInput.propTypes = {
  selectValue: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired
  ),
  onSelect: PropTypes.func,
  selectPlaceholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default TextInput;
