import styled from 'styled-components';

import Button from 'components/Button';
import AnimateBox from 'components/AnimateBox';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ConfirmContainer = styled(AnimateBox)`
  display: flex;
  margin: 5px 0;
  align-items: center;
`;

export const Label = styled.p`
  font-size: var(--font-size14);
  font-weight: var(--font-weight700);
  letter-spacing: 0.5px;
  color: var(--color-orangeDC);
  margin-right: 35px;
`;

export const NoButton = styled(Button)`
  margin: 5px 0 5px 10px;
  border: solid 1px var(--color-orangeF5);
  background-color: var(--color-orangeFE);
`;
