import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { pipe, filter, complement, propEq } from 'ramda';

import api from 'api';
import { useAsyncState } from 'utils/useAsyncState';
import { useMedicalHistoryActions } from 'store/medicalHistory';
import ImagesReview from 'components/ImagesReview';

import SearchableBox from './SearchableBox';
import Item from './Item';
import { Container, Label, ReadModeWrapper, Title, NoDocuments } from './styles';

const MultiImage = ({ values, updateValues, label, readMode, isSearchableMedia }) => {
  const { updateSearchableMedia, deleteSearchableMedia } = useMedicalHistoryActions();
  const [imagePath, setImagePath] = useAsyncState();
  const [searchId, setSearchId] = useState();

  const openFile = useCallback(
    async (mimetype, url, offline) => {
      if (mimetype === 'application/pdf') {
        const blobUrl = await api.loadFile({ url, mimetype, offline });

        window.open(blobUrl, '_blank');
      } else {
        setImagePath(url);
      }
    },
    [setImagePath]
  );
  const closeFile = useCallback(() => setImagePath(), [setImagePath]);

  const updateFileName = (path, id) => (description) => {
    if (!path) return;
    const result = values.map((item) => (path.includes(item.path) ? { ...item, description } : item));

    updateValues(result);
    if (isSearchableMedia && id) {
      updateSearchableMedia({ id, description });
    }
  };

  const deleteFile = (id, path) => {
    pipe(filter(complement(propEq(path, 'path'))), updateValues)(values);
    if (isSearchableMedia && id) {
      deleteSearchableMedia(id);
    }
  };

  if (readMode && values.length === 0) {
    return (
      <ReadModeWrapper>
        {label && <Title>{label}</Title>}
        <NoDocuments>Noch keine Dokumente vorhanden</NoDocuments>
      </ReadModeWrapper>
    );
  }

  return (
    <Container>
      {label && <Label>{label}</Label>}
      {values.map((data) => (
        <Item
          key={data.path}
          {...data}
          openFile={openFile}
          updateFileName={updateFileName}
          deleteFile={deleteFile}
          isSearchableMedia={isSearchableMedia}
          openSearchableMedia={setSearchId}
          readMode={readMode}
        />
      ))}
      {imagePath && <ImagesReview currentImage={imagePath} images={values} onClose={closeFile} />}
      {searchId && <SearchableBox id={searchId} closeBox={setSearchId} />}
    </Container>
  );
};

MultiImage.defaultProps = {
  readMode: false,
  label: null,
  updateValues: () => null,
  isSearchableMedia: false,
};

MultiImage.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      destination: PropTypes.string,
      path: PropTypes.string,
      previewname: PropTypes.string,
      originalname: PropTypes.string,
      mimetype: PropTypes.string,
    })
  ).isRequired,
  updateValues: PropTypes.func,
  label: PropTypes.string,
  readMode: PropTypes.bool,
  isSearchableMedia: PropTypes.bool,
};

export default MultiImage;
