import styled from 'styled-components';

export const Message = styled.p`
  background-color: var(--color-greyEC);
  border-radius: var(--border-radius8);
  font-size: var(--font-size18);
  font-weight: var(--font-weight400);
  line-height: 24px;
  color: var(--color-grey6A);
  padding: 8px 10px;
`;
