import Icon1 from 'assets/svg-icons/Password1';
import Icon2 from 'assets/svg-icons/Password2';
import Icon3 from 'assets/svg-icons/Password3';

export const guidelines = [
  {
    icon: Icon1,
    text: 'Verwende stets ein sicheres Passwort. Dein Passwort muss aus acht oder mehr Zeichen bestehen. Augenmobil empfiehlt, dass dein Passwort Gross- und Kleinbuchstaben sowie mindestens eine Zahl enthält.',
  },
  {
    icon: Icon2,
    text: 'Verwende das Passwort deines Augenmobil-Kontos nicht für andere Onlinekonten.',
  },
  {
    icon: Icon3,
    text: 'Ändere dein Passwort regelmässig und verwende kein Passwort zweimal. Augenmobil empfiehlt, das Passwort jährlich zu ändern.',
  },
];
