import styled from 'styled-components';

import Msg from 'components/Message';

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--color-grey97);
  border-radius: var(--border-radius8);
  overflow: auto;
  width: 100%;
  padding: 20px 0;
`;

export const Wrapper = styled.div`
  flex: 1 1 auto;
  position: relative;
  max-width: 920px;
  margin-top: 50px;
`;

export const Text = styled.p`
  font-size: var(--font-size18);
  line-height: 1.56;
  letter-spacing: 0.5px;
  color: var(--color-grey97);
  min-height: 1em;
`;

export const Highlight = styled.span`
  background-color: var(--color-orangeFE);
  border-radius: var(--border-radius8);
  padding: 2px;
`;

export const Message = styled(Msg)`
  text-align: center;
`;

export const Cover = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
`;
