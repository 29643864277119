import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { when, complement, is, not } from 'ramda';
import PropTypes from 'prop-types';

import Close from 'assets/svg-icons/SmallClose';
import Search from 'assets/svg-icons/SmallSearch';
import OfflineIcon from 'assets/svg-icons/OfflineIcon';
import Warn from 'components/WarnDeleting';
import { useAsyncState } from 'utils/useAsyncState';
import { parseDateWithTime } from 'utils';
import api from 'api';
import { ID } from 'api/consts';
import { getOriginName } from '../utils';

import Spinner from './Spinner';
import { Container, Button, ButtonWrap, SmallBtn, Input, DateStamp, IconWrap } from './styles';

const Item = ({
  [ID]: id,
  destination,
  path,
  previewname: previewName,
  originalname: originalName,
  mimetype,
  description,
  status,
  openFile,
  updateFileName,
  deleteFile,
  isSearchableMedia,
  openSearchableMedia,
  readMode,
  meta,
  offline,
}) => {
  const [isShowWarn, setIsShowWarn] = useState();
  const [imageUrl, setImageUrl] = useAsyncState('');

  const fileName = useMemo(
    () => when(complement(is(String)), () => getOriginName(originalName))(description),
    [originalName, description]
  );

  const handleShowWarn = useCallback(() => setIsShowWarn(not), []);
  const handleOpen = useCallback(() => openFile(mimetype, path, offline), [mimetype, openFile, path, offline]);
  const handleDelete = useCallback(() => {
    deleteFile(id, path);
    if (offline) api.deleteFileFromCache(path);
  }, [deleteFile, id, offline, path]);
  const handleUpdate = useCallback((text) => updateFileName(path, id)(text), [updateFileName, path, id]);
  const openSearchMedia = useCallback(() => openSearchableMedia(id), [openSearchableMedia, id]);

  const getImageUrl = useCallback(async () => {
    const blobUrl = await api.loadFile({
      url: offline ? path : `${destination}/${previewName}`,
      mimetype,
      offline,
    });

    setImageUrl((prev) => {
      if (prev) (window.URL || window.webkitURL).revokeObjectURL(prev);

      return blobUrl;
    });
  }, [destination, mimetype, offline, path, previewName, setImageUrl]);

  const onlyRead = useMemo(() => Boolean(readMode || (offline && !imageUrl)), [imageUrl, offline, readMode]);

  useEffect(() => {
    getImageUrl();
  }, [getImageUrl]);

  useEffect(
    () => () => {
      if (imageUrl) (window.URL || window.webkitURL).revokeObjectURL(imageUrl);
    },
    [imageUrl]
  );

  return (
    <Container key={path}>
      <Button type="button" src={imageUrl} onClick={handleOpen} disabled={!imageUrl} />
      <Input value={fileName} handleChangeText={handleUpdate} readMode={onlyRead} mb={0} />
      <ButtonWrap>
        {onlyRead || (
          <SmallBtn type="button" onClick={handleShowWarn}>
            <Close />
          </SmallBtn>
        )}
        {isSearchableMedia && (
          <SmallBtn type="button" onClick={openSearchMedia} disabled={status !== 1}>
            {status === 0 ? <Spinner /> : <Search />}
          </SmallBtn>
        )}
        {offline && (
          <IconWrap>
            <OfflineIcon />
          </IconWrap>
        )}
      </ButtonWrap>
      {meta.created && <DateStamp>{parseDateWithTime(meta.created)}</DateStamp>}
      {isShowWarn && (
        <Warn
          message="Wollen Sie die Datei wirklich löschen?"
          showActon={handleShowWarn}
          deleteAction={handleDelete}
          smallWidth
        />
      )}
    </Container>
  );
};

Item.defaultProps = {
  [ID]: null,
  description: null,
  status: null,
  isSearchableMedia: false,
  readMode: false,
  meta: {},
  offline: false,
};

Item.propTypes = {
  [ID]: PropTypes.string,
  destination: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  previewname: PropTypes.string.isRequired,
  originalname: PropTypes.string.isRequired,
  mimetype: PropTypes.string.isRequired,
  description: PropTypes.string,
  status: PropTypes.number,
  openFile: PropTypes.func.isRequired,
  updateFileName: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  isSearchableMedia: PropTypes.bool,
  openSearchableMedia: PropTypes.func.isRequired,
  readMode: PropTypes.bool,
  meta: PropTypes.shape({
    created: PropTypes.string,
  }),
  offline: PropTypes.bool,
};

export default Item;
