import styled from 'styled-components';

import Msg from 'components/Message';

export const Title = styled.h1`
  font-size: var(--font-size15);
  color: var(--color-grey6A);
  border-top: 1px solid var(--color-grey6A);
  padding-top: 5px;
  margin-bottom: 30px;
`;

export const Container = styled.div`
  width: 100%;
`;

export const THead = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 15px;
`;

export const TBody = styled.div`
  position: relative;
  width: 100%;
`;

export const TFoot = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;
`;

export const PageButton = styled.button.attrs(() => ({ type: 'button' }))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: var(--color-greyEC);
  border-radius: var(--border-radius8);
  &:last-child {
    transform: rotate(180deg);
    margin-left: 10px;
  }
  &:hover {
    ${({ disabled }) => !disabled && 'background-color: var(--color-greyEC);'}
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export const PaginationInfo = styled.span`
  font-size: var(--font-size13);
  color: var(--color-grey6A);
  margin-right: 20px;
`;

export const Message = styled(Msg)`
  text-align: center;
`;

export const Cover = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-white);
  opacity: 0.75;
`;
