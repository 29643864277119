import styled from 'styled-components';

import Btn from 'components/Button';
import WarnDef from 'components/WarnDeleting';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 30px;
`;

export const Button = styled(Btn)`
  align-self: flex-end;
  height: 30px;
  width: auto;
  padding: 0 15px;
`;

export const Warn = styled(WarnDef)`
  position: static;
  padding: 0;
`;
