import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  SET_CURRENT,
  RESET_CURRENT,
  SET_SCHEDULE_UPDATES,
  SET_NEW_REGISTRATION,
  RESET_UPDATES_PROP,
  RESET_UPDATES,
  UPDATE_STATUS,
  UPDATE_SEARCH,
  UPDATE_SORT,
  UPDATE_PAGINATION,
  RESET_FILTERS,
  CREATE_SCHEDULE,
  FETCH_SCHEDULES,
  FETCH_SCHEDULE,
  FETCH_SCHEDULE_UNSUBSCRIBERS,
  CREATE_SCHEDULE_XML,
  SAVE_SCHEDULE_XML,
  SAVE_SCHEDULE,
  CREATE_REGISTRATION,
  SAVE_REGISTRATION,
  REMOVE_PATIENT_FROM_SCHEDULE,
  EXPORT_SCHEDULE,
  GET_SCHEDULE_LABELS,
  GET_SCHEDULE_COVER_LETTERS,
  GET_SCHEDULE_CONSENTS,
  FETCH_SCHEDULE_FORMS,
  CREATE_SCHEDULE_FORMS,
  UPLOAD_SCHEDULE_FORM_MEDIA,
  SAVE_SCHEDULE_FORM_MEDIAS,
  SAVE_SCHEDULE_FORM_MEDIA,
  REMOVE_SCHEDULE_FORM_MEDIA,
} from './types';

export const setCurrent = createAction(SET_CURRENT);
export const resetCurrent = createAction(RESET_CURRENT);
export const setScheduleUpdates = createAction(SET_SCHEDULE_UPDATES);
export const setNewRegistration = createAction(SET_NEW_REGISTRATION);
export const resetUpdatesProp = createAction(RESET_UPDATES_PROP);
export const resetUpdates = createAction(RESET_UPDATES);
export const updateStatus = createAction(UPDATE_STATUS);
export const updateSearch = createAction(UPDATE_SEARCH);
export const updateSort = createAction(UPDATE_SORT);
export const updatePagination = createAction(UPDATE_PAGINATION);
export const resetFilters = createAction(RESET_FILTERS);

export const createSchedule = createAlertAction(CREATE_SCHEDULE);
export const fetchSchedules = createAlertAction(FETCH_SCHEDULES);
export const fetchSchedule = createAlertAction(FETCH_SCHEDULE);
export const fetchScheduleUnsubscribers = createAlertAction(FETCH_SCHEDULE_UNSUBSCRIBERS);
export const createScheduleXML = createAlertAction(CREATE_SCHEDULE_XML);
export const saveScheduleXML = createAlertAction(SAVE_SCHEDULE_XML);
export const saveSchedule = createAlertAction(SAVE_SCHEDULE);
export const createRegistration = createAlertAction(CREATE_REGISTRATION);
export const saveRegistration = createAlertAction(SAVE_REGISTRATION);
export const removePatientFromSchedule = createAlertAction(REMOVE_PATIENT_FROM_SCHEDULE);
export const exportSchedule = createAlertAction(EXPORT_SCHEDULE);
export const getScheduleLabels = createAlertAction(GET_SCHEDULE_LABELS);
export const getScheduleCoverLetters = createAlertAction(GET_SCHEDULE_COVER_LETTERS);
export const getScheduleConsents = createAlertAction(GET_SCHEDULE_CONSENTS);
export const fetchScheduleForms = createAlertAction(FETCH_SCHEDULE_FORMS);
export const createScheduleForms = createAlertAction(CREATE_SCHEDULE_FORMS);
export const uploadScheduleFormMedia = createAlertAction(UPLOAD_SCHEDULE_FORM_MEDIA);
export const saveScheduleFormMedias = createAlertAction(SAVE_SCHEDULE_FORM_MEDIAS);
export const saveScheduleFormMedia = createAlertAction(SAVE_SCHEDULE_FORM_MEDIA);
export const removeScheduleFormMedia = createAlertAction(REMOVE_SCHEDULE_FORM_MEDIA);
