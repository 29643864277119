import styled from 'styled-components';

export const Container = styled.form`
  display: grid;
  grid-template-columns: 1fr 6px 1fr;
  align-items: center;
  position: relative;
  width: 100%;
  height: 40px;
  border: ${({ error }) => (error ? '2px solid var(--color-orangeF5)' : '1px solid var(--color-grey97)')};
  border-radius: var(--border-radius8);
  background-color: transparent;
  font-size: var(--font-size20);
  color: ${({ error }) => (error ? 'var(--color-orangeF5)' : 'var(--color-grey6A)')};
  padding: 0 8px;
`;

export const Placeholder = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 6px;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  left: 0;
  top: -7px;
  color: inherit;
  padding: 0 8px;
`;

export const PlaceholderText = styled.p`
  width: fit-content;
  background-color: ${({ compareError }) => (compareError ? '#fef3e4' : 'var(--color-white)')};
  justify-self: center;
  font-size: var(--font-size10);
  color: inherit;
  padding: 0 3px;
`;

export const Input = styled.input`
  height: 100%;
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: inherit;
  color: inherit;
  outline: none;
  text-align: center;
  appearance: none;
  &::placeholder {
    text-align: center;
    letter-spacing: 2px;
  }
`;

export const Divider = styled.p`
  display: flex;
  justify-content: center;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  &::after {
    content: ':';
  }
`;
