import { createAction } from 'redux-actions';

import { createAlertAction } from 'store/alerts';

import {
  UPDATE_DIAGNOSES,
  UPDATE_DIAGNOSIS,
  RESET_DIAGNOSES,
  UPDATE_DIAGNOSIS_REPORTS,
  UPDATE_DIAGNOSIS_REPORT,
  RESET_DIAGNOSIS_REPORTS,
  UPDATE_DIAGNOSIS_PREVIEW_REPORT,
  RESET_DIAGNOSIS_PREVIEW_REPORT,
  UPDATE_DIAGNOSIS_CODES,
  FETCH_DIAGNOSIS_CODES,
  FETCH_DIAGNOSES,
  FETCH_DIAGNOSES_FOR_EXAMINATION,
  SAVE_DIAGNOSIS,
  CREATE_DIAGNOSIS_REPORTS,
  CREATE_DIAGNOSIS_PREVIEW_REPORT,
  WITHDRAW_DIAGNOSIS,
  TAKE_OVER_DIAGNOSIS,
} from './types';

export const updateDiagnoses = createAction(UPDATE_DIAGNOSES);
export const updateDiagnosis = createAction(UPDATE_DIAGNOSIS);
export const resetDiagnoses = createAction(RESET_DIAGNOSES);

export const updateDiagnosisReports = createAction(UPDATE_DIAGNOSIS_REPORTS);
export const updateDiagnosisReport = createAction(UPDATE_DIAGNOSIS_REPORT);
export const resetDiagnosisReports = createAction(RESET_DIAGNOSIS_REPORTS);

export const updateDiagnosisPreviewReport = createAction(UPDATE_DIAGNOSIS_PREVIEW_REPORT);
export const resetDiagnosisPreviewReport = createAction(RESET_DIAGNOSIS_PREVIEW_REPORT);

export const updateDiagnosisCodes = createAction(UPDATE_DIAGNOSIS_CODES);

export const fetchDiagnosisCodes = createAlertAction(FETCH_DIAGNOSIS_CODES);
export const fetchDiagnoses = createAlertAction(FETCH_DIAGNOSES);
export const fetchDiagnosesForExamination = createAlertAction(FETCH_DIAGNOSES_FOR_EXAMINATION);
export const saveDiagnosis = createAlertAction(SAVE_DIAGNOSIS);
export const createDiagnosisReports = createAlertAction(CREATE_DIAGNOSIS_REPORTS);
export const createDiagnosisPreviewReport = createAlertAction(CREATE_DIAGNOSIS_PREVIEW_REPORT);
export const withdrawDiagnosis = createAlertAction(WITHDRAW_DIAGNOSIS);
export const takeOverDiagnosis = createAlertAction(TAKE_OVER_DIAGNOSIS);
