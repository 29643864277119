import React, { memo, useCallback } from 'react';
import { equals, pick } from 'ramda';
import PropTypes from 'prop-types';

import ArrowRight from 'assets/svg-icons/ArrowRight';

import { SurveySection, Right, Left, AnswerContainer, AnswerItem } from './styles';

const Survey = ({ options, activeQuestion, answer, setActiveQuestion, onChange }) => {
  const index = options.findIndex(({ prop }) => prop === activeQuestion);
  const activeIndex = index >= 0 ? index : 0;
  const current = options[activeIndex];

  const handleQuestionClick = useCallback(
    (step) => () => {
      setActiveQuestion(options[activeIndex + step].prop);
    },
    [activeIndex, options, setActiveQuestion]
  );

  const handleAnswerClick = useCallback(
    (item) => () => {
      onChange(item);
      setTimeout(() => {
        if (activeIndex < options.length - 1) {
          handleQuestionClick(1)();
        }
      }, 300);
    },
    [activeIndex, handleQuestionClick, onChange, options.length]
  );

  return (
    <div>
      <SurveySection>
        <Left type="button" disabled={activeIndex === 0} onClick={handleQuestionClick(-1)}>
          <ArrowRight />
        </Left>
        <AnswerContainer>
          {current.value.map((item, itemIndex) => (
            <AnswerItem
              key={item}
              type="button"
              disabled={answer === item}
              lastInRow={(itemIndex + 1) % 4 === 0}
              lastRow={itemIndex >= 4}
              onClick={handleAnswerClick(item)}
            >
              {item}
            </AnswerItem>
          ))}
        </AnswerContainer>
        <Right type="button" disabled={activeIndex === options.length - 1} onClick={handleQuestionClick(1)}>
          <ArrowRight />
        </Right>
      </SurveySection>
    </div>
  );
};

Survey.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      prop: PropTypes.string.isRequired,
      value: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }).isRequired
  ).isRequired,
  activeQuestion: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  setActiveQuestion: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(Survey, (prev, next) => {
  const props = ['activeQuestion', 'answer'];

  return equals(pick(props, prev), pick(props, next));
});
