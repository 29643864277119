import { string, addMethod } from 'yup';
import { isEmail, isURL } from 'validator';

function customEmail(message = 'Not valid') {
  return this.test('customEmail', message, (value) => (value && typeof value === 'string' ? isEmail(value) : true));
}
function customURL(message = 'Not valid') {
  return this.test('customURL', message, (value) => (value && typeof value === 'string' ? isURL(value) : true));
}

addMethod(string, 'customEmail', customEmail);
addMethod(string, 'customURL', customURL);
