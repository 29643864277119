import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import { Container, ConfirmContainer, Label, NoButton } from './styles';

const Confirm = ({ title, question, onClick, width, height, className, disabled }) => {
  const [isOpen, setIsOpen] = useState();
  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClickYes = useCallback(() => {
    onClick();
    setIsOpen(false);
  }, [onClick]);
  const handleClickNo = useCallback(() => setIsOpen(false), []);

  return (
    <Container className={className}>
      <Button type="button" color="warning" height={height} width={width} onClick={handleOpen}>
        {title}
      </Button>
      <ConfirmContainer isOpen={isOpen}>
        <Label>{question}</Label>
        <Button
          type="button"
          height={height}
          width={`calc(${width} / 2 - 5px)`}
          mt={5}
          mb={5}
          onClick={handleClickYes}
          disabled={disabled}
        >
          JA
        </Button>
        <NoButton type="button" color="warning" height={height} width={`calc(${width} / 2 - 5px)`} onClick={handleClickNo}>
          Nein
        </NoButton>
      </ConfirmContainer>
    </Container>
  );
};

Confirm.defaultProps = {
  width: '180px',
  height: '40px',
  className: '',
  question: 'Sind Sie sicher?',
  disabled: false,
};

Confirm.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  question: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default Confirm;
