import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
    <g fill="none" fillRule="evenodd">
      <circle cx="13" cy="13" r="13" fill="#65D8A2" />
      <path stroke="#FFF" strokeWidth="1.5" d="M7 12.842l4.412 5.11L19 8.056" />
    </g>
  </svg>
);
