import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  width: 100%;
  &:before {
    content: '${({ $type }) => ($type === 'client' ? 'You' : 'medignition Index')}:';
    font-size: var(--font-size15);
    font-weight: var(--font-weight600);
    color: var(${({ $type }) => ($type === 'error' ? '--color-redED' : '--color-grey6A')});
  }
`;

export const Text = styled.p`
  font-size: var(--font-size15);
  font-weight: var(--font-weight400);
  color: var(${({ $isError }) => ($isError ? '--color-redED' : '--color-grey6A')});
`;
