/* eslint-disable react/no-array-index-key */
import React, { memo, useCallback, useMemo } from 'react';
import { equals, pick } from 'ramda';
import PropTypes from 'prop-types';

import Checkbox from 'components/CheckboxNew';

import { Container, Inside } from './styles';

const Checkboxes = ({ columns, items, allItems, onChange }) => {
  const sections = useMemo(() => {
    const height = Math.ceil(allItems.length / columns);

    return allItems.reduce((acc, item, index) => {
      const num = Math.floor(index / height);

      if (!Array.isArray(acc[num])) {
        acc[num] = [];
      }
      acc[num].push(item);

      return acc;
    }, []);
  }, [allItems, columns]);

  const handleClick = useCallback(
    (selected, item) => () => {
      if (selected) {
        onChange(items.filter((val) => val !== item));
      } else {
        onChange([...items, item]);
      }
    },
    [items, onChange]
  );

  return (
    <Container>
      {sections.map((section, i) => (
        <Inside key={i} columns={columns}>
          {section.map((item) => {
            const selected = items.includes(item);
            return <Checkbox key={item} label={item} selected={selected} onClick={handleClick(selected, item)} />;
          })}
        </Inside>
      ))}
    </Container>
  );
};

Checkboxes.defaultProps = {
  columns: 1,
};

Checkboxes.propTypes = {
  columns: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  allItems: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(Checkboxes, (prev, next) => {
  const props = ['items'];

  return equals(pick(props, prev), pick(props, next));
});
