import { prop } from 'ramda';

import { GET, TOKEN, TOKEN_2FA, DEFAULT_ERROR, OWN_ERR_HANDLER } from './consts';
import { notify } from './bugsnagOfflineMode';

export const getToken = () => prop(TOKEN_2FA, sessionStorage) || prop(TOKEN, sessionStorage);

const requestHeaders = () => ({
  'Content-Type': 'application/json',
  AUTHORIZATION: `Bearer ${getToken()}`,
});

const handleOptions = (data = {}) => {
  const { method = GET, headers = requestHeaders(), body, file } = data;

  const options = {
    method,
    headers,
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  if (file) {
    options.body = file;
  }

  return options;
};

export const wrapperRequest = async ({ url, options, downloadFile }) => {
  let response;
  const isOwnErrHandler = options && options[OWN_ERR_HANDLER];

  try {
    response = await fetch(url, handleOptions(options));
  } catch (e) {
    await notify(`${options?.method || GET}/${url}`);

    const error = new Error('Keine Internetverbindung vorhanden.');

    error.type = isOwnErrHandler ? '' : 'main';

    return Promise.reject(error);
  }
  const { status, headers } = response;

  if (status === 401) {
    const data = await response.text();
    window.location.replace('/');

    return Promise.reject(data.message);
  }

  if ([400, 403, 404, 415, 429].includes(status)) {
    const data = await response.json();
    const error = new Error(data?.error || DEFAULT_ERROR);
    error.type = isOwnErrHandler ? '' : 'app';

    if (data?.errors) {
      error.errors = data.errors;
    }

    return Promise.reject(error);
  }

  if (status < 200 || status >= 300) {
    const error = new Error(DEFAULT_ERROR);
    error.type = isOwnErrHandler ? '' : 'app';

    return Promise.reject(error);
  }

  if (!downloadFile) return response.json();

  const blob = await response.blob();
  const name = (headers.get('Content-Disposition') || '').split('filename=')[1];
  const URL = window.URL || window.webkitURL;
  const href = URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = href;
  a.target = '_blank';
  a.rel = 'noopener noreferrer';

  if (name) a.download = name;

  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(href);
  document.body.removeChild(a);

  return true;
};
