import Personal from './Personal';
import Security from './Security';

export const routes = {
  '/profile': {
    label: 'Mein Profil',
    content: Personal,
    url: '/profile',
  },
  '/profile/authentication': {
    label: 'Sicherheit',
    content: Security,
    url: '/profile/authentication',
  },
};
