import { applySpec, prop, pipe, propOr } from 'ramda';

import { ID, PUBLIC_ID, FIRST_NAME, LAST_NAME, BIRTH } from 'store/patients';
import { parseBirthday } from 'utils';

export const tableHead = [
  {
    id: PUBLIC_ID,
    content: 'ID',
    width: '15%',
  },
  {
    id: FIRST_NAME,
    content: 'Vorname',
    width: '30%',
  },
  {
    id: LAST_NAME,
    content: 'Nachname',
    width: '30%',
  },
  {
    id: BIRTH,
    content: 'Geburtstag',
    width: '25%',
  },
];

export const handlePatients = (patients) =>
  patients.map(
    applySpec({
      id: prop(ID),
      publicId: prop(PUBLIC_ID),
      firstName: propOr('', FIRST_NAME),
      lastName: propOr('', LAST_NAME),
      birth: pipe(prop(BIRTH), parseBirthday),
    })
  );
