import React, { useRef, useMemo, useCallback, useEffect } from 'react';
import { pipe, props, map, propSatisfies } from 'ramda';
import PropTypes from 'prop-types';

import { parseDate, parseTime, validateDate } from 'utils';

import { Container, Placeholder, PlaceholderText, Input, Dot } from './styles';

const DateInput = ({ value, onChange, error, onError, className, disabled, compareError }) => {
  const monthRef = useRef();
  const yearRef = useRef();
  const [day = '', month = '', year = ''] = useMemo(() => parseDate(value).split('.'), [value]);
  const [hour = 0, minute = 0] = useMemo(() => (value ? parseTime(value).split(':').map(Number) : []), [value]);

  const onFocus = useCallback((e) => e.currentTarget.select(), []);
  const onBlur = useCallback((e) => {
    const val = e.currentTarget.value;
    if (val.length === 1) e.currentTarget.value = `0${val}`;
  }, []);

  const onChangeDay = useCallback((e) => {
    const val = e.currentTarget.value.replace(/\D/, '').slice(-2);
    e.currentTarget.value = val;

    if (val.length === 2) monthRef.current?.focus();
  }, []);
  const onChangeMonth = useCallback((e) => {
    const val = e.currentTarget.value.replace(/\D/, '').slice(-2);
    e.currentTarget.value = val;

    if (val.length === 2) yearRef.current?.focus();
  }, []);
  const onChangeYear = useCallback((e) => {
    const val = e.currentTarget.value.replace(/\D/, '').slice(-4);
    e.currentTarget.value = val;
  }, []);
  const onChangeDate = useCallback(
    ({ currentTarget }) => {
      if (error) onError('');

      const [d, m, y] = pipe(props(['day', 'month', 'year']), map(propSatisfies(Number, 'value')))(currentTarget);

      if (validateDate(d, m, y)) {
        onChange(new Date(y, m - 1, d, hour, minute).toISOString());
      } else {
        onError('Datum ungültig');
      }
    },
    [error, hour, minute, onChange, onError]
  );

  useEffect(() => {
    if (!disabled && !validateDate(Number(day), Number(month), Number(year))) onError('Datum ungültig');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container onChange={onChangeDate} error={Boolean(error)} className={className}>
      <Placeholder>
        <PlaceholderText compareError={compareError}>DD</PlaceholderText>
        <PlaceholderText compareError={compareError}>MM</PlaceholderText>
        <PlaceholderText compareError={compareError}>JJJJ</PlaceholderText>
      </Placeholder>
      <Input
        name="day"
        placeholder="--"
        defaultValue={day}
        onChange={onChangeDay}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
      />
      <Dot />
      <Input
        ref={monthRef}
        name="month"
        placeholder="--"
        defaultValue={month}
        onChange={onChangeMonth}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
      />
      <Dot />
      <Input
        ref={yearRef}
        name="year"
        placeholder="----"
        defaultValue={year}
        onChange={onChangeYear}
        onFocus={onFocus}
        disabled={disabled}
      />
    </Container>
  );
};

DateInput.defaultProps = {
  value: '',
  onChange: () => null,
  error: '',
  onError: () => null,
  className: '',
  disabled: false,
};
DateInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  onError: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  compareError: PropTypes.bool.isRequired,
};

export default DateInput;
