import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import TextInput from 'components/TextInputNew';

const Input = ({ field, label }) => {
  const [props] = useField(field);

  return <TextInput {...props} placeholder={label} />;
};

Input.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Input;
