import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  text-decoration: none;
  border-radius: var(--border-radius8);
  @media (hover) {
    &:hover {
      background-color: var(--color-greyF8);
    }
  }
`;

export const Text = styled.p`
  display: block;
  width: ${({ $width }) => `calc(${$width} - 10px)`};
  font-size: ${({ $fontSize }) =>
    ($fontSize === 10 && 'var(--font-size10)') || ($fontSize === 13 && 'var(--font-size13)') || 'var(--font-size18)'};
  font-weight: ${({ $fontSize }) => ($fontSize === 10 && 600) || ($fontSize === 13 && 500) || 400};
  letter-spacing: ${({ $fontSize }) => ($fontSize === 10 && 1.01) || ($fontSize === 13 && 0.92) || 0.5}px;
  color: ${({ $color }) => $color || 'var(--color-grey6A)'};
  text-align: ${({ $align }) => $align || 'left'};
  text-transform: ${({ $fontSize }) => ($fontSize === 10 || $fontSize === 13 ? 'uppercase' : 'none')};
`;
