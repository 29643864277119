import qs from 'query-string';
import { omit, pick } from 'ramda';

import { wrapperRequest } from './utils';
import { API_URL, PUT, SUB_PATIENT, EXPAND, POST, OWN_ERR_HANDLER } from './consts';

const defParams = { [EXPAND]: [SUB_PATIENT] };

export const getReports = (query = {}) =>
  wrapperRequest({
    url: `${API_URL}/reports?${qs.stringify({ ...defParams, ...query })}`,
  });

export const getReportsStatus = () =>
  wrapperRequest({
    url: `${API_URL}/reports/status`,
  });

export const putReport = ({ reportId, status }) =>
  wrapperRequest({
    url: `${API_URL}/reports/${reportId}?${qs.stringify(defParams)}`,
    options: { method: PUT, body: { status } },
  });

export const postReportsArchive = (query = {}) =>
  wrapperRequest({
    url: `${API_URL}/reports/archive?${qs.stringify(omit(['reportIds'], query))}`,
    options: { method: POST, body: pick(['reportIds'], query), [OWN_ERR_HANDLER]: true },
  });
