import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import { reducerTemplate } from 'store/utils';

import { LIST, REPORTS, PREVIEW_REPORT, DIAGNOSIS_CODES, ID } from '.';
import {
  UPDATE_DIAGNOSES,
  UPDATE_DIAGNOSIS,
  DELETE_DIAGNOSIS,
  RESET_DIAGNOSES,
  UPDATE_DIAGNOSIS_REPORTS,
  UPDATE_DIAGNOSIS_REPORT,
  DELETE_DIAGNOSIS_REPORT,
  RESET_DIAGNOSIS_REPORTS,
  UPDATE_DIAGNOSIS_PREVIEW_REPORT,
  RESET_DIAGNOSIS_PREVIEW_REPORT,
  UPDATE_DIAGNOSIS_CODES,
} from './types';

const diagnosesReducer = reducerTemplate(
  {
    id: ID,
    list: UPDATE_DIAGNOSES,
    item: UPDATE_DIAGNOSIS,
    deleteItem: DELETE_DIAGNOSIS,
    reset: RESET_DIAGNOSES,
  },
  {}
);

const diagnosesReportsReducer = reducerTemplate(
  {
    id: ID,
    list: UPDATE_DIAGNOSIS_REPORTS,
    item: UPDATE_DIAGNOSIS_REPORT,
    deleteItem: DELETE_DIAGNOSIS_REPORT,
    reset: RESET_DIAGNOSIS_REPORTS,
  },
  {}
);

const previewReportReducer = handleActions(
  {
    [UPDATE_DIAGNOSIS_PREVIEW_REPORT]: (_, { payload }) => payload,
    [RESET_DIAGNOSIS_PREVIEW_REPORT]: () => null,
  },
  null
);

const diagnosisCodesReducer = handleActions(
  {
    [UPDATE_DIAGNOSIS_CODES]: (_, { payload }) => payload,
  },
  []
);

export default combineReducers({
  [LIST]: diagnosesReducer,
  [REPORTS]: diagnosesReportsReducer,
  [PREVIEW_REPORT]: previewReportReducer,
  [DIAGNOSIS_CODES]: diagnosisCodesReducer,
});
