import React, { useCallback, useMemo } from 'react';
import { useFormikContext, useField } from 'formik';
import { tail, pathOr } from 'ramda';
import PropTypes from 'prop-types';

import TextInput from 'components/TextInputNew';
import Radios from 'components/RadiosNew';
import Select from 'components/SelectNew';
import { POSITION_TITLES, GENDER_RADIOS } from 'utils/constants';
import { CONTACTS, TITLE, FIRST_NAME, LAST_NAME, GENDER, EMAIL, PHONE, MAIN, DOCTOR, CHANNEL } from 'store/organizations';

const DoctorForm = ({ getFieldProps, type, isClinic, errors }) => {
  const getPath = useCallback((name) => [CONTACTS, type, name].join('.'), [type]);

  const { values, setValues } = useFormikContext();
  const [{ value: title }, , { setValue: setTitle }] = useField(getPath(TITLE));
  const [{ value: gender }, , { setValue: setGender }] = useField(getPath(GENDER));
  const [{ name, value: email }, , { setValue: setEmail }] = useField(getPath(EMAIL));

  const options = useMemo(() => (title ? POSITION_TITLES : tail(POSITION_TITLES)), [title]);

  const onChangeEmail = useCallback(
    (value = '') => {
      if (isClinic && values[CHANNEL] !== EMAIL && /.+@hin.ch$/i.test(value.trim())) {
        setValues({
          ...values,
          [CHANNEL]: EMAIL,
          [CONTACTS]: {
            ...values[CONTACTS],
            [type]: {
              ...pathOr({}, [CONTACTS, type], values),
              [EMAIL]: value,
            },
          },
        });
      } else {
        setEmail(value);
      }
    },
    [isClinic, setEmail, setValues, type, values]
  );

  return (
    <>
      <Select label="Titel" value={title || ''} options={options} onSelect={setTitle} />
      <TextInput {...getFieldProps(getPath(FIRST_NAME))} label="Vorname" />
      <TextInput {...getFieldProps(getPath(LAST_NAME))} label="Nachname" />
      <Radios value={gender} label="Geschlecht" items={GENDER_RADIOS} setValue={setGender} />
      <TextInput name={name} value={email} handleChangeText={onChangeEmail} label="E-Mail" error={errors && errors[EMAIL]} />
      <TextInput {...getFieldProps(getPath(PHONE))} label="Telefon" />
    </>
  );
};

DoctorForm.defaultProps = { errors: null };
DoctorForm.propTypes = {
  getFieldProps: PropTypes.func.isRequired,
  type: PropTypes.oneOf([MAIN, DOCTOR]).isRequired,
  isClinic: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    [EMAIL]: PropTypes.string,
  }),
};

export default DoctorForm;
