import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { usePermissions, TARMED } from 'permissions';
import { useInvoicesActions, useAlerts } from 'store/invoices';
import Button from 'components/Button';
import Spinner from 'components/FullScreenSpinner';
import Message from 'components/Message';

const BaseTarmeds = ({ patientId, setBaseTarmeds, className }) => {
  const can = usePermissions();
  const hasAccess = can.read(TARMED);
  const { fetchBaseTarmeds } = useInvoicesActions();
  const { extractId, loading, success, error, resetAlerts } = useAlerts();

  const handleClick = useCallback(() => extractId(fetchBaseTarmeds(patientId)), [extractId, fetchBaseTarmeds, patientId]);

  useEffect(() => {
    if (success) {
      setBaseTarmeds(success);
      resetAlerts();
    }
  }, [resetAlerts, setBaseTarmeds, success]);

  return (
    <>
      <Button
        type="button"
        onClick={handleClick}
        disabled={!hasAccess || !patientId || Boolean(loading || success)}
        height="40px"
        className={className}
      >
        {loading ? <Spinner size={30} height="40px" /> : 'Augenmobil Basis-Positionen laden'}
      </Button>
      {error && (
        <Message type="error" small={1} form={1}>
          {error}
        </Message>
      )}
    </>
  );
};

BaseTarmeds.defaultProps = { className: null };
BaseTarmeds.propTypes = {
  patientId: PropTypes.string.isRequired,
  setBaseTarmeds: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default BaseTarmeds;
