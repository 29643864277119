import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { KIND } from 'store/organizations';

import { Container, Item } from './styles';

const Row = ({ id, kind, widths, name, city, contact }) => {
  const { push } = useHistory();
  const handleRowClick = useCallback(
    ({ currentTarget }) => {
      const { organization } = currentTarget?.dataset || {};

      if (organization) {
        push(`/organizations/${organization}`, { [KIND]: kind });
      }
    },
    [push, kind]
  );

  return (
    <Container type="button" data-organization={id} onClick={handleRowClick}>
      <Item width={widths[0]}>{name}</Item>
      <Item width={widths[1]}>{city}</Item>
      <Item width={widths[2]}>{contact || '–'}</Item>
    </Container>
  );
};

Row.propTypes = {
  id: PropTypes.string.isRequired,
  kind: PropTypes.number.isRequired,
  widths: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  name: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  contact: PropTypes.string.isRequired,
};

export default Row;
