import React, { useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { pathOr } from 'ramda';

import EyeImagesAnalysis from 'containers/EyeImagesAnalysis/';
import PageHeader from 'components/PageHeader';
import Warning from 'components/Warning';
import FullScreenSpinner from 'components/FullScreenSpinner';
import Switcher from 'components/Switcher';
import { useInitDiagnoses, useInitDiagnosesCodes } from 'store/diagnoses';
import {
  PATIENT,
  DIAGNOSIS,
  NURSE_PATIENT,
  PHYSICIAN_PATIENT,
  MEDICAL_HISTORY,
  EXAMINATION,
  PERSONAL,
  REPORT,
  DIAGNOSIS_CODE,
  usePermissions,
  INVOICES,
} from 'permissions';
import { mergeAlerts } from 'store/alerts';
import { FIRST_NAME, LAST_NAME, BIRTH, PUBLIC_ID, useInitPatient, usePatient } from 'store/patients';
import { useInitMedicalHistory } from 'store/medicalHistory';
import { useIsExistError } from 'store/errors';
import { ID, STATUS, useExaminationsActions, useCurrentExamination, usePatientExaminations } from 'store/examinations';
import { getHeadTitleOfPatient } from 'utils';

import NursePatient from './NursePatient';
import PhysicianPatient from './PhysicianPatient';
import InfoBox from './InfoBox';
import PatientReports from './PatientReports';
import { useReadMode, useExaminationStatusUpdater } from './hooks';
import { PageContainer } from './styles';

function Patient({ history, location }) {
  const [examId, examStatus] = useCurrentExamination([ID, STATUS]);
  const { id, view = PERSONAL } = useParams();
  const can = usePermissions();
  const { error, isWrite, isRead, canWrite, switchReadMode } = useReadMode(examId, examStatus, view);
  const { setCurrentExamination } = useExaminationsActions();
  const alerts = [
    useInitPatient(can.read(PATIENT), id),
    usePatientExaminations(can.read(EXAMINATION), id),
    useInitDiagnoses(can.read(DIAGNOSIS), examId),
    useInitMedicalHistory(can.read(MEDICAL_HISTORY), id),
    useInitDiagnosesCodes(can.read(DIAGNOSIS_CODE)),
  ];

  const { loading } = mergeAlerts(alerts);
  const isError = useIsExistError();
  const [firstName, lastName, birthday, publicId] = usePatient(id, [FIRST_NAME, LAST_NAME, BIRTH, PUBLIC_ID]);
  const headTitle = useMemo(
    () => getHeadTitleOfPatient(firstName, lastName, birthday, publicId),
    [firstName, lastName, birthday, publicId]
  );

  useExaminationStatusUpdater();

  useEffect(() => {
    const { exam } = qs.parse(location.search.slice(1));

    if (examId && exam && examId !== exam) {
      setCurrentExamination(exam);
    }
  }, [examId, location.search, setCurrentExamination]);

  const redirectToBack = useCallback(() => {
    history.push(pathOr('/patients', ['state', 'previousPath'], location));
  }, [history, location]);

  const errorComponent = (
    <PageContainer>
      <Warning>Sie haben keine Berechtigung, auf diese Informationen zuzugreifen.</Warning>
    </PageContainer>
  );

  if (isError) return null;

  if (loading) return <FullScreenSpinner />;

  if (error) return errorComponent;

  if (view === DIAGNOSIS && can.read(DIAGNOSIS)) {
    return (
      <PageContainer>
        <EyeImagesAnalysis readMode={isRead} />
      </PageContainer>
    );
  }

  if (can.redirect(NURSE_PATIENT)) {
    return (
      <PageContainer>
        <PageHeader title={headTitle} redirectToBack={redirectToBack}>
          <Switcher label="BEARBEITEN" checked={isWrite} onChange={switchReadMode} allowChange={canWrite} />
        </PageHeader>
        <NursePatient readMode={isRead} />
        {can.read(REPORT) && <PatientReports />}
        <InfoBox />
      </PageContainer>
    );
  }

  if (can.redirect(PHYSICIAN_PATIENT)) {
    return (
      <PageContainer>
        <PageHeader title={headTitle} redirectToBack={redirectToBack}>
          {view !== INVOICES && (
            <Switcher label="BEARBEITEN" checked={isWrite} onChange={switchReadMode} allowChange={canWrite} />
          )}
        </PageHeader>
        <PhysicianPatient readMode={isRead} />
        {can.read(REPORT) && <PatientReports />}
        <InfoBox />
      </PageContainer>
    );
  }

  return errorComponent;
}

Patient.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Patient;
