import React, { useCallback, useEffect, useMemo } from 'react';
import { equals, remove, is, props } from 'ramda';

import TextInput from 'components/TextInputNew';
import Button from 'components/Button';
import Table from 'components/Table';
import Message from 'components/Message';
import {
  EMPTY,
  CUSTOM,
  STATUS,
  CURSOR,
  COUNT,
  LIMIT,
  HAS_MORE,
  ID,
  useInitReports,
  useReportsActions,
  useAlerts,
} from 'store/reports';
import { REPORT, usePermissions } from 'permissions';

import Row from './Row';
import DropDown from './DropDown';
import { Container, Wrapper } from './styles';
import { headerFields, handleFields } from './utils';
import { useCheckedList } from './hooks';

const ExternReports = () => {
  const can = usePermissions();
  const { list: reports, search, sort, pagination, loading: reportsLoading } = useInitReports(can.read(REPORT));

  const { updateSearch, updatePagination, updateSort, updateReports, saveReport, createReportsArchive } = useReportsActions();
  const { list, checkboxStatus, setCheckboxStatus, markers, setMarkers } = useCheckedList(reports);
  const { extractId, loading, success, error, resetAlerts } = useAlerts();
  const { extractId: saveReportExtract, success: saveReportSuccess } = useAlerts();

  const handleCheckBoxClick = useCallback(
    async (reportId) => {
      if (!reportId) return;
      const index = markers.findIndex(equals(reportId));
      const nextState = index < 0 ? [...markers, reportId] : remove(index, 1)(markers);

      setMarkers(nextState);
      setCheckboxStatus(nextState.length ? CUSTOM : EMPTY);
    },
    [markers, setMarkers, setCheckboxStatus]
  );

  const handleItemClick = useCallback(
    ({ reportId, url, status }) => {
      if (!reportId || !is(String, url)) return;

      window.open(url);

      if (status !== 2) {
        saveReportExtract(saveReport({ reportId, status: 2 }));
      }
    },
    [saveReportExtract, saveReport]
  );

  const handleArchiveClick = useCallback(() => {
    if (!checkboxStatus || checkboxStatus === EMPTY || loading) return;

    if (checkboxStatus === CUSTOM) {
      extractId(
        createReportsArchive({
          filter: CUSTOM,
          reportIds: markers,
          ...(search && { search }),
        })
      );
    } else {
      extractId(createReportsArchive({ filter: checkboxStatus, ...(search && { search }) }));
    }
  }, [checkboxStatus, extractId, createReportsArchive, loading, markers, search]);

  const renderRow = useCallback(
    (args) => <Row markers={markers} onCheckBoxClick={handleCheckBoxClick} onClick={handleItemClick} {...handleFields(args)} />,
    [handleCheckBoxClick, handleItemClick, markers]
  );

  const errorMessage = useMemo(() => {
    if (error) return error;
    if (!markers.length) return 'Bitte wählen Sie die Berichte aus, die Sie herunterladen möchten.';

    return null;
  }, [error, markers.length]);

  useEffect(() => {
    if (is(String, success)) {
      updateReports(
        reports.map((report) => {
          const [id, status] = props([ID, STATUS], report);
          if (status !== 2 && markers.includes(id)) {
            return { ...report, [STATUS]: 2 };
          }

          return report;
        })
      );
      window.open(success);
      setMarkers([]);
      resetAlerts();
    }
  }, [markers, reports, resetAlerts, setMarkers, success, updateReports]);

  return (
    <Container>
      <TextInput type="search" placeholder="Bericht suchen" value={search} handleChangeText={updateSearch} mb={70} />
      <Wrapper>
        <Button width="260px" color="normal" onClick={handleArchiveClick} disabled={!markers.length || loading}>
          markierte berichte herunterladen
        </Button>
        {errorMessage && <Message type="error">{errorMessage}</Message>}
      </Wrapper>
      <Table
        title="Berichte"
        head={headerFields(
          <DropDown
            marked={markers.length}
            all={list.length}
            checkboxStatus={checkboxStatus}
            setCheckboxStatus={setCheckboxStatus}
            saveSuccess={Boolean(saveReportSuccess)}
            archiveSuccess={Boolean(success)}
          />
        )}
        list={list}
        renderRow={renderRow}
        sort={sort}
        cursor={pagination[CURSOR]}
        count={pagination[COUNT]}
        limit={pagination[LIMIT]}
        hasMore={pagination[HAS_MORE]}
        updateSort={updateSort}
        updatePagination={updatePagination}
        loading={reportsLoading}
      />
    </Container>
  );
};

export default ExternReports;
