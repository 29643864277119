export const FEATURE_NAME = 'diagnoses';

export const DIAGNOSIS_UPDATE = 'DIAGNOSIS_UPDATE';

export const LIST = 'list';
export const REPORTS = 'reports';
export const PREVIEW_REPORT = 'previewReport';
export const DIAGNOSIS_CODES = 'diagnosisCodes';
export const DIAGNOSIS = 'diagnosis';

export const PROCEDURE = 'procedure';
export const ASSESSMENT = 'assessment';
export const PREVENT_ANALYSIS = 'noAnalysis';
export const PREVENT_ANALYSIS_REPORT = 'noAnalysisReport';
export const APPROVALS = 'approvals';
export const TAKE_LAST_DIAGNOSIS = 'diagnosisFromPrevious';

export const VALUE = 'value';
export const EYE_DOCTOR = 'eyeDoctor';
export const NEXT_EXAM = 'nextExamination';
export const COMMENT = 'comment';

export const EYE_EXTERIOR = 'eyeExterior';
export const FUNDUS = 'fundus';
export const RIGHT = 'right';
export const LEFT = 'left';

export const EYE = 'eye';
export const DESCRIPTION = 'description';
export const MACULA = 'macula';
export const MACULA_DESCRIPTION = 'maculaDescription';
export const PERIPHERAL_VISION = 'peripheralVision';
export const PERIPHERAL_VISION_DESCRIPTION = 'peripheralVisionDescription';
export const PAPILLA = 'papilla';
export const PAPILLA_DESCRIPTION = 'papillaDescription';
export const DIABETES = 'diabetes';
export const DIABETES_TYPE = 'diabetesType';
export const INSULIN = 'insulin';
export const ANALYSIS_FROM_PREVIOUS = 'valuesFromPrevious';

export const APPROVAL_COMMENT = 'approvalMessage';

export const PROCEDURE_STATUS = {
  UNDECIDED: 0,
  TREATMENT_REQUIRED: 1,
  NO_TREATMENT: 2,
};
