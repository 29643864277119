import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ small }) => (small ? '-15px' : 0)};
  margin-bottom: 30px;
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  width: 20%;
`;

export const TabConnector = styled.span`
  display: inline-block;
  width: 13px;
  height: 2px;
  background-color: var(--color-greyF8);
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ small }) => (small ? 41 : 80)}px;
  padding: 0 10px;
  width: 100%;
  background-color: var(--color-greyF8);
  border: none;
  color: var(--color-grey6A);
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  text-decoration: none;
  border-radius: var(--border-radius8);
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.92px;
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: var(--color-greyEC);
  }
  ${({ highlight, disabled }) =>
    (highlight || disabled) &&
    `
    background-color: var(--color-white);
    border: 1px solid var(--color-grey6A);
    &:hover {
      background-color: var(--color-white);
    }
  `}
`;
