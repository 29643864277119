import { times } from 'ramda';

export const DAYS_OPTIONS = times(($) => ({ id: $ + 1, label: $ + 1 }), 10);
export const TIME_OPTIONS = times((id) => {
  const h = Math.round(8 + id * 0.49);
  const m = id % 2 ? '30' : '00';
  const v = `${h < 10 ? 0 : ''}${h}:${m}`;

  return { id: v, label: v };
}, 21);

export const STATUSES = {
  0: { label: 'In Vorbereitung', color: '#f5a623' },
  1: { label: 'Geplant', color: '#0f7eff' },
  2: { label: 'Gestartet', color: '#009838' },
  3: { label: 'Abgeschlossen', color: '#6a6a6a' },
};
