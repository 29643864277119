import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { values } from 'ramda';

import { wrapActions, wrapSelector, wrapMultiSelector } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { SORT, PAGINATION, LIMIT, CURSOR, META, OWNER, EDITOR, SUB_PATIENT, EXPAND, FIELDS } from 'api/consts';

import { MESSAGE, ATTACHMENTS } from './consts';
import * as actions from './actions';
import { getInfoItems, getInfoItem, getInfoItemProp, getRequestData } from './selectors';

export const useInfoItemsActions = wrapActions({ ...actions });

export const useInfoItems = () => {
  const infoItems = useSelector(getInfoItems);

  return useMemo(() => values(infoItems), [infoItems]);
};
export const useInfoItem = (id, arg) => wrapMultiSelector(getInfoItemProp(id), getInfoItem(id))(arg);

export const useRequestData = wrapSelector(getRequestData);

export const useInitInfoItems = (hasAccess, patientId) => {
  const alerts = useAlerts();
  const requestData = useRequestData();
  const sort = requestData[SORT];
  const pagination = requestData[PAGINATION];
  const limit = pagination[LIMIT];
  const cursor = pagination[CURSOR];

  const { extractId } = alerts;
  const { fetchInfoItems, resetInfoItems } = useInfoItemsActions();

  useEffect(() => {
    if (!hasAccess) return;
    extractId(
      fetchInfoItems({
        [SUB_PATIENT]: patientId,
        [FIELDS]: [MESSAGE, ATTACHMENTS, META, SUB_PATIENT],
        [EXPAND]: [`${META}.${OWNER}`, `${META}.${EDITOR}`],
        [LIMIT]: limit,
        [CURSOR]: cursor,
        ...sort,
      })
    );
  }, [hasAccess, extractId, fetchInfoItems, limit, cursor, sort, patientId]);

  useEffect(() => () => resetInfoItems(), [resetInfoItems]);

  return hasAccess ? { ...alerts, ...pagination } : { success: true };
};
