import moment from 'moment';
import { prop, applySpec, propOr, pipe, path, props, filter, join, __ } from 'ramda';

import { PUBLIC_ID, FIRST_NAME, LAST_NAME, BIRTH, SUB_PATIENT } from 'store/patients';
import { ID, DATE, STATUS, EXAMINATION_STATUS, EXAMINATION_LABELS } from 'store/examinations';
import { parseBirthday } from 'utils';
import palette from 'theme/palette';

export const getTableHead = (withStatus) => {
  const tableHead = [
    {
      id: `${SUB_PATIENT}.${PUBLIC_ID}`,
      content: 'ID',
      width: withStatus ? '14%' : '20%',
      withoutSort: true,
    },
    {
      id: `${SUB_PATIENT}.${LAST_NAME}`,
      content: 'Name',
      width: '30%',
      withoutSort: true,
    },
    {
      id: `${SUB_PATIENT}.${BIRTH}`,
      content: 'Geburtstag',
      width: withStatus ? '18%' : '25%',
      withoutSort: true,
    },
    {
      id: DATE,
      content: 'Untersuch vom',
      width: withStatus ? '18%' : '25%',
    },
    {
      id: STATUS,
      content: 'Status',
      width: '20%',
    },
  ];

  return withStatus ? tableHead : tableHead.slice(0, -1);
};

export const handleExaminations = (examinations) =>
  examinations.map(
    applySpec({
      id: prop(ID),
      patientId: path([SUB_PATIENT, ID]),
      publicId: path([SUB_PATIENT, PUBLIC_ID]),
      name: pipe(propOr({}, SUB_PATIENT), props([FIRST_NAME, LAST_NAME]), filter(Boolean), join(', ')),
      birth: pipe(path([SUB_PATIENT, BIRTH]), parseBirthday),
      date: pipe(prop(DATE), (date) => (date ? moment(date).format('DD.MM.YYYY, HH:mm') : '–')),
      status: pipe(prop(STATUS), prop(__, EXAMINATION_LABELS)),
      color: pipe(prop(STATUS), prop(__, palette.examination)),
    })
  );

const { IN_PREPARATION, SUBMITTED, UNDER_REVIEW, IN_APPROVAL } = EXAMINATION_STATUS;

export const tableTitles = {
  [IN_PREPARATION]: 'In Bearbeitung',
  [SUBMITTED]: 'Neue Befundungen',
  [IN_APPROVAL]: 'Zur Überprüfung gesendet',
  [UNDER_REVIEW]: 'Meine offenen Befundungen',
};
