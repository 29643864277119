import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useAsyncState } from 'utils/useAsyncState';
import Close from 'assets/svg-icons/Close';
import Spinner from 'components/FullScreenSpinner';
import { ANIMATION_TIME } from '../consts';

import { PREVIEW_WIDTH, PREVIEW_MARGIN } from './consts';
import { Container, ListContainer, Wrapper, Item, Image, Text, AlertBox, Message, Button, Input } from './styles';

const List = ({ loading, list, selected, selectImage, withCompare }) => {
  const containerRef = useRef();
  const timer = useRef();
  const [alert, setAlert] = useState();
  const [scroll, setScroll] = useAsyncState();
  const wrapperWidth = useMemo(() => list.length * (PREVIEW_WIDTH + PREVIEW_MARGIN) - PREVIEW_MARGIN, [list]);

  const handleScroll = useCallback(
    ({ target }) => {
      const { value } = target;
      if (!timer.current) {
        timer.current = setTimeout(() => {
          setScroll(value);
          timer.current = 0;
        }, ANIMATION_TIME);
      }
    },
    [setScroll]
  );
  const handleClick = useCallback(
    ({ currentTarget }) => {
      const { id } = currentTarget.dataset;
      if (!id) return;
      if (!withCompare) {
        selectImage([id]);
        return;
      }
      if (selected.length < 2) {
        selectImage([...selected, id]);
      } else {
        setAlert('Schliessen Sie zuerst ein Bild.');
      }
    },
    [selected, selectImage, withCompare]
  );
  const closeAlert = useCallback(() => setAlert(), []);
  const defineScroll = useCallback(() => {
    const elem = containerRef.current;
    if (!elem || !wrapperWidth) return;
    const { width } = elem.getBoundingClientRect();

    if (wrapperWidth > width) {
      setScroll((value) => (!value ? '0' : value));
    } else {
      setScroll((value) => (value ? null : value));
    }
  }, [wrapperWidth, setScroll]);

  useEffect(() => {
    window.addEventListener('resize', defineScroll);
    defineScroll();

    return () => {
      window.removeEventListener('resize', defineScroll);
    };
  }, [defineScroll]);

  useEffect(() => {
    if (alert && selected.length < 2) {
      closeAlert();
    }
  }, [alert, selected.length, closeAlert]);

  useEffect(
    () => () => {
      if (timer.current) clearTimeout(timer.current);
    },
    []
  );

  if (loading) {
    return (
      <Container>
        <Spinner height="100%" />
      </Container>
    );
  }

  return (
    <Container>
      {alert && (
        <AlertBox>
          <Message>{alert}</Message>
          <Button type="Button" onClick={closeAlert}>
            <Close />
          </Button>
        </AlertBox>
      )}
      <ListContainer ref={containerRef}>
        <Wrapper $scroll={Number(scroll)} $length={wrapperWidth}>
          {list.map(({ id, blobUrl, date, isCurrent }) => (
            <Item key={id}>
              <Image type="button" data-id={id} src={blobUrl} onClick={handleClick} disabled={selected.includes(id)} />
              {date && <Text highlight={isCurrent}>{date}</Text>}
            </Item>
          ))}
        </Wrapper>
      </ListContainer>
      {scroll && <Input type="range" value={scroll} onChange={handleScroll} min={0} step={0.1} max={100} />}
    </Container>
  );
};

List.defaultProps = {
  loading: false,
  list: [],
  selected: [],
  withCompare: false,
};

List.propTypes = {
  loading: PropTypes.bool,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      blobUrl: PropTypes.string.isRequired,
      date: PropTypes.string,
      isCurrent: PropTypes.bool,
    })
  ),
  selected: PropTypes.arrayOf(PropTypes.string),
  selectImage: PropTypes.func.isRequired,
  withCompare: PropTypes.bool,
};

export default List;
