import 'react-app-polyfill/stable';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import 'utils/yupCustomValidations';
import store from 'store/store';
import Root from 'components/Root';
import FallbackComponent from 'components/Error';
import { BUGSNAG_API_KEY } from 'api/consts';
import * as serviceWorker from './serviceWorkerRegistration';
import packageJson from '../package.json';

const isProd = process.env.NODE_ENV === 'production';

if (isProd) {
  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    appType: 'client',
    appVersion: packageJson.version,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production'],
  });
}
const ErrorBoundary = isProd ? Bugsnag.getPlugin('react').createErrorBoundary(React) : Fragment;
const bugsnagProps = isProd ? { FallbackComponent } : {};

ReactDOM.render(
  <ErrorBoundary {...bugsnagProps}>
    <Provider store={store}>
      <Router>
        <Root />
      </Router>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);

serviceWorker.register({
  onUpdate: (event) => {
    const notification = document.getElementById('sw-notification');
    const button = document.getElementById('sw-reload');

    if (notification && button) {
      notification.classList.add('show-sw-notification');

      const timer = setTimeout(() => {
        event?.postMessage({ type: 'SKIP_WAITING' });
        document?.getElementById('sw-notification')?.classList?.remove('show-sw-notification');
        window.location.reload();
      }, 10000);

      button.addEventListener('click', () => {
        clearTimeout(timer);
        event.postMessage({ type: 'SKIP_WAITING' });
      });
    }
  },
  score: '/',
});
