import { PATIENTS, REPORTS, ORGANIZATIONS, INVOICES, BUG_REPORTS, ORGANIZATION_REPORTS, PLANNINGS, INSIGHTS } from 'permissions';

export const items = [
  { label: 'Patienten', path: '/patients', resource: PATIENTS },
  {
    label: 'Partner',
    path: '/organizations',
    regexp: /^\/organization/i,
    resource: ORGANIZATIONS,
  },
  { label: 'Berichte', path: '/reports', resource: REPORTS },
  { label: 'Finanzen', path: '/invoices', regexp: /^\/invoices/i, resource: INVOICES },
  { label: 'Admin', path: '/bugreports', resource: BUG_REPORTS },
  { label: 'Berichte', path: '/extern/reports', resource: ORGANIZATION_REPORTS },
  { label: 'Planen', path: '/planning', resource: PLANNINGS },
  { label: 'Insights', path: '/insights', resource: INSIGHTS },
];
