import {
  propOr,
  reduce,
  when,
  has,
  mergeDeepLeft,
  includes,
  pipe,
  head,
  cond,
  equals,
  T,
  map,
  flatten,
  uniq,
  path,
  __,
  not,
} from 'ramda';

import { EXAMINATION_STATUS } from 'store/examinations';

import readModeModels from './models/readMode';
import permissionsModel from './models/permissions';
import {
  CREATE,
  READ,
  UPDATE,
  DELETE,
  REDIRECT,
  PHYSICIAN,
  PHYSICIAN_SENIOR,
  NURSE,
  SECRETARY,
  FINANCE,
  FINANCE_MANAGER,
  SYSADMIN,
  EXTERN,
  PLANNER,
  PLANNER_SUPPORT,
} from './consts';

const { IN_PREPARATION, SUBMITTED, UNDER_REVIEW, IN_APPROVAL } = EXAMINATION_STATUS;

const getReadModeItems = (role, status, route) => {
  const routes = propOr({}, role, readModeModels);
  const check = propOr(() => [], route, routes);

  return check(status);
};

export const getAllReadModeItems = (roles, status, route) => {
  if (!route) return [];

  return pipe(
    map((role) => getReadModeItems(role, status, route)),
    flatten,
    uniq
  )(roles || []);
};

export const getExaminationStatusesForSearch = (roles = []) => {
  const forNurse = [IN_PREPARATION];
  const forPhysicianSenior = [SUBMITTED, IN_APPROVAL, UNDER_REVIEW];
  const forPhysician = [SUBMITTED, UNDER_REVIEW];
  const defaultStatuses = [];

  return cond([
    [includes(NURSE), () => forNurse],
    [includes(PHYSICIAN_SENIOR), () => forPhysicianSenior],
    [includes(PHYSICIAN), () => forPhysician],
    [T, () => defaultStatuses],
  ])(roles);
};

export const getHomePage = pipe(
  head,
  cond([
    [equals(PHYSICIAN), () => '/patients'],
    [equals(NURSE), () => '/patients/tasks'],
    [equals(SECRETARY), () => '/reports'],
    [equals(FINANCE), () => '/invoices'],
    [equals(FINANCE_MANAGER), () => '/invoices'],
    [equals(SYSADMIN), () => '/bugreports'],
    [equals(EXTERN), () => '/extern/reports'],
    [includes(__, [PLANNER, PLANNER_SUPPORT]), () => '/planning'],
    [T, () => '/login'],
  ])
);

export const getPermissions = reduce(
  (acc, item) => when(() => has(item, permissionsModel), mergeDeepLeft(permissionsModel[item]), acc),
  {}
);

const checkSource =
  (name, permissions) =>
  (source, task = T) =>
    Boolean(path([source, name], permissions) && task());

export const checkAccess = (permissions) => ({
  create: checkSource(CREATE, permissions),
  read: checkSource(READ, permissions),
  update: checkSource(UPDATE, permissions),
  delete: checkSource(DELETE, permissions),
  redirect: checkSource(REDIRECT, permissions),
});

export const userWithout2EA = pipe(head, includes(__, [EXTERN]), not);
