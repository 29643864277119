import styled from 'styled-components';

import Btn from 'components/Button';
import RangeInput from '../RangeInput';
import { ANIMATION_TIME } from '../consts';

import { PREVIEW_WIDTH, PREVIEW_HEIGHT, PREVIEW_MARGIN } from './consts';

export const Container = styled.div`
  flex: 1 0 auto;
  width: 100%;
`;

export const ListContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 225px;
`;

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  left: ${({ $length, $scroll }) => ($scroll ? `calc((100% - ${$length}px) * ${$scroll / 100})` : 0)};
  transition: left ${ANIMATION_TIME * 1.5}ms;
`;

export const Item = styled.div`
  margin-right: ${PREVIEW_MARGIN}px;
  &:last-child {
    margin-right: 0;
  }
`;

export const Image = styled.button`
  display: block;
  width: ${PREVIEW_WIDTH}px;
  height: ${PREVIEW_HEIGHT}px;
  border-radius: var(--border-radius8);
  outline: none;
  border: none;
  cursor: pointer;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const AlertBox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 560px;
  height: 120px;
  border: 1px solid var(--color-grey97);
  border-radius: var(--border-radius8);
  background-color: var(--color-white);
  padding: 5px;
  z-index: 1;
`;

export const Text = styled.p`
  font-size: var(--font-size18);
  font-weight: var(--font-weight400);
  line-height: 26px;
  letter-spacing: 0.5px;
  color: var(--color-grey6A);
  text-align: center;
  border-radius: var(--border-radius8);
  margin-top: 10px;
  ${({ highlight }) => highlight && 'border: 1px solid var(--color-blue50); background-color: var(--color-greenF6);'}
`;

export const Message = styled(Text)`
  flex: 1 1 auto;
  line-height: 110px;
  margin: 0;
  padding-left: 40px;
`;

export const Button = styled(Btn)`
  width: 40px;
  height: 40px;
  background-color: var(--color-white);
`;

export const Input = styled(RangeInput)`
  margin: 30px 0;
`;
