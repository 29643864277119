import * as session from './session';
import * as patients from './patients';
import * as medicalHistory from './medicalHistory';
import * as examinations from './examinations';
import * as diagnoses from './diagnoses';
import * as reports from './reports';
import * as invoices from './invoices';
import * as organizations from './organizations';
import * as bugReports from './bugReports';
import * as infoItems from './infoItems';
import * as uploads from './uploads';
import * as planning from './planning';
import * as analytics from './analytics';

export default {
  ...session,
  ...patients,
  ...medicalHistory,
  ...examinations,
  ...diagnoses,
  ...reports,
  ...invoices,
  ...organizations,
  ...bugReports,
  ...infoItems,
  ...uploads,
  ...planning,
  ...analytics,
};
