import styled from 'styled-components';

export const DropContainer = styled.div`
  display: flex;
  min-height: ${({ readMode }) => (readMode ? '0' : '410px')};
  & > div:first-child {
    margin-right: ${({ readMode }) => (readMode ? '0' : '10px')};
  }
`;

export default {};
