import { map, applySpec, prop, pipe, length, path, pathOr, props, filter, join } from 'ramda';

import { parseDate } from 'utils';
import { TITLE, FIRST_NAME, LAST_NAME } from 'store/session';
import { ID, DATE, SUB_ORGANIZATION, META, EDITOR, STATUS } from 'api/consts';
import { POSITION_MAP } from 'utils/constants';

import { REGISTRATIONS, NAME, DAYS } from './consts';

export const handleScheduleList = map(
  applySpec({
    id: prop(ID),
    date: pipe(prop(DATE), parseDate),
    days: prop(DAYS),
    amountPatients: pipe(prop(REGISTRATIONS), length),
    organization: path([SUB_ORGANIZATION, NAME]),
    editor: pipe(
      pathOr({}, [META, EDITOR]),
      ($) => [POSITION_MAP[$[TITLE]], ...props([FIRST_NAME, LAST_NAME], $)],
      filter(Boolean),
      join(' ')
    ),
    status: prop(STATUS),
  })
);
