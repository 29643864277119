import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useInvoicesActions, useAlerts } from 'store/invoices';
import { ReactComponent as Icon } from 'assets/svg-icons/downloadPdf.svg';

import { Container, Title, Wrapper, Button, Message } from './styles';

const PdfSection = () => {
  const { id } = useParams();
  const { downloadPdf } = useInvoicesActions();
  const { extractId, loading, success, error, resetAlerts } = useAlerts();
  const handleClick = useCallback(() => extractId(downloadPdf(id)), [extractId, downloadPdf, id]);

  useEffect(() => {
    if (success?.url) {
      window.open(success.url, '_blank');
      resetAlerts();
    }
  }, [success, resetAlerts]);

  return (
    <Container>
      <Title>Rechnung als PDF herunterladen</Title>
      <Wrapper>
        <Button type="button" onClick={handleClick} disabled={loading}>
          <Icon />
        </Button>
        {error && <Message type="error">{error}</Message>}
      </Wrapper>
    </Container>
  );
};

export default PdfSection;
