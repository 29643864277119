import styled from 'styled-components';

export const Container = styled.div`
  width: 90%;
  max-width: 1160px;
  margin: 0 auto;
`;

export const FormContainer = styled.div`
  width: 90%;
  max-width: 1024px;
  margin: 30px auto;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LeftArrow = styled.button`
  height: 100px;
  width: 96px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin-top: ${({ hasMargin }) => (hasMargin ? 30 : 0)}px;
  background: var(--color-greyF8);
  border: none;
  border-radius: var(--border-radius8);
  outline: none;
  svg {
    height: 100%;
    path {
      stroke: ${({ disabled }) => (disabled ? 'rgba(106, 106, 106, 0.37)' : 'var(--color-grey6A)')};
    }
  }
  &:hover {
    background: var(--color-greyEC);
  }
  &:active {
    background: #c5c5c5;
  }
  &:disabled {
    background: var(--color-greyEC);
  }
  &:focus {
    box-shadow: var(--shadow-focus);
  }
`;

export const RightArrow = styled(LeftArrow)`
  transform: rotate(180deg);
  margin-top: ${({ hasMargin }) => (hasMargin ? 30 : 0)}px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ mt }) => mt || 40}px;
  margin-bottom: ${({ mb }) => mb || 40}px;
  padding-top: 2px;
  border-top: 1px solid var(--color-grey97);
`;

export const SectionLabel = styled.label`
  align-self: flex-start;
  text-align: left;
  color: var(--color-grey6A);
  font-size: var(--font-size15);
  font-weight: var(--font-weight600);
  margin-bottom: 20px;
`;
