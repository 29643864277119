import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'components/CheckboxNew';

import { Container, CheckBoxContainer, Field } from './styles';

const Row = ({ id: reportId, widths, patientName, birth, reportDate, status, markers, url, onCheckBoxClick, onClick }) => {
  const selected = useMemo(() => markers.includes(reportId), [markers, reportId]);

  const handleCheckBoxClick = useCallback(() => onCheckBoxClick(reportId), [onCheckBoxClick, reportId]);

  const handleClick = useCallback(() => onClick({ reportId, url, status }), [onClick, reportId, status, url]);

  const fieldProps = useMemo(() => ({ status, onClick: handleClick }), [status, handleClick]);

  return (
    <Container key={reportId}>
      <CheckBoxContainer width={widths[0]} onClick={handleCheckBoxClick}>
        <Checkbox selected={selected} size={14} mr={0} mb={0} iconType="darkCheck" borderColor="var(--color-grey6A)" />
      </CheckBoxContainer>
      <Field width={widths[1]} {...fieldProps}>
        {patientName}
      </Field>
      <Field width={widths[2]} {...fieldProps}>
        {birth}
      </Field>
      <Field width={widths[3]} {...fieldProps}>
        {reportDate}
      </Field>
    </Container>
  );
};

Row.defaultProps = {
  patientName: '',
  birth: '',
  reportDate: '',
  markers: [],
};

Row.propTypes = {
  id: PropTypes.string.isRequired,
  widths: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  markers: PropTypes.arrayOf(PropTypes.string),
  patientName: PropTypes.string,
  birth: PropTypes.string,
  reportDate: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  url: PropTypes.string.isRequired,
  onCheckBoxClick: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Row;
