import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import FormQuestion from '../FormQuestion';
import TextInput from '../TextInputNew';

import { DateErrorContainer, DateContainer, InputContainer, ErrorMessage, InputExpander } from './styles';

class DateInput extends Component {
  constructor(props) {
    super(props);

    const { value } = props;
    this.state = {
      isDateInvalid: false,
      day: value ? value.getDate() : '',
      month: value ? value.getMonth() + 1 : '',
      year: value ? value.getFullYear() : '',
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if (value !== prevProps.value) {
      this.setState({
        isDateInvalid: false,
        day: value ? value.getDate() : '',
        month: value ? value.getMonth() + 1 : '',
        year: value ? value.getFullYear() : '',
      });
    }
  }

  validateDate = () => {
    const { day, month, year } = this.state;
    const { onChangeDate, withoutFuture } = this.props;

    const touchedDay = day > 0 && day < 32;
    const touchedMonth = month > 0 && month < 13;
    const touchedYear = year >= 1870;

    if (!touchedDay || !touchedMonth || !touchedYear) {
      this.setState({ isDateInvalid: true });
      return;
    }

    const date = new Date(Date.UTC(year, month - 1, day));
    const isInRange = withoutFuture ? moment(date).isBetween('1870-01-01', undefined) : moment(date).isAfter('1870-01-01');

    if (moment(date).isValid() && isInRange) {
      this.setState({ isDateInvalid: false }, () => onChangeDate(date));
    } else {
      this.setState({ isDateInvalid: true });
    }
  };

  render() {
    const { isDateInvalid, day, month, year } = this.state;
    const { label, errorMessage, readMode, value, disabled, error } = this.props;
    const textValue = value ? moment(value).format('DD.MM.YYYY') : '';

    return (
      <FormQuestion question={label} inlineStyle>
        {readMode ? (
          <InputExpander>
            <TextInput readMode={readMode} value={textValue} mb={0} />
          </InputExpander>
        ) : (
          <DateErrorContainer>
            <DateContainer>
              <InputContainer>
                <TextInput
                  name="day"
                  type="number"
                  placeholder="DD"
                  value={day}
                  handleChangeText={(text) => {
                    this.setState({ day: text }, this.validateDate);
                  }}
                  error={isDateInvalid}
                  mb={0}
                  disabled={disabled}
                />
              </InputContainer>
              <InputContainer>
                <TextInput
                  name="month"
                  type="number"
                  placeholder="MM"
                  value={month}
                  handleChangeText={(text) => {
                    this.setState({ month: text }, this.validateDate);
                  }}
                  error={isDateInvalid}
                  mb={0}
                  disabled={disabled}
                />
              </InputContainer>
              <InputContainer>
                <TextInput
                  name="year"
                  type="number"
                  placeholder="YYYY"
                  value={year}
                  handleChangeText={(text) => {
                    this.setState({ year: text }, this.validateDate);
                  }}
                  error={isDateInvalid}
                  mb={0}
                  disabled={disabled}
                />
              </InputContainer>
            </DateContainer>
            <ErrorMessage isDateInvalid={isDateInvalid || error}>{typeof error === 'string' ? error : errorMessage}</ErrorMessage>
          </DateErrorContainer>
        )}
      </FormQuestion>
    );
  }
}

DateInput.defaultProps = {
  label: '',
  value: null,
  errorMessage: '',
  readMode: false,
  disabled: false,
  error: null,
  withoutFuture: false,
};

DateInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  errorMessage: PropTypes.string,
  onChangeDate: PropTypes.func.isRequired,
  readMode: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  withoutFuture: PropTypes.bool,
};

export default DateInput;
