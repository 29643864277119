import styled from 'styled-components';

import BaseTarmedsDef from 'containers/InvoicesForm/BaseTarmeds';
import DefWarning from 'components/Warning';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-top: 10px;
  border-top: ${({ $title }) => ($title ? '1px solid var(--color-grey97)' : 'none')};
  margin-top: 30px;
  &:before {
    content: '${({ $title }) => $title}';
    color: var(--color-grey6A);
    font-size: var(--font-size15);
    font-weight: var(--font-weight600);
  }
`;

export const BaseTarmeds = styled(BaseTarmedsDef)`
  margin-top: 20px;
`;

export const Warning = styled(DefWarning)`
  margin-bottom: 30px;
`;

export const Span = styled.span`
  display: block;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 0.3em;
  &:last-of-type {
    margin-bottom: 0;
  }
`;
