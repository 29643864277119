import styled from 'styled-components';

import Input2FA from 'components/MultiInputCode';

export const Input = styled(Input2FA)`
  margin: 90px auto;
`;

export const Description = styled.p`
  margin-bottom: 20px;
  font-size: var(--font-size14);
  line-height: 1.36;
  letter-spacing: 0.08px;
  color: var(--color-grey6A);
`;
