/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import KPI from '../../KPIValue';

import { Wrapper, ItemList, Title } from './styles';

const Item = ({ title, items, width }) => (
  <Wrapper width={width}>
    <Title>{title}</Title>
    <ItemList>
      {items.map((kpi, index) => (
        <KPI
          key={index}
          label={kpi.labelLocale}
          value={kpi.value}
          start={kpi.start}
          end={kpi.end}
          prevStart={kpi.prevStart}
          prevEnd={kpi.prevEnd}
          prevValue={kpi.prevValue}
          period={kpi.period}
          unit={kpi.unit}
          layout="small"
        />
      ))}
    </ItemList>
  </Wrapper>
);

Item.defaultProps = {
  width: '300px',
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  width: PropTypes.string,
};

export default Item;
