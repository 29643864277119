import { FIRST_NAME, LAST_NAME, BIRTH } from 'store/patients';
import { parseBirthday } from 'utils';

export const COMPARING = [
  (val, patient) => (val !== patient[FIRST_NAME] ? [FIRST_NAME, 'Vorname', patient[FIRST_NAME]] : null),
  (val, patient) => (val !== patient[LAST_NAME] ? [LAST_NAME, 'Nachname', patient[LAST_NAME]] : null),
  (val, patient) => {
    const date = parseBirthday(patient[BIRTH]);

    return parseBirthday(val) !== date ? [BIRTH, 'Geb.-Datum', date] : null;
  },
];

export const CHECKBOXES = [
  { name: 'Stimmt überein', value: 2 },
  { name: 'Neuer Patient', value: 3 },
];
