import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import KPIValue from './Item';
import { Wrapper, TitleWrapper, Label, Change, Values } from './styles';

const KPI = ({ label, start, end, unit, value, period, prevStart, prevEnd, prevValue, layout }) => {
  const hasChange = useMemo(() => period && period !== 'ALL', [period]);

  const growth = useMemo(() => (prevValue > 0 ? (value - prevValue) / prevValue : null), [prevValue, value]);

  return (
    <Wrapper layout={layout}>
      <TitleWrapper>
        <Label>{label}</Label>
        {hasChange && prevValue > 0 && (
          <Change isGrowing={growth >= 0}>{`${growth >= 0 ? '+' : ''}${Math.round(100 * growth)}%`}</Change>
        )}
      </TitleWrapper>
      <Values layout={layout}>
        <KPIValue start={start} end={end} unit={unit} value={value} hasChange={hasChange} layout={layout} />
        {hasChange && (
          <KPIValue start={prevStart} end={prevEnd} unit={unit} value={prevValue} hasChange={hasChange} layout={layout} prev />
        )}
      </Values>
    </Wrapper>
  );
};

KPI.defaultProps = {
  start: null,
  end: null,
  unit: null,
  period: 'ALL',
  prevStart: null,
  prevEnd: null,
  prevValue: null,
  layout: 'default',
};

KPI.propTypes = {
  label: PropTypes.string.isRequired,
  start: PropTypes.string,
  end: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.number.isRequired,
  period: PropTypes.string,
  prevStart: PropTypes.string,
  prevEnd: PropTypes.string,
  prevValue: PropTypes.number,
  layout: PropTypes.string,
};

export default KPI;
