import { omit } from 'ramda';
import { handleActions } from 'redux-actions';

import { SUCCESS, ERROR, ERRORS, LOADING } from './consts';
import { SET_LOADING, SET_SUCCESS, SET_ERROR, SET_ERRORS, SET_CANCEL } from './types';

export default handleActions(
  {
    [SET_LOADING]: (state, { payload }) => ({
      ...state,
      [payload]: {
        [LOADING]: true,
      },
    }),
    [SET_SUCCESS]: (state, { payload }) => {
      const { id, success } = payload;

      return {
        ...state,
        [id]: {
          [SUCCESS]: success || true,
        },
      };
    },
    [SET_ERROR]: (state, { payload }) => {
      const { id, error, errors } = payload;

      return {
        ...state,
        [id]: {
          [ERROR]: error,
          [ERRORS]: errors,
        },
      };
    },
    [SET_ERRORS]: (state, { payload }) => {
      const { id, errors } = payload;

      return {
        ...state,
        [id]: {
          [ERRORS]: errors,
        },
      };
    },
    [SET_CANCEL]: (state, { payload }) => omit([payload.id], state),
  },
  {}
);
