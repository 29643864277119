import styled from 'styled-components';

import Button from 'components/Button';

export const Btn = styled(Button)`
  width: ${({ width }) => (width ? `${width}%` : 'auto')};
  height: 40px;
  padding: 0 6px;
  margin-right: 7px;
  font-size: var(--font-size11);
  font-weight: var(--font-weight500);
  letter-spacing: 0.92px;
  color: var(--color-grey6A);
  word-break: break-word;
  text-align: center;
  &:last-child {
    margin-right: 0;
  }
`;
