import { handleActions } from 'redux-actions';

import { MAIN, APP } from './consts';
import { SET_MAIN_ERROR, SET_APP_ERROR, RESET_ERRORS } from './types';

const init = {
  [MAIN]: null,
  [APP]: null,
};

export default handleActions(
  {
    [SET_MAIN_ERROR]: (state, { payload }) => ({ ...state, [MAIN]: payload }),
    [SET_APP_ERROR]: (state, { payload }) => ({ ...state, [APP]: payload }),
    [RESET_ERRORS]: () => init,
  },
  init
);
