import styled from 'styled-components';
import { motion } from 'framer-motion';

import Msg from 'components/Message';

const getAnimate = ({ isOpen }) => ({
  animate: { x: isOpen ? 0 : 450 },
  transition: { type: 'spring', damping: 40, stiffness: 300 },
  get initial() {
    return this.animate;
  },
});

export const Container = styled(motion.div).attrs(getAnimate)`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  width: 450px;
  height: 1px;
  min-height: 100%;
  z-index: 100;
  background-color: var(--color-white);
  border-left: 1px solid var(--color-grey97);
  opacity: 0.95;
  overflow: auto;
`;

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid var(--color-grey97);
`;

export const Button = styled.button`
  width: 40px;
  height: 40px;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: var(--border-radius8);
  &:hover,
  &:focus {
    box-shadow: var(--shadow-focus);
  }
`;

export const Title = styled.p`
  font-size: var(--font-size18);
  color: var(--color-grey6A);
  font-weight: var(--font-weight500);
  margin-left: 12px;
  padding-bottom: 2px;
`;

export const Content = styled.div`
  width: 100%;
  padding: 15px 20px;
`;

export const Message = styled(Msg)`
  text-align: center;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: var(--color-greyEC);
  padding: 5px 7px;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  flex: 1 1 auto;
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  line-height: 1.15;
  letter-spacing: 0.92px;
  color: var(--color-grey6A);
  text-align: center;
  text-transform: uppercase;
`;

export const DownloadWrap = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border: 1px solid #21ea9f;
  border-radius: 50%;
`;
