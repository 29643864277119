import { useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { values, props } from 'ramda';

import { SORT, PAGINATION, CURSOR, LIMIT } from 'api/consts';
import { wrapActions, wrapMultiSelector } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { BUG_REPORT, usePermissions } from 'permissions';

import * as actions from './actions';
import { getBugReports, getBugReport, getBugReportProp, getBugReportsFilters } from './selectors';

export const useBugReportsActions = wrapActions({ ...actions });

export const useBugReport = (id, arg) => wrapMultiSelector(getBugReportProp(id), getBugReport(id))(arg);

export const useBugReports = () => {
  const can = usePermissions();
  const hasAccess = can.read(BUG_REPORT);
  const { id, extractId, loading } = useAlerts();
  const data = useSelector(getBugReports);
  const list = useMemo(() => values(data), [data]);
  const filters = useSelector(getBugReportsFilters);
  const [sort, pagination] = props([SORT, PAGINATION], filters);
  const [cursor, limit] = props([CURSOR, LIMIT], pagination);
  const { fetchBugReports } = useBugReportsActions();

  const requestData = useCallback(() => {
    extractId(fetchBugReports({ ...sort, [CURSOR]: cursor, [LIMIT]: limit }));
  }, [cursor, extractId, fetchBugReports, limit, sort]);

  useEffect(() => {
    if (hasAccess) requestData();
  }, [hasAccess, requestData]);

  return { list, loading: !id || loading, filters };
};
