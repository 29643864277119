import { string } from 'yup';

import { TOKEN, CLIENT_ID, MEDI_URL } from 'store/bugReports';

const ERROR_MESSAGE =
  'Die URL ist ungültig und kann nicht gespeichert werden. Gültige Beispiele: https://augenmobil.ch oder https://www.augenmobil.ch';

export const validationObject = {
  [TOKEN]: string().trim().required('Autorisierungsheader fehlt'),
  [CLIENT_ID]: string().trim().required('Client-ID fehlt'),
  [MEDI_URL]: string()
    .trim()
    .matches(process.env.NODE_ENV === 'production' ? /^https:\/\/.+/i : /^https?:\/\/.+/i, ERROR_MESSAGE)
    .customURL(ERROR_MESSAGE)
    .required('URL fehlt'),
};
