import { useMemo, useCallback, useEffect } from 'react';
import { filter, pipe, prop, includes, map } from 'ramda';

import api from 'api';
import { useAsyncState } from 'utils/useAsyncState';

const loadImage = async ({ path, mimetype, offline, ...rest }) => {
  const blobUrl = await api.loadFile({ url: path, mimetype, offline });

  return { id: path, blobUrl, ...rest };
};
const loadImages = (images) => Promise.all(map(loadImage)(images));

export const useLoadImage = (items) => {
  const [data, setData] = useAsyncState({ loading: true, list: [] });
  const images = useMemo(
    () =>
      pipe(
        filter(pipe(prop('mimetype'), includes('image'))),
        map(({ path, description, mimetype, originalname, date, isCurrent, offline }) => ({
          path,
          description: date ? `${description || originalname} (${date})` : description || originalname,
          mimetype,
          date,
          isCurrent,
          offline,
        }))
      )(items),
    [items]
  );

  const requestImages = useCallback(
    async (values) => {
      try {
        const list = await loadImages(values);

        setData({ loading: false, list });
      } catch (e) {
        setData(({ list }) => ({ list, error: 'Beim Laden der Daten tritt ein Fehler auf.' }));
      }
    },
    [setData]
  );

  useEffect(() => {
    requestImages(images);
  }, [images, requestImages]);

  useEffect(
    () => () => {
      if (data?.list?.length) {
        data.list.forEach(({ blobUrl }) => {
          (window.URL || window.webkitURL).revokeObjectURL(blobUrl);
        });
      }
    },
    [data.list]
  );

  return data;
};
