import React, { useMemo, useCallback, useEffect } from 'react';
import { useField } from 'formik';
import { pipe, props, filter, join, when, not, remove } from 'ramda';
import PropTypes from 'prop-types';

import { OTHER_CONTACTS, EMAIL, INSTITUTION, ZIP_CODE, TITLE, FIRST_NAME, LAST_NAME } from 'store/patients';
import { POSITION_MAP } from 'utils/constants';
import Button from 'components/AddItemBar';
import Accordion from 'components/Accordion';
import { contactValues } from '../consts';
import ContactForm from '../Contact';

import Removing from './Removing';

const Contacts = ({ errors, readMode }) => {
  const [{ value: contacts }, , { setValue: setContacts }] = useField(OTHER_CONTACTS);
  const isEmpty = !contacts?.length;
  const items = useMemo(
    () =>
      (contacts || []).map((data, index) => ({
        key: `${contacts?.length || 0}_${index}`,
        index,
        feature: [OTHER_CONTACTS, index],
        name: pipe(
          ($) => [POSITION_MAP[$[TITLE]], ...props([FIRST_NAME, LAST_NAME], $)],
          filter(Boolean),
          join(' '),
          when(not, () => 'Neue Kontaktperson')
        )(data),
      })),
    [contacts]
  );

  const onAdd = useCallback(() => setContacts([contactValues, ...contacts]), [setContacts, contacts]);
  const onRemove = useCallback((index) => setContacts(remove(index, 1, contacts)), [setContacts, contacts]);

  useEffect(() => {
    if (isEmpty) setContacts([contactValues]);
  }, [isEmpty, setContacts]);

  return (
    <>
      <Button label="Kontaktperson hinzufügen" onClick={onAdd} />
      {items.map(({ key, name, feature, index }) => (
        <Accordion key={key} label={name} defaultOpen={index === 0 || Boolean(errors && errors[index])}>
          <ContactForm feature={feature} errors={errors && errors[index]} readMode={readMode} />
          {!readMode && <Removing index={index} onRemove={onRemove} />}
        </Accordion>
      ))}
    </>
  );
};

Contacts.defaultProps = { errors: null };
Contacts.propTypes = {
  readMode: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    [EMAIL]: PropTypes.string,
    [INSTITUTION]: PropTypes.string,
    [ZIP_CODE]: PropTypes.string,
  }),
};

export default Contacts;
