import { useMemo } from 'react';
import { any, propEq, propOr } from 'ramda';

import { CORRESPONDENCE, OTHER_CONTACTS, usePatient } from 'store/patients';

import { contactNames } from './consts';

const checkContact = propOr(false, CORRESPONDENCE);
const checkContacts = any(propEq(true, CORRESPONDENCE));

export const useContactsForPatient = (patientId) => {
  const patient = usePatient(patientId) || null;

  return useMemo(
    () =>
      contactNames.filter(({ id }) =>
        id === 'contact' ? checkContacts(propOr([], OTHER_CONTACTS, patient)) : checkContact(propOr({}, id, patient))
      ),
    [patient]
  );
};
