import styled from 'styled-components';

import { ReactComponent as PlusIcon } from 'assets/svg-icons/plus.svg';

export const Button = styled.button`
  display: flex;
  align-items: center;
  column-gap: 10px;
  min-height: 25px;
  background-color: var(--color-greyEC);
  border: 1px solid;
  border-color: var(--color-greyEC);
  border-radius: var(--border-radius8);
  color: var(--color-grey6A);
  padding: 0 5px 0 10px;
  transition-property: background-color border-color;
  transition-duration: 0.3s;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  @media (hover) {
    &:hover {
      background-color: var(--color-white);
      border-color: var(--color-grey97);
    }
  }
`;

export const Text = styled.p`
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  letter-spacing: 0.92px;
  color: inherit;
`;

export const Icon = styled(PlusIcon)`
  width: 25px;
  height: 25px;
  color: inherit;
`;
