import React, { useMemo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { props } from 'ramda';
import PropTypes from 'prop-types';

import {
  usePatientTarmeds,
  useFilters,
  useInvoicesActions,
  useAlerts,
  SUB_PATIENT,
  LIMIT,
  SEARCH,
  SORT,
  TARMEDS_FILTERS,
} from 'store/invoices';
import { useGetScrollWidth } from 'containers/InvoicesForm/hooks';
import Table from 'containers/InvoicesForm/Table';
import TextInput from 'components/TextInputNew';

import { ScrollContainer, Line } from './styles';

const SearchList = ({ onAddService }) => {
  const { id } = useParams();
  const scrollWidth = useGetScrollWidth();
  const patientTarmeds = usePatientTarmeds();
  const filters = useFilters(TARMEDS_FILTERS);
  const [search, sort] = useMemo(() => props([SEARCH, SORT], filters), [filters]);
  const { fetchPatientTarmeds, updateSearch } = useInvoicesActions();
  const { action, loading } = useAlerts(fetchPatientTarmeds);
  const onChangeSearch = useCallback((value) => updateSearch({ kind: TARMEDS_FILTERS, value }), [updateSearch]);

  useEffect(() => {
    action({ [SUB_PATIENT]: id, [LIMIT]: 50, [SEARCH]: search, ...sort });
  }, [action, id, search, sort]);

  return (
    <>
      <TextInput type="search" placeholder="Tarmeds suchen" value={search} handleChangeText={onChangeSearch} />
      <div>
        <ScrollContainer $scrollWidth={scrollWidth}>
          <Table list={patientTarmeds} action={onAddService} loading={loading} />
        </ScrollContainer>
        <Line />
      </div>
    </>
  );
};

SearchList.propTypes = { onAddService: PropTypes.func.isRequired };

export default SearchList;
