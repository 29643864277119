import React from 'react';
import PropTypes from 'prop-types';

const ArrowDate = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11">
    <g fill="none" fillRule="evenodd" stroke={color}>
      <path
        d="M9.828 0L5.331 4.5 9.828 9"
        transform="translate(-315 -816) translate(257 694) translate(58 123) matrix(-1 0 0 1 15.159 0)"
      />
      <path d="M0 4.5L9.828 4.5" transform="translate(-315 -816) translate(257 694) translate(58 123) matrix(-1 0 0 1 9.828 0)" />
    </g>
  </svg>
);

ArrowDate.defaultProps = { color: '#000' };

ArrowDate.propTypes = { color: PropTypes.string };

export default ArrowDate;
