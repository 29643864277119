import { DOCTOR, OPHTHALMOLOGY, EMAIL, SHARE } from 'store/organizations';

export const SPECIALTY_LABELS = [
  { value: DOCTOR, label: 'Hausarzt' },
  { value: OPHTHALMOLOGY, label: 'Ophthalmologie' },
];

export const CHANNEL_LABELS = [
  { value: EMAIL, label: 'Via E-Mail-Anhang' },
  { value: SHARE, label: 'Via Augenmobil-System' },
];
