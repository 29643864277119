import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container, Label, TA } from './styles';

const TextArea = forwardRef(({ label, value, onChangeText, onChange, readMode, disabled, mb, ...rest }, ref) => {
  const handleChange = useCallback(
    (e) => {
      onChange(e);
      onChangeText(e.target.value);
    },
    [onChangeText, onChange]
  );

  return label ? (
    <Container>
      <Label>{label}</Label>
      <TA ref={ref} value={value} onChange={handleChange} disabled={disabled || readMode} readMode={readMode} mb={mb} {...rest} />
    </Container>
  ) : (
    <TA ref={ref} value={value} onChange={handleChange} disabled={disabled || readMode} readMode={readMode} mb={mb} {...rest} />
  );
});

TextArea.defaultProps = {
  label: null,
  value: '',
  onChangeText: () => null,
  onChange: () => null,
  disabled: false,
  readMode: false,
  mb: 10,
};
TextArea.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChangeText: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readMode: PropTypes.bool,
  mb: PropTypes.number,
};

export default TextArea;
