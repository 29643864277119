import { path, pipe, values, propOr, prop } from 'ramda';

import { FEATURE_NAME as $, LIST, REPORTS, PREVIEW_REPORT, DIAGNOSIS_CODES } from './consts';

export const getDiagnoses = path([$, LIST]);
export const getCurrentDiagnosis = pipe(path([$, LIST]), values, propOr({}, 0));
export const getCurrentDiagnosisProp = (name) => pipe(path([$, LIST]), values, propOr({}, 0), prop(name));
export const getDiagnosisReports = path([$, REPORTS]);
export const getDiagnosisReport = (id) => path([$, REPORTS, id]);
export const getDiagnosisPreviewReport = path([$, PREVIEW_REPORT]);
export const getDiagnosisCodes = path([$, DIAGNOSIS_CODES]);
