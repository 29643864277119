import styled from 'styled-components';

export const Container = styled.div`
  margin-top: ${({ isFirst }) => (isFirst ? 0 : 20)}px;
  padding-top: 10px;
  margin-bottom: 50px;
  border-top: 1px solid var(--color-grey97);
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  color: var(--color-grey6A);
  font-size: var(--font-size15);
  font-weight: var(--font-weight600);
`;

export const Button = styled.button`
  display: flex;
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 9px;
  &:hover {
    background-color: var(--color-greyF3);
  }
  &,
  & > * {
    transition: all 0.3s;
  }
`;

export const Flex = styled.div`
  display: flex;
  width: 100%;
`;

export const TitleWrap = styled.div`
  width: ${({ width }) => (width ? `${width}px` : '80px')};
  min-width: 80px;
  margin-right: 6px;
  &:last-child {
    margin-right: 0;
  }
`;

const smallText = `
  font-size: var(--font-size13);
  font-weight: var(--font-weight500);
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: var(--color-grey6A);
`;

export const Title = styled.p`
  ${smallText}
  min-height: 24px;
`;

export const SubTitle = styled(Title)`
  ${smallText}
  padding-top: 5px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  min-height: 40px;
  min-width: 80px;
  border-radius: var(--border-radius8);
  background-color: ${({ $isRed, gray }) => ($isRed && '#fdd7d7') || (gray && 'var(--color-greyEC)') || 'none'};
  color: ${({ gray }) => (gray ? 'var(--color-grey97)' : 'var(--color-grey6A)')};
  font-size: var(--font-size13);
  font-weight: ${({ gray }) => (gray ? 'var(--font-weight500)' : 'var(--font-weight400)')};
  line-height: 1.15;
  letter-spacing: 0.5px;
  padding: 0 13px;
  margin-right: 6px;
  &:last-child {
    margin-right: 0;
  }
`;

export const ItemWrap = styled.div`
  border-radius: var(--border-radius8);
  margin: 5px 0;
  ${({ isActive }) =>
    isActive &&
    `
    background-color: var(--color-greenF6);
    border: 1px solid var(--color-blue50);
  `}
  &:first-child {
    margin-top: 16px;
  }
`;

export const DateText = styled.p`
  ${smallText}
  width: 100%;
  border-bottom: 1px solid #cfcfcf;
  padding: 7px 13px;
`;
