import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html, body {
    min-width: 1100px;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "IBM Plex Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    -moz-osx-font-smoothing: grayscale;
  }
  .show-sw-notification {
    height: 40px !important;
  }
  .droppableHover {
    outline: 2px solid var(--color-green65);
    border-color: var(--color-green65);
  }
  &:root {
    --color-black: #000;
    --color-white: #fff;
    --color-red: #f00;

    --color-grey6A: #6a6a6a;
    --color-grey97: #979797;
    --color-greyDC: #dcdcdc;
    --color-greyEC: #ececec;
    --color-greyF3: #f3f3f3;
    --color-greyF8: #f8f8f8;
    
    --color-orangeDC: #dc8d0b;
    --color-orangeF5: #f57728;
    --color-orangeFE: #fee9db;
    --color-orangeF7: #f79253;
    
    --color-blue2E: #2e89ff;
    --color-blue4A: #4a90e2;
    --color-blue50: #50e3c2;
    --color-blue5D: #5da4ff;
    --color-blue8E: #8ebfff;
    --color-blueC7: #c7c7cc;
    
    --color-green50: #50ac81;
    --color-green65: #65d8a2;
    --color-green86: #86deb5;
    --color-greenF6: #f6fefc;

    --color-redED: #ed2929;

    --font-size10: 10px;
    --font-size11: 11px;
    --font-size12: 12px;
    --font-size13: 13px;
    --font-size14: 14px;
    --font-size15: 15px;
    --font-size18: 18px;
    --font-size20: 20px;
    --font-size23: 23px;
    --font-size27: 27px;
    --font-size30: 30px;

    --font-weight100: 100;
    --font-weight200: 200;
    --font-weight300: 300;
    --font-weight400: 400;
    --font-weight500: 500;
    --font-weight600: 600;
    --font-weight700: 700;
    --font-weight800: 800;
    --font-weight900: 900;

    --shadow-focus: 0 0 5px rgba(0, 0, 0, 0.3);
    --shadow-input-focus: inset 0 0 3px 0 #6e6e6e;

    --border-radius8: 8px;
  }
`;
