import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export const Item = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: start;
  column-gap: 10px;
  width: 100%;
  position: relative;
`;

export const Label = styled.p`
  align-self: center;
  font-size: var(--font-size15);
  font-weight: var(--font-weight400);
  color: var(--color-grey6A);
`;

export const Button = styled.button.attrs(() => ({ type: 'button' }))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border: 2px solid;
  border-radius: 50%;
  border-color: ${({ $error, $gray }) => ($error && 'var(--color-orangeF5)') || ($gray && '#eaeaea') || '#21ea9f'};
  & g {
    stroke: ${({ $error, $gray }) => ($error && 'var(--color-orangeF5)') || ($gray && '#eaeaea') || '#21ea9f'};
  }
`;
