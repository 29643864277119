import { pipe, propOr, props, filter, join, trim } from 'ramda';

import { FIRST_NAME, LAST_NAME } from 'store/reports';
import { parseBirthday, parseDate } from 'utils';

export const headerFields = (checkerName) => [
  {
    id: 'selected',
    content: checkerName,
    width: '5%',
    withoutSort: true,
  },
  {
    id: 'patientName',
    content: 'Name',
    width: '55%',
    withoutSort: true,
  },
  {
    id: 'birth',
    content: 'Geburtstag',
    width: '20%',
    withoutSort: true,
  },
  {
    id: 'meta.created',
    content: 'Bericht vom',
    width: '20%',
  },
];

export const joinName = (feature) => pipe(propOr({}, feature), props([FIRST_NAME, LAST_NAME]), filter(Boolean), join(', '), trim);

export const handleFields = ({ birth, reportDate, ...rest }) => ({
  ...rest,
  birth: parseBirthday(birth),
  reportDate: parseDate(reportDate),
});
