import { EXAMINATION_LABELS } from 'store/examinations';
import palette from 'theme/palette';

const exam = palette.examination;

export const statuses = [
  {
    id: 0,
    key: 'IN_PREPARATION',
    name: EXAMINATION_LABELS[0],
    color: exam[0],
  },
  {
    id: 1,
    key: 'SUBMITTED',
    name: EXAMINATION_LABELS[1],
    color: exam[1],
  },
  {
    id: 2,
    key: 'UNDER_REVIEW',
    name: EXAMINATION_LABELS[2],
    color: exam[2],
  },
  {
    id: 3,
    key: 'IN_APPROVAL',
    name: EXAMINATION_LABELS[3],
    color: exam[3],
  },
  {
    id: 4,
    key: 'APPROVED',
    name: EXAMINATION_LABELS[4],
    color: exam[4],
  },
  {
    id: 5,
    key: 'RELEASED',
    name: EXAMINATION_LABELS[5],
    color: exam[5],
  },
  {
    id: 6,
    key: 'SENT',
    name: EXAMINATION_LABELS[6],
    color: exam[6],
  },
  {
    id: 7,
    key: 'ABORTED',
    name: EXAMINATION_LABELS[7],
    color: exam[7],
  },
];
