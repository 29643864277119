import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { pipe, map, propOr, filter } from 'ramda';

import { INFO_BOX, FILE } from 'store/infoItems';

export const useTogglePanel = () => {
  const { push, location } = useHistory();
  const { hash, pathname, state } = location;

  return [hash === `#${INFO_BOX}`, useCallback(() => push(pathname, state), [pathname, push, state])];
};

export const useAllAttachments = (attachments) =>
  useMemo(() => pipe(map(propOr(null, FILE)), filter(Boolean))(attachments), [attachments]);
