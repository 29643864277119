import React, { useCallback, useEffect } from 'react';
import { pathOr, values } from 'ramda';
import PropTypes from 'prop-types';

import getWebSocket from 'api/webSocket';
import { usePlanningActions, useAlerts, SUB_SCHEDULE, WARD } from 'store/planning';
import { SEARCHABLE_MEDIA_UPDATE } from 'store/medicalHistory';
import QuestionContainer from 'components/QuestionContainer';
import AddButtonIcon from 'assets/svg-icons/AddButtonIcon';
import Spinner from 'components/FullScreenSpinner';
import Message from 'components/Message';

import Section from './Section';
import { Button, Wrapper, SpinnerWrap } from './styles';

const UploadMultiPDF = ({ files, updateFiles, refetchMedias, refetchLoading }) => {
  const { saveScheduleFormMedia } = usePlanningActions();
  const { action, loading, success, error, resetAlerts } = useAlerts(saveScheduleFormMedia);
  const onClick = useCallback(() => {
    updateFiles(($) => [
      { index: 0, ward: '', items: [], wait: [], created: new Date().valueOf() },
      ...$.map((item, i) => ({ ...item, index: i + 1 })),
    ]);
  }, [updateFiles]);

  useEffect(() => {
    const socket = getWebSocket(true, 'medicalHistory');
    const update = (data) => {
      if (!data[SUB_SCHEDULE]) return;

      const ward = pathOr('', ['form', WARD], data);

      updateFiles(($) => {
        const reduced = values(
          $.reduce((acc, item) => {
            const key = item.ward || null;

            if (acc[key]) {
              acc[key].items = [...acc[key].items, ...item.items];
            } else {
              acc[key] = item;
            }

            return acc;
          }, {})
        );

        return reduced
          .sort((a, b) => b.created - a.created)
          .map((item, index) => ({
            ...item,
            index,
            ...(item.ward === ward && {
              items: [...item.items, data],
              wait: item.wait.filter((name) => name !== data.originalname),
            }),
          }));
      });
    };

    socket.on(SEARCHABLE_MEDIA_UPDATE, update);

    return () => {
      socket.off(SEARCHABLE_MEDIA_UPDATE, update);
    };
  }, [updateFiles]);

  useEffect(() => {
    if (success) {
      refetchMedias();
      resetAlerts();
    }
  }, [refetchMedias, resetAlerts, success]);

  return (
    <QuestionContainer label="Einverständniserklärungen">
      <Wrapper>
        <Button type="button" onClick={onClick}>
          <span>Neue Station hinzufügen</span>
          <AddButtonIcon />
        </Button>
        {error && (
          <Message type="error" form={1}>
            {error}
          </Message>
        )}
        {files.map(({ index, ward, items, wait }) => (
          <Section
            key={index}
            index={index}
            ward={ward}
            files={items}
            waitFiles={wait}
            onChange={updateFiles}
            saveMedia={action}
          />
        ))}
        {(loading || refetchLoading) && (
          <SpinnerWrap>
            <Spinner height="100%" />
          </SpinnerWrap>
        )}
      </Wrapper>
    </QuestionContainer>
  );
};

UploadMultiPDF.defaultProps = { refetchLoading: false };
UploadMultiPDF.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
      ward: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
      wait: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }).isRequired
  ).isRequired,
  updateFiles: PropTypes.func.isRequired,
  refetchMedias: PropTypes.func.isRequired,
  refetchLoading: PropTypes.bool,
};

export default UploadMultiPDF;
