import React from 'react';

import { useShouldCheck2FA } from 'permissions';
import Accordion from 'components/Accordion';

import Psd from './Psd';
import TwoFactorAuth from './TwoFactorAuth';
import { Container } from './styles';

const Security = () => {
  const shouldCheck2FA = useShouldCheck2FA();

  return (
    <Container>
      <Accordion label="Passwort ändern" defaultOpen={!shouldCheck2FA}>
        <Psd />
      </Accordion>
      {shouldCheck2FA && (
        <Accordion label="Zwei-Faktor-Authentifizierung" defaultOpen>
          <TwoFactorAuth />
        </Accordion>
      )}
    </Container>
  );
};

export default Security;
