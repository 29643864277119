import React, { useMemo } from 'react';
import moment from 'moment';
import { pipe, props, filter, join } from 'ramda';
import PropTypes from 'prop-types';

import ArrowRight from 'assets/svg-icons/ArrowRight';

import { Container, Button, ContentContainer, LeftSide, RightSide, Kind, CreatedDate } from './styles';

const Panel = ({ redirectBack, user, email, kind, date }) => {
  const name = useMemo(() => pipe(props(['lastname', 'firstname']), filter(Boolean), join(', '))(user), [user]);

  return (
    <Container>
      <Button type="button" onClick={redirectBack}>
        <ArrowRight />
      </Button>
      <ContentContainer>
        <LeftSide>{name || email || ''}</LeftSide>
        <RightSide>
          <Kind>{kind}</Kind>
          <CreatedDate>{date && moment(date).format('DD.MM.YYYY')}</CreatedDate>
        </RightSide>
      </ContentContainer>
    </Container>
  );
};

Panel.defaultProps = {
  user: {},
  email: '',
  kind: '',
  date: null,
};

Panel.propTypes = {
  redirectBack: PropTypes.func.isRequired,
  user: PropTypes.shape({
    lastname: PropTypes.string,
    firstname: PropTypes.string,
  }),
  email: PropTypes.string,
  kind: PropTypes.string,
  date: PropTypes.string,
};

export default Panel;
