/* eslint-disable react/no-array-index-key */
import React, { useCallback, useMemo, Fragment, useEffect } from 'react';
import { useField } from 'formik';
import { ifElse, pipe, path, is, map } from 'ramda';
import PropTypes from 'prop-types';

import { DIAGNOSIS, RIGHT, LEFT } from 'store/diagnoses';
import AnimateBox from 'components/AnimateBox';
import { CoupleWrap } from '../styles';

import MultiCheckBox from './MultiCheckBox';
import CheckBox from './CheckBox';
import { ItemWrap, Button, Wrapper } from './styles';

const MultiButton = ({
  id,
  activeSides,
  items,
  label,
  subButton,
  readMode,
  type,
  parentValue,
  parentSide,
  parentType,
  include,
  includedIn,
  exclude,
}) => {
  const [{ value }, , { setValue }] = useField(DIAGNOSIS);

  const updateValue = useCallback(
    (side, val) => {
      const result = {
        ...value,
        [side]: {
          ...value[side],
          [id]: val,
        },
      };

      if (val && include) {
        include.forEach((item) => {
          result[side][item] = true;
        });
      }

      if (val && exclude) {
        exclude.forEach((item) => {
          result[side][item] = false;
        });
      }

      setValue(result);
    },
    [id, setValue, value, include, exclude]
  );

  const handleClick = useCallback(
    (e) => {
      const { side } = e.target.dataset;

      if (includedIn && includedIn.find((item) => value[side][item])) return;
      if (side) {
        updateValue(side, !value[side][id]);
      }
    },
    [updateValue, value, id, includedIn]
  );

  const mapping = useMemo(() => (type === 'both' ? ['both'] : activeSides), [type, activeSides]);
  const Container = useMemo(() => (type === 'both' ? Fragment : CoupleWrap), [type]);

  useEffect(() => {
    if (parentValue) return;

    if (parentType === 'both' && value[LEFT][id]) {
      updateValue(LEFT, false);
    }

    if (parentType === 'both' && value[RIGHT][id]) {
      updateValue(RIGHT, false);
    }

    if (parentSide && value[parentSide][id]) {
      updateValue(parentSide, false);
    }
  }, [parentValue, parentSide, parentType, id, type, value, updateValue]);

  if (!activeSides[0] && !activeSides[1]) return null;

  return (
    <Container>
      {mapping.map((key) => {
        if (!key) return <ItemWrap key={key} />;

        const side = type === 'both' ? LEFT : key;

        return (
          <ItemWrap key={key} singleItem={key === 'both'}>
            <Button
              data-side={side}
              type="button"
              isActive={value[side][id]}
              onClick={handleClick}
              disabled={readMode || (exclude && exclude.find((item) => value[side][item]))}
            >
              {label}
            </Button>
            <AnimateBox isOpen={Boolean(value[side][id])}>
              <Wrapper>
                {items.map((item, idx) =>
                  ifElse(
                    pipe(path([0, 'value']), is(String)),
                    (data) => (
                      <MultiCheckBox
                        key={idx}
                        items={data}
                        readMode={readMode}
                        subItem={subButton}
                        side={side}
                        parentValue={value[side][id]}
                      />
                    ),
                    map(
                      ({
                        id: subId,
                        label: subLabel,
                        checkboxes,
                        type: subType,
                        include: subInclude,
                        includedIn: subIncludeIn,
                        exclude: subExclude,
                      }) =>
                        checkboxes ? (
                          <MultiButton
                            key={subId}
                            id={subId}
                            activeSides={activeSides}
                            items={checkboxes}
                            label={subLabel}
                            readMode={readMode}
                            type={subType}
                            parentValue={value[side][id]}
                            parentSide={side}
                            parentType={type}
                            include={subInclude}
                            includedIn={subIncludeIn}
                            exclude={subExclude}
                          />
                        ) : (
                          <CheckBox
                            key={subId}
                            id={subId}
                            label={subLabel}
                            readMode={readMode}
                            side={side}
                            parentValue={value[side][id]}
                          />
                        )
                    )
                  )(item)
                )}
              </Wrapper>
            </AnimateBox>
          </ItemWrap>
        );
      })}
    </Container>
  );
};

MultiButton.defaultProps = {
  subButton: false,
  readMode: false,
  type: 'each',
  parentValue: true,
  parentSide: null,
  parentType: 'each',
  include: null,
  includedIn: null,
  exclude: null,
};

MultiButton.propTypes = {
  id: PropTypes.string.isRequired,
  activeSides: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
        label: PropTypes.string.isRequired,
        checkboxes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
      }).isRequired
    )
  ).isRequired,
  label: PropTypes.string.isRequired,
  subButton: PropTypes.bool,
  readMode: PropTypes.bool,
  type: PropTypes.oneOf(['each', 'both']),
  parentValue: PropTypes.bool,
  parentSide: PropTypes.oneOf([LEFT, RIGHT]),
  parentType: PropTypes.oneOf(['each', 'both']),
  include: PropTypes.arrayOf(PropTypes.string.isRequired),
  includedIn: PropTypes.arrayOf(PropTypes.string.isRequired),
  exclude: PropTypes.arrayOf(PropTypes.string.isRequired),
};

export default MultiButton;
