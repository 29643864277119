import React, { useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { EXAMINATION_REAL_TIME, PATIENTS, PATIENT, usePermissions } from 'permissions';

import ExaminationsTasks from './ExaminationsTasks';
import OpenExaminations from './OpenExaminations';
import Search from './Search';
import Duplicates from './Duplicates';
import { Container, Wrapper, Button } from './styles';

const Patients = () => {
  const can = usePermissions();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const navItems = useMemo(
    () => [
      {
        label: 'Live',
        path: '/patients/tasks',
        component: <ExaminationsTasks />,
        hasAccess: can.redirect(EXAMINATION_REAL_TIME),
      },
      {
        label: 'Offene Untersuchungen',
        path: '/patients',
        component: <OpenExaminations />,
        hasAccess: can.redirect(PATIENTS),
      },
      {
        label: 'Alle Patienten',
        path: '/patients/search',
        component: <Search />,
        hasAccess: can.redirect(PATIENTS),
      },
      {
        label: 'Doppelte Patienten',
        path: '/patients/duplicates',
        component: <Duplicates />,
        hasAccess: can.update(PATIENT),
      },
    ],
    [can]
  );
  const [activeItem = navItems[1]] = useMemo(() => navItems.filter(({ path }) => pathname === path), [pathname, navItems]);
  const onClick = useCallback(({ currentTarget }) => push(currentTarget.value), [push]);

  return (
    <Container>
      <Wrapper>
        {navItems.map(({ label, path, hasAccess }) =>
          hasAccess ? (
            <Button key={path} type="button" value={path} isActive={activeItem.path === path} onClick={onClick}>
              {label}
            </Button>
          ) : null
        )}
      </Wrapper>
      {activeItem.component}
    </Container>
  );
};

export default Patients;
