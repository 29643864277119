import { takeLatest, throttle, call, put, select, takeLeading } from 'redux-saga/effects';
import { prop } from 'ramda';

import { withAlert } from 'store/alerts';
import api from 'api';
import { DEFAULT_ERROR } from 'api/consts';

import { authenticate, authenticate2FA, updateUser, update2FALink, updateUserData } from './actions';
import { getUserId } from './selectors';
import {
  LOGIN,
  REQUEST_EXTERN_CODE,
  RESTORE,
  FETCH_RESET_LINK,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  FETCH_LINK_2FA,
  FETCH_2FA,
  VALIDATE_2FA,
  RESET_2FA,
  SAVE_USER_DATA,
  GET_EXTERNAL_EMAIL,
} from './types';

function* login({ payload }) {
  const data = yield call(api.login, payload);
  const error = prop('error', data);

  if (!error) {
    yield put(authenticate(data));
  }

  return data;
}

function* requestExternCode({ payload }) {
  const data = yield call(api.requestExternCode, payload);
  return data;
}

function* restore() {
  const data = yield call(api.restore);
  yield put(updateUser(data));
}

function* fetchResetLink({ payload }) {
  const data = yield call(api.sendResetLink, payload);
  return data;
}

function* resetPassword({ payload }) {
  const data = yield call(api.resetPassword, payload);
  return data;
}

function* updatePassword({ payload }) {
  const id = yield select(getUserId);
  const data = yield call(api.updatePassword, id, payload);

  yield put(updateUser(data));
}

function* fetch2FALink() {
  const data = yield call(api.create2FA);
  yield put(update2FALink(data));
}

function* fetch2FA({ payload }) {
  const data = yield call(api.post2FA, payload);
  const error = prop('error', data);

  if (!error) {
    yield put(authenticate2FA(data));
  }

  return data;
}

function* validate2FA({ payload }) {
  const data = yield call(api.validate2FA, payload);
  return data;
}

function* reset2FA() {
  const data = yield call(api.delete2FA);
  yield put(authenticate(data));
}

function* saveUserData({ payload }) {
  const id = yield select(getUserId);
  const data = yield call(api.patchUserData, { id, ...payload });
  yield put(updateUserData(data));
}

function* getExternEmail({ payload }) {
  const { email, error } = yield call(api.getEmailByHash, payload);

  if (error || !email) return { error: error || DEFAULT_ERROR };

  return { success: email };
}

export default function* watchSession() {
  yield takeLatest(LOGIN, withAlert(login));
  yield takeLatest(REQUEST_EXTERN_CODE, withAlert(requestExternCode));
  yield takeLatest(RESTORE, withAlert(restore));
  yield takeLatest(FETCH_RESET_LINK, withAlert(fetchResetLink));
  yield takeLatest(RESET_PASSWORD, withAlert(resetPassword));
  yield takeLeading(UPDATE_PASSWORD, withAlert(updatePassword));
  yield takeLatest(FETCH_LINK_2FA, withAlert(fetch2FALink));
  yield takeLatest(FETCH_2FA, withAlert(fetch2FA));
  yield takeLatest(VALIDATE_2FA, withAlert(validate2FA));
  yield takeLatest(RESET_2FA, withAlert(reset2FA));
  yield throttle(500, SAVE_USER_DATA, withAlert(saveUserData));
  yield takeLatest(GET_EXTERNAL_EMAIL, withAlert(getExternEmail));
}
