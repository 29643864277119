import React from 'react';
import { useLocation } from 'react-router-dom';

import { usePermissions, INSIGHTS, LLM } from 'permissions';

import { NavLink } from './styles';

const LlmLink = () => {
  const can = usePermissions();
  const { pathname } = useLocation();
  const hasAccess = (can.read(INSIGHTS) && pathname === '/llm') || (can.read(LLM) && pathname === '/insights');

  return hasAccess && <NavLink to={pathname === '/llm' ? '/insights' : '/llm'}>Frage stellen</NavLink>;
};

export default LlmLink;
