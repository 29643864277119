import { takeEvery, takeLatest, call, put, debounce, select, all } from 'redux-saga/effects';
import { prop, filter, path, pipe, head, propOr } from 'ramda';

import { withAlert } from 'store/alerts';
import api from 'api';

import { ID, FILES, EXPAND, SUB_PATIENT } from '.';
import { updateMedicalHistory } from './actions';
import { getMedicalHistoryProp } from './selectors';
import {
  FETCH_MEDICAL_HISTORY,
  FETCH_COLLECT_MEDICAL_HISTORY,
  SAVE_MEDICAL_HISTORY,
  UPLOAD_MEDIA_DATA,
  FETCH_SEARCHABLE_MEDIA,
  UPDATE_SEARCHABLE_MEDIA,
  DELETE_SEARCHABLE_MEDIA,
} from './types';

const query = { [EXPAND]: ['files'] };

function* createMedicalHistory({ payload }) {
  const data = yield call(api.postMedicalHistory, { [SUB_PATIENT]: payload });
  yield put(updateMedicalHistory(data));
}

function* fetchMedicalHistory({ payload }) {
  const { data } = yield call(api.getMedicalHistory, { [SUB_PATIENT]: payload, ...query });

  if (path([0, ID], data)) {
    yield pipe(head, updateMedicalHistory, put)(data);
  } else {
    yield createMedicalHistory({ payload });
  }
}

function* fetchCollectMedicalHistory({ payload }) {
  const { data } = yield call(api.getMedicalHistory, payload);
  return { success: data && data[0] };
}

function* saveMedicalHistory({ payload }) {
  const medicalHistoryId = yield select(getMedicalHistoryProp(ID));
  const data = yield call(
    api.patchMedicalHistory,
    {
      ...payload,
      medicalHistoryId,
      ...(payload[FILES] ? { [FILES]: payload[FILES].map((file) => propOr(file, ID, file)) } : {}),
    },
    query
  );
  yield put(updateMedicalHistory(data));
}

function* uploadMediaData({ payload }) {
  const { files, patientId } = payload;
  const media = yield select(getMedicalHistoryProp(FILES)) || [];
  const data = yield all(files.map((file) => call(api.uploadSearchableMedia, file, patientId)));

  return {
    success: [...media, ...filter(prop('filename'), data)],
  };
}

function* fetchSearchableMedia({ payload }) {
  const { id, search } = payload;

  const success = yield call(api.getSearchableMedia, id, { query: search });

  return { success };
}

function* updateSearchableMedia({ payload }) {
  yield call(api.patchSearchableMedia, payload);
}

function* deleteSearchableMedia({ payload }) {
  yield call(api.deleteSearchableMedia, payload);
}

export default function* watchMedicalHistory() {
  yield takeLatest(FETCH_MEDICAL_HISTORY, withAlert(fetchMedicalHistory));
  yield takeEvery(FETCH_COLLECT_MEDICAL_HISTORY, withAlert(fetchCollectMedicalHistory));
  yield debounce(500, SAVE_MEDICAL_HISTORY, withAlert(saveMedicalHistory));
  yield takeEvery(UPLOAD_MEDIA_DATA, withAlert(uploadMediaData));
  yield debounce(500, FETCH_SEARCHABLE_MEDIA, withAlert(fetchSearchableMedia));
  yield debounce(500, UPDATE_SEARCHABLE_MEDIA, withAlert(updateSearchableMedia));
  yield takeEvery(DELETE_SEARCHABLE_MEDIA, withAlert(deleteSearchableMedia));
}
