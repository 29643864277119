import styled from 'styled-components';

import Btn from 'components/Button';

export const ItemWrap = styled.div`
  width: ${({ singleItem }) => (singleItem ? '100%' : 'calc(50% - 7px)')};
`;

export const Button = styled(Btn)`
  height: 40px;
  margin-bottom: 24px;
  &:disabled {
    color: #bcbcbc;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 10px);
  margin: auto;
  padding-top: 1px;
`;

export const CheckBoxWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: ${({ inActive }) => (inActive ? 0.44 : 1)};
  margin-bottom: 16px;
`;
