import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import { Container, ConfirmContainer, Label, NoButton } from './styles';

const ConfirmButton = ({ onClick, disabled, children }) => {
  const [isOpen, setIsOpen] = useState();
  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClickYes = useCallback(() => {
    onClick();
    setIsOpen(false);
  }, [onClick]);
  const handleClickNo = useCallback(() => setIsOpen(false), []);

  return (
    <Container>
      <Button type="button" color="warning" onClick={handleOpen}>
        {children}
      </Button>
      <ConfirmContainer isOpen={isOpen}>
        <Label>Sind Sie sicher?</Label>
        <Button type="button" onClick={handleClickYes} disabled={disabled} height="40px">
          JA
        </Button>
        <NoButton type="button" color="warning" onClick={handleClickNo}>
          Nein
        </NoButton>
      </ConfirmContainer>
    </Container>
  );
};

ConfirmButton.defaultProps = { disabled: false };

ConfirmButton.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ConfirmButton;
