import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  max-height: ${({ $isFullHeight }) => ($isFullHeight ? 'none' : 'calc((100% - 46px) / 2)')};
  width: 100%;
  border-bottom: 1px solid var(--color-grey97);
`;

export const Title = styled.p`
  font-size: var(--font-size20);
  font-weight: var(--font-weight500);
  padding-right: 10px;
  border-width: 1px 0;
  padding: 5px 10px 0;
`;

export const Wrapper = styled.div`
  flex: 1 1 auto;
  width: 100%;
  overflow: auto;
  padding: 0 10px 0 5px;
  opacity: ${({ $isPending }) => ($isPending ? 0.5 : 1)};
`;

export const Item = styled(motion.button)`
  display: flex;
  align-items: center;
  column-gap: 5px;
  min-height: 25px;
  width: 100%;
  border: 1px solid;
  border-color: ${({ $isSelected }) => ($isSelected ? 'var(--color-grey6A)' : 'transparent')};
  border-radius: var(--border-radius8);
  background-color: ${({ $isHovered, $isSelected }) =>
    ($isHovered && 'var(--color-greyF8)') || ($isSelected && 'var(--color-greyF8)') || 'var(--color-white)'};
  font-size: var(--font-size15);
  color: var(--color-black);
  text-align: left;
  word-break: break-word;
  padding: 0 5px;
  cursor: pointer;
  margin-bottom: 5px;
  &:before {
    content: '';
    height: 10px;
    width: 10px;
    min-width: 10px;
    border-radius: 50%;
    background-color: ${({ $color }) => $color};
  }
`;

export const InputWrap = styled.div`
  margin: 5px 10px;
`;

export const Message = styled.p`
  font-size: var(--font-size15);
  color: var(--color-black);
  word-break: break-word;
  padding: 5px;
`;
