import { useMemo, useState, useCallback, useLayoutEffect, useRef } from 'react';

import { usePermissions } from 'permissions';

import { items } from './utils';

const MAX_AMOUNT = items.length;

export const useNavigationItems = () => {
  const can = usePermissions();

  return useMemo(() => items.filter(({ resource }) => can.redirect(resource)), [can]);
};

export const useAmountOfItems = () => {
  const last = useRef(1);
  const [amount, setAmount] = useState(1);
  const countAmount = useCallback(() => {
    const quantity = Math.floor((window.innerWidth - 2 * (230 + 20 + 20) - 2 * 160) / 160);

    if (!quantity || quantity < 0 || last.current === quantity || (quantity >= MAX_AMOUNT && last.current === MAX_AMOUNT)) return;

    setAmount(Math.min(MAX_AMOUNT, quantity));
    last.current = Math.min(MAX_AMOUNT, quantity);
  }, []);

  useLayoutEffect(() => {
    countAmount();

    window.addEventListener('resize', countAmount);

    return () => window.removeEventListener('resize', countAmount);
  }, [countAmount]);

  return amount;
};
