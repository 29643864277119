import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ readMode }) => (readMode ? 0 : 10)}px;
`;

export const InputExpander = styled.div`
  flex: 1 1 0%;
`;

export const DateErrorContainer = styled.div``;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputContainer = styled.div`
  width: 70px;
  margin-right: 5px;
  &:last-child {
    width: 85px;
  }
  & input {
    flex: 1 0 auto;
  }
`;

export const ErrorMessage = styled.span`
  color: var(--color-orangeDC);
  font-size: var(--font-size13);
  text-align: left;
  margin-top: 3px;
  display: ${({ isDateInvalid }) => (isDateInvalid ? 'block' : 'none')};
`;
