import styled from 'styled-components';
import { Link as DefLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px auto auto;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  column-gap: 60px;
  width: 100%;
  max-width: 950px;
`;

export const Sidebar = styled.div`
  width: 100%;
`;

export const Link = styled(DefLink)`
  display: block;
  width: fit-content;
  color: var(--color-grey97);
  font-size: var(--font-size18);
  line-height: 1.22;
  letter-spacing: 0.5px;
  text-decoration: none;
  ${({ disabled }) =>
    disabled &&
    `
      color: var(--color-blue4A);
      cursor: not-allowed;
  `}
`;

export const ContentContainer = styled.div`
  width: 100%;
`;
