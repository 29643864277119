import { times } from 'ramda';

import { ALL } from 'store/invoices';

export const CANTON_OPTIONS = [
  { id: ALL, label: 'Alle Kantone' },
  { id: 'AG', label: 'Aargau' },
  { id: 'BE', label: 'Bern' },
  { id: 'LU', label: 'Luzern' },
  { id: 'SG', label: 'St.Gallen' },
  { id: 'ZH', label: 'Zürich' },
  { id: 'SO', label: 'Solothurn' },
  { id: 'BL', label: 'Basel-Landschaft' },
];

export const YEAR_OPTIONS = (() => {
  const current = new Date().getFullYear();

  return times((i) => current - i, current - 2018).map((year) => ({
    id: year,
    label: year === current ? 'Laufendes Geschäftsjahr' : String(year),
  }));
})();
