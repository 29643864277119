import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  column-gap: 20px;
  align-items: center;
  margin: 15px 10px 0 20px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  min-width: 44px;
  height: 44px;
  border: 2px solid var(--color-black);
  border-radius: var(--border-radius8);
  background: none;
  outline: none;
  cursor: pointer;
  @media (hover) {
    &:hover {
      background-color: var(--color-black);
      & g {
        stroke: var(--color-white);
      }
    }
  }
`;

export const Track = styled.button`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 16px;
  position: relative;
  border: none;
  background: none;
  cursor: pointer;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background-color: var(--color-black);
  }
`;

export const Thumb = styled(motion.div)`
  position: absolute;
  top: 8px;
  left: 0;
  transform: translate(-50%, -50%);
  height: 16px;
  width: 16px;
  border: 1px solid var(--color-black);
  border-radius: 50%;
  background-color: var(--color-grey97);
  cursor: pointer;
  touch-action: none;
  transition: background-color 0.3s;
  &:before {
    content: '${({ $content }) => $content}';
    display: block;
    width: max-content;
    position: absolute;
    inset: -4px auto auto 8px;
    font-size: var(--font-size15);
    color: var(--color-black);
    transform: translate(-50%, -100%);
    opacity: 0;
    transition: opacity 0.3s;
  }
  @media (hover) {
    &:hover {
      background-color: var(--color-black);
      &:before {
        opacity: 1;
      }
    }
  }
`;
