import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Text from 'components/TextAreaNew';

const TextArea = ({ name, setFieldValue, ...rest }) => {
  const handleChange = useCallback(
    (text) => {
      setFieldValue(name, text);
    },
    [setFieldValue, name]
  );

  return <Text onChangeText={handleChange} tall {...rest} />;
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default TextArea;
