import styled from 'styled-components';

export default styled.button`
  height: 41px;
  width: 41px;
  margin-left: 10px;
  background: var(--color-greyF8);
  border: 1px solid var(--color-greyF8);
  border-radius: var(--border-radius8);
  cursor: pointer;
  outline: 0;
  &:hover {
    background: var(--color-greyEC);
    border: 1px solid var(--color-greyEC);
  }
  &:focus {
    box-shadow: var(--shadow-focus);
  }
  &:active {
    background: var(--color-white);
    border: 1px solid var(--color-grey97);
  }
`;
