import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Container, Item, Wrapper, Art, Status } from './styles';

const Row = ({ id, widths, status, sender, message, date, severity }) => {
  const { push } = useHistory();
  const handleRowClick = useCallback(
    ({ currentTarget }) => {
      const { report } = currentTarget?.dataset || {};

      if (report) push(`/bugreports/${report}`);
    },
    [push]
  );

  return (
    <Container type="button" data-report={id} bold={status === 0} onClick={handleRowClick}>
      <Item width={widths[0]}>{sender}</Item>
      <Item width={widths[1]}>{message}</Item>
      <Item width={widths[2]}>{date}</Item>
      <Wrapper width={widths[3]}>
        <Art>{severity}</Art>
        <Status status={status} />
      </Wrapper>
    </Container>
  );
};

Row.defaultProps = {
  sender: '–',
  date: '',
};

Row.propTypes = {
  id: PropTypes.string.isRequired,
  widths: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  status: PropTypes.number.isRequired,
  sender: PropTypes.string,
  message: PropTypes.string.isRequired,
  date: PropTypes.string,
  severity: PropTypes.string.isRequired,
};

export default Row;
