import styled from 'styled-components';

import ConfirmDef from 'components/Confirm';

export const Title = styled.div`
  border-top: solid 1px var(--color-grey97);
  text-align: left;
  color: var(--color-grey6A);
  font-weight: var(--font-weight500);
  font-size: var(--font-size14);
  padding-top: 12px;
  margin-top: 50px;
`;

export const Confirm = styled(ConfirmDef)`
  margin-top: -18px;
`;
