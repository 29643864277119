import { mergeDeepLeft, pipe, prop, pathOr, propOr } from 'ramda';

import {
  ID,
  NAME,
  ADDRESS,
  CITY,
  CANTON,
  COUNTRY,
  ZIP_CODE,
  PHONE,
  ORG_URL,
  SUB_OPTICIAN,
  CONTACTS,
  TITLE,
  FIRST_NAME,
  LAST_NAME,
  NO_PATIENT_REPORTS,
  GENDER,
  EMAIL,
  KIND,
  SPECIALTY,
  CONTACT_KINDS,
  SERVICES,
  DESCRIPTION,
  STATUS,
  CONSENT,
  ORGANIZATION_KINDS,
  PATIENT_NAME,
  NURSING_HOME_KIND,
} from 'store/organizations';

const getDefaultValues = (kind) => {
  const contactNames = CONTACT_KINDS[kind] || [];

  return {
    [ID]: '',
    [NAME]: '',
    [ADDRESS]: '',
    [CITY]: '',
    [ZIP_CODE]: '',
    [CANTON]: '',
    [COUNTRY]: 'CH',
    [PHONE]: '',
    [ORG_URL]: '',
    [SUB_OPTICIAN]: null,
    [SPECIALTY]: '',
    [SERVICES]: {},
    [NO_PATIENT_REPORTS]: false,
    [DESCRIPTION]: '',
    [STATUS]: 0,
    ...(kind === ORGANIZATION_KINDS.NURSING_HOME && {
      [GENDER]: 'neutral',
      [CONSENT]: 'default',
      [PATIENT_NAME]: 'default',
      [NURSING_HOME_KIND]: 1,
    }),
    [CONTACTS]: contactNames.reduce((acc, contactName) => {
      acc[contactName] = {
        [TITLE]: '',
        [FIRST_NAME]: '',
        [LAST_NAME]: '',
        [GENDER]: '',
        [EMAIL]: '',
        [PHONE]: '',
      };
      return acc;
    }, {}),
  };
};

export const getInitValues = (organization) => pipe(prop(KIND), getDefaultValues, mergeDeepLeft(organization))(organization);

export const extractOpticianId = (data = {}) => pathOr(propOr(null, SUB_OPTICIAN, data), [SUB_OPTICIAN, ID], data);
