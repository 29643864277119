/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState, useMemo } from 'react';
import { not, isEmpty } from 'ramda';
import PropTypes from 'prop-types';

import { ID, VISUAL_ACUITY, RIGHT, LEFT, CONFIDENCE, useExaminations, useCurrentExamination } from 'store/examinations';
import HistoryIcon from 'assets/svg-icons/HistoryIcon';
import AnimateBox from 'components/AnimateBox';

import { handleItems, getVisualAcuity } from './utils';
import { Container, Wrapper, Label, Button, Flex, TitleWrap, Title, SubTitle, Item, ItemWrap, DateText } from './styles';

const VisualAcuitySection = ({ side }) => {
  const [isOpen, setIsOpen] = useState(false);
  const examinations = useExaminations();
  const [currentId, currentSide = {}] = useCurrentExamination([ID, side]);
  const currentVisualAcuity = useMemo(() => getVisualAcuity(currentSide || {}), [currentSide]);
  const visualAcuities = useMemo(() => handleItems(examinations, side), [examinations, side]);
  const handleClick = useCallback(() => setIsOpen(not), []);

  if (visualAcuities.length <= 1 && (!currentSide[VISUAL_ACUITY] || isEmpty(currentSide[VISUAL_ACUITY]))) {
    return null;
  }

  return (
    <Container>
      <Wrapper>
        <Label>Sehschärfe</Label>
        {visualAcuities.length > 1 && (
          <Button type="button" onClick={handleClick}>
            <HistoryIcon isActive={isOpen} />
          </Button>
        )}
      </Wrapper>
      <Flex>
        {currentVisualAcuity.map(({ title, width }, index) => (
          <TitleWrap key={index} width={width}>
            <Title>{title}</Title>
          </TitleWrap>
        ))}
      </Flex>
      <Flex>
        {currentVisualAcuity.map(({ items, width }) =>
          items.map(({ name }, index) => (
            <TitleWrap key={index} width={width}>
              <SubTitle>{name}</SubTitle>
            </TitleWrap>
          ))
        )}
      </Flex>
      <Flex>
        {currentVisualAcuity.map(({ items, width }) =>
          items.map(({ id, value, isRed }, index) => (
            <Item key={index} width={width} $isRed={isRed} gray={!isRed}>
              {(id === CONFIDENCE && !value && 'Schlecht') || value || '–'}
            </Item>
          ))
        )}
      </Flex>
      {visualAcuities.length > 1 && (
        <AnimateBox isOpen={isOpen}>
          {visualAcuities.map(({ id, values, date }) => (
            <ItemWrap key={id} isActive={id === currentId}>
              <DateText>{date}</DateText>
              <Flex>
                {values.map(({ items, width }) =>
                  items.map(({ id: subId, value, isRed }, index) => (
                    <Item key={index} width={width} $isRed={isRed}>
                      {(subId === CONFIDENCE && !value && 'Schlecht') || value || '–'}
                    </Item>
                  ))
                )}
              </Flex>
            </ItemWrap>
          ))}
        </AnimateBox>
      )}
    </Container>
  );
};

VisualAcuitySection.propTypes = {
  side: PropTypes.oneOf([RIGHT, LEFT]).isRequired,
};

export default VisualAcuitySection;
