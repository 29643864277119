import { URL, API_URL, POST, OWN_ERR_HANDLER } from './consts';
import { wrapperRequest, getToken } from './utils';
import { getCache, UPLOAD_FILES_CACHE } from './cache';

const urlCreator = window.URL || window.webkitURL;

export const uploadFile = (file) => {
  const data = new FormData();
  data.append('file', file);

  return wrapperRequest({
    url: `${API_URL}/uploads`,
    options: {
      method: POST,
      headers: { AUTHORIZATION: `Bearer ${getToken()}` },
      file: data,
    },
  });
};

export const uploadXMLFile = (xmlFile) => {
  const data = new FormData();
  data.append('file', xmlFile);

  return wrapperRequest({
    url: `${API_URL}/visualacuities`,
    options: {
      method: POST,
      headers: { AUTHORIZATION: `Bearer ${getToken()}` },
      file: data,
    },
  });
};

export const uploadSearchableMedia = (file, patientId) => {
  const data = new FormData();
  data.append('file', file);

  return wrapperRequest({
    url: `${API_URL}/patients/${patientId}/searchablemedia`,
    options: {
      method: POST,
      headers: { AUTHORIZATION: `Bearer ${getToken()}` },
      file: data,
      [OWN_ERR_HANDLER]: true,
    },
  });
};

export const loadFile = async ({ url, mimetype, offline }) => {
  const cache = await getCache(UPLOAD_FILES_CACHE);

  try {
    let response;
    if (offline && cache) {
      response = await cache.match(url);
    } else {
      response = await fetch(`${URL}/${url}`, { 'Content-Type': mimetype });
    }

    if (!response) return '';

    const blob = await response.blob();
    const blobUrl = await urlCreator.createObjectURL(blob);

    return blobUrl;
  } catch (e) {
    console.error('Image could not be loaded.'); // eslint-disable-line no-console
    return '';
  }
};
